import React from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { RootState } from "../../../../../app/store";
import { InstallMandatoryApp } from "../../../../../types/setting/type";

const DeviceSettingDescriptionList = () => {
  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const { register } = useFormContext<InstallMandatoryApp>();

  const onChangeTeamId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    e.target.value = value.toUpperCase();
    register("team_id").onChange(e);
  };

  const deviceSettings = [
    {
      title: "Model Name",
      description: deviceInfo.model,
    },
    {
      title: "UDID",
      description: deviceInfo.udid,
    },
  ];

  return (
    <>
      {deviceSettings.map((deviceSetting) => (
        <dl
          key={deviceSetting.title}
          className="grid grid-flow-col grid-cols-4 px-6 py-5"
        >
          <dt className="col-span-1 text-gray-500">{deviceSetting.title}</dt>
          <dd className="col-span-3">{deviceSetting.description}</dd>
        </dl>
      ))}
      <div className="grid grid-flow-col grid-cols-4 items-center px-6 py-5">
        <label className="col-span-1 text-gray-500">Team ID</label>
        <input
          type="password"
          className="input-base col-span-3"
          {...register("team_id")}
          onChange={onChangeTeamId}
        />
      </div>
    </>
  );
};

export default DeviceSettingDescriptionList;
