import DraggableSearchbar from '@components/shared/Searchbar/DraggableSearchbar';
import DraggableCardSkeleton from '@components/shared/Skeleton/DraggableCardSkeleton';
import {
  MobileSpeedTestScenarioData,
  SpeedTestScenarioDataInScheduler,
  WebSpeedTestScenarioData,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';

import React from 'react';
import NoSpeedTestScenarioList from './NoSpeedTestScenarioList';
import TestScenarioListItemCard from './TestScenarioListItemCard';

type Props = {
  isLoading: boolean;
  speedTestScenarioList:
    | MobileSpeedTestScenarioData[]
    | WebSpeedTestScenarioData[];
  searchResultTestScenarioList: SpeedTestScenarioDataInScheduler[];
  copiedTestScenarioList: SpeedTestScenarioDataInScheduler[];
  changeCardList: (list: SpeedTestScenarioDataInScheduler[]) => void;
  searchWord: string;
  onChangeSearchWord: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setSearchWord: React.Dispatch<React.SetStateAction<string>>;
};

const AllTestScenarioListInDraggableElement = ({
  isLoading,
  speedTestScenarioList,
  searchResultTestScenarioList,
  copiedTestScenarioList,
  changeCardList,
  searchWord,
  onChangeSearchWord,
  setSearchWord,
}: Props) => {
  return (
    <section>
      <div className="mb-2 flex justify-between">
        <p className="font-medium leading-6">All Test Scenario List</p>
      </div>
      <div className="gray-scrollbar h-52 rounded-md border border-solid border-gray-200">
        <DraggableSearchbar
          searchWord={searchWord}
          onChangeSearchWord={onChangeSearchWord}
          setSearchWord={setSearchWord}
        />
        {isLoading ? (
          <DraggableCardSkeleton cardLength={3} hasProperty={false} />
        ) : speedTestScenarioList?.length === 0 ? (
          <NoSpeedTestScenarioList />
        ) : (
          searchResultTestScenarioList?.map((speedTestScenario) => (
            <TestScenarioListItemCard
              key={speedTestScenario.idx}
              copiedTestScenarioList={copiedTestScenarioList}
              speedTestScenario={speedTestScenario}
              changeCardList={changeCardList}
            />
          ))
        )}
      </div>
    </section>
  );
};

export default AllTestScenarioListInDraggableElement;
