import React from 'react';
import clsx from 'clsx';
import { DownloadButton, VideoSkeletonLoader } from '@autosquare/common';
import TestResultDetailError from '@components/shared/Error/TestResultDetailError';
import { RepeatResultsData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { Tab, TabGroup, TabList, TabPanel } from '@headlessui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
import {
  useIdeSpeedMobileTestResultLogData,
  useIdeSpeedMobileTestResultVideoData,
} from '@lib/api/ide/speedMobile/speedMobileTestResult';
import { downloadBlob } from '@utils/static/downloadBlob';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import {
  useSpeedMobileTestResultLogData,
  useSpeedMobileTestResultVideoData,
} from '@lib/api/dashboard/mobile/mobileSpeedResult';

type Props = {
  repeatResults: RepeatResultsData[];
  logPath: string;
  chartIndex: number;
};

const VideoAndLogTabPanel = ({ repeatResults, logPath, chartIndex }: Props) => {
  const tabs = [
    { name: 'Video', current: true },
    { name: 'Log File', current: false },
  ];
  const isDashboard = isWordInLocationPathname('mobile-app');
  const videoPath = repeatResults.map((item) => item.videoPath);
  const videoData = isDashboard
    ? useSpeedMobileTestResultVideoData(videoPath, chartIndex)
    : useIdeSpeedMobileTestResultVideoData(videoPath, chartIndex);
  const videoSrc = videoData?.data;

  const logData = isDashboard
    ? useSpeedMobileTestResultLogData(logPath, chartIndex)
    : useIdeSpeedMobileTestResultLogData(logPath, chartIndex);
  const logSrc = logData?.data;

  return (
    <TabGroup>
      <TabList className="-mb-px flex space-x-8 border-b border-gray-200">
        {tabs.map((tab) => (
          <Tab key={tab.name}>
            {({ selected }) => (
              <div
                className={clsx(
                  'whitespace-nowrap border-b-2 px-1 py-5 text-sm font-medium',
                  selected
                    ? 'border-gray-900 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                )}
              >
                {tab.name}
              </div>
            )}
          </Tab>
        ))}
      </TabList>
      <TabPanel className="py-2">
        {videoData.isLoading ? (
          <VideoSkeletonLoader />
        ) : videoData.isError ? (
          <TestResultDetailError errorMessage={videoData.error.message} />
        ) : (
          <Swiper
            slidesPerView={1}
            loop={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="size-full !py-14"
          >
            {videoSrc?.map((videoSrc, index) => (
              <SwiperSlide
                key={videoSrc}
                className="flex justify-center bg-white text-center text-sm"
              >
                <div className="flex items-center">
                  <div className="flex flex-col items-center justify-center">
                    {videoSrc !== null && (
                      <DownloadButton
                        onClick={() =>
                          downloadBlob(
                            videoSrc,
                            videoPath[index].split('/').pop(),
                          )
                        }
                      />
                    )}
                    <video
                      autoPlay
                      controls
                      controlsList="nodownload"
                      className="aspect-video"
                    >
                      <source src={videoSrc} type="video/mp4" />
                      <track default kind="captions" />
                    </video>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </TabPanel>
      <TabPanel className="py-2">
        {logData.isError ? (
          <TestResultDetailError errorMessage={logData.error.message} />
        ) : (
          <div className="mt-4 text-center">
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              {logData.isLoading ? (
                <div className="flex w-full animate-pulse items-center justify-center gap-x-1.5 px-2.5 py-1.5">
                  <div className="h-6 w-72 rounded-md bg-gray-200 shadow-sm dark:bg-gray-700" />
                </div>
              ) : (
                logPath?.split('/')?.[logPath?.split('/').length - 1]
              )}
            </h3>
            <div className="mt-2">
              {logData.isLoading ? (
                <div className="mb-3 flex w-full animate-pulse items-center justify-center gap-x-1.5 px-2.5 py-1.5">
                  <div className="size-6 rounded-md bg-gray-200 shadow-sm dark:bg-gray-700" />
                </div>
              ) : (
                logPath !== null && (
                  <DownloadButton
                    onClick={() =>
                      downloadBlob(
                        logSrc,
                        logPath?.split('/')?.[logPath?.split('/').length - 1],
                      )
                    }
                  />
                )
              )}
            </div>
          </div>
        )}
      </TabPanel>
    </TabGroup>
  );
};

export default VideoAndLogTabPanel;
