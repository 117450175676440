import React from 'react';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { TestCaseDetailData } from '@customTypes/testCase/type';
import { ScenarioListDetailData } from '@autosquare/common';
import clsx from 'clsx';
import { DeviceInfo, DeviceInfoOs } from '@customTypes/ide/device/device';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

type Props = {
  selectedTestCase: TestCaseDetailData | ScenarioListDetailData;
  setSelectedTestCase: React.Dispatch<
    React.SetStateAction<TestCaseDetailData | ScenarioListDetailData>
  >;
  query: string;
  setQuery: (value: React.SetStateAction<string>) => void;
  reset: (serialNumber: string) => void;
  testType: TestTypeCategory;
  filteredTestCaseList: TestCaseDetailData[];
  filteredTestScenarioList: ScenarioListDetailData[];
  device: DeviceInfo;
};

const TestCombobox = ({
  selectedTestCase,
  setSelectedTestCase,
  query,
  setQuery,
  reset,
  testType,
  filteredTestCaseList,
  filteredTestScenarioList,
  device,
}: Props) => {
  return (
    <Combobox
      as="div"
      value={selectedTestCase}
      onChange={setSelectedTestCase}
      onClose={() => setQuery('')}
    >
      <div className="relative">
        <div className="flex w-full items-center justify-between gap-2">
          <div className="relative flex w-full items-center justify-between">
            <ComboboxInput
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              displayValue={(
                testCase: TestCaseDetailData | ScenarioListDetailData,
              ) => (selectedTestCase ? testCase?.title : '')}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={'Select Test Case'}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>
          </div>
          <button
            type="button"
            className="button-gray h-9 px-2 focus:outline-none"
            onClick={() => {
              reset(device.serialNumber);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </button>
        </div>
        {testType === 'case' ? (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-[calc(100%-2.5rem)] overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
            {filteredTestCaseList?.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found
              </div>
            ) : (
              filteredTestCaseList?.map((testCase) => (
                <ComboboxOption
                  key={testCase.idx}
                  className="group relative cursor-default select-none py-2 pl-3 pr-9 data-[focus]:bg-indigo-600 data-[focus]:font-semibold data-[focus]:text-white data-[disabled]:opacity-50"
                  value={testCase}
                  disabled={
                    device.os === DeviceInfoOs.Aos &&
                    testCase.steps.aosStepCount === 0
                      ? true
                      : device.os === DeviceInfoOs.Ios &&
                          testCase.steps.iosStepCount === 0
                        ? true
                        : false
                  }
                >
                  <span
                    className={`break-all group-data-[selected]:font-semibold`}
                  >
                    {testCase.title}
                  </span>
                  <span
                    className={clsx(
                      'invisible absolute inset-y-0 right-0 flex items-center pr-4 group-data-[selected]:visible',
                    )}
                  >
                    <CheckIcon className="size-5" aria-hidden="true" />
                  </span>
                </ComboboxOption>
              ))
            )}
          </ComboboxOptions>
        ) : (
          testType === 'scenario' && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-[calc(100%-2.5rem)] overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
              {filteredTestScenarioList?.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found
                </div>
              ) : (
                filteredTestScenarioList?.map((testScenario) => (
                  <ComboboxOption
                    key={testScenario.idx}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 data-[focus]:bg-indigo-600 data-[focus]:font-semibold data-[focus]:text-white data-[disabled]:opacity-50"
                    value={testScenario}
                  >
                    <span
                      className={`break-all group-data-[selected]:font-semibold`}
                    >
                      {testScenario.title}
                    </span>
                    <span
                      className={clsx(
                        'invisible absolute inset-y-0 right-0 flex items-center pr-4 group-data-[selected]:visible',
                      )}
                    >
                      <CheckIcon className="size-5" aria-hidden="true" />
                    </span>
                  </ComboboxOption>
                ))
              )}
            </ComboboxOptions>
          )
        )}
      </div>
    </Combobox>
  );
};

export default TestCombobox;
