import { z } from 'zod';
import { PriorityNameEnum } from './testCase/type';

export type WebResultsType = {
  idx: number;
  tester: string;
  scenarioName: string;
  startTime: string;
  endTime: string;
  type: 'Manual ' | 'Monitoring';
  result: 'PASS' | 'FAIL' | 'Running';
};

export interface MobileTestResultDetailByCaseInformation
  extends WebResultsType {
  device: string;
  appVersion: string;
}

export type MobileTestResultDetailByCaseSummary = {
  total: number;
  pass: number;
  fail: number;
  passRate: number | 'NaN';
};

export type MobileTestResultDetailsType = {
  idx: number;
  seq: number;
  description: string;
  imagePath: string;
};

export type MobileTestResultDetailByCaseResultDetail = {
  idx: number;
  seq: number;
  title: string;
  result: string;
  videoPath: string;
  logPath: string;
  description?: string;
  details: MobileTestResultDetailsType[];
  osType: string;
};

export type MobileTestResultDetailByCase = {
  summary: MobileTestResultDetailByCaseSummary;
  resultDetail: MobileTestResultDetailByCaseResultDetail;
  information: MobileTestResultDetailByCaseInformation;
};

export type MobileTestResultCaseDetailType = {
  idx: number;
  seq: number;
  command: string;
  description: string;
  failReason: string;
  result: string;
  packageName: string;
  appVersion: string;
};

export type UserInfoType = {
  idx: number;
  userId: string;
  name: string;
  status: string;
};

export type ChildrenList = {
  name: string;
  href: string;
  hrefBasic: string;
};

export type SidebarList = {
  name: string;
  href?: string;
  location?: string;
  hrefBasic?: string;
  children?: ChildrenList[];
};

export interface MailReceiver {
  email: string;
  name: string | null;
}

export enum ServerErrorCode {
  NOT_READY_TEST_ENVIRONMENT = '02T00001',
}

export interface ServerErrorData {
  errorDetails: ServerResponseErrorDetails;
}

export interface ServerResponseErrorDetails {
  errorCode: ServerErrorCode;
  httpStatusCode: number;
  message: string;
  status: string;
  timestamp: string;
}

export interface PriorityList {
  id: number;
  name: PriorityNameEnum;
  color: string;
  fillColor: string;
}

export const deleteIndexListSchema = z.object({
  idxList: z.array(z.number()),
});

export type DeleteIndexList = z.infer<typeof deleteIndexListSchema>;
