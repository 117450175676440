import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "@components/shared/Header";

const Admin = () => {
  const location = useLocation();

  const headerTitle =
    location.pathname
      .split("/")
      [location.pathname.split("/").length - 1][0].toUpperCase() +
    location.pathname
      .split("/")
      [location.pathname.split("/").length - 1].slice(1);

  return (
    <>
      <Header>{headerTitle}</Header>
      <Outlet />
    </>
  );
};

export default Admin;
