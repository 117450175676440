import { useInput } from '@autosquare/common';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  getBrowserCurrentPageUrl,
  getBrowserXPath,
} from '@store/ide/webTestStep/webTestStepInfoSlice';

import WebTestStepForm from './WebTestStepForm';

const BrowserView = () => {
  const [websiteUrl, onChangeWebsiteUrl, setWebsiteUrl] = useInput<string>('');
  const [isLoadedWebsite, setIsLoadedWebsite] = useState<boolean>(null);
  const [errorCode, setErrorCode] = useState<number>(null);
  const [errorDescription, setErrorDescription] = useState<string>(null);

  const webpageDivRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    window.electron.send('createBrowserView');
    window.electron.send('viewWebsite', websiteUrl, true);

    setWebsiteUrl(websiteUrl);
    setIsInspectorMode(false);
  };

  // close Browserview when reload window or move another page
  useEffect(() => {
    const closeWebsite = () => window.electron.send('closeWebsite');

    window.addEventListener('beforeunload', closeWebsite);
    return () => {
      window.removeEventListener('beforeunload', closeWebsite);
      closeWebsite();
    };
  }, []);

  // send x path from electron to redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrowserXPath('Value'));
    dispatch(getBrowserCurrentPageUrl('Value'));

    const currentPageUrlMessageHandler = (message: string) => {
      dispatch(getBrowserCurrentPageUrl(message));
      setWebsiteUrl(message);
    };

    window.electron.on<string>('webPageUrl', currentPageUrlMessageHandler);
    window.electron.on<string>('websiteXPath', (message) => {
      dispatch(getBrowserXPath(`xpath=${message}`));
      setGetXPath(`xpath=${message}`);
    });
    return () => {
      dispatch(getBrowserCurrentPageUrl('Value'));
      dispatch(getBrowserXPath('Value'));
    };
  }, []);

  // check did-finish-load & did-fail-load
  useEffect(() => {
    window.electron.on<{
      isLoadingFailed: boolean;
      errorCode: number;
      errorDescription: string;
    }>('didFinishLoad', ({ isLoadingFailed, errorCode, errorDescription }) => {
      setIsLoadedWebsite(isLoadingFailed);
      setErrorCode(errorCode);
      setErrorDescription(errorDescription);
    });
  }, []);

  // select inpectorMode
  const [getXPath, setGetXPath] = useState<string>('');
  const [isInspectorMode, setIsInspectorMode] = useState(false);
  const changeInspectorMode = () => {
    if (isLoadedWebsite) {
      setIsInspectorMode(!isInspectorMode);
      window.electron.send(
        'inspectorMode',
        !isInspectorMode ? 'inspectorMode' : 'basicMode',
      );
    } else {
      setIsInspectorMode(false);
    }
  };

  useEffect(() => {
    if (getXPath) {
      window.electron.send(
        'inspectorMode',
        !isInspectorMode ? 'inspectorMode' : 'basicMode',
      );
      setIsInspectorMode(false);
    }
  }, [getXPath]);

  useEffect(() => {
    const updateInspectorMode = () => {
      setIsInspectorMode(false);
    };

    window.electron.on('update-inspectorMode', updateInspectorMode);

    return () => {
      window.electron.removeListener(
        'update-inspectorMode',
        updateInspectorMode,
      );
    };
  }, []);

  return (
    <div className="mb-4 flex grow justify-between">
      <div className="mx-4 flex size-full flex-col gap-4">
        <WebTestStepForm
          handleSubmit={handleSubmit}
          websiteUrl={websiteUrl}
          onChangeWebsiteUrl={onChangeWebsiteUrl}
          isInspectorMode={isInspectorMode}
          setIsInspectorMode={setIsInspectorMode}
          isLoadedWebsite={isLoadedWebsite}
          changeInspectorMode={changeInspectorMode}
        />
        <div
          className="flex h-[calc(100vh-9rem)] w-full items-center justify-center border"
          ref={webpageDivRef}
        >
          {errorCode === -105 ? (
            <div className="flex flex-col gap-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                />
              </svg>
              <p className="text-2xl font-medium leading-5 ">
                사이트에 연결할 수 없음
              </p>
              <p className="text-gray-600">URL에 오타가 있는지 확인하세요.</p>
              <p className="text-sm text-gray-500">{`${errorDescription} (${errorCode})`}</p>
            </div>
          ) : isLoadedWebsite ? (
            ''
          ) : (
            'URL을 입력해 주세요.'
          )}
        </div>
      </div>
    </div>
  );
};

export default BrowserView;
