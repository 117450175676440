import React from 'react';

const SmsListSkeleton = () => {
  return (
    <div className="gap-4 h-full animate-pulse">
      <div className="grid grid-cols-3 gap-4 w-full p-2 border-b border-gray-200">
        {Array.from({ length: 3 }, (_, i) => (
          <div
            key={i}
            className="w-full h-6 bg-gray-200 dark:bg-gray-700 rounded-md"
          />
        ))}
      </div>
      {Array.from({ length: 3 }, (_, i) => (
        <div key={i} className="grid grid-cols-3 gap-4 w-full p-2">
          {Array.from({ length: 3 }, (_, j) => (
            <div
              key={j}
              className="w-full h-6 bg-gray-200 dark:bg-gray-700 rounded-md"
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SmsListSkeleton;
