import React from 'react';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import LoopCondition from './EditLoop/LoopCondition';
import LoopAction from './EditLoop/LoopAction';

interface Props {
  mobileTestStep: MobileTestStepData;
}

const EditLoop = ({ mobileTestStep }: Props) => {
  return (
    <>
      <LoopCondition />
      <LoopAction mobileTestStep={mobileTestStep} />
    </>
  );
};

export default EditLoop;
