import React, { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  TableBodyBasic,
  TableBodyBasicNoData,
  TableHeader,
  TableRow,
} from '@autosquare/common';

import { MobileTestResultProperties } from '@customTypes/testResult/type';

type Props = {
  tableHeaders: string[];
  mobileResults: MobileTestResultProperties[];
};

const MobileUITestResults = ({ tableHeaders, mobileResults }: Props) => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();

  const subTitle = [{ title: 'UI Test' }, { title: 'Mobile' }];

  return (
    <div className="mt-8 flow-root">
      <div className="pb-3">
        <div className="flex items-center space-x-2">
          {subTitle.map((item) => (
            <Fragment key={item.title}>
              <p className="text-sm font-semibold leading-6 text-gray-900">
                {item.title}
              </p>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </Fragment>
          ))}
          <p className="truncate text-sm text-gray-500">
            최근 테스트 결과 목록
          </p>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            {tableHeaders?.map((header, index) => (
              <TableHeader key={index}>{header}</TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {mobileResults?.length === 0 ? (
            <TableBodyBasicNoData colSpan={tableHeaders?.length}>
              최근 테스트 결과가 없습니다.
            </TableBodyBasicNoData>
          ) : (
            mobileResults?.map((mobileResult, index) => (
              <TableRow key={mobileResult.idx}>
                <TableBodyBasic>{index + 1}</TableBodyBasic>
                <TableBodyBasic>{mobileResult.scenarioName}</TableBodyBasic>
                <TableBodyBasic>{mobileResult.startTime}</TableBodyBasic>
                <TableBodyBasic>{mobileResult.result}</TableBodyBasic>
                <TableBodyBasic>
                  {mobileResult.result !== 'Running' && (
                    <button
                      type="button"
                      className="rounded-md shadow-sm hover:text-congress-blue"
                      onClick={() =>
                        navigate({
                          pathname: `/ide/projects/${projectIdx}/ui-test/mobile/test-result/detail`,
                          search: `idx=${mobileResult.idx}`,
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                        />
                      </svg>
                    </button>
                  )}
                </TableBodyBasic>
              </TableRow>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MobileUITestResults;
