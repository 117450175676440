import {
  RepeatStepComboboxHeadlessUI,
  StartAndFinishMark,
} from '@autosquare/common';
import { FilteredList } from '@autosquare/common/src/utils/type/repeatBox/type';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useRepeatSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import React, { useEffect, useState } from 'react';
type Props = {
  filteredList: FilteredList[];
  startRepeatIdx: number;
  endRepeatIdx: number;
  deviceInfo: DeviceInfoOs;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};
const TestStepRepeatFinishBox = ({
  filteredList,
  startRepeatIdx,
  endRepeatIdx,
  deviceInfo,
  setError,
}: Props) => {
  const initialValue =
    endRepeatIdx === 0
      ? { idx: null, label: '', disabled: false }
      : filteredList?.find((item) => item.idx === endRepeatIdx);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const saveRepeatTestStep = useRepeatSpeedMobileTestStep();
  const startIndex = filteredList?.findIndex(
    (item) => item?.idx === startRepeatIdx,
  );

  const filteredFinishList = filteredList?.map((item, index) => ({
    ...item,
    disabled: index <= startIndex || item.disabled,
  }));

  const handleChange = (value: FilteredList) => {
    setSelectedValue(value);
    saveRepeatTestStep.mutate({
      startStepIdx: startRepeatIdx,
      endStepIdx: value === null ? 0 : value.idx,
      mobileOs: deviceInfo,
    });
    setError(value === null ? true : false);
  };

  useEffect(() => {
    if (startRepeatIdx === 0) {
      setSelectedValue(initialValue);
    }
  }, [endRepeatIdx]);
  return (
    <div className="flex items-center gap-2.5">
      <StartAndFinishMark />
      <p className="w-12 text-xs font-medium  text-gray-900">Finish</p>
      <div className="w-full">
        <RepeatStepComboboxHeadlessUI
          value={selectedValue}
          filteredList={filteredFinishList}
          onChange={handleChange}
          disabledBox={startRepeatIdx === 0}
          findIndex={
            filteredFinishList?.find((item) => item?.idx === selectedValue?.idx)
              ?.idx
          }
        />
      </div>
    </div>
  );
};

export default TestStepRepeatFinishBox;
