import { RootState } from '@app/store';
import { DoubleCheckDialogHeadlessUI } from '@autosquare/common';
import { DeviceInfo } from '@customTypes/ide/device/device';
import { useSpeedExecutionMobileTestStepMutation } from '@lib/api/ide/speedMobile/speedMobileExecution';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import RepeatStepExecutionRepeatInput from './RepeatStepExecutionRepeatInput/RepeatStepExecutionRepeatInput';

type Props = {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  inputRepeat: string;
  setInputRepeat: React.Dispatch<React.SetStateAction<string>>;
  deviceInfo: DeviceInfo;
  startRepeatIdx: number;
  endRepeatIdx: number;
};

const RepeatStepExecutionModal = ({
  openModal,
  setOpenModal,
  inputRepeat,
  setInputRepeat,
  deviceInfo,
  startRepeatIdx,
  endRepeatIdx,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const repeat = Number(inputRepeat);

  const refreshToken = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  const executeMobileTestStepMutation = useSpeedExecutionMobileTestStepMutation(
    refreshToken,
    {
      infos: [deviceInfo],
      caseIdx: Number(idx),
      startStepIdx: startRepeatIdx,
      endStepIdx: endRepeatIdx,
      repeatCount: Number(inputRepeat),
    },
  );

  const closeModal = () => {
    setOpenModal(false);
  };

  const executeMobileTestStep = () => {
    if (repeat >= 3 && repeat <= 12) {
      setErrorMessage('');
      executeMobileTestStepMutation.mutate();
      closeModal();
    }
    if (repeat < 3 || repeat > 12) {
      setErrorMessage(
        '테스트 횟수를 최소 3회에서 최대 12회까지 입력해 주세요.',
      );
    }
    if (!inputRepeat) {
      setErrorMessage('테스트 반복 횟수를 입력해 주세요.');
    }
  };

  return (
    <DoubleCheckDialogHeadlessUI
      isOpened={openModal}
      setIsOpened={setOpenModal}
      type="caution"
      title="Execute Speed Test"
      subTitle={
        '설정한 구간에 대한 속도 테스트를 진행하시겠습니까?\n(테스트 결과는 저장되지 않으며, \n테스트 실행 완료 후 현재 스크린샷과 객체 정보가 자동 갱신됩니다.)'
      }
      buttonChildren={
        <RepeatStepExecutionRepeatInput
          inputRepeat={inputRepeat}
          setInputRepeat={setInputRepeat}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setOpenModal={setOpenModal}
          executeMobileTestStep={executeMobileTestStep}
        />
      }
    />
  );
};

export default RepeatStepExecutionModal;
