import React, { createContext, ReactNode, useContext, useState } from 'react';

interface TestExecutionProviderContext {
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: () => void;
  closeModal: () => void;
}

export const TestExecutionContext = createContext<TestExecutionProviderContext>(
  {
    isModalOpened: false,
    setIsModalOpened: () => {},
    openModal: () => {},
    closeModal: () => {},
  },
);

export const useTestExecutionContext = () => {
  return useContext(TestExecutionContext);
};

interface Props {
  children: ReactNode;
}

const TestExecutionProvider = ({ children }: Props) => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  return (
    <TestExecutionContext.Provider
      value={{
        isModalOpened,
        setIsModalOpened,
        openModal,
        closeModal,
      }}
    >
      {children}
    </TestExecutionContext.Provider>
  );
};

export default TestExecutionProvider;
