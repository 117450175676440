import { KonvaEventObject } from 'konva/lib/Node';

export const canvasHandleCursor = (e: KonvaEventObject<MouseEvent>) => {
  const stage = e.target.getStage();
  if (stage) {
    if (e.type === 'mouseenter') {
      stage.container().style.cursor = 'move';
    } else {
      stage.container().style.cursor = 'default';
    }
  }
};
