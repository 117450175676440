import React from 'react';
import { AdminWebDriverListData } from '@customTypes/dashboard/admin/type';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';

type Props = {
  adminWebDriverList: AdminWebDriverListData[];
};

const AdminWebDriverListTable = ({ adminWebDriverList }: Props) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
          >
            #
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            OS
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Browser
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Version
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            CPU arch
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {adminWebDriverList.length === 0 ? (
          <tr>
            <td
              colSpan={6}
              className="px-3 py-4 text-sm text-gray-500 text-center"
            >
              업로드된 WebDriver가 없습니다.
            </td>
          </tr>
        ) : (
          adminWebDriverList.map((webdriver, index) => (
            <tr key={webdriver.idx}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {index + 1}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                {webdriver.os}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                {webdriver.browser}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                {webdriver.version}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                {webdriver.cpuArch}
              </td>
              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <span className="isolate inline-flex">
                  <button
                    type="button"
                    className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-900 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  >
                    <span className="sr-only">Next</span>
                    <TrashIcon aria-hidden="true" className="h-5 w-5" />
                  </button>
                </span>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default AdminWebDriverListTable;
