import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const TestStepDetailTableDataCell = ({ children }: Props) => {
  return <td className="pb-2 pt-3.5">{children}</td>;
};

export default TestStepDetailTableDataCell;
