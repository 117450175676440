import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { axiosInstance } from '..';

import { API } from '@api/endpoints';

import { ServerResponseErrorDetails } from '@customTypes/type';
import { projectKeys } from '@queries/dashboard/projectKeys';
import {
  AddMemberData,
  ProjectListDetail,
  ProjectMemberList,
  UpdateMemberInfoData,
} from '@customTypes/dashboard/project/type';

// GET
const getProjectListQuery = async (): Promise<ProjectListDetail[]> => {
  const response = await axiosInstance.get<ProjectListDetail[]>(API.PROJECT);
  return response.data;
};
export const projectListQuery = () =>
  useQuery<ProjectListDetail[], ServerResponseErrorDetails>({
    queryKey: projectKeys.lists(),
    queryFn: () => getProjectListQuery(),
  });

const getProjectMemberList = async (
  projectIdx: string,
): Promise<ProjectMemberList[]> => {
  const response = await axiosInstance.get<ProjectMemberList[]>(
    API.getMembers(projectIdx),
  );
  return response.data;
};
export const useProjectMemberListQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<ProjectMemberList[], ServerResponseErrorDetails>({
    queryKey: projectKeys.projectMemberList(projectIdx),
    queryFn: () => getProjectMemberList(projectIdx),
  });
};

const getProjectMembetDetail = async (
  projectIdx: string,
  idx: string,
): Promise<ProjectMemberList> => {
  const response = await axiosInstance.get<ProjectMemberList>(
    API.getMemberDetail(projectIdx, idx),
  );
  return response.data;
};
export const useProjectMemberDetailQuery = (projectIdx: string, idx: string) =>
  useQuery<ProjectMemberList, ServerResponseErrorDetails>({
    queryKey: projectKeys.projectMebmerDetail(projectIdx, idx),
    queryFn: () => getProjectMembetDetail(projectIdx, idx),
  });

const getProjectDetail = async (
  projectIdx: string,
): Promise<ProjectListDetail> => {
  const response = await axiosInstance.get<ProjectListDetail>(
    `${API.PROJECT}/${projectIdx}`,
  );
  return response.data;
};
export const useProjectDetailQuery = (projectIdx: string) =>
  useQuery<ProjectListDetail, ServerResponseErrorDetails>({
    queryKey: projectKeys.detail(projectIdx),
    queryFn: () => getProjectDetail(projectIdx),
  });

// POST
const addMemberProjectMember = async (
  projectIdx: string,
  data: AddMemberData,
): Promise<unknown> => {
  const response = await axiosInstance.post(
    API.postProjectMember(projectIdx),
    data,
  );
  return response.data;
};

export const useAddProjectMemberMutation = (projectIdx: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<unknown, ServerResponseErrorDetails, AddMemberData>({
    mutationFn: (data) => addMemberProjectMember(projectIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      navigate(`/projects/${projectIdx}/members/list`);
    },
  });
};

// PUT
const updateMemberInfo = async (
  projectIdx: string,
  idx: string,
  data: UpdateMemberInfoData,
): Promise<unknown> => {
  const response = await axiosInstance.put(
    API.putMemberInfo(projectIdx, idx),
    data,
  );
  return response.data;
};
export const useUpdateMemberInfoMutation = (
  projectIdx: string,
  idx: string,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, ServerResponseErrorDetails, UpdateMemberInfoData>(
    {
      mutationFn: (data) => updateMemberInfo(projectIdx, idx, data),
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.all);
      },
    },
  );
};

const deleteMemberInfo = async (
  projectIdx: string,
  idx: string,
): Promise<unknown> => {
  const response = await axiosInstance.delete(
    API.removeMember(projectIdx, idx),
  );
  return response.data;
};
export const useRemoveMemberMutation = (projectIdx: string, idx: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<unknown, ServerResponseErrorDetails>({
    mutationFn: () => deleteMemberInfo(projectIdx, idx),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.projectMemberLists());
      navigate({ pathname: `/projects/${projectIdx}/members/list` });
    },
  });
};
