import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '..';

import { API } from '@api/endpoints';

import { OverviewFunctionalResultType } from '@autosquare/common';

import {
  MobileSpeedTestResultsInOverviewType,
  WebSpeedTestResultsInOverviewType,
} from '@customTypes/ide/overview/type';
import { ServerResponseErrorDetails } from '@customTypes/type';

import { overviewKeys } from '@queries/dashboard/overviewKeys';

interface MobileOverview {
  functionalResults: OverviewFunctionalResultType[];
  speedResults: MobileSpeedTestResultsInOverviewType;
}

interface WebOverview {
  functionalResults: OverviewFunctionalResultType[];
  speedResults: WebSpeedTestResultsInOverviewType;
}

// GET
const getMobileOverview = async (
  projectIdx: string,
): Promise<MobileOverview> => {
  const response = await axiosInstance.get<MobileOverview>(
    API.MOBILE_OVERVIEW + projectIdx,
  );
  return response.data;
};
export const useMobileOverviewQuery = (projectIdx: string) => {
  return useQuery<MobileOverview, ServerResponseErrorDetails>({
    queryKey: overviewKeys.mobile(projectIdx),
    queryFn: () => getMobileOverview(projectIdx),
  });
};

const getWebOverview = async (projectIdx: string): Promise<WebOverview> => {
  const response = await axiosInstance.get<WebOverview>(
    API.WEB_OVERVIEW + projectIdx,
  );
  return response.data;
};
export const useWebOverviewQuery = (projectIdx: string) => {
  return useQuery<WebOverview, ServerResponseErrorDetails>({
    queryKey: overviewKeys.web(projectIdx),
    queryFn: () => getWebOverview(projectIdx),
  });
};
