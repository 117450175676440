import React, { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useUpdateAdminUserDetailMutation } from '@lib/api/dashboard/admin/admin';

import AdminUserInformation from './components/AdminUserInformation';

import {
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadings,
} from '@autosquare/common';

const AdminUserDetail = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const adminUserListPage = '/admin/users/list';

  const [isEdited, setIsEdited] = useState(false);

  const [isActivated, setIsActivated] = useState<boolean | null>();
  const [selectedRole, setSelectedRole] = useState<string>();

  const data = { role: selectedRole, status: isActivated };

  const updateAdminUserDetailMutation = useUpdateAdminUserDetailMutation(idx);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isActivated !== null) {
      updateAdminUserDetailMutation.mutate(data);
    }
  };

  const breadcrumbs = [
    { title: 'Administration', href: adminUserListPage },
    { title: 'Users', href: adminUserListPage },
    { title: 'Detail', href: location.pathname + location.search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = isEdited
    ? [
        {
          title: 'Cancel',
          onClick: () => {
            setIsEdited(false);
          },
          type: 'button',
          variant: 'secondary',
        },
        {
          title: 'Save',
          type: 'submit',
          variant: 'primary',
          disabled: isActivated === null,
        },
      ]
    : [
        {
          title: 'Edit',
          onClick: () => {
            setIsEdited(true);
          },
          type: 'button',
          variant: 'secondary',
        },
      ];

  return (
    <form onSubmit={handleSubmit}>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={isEdited ? 'Edit User Info' : 'User Detail'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <AdminUserInformation
        isEdited={isEdited}
        isActivated={isActivated}
        setIsActivated={setIsActivated}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      />
      {updateAdminUserDetailMutation.isError && (
        <ErrorMessage>
          {updateAdminUserDetailMutation.error.message}
        </ErrorMessage>
      )}
    </form>
  );
};

export default AdminUserDetail;
