import React from "react";

import { MobileTestResultDetailByCaseSummary } from "../../../types/type";

import { HeadingLevelTwoWithUnderline } from "@autosquare/common";

type Props = {
  testResultSummary: MobileTestResultDetailByCaseSummary;
  result: string;
  reason: string;
};

const Summary = ({ testResultSummary, result, reason }: Props) => {
  const stats = [
    {
      name: "Result",
      stat: result,
      color: "text-gray-900",
      sub: result === "N/A" && reason === null ? "Unknown Error" : reason,
    },
    {
      name: "Total",
      stat: testResultSummary?.total,
      color: "text-gray-900",
    },
    {
      name: "Pass",
      stat: testResultSummary?.pass,
      color: "text-green-500",
    },
    {
      name: "Fail",
      stat: testResultSummary?.fail,
      color: "text-red-500",
    },
    {
      name: "Pass Rate",
      stat:
        result === "N/A"
          ? "-"
          : testResultSummary?.passRate === "NaN"
          ? "0%"
          : Number.isInteger(testResultSummary?.passRate * 100)
          ? `${testResultSummary?.passRate * 100}%`
          : `${(testResultSummary?.passRate * 100).toFixed(2)}%`,
      color:
        testResultSummary?.passRate === 1 ? "text-blue-500" : "text-red-500",
    },
  ];

  return (
    <section>
      <HeadingLevelTwoWithUnderline>Summary</HeadingLevelTwoWithUnderline>
      <div className="-mx-4">
        <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden bg-white md:grid-cols-5 md:divide-x md:divide-y-0">
          {stats.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                {item.name}
              </dt>
              <dd
                className={`mt-1 text-xl font-semibold tracking-tight ${item.color}`}
              >
                {item.stat}
              </dd>
              {item.sub && (
                <dd className="truncate text-xs font-sm text-gray-500 whitespace-normal">
                  {item.sub}
                </dd>
              )}
            </div>
          ))}
        </dl>
      </div>
    </section>
  );
};

export default Summary;
