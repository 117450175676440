import React, { Fragment } from 'react';
import { Switch } from '@headlessui/react';

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  size?: 'sm' | 'base';
};

const SwitchInController = ({
  checked,
  onChange,
  disabled,
  size = 'base',
}: Props) => {
  const buttonSize =
    size === 'base'
      ? {
          width: 'w-11',
          height: 'h-6',
          size: 'size-4',
        }
      : size === 'sm' && {
          width: 'w-10',
          height: 'h-5',
          size: 'size-3',
        };

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      as={Fragment}
    >
      {({ checked, disabled }) => (
        <button
          className={`group inline-flex ${buttonSize.width} ${buttonSize.height} items-center rounded-full ${disabled ? 'cursor-not-allowed bg-gray-200' : checked ? 'bg-congress-blue' : 'bg-gray-400'}`}
        >
          <span className="sr-only">Switch Enabled</span>
          <span
            className={`${buttonSize.size} rounded-full bg-white transition ${checked ? 'translate-x-6' : 'translate-x-1'}`}
          />
        </button>
      )}
    </Switch>
  );
};

export default SwitchInController;
