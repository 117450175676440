import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

interface Props {
  webTestStep: WebTestStepData;
}

const TestStepScrollAndSwipe = ({ webTestStep }: Props) => {
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const [startX, startY, endX, endY] = webTestStep.value.split(',');

  const browserScreenWidth = window.innerWidth;
  const browserScreenHeight = window.innerHeight;

  useEffect(() => {
    setValue('startX', Number(startX));
    setValue('startY', Number(startY));
    setValue('endX', Number(endX));
    setValue('endY', Number(endY));
  }, [setValue, startX, startY, endX, endY]);

  const watchStartX = watch('startX');
  const watchStartY = watch('startY');
  const watchEndX = watch('endX');
  const watchEndY = watch('endY');

  useEffect(() => {
    setValue(
      'value',
      `${watchStartX},${watchStartY},${watchEndX},${watchEndY}`,
    );
  }, [setValue, watchStartX, watchStartY, watchEndX, watchEndY]);

  const scrollSwipeProperties = [
    {
      labelTitle: 'startX',
      maxSize: browserScreenWidth,
      register: register('startX', {
        valueAsNumber: true,
        required: '좌표 값을 모두 입력해주세요.',
        min: {
          value: 0,
          message:
            '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요. ',
        },
        max: {
          value: browserScreenWidth,
          message:
            '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요. ',
        },
        validate: (value) => {
          if (isNaN(value)) {
            return '숫자를 입력해 주세요.';
          }
          return true;
        },
      }),
    },
    {
      labelTitle: 'startY',
      maxSize: browserScreenHeight,
      register: register('startY', {
        valueAsNumber: true,
        required: '좌표 값을 모두 입력해주세요.',
        min: {
          value: 0,
          message:
            '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요. ',
        },
        max: {
          value: browserScreenHeight,
          message:
            '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요. ',
        },
        validate: (value) => {
          if (isNaN(value)) {
            return '숫자를 입력해 주세요.';
          }
          return true;
        },
      }),
    },
    {
      labelTitle: 'endX',
      maxSize: browserScreenWidth,
      register: register('endX', {
        valueAsNumber: true,
        required: '좌표 값을 모두 입력해주세요.',
        min: {
          value: 0,
          message:
            '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요. ',
        },
        max: {
          value: browserScreenWidth,
          message:
            '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요. ',
        },
        validate: (value) => {
          if (isNaN(value)) {
            return '숫자를 입력해 주세요.';
          }
          return true;
        },
      }),
    },
    {
      labelTitle: 'endY',
      maxSize: browserScreenHeight,
      register: register('endY', {
        valueAsNumber: true,
        required: '좌표 값을 모두 입력해주세요.',
        min: {
          value: 0,
          message:
            '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요. ',
        },
        max: {
          value: browserScreenHeight,
          message:
            '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요. ',
        },
        validate: (value) => {
          if (isNaN(value)) {
            return '숫자를 입력해 주세요.';
          }
          return true;
        },
      }),
    },
  ];

  return (
    <div className="items-center sm:flex sm:px-6 sm:py-4">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        Value*
      </dt>
      <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        <div className="text-sm">
          <div className="mb-4 grid w-full grid-cols-4 gap-4 text-center">
            <p>Width</p>
            <p>{browserScreenWidth}</p>
            <p>Height</p>
            <p>{browserScreenHeight}</p>
          </div>
          <div className="grid w-full grid-cols-4 gap-4 text-center">
            {scrollSwipeProperties?.map((property, index) => (
              <div key={index}>
                <label htmlFor={property.labelTitle}>
                  {property.labelTitle}
                </label>
                <input
                  type="text"
                  name={property.labelTitle}
                  id={property.labelTitle}
                  className={clsx(
                    'mt-2 w-full rounded-md border border-solid border-gray-300 px-3 py-1 text-center text-sm',
                    inputNumberArrowHide,
                  )}
                  {...property.register}
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                />
              </div>
            ))}
          </div>
          <ErrorMessage
            name={
              errors.startX
                ? 'startX'
                : errors.startY
                  ? 'startY'
                  : errors.endX
                    ? 'endX'
                    : errors.endY && 'endY'
            }
            errors={errors}
            render={({ message }) => (
              <p className="standard-error-message">{message}</p>
            )}
          />
        </div>
      </dd>
    </div>
  );
};

export default TestStepScrollAndSwipe;

const inputNumberArrowHide =
  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none';
