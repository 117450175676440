import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import React from 'react';
import { useFormContext } from 'react-hook-form';

import ExtractTextFormat from './SaveText/ExtractTextFormat';
import From from './SaveText/From';
import SmsList from './SaveText/SmsList';

const SaveText = () => {
  const { watch } = useFormContext<CreateTestStepData>();

  const saveTextFrom = watch('optionsDto.from');

  return (
    <>
      <From />
      {saveTextFrom === SaveTextFrom.Sms && <SmsList />}
      <ExtractTextFormat />
    </>
  );
};

export default SaveText;
