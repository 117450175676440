import { ErrorMessage } from '@hookform/error-message';
import {
  TestCaseGroup,
  UpdateTestCaseGroup,
} from '@customTypes/testCaseGroup/type';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import {
  useTestCaseGroupListQuery,
  useUpdateTestCaseGroupMutation,
} from '@lib/api/ide/testGroup/testCaseGroup';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGroupContext } from '@contexts/ide/GroupProvider';
import {
  useGetTestCaseServiceGroupListQuery,
  useUpdateTestCaseServiceGroupMutation,
} from '@lib/api/ide/serviceGroup/serviceGroup';
import { UpdateTestCaseServiceGroup } from '@customTypes/serviceGroup/type';
import useDebounce from '@hooks/group/useDebouce';
import { Button } from '@autosquare/common';
import { checkForDuplicateEdit } from '@utils/static/checkForDuplicate';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  groupIdx: number;
  groupData: TestCaseGroup[];
};
const EditGroup = ({ isOpen, setIsOpen, groupIdx }: Props) => {
  const { projectIdx } = useParams();
  const { tabGroupType } = useGroupContext();

  const testCaseGroupListQuery = useTestCaseGroupListQuery({
    enabled: tabGroupType === 'Test',
  });
  const testCaseGroupListData = testCaseGroupListQuery.data;

  const serviceGroupListQuery = useGetTestCaseServiceGroupListQuery({
    enabled: tabGroupType === 'Service',
  });

  const serviceGroupListData = serviceGroupListQuery.data;

  const groupListData =
    tabGroupType === 'Test' ? testCaseGroupListData : serviceGroupListData;

  const selectedData = groupListData?.find((item) => groupIdx === item.idx);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    control,
  } = useForm<UpdateTestCaseGroup | UpdateTestCaseServiceGroup>();

  const updateTestGroup = useUpdateTestCaseGroupMutation(projectIdx);
  const updateServiceGroup = useUpdateTestCaseServiceGroupMutation(projectIdx);

  const groupTitle = useWatch({ control, name: 'name' });
  const debouncedTitle = useDebounce(groupTitle, 500);

  useEffect(() => {
    if (
      tabGroupType === 'Test' &&
      checkForDuplicateEdit(
        groupListData,
        selectedData,
        debouncedTitle,
        setError,
        clearErrors,
      )
    ) {
      updateTestGroup.reset();
    }

    if (
      tabGroupType === 'Service' &&
      checkForDuplicateEdit(
        groupListData,
        selectedData,
        debouncedTitle,
        setError,
        clearErrors,
      )
    ) {
      updateServiceGroup.reset();
    }
  }, [debouncedTitle, setError, clearErrors, groupListData, tabGroupType]);

  const onSubmit: SubmitHandler<
    UpdateTestCaseGroup | UpdateTestCaseServiceGroup
  > = (data) => {
    const testData = {
      caseGroupIdx: (data as UpdateTestCaseGroup).caseGroupIdx,
      name: data.name,
    };
    const serviceData = {
      serviceGroupIdx: (data as UpdateTestCaseServiceGroup).serviceGroupIdx,
      name: data.name,
    };

    if (groupTitle.length === 0 || groupTitle === '') {
      setTimeout(() => {
        setError('name', {
          type: 'required',
          message: '그룹명을 입력해 주세요.',
        });
      }, 500);
      return;
    }

    if (selectedData.name === data.name) {
      return setIsOpen(false);
    }

    if (tabGroupType === 'Test') {
      if (
        checkForDuplicateEdit(
          testCaseGroupListData,
          selectedData,
          groupTitle,
          setError,
          clearErrors,
        )
      )
        return;
      updateTestGroup.mutate(testData, {
        onSuccess: () => setIsOpen(false),
        onError: () => testCaseGroupListQuery.refetch(),
      });
    }

    if (tabGroupType === 'Service') {
      if (
        checkForDuplicateEdit(
          serviceGroupListData,
          selectedData,
          groupTitle,
          setError,
          clearErrors,
        )
      )
        return;
      updateServiceGroup.mutate(serviceData, {
        onSuccess: () => setIsOpen(false),
        onError: () => serviceGroupListQuery.refetch(),
      });
    }
  };

  useEffect(() => {
    if (selectedData) {
      setValue('name', selectedData.name);
      if (tabGroupType === 'Test') {
        setValue('caseGroupIdx', selectedData.idx);
      }
      if (tabGroupType === 'Service') {
        setValue('serviceGroupIdx', selectedData.idx);
      }
    }
  }, [setValue, selectedData, tabGroupType]);

  const closeHandler = () => {
    setValue('name', selectedData.name);
    clearErrors('name');
    setIsOpen(false);
  };
  return (
    <Dialog open={isOpen} onClose={closeHandler} className="relative z-50">
      <div className="fixed inset-0 bg-white/50" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="mx-auto w-[102rem] max-w-lg rounded-lg bg-white shadow-lg">
          <DialogTitle className="text-md mb-3 font-medium leading-6 text-gray-900">
            <header className="mb-4 rounded-t-lg border-b border-gray-200 bg-congress-blue p-4 text-base font-semibold leading-6 text-white">
              Edit {tabGroupType} Group
            </header>
          </DialogTitle>
          <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="max-h-96 overflow-y-auto  border-gray-900/10 px-2 pb-5">
              <label
                htmlFor="create-group-title"
                className="mb-3 block text-sm font-medium leading-6 text-gray-900"
              >
                Title
              </label>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                id="create-group-title"
                {...register('name')}
                autoFocus
              />
              {updateTestGroup.isError ? (
                <p className="ml-1 mt-2 text-sm text-red-500">
                  {updateTestGroup.error.message}
                </p>
              ) : updateServiceGroup.isError ? (
                <p className="ml-1 mt-2 text-sm text-red-500">
                  {updateServiceGroup.error.message}
                </p>
              ) : (
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => (
                    <p className="ml-1 mt-2 text-sm text-red-500">{message}</p>
                  )}
                />
              )}
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-3 border-t px-4 pt-5">
              <Button
                type="button"
                variant="secondary"
                noRing={true}
                onClick={closeHandler}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default EditGroup;
