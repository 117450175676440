import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import SelectRoleListBox from "@components/shared/SelectRoleListBox";

import {
  DescriptionListSkeleton,
  ErrorMessage,
  ToggledSwitch,
} from "@autosquare/common";

import { useAdminUserDetailQuery } from "@lib/api/dashboard/admin/admin";

type Props = {
  isEdited: boolean;
  isActivated: boolean | null;
  setIsActivated: React.Dispatch<React.SetStateAction<boolean | null>>;
  selectedRole: string;
  setSelectedRole: React.Dispatch<React.SetStateAction<string>>;
};

const AdminUserInformation = ({
  isEdited,
  isActivated,
  setIsActivated,
  selectedRole,
  setSelectedRole,
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get("idx");

  const adminUserDetailQuery = useAdminUserDetailQuery(idx);
  const adminUserDetail = adminUserDetailQuery.data;

  useEffect(() => {
    setIsActivated(
      adminUserDetail?.status === "Activate"
        ? true
        : adminUserDetail?.status === "Deactivate"
        ? false
        : adminUserDetail?.status === "Pending" && null
    );
    setSelectedRole(adminUserDetail?.role);
  }, [adminUserDetail]);

  const descriptionList = [
    { term: "Name", description: adminUserDetail?.name },
    { term: "Email", description: adminUserDetail?.email },
    {
      term: "Role",
      description: isEdited ? (
        <SelectRoleListBox
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          isActivated={isActivated}
        />
      ) : (
        adminUserDetail?.role
      ),
    },
    {
      term: "Status",
      description: isEdited ? (
        <ToggledSwitch isEnabled={isActivated} setIsEnabled={setIsActivated} />
      ) : (
        adminUserDetail?.status
      ),
    },
    {
      term: "Last Seen",
      description: adminUserDetail?.lastSeen ? adminUserDetail?.lastSeen : "-",
    },
  ];

  return (
    <div className="mt-8">
      <h2 className="pb-5 text-base font-semibold border-b border-solid border-gray-200">
        User Information
      </h2>
      {adminUserDetailQuery.isLoading || adminUserDetailQuery.isFetching ? (
        <DescriptionListSkeleton
          number={5}
          isLine={false}
          outPadding={"px-0 py-4"}
        />
      ) : adminUserDetailQuery.isError ? (
        <ErrorMessage>{adminUserDetailQuery.error.message}</ErrorMessage>
      ) : (
        <div className="mt-2">
          <dl>
            {descriptionList?.map((item) => (
              <div
                className="grid grid-cols-3 items-center gap-4 py-4 text-sm leading-6 font-medium"
                key={item.term}
              >
                <dt>{item.term}</dt>
                <dd className="col-span-2 sm:col-span-1">{item.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      )}
    </div>
  );
};

export default AdminUserInformation;
