import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { RootState } from '@app/store';

import { PagingHeadings, decodeJwtToken } from '@autosquare/common';

import AddProjectMemberForm from './components/AddProjectMemberForm';

const AddProjectMember = () => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue,
  );

  const userInProject =
    decodeJwtToken(accessToken)?.pr?.find(
      (pr) => pr.idx === parseInt(projectIdx),
    ).role === 0;

  const breadcrumbs = [
    { title: 'Members', href: `/projects/${projectIdx}/members/list` },
    { title: 'Add', href: pathname },
  ];

  return (
    <>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Add Member'} />
      {userInProject ? (
        <AddProjectMemberForm />
      ) : (
        <div className="flex flex-col justify-center items-center h-[calc(100vh-15rem)] m-auto w-[25rem]">
          <div className="text-error-message text-lg font-bold">
            멤버 추가 권한이 없습니다.
          </div>
          <Link to={'/projects'}>
            <button
              type="button"
              className="flex items-center h-[2.25rem] my-[1rem] px-[0.75rem] bg-congress-blue text-sm text-white font-semibold rounded-xl"
            >
              프로젝트 페이지 이동
            </button>
          </Link>
        </div>
      )}
    </>
  );
};

export default AddProjectMember;
