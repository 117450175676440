import { Button } from '@autosquare/common';

import React, { useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { useMobileDeleteDriverMutation } from '@lib/api/ide/mobile/mobileDevice';
import { useProjectDetailQuery } from '@lib/api/ide/project/project';

import { useGetRefreshToken } from '@hooks/electron/useGetRefreshToken';

import MobileTestStepProvider from '@contexts/ide/MobileTestStepProvider';

import MainTestStep from './components/MainTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

const MobileTestStep = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const projectDetailQuery = useProjectDetailQuery(projectIdx);
  const projectName = projectDetailQuery.data?.name;
  const location = isWordInLocationPathname('ui-test')
    ? 'ui-test'
    : 'speed-test';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { cookie } = useGetRefreshToken();

  const mobileDeleteDriverMutation = useMobileDeleteDriverMutation();

  useEffect(() => {
    return () => {
      mobileDeleteDriverMutation.mutate();
    };
  }, []);

  const testCaseDetailPage = `/ide/projects/${projectIdx}/${location}/mobile/test-case/detail?idx=${idx}`;
  const breadcrumbsTitle = location === 'ui-test' ? 'UI Test' : 'Speed Test';

  const breadcrumbs = [
    {
      title: projectName,
      href: '/ide/projects/list',
    },
    {
      title: breadcrumbsTitle,
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    {
      title: 'Mobile',
      href: `${pathname.split('/').slice(0, -1).join('/')}/test-case`,
    },
    { title: 'Test Case', href: testCaseDetailPage },
    { title: 'Test Step', href: pathname + search },
  ];

  return (
    <MobileTestStepProvider>
      <div className="relative flex h-dvh max-w-full flex-col overflow-hidden">
        <div className="mx-4 flex justify-between py-4">
          <div className="grid content-center">
            <div>
              <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" className="flex items-center space-x-4">
                  {breadcrumbs?.map((breadcrumb, index) => (
                    <li key={index} className="flex items-center">
                      <button
                        type="button"
                        className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        onClick={() => navigate(breadcrumb.href)}
                      >
                        {breadcrumb.title}
                      </button>
                      <ChevronRightIcon
                        className={`size-5 shrink-0 text-gray-400 ${
                          breadcrumbs.length - 1 === index && 'hidden'
                        }`}
                        aria-hidden="true"
                      />
                    </li>
                  ))}
                </ol>
              </nav>
            </div>
          </div>
          <div className="px-0">
            <Button
              type="button"
              variant="primary"
              onClick={() => navigate(testCaseDetailPage)}
            >
              Close
            </Button>
          </div>
        </div>
        <MainTestStep />
      </div>
    </MobileTestStepProvider>
  );
};

export default MobileTestStep;
