import { Checkbox, TableBodyBasic, Detail } from '@autosquare/common';

import React, { useState } from 'react';

import {
  findWebCommandNameFromServerName,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';
import { findBrowserKeyNameFromServerName } from '@utils/static/browserKeyList';
import { useDispatch } from 'react-redux';
import { getTestStepIdx } from '@store/ide/webTestStepDetail/webTestStepDetailSlice';
import TestStepDetail from './WebTestStepTableBodyRow/TestStepDetail';
import { WebTestStepData } from 'src/types/testStep/type';
import { Draggable } from 'react-beautiful-dnd';
import { useWebTestStepListQuery } from '@lib/api/ide/web/webTestStep';
import { useSearchParams } from 'react-router-dom';
import {
  findKeyboardEventNameFromServerName,
  KeyboardEventServerName,
} from '@utils/static/keyboardEventList';

type Props = {
  testStepList: WebTestStepData;
  index: number;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
};

const WebTestStepTableBodyRow = ({
  testStepList,
  index,
  selectArray,
  selectedArray,
}: Props) => {
  const [searchParams] = useSearchParams();
  const Idx = searchParams.get('idx');
  const webTestStepListQuery = useWebTestStepListQuery(Idx);
  const webTestStepList = webTestStepListQuery.data;

  const testStepListValue =
    testStepList.command === WebCommandOptionServerName.GetUrl ||
    testStepList.command === WebCommandOptionServerName.ImportTestCase
      ? testStepList.value
      : testStepList.command === WebCommandOptionServerName.KeyBoardEvent
        ? findKeyboardEventNameFromServerName(
            testStepList.value as KeyboardEventServerName,
          )
        : testStepList.command === WebCommandOptionServerName.BrowserKey
          ? findBrowserKeyNameFromServerName(testStepList.value)
          : testStepList.command === WebCommandOptionServerName.IF
            ? testStepList.optionsDto.conditionValue
            : testStepList.command === WebCommandOptionServerName.ScrollAndSwipe
              ? `[${testStepList.value}]
Repeat=${testStepList.optionsDto.loop}`
              : testStepList.optionsDto.compareBy === 'id'
                ? `${testStepList.value}\ntarget text=#${
                    webTestStepList
                      .map((item) => item.idx)
                      .indexOf(Number(testStepList.optionsDto.compareIdx)) + 1
                  }`
                : testStepList.optionsDto.compareBy === 'text'
                  ? `${testStepList.value}\ntarget text="${testStepList.optionsDto.compareText}"`
                  : `${testStepList.value}`;

  const dispatch = useDispatch();
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <Draggable draggableId={testStepList.idx.toString()} index={index}>
      {(provided) => (
        <tr
          className={`group h-full hover:bg-gray-50 [&>*:nth-child(4)]:whitespace-pre-wrap`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TableBodyBasic>
            <Checkbox
              checked={selectedArray.includes(testStepList.idx)}
              onChange={(e) => selectArray(testStepList.idx, e)}
            />
          </TableBodyBasic>
          <TableBodyBasic>{index + 1}</TableBodyBasic>
          <TableBodyBasic>
            {findWebCommandNameFromServerName(testStepList.command)}
          </TableBodyBasic>
          <TableBodyBasic isBreakAll={true}>{testStepListValue}</TableBodyBasic>
          <TableBodyBasic>{testStepList.description}</TableBodyBasic>
          <TableBodyBasic>{testStepList.required ? 'Y' : 'N'}</TableBodyBasic>
          <TableBodyBasic>
            <Detail
              onClick={() => {
                dispatch(getTestStepIdx(testStepList.idx));
                setIsDialogOpened(true);
              }}
              isHoverEnabled={false}
            />
            <TestStepDetail
              isDialogOpened={isDialogOpened}
              setIsDialogOpened={setIsDialogOpened}
              testStepDetailListValue={testStepListValue}
            />
          </TableBodyBasic>
        </tr>
      )}
    </Draggable>
  );
};

export default WebTestStepTableBodyRow;
