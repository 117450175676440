import React, { useEffect, useState } from 'react';
import { Position, Rnd, ResizableDelta } from 'react-rnd';
import { ResizeDirection } from 're-resizable';

import MobileTestExecutionDetail from './components/MobileTestExecutionDetail';
import MobileTestExecution from './components/MobileTestExecution';
import ModalExecutionFrame from './components/ModalExecutionFrame';
import WebTestExecution from './components/WebTestExecution';
import WebTestExecutionDetail from './components/WebTestExecutionDetail';

import TestExecutionProvider from '@contexts/ide/TestExecutionProvider';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import SettingsAndHelpModal from '@components/SettingsAndHelpModal/SettingsAndHelpModal';
import SpeedMobileTestExecution from './components/SpeedMobileTestExecution';
import SpeedMobileTestExecutionDetail from './components/SpeedMobileTestExecutionDetail';
import SpeedWebTestExecution from './components/SpeedWebTestExecution';
import SpeedWebTestExecutionDetail from './components/SpeedWebTestExecutionDetail';
import MobileDistributedExecution from './components/MobileDistributedExecution';
import { FormProvider, useForm } from 'react-hook-form';
import { SpeedMobileCaseExecution } from '@customTypes/speedTestExecution/speedTestExecution';
import MobileDistributedExecutionDetail from './components/MobileDistributedExecutionDetail';
import { useDispatch } from 'react-redux';
import { resetExecutionItem } from '@store/ide/device/distributionExecutionSlice';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { PlatFormTypeServerName } from '@autosquare/common';
import { resetWebExecutionData } from '@store/ide/browser/selectedWebExecutionDataSlice';
import AddDeviceInModal from '@components/AddDeviceInModal/AddDeviceInModal';

interface Props {
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  testType: TestTypeCategory;
  deviceType: 'mobile' | 'web';
  idx?: number;
  mobileOS?: DeviceInfoOs;
  platformType?: PlatFormTypeServerName;
  testLocation?: 'ui-test' | 'speed-test';
  isDistributedClicked?: boolean;
}

const TestExecutionContainer = ({
  setState,
  idx,
  testType,
  deviceType,
  mobileOS,
  platformType,
  testLocation = 'ui-test',
  isDistributedClicked = false,
}: Props) => {
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);
  const [isWirelessDeviceModal, setIsWirelessDeviceModal] = useState(false);
  const [connectionType, setConnectionType] = useState<'wifi' | 'port'>('wifi');
  const [divWidth, setDivWidth] = useState<number>(820);
  const [divHeight, setDivHeight] = useState<number>(480 + 28);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [position, setPosition] = useState({
    x: window.innerWidth / 2 - divWidth / 2,
    y: window.innerHeight / 2 - divHeight / 2,
  });

  const methods = useForm<SpeedMobileCaseExecution>();

  const dispatch = useDispatch();

  const handleDragStop = (data: { x: number; y: number }) => {
    setPosition({ x: data.x, y: data.y });
  };

  const handleResizeStop = (
    _: MouseEvent | TouchEvent,
    __: ResizeDirection,
    ___: HTMLElement,
    d: ResizableDelta,
    p: Position,
  ) => {
    setDivWidth((prevWidth) => prevWidth + d.width);
    setDivHeight((prevHeight) => prevHeight + d.height);
    setPosition({ x: p.x, y: p.y });
  };

  const maximizeWindow = () => {
    setPosition({ x: 20, y: 20 });
    setDivWidth(window.innerWidth - 40);
    setDivHeight(window.innerHeight - 40);
  };

  const closeModalExecution = () => {
    setState(false);
  };

  useEffect(() => {
    return () => {
      dispatch(resetExecutionItem());
      dispatch(resetWebExecutionData());
    };
  }, []);
  return (
    <>
      <TestExecutionProvider>
        <div className="relative z-50">
          <div className="fixed inset-0 bg-black/30 backdrop-blur-sm">
            <div className="h-dvh">
              <Rnd
                size={{ width: divWidth, height: divHeight }}
                minWidth={820}
                minHeight={480}
                position={position}
                onDragStop={(_, d) => {
                  handleDragStop(d);
                }}
                onResizeStop={handleResizeStop}
                bounds="window"
                dragHandleClassName="draggable-handle"
              >
                <div
                  className={`h-[calc(100%-2rem)] w-full rounded-md bg-white`}
                >
                  <ModalExecutionFrame
                    closeModalExecution={closeModalExecution}
                    maximizeWindow={maximizeWindow}
                  />
                  <div className="gray-scrollbar flex h-full flex-col gap-8 p-4 ">
                    {deviceType === 'mobile' &&
                    testLocation === 'ui-test' &&
                    isDistributedClicked ? (
                      isReadyToNavigate ? (
                        <MobileDistributedExecutionDetail
                          testType={testType}
                          testLocation={testLocation}
                          closeModalExecution={closeModalExecution}
                        />
                      ) : isWirelessDeviceModal ? (
                        <AddDeviceInModal
                          connectionType={connectionType}
                          setState={setIsWirelessDeviceModal}
                          testType={testType}
                        />
                      ) : (
                        <MobileDistributedExecution
                          testType={testType}
                          divWidth={divWidth}
                          setIsReadyToNavigate={setIsReadyToNavigate}
                          setIsSettingsOpen={setIsSettingsOpen}
                          setIsWirelessDeviceModal={setIsWirelessDeviceModal}
                          setConnectionType={setConnectionType}
                        />
                      )
                    ) : deviceType === 'mobile' &&
                      testLocation === 'ui-test' &&
                      !isDistributedClicked ? (
                      isReadyToNavigate ? (
                        <MobileTestExecutionDetail
                          idx={idx}
                          closeModalExecution={closeModalExecution}
                          testType={testType}
                          testLocation={testLocation}
                        />
                      ) : isWirelessDeviceModal ? (
                        <AddDeviceInModal
                          connectionType={connectionType}
                          setState={setIsWirelessDeviceModal}
                          testType={testType}
                          isDistributedClicked={false}
                        />
                      ) : (
                        <MobileTestExecution
                          idx={idx}
                          setIsReadyToNavigate={setIsReadyToNavigate}
                          testType={testType}
                          divWidth={divWidth}
                          mobileOS={mobileOS}
                          setIsSettingsOpen={setIsSettingsOpen}
                          testLocation={testLocation}
                          setIsWirelessDeviceModal={setIsWirelessDeviceModal}
                          setConnectionType={setConnectionType}
                        />
                      )
                    ) : deviceType === 'web' && testLocation === 'ui-test' ? (
                      isReadyToNavigate ? (
                        <WebTestExecutionDetail
                          idx={idx}
                          closeModalExecution={closeModalExecution}
                          testType={testType}
                        />
                      ) : (
                        <WebTestExecution
                          idx={idx}
                          setIsReadyToNavigate={setIsReadyToNavigate}
                          testType={testType}
                          divWidth={divWidth}
                        />
                      )
                    ) : deviceType === 'mobile' &&
                      testLocation === 'speed-test' ? (
                      <FormProvider {...methods}>
                        {isReadyToNavigate ? (
                          <SpeedMobileTestExecutionDetail
                            idx={idx}
                            closeModalExecution={closeModalExecution}
                            platformType={platformType}
                            testType={testType}
                          />
                        ) : isWirelessDeviceModal ? (
                          <AddDeviceInModal
                            connectionType={connectionType}
                            setState={setIsWirelessDeviceModal}
                            testType={testType}
                            isDistributedClicked={false}
                          />
                        ) : (
                          <SpeedMobileTestExecution
                            idx={idx}
                            setIsReadyToNavigate={setIsReadyToNavigate}
                            testType={testType}
                            divWidth={divWidth}
                            setIsSettingsOpen={setIsSettingsOpen}
                            setIsWirelessDeviceModal={setIsWirelessDeviceModal}
                            setConnectionType={setConnectionType}
                          />
                        )}
                      </FormProvider>
                    ) : isReadyToNavigate ? (
                      <SpeedWebTestExecutionDetail
                        testType={testType}
                        closeModalExecution={closeModalExecution}
                      />
                    ) : (
                      <SpeedWebTestExecution
                        idx={idx}
                        setIsReadyToNavigate={setIsReadyToNavigate}
                        testType={testType}
                        divWidth={divWidth}
                      />
                    )}
                  </div>
                </div>
              </Rnd>
            </div>
          </div>
        </div>
      </TestExecutionProvider>
      {isSettingsOpen && (
        <SettingsAndHelpModal
          setState={setIsSettingsOpen}
          type="settings"
          menu="iOS Test"
        />
      )}
    </>
  );
};

export default TestExecutionContainer;
