import { IDEAPI } from '@api/ideEndpoints';
import { axiosLocalInstance } from '..';
import {
  SpeedMobileCaseExecution,
  SpeedMobileExecution,
  SpeedMobileScenarioExecution,
} from '@customTypes/speedTestExecution/speedTestExecution';
import { useMutation } from '@tanstack/react-query';

const mobileSpeedTestExecutionUrl = IDEAPI.RUN_SPEED_APP_TEST_STEP;

//POST
const speedExecutionMobileTestStep = async (
  cookie: string,
  data: SpeedMobileExecution,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${mobileSpeedTestExecutionUrl}command_app_speed`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useSpeedExecutionMobileTestStepMutation = (
  cookie: string,
  data: SpeedMobileExecution,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => speedExecutionMobileTestStep(cookie, data),
  });
};

//mobile app
const speedExecutionMobileTestCase = async (
  cookie: string,
  data: SpeedMobileCaseExecution,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${mobileSpeedTestExecutionUrl}unit_app_speed`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};

export const useSpeedExecutionMobileTestCaseMutation = (
  cookie: string,
  data: SpeedMobileCaseExecution,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => speedExecutionMobileTestCase(cookie, data),
  });
};

//mobile web
const speedExecutionMobileWebTestCase = async (
  cookie: string,
  data: SpeedMobileCaseExecution,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${mobileSpeedTestExecutionUrl}unit_web_speed`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};

export const useSpeedExecutionMobileWebTestCaseMutation = (
  cookie: string,
  data: SpeedMobileCaseExecution,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => speedExecutionMobileWebTestCase(cookie, data),
  });
};

// scenario
const speedExecutionMobileTestScenario = async (
  cookie: string,
  data: SpeedMobileScenarioExecution,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${mobileSpeedTestExecutionUrl}scenario_speed`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};

export const useSpeedExecutionMobileTestScenarioMutation = (cookie: string) => {
  return useMutation<unknown, Error, SpeedMobileScenarioExecution>({
    mutationFn: (data) => speedExecutionMobileTestScenario(cookie, data),
  });
};
