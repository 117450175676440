import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';

import { UseDeleteTestStepMutation } from '@lib/api/ide/mobile/mobileTestStep';

import React, { useContext } from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { MobileTestStepDetailContext } from '@contexts/ide/MobileTestStepDetailProvider';

type Props = {
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: () => void;
  closeDialog: () => void;
  openModal: () => void;
  uiTestDeleteStepMutation: UseMutationResult<
    UseDeleteTestStepMutation,
    Error,
    void,
    unknown
  >;
  speedTestDeleteStepMutation: UseMutationResult<
    { stepIdxList: number[]; mobileOs: string },
    Error,
    { stepIdxList: number[]; mobileOs: string }
  >;
  mobileStepIdx: number;
};

const DeleteDialog = ({
  isModalOpened,
  setIsModalOpened,
  closeModal,
  closeDialog,
  openModal,
  uiTestDeleteStepMutation,
  speedTestDeleteStepMutation,
  mobileStepIdx,
}: Props) => {
  const isUiTestLocation = isWordInLocationPathname('ui-test');
  const deleteTestStepMutation = isUiTestLocation
    ? uiTestDeleteStepMutation
    : speedTestDeleteStepMutation;

  const { mobileOS } = useContext(MobileTestStepDetailContext);
  return (
    <DoubleCheckDialogHeadlessUI
      isOpened={isModalOpened}
      setIsOpened={setIsModalOpened}
      type={'caution'}
      title={deleteTestStepMutation.isError ? 'Error' : 'Delete Test Step'}
      subTitle={
        deleteTestStepMutation.isError
          ? deleteTestStepMutation.error.message
          : isUiTestLocation
            ? '해당 테스트 스텝을 삭제하시겠습니까?'
            : `해당 테스트 스텝을 삭제하시겠습니까?\n설정한 Repeat Step 옵션도 같이 삭제 처리됩니다.`
      }
      buttonChildren={
        <>
          {!deleteTestStepMutation.isError && (
            <Button
              type="button"
              variant="caution"
              onClick={() => {
                isUiTestLocation
                  ? uiTestDeleteStepMutation.mutate(undefined, {
                      onSuccess: () => {
                        closeModal();
                        closeDialog();
                      },
                      onError: () => {
                        openModal();
                      },
                    })
                  : speedTestDeleteStepMutation.mutate(
                      {
                        stepIdxList: [mobileStepIdx],
                        mobileOs: mobileOS,
                      },
                      {
                        onSuccess: () => {
                          closeModal();
                          closeDialog();
                        },
                        onError: () => {
                          openModal();
                        },
                      },
                    );
              }}
            >
              Delete
            </Button>
          )}
          <Button type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </>
      }
    />
  );
};

export default DeleteDialog;
