import clsx from 'clsx';
import React from 'react';

interface Props {
  imageWidth: number;
  imageHeight: number;
  properties: {
    labelTitle: string;
    maxSize: number;
    value: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }[];
  isHiddenInputArrow?: boolean;
}

const PointOrRectangleInput = ({
  imageWidth,
  imageHeight,
  properties,
  isHiddenInputArrow = false,
}: Props) => {
  return (
    <div className="text-sm">
      <div className="mb-4 grid w-full grid-cols-4 gap-4 text-center">
        <p>Width</p>
        <p>{imageWidth}</p>
        <p>Height</p>
        <p>{imageHeight}</p>
      </div>
      <div className="grid w-full grid-cols-4 gap-4 text-center">
        {properties?.map((property, index) => (
          <div key={index}>
            <label htmlFor={property.labelTitle}>{property.labelTitle}</label>
            <input
              type="number"
              name={property.labelTitle}
              id={property.labelTitle}
              min={0}
              max={property?.maxSize}
              className={clsx(
                'mt-2 w-full rounded-md border border-solid border-gray-300 px-3 py-1 text-center text-sm',
                isHiddenInputArrow && inputNumberArrowHide,
              )}
              onChange={property.onChange}
              value={property.value || ''}
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              required
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PointOrRectangleInput;

const inputNumberArrowHide =
  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none';
