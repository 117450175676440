import {
  DefaultSkeleton,
  ErrorMessage,
  ScenarioListDetailData,
} from '@autosquare/common';

import React from 'react';

import { useWebTestCaseDetailQuery } from '@lib/api/ide/web/webTestCase';
import { useWebTestScenarioDetailQuery } from '@lib/api/ide/web/webTestScenario';
import { WebTestCaseDetailData } from '@customTypes/testCase/type';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

interface Props {
  idx: number;
  testType: TestTypeCategory;
}

const WebExecutionInfo = ({ idx, testType }: Props) => {
  const webExecutionQuery =
    testType === 'case'
      ? useWebTestCaseDetailQuery(idx.toString())
      : useWebTestScenarioDetailQuery(idx.toString());
  const webExecution = webExecutionQuery.data;

  return (
    <div className="px-0">
      <h3 className="text-base font-semibold leading-7 text-gray-900">
        Execution Info
      </h3>
      <div className="mt-4">
        {webExecutionQuery.isError ? (
          <ErrorMessage>{webExecutionQuery.error.message}</ErrorMessage>
        ) : (
          <dl className="grid grid-cols-2">
            <div className="col-span-1 border-t border-gray-100 px-0 py-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Title
              </dt>
              <dd className="mt-2 text-sm leading-6 text-gray-700">
                {webExecutionQuery.isLoading ? (
                  <DefaultSkeleton
                    number={1}
                    outMargin={'m-0'}
                    margin={'m-0'}
                  />
                ) : (
                  webExecution?.title
                )}
              </dd>
            </div>
            <div className="col-span-1 border-t border-gray-100 px-0 py-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Execution Count
              </dt>
              <dd className="mt-2 text-sm leading-6 text-gray-700">
                {webExecutionQuery.isLoading ? (
                  <DefaultSkeleton
                    number={1}
                    outMargin={'m-0'}
                    margin={'m-0'}
                  />
                ) : testType === 'case' ? (
                  (webExecution as WebTestCaseDetailData)?.stepCount
                ) : (
                  (webExecution as ScenarioListDetailData)?.caseCount
                )}
              </dd>
            </div>
          </dl>
        )}
      </div>
    </div>
  );
};

export default WebExecutionInfo;
