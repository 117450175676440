import {
  CreateMobileSpeedTestScenarioData,
  UpdateMobileSpeedTestScenarioData,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  title: string;
  id:
    | keyof CreateMobileSpeedTestScenarioData
    | keyof UpdateMobileSpeedTestScenarioData;
  register: UseFormRegisterReturn;
  errors: FieldErrors;
};

const InformationInput = ({ register, errors, title, id }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <label
        htmlFor={id}
        className="text-sm font-medium leading-normal text-gray-900"
      >
        {title}
      </label>
      <input type="text" id={id} className="input-base" {...register} />
      <ErrorMessage
        errors={errors}
        name={id}
        render={({ message }) => (
          <p className="standard-error-message py-0">{message}</p>
        )}
      />
    </div>
  );
};

export default InformationInput;
