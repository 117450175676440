import React from "react";
import dayjs from "dayjs";

const CheckUpdateDate = () => {
  const buildDate = dayjs(VITE_BUILD_DATE).format().split("T")[0];

  const buildTime = dayjs(VITE_BUILD_DATE).format().split("T")[1].split("+")[0];

  return (
    <>
      <p className="text-3xl">v{VITE_APP_VERSION}-Dashboard</p>
      <p className="text-3xl">
        Release Date: {buildDate} {buildTime}
      </p>
    </>
  );
};

export default CheckUpdateDate;
