import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  TestCaseDetailSkeleton,
} from '@autosquare/common';

import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';

import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

import { RootState } from '@app/store';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  useDeleteTestCaseMutation,
  useMobileTestCaseDetailQuery,
  useUpdateMobileTestCaseMutation,
  useCopyTestCaseMutation,
} from '@lib/api/ide/mobile/mobileTestCase';

import {
  PriorityNameEnum,
  TestCaseDetailData,
  UpdateMobileTestCaseData,
} from '@customTypes/testCase/type';

import TestCaseInfoDefault from './TestCaseInfo/TestCaseInfoDefault';
import TestCaseInfoForm from './TestCaseInfo/TestCaseInfoForm';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';

type Props = {
  idx: string;
};

const TestCaseInfo = ({ idx }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  const [isEdited, setIsEdited] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isCopyModalOpened, setIsCopyModalOpened] = useState(false);
  const [isCopyCompleteModalOpened, setIsCopyCompleteModalOpened] =
    useState(false);
  const [copiedTestCaseIdx, setCopiedTestCaseIdx] = useState<string | null>(
    null,
  );

  const mobileTestCaseDetailQuery = useMobileTestCaseDetailQuery(idx);
  const mobileTestCaseDetail = mobileTestCaseDetailQuery.data;

  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );

  const method = useForm<UpdateMobileTestCaseData>({
    defaultValues: {
      title: '',
      description: '',
      priority: PriorityNameEnum.Medium,
      platformType: PlatFormTypeServerName.Default,
      caseGroupIdx: undefined,
    },
  });

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const openCopyModal = () => {
    setIsCopyModalOpened(true);
  };

  const closeCopyModal = () => {
    setIsCopyModalOpened(false);
  };

  const openEditTestCase = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEdited(true);
    deleteTestCaseMutation.reset();
  };

  const closeEditTestCase = () => {
    setIsEdited(false);
  };

  const updateMobileTestCaseMutation = useUpdateMobileTestCaseMutation({
    idx: idx,
  });

  const onSubmit: SubmitHandler<Partial<TestCaseDetailData>> = (data) => {
    updateMobileTestCaseMutation.mutate(data, {
      onSuccess: () => {
        setIsEdited(false);
      },
    });
  };

  const deleteTestCaseMutation = useDeleteTestCaseMutation();

  const copyTestCaseMutation = useCopyTestCaseMutation();

  const handleCopyTestCase = () => {
    if (!projectIdx) {
      console.error('Project ID is undefined');
      return;
    }

    copyTestCaseMutation.mutate(
      { idx: idx, projectIdx: projectIdx },
      {
        onSuccess: (data: TestCaseDetailData) => {
          closeCopyModal();
          setCopiedTestCaseIdx(data.idx.toString());
          setIsCopyCompleteModalOpened(true);
        },
        onError: (error) => {},
      },
    );
  };

  const handleGoToCopiedCase = () => {
    if (copiedTestCaseIdx) {
      navigate(
        `/ide/projects/${projectIdx}/ui-test/mobile/test-case/detail?idx=${copiedTestCaseIdx}`,
      );
    }
    setIsCopyCompleteModalOpened(false);
  };

  return (
    <FormProvider {...method}>
      <form onSubmit={method.handleSubmit(onSubmit)}>
        <div className="mt-8 pb-5 sm:flex sm:items-center sm:justify-between">
          <h2 className="text-base font-semibold leading-6 text-gray-900">
            Test Case Information
          </h2>
          <div className="mt-3 flex gap-2 sm:ml-4 sm:mt-0">
            {isEdited ? (
              <>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={closeEditTestCase}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button type="button" variant="primary" onClick={openCopyModal}>
                  Copy
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={openEditTestCase}
                >
                  Edit
                </Button>
                <Button type="button" variant="caution" onClick={openModal}>
                  Delete
                </Button>
              </>
            )}
          </div>
        </div>
        <ErrorMessage>
          {updateMobileTestCaseMutation.isError
            ? updateMobileTestCaseMutation.error.message
            : deleteTestCaseMutation.isError &&
              deleteTestCaseMutation.error.message}
        </ErrorMessage>
        {mobileTestCaseDetailQuery.isLoading ||
        mobileTestCaseDetailQuery.isFetching ? (
          <TestCaseDetailSkeleton testCategory={'ui'} platform={'mobile'} />
        ) : isEdited ? (
          <TestCaseInfoForm />
        ) : (
          <TestCaseInfoDefault mobileTestCaseDetail={mobileTestCaseDetail} />
        )}
      </form>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={'Delete Test Case'}
        subTitle={'해당 테스트 케이스를 삭제하시겠습니까?'}
        buttonChildren={
          <>
            <Button
              type="button"
              variant="caution"
              onClick={() =>
                deleteTestCaseMutation.mutate(
                  {
                    idx_list: [Number(idx)],
                  },
                  {
                    onSuccess: () => {
                      closeModal();
                      navigate({
                        pathname: `/ide/projects/${projectIdx}/ui-test/mobile/test-case`,
                        search: 'page=1',
                      });
                    },
                  },
                )
              }
            >
              Delete
            </Button>
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </>
        }
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isCopyModalOpened}
        setIsOpened={setIsCopyModalOpened}
        type={'checked'}
        title={'Copy Test Case'}
        subTitle={'해당 테스트 케이스를 복사하시겠습니까?'}
        buttonChildren={
          <>
            <Button
              type="button"
              variant="primary"
              onClick={handleCopyTestCase}
            >
              Copy
            </Button>
            <Button type="button" variant="secondary" onClick={closeCopyModal}>
              Cancel
            </Button>
          </>
        }
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isCopyCompleteModalOpened}
        setIsOpened={setIsCopyCompleteModalOpened}
        type={'checked'}
        title={'Test case copy complete'}
        subTitle={'테스트 케이스 복사가 완료되었습니다.'}
        buttonChildren={
          <>
            <Button
              type="button"
              variant="primary"
              onClick={handleGoToCopiedCase}
            >
              Go to copied case
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setIsCopyCompleteModalOpened(false)}
            >
              Close
            </Button>
          </>
        }
      />
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
    </FormProvider>
  );
};

export default TestCaseInfo;
