import { ClipBoardButton } from '@autosquare/common';

import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';

import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { ClickId } from '@utils/static/clickOptions';
import { MobileTestStepDataOptionsDto } from '@customTypes/ide/mobileTestStep/mobileTestStep';

type Props = {
  valueText: string;
  commandText: string;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
  optionsDto: MobileTestStepDataOptionsDto;
  saveTextIndex: number;
  findTitleFromIdx: (idx: number) => string;
};

const PropertyValue = ({
  valueText,
  commandText,
  setIsCopied,
  optionsDto,
  saveTextIndex,
  findTitleFromIdx,
}: Props) => {
  const [imageWidth, setImageWidth] = useState<number>(null);
  const [imageHeight, setImageHeight] = useState<number>(null);

  const divRef = useRef<HTMLDivElement>(null);

  // Mobile Image konva
  const MobileImage = () => {
    const [image] = useImage(`data:image/png;base64,${valueText}`);

    useEffect(() => {
      const divWidth = divRef.current?.clientWidth;

      // eslint-disable-next-line prefer-const
      let imageObj = new window.Image();
      imageObj.src = `data:image/png;base64,${valueText}`;
      setImageWidth(
        imageObj.width / 3 < divWidth ? imageObj.width / 3 : divWidth,
      );
      setImageHeight(
        imageObj.width / 3 < divWidth
          ? imageObj.height / 3
          : imageObj.height * (divWidth / imageObj.width),
      );
    }, []);

    return <Image image={image} width={imageWidth} height={imageHeight} />;
  };

  const handleCopy = (valueText: string) => {
    window.navigator.clipboard.writeText(valueText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };
  const showViewValue =
    commandText === MobileCommandOptionServerName.ScrollSwipe ||
    (commandText === MobileCommandOptionServerName.Click &&
      valueText?.split(',').length >= 2 &&
      optionsDto.clickBy === ClickId.Coordinate)
      ? `[${valueText}]`
      : commandText === MobileCommandOptionServerName.DeviceKey
        ? findListNameFromServerName(valueText as DeviceKeyServerName)
        : commandText === MobileCommandOptionServerName.Loop
          ? findMobileCommandNameFromServerName(
              valueText as MobileCommandOptionServerName,
            )
          : (commandText === MobileCommandOptionServerName.ConfirmText ||
                commandText === MobileCommandOptionServerName.InputText) &&
              optionsDto.compareBy === 'text' &&
              optionsDto.textEncrypt === 'true'
            ? `${valueText} \ntarget text="**********"`
            : (commandText === MobileCommandOptionServerName.ConfirmText ||
                  commandText === MobileCommandOptionServerName.InputText) &&
                optionsDto.compareBy === 'text' &&
                optionsDto.textEncrypt === 'false'
              ? `${valueText} \ntarget text="${optionsDto.compareText}"`
              : (commandText === MobileCommandOptionServerName.ConfirmText ||
                    commandText === MobileCommandOptionServerName.InputText) &&
                  optionsDto.compareBy === 'id'
                ? `${valueText} \ntarget text=#${saveTextIndex}`
                : commandText === MobileCommandOptionServerName.ImportTestCase
                  ? findTitleFromIdx(Number(valueText))
                  : valueText;

  return (
    <div className="grid grid-cols-3 px-3 py-2">
      <dt className="text-xs font-medium leading-6 text-gray-900">Value</dt>
      <div
        className={`col-span-2 text-xs leading-6 text-gray-700 ${
          ((commandText === MobileCommandOptionServerName.Click &&
            optionsDto.clickBy === ClickId.Image) ||
            commandText === MobileCommandOptionServerName.CompareImage) &&
          'flex items-center justify-center'
        }`}
        ref={divRef}
      >
        {(commandText === MobileCommandOptionServerName.Click &&
          optionsDto.clickBy === ClickId.Image) ||
        commandText === MobileCommandOptionServerName.CompareImage ? (
          <Stage width={imageWidth} height={imageHeight}>
            <Layer>
              <MobileImage />
            </Layer>
          </Stage>
        ) : (
          <div className="whitespace-pre-wrap break-all">
            {showViewValue}
            <ClipBoardButton onClick={() => handleCopy(showViewValue)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyValue;
