import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosFileInstance, axiosInstance } from '..';
import { API } from '@api/endpoints';

import { useParams, useSearchParams } from 'react-router-dom';
import {
  MobileSpeedTestResultData,
  MobileSpeedTestResultDetailData,
  MobileSpeedTestResultTableDetailData,
} from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { mobileSpeedTestResultKeys } from '@queries/dashboard/mobileSpeedTestResultKeys';

// GET
const getMobileSpeedTestResultList = async (
  projectIdx: string,
  page: number,
  size: number,
): Promise<MobileSpeedTestResultData> => {
  const response = await axiosInstance.get<MobileSpeedTestResultData>(
    API.getMobileSpeedTestResultList(projectIdx, page, size),
  );
  return response.data;
};

export const useGetMobileSpeedTestResultList = (page: number, size: number) => {
  const { projectIdx } = useParams();
  return useQuery<MobileSpeedTestResultData, Error>({
    queryKey: mobileSpeedTestResultKeys.list(projectIdx, page, size),
    queryFn: () => getMobileSpeedTestResultList(projectIdx, page, size),
  });
};

const speedMobileTestResultDetail = async (
  resultIdx: string,
  projectIdx: string,
  groupIdx: string,
  start: string,
  end: string,
): Promise<MobileSpeedTestResultDetailData> => {
  const response = await axiosInstance.get<MobileSpeedTestResultDetailData>(
    API.getMobileSpeedTestResultDetail(
      resultIdx,
      projectIdx,
      groupIdx,
      start,
      end,
    ),
  );
  return response.data;
};

export const useMobileSpeedResultDetailQuery = () => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const resultIdx = searchParams.get('result');
  const groupIdx = searchParams.get('group');
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  return useQuery<MobileSpeedTestResultDetailData, Error>({
    queryKey: mobileSpeedTestResultKeys.detail(resultIdx),
    queryFn: () =>
      speedMobileTestResultDetail(resultIdx, projectIdx, groupIdx, start, end),
  });
};

const speedMobileTestResultTableDetail = async (detailIdx: string) => {
  const response =
    await axiosInstance.get<MobileSpeedTestResultTableDetailData>(
      `${API.DETAIL_SPEED_MOBILE_TEST_RESULT_BY_IDX}${detailIdx}`,
    );
  return response.data;
};

export const useSpeedMobileTestResultTableDetailQuery = (
  detailIdx: string,
  options?: UseQueryOptions<MobileSpeedTestResultTableDetailData>,
) => {
  return useQuery<MobileSpeedTestResultTableDetailData, Error>({
    queryFn: () => speedMobileTestResultTableDetail(detailIdx),
    queryKey: mobileSpeedTestResultKeys.detail(detailIdx),
    ...options,
  });
};

//video
const speedMobileTestResultVideoData = async (
  path: string[],
): Promise<string[]> => {
  const requests = path.map((path) =>
    axiosFileInstance.get<MediaSource>(path, {
      responseType: 'blob',
    }),
  );

  const responses = await Promise.all(requests);
  const objectUrls = responses.map((response) =>
    URL.createObjectURL(response.data),
  );

  return objectUrls;
};

export const useSpeedMobileTestResultVideoData = (
  path: string[],
  chartIndex: number,
  options?: UseQueryOptions<string[], Error>,
) => {
  return useQuery<string[], Error>({
    queryKey: mobileSpeedTestResultKeys.detailVideo(path, chartIndex),
    queryFn: () => speedMobileTestResultVideoData(path),
    ...options,
  });
};

//log
const speedMobileTestResultLogData = async (path: string): Promise<string> => {
  const response = await axiosFileInstance.get<MediaSource | Blob>(path, {
    responseType: 'blob',
  });

  const objectUrl = URL.createObjectURL(response.data);
  return objectUrl;
};

export const useSpeedMobileTestResultLogData = (
  path: string,
  chartIndex: number,
  options?: UseQueryOptions<string, Error>,
) => {
  return useQuery<string, Error>({
    queryKey: mobileSpeedTestResultKeys.detailLog(path, chartIndex),
    queryFn: () => speedMobileTestResultLogData(path),
    ...options,
  });
};
