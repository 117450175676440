import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PatternPoint {
  uuid: string;
  pointsCoordinate: { x: number; y: number };
}

interface UnlockPatternState {
  points: PatternPoint[];
}

const initialState: UnlockPatternState = {
  points: [],
};

const unlockPatternSlice = createSlice({
  name: 'unlockPattern',
  initialState,
  reducers: {
    addPoint: (state, action: PayloadAction<PatternPoint>) => {
      state.points.push(action.payload);
    },
    updatePoint: (
      state,
      action: PayloadAction<{
        index: number;
        pointsCoordinate: { x: number; y: number };
      }>,
    ) => {
      const { index, pointsCoordinate } = action.payload;
      state.points[index].pointsCoordinate = pointsCoordinate;
    },
    deletePoint: (state, action: PayloadAction<string>) => {
      state.points = state.points.filter(
        (point) => point.uuid !== action.payload,
      );
    },
    clearPoints: (state) => {
      state.points = [];
    },
  },
});

export const { addPoint, updatePoint, deletePoint, clearPoints } =
  unlockPatternSlice.actions;

export default unlockPatternSlice.reducer;
