import { TooltipTop } from '@autosquare/common';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

import clsx from 'clsx';
import React, { ReactNode, useState } from 'react';

type Props = {
  title: string;
  children: ReactNode;
  isTitleCenter?: boolean;
  isTooltip?: boolean;
  tooltipClass?: string;
  tooltipWord?: string;
};

const SpeedSpeedSchedulerGridContainer = ({
  title,
  children,
  tooltipClass,
  tooltipWord,
  isTitleCenter = false,
  isTooltip = false,
}: Props) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  return (
    <div className={clsx('grid grid-cols-3 gap-4')}>
      {isTooltip ? (
        <div className="flex items-center gap-1">
          <p className={clsx(isTitleCenter && 'pt-2')}>{title}</p>
          <div className="relative">
            <QuestionMarkCircleIcon
              className="size-4 text-red-500"
              strokeWidth={2}
              onMouseEnter={() => setIsMouseEnter(true)}
              onMouseLeave={() => setIsMouseEnter(false)}
            />
            {isMouseEnter && (
              <TooltipTop positionClass={tooltipClass}>
                {tooltipWord}
              </TooltipTop>
            )}
          </div>
        </div>
      ) : (
        <p className={clsx(isTitleCenter && 'pt-2')}>{title}</p>
      )}

      <div className="col-span-2">{children}</div>
    </div>
  );
};

export default SpeedSpeedSchedulerGridContainer;
