import { SpeedTestCaseDataInScenario } from '@customTypes/speedTestCase/type';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';
import clsx from 'clsx';
import React, { useRef } from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
  XYCoord,
} from 'react-dnd';

type Props = {
  index: number;
  copiedTestCase: SpeedTestCaseDataInScenario;
  onRemove: (index: number) => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
};

const ScenarioTestCaseListCard = ({
  index,
  copiedTestCase,
  onRemove,
  moveCard,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'card',
    hover(item: { index: number }, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current!.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      if (item.index !== undefined) {
        item.index = hoverIndex;
      }
    },
  });

  drag(drop(ref));

  const detailList = copiedTestCase.platformType
    ? [
        {
          title: 'Platform',
          description:
            copiedTestCase.platformType === PlatFormTypeServerName.MobileApp
              ? 'App'
              : 'Web',
        },
        {
          title: 'Test Group',
          description: copiedTestCase.caseGroupInfo?.name || '-',
        },
        {
          title: 'Service Group',
          description: copiedTestCase.serviceGroupInfo?.name || '-',
        },
        {
          title: 'bin',
          deleteIcon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5 cursor-pointer text-gray-700"
              onClick={() => onRemove(index)}
            >
              <path
                fillRule="evenodd"
                d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
      ]
    : [
        {
          title: 'Test Group',
          description: copiedTestCase.caseGroupInfo?.name || '-',
        },
        {
          title: 'Service Group',
          description: copiedTestCase.serviceGroupInfo?.name || '-',
        },
        {
          title: 'bin',
          deleteIcon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5 cursor-pointer text-gray-700"
              onClick={() => onRemove(index)}
            >
              <path
                fillRule="evenodd"
                d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
      ];

  return (
    <div ref={ref} className="m-2 cursor-move">
      <Disclosure>
        <DisclosureButton
          className={clsx(
            'group flex w-full cursor-move items-center justify-between border border-solid border-gray-200 bg-gray-50 px-3 py-2',
            isDragging ? 'opacity-50' : 'opacity-100',
          )}
        >
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 9h16.5m-16.5 6.75h16.5"
              />
            </svg>
            <div className="ml-7">{copiedTestCase.title}</div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5 cursor-pointer group-data-[open]:rotate-180"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </DisclosureButton>
        <DisclosurePanel className="border-x border-b border-solid border-gray-200">
          {detailList.map((detail) => (
            <div
              key={detail.title}
              className={clsx(
                'px-3 py-2 odd:bg-gray-50',
                detail.deleteIcon ? 'flex justify-end' : 'grid grid-cols-3',
              )}
            >
              {detail.deleteIcon ?? (
                <>
                  <p className="flex h-6 items-center text-xs font-medium leading-normal text-gray-900">
                    {detail.title}
                  </p>
                  <p className="col-span-2 flex h-6 items-center break-words text-xs font-normal leading-normal text-gray-700">
                    {detail.description}
                  </p>
                </>
              )}
            </div>
          ))}
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
};

export default ScenarioTestCaseListCard;
