import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';

import { projectKeys } from '@queries/ide/projectKeys';
import {
  ProjectListDetail,
  ProjectMember,
} from '@customTypes/dashboard/project/type';
import { useParams } from 'react-router-dom';

// GET
const getProjectList = async (): Promise<ProjectListDetail[]> => {
  const reponse = await axiosInstance.get<ProjectListDetail[]>(
    IDEAPI.PROJECT_LIST,
  );
  return reponse.data;
};
export const useProjectListQuery = () =>
  useQuery<ProjectListDetail[], Error, ProjectListDetail[]>({
    queryKey: ['project', 'projectList'],
    queryFn: getProjectList,
  });

const getProjectDetail = async (
  projectIdx: string,
): Promise<ProjectListDetail> => {
  const response = await axiosInstance.get<ProjectListDetail>(
    IDEAPI.PROJECT_DETAIL + projectIdx,
  );
  return response.data;
};
export const useProjectDetailQuery = (
  projectIdx: string,
  options?: UseQueryOptions<ProjectListDetail>,
) =>
  useQuery<ProjectListDetail, Error>({
    queryKey: projectKeys.detail(projectIdx),
    queryFn: () => getProjectDetail(projectIdx),
    ...options,
  });

const projectMemberList = async (
  projectIdx: string,
): Promise<ProjectMember[]> => {
  const response = await axiosInstance.get<ProjectMember[]>(
    IDEAPI.getProjectMembers(projectIdx),
  );
  return response.data;
};
export const useProjectMemberListQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<ProjectMember[], Error>({
    queryKey: projectKeys.groupMemberList(projectIdx),
    queryFn: () => projectMemberList(projectIdx),
  });
};
