import React, { useContext, memo } from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

interface Props {
  screenshot: string;
  stageWidth: number;
  stageHeight: number;
  imageRef: React.MutableRefObject<Konva.Image>;
}

const MobileImage = memo(function MobileImage({
  screenshot,
  stageWidth,
  stageHeight,
  imageRef,
}: Props) {
  const { setImageWidth, setImageHeight } = useContext(MobileTestStepContext);

  const [image] = useImage(`data:image/png;base64,${screenshot}`);

  // eslint-disable-next-line prefer-const
  let imageObj = new window.Image();
  imageObj.src = `data:image/png;base64,${screenshot}`;
  setImageWidth(imageObj?.width);
  setImageHeight(imageObj?.height);

  return (
    <Image
      image={image}
      width={stageWidth}
      height={stageHeight}
      ref={imageRef}
    />
  );
});

export default MobileImage;
