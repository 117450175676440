import {
  ProjectListDetail,
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';

export const checkIsProjectFeatureIncluded = (
  projectDetail: ProjectListDetail,
  category: ProjectListDetailFeaturesCategory,
  feature: ProjectListDetailFeaturesFeature,
) =>
  projectDetail?.features.some(
    (item) => item.category === category && item.feature === feature,
  );

export const checkIsProjectFeatureIncludedAll = (
  projectDetail: ProjectListDetail,
  feature: ProjectListDetailFeaturesFeature,
) => projectDetail?.features.some((item) => item.feature === feature);
