import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import ProjectsSidebar from "./components/ProjectsSidebar";
import Administration from "./components/Administration";

const Sidebar = () => {
  const location = useLocation();

  const [isToggledSidebar, setIsToggledSidebar] = useState(false);

  const documentHeight = document.documentElement.scrollHeight;

  const inlineHeightStyle = () =>
    isToggledSidebar ? { height: `${documentHeight}px` } : { height: "auto" };

  return (
    <div
      className={`flex w-screen z-30 lg:w-auto ${
        isToggledSidebar && `absolute backdrop-blur-sm`
      }`}
      style={inlineHeightStyle()}
    >
      <aside
        className={`w-full bg-white lg:w-72 lg:h-full lg:pt-1 lg:border-r lg:border-gray-200 lg:fixed ${
          isToggledSidebar && "w-72"
        }`}
      >
        {location.pathname.includes("/admin") ? (
          <Administration
            isToggledSidebar={isToggledSidebar}
            setIsToggledSidebar={setIsToggledSidebar}
          />
        ) : (
          <ProjectsSidebar
            isToggledSidebar={isToggledSidebar}
            setIsToggledSidebar={setIsToggledSidebar}
          />
        )}
      </aside>
      {isToggledSidebar && (
        <div
          className="w-full bg-black/20 opacity-90"
          onClick={() => setIsToggledSidebar(false)}
        />
      )}
    </div>
  );
};

export default Sidebar;
