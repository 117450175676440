import React from 'react';

import { useSendAuthCode } from '@lib/api/dashboard/forgotAndResetPassword/forgotAndResetPassword';
import SendAuthCodeForm from './components/SendAuthCodeForm';
import ConfirmAuthCodeForm from './components/ConfirmAuthCodeForm';
import { useAuthCodeTimer } from '@hooks/resetPassword/useAuthCodeTimer';

const ForgotPassword = () => {
  const sendAuthCode = useSendAuthCode();
  const { minutes, seconds, resetTimer, resetErrorMessage, timerErrorMessage } =
    useAuthCodeTimer();

  return (
    <div className="mx-auto grid w-96 grid-rows-1">
      <div className="px-3 py-6">
        <h2 className="text-lg font-semibold">비밀번호 재설정</h2>
        <hr className="my-2 border-t border-solid border-black" />
        <p className="text-sm">
          본인확인을 위해 가입하신 이메일 주소를 입력해 주세요.
          <br />
          입력하신 이메일 주소로 인증번호를 보내드립니다.
        </p>
      </div>
      <SendAuthCodeForm
        sendAuthCode={sendAuthCode}
        resetTimer={resetTimer}
        resetErrorMessage={resetErrorMessage}
      />
      <ConfirmAuthCodeForm
        isSentSuccessfully={sendAuthCode.isSuccess}
        minutes={minutes}
        seconds={seconds}
        timerErrorMessage={timerErrorMessage}
      />
    </div>
  );
};

export default ForgotPassword;
