/* eslint-disable @typescript-eslint/no-unused-vars */
import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { UseMutationResult } from '@tanstack/react-query';
import { ClickId } from '@utils/static/clickOptions';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import {
  checkCoordinateRange,
  checkEmptyInput,
} from '@utils/static/mobileTestStep/checkCoordinateValidation/checkCoordinateValidation';
import {
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';

const useTestStepEditFormHandler = (
  methods: UseFormReturn<UpdateTestStepData, unknown, undefined>,
  updateTestStepMutation: UseMutationResult<
    unknown,
    Error,
    UpdateTestStepData,
    unknown
  >,
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const onSubmit: SubmitHandler<UpdateTestStepData> = (data) => {
    const {
      parsedValue,
      clickXValue,
      clickYValue,
      startX,
      startY,
      endX,
      endY,
      ...rest
    } = data;
    if (
      rest.command === MobileCommandOptionServerName.Loop &&
      rest.value !== MobileCommandOptionServerName.ScrollSwipe
    ) {
      rest.optionsDto.actionOptions.repeatLimit = undefined;
    }

    if (
      rest.optionsDto.compareText === '' &&
      rest.optionsDto.textEncrypt === 'true'
    ) {
      rest.optionsDto.compareText = null;
    }
    if (
      rest.optionsDto.compareBy === 'id' &&
      rest.optionsDto.compareIdx === undefined
    ) {
      methods.setError('optionsDto.compareIdx', {
        type: 'manual',
        message: 'Test Step으로 비교 시, 비교할 스텝의 idx가 필요합니다.',
      });
    } else if (
      rest.command === MobileCommandOptionServerName.Click &&
      rest.optionsDto.clickBy === ClickId.Default &&
      rest.optionsDto.textReplace === 'true' &&
      rest.optionsDto.textReplaceOptions.dateFormat === ''
    ) {
      methods.setError('optionsDto.textReplaceOptions.dateFormat', {
        type: 'manual',
        message: 'Date Format의 년, 월, 일 중 1가지 이상 선택해 주세요.',
      });
    } else if (
      rest.command === MobileCommandOptionServerName.UnlockPattern &&
      checkCoordinateRange(
        rest.optionsDto.screenSize,
        parsedValue,
        methods.setError,
        methods.setFocus,
      )
    ) {
      return;
    } else {
      updateTestStepMutation.mutate(rest, {
        onSuccess: () => {
          setIsEdited(false);
        },
      });
    }
  };

  const onError: SubmitErrorHandler<UpdateTestStepData> = (error) => {
    if (error.parsedValue) {
      if (error.parsedValue.type === 'range') {
        checkCoordinateRange(
          methods.getValues('optionsDto.screenSize'),
          methods.getValues('parsedValue'),
          methods.setError,
          methods.setFocus,
        );
      } else if (error.parsedValue.type === 'empty') {
        checkEmptyInput(
          methods.getValues('parsedValue'),
          methods.setError,
          methods.setFocus,
        );
      }

      if (methods.getValues('parsedValue').length == 2) {
        methods.clearErrors('parsedValue');
        methods.handleSubmit(onSubmit)();
      }
    }
  };

  return {
    onError,
    onSubmit,
  };
};
export default useTestStepEditFormHandler;
