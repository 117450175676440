import { RootState } from '@app/store';
import SpeedSchedulerBrowserListbox from '@components/Scheduler/SpeedSchedulerBrowserListbox';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';
import {
  BrowserInfo,
  SupportedBrowserList,
  SupportedBrowsers,
} from '@customTypes/ide/browser/browser';
import {
  SpeedSchedulerTypeServerName,
  UpdateSpeedSchedulerData,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorMessage } from '@hookform/error-message';
import { useSpeedSchedulerDetailQuery } from '@lib/api/ide/speedScheduler/speedScheduler';
import { useWebBrowserListQuery } from '@lib/api/ide/web/webBrowser';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const SpeedTestSchedulerDetailBrowser = () => {
  const {
    watch,
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateSpeedSchedulerData>();

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const [browserInfoDto, setBrowserInfoDto] = useState<BrowserInfo>(undefined);

  const { data: speedSchedulerDetail } = useSpeedSchedulerDetailQuery();

  const testType = speedSchedulerDetail?.type;
  const infos = watch('infos');

  const webBrowserListQuery = useWebBrowserListQuery({
    enabled: testType === SpeedSchedulerTypeServerName.Web,
  });

  // 초기값 설정
  useEffect(() => {
    if (testType === SpeedSchedulerTypeServerName.Web) {
      const [browserName, version] =
        speedSchedulerDetail.environment.split('/');
      const browserInfo: BrowserInfo = {
        browserName: browserName as SupportedBrowserList,
        version: version,
      };
      setValue('infos', browserInfo);
      setBrowserInfoDto(browserInfo);
    }
  }, [testType]);

  // 디바이스에 설치된 브라우저 리스트를 확인하여 browser 초기값 설정
  useEffect(() => {
    if (infos?.installedBrowserList?.includes(SupportedBrowsers.Chrome)) {
      setValue('infos.browser', SupportedBrowsers.Chrome);
    }

    if (infos?.installedBrowserList?.includes(SupportedBrowsers.Safari)) {
      setValue('infos.browser', SupportedBrowsers.Safari);
    }
  }, [infos]);

  // browser error 초기화
  useEffect(() => {
    if (browserInfoDto) {
      clearErrors('infos');
    }
  }, [clearErrors, browserInfoDto]);

  // 브라우저 정보 저장
  const handleChange = (selectedBrowser: BrowserInfo) => {
    setBrowserInfoDto(selectedBrowser);
    setValue('infos', selectedBrowser);
  };

  const mobileBrowserList = [
    {
      name: 'Chrome',
      value: SupportedBrowsers.Chrome,
      disabled:
        !infos?.serialNumber ||
        !infos?.installedBrowserList?.includes(SupportedBrowsers.Chrome),
    },
    {
      name: 'Safari',
      value: SupportedBrowsers.Safari,
      disabled:
        !infos?.serialNumber ||
        !infos?.installedBrowserList?.includes(SupportedBrowsers.Safari),
    },
  ];

  return (
    <SpeedSchedulerGridContainer
      title="Browser"
      isTitleCenter={testType === SpeedSchedulerTypeServerName.Web}
    >
      {testType === SpeedSchedulerTypeServerName.Mobile ? (
        <fieldset className="flex items-center gap-10">
          {mobileBrowserList.map((browser) => (
            <label
              key={browser.name}
              className={clsx(
                'flex cursor-pointer items-center gap-3',
                (browser.disabled || !isRunning) && 'text-gray-200',
              )}
            >
              <input
                type="radio"
                value={browser.value}
                className={clsx(
                  'input-radio-congress-blue',
                  (browser.disabled || !isRunning) &&
                    'bg-gray-200 text-gray-200',
                )}
                disabled={browser.disabled || !isRunning}
                {...register('infos.browser')}
              />
              {browser.name}
            </label>
          ))}
        </fieldset>
      ) : (
        <>
          <SpeedSchedulerBrowserListbox
            browserInfoDto={browserInfoDto}
            handleChange={handleChange}
            webBrowserListQuery={webBrowserListQuery}
            disabled={!isRunning}
          />
          <ErrorMessage
            errors={errors}
            name={'info'}
            render={({ message }) => (
              <p className="standard-error-message">{message}</p>
            )}
          />
        </>
      )}
    </SpeedSchedulerGridContainer>
  );
};

export default SpeedTestSchedulerDetailBrowser;
