import { PagingHeadingButtonOnClick } from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '@app/store';

import { getSelectedDevices } from '@store/ide/device/selectedDevicesSlice';
import { hasDownloadedData } from '@store/ide/settings/settingsSlice';

import { DeviceInfo, DeviceInfoOs } from '@customTypes/ide/device/device';

import ExecutionInfo from './MobileTestExecution/ExecutionInfo';
import SelectedDevices from './MobileTestExecution/SelectedDevices';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

interface Props {
  idx: number;
  setIsReadyToNavigate: React.Dispatch<React.SetStateAction<boolean>>;
  testType: TestTypeCategory;
  divWidth: number;
  mobileOS?: DeviceInfoOs;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  testLocation: 'ui-test' | 'speed-test';
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
}

const MobileTestExecution = ({
  idx,
  setIsReadyToNavigate,
  testType,
  divWidth,
  mobileOS,
  setIsSettingsOpen,
  testLocation = 'ui-test',
  setIsWirelessDeviceModal,
  setConnectionType,
}: Props) => {
  const { projectIdx } = useParams();

  const [selectedDevices, setSelectedDevices] = useState<DeviceInfo[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isSelectedDevices, setIsSelectedDevices] = useState(false);
  const [isXcodeApp, setIsXcodeApp] = useState(false);
  const [isWdaFile, setIsWdaFile] = useState(false);
  const [needIosSetting, setNeedIosSetting] = useState(false);

  const checkedDeviceHandler = (value: DeviceInfo, isChecked: boolean) => {
    if (isChecked) {
      setSelectedDevices([...selectedDevices, value]);
    }
    if (!isChecked && selectedDevices.includes(value)) {
      setSelectedDevices(
        selectedDevices.filter((selectedDevice) => selectedDevice !== value),
      );
    }
  };

  const checkHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: DeviceInfo,
  ) => {
    setIsChecked(!isChecked);
    checkedDeviceHandler(value, e.target.checked);
    setIsSelectedDevices(false);
  };

  const dispatch = useDispatch();
  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  useEffect(() => {
    dispatch(getSelectedDevices(selectedDevices));
  }, [selectedDevices]);

  const executionTestOnClick = () => {
    if (!selectedDevices?.length) {
      setIsSelectedDevices(true);
      return;
    }

    if (
      selectedDevices.some(
        (device) =>
          device.os === DeviceInfoOs.Ios && (!isXcodeApp || !isWdaFile),
      )
    ) {
      setNeedIosSetting(true);
      return;
    }

    setIsSelectedDevices(false);
    setIsReadyToNavigate(true);
  };

  useEffect(() => {
    const getXcodeLocation = async () => {
      const xcodeLocation: string | undefined =
        await window.electron.invoke('checkXcodeLocation');
      setIsXcodeApp(xcodeLocation?.toLowerCase().includes('xcode.app'));
    };
    const checkWdaFile = async () => {
      const existWdaFile: boolean = await window.electron.invoke(
        'existWdaFile',
        projectIdx,
      );
      setIsWdaFile(existWdaFile);
    };
    getXcodeLocation();
    checkWdaFile();

    if (isDownloadedData) {
      getXcodeLocation();
      checkWdaFile();
      dispatch(hasDownloadedData(false));
    }
  }, [isDownloadedData]);

  const breadcrumbs = [
    { title: testLocation === 'ui-test' ? 'UI Test' : 'Speed Test' },
    { title: 'Mobile' },
    {
      title:
        testType === 'step'
          ? 'Test Step'
          : testType === 'case'
            ? 'Test Case'
            : 'Test Scenario',
    },
    { title: 'Execution' },
  ];

  const pageHeadingButtons = [
    {
      title: 'Execution test',
      onClick: executionTestOnClick,
    },
  ];

  return (
    <>
      <PagingHeadingButtonOnClick
        breadcrumbs={breadcrumbs}
        pageTitle={
          testType === 'step'
            ? 'Test Step Execution'
            : testType === 'case'
              ? 'Test Case Execution'
              : 'Test Scenario Execution'
        }
        pageHeadingButtons={pageHeadingButtons}
      />
      <ExecutionInfo idx={idx} testType={testType} />
      <SelectedDevices
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        checkHandler={checkHandler}
        isSelectedDevices={isSelectedDevices}
        setIsSelectedDevices={setIsSelectedDevices}
        divWidth={divWidth}
        testType={testType}
        mobileOS={mobileOS}
        idx={idx}
        needIosSetting={needIosSetting}
        setNeedIosSetting={setNeedIosSetting}
        setIsSettingsOpen={setIsSettingsOpen}
        setIsWirelessDeviceModal={setIsWirelessDeviceModal}
        setConnectionType={setConnectionType}
      />
    </>
  );
};

export default MobileTestExecution;
