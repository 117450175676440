import SpeedSchedulerDeviceListbox from '@components/Scheduler/SpeedSchedulerDeviceListbox';
import {
  CreateSpeedSchedulerData,
  SpeedSchedulerTypeName,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { DeviceInfo } from '@customTypes/ide/device/device';
import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';

const CreateSpeedTestSchedulerDevice = () => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSpeedSchedulerData>();

  const testType = watch('type');

  const [deviceInfoDto, setDeviceInfoDto] = useState<DeviceInfo>(undefined);

  const deviceListQuery = useDeviceListQuery({
    enabled: testType === SpeedSchedulerTypeName.Mobile,
  });

  const handleChange = (selectedDevice: DeviceInfo) => {
    setDeviceInfoDto(selectedDevice);
    setValue('deviceInfoDto', selectedDevice);
  };

  useEffect(() => {
    if (testType === SpeedSchedulerTypeName.Web) {
      setDeviceInfoDto(undefined);
    }
  }, [testType]);

  useEffect(() => {
    if (deviceInfoDto) {
      clearErrors('deviceInfoDto');
    }
  }, [deviceInfoDto]);

  return (
    testType === SpeedSchedulerTypeName.Mobile && (
      <SpeedSchedulerGridContainer title="Device" isTitleCenter={true}>
        <SpeedSchedulerDeviceListbox
          deviceInfoDto={deviceInfoDto}
          handleChange={handleChange}
          deviceListQuery={deviceListQuery}
        />
        <ErrorMessage
          errors={errors}
          name="deviceInfoDto"
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </SpeedSchedulerGridContainer>
    )
  );
};

export default CreateSpeedTestSchedulerDevice;
