import { BrowserInfoSchema } from '../browser/browser';
import { DeviceInfoSchema } from '../device/device';

import { z } from 'zod';
import { DeleteIndexList } from '@customTypes/type';

export enum SpeedSchedulerTypeName {
  Mobile = 'Mobile',
  Web = 'Web',
}

export enum SpeedSchedulerTypeServerName {
  Mobile = 'mobile',
  Web = 'web',
}

export const MailReceiverSchema = z.object({
  email: z.string().email(),
  name: z.string().nullable(),
});

export const BaseSpeedSchedulerSchema = z.object({
  title: z.string(),
  type: z.nativeEnum(SpeedSchedulerTypeServerName),
  start_time: z.string(),
  auto_register: z.boolean(),
  mail_receivers: z.array(MailReceiverSchema),
});

export const SpeedSchedulerDetailSchema = BaseSpeedSchedulerSchema.extend({
  project_idx: z.number(),
  idx: z.number(),
  scenario_idx_list: z.array(z.number()),
  environment: z.string(),
  cycle: z.string(),
  running: z.boolean(),
  creator: z.string(),
  owner_uuid: z.string(),
  test_category: z.string(),
  repeat_num: z.number(),
});

export const BaseLocalSpeedSchedulerSchema = z.object({
  title: z
    .string()
    .min(1, { message: 'Title을 입력해 주세요.' })
    .max(50, { message: '50자 이하 입력해 주세요.' }),
  scenarioIdxList: z.array(z.number()),
  startTime: z.string(),
  autoRegister: z.boolean(),
  mailReceivers: z.array(MailReceiverSchema).optional(),
  repeatNum: z
    .number({
      required_error: '3 이상 50 이하 숫자를 입력해 주세요.',
      invalid_type_error: '3 이상 50 이하 숫자를 입력해 주세요.',
    })
    .min(3, { message: '3 이상 50 이하 숫자를 입력해 주세요.' })
    .max(50, { message: '3 이상 50 이하 숫자를 입력해 주세요.' }),
});

export const CreateSpeedSchedulerSchema = BaseLocalSpeedSchedulerSchema.extend({
  type: z.nativeEnum(SpeedSchedulerTypeName),
  projectIdx: z.number(),
  deviceInfoDto: DeviceInfoSchema.optional(),
  browserInfoDto: BrowserInfoSchema.optional(),
  cronCycle: z.string(),
});

const InfosSchema = DeviceInfoSchema.merge(BrowserInfoSchema).optional();

export const UpdateSpeedSchedulerSchema = BaseLocalSpeedSchedulerSchema.extend({
  scheduleIdx: z.number(),
  infos: InfosSchema,
  cronCycle: z.string(),
});

export type SpeedSchedulerDetailData = z.infer<
  typeof SpeedSchedulerDetailSchema
>;
export type CreateSpeedSchedulerData = z.infer<
  typeof CreateSpeedSchedulerSchema
>;
export type UpdateSpeedSchedulerData = z.infer<
  typeof UpdateSpeedSchedulerSchema
>;
export type DeleteSpeedSchedulerData = DeleteIndexList;
export type MailReceiver = z.infer<typeof MailReceiverSchema>;
