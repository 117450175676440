import { SkeletonText } from '@autosquare/common';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { useWebSpeedTestCaseDetailQuery } from '@lib/api/ide/speedWeb/webSpeedTestCase';
import { useSpeedWebTestScenarioDetailQuery } from '@lib/api/ide/speedWeb/webSpeedTestScenario';
import clsx from 'clsx';
import React from 'react';

type Props = {
  idx: number;
  testType: TestTypeCategory;
};

const ExecutionInfo = ({ idx, testType }: Props) => {
  const webSpeedTestCaseDetailQuery = useWebSpeedTestCaseDetailQuery(
    idx.toString(),
    { enabled: testType === 'case' },
  );
  const webSpeedTestCaseDetail = webSpeedTestCaseDetailQuery.data;

  const speedWebTestScenarioDetailQuery = useSpeedWebTestScenarioDetailQuery(
    idx,
    { enabled: testType === 'scenario' },
  );
  const speedWebTestScenarioDetail = speedWebTestScenarioDetailQuery.data;

  const executionInfoList =
    testType === 'case'
      ? [
          {
            title: 'Test Group',
            description: webSpeedTestCaseDetailQuery.isLoading ? (
              <SkeletonText />
            ) : (
              webSpeedTestCaseDetail?.caseGroupInfo?.name
            ),
          },
          {
            title: 'Service Group',
            description: webSpeedTestCaseDetailQuery.isLoading ? (
              <SkeletonText />
            ) : (
              webSpeedTestCaseDetail?.serviceGroupInfo?.name
            ),
          },
          {
            title: 'Title',
            description: webSpeedTestCaseDetailQuery.isLoading ? (
              <SkeletonText />
            ) : (
              webSpeedTestCaseDetail?.title
            ),
          },
        ]
      : testType === 'scenario' && [
          {
            title: 'Title',
            description: speedWebTestScenarioDetailQuery.isLoading ? (
              <SkeletonText />
            ) : (
              speedWebTestScenarioDetail?.title
            ),
          },
          {
            title: 'Execution Count',
            description: speedWebTestScenarioDetailQuery.isLoading ? (
              <SkeletonText />
            ) : (
              speedWebTestScenarioDetail?.caseCount
            ),
          },
        ];

  return (
    <section>
      <h3 className="pb-4 text-base font-semibold leading-7 text-gray-900">
        ExecutionInfo
      </h3>
      <div
        className={clsx(
          'grid border-t border-solid border-gray-100 pt-4 text-sm font-medium leading-normal text-gray-900',
          testType === 'case'
            ? 'grid-cols-3'
            : testType === 'scenario' && 'grid-cols-2',
        )}
      >
        {executionInfoList.map((executionInfo) => (
          <div key={executionInfo.title} className="flex flex-col gap-2">
            <p>{executionInfo.title}</p>
            <div className="font-normal text-gray-700">
              {executionInfo.description}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ExecutionInfo;
