import React from 'react';
import { LiaUsb } from 'react-icons/lia';

type Props = {
  gridCols: string;
};

const DeviceSkeleton = ({ gridCols }: Props) => {
  return (
    <div className={`my-3 grid gap-2 ${gridCols}`}>
      <div className="mr-3 flex animate-pulse items-center justify-start gap-2 rounded border border-gray-200 px-2 py-3.5 shadow dark:border-gray-700">
        <div className="flex items-center justify-start gap-2">
          <div className="size-4 rounded border border-solid border-gray-200" />
          <LiaUsb size="1.5rem" />
        </div>
        <div className="flex flex-col gap-1">
          <div className="h-3 w-20 rounded bg-gray-200" />
          <div className="h-3 w-32 rounded bg-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default DeviceSkeleton;
