import {
  DefaultSkeleton,
  ErrorMessage,
  Pagination,
  PagingHeadingsNavigate,
  TableSkeletonDefault,
} from '@autosquare/common';

import React from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useWebTestResultListQuery } from '@lib/api/ide/web/webTestResult';

import WebTestResultTable from './components/WebTestResultTable';

const IdeWebTestResult = () => {
  const PAGE_LIMIT = 10;
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));

  const webTestResultQuery = useWebTestResultListQuery(
    projectIdx,
    page,
    PAGE_LIMIT,
  );
  const webTestResult = webTestResultQuery.data;

  const currentUrl = pathname + search;

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Web', href: currentUrl },
    { title: 'Test Result', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      {webTestResultQuery.isLoading ? (
        <TableSkeletonDefault number={2} />
      ) : webTestResultQuery.isError ? (
        <ErrorMessage>{webTestResultQuery.error.message}</ErrorMessage>
      ) : (
        <WebTestResultTable
          webTestResult={webTestResult}
          page={webTestResult?.currentPage}
          PAGE_LIMIT={webTestResult?.currentSize}
        />
      )}
      {webTestResultQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        webTestResult?.content.length !== 0 && (
          <Pagination
            page={page}
            total={webTestResult?.totalElements}
            size={PAGE_LIMIT}
            isDashboard={false}
          />
        )
      )}
    </>
  );
};

export default IdeWebTestResult;
