import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { axiosFileInstance, axiosInstance } from '..';
import { API } from '@api/endpoints';
import { mobileTestResultKeys } from '@queries/ide/mobile/mobileTestResultKeys';

import {
  MobileTestResultPagingData,
  TestResult,
  TestResultCaseDetail,
  TestResultInquiryByCase,
} from '@customTypes/testResult/type';

import { ServerResponseErrorDetails } from '@customTypes/type';

// GET
const getMobileTestResultList = async (
  projectIdx: string,
  currentPage: number,
  limit: number,
): Promise<MobileTestResultPagingData> => {
  const response = await axiosInstance.get(
    API.testResultListPagination(projectIdx, currentPage, limit),
  );
  return response.data;
};
export const useMobileTestResultListQuery = (
  projectIdx: string,
  currentPage: number,
  limit: number,
) =>
  useQuery<MobileTestResultPagingData, ServerResponseErrorDetails>({
    queryKey: mobileTestResultKeys.list(projectIdx, currentPage, limit),
    queryFn: () => getMobileTestResultList(projectIdx, currentPage, limit),
    keepPreviousData: true,
  });

const getMobileTestResultByTestCase = async (
  idx: string,
): Promise<TestResultInquiryByCase> => {
  const response = await axiosInstance.get<TestResultInquiryByCase>(
    `${API.getTestCaseResult(idx)}`,
  );
  return response.data;
};
export const useMobileTestCaseResultQuery = (idx: string) => {
  return useQuery<TestResultInquiryByCase, ServerResponseErrorDetails>({
    queryKey: mobileTestResultKeys.detailInfo(idx),
    queryFn: () => getMobileTestResultByTestCase(idx),
  });
};

const getMobileTestResultDetail = async (idx: string): Promise<TestResult> => {
  const response = await axiosInstance.get(`${API.GET_TEST_RESULT}${idx}`);
  return response.data;
};
export const useMobileTestResultDetailQuery = (idx: string) => {
  return useQuery<TestResult, ServerResponseErrorDetails>({
    queryKey: mobileTestResultKeys.detail(idx),
    queryFn: () => getMobileTestResultDetail(idx),
  });
};

const getMobileTestCaseExecutionDetail = async (
  idx: string,
  caseResultIdx: number,
): Promise<TestResultCaseDetail[]> => {
  const response = await axiosInstance.get(
    API.getMobileTestCaseExecutionDetail(idx, caseResultIdx),
  );
  return response.data;
};
export const useMobileTestCaseExecutionDetailQuery = (
  idx: string,
  caseResultIdx: number,
  options?: UseQueryOptions<TestResultCaseDetail[], ServerResponseErrorDetails>,
) => {
  return useQuery<TestResultCaseDetail[], ServerResponseErrorDetails>({
    queryKey: mobileTestResultKeys.executionDetail(idx, caseResultIdx),
    queryFn: () => getMobileTestCaseExecutionDetail(idx, caseResultIdx),
    ...options,
  });
};

//image
const mobileTestResultImageData = async (path: string[]): Promise<string[]> => {
  const requests = path.map((path) =>
    axiosFileInstance.get<MediaSource>(path, {
      responseType: 'blob',
    }),
  );

  const responses = await Promise.all(requests);
  const objectUrls = responses.map((response) =>
    URL.createObjectURL(response.data),
  );

  return objectUrls;
};
export const useMobileTestResultImageData = (
  path: string[],
  options?: UseQueryOptions<string[], Error>,
) => {
  return useQuery<string[], Error>({
    queryKey: mobileTestResultKeys.detailImage(path[0]),
    queryFn: () => mobileTestResultImageData(path),
    ...options,
  });
};

//video
const mobileTestResultVideoAndLogData = async (
  path: string,
): Promise<string> => {
  const response = await axiosFileInstance.get<MediaSource | Blob>(path, {
    responseType: 'blob',
  });

  const objectUrl = URL.createObjectURL(response.data);
  return objectUrl;
};

export const useMobileTestResultVideoData = (
  path: string,
  options?: UseQueryOptions<string, Error>,
) => {
  return useQuery<string, Error>({
    queryKey: mobileTestResultKeys.detailVideo(path),
    queryFn: () => mobileTestResultVideoAndLogData(path),
    ...options,
  });
};

//log
export const useMobileTestResultLogData = (
  path: string,
  options?: UseQueryOptions<string, Error>,
) => {
  return useQuery<string, Error>({
    queryKey: mobileTestResultKeys.detailLog(path),
    queryFn: () => mobileTestResultVideoAndLogData(path),
    ...options,
  });
};
