import AllTestCaseListInDraggableElement from '@components/SpeedTestScenario/AllTestCaseListInDraggableElement';
import DraggableElementContainer from '@components/SpeedTestScenario/DraggableElementContainer';
import ScenarioTestCaseListContainer from '@components/SpeedTestScenario/ScenarioTestCaseListContainer';
import { useSpeedTestScenarioDraggableSpace } from '@hooks/speedTestScenario/useSpeedTestScenarioDraggableSpace';
import React from 'react';
import ScenarioTestCaseList from './DraggableSpace/ScenarioTestCaseList';

const DraggableSpace = () => {
  const {
    copiedTestCaseList,
    setCopiedTestCaseList,
    searchWord,
    onChangeSearchWord,
    setSearchWord,
    changeCardList,
    searchResultTestCaseList,
    speedMobileTestCaseList,
    isLoading,
  } = useSpeedTestScenarioDraggableSpace('detail', 'mobile');

  return (
    <DraggableElementContainer>
      <AllTestCaseListInDraggableElement
        isLoading={isLoading}
        speedTestCaseList={speedMobileTestCaseList}
        searchResultTestCaseList={searchResultTestCaseList}
        copiedTestCaseList={copiedTestCaseList}
        changeCardList={changeCardList}
        searchWord={searchWord}
        onChangeSearchWord={onChangeSearchWord}
        setSearchWord={setSearchWord}
      />
      <ScenarioTestCaseListContainer>
        <ScenarioTestCaseList
          isLoading={isLoading}
          copiedTestCaseList={copiedTestCaseList}
          setCopiedTestCaseList={setCopiedTestCaseList}
          changeCardList={changeCardList}
        />
      </ScenarioTestCaseListContainer>
    </DraggableElementContainer>
  );
};

export default DraggableSpace;
