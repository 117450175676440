import { Button, regexLibrary } from '@autosquare/common';

import { useSettingWifiWirelessDeviceMutation } from '@lib/api/ide/mobile/mobileDevice';

import { WifiConnectRequest } from '@customTypes/ide/device/device';

import { ErrorMessage } from '@hookform/error-message';

import React from 'react';
import {
  SubmitHandler,
  useForm,
  UseFormRegisterReturn,
  ValidationRule,
} from 'react-hook-form';
import IsSuccessConnection from './IsSuccessConnection';

import HowToUseWifiConnection from './WifiConnection/HowToUseWifiConnection';
import clsx from 'clsx';
import { isMacOs } from '@utils/static/isMacOs';

interface Props {
  closeModal: () => void;
}

const WifiConnection = ({ closeModal }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<WifiConnectRequest>();

  const settingWifiWirelessDeviceMutation =
    useSettingWifiWirelessDeviceMutation();

  const onSubmit: SubmitHandler<WifiConnectRequest> = (data) => {
    settingWifiWirelessDeviceMutation.mutate(data);
  };

  const resetFetchingAndFormError = () => {
    settingWifiWirelessDeviceMutation.reset();
    clearErrors();
  };

  const ipAddressPattern: ValidationRule<RegExp> = {
    value: regexLibrary.ipAddressWithPortNumber,
    message: 'IP 주소 및 포트 번호를 다시 확인해 주세요.',
  };

  const inputLists: {
    labelTitle: string;
    id: keyof WifiConnectRequest;
    register: UseFormRegisterReturn;
  }[] = [
    {
      labelTitle: '디바이스 IP 주소 및 포트',
      id: 'deviceIpAddressAndPort',
      register: register('deviceIpAddressAndPort', {
        required: 'IP 주소 및 포트 번호를 입력해 주세요.',
        pattern: ipAddressPattern,
      }),
    },
    {
      labelTitle: '페어링 코드',
      id: 'pairingCode',
      register: register('pairingCode', {
        required: '6자리의 코드 번호를 입력해 주세요.',
        pattern: {
          value: regexLibrary.sixDigitNumber,
          message: '6자리의 코드 번호를 입력해 주세요.',
        },
      }),
    },
    {
      labelTitle: '페어링 IP 주소 및 포트',
      id: 'pairingIpAddressAndPort',
      register: register('pairingIpAddressAndPort', {
        required: 'IP 주소 및 포트 번호를 입력해 주세요.',
        pattern: ipAddressPattern,
      }),
    },
  ];

  return (
    <>
      <h3 className="text-base font-semibold leading-7 text-gray-900">
        Wi-Fi ADB 무선 연결 방식
      </h3>
      {settingWifiWirelessDeviceMutation.isSuccess ? (
        <IsSuccessConnection
          isSuccess={true}
          onClick={closeModal}
          connectingType={'wifi'}
        />
      ) : settingWifiWirelessDeviceMutation.isError ? (
        <IsSuccessConnection
          isSuccess={false}
          onClick={resetFetchingAndFormError}
          connectingType={'wifi'}
        />
      ) : (
        <>
          <p className="text-sm font-medium leading-7">
            PC와 디바이스를 동일한 Wi-Fi에 연결한 후, 연결하려는 디바이스의 IP
            주소 및 포트, 페어링 코드, 페어링 IP 주소 및 포트를 입력해 주세요.
          </p>
          <form
            className="border-b border-solid border-gray-200 pb-5 pt-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-2.5">
              {inputLists.map((inputItem) => (
                <div key={inputItem.id} className="w-full">
                  <label className="flex w-full items-start justify-center">
                    <p
                      className={clsx(
                        'whitespace-pre px-3 py-2.5 text-end text-sm font-normal leading-tight text-gray-900',
                        isMacOs ? 'w-40' : 'w-44',
                      )}
                    >
                      {inputItem.labelTitle}
                    </p>
                    <div className="flex flex-col items-start">
                      <input
                        type="text"
                        className="input-base w-64"
                        {...inputItem.register}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={inputItem.id}
                        render={({ message }) => (
                          <p className="error-message pl-3 pt-2.5">{message}</p>
                        )}
                      />
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <div className="flex-center pt-2.5">
              <Button
                type="submit"
                buttonSize="sm"
                disabled={settingWifiWirelessDeviceMutation.isLoading}
              >
                OK
              </Button>
            </div>
          </form>
          <HowToUseWifiConnection />
        </>
      )}
    </>
  );
};

export default WifiConnection;
