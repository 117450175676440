import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { IDEAPI } from '@api/ideEndpoints';
import { groupKeys } from '@queries/ide/groupKeys';
import {
  DeleteTestCaseGroup,
  TestCaseGroup,
  CreateTestCaseGroup,
  UpdateTestCaseGroup,
} from '@customTypes/testCaseGroup/type';
import { axiosInstance } from '..';
import { webTestCaseKeys } from '@queries/ide/web/webTestCaseKeys';
import { mobileTestCaseKeys } from '@queries/ide/mobile/mobileTestCaseKeys';
import { speedMobileTestCaseKeys } from '@queries/ide/speedMobile/speedMobileTestCaseKeys';
import { useParams } from 'react-router-dom';
import { webSpeedTestCaseKeys } from '@queries/ide/speedWeb/webSpeedTestCaseKeys';

//GET
export const getTestCaseGroupList = async (
  projectIdx: string,
): Promise<TestCaseGroup[]> => {
  const response = await axiosInstance.get<TestCaseGroup[]>(
    IDEAPI.GET_TEST_GROUP_LIST + projectIdx,
  );
  return response.data;
};
export const useTestCaseGroupListQuery = (
  options?: UseQueryOptions<TestCaseGroup[], Error>,
) => {
  const { projectIdx } = useParams();

  return useQuery<TestCaseGroup[], Error, TestCaseGroup[]>({
    queryKey: groupKeys.list(projectIdx),
    queryFn: () => getTestCaseGroupList(projectIdx),
    ...options,
  });
};

//POST
const createTestCaseGroup = async (
  data: CreateTestCaseGroup,
): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    IDEAPI.CRUD_TEST_GROUP,
    data,
  );
  return response.data;
};

export const useCreateTestCaseGroupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, CreateTestCaseGroup>({
    mutationFn: (data) => createTestCaseGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries(groupKeys.lists());
    },
  });
};

//PUT
const updateTestCaseGroup = async (
  data: UpdateTestCaseGroup,
): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.CRUD_TEST_GROUP,
    data,
  );
  return response.data;
};

export const useUpdateTestCaseGroupMutation = (projectIdx: string) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, UpdateTestCaseGroup>({
    mutationFn: (data) => updateTestCaseGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries(groupKeys.list(projectIdx));
      queryClient.invalidateQueries(webTestCaseKeys.all);
      queryClient.invalidateQueries(mobileTestCaseKeys.all);
      queryClient.invalidateQueries(speedMobileTestCaseKeys.all);
      queryClient.invalidateQueries(webSpeedTestCaseKeys.all);
    },
  });
};

const deleteTestCaseGroup = async (
  data: DeleteTestCaseGroup,
): Promise<DeleteTestCaseGroup> => {
  const response = await axiosInstance.delete<DeleteTestCaseGroup>(
    IDEAPI.CRUD_TEST_GROUP,
    { data: data },
  );
  return response.data;
};

export const useDeleteCaseGroupMutation = (projectIdx: string) => {
  const queryClient = useQueryClient();

  return useMutation<DeleteTestCaseGroup, Error, DeleteTestCaseGroup>({
    mutationFn: (data) => deleteTestCaseGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries(groupKeys.list(projectIdx));
      queryClient.invalidateQueries(webTestCaseKeys.all);
      queryClient.invalidateQueries(mobileTestCaseKeys.all);
      queryClient.invalidateQueries(speedMobileTestCaseKeys.all);
      queryClient.invalidateQueries(webSpeedTestCaseKeys.all);
    },
  });
};
