import {
  Breadcrumbs,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingButtonOnClick,
} from '@autosquare/common';
import SpeedTestScenaioDetailSkeleton from '@components/SpeedTestScenario/SpeedTestScenaioDetailSkeleton';
import {
  CreateWebSpeedTestScenarioDataSchema,
  UpdateWebSpeedTestScenarioData,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useDeleteSpeedWebTestScenarioMutation,
  useSpeedWebTestScenarioDetailQuery,
  useUpdateSpeedWebTestScenarioMutation,
} from '@lib/api/ide/speedWeb/webSpeedTestScenario';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import WebSpeedTestScenarioDetailDndSpace from './components/WebSpeedTestScenarioDetailDndSpace';
import WebSpeedTestScenarioDetailInformation from './components/WebSpeedTestScenarioDetailInformation';

const WebSpeedTestScenarioDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const speedWebTestScenarioDetailQuery = useSpeedWebTestScenarioDetailQuery();
  const speedWebTestScenarioDetail = speedWebTestScenarioDetailQuery.data;

  const updateSpeedWebTestScenarioMutation =
    useUpdateSpeedWebTestScenarioMutation();

  const deleteSpeedWebTestScenarioMutation =
    useDeleteSpeedWebTestScenarioMutation();

  const methods = useForm<UpdateWebSpeedTestScenarioData>({
    resolver: zodResolver(CreateWebSpeedTestScenarioDataSchema),
  });

  useEffect(() => {
    if (speedWebTestScenarioDetailQuery.isSuccess) {
      methods.setValue('title', speedWebTestScenarioDetail.title);
      methods.setValue('description', speedWebTestScenarioDetail.description);
      methods.setValue('testCaseIdxs', speedWebTestScenarioDetail.step);
    }
  }, [speedWebTestScenarioDetailQuery.isSuccess]);

  const testScenarioListPage = location.pathname
    .split('/')
    .slice(0, -1)
    .join('/');

  const onSubmit: SubmitHandler<UpdateWebSpeedTestScenarioData> = (data) => {
    updateSpeedWebTestScenarioMutation.mutate(data, {
      onSuccess: () => {
        navigate(testScenarioListPage);
      },
    });
  };

  const deleteOnClick = () => {
    deleteSpeedWebTestScenarioMutation.mutate({
      idxList: [speedWebTestScenarioDetail?.idx],
    });
  };

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Speed Test',
      href: location.pathname.split('/').slice(0, -3).join('/') + '/overview',
    },
    {
      title: 'Web',
      href: testScenarioListPage,
    },
    {
      title: 'Test Scenario',
      href: testScenarioListPage,
    },
    {
      title: 'Detail',
      href: location.pathname + location.search,
    },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Delete',
      type: 'button',
      variant: 'caution',
      onClick: () => {
        setIsModalOpened(true);
      },
    },
    { title: 'Save', type: 'submit' },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PagingHeadingButtonOnClick
          breadcrumbs={breadcrumbs}
          pageTitle={'Test Scenario Detail'}
          pageHeadingButtons={pageHeadingButtons}
        />
        {speedWebTestScenarioDetailQuery.isError ? (
          <ErrorMessage>
            {speedWebTestScenarioDetailQuery.error.message}
          </ErrorMessage>
        ) : (
          updateSpeedWebTestScenarioMutation.isError && (
            <ErrorMessage>
              {updateSpeedWebTestScenarioMutation.error.message}
            </ErrorMessage>
          )
        )}
        {speedWebTestScenarioDetailQuery.isLoading ? (
          <SpeedTestScenaioDetailSkeleton />
        ) : (
          <WebSpeedTestScenarioDetailInformation />
        )}
        <WebSpeedTestScenarioDetailDndSpace />
      </form>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={
          deleteSpeedWebTestScenarioMutation.isError
            ? 'Error'
            : 'Delete Test Scenario'
        }
        subTitle={
          deleteSpeedWebTestScenarioMutation.isError
            ? deleteSpeedWebTestScenarioMutation.error.message
            : '해당 테스트 시나리오를 삭제하시겠습까?'
        }
        buttonChildren={
          <>
            {deleteSpeedWebTestScenarioMutation.isError ? undefined : (
              <Button type="button" variant="caution" onClick={deleteOnClick}>
                Delete
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setIsModalOpened(false);
              }}
            >
              Cancel
            </Button>
          </>
        }
      />
    </FormProvider>
  );
};

export default WebSpeedTestScenarioDetail;
