import { ListBoxHeadlessInController } from '@autosquare/common';

import {
  CreateWebTestStepData,
  WebTestStepOptionsDto,
} from '@customTypes/testStep/type';

import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';

import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

type ValueKeys =
  | keyof Pick<CreateWebTestStepData, 'value'>
  | `optionsDto.${keyof Pick<
      WebTestStepOptionsDto,
      'passActionValue' | 'failActionValue'
    >}`;

type Props = {
  value?: ValueKeys;
  rules?: Omit<
    RegisterOptions<CreateWebTestStepData, ValueKeys>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

const WebTestStepImportTestCaseListBox = ({
  value = 'value',
  rules = { required: 'Test Case를 선택해주세요.' },
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const webTestCaseListQuery = useWebTestCaseListQuery();

  const webTestCaseList = webTestCaseListQuery.data?.filter(
    (webTestCase) => webTestCase.idx !== Number(idx),
  );

  const findTitleFromIdxByWebTestCase = (idx: string) =>
    webTestCaseList?.find((webTestCase) => webTestCase.idx === Number(idx))
      ?.title;

  return (
    <>
      <Controller
        control={control}
        name={value}
        rules={rules}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              findTitleFromIdxByWebTestCase(field.value) || 'Select Test Case'
            }
            onChange={field.onChange}
            lists={webTestCaseList}
            valueToSave={'idx'}
            valueToShow={'title'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={value}
        render={({ message }) => <p className="error-message">{message}</p>}
      />
    </>
  );
};

export default WebTestStepImportTestCaseListBox;
