import React, { Fragment, SetStateAction } from 'react';

import {
  ConnectionType,
  DeviceInfo,
  DeviceInfoOs,
} from '@customTypes/ide/device/device';
import clsx from 'clsx';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { SupportedBrowsers } from '@customTypes/ide/browser/browser';
import { LiaUsb } from 'react-icons/lia';

interface Props {
  deviceList: DeviceInfo;
  testType: TestTypeCategory;
  mobileOS: DeviceInfoOs;
  aosCount: number;
  iosCount: number;
  selectedDevices: DeviceInfo[];
  checkHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: DeviceInfo,
  ) => void;
  setSelectedBrowser: React.Dispatch<SetStateAction<string>>;
}
const Device = ({
  deviceList,
  testType,
  mobileOS,
  aosCount,
  iosCount,
  selectedDevices,
  checkHandler,
  setSelectedBrowser,
}: Props) => {
  const isEnabled =
    (testType === 'step' &&
      mobileOS === DeviceInfoOs.Aos &&
      deviceList.os === DeviceInfoOs.Aos &&
      deviceList.enabled) ||
    (testType === 'step' &&
      mobileOS === DeviceInfoOs.Ios &&
      deviceList.os === DeviceInfoOs.Ios &&
      deviceList.enabled) ||
    (testType === 'case' &&
      aosCount !== 0 &&
      deviceList.os === DeviceInfoOs.Aos &&
      deviceList.enabled) ||
    (testType === 'case' &&
      iosCount !== 0 &&
      deviceList.os === DeviceInfoOs.Ios &&
      deviceList.enabled) ||
    (testType === 'scenario' && deviceList.enabled);

  const disabledCondition =
    (testType === 'step' &&
      mobileOS === DeviceInfoOs.Ios &&
      deviceList.os === DeviceInfoOs.Aos) ||
    (testType === 'step' &&
      mobileOS === DeviceInfoOs.Aos &&
      deviceList.os === DeviceInfoOs.Ios) ||
    (testType === 'case' &&
      aosCount === 0 &&
      deviceList.os === DeviceInfoOs.Aos) ||
    (testType === 'case' &&
      iosCount === 0 &&
      deviceList.os === DeviceInfoOs.Ios);

  const disabledBaseBrowser = !deviceList.installedBrowserList.includes(
    SupportedBrowsers.Chrome,
  );
  const disabledSecondBrowser = !deviceList.installedBrowserList.includes(
    SupportedBrowsers.Safari,
  );

  const isChecked = selectedDevices.some(
    (device) => device.udid === deviceList.udid,
  );

  const browsers = [
    {
      id: `chrome-${deviceList.udid}`,
      name: `browser-${deviceList.udid}`,
      value: 'Chrome',
      defaultChecked: deviceList.installedBrowserList.includes(
        SupportedBrowsers.Chrome,
      ),
      disabled: disabledBaseBrowser || !isEnabled,
      classNames: clsx(
        'input-radio-congress-blue ml-10',
        disabledBaseBrowser || (!isEnabled && 'cursor-default text-gray-300'),
      ),
    },
    {
      id: `safari-${deviceList.udid}`,
      name: `browser-${deviceList.udid}`,
      value: 'Safari',
      defaultChecked:
        disabledBaseBrowser &&
        deviceList.installedBrowserList.includes(SupportedBrowsers.Safari),
      disabled: disabledSecondBrowser || !isEnabled,
      classNames: clsx(
        'input-radio-congress-blue',
        disabledSecondBrowser || (!isEnabled && 'cursor-default text-gray-300'),
      ),
    },
  ];

  return (
    <li
      key={deviceList.udid}
      className={clsx('mr-3', isEnabled ? 'bg-white' : 'bg-gray-100')}
    >
      <label
        className={clsx(
          'flex rounded-md shadow-sm',
          isEnabled ? 'cursor-pointer' : 'cursor-default',
        )}
        htmlFor={`comments-${deviceList.udid}`}
      >
        <div className="flex w-full flex-col rounded-md border border-gray-200 p-3">
          <div className="flex">
            <div
              className={clsx(
                'flex w-16 flex-1 items-center justify-center gap-2 text-sm font-medium',
                !isEnabled && 'text-gray-500',
              )}
            >
              <input
                id={`comments-${deviceList.udid}`}
                aria-describedby="comments-description"
                name={`comments-${deviceList.udid}`}
                type="checkbox"
                className={clsx(
                  'size-4 rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600',
                  isEnabled
                    ? 'cursor-pointer bg-white'
                    : 'cursor-default bg-gray-100',
                )}
                checked={deviceList.enabled && isChecked}
                onChange={(e) =>
                  deviceList.enabled && checkHandler(e, deviceList)
                }
                disabled={!deviceList.enabled || disabledCondition}
              />
              {deviceList.connectionType === null ||
              deviceList.connectionType === ConnectionType.USB ? (
                <LiaUsb size="1.5rem" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.288 15.038a5.25 5.25 0 0 1 7.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 0 1 1.06 0Z"
                  />
                </svg>
              )}
              <div className="flex flex-1 items-center justify-between truncate">
                <div className="flex-1 truncate px-0 py-2 text-sm">
                  {deviceList.model ?? '-'}
                  <p className="truncate text-gray-500">{deviceList.udid}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 flex items-center justify-start gap-x-2">
            {browsers.map((browser) => (
              <Fragment key={browser.id}>
                <input
                  type="radio"
                  id={browser.id}
                  name={browser.name}
                  defaultValue={browser.value}
                  className={browser.classNames}
                  defaultChecked={browser.defaultChecked}
                  disabled={browser.disabled}
                  onChange={(e) =>
                    isChecked && setSelectedBrowser(e.target.value)
                  }
                />
                <label
                  htmlFor={browser.id}
                  className={clsx(
                    'text-sm',
                    browser.disabled && 'text-gray-300',
                  )}
                >
                  {browser.value}
                </label>
              </Fragment>
            ))}
          </div>
        </div>
      </label>
    </li>
  );
};

export default Device;
