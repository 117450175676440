import { PagingHeadingsInAutosquare } from '@autosquare/common';
import React from 'react';
import { useLocation } from 'react-router-dom';

import CreateSchedulerForm from './components/CreateSchedulerForm';
import AddWirelessDeviceButton from '@components/AddWirelessDeviceButton/AddWirelessDeviceButton';

const CreateScheduler = () => {
  const location = useLocation();

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: location.pathname.split('/').slice(0, -2).join('/') + '/overview',
    },
    {
      title: 'Scheduler',
      href: `${location.pathname.split('/').slice(0, -1).join('/')}`,
    },
    {
      title: 'Create',
      href: location.pathname + location.search,
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between">
        <PagingHeadingsInAutosquare
          breadcrumbs={breadcrumbs}
          pageTitle={'Create Scheduler'}
        />
        <div className="mt-8">
          <AddWirelessDeviceButton />
        </div>
      </div>
      <CreateSchedulerForm />
    </>
  );
};

export default CreateScheduler;
