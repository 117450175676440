import { Breadcrumbs, Button, PageHeadingButtons } from '@autosquare/common';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';

type Props = {
  breadcrumbs: Breadcrumbs[];
  pageTitle: string;
  selectedDeviceLength: number;
  pageHeadingButtons?: PageHeadingButtons[];
};

const PagingHeaderWithTotalCount = ({
  breadcrumbs,
  pageTitle,
  selectedDeviceLength,
  pageHeadingButtons,
}: Props) => {
  return (
    <div>
      <div>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            {breadcrumbs?.map((breadcrumb, bIndex) => (
              <li key={breadcrumb?.title}>
                <div className="flex">
                  {breadcrumb.href ? (
                    <button
                      type="button"
                      className={clsx(
                        'text-sm font-medium text-gray-500 hover:text-gray-700',
                        bIndex < breadcrumbs.length - 1 && 'mr-4',
                      )}
                    >
                      {breadcrumb?.title}
                    </button>
                  ) : (
                    <p
                      className={clsx(
                        'text-sm font-medium text-gray-500',
                        bIndex < breadcrumbs.length - 1 && 'mr-4',
                      )}
                    >
                      {breadcrumb.title}
                    </p>
                  )}
                  {bIndex < breadcrumbs?.length - 1 && (
                    <ChevronRightIcon
                      className="size-5 shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {pageTitle}
          </h2>
        </div>

        <div className="mt-4 flex shrink-0 items-center gap-4 md:ml-4 md:mt-0 ">
          <p className="text-base font-semibold leading-7 text-gray-900">
            Total {selectedDeviceLength}
          </p>
          {pageHeadingButtons?.map((item, index) => (
            <Button
              type={item.type ?? 'button'}
              key={index}
              onClick={item.onClick}
              variant={item.variant ?? 'primary'}
              disabled={item.disabled}
            >
              {item.title}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PagingHeaderWithTotalCount;
