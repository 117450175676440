import { Button, ErrorMessage } from '@autosquare/common';

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import SchedulerDetailStatus from './SchedulerDetailForm/SchedulerDetailStatus';
import SchedulerDetailTestType from './SchedulerDetailForm/SchedulerDetailTestType';
import SchedulerDetailScenario from './SchedulerDetailForm/SchedulerDetailScenario';
import SchedulerDetailDevice from './SchedulerDetailForm/SchedulerDetailDevice';
import SchedulerDetailSchedule from './SchedulerDetailForm/SchedulerDetailSchedule';
import SchedulerDetailEmailAlarm from './SchedulerDetailForm/SchedulerDetailEmailAlarm';
import SchedulerDetailTitle from './SchedulerDetailForm/SchedulerDetailTitle';

import { RootState } from '@app/store';

import {
  useUpdateSchedulerMutation,
  useUpdateSchedulerStatusMutation,
} from '@lib/api/ide/scheduler/scheduler';

import { startTime } from '@utils/static/startTime';

import {
  SchedulerDetailData,
  SchedulerTypeServerName,
  UpdateSchedulerData,
  UpdateSchedulerSchema,
} from '@customTypes/ide/scheduler/scheduler';
import { ConnectionType, DeviceInfoOs } from '@customTypes/ide/device/device';
import { SupportedBrowserList } from '@customTypes/ide/browser/browser';
import { zodResolver } from '@hookform/resolvers/zod';
import { MailReceiver } from '@customTypes/ide/speedScheduler/speedScheduler';

interface Props {
  schedulerDetailData: SchedulerDetailData;
}

const SchedulerDetailForm = ({ schedulerDetailData }: Props) => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  const [isRunning, setIsRunning] = useState<boolean>(
    schedulerDetailData?.running,
  );

  const [emailAddressLists, setEmailAddressLists] = useState<MailReceiver[]>(
    schedulerDetailData?.mail_receivers,
  );

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  const environmentSplitValue = (index: number) =>
    schedulerDetailData?.environment.split('/')[index];
  const methods = useForm<UpdateSchedulerData>({
    defaultValues: {
      schedule_idx: schedulerDetailData?.idx,
      title: schedulerDetailData.title,
      scenario_idx_list: schedulerDetailData?.scenario_idx_list,
      infos:
        schedulerDetailData.type === SchedulerTypeServerName.Mobile
          ? {
              udid: environmentSplitValue(0),
              model: environmentSplitValue(1),
              os: environmentSplitValue(2) as DeviceInfoOs,
              connectionType: environmentSplitValue(3) as ConnectionType,
              serialNumber: environmentSplitValue(0),
            }
          : {
              browserName: environmentSplitValue(0) as SupportedBrowserList,
              version: environmentSplitValue(1),
            },
      start_time: startTime(new Date(schedulerDetailData?.start_time)),
      cron_cycle: schedulerDetailData?.cycle,
      auto_register: schedulerDetailData?.auto_register,
      mail_receivers: schedulerDetailData?.mail_receivers,
    },
    resolver: zodResolver(UpdateSchedulerSchema),
  });

  const schedulerListUrl = `/ide/projects/${projectIdx}/ui-test/scheduler/`;

  const updateSchedulerStatusMutation = useUpdateSchedulerStatusMutation({
    idx: schedulerDetailData?.idx,
    isRunning: isRunning,
    cookie: cookie,
  });

  const updateSchedulerMutation = useUpdateSchedulerMutation({
    cookie: cookie,
    url: schedulerListUrl,
  });

  const onSubmit: SubmitHandler<UpdateSchedulerData> = (data) => {
    if (data.scenario_idx_list.length === 0) {
      methods.setError('scenario_idx_list', {
        type: 'required',
        message: '시나리오를 선택해 주세요.',
      });
    } else {
      updateSchedulerStatusMutation.mutate(undefined, {
        onSuccess: () => {
          if (isRunning) {
            updateSchedulerMutation.mutate(data, {
              onSuccess: () => {
                navigate(schedulerListUrl);
              },
            });
          } else {
            navigate(schedulerListUrl);
          }
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
        className="mt-4 flow-root"
      >
        <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:pb-0">
          <SchedulerDetailStatus
            isRunning={isRunning}
            setIsRunning={setIsRunning}
          />
          <SchedulerDetailTestType type={schedulerDetailData?.type} />
          <SchedulerDetailTitle isRunning={isRunning} />
          <SchedulerDetailScenario
            testType={schedulerDetailData?.type}
            scenarioList={schedulerDetailData?.scenario_idx_list}
          />
          <SchedulerDetailDevice
            type={schedulerDetailData?.type}
            isRunning={isRunning}
          />
          <SchedulerDetailSchedule isRunning={isRunning} />
          <SchedulerDetailEmailAlarm
            emailAddressLists={emailAddressLists}
            setEmailAddressLists={setEmailAddressLists}
            isRunning={isRunning}
          />
        </div>
        <div className="mt-6 flex items-center justify-between gap-x-6">
          {updateSchedulerMutation.isError && (
            <ErrorMessage>{updateSchedulerMutation.error.message}</ErrorMessage>
          )}
          <div />
          <div className="flex items-center justify-between gap-x-6">
            <Button
              type="button"
              variant="secondary"
              onClick={() => navigate(schedulerListUrl)}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default SchedulerDetailForm;
