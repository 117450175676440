import { Button } from '@autosquare/common';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

type Props = {
  setIsTestExecutionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  testStepListlength: number;
};

const WebTestStepListHeader = ({
  setIsTestExecutionOpened,
  testStepListlength,
}: Props) => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Test Step List
        </h2>
      </div>
      <div className="relative flex gap-4">
        <div className="group">
          <Button
            type="button"
            onClick={() => setIsTestExecutionOpened(true)}
            variant="secondary"
            disabled={testStepListlength === 0}
          >
            Execute
          </Button>
          <div
            className={`absolute bottom-full mb-2 min-w-max rounded bg-black px-2.5 py-1 text-xs text-white ${
              testStepListlength === 0
                ? 'right-2/3 hidden group-hover:block group-hover:opacity-85'
                : 'hidden'
            }
                `}
          >
            해당 케이스를 실행할 수 없습니다.
          </div>
        </div>
        <Button
          type="button"
          variant="primary"
          onClick={() =>
            navigate(
              `/ide/projects/${projectIdx}/ui-test/web/test-step?idx=${idx}`,
            )
          }
        >
          Add test step
        </Button>
      </div>
    </div>
  );
};

export default WebTestStepListHeader;
