import { RectOrTwoPointsCoordinate } from '@customTypes/testStep/type';
import React from 'react';
import { Rect } from 'react-konva';

interface Props {
  coordinate: RectOrTwoPointsCoordinate;
}

const SelectedObject = ({ coordinate }: Props) => {
  return (
    <Rect
      x={Math.floor(coordinate?.x1)}
      y={Math.floor(coordinate?.y1)}
      width={Math.floor(Math.abs(coordinate?.x2 - coordinate?.x1))}
      height={Math.floor(Math.abs(coordinate?.y2 - coordinate?.y1))}
      fill="blue"
      opacity={0.3}
    />
  );
};

export default SelectedObject;
