import {
  ListBoxHeadlessInController,
  QuestionsMarkCircle,
  TooltipTop,
} from '@autosquare/common';

import {
  findTextReplaceAddNameByServerName,
  textReplaceAddList,
} from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';
import {
  findTextReplaceFromNameByServerName,
  textReplaceFromList,
} from '@utils/static/mobileTestStep/textReplace/textReplaceFromList';

import clsx from 'clsx';
import React, { useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  CreateMobileWebSpeedTestStepData,
  UpdateMobileWebSpeedTestStepData,
} from '@customTypes/ide/speedTest/Mobile/webTestStep/type';
import { isMacOs } from '@utils/static/isMacOs';

type Props = {
  watch: UseFormWatch<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
  setValue: UseFormSetValue<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
  register: UseFormRegister<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
  errors: FieldErrors<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
  control: Control<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
};

const KeyNameAndFromAndValue = ({
  watch,
  setValue,
  register,
  errors,
  control,
}: Props) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const currentIndex = watch('optionsDto')?.length - 1;

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  return (
    <>
      <div className="grid grid-cols-5 items-center justify-between">
        <label>Key Name*</label>
        <input
          type="text"
          className="input-base col-span-4"
          {...register(`optionsDto.${currentIndex}.keyName` as const)}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name={`optionsDto.${currentIndex}.keyName`}
        render={({ message }) => <p className="error-message">{message}</p>}
      />
      <div className="relative grid grid-cols-5 items-center justify-between">
        <div className="flex items-center gap-1">
          <span>From</span>
          <div className="flex items-center">
            <button
              type="button"
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
            >
              <QuestionsMarkCircle />
            </button>
          </div>
          {tooltipVisible && (
            <TooltipTop
              positionClass={clsx(
                'bottom-10 left-0',
                isMacOs ? 'after:left-[16%]' : 'after:left-[14%]',
              )}
            >
              값을 추출하여 지정한 값으로 치환하는 기능입니다.
            </TooltipTop>
          )}
        </div>
        <div className="col-span-4">
          <Controller
            control={control}
            name={`optionsDto.${currentIndex}.from` as const}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findTextReplaceFromNameByServerName(field.value)}
                onChange={field.onChange}
                lists={textReplaceFromList}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        </div>
      </div>
      <div className="grid grid-cols-5 items-center justify-between">
        <span>Value*</span>
        <div className="col-span-4 grid grid-cols-2 gap-2">
          <Controller
            control={control}
            name={`optionsDto.${currentIndex}.addKey` as const}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findTextReplaceAddNameByServerName(field.value)}
                onChange={field.onChange}
                lists={textReplaceAddList}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
          <input
            type="number"
            className="input-base"
            {...register(`optionsDto.${currentIndex}.addValue` as const)}
            onChange={(e) => {
              const value = e.target.valueAsNumber;
              setValue(
                `optionsDto.${currentIndex}.addValue`,
                isNaN(value) ? undefined : value,
              );
            }}
          />
        </div>
      </div>
      <ErrorMessage
        errors={errors}
        name={`optionsDto.${currentIndex}.addValue`}
        render={({ message }) => <p className="error-message">{message}</p>}
      />
    </>
  );
};

export default KeyNameAndFromAndValue;
