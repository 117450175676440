import {
  ListBoxHeadlessInController,
  QuestionsMarkCircle,
  TooltipTop,
  useDeviceSize,
} from '@autosquare/common';

import {
  dateFormatDayList,
  dateFormatMonthList,
  dateFormatSeparatorList,
  dateFormatYearList,
  findDateFormatDayNameByServerName,
  findDateFormatMonthNameByServerName,
  findDateFormatSeparatorNameByServerName,
  findDateFormatYearNameByServerName,
} from '@utils/static/mobileTestStep/textReplace/dateFormatList';
import { isMacOs } from '@utils/static/isMacOs';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import {
  DayServerName,
  MonthServerName,
  SeparatorServerName,
  YearServerName,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const TextReplaceDateFormat = () => {
  const [selectedYear, setSelectedYear] = useState<YearServerName>(
    YearServerName.None,
  );
  const [selectedMonth, setSelectedMonth] = useState<MonthServerName>(
    MonthServerName.None,
  );
  const [selectedDay, setSelectedDay] = useState<DayServerName>(
    DayServerName.None,
  );
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const {
    control,
    watch,
    setValue,
    resetField,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const separator = watch('optionsDto.textReplaceOptions.separator');
  const dateFormatError =
    errors?.optionsDto?.textReplaceOptions?.dateFormat?.message;

  const { width: deviceWidth } = useDeviceSize();

  useEffect(() => {
    const dateFormat =
      separator === SeparatorServerName.None
        ? `${selectedYear}${selectedMonth}${selectedDay}`
        : separator === SeparatorServerName.Space
          ? `${selectedYear}${(selectedYear && selectedMonth) || (selectedYear && selectedDay) ? ' ' : ''}${selectedMonth}${selectedMonth && selectedDay ? ' ' : ''}${selectedDay}`
          : `${selectedYear}${(selectedYear && selectedMonth) || (selectedYear && selectedDay) ? findDateFormatSeparatorNameByServerName(separator) : ''}${selectedMonth}${selectedMonth && selectedDay ? findDateFormatSeparatorNameByServerName(separator) : ''}${selectedDay}`;

    setValue('optionsDto.textReplaceOptions.dateFormat', dateFormat);
  }, [selectedYear, selectedMonth, selectedDay, separator, setValue]);

  useEffect(() => {
    if (
      dateFormatError &&
      (selectedYear !== YearServerName.None ||
        selectedMonth !== MonthServerName.None ||
        selectedDay !== DayServerName.None)
    ) {
      clearErrors('optionsDto.textReplaceOptions.dateFormat');
      return () => clearErrors('optionsDto.textReplaceOptions.dateFormat');
    }
  }, [selectedYear, selectedMonth, selectedDay, dateFormatError, clearErrors]);

  useEffect(() => {
    return () => resetField('optionsDto.textReplaceOptions.dateFormat');
  }, [resetField]);

  const dateFormatList = [
    {
      idx: 1,
      title: 'Year',
      listbox: (
        <ListBoxHeadlessInController
          value={selectedYear}
          buttonValue={findDateFormatYearNameByServerName(selectedYear)}
          onChange={setSelectedYear}
          lists={dateFormatYearList}
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      ),
    },
    {
      idx: 2,
      title: 'Month',
      listbox: (
        <ListBoxHeadlessInController
          value={selectedMonth}
          buttonValue={findDateFormatMonthNameByServerName(selectedMonth)}
          onChange={setSelectedMonth}
          lists={dateFormatMonthList}
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      ),
    },
    {
      idx: 3,
      title: 'Day',
      listbox: (
        <ListBoxHeadlessInController
          value={selectedDay}
          buttonValue={findDateFormatDayNameByServerName(selectedDay)}
          onChange={setSelectedDay}
          lists={dateFormatDayList}
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      ),
    },
    {
      idx: 4,
      title: 'Separator',
      listbox: (
        <Controller
          control={control}
          name="optionsDto.textReplaceOptions.separator"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={findDateFormatSeparatorNameByServerName(field.value)}
              onChange={field.onChange}
              lists={dateFormatSeparatorList}
              valueToSave={'serverName'}
              valueToShow={'name'}
            />
          )}
        />
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <div className="relative flex items-center justify-start gap-2">
        <p>Date Format</p>
        <div className="flex items-center">
          <button
            type="button"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
          >
            <QuestionsMarkCircle />
          </button>
        </div>
        {tooltipVisible && deviceWidth > 1350 ? (
          <TooltipTop
            positionClass={`left-0 bottom-7 ${isMacOs ? 'after:left-[30%]' : 'after:left-[26.5%]'}`}
          >
            사용자가 변환한 값의 날짜 형식을 지정해주는 기능입니다.
          </TooltipTop>
        ) : (
          tooltipVisible &&
          deviceWidth <= 1350 && (
            <TooltipTop
              positionClass={`left-0 bottom-7 ${isMacOs ? 'after:left-[50%]' : 'after:left-[45%]'} `}
            >
              <p>사용자가 변환한 값의 날짜 형식을</p>
              <p>지정해주는 기능입니다.</p>
            </TooltipTop>
          )
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 text-xs font-normal leading-3 text-gray-500">
        {dateFormatList?.map((dateFormat) => (
          <div key={dateFormat.idx}>
            <p className="py-1.5 pl-3 pr-1">{dateFormat.title}</p>
            {dateFormat.listbox}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextReplaceDateFormat;
