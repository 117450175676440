import { ResetAndConfirmPassword } from '@autosquare/common/src/utils/type/resetPassword/type';
import { ErrorMessage } from '@hookform/error-message';
import { passwordResetForm } from '@utils/static/dashboard/ResetPasswordForm';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const PasswordForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ResetAndConfirmPassword>();
  return (
    <>
      {passwordResetForm.map((form) => (
        <div key={form.id} className="w-full pb-4">
          <div className="flex items-center justify-center space-x-3 px-3">
            <div className="grow">
              <label htmlFor={form.id} className="my-1 block">
                {form.labelTitle}
              </label>
              <input
                type="password"
                id={form.id}
                className="input-base"
                {...register(form.name, {
                  required: '변경할 비밀번호를 입력해 주세요.',
                  pattern: {
                    value: form.pattern,
                    message: form.message,
                  },
                })}
              />
              <ErrorMessage
                name={form.name}
                errors={errors}
                render={({ message }) => (
                  <p className="error-message py-2">{message}</p>
                )}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PasswordForm;
