import { ScenarioListDetailData } from '@autosquare/common';

import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { webTestScenarioKeys } from '@queries/ide/web/webTestScenarioKeys';
import {
  CreateTestScenarioData,
  UpdateTestScenarioData,
} from 'src/types/testScenario/type';
import { schedulerKeys } from '@queries/ide/schedulerKeys';

interface UseWebTestScenarioQuery
  extends UseQueryOptions<ScenarioListDetailData[]> {
  idx: string;
}

interface CreateWebTestScenarioMutation extends UseMutationOptions {
  projectIdx: string;
}

// GET
const webTestScenario = async (
  idx: string,
): Promise<ScenarioListDetailData[]> => {
  const response = await axiosInstance.get<ScenarioListDetailData[]>(
    IDEAPI.GET_ALL_WEB_TEST_SCENARIO + idx,
  );
  return response.data;
};
export const useWebTestScenarioQuery = ({ idx }: UseWebTestScenarioQuery) =>
  useQuery<ScenarioListDetailData[], Error>({
    queryKey: webTestScenarioKeys.list(idx),
    queryFn: () => webTestScenario(idx),
  });

const webTestScenarioDetail = async (
  idx: string,
): Promise<ScenarioListDetailData> => {
  const response = await axiosInstance.get<ScenarioListDetailData>(
    IDEAPI.GET_EACH_WEB_TEST_SCENARIO + idx,
  );
  return response.data;
};
export const useWebTestScenarioDetailQuery = (idx: string) =>
  useQuery<ScenarioListDetailData, Error>({
    queryKey: webTestScenarioKeys.detail(idx),
    queryFn: () => webTestScenarioDetail(idx),
  });

// POST
const createWebTestScenario = async (
  projectIdx: string,
  data: CreateTestScenarioData,
): Promise<CreateTestScenarioData> => {
  const response = await axiosInstance.post(
    IDEAPI.CREATE_WEB_TEST_SCENARIO + projectIdx,
    data,
  );
  return response.data;
};
export const useCreateWebTestScenarioMutation = ({
  projectIdx,
}: CreateWebTestScenarioMutation) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  return useMutation<CreateTestScenarioData, Error, CreateTestScenarioData>({
    mutationFn: (data) => createWebTestScenario(projectIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestScenarioKeys.lists());
      navigate(location.pathname.split('/').slice(0, -1).join('/'));
    },
  });
};

// PUT
const updateWebTestScenario = async (
  scenarioIdx: string,
  data: UpdateTestScenarioData,
): Promise<UpdateTestScenarioData> => {
  const reponse = await axiosInstance.put<UpdateTestScenarioData>(
    IDEAPI.UPDATE_WEB_TEST_SCENARIO + scenarioIdx,
    data,
  );
  return reponse.data;
};
export const useUpdateWebTestScenarioMutation = (scenarioIdx: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<UpdateTestScenarioData, Error, UpdateTestScenarioData>({
    mutationFn: (data) => updateWebTestScenario(scenarioIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestScenarioKeys.all);
      navigate(location.pathname.split('/').slice(0, -1).join('/'));
    },
  });
};

const deleteWebTestScenario = async (idxList: number[]): Promise<unknown> => {
  const response = await axiosInstance.delete(IDEAPI.DELETE_WEB_TEST_SCENARIO, {
    data: { idxList: idxList },
  });
  return response.data;
};
export const useDeleteWebTestScenarioMutation = (idxList: number[]) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error>({
    mutationFn: () => deleteWebTestScenario(idxList),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestScenarioKeys.lists());
      queryClient.invalidateQueries(schedulerKeys.lists());
    },
  });
};
