import { Button } from '@autosquare/common';
import { useGroupContext } from '@contexts/ide/GroupProvider';
import {
  CreateTestCaseGroup,
  TestCaseGroup,
} from '@customTypes/testCaseGroup/type';
import { DialogPanel, DialogTitle } from '@headlessui/react';
import { ErrorMessage } from '@hookform/error-message';
import useDebounce from '@hooks/group/useDebouce';
import {
  useCreateTestCaseServiceGroupMutation,
  useGetTestCaseServiceGroupListQuery,
} from '@lib/api/ide/serviceGroup/serviceGroup';
import {
  useCreateTestCaseGroupMutation,
  useTestCaseGroupListQuery,
} from '@lib/api/ide/testGroup/testCaseGroup';
import { QueryObserverResult } from '@tanstack/react-query';
import { checkForDuplicateCreate } from '@utils/static/checkForDuplicate';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const CreateNewGroup = ({ setIsOpen }: Props) => {
  const { projectIdx } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    control,
    setError,
  } = useForm<CreateTestCaseGroup>({
    defaultValues: {
      projectIdx: Number(projectIdx),
    },
    mode: 'onSubmit',
  });
  const { clickGroupType } = useGroupContext();

  const createTestCaseGroup = useCreateTestCaseGroupMutation();
  const createTestCaseServiceGroup = useCreateTestCaseServiceGroupMutation();
  const testGroupListQuery = useTestCaseGroupListQuery({
    enabled: clickGroupType === 'Test',
  });
  const serviceGroupListQuery = useGetTestCaseServiceGroupListQuery({
    enabled: clickGroupType === 'Service',
  });

  const testGroupList = testGroupListQuery.data || [];
  const serviceGroupList = serviceGroupListQuery.data || [];

  const successHandler = () => {
    setIsOpen(false);
    reset();
    clearErrors('name');
  };

  const groupTitle = useWatch({ control, name: 'name' }) || '';
  const debouncedTitle = useDebounce(groupTitle, 500);

  const handleGroupCreation = (
    groupList: TestCaseGroup[],
    createMutation:
      | ReturnType<typeof useCreateTestCaseGroupMutation>
      | ReturnType<typeof useCreateTestCaseServiceGroupMutation>,
    refetchQuery: () => Promise<QueryObserverResult<TestCaseGroup[], Error>>,
    data: CreateTestCaseGroup,
  ) => {
    if (checkForDuplicateCreate(groupList, groupTitle, setError, clearErrors))
      return;
    createMutation.mutate(data, {
      onSuccess: successHandler,
      onError: () => {
        refetchQuery();
      },
    });
  };

  const onSubmit: SubmitHandler<CreateTestCaseGroup> = (data) => {
    if (groupTitle.length === 0 || groupTitle === '') {
      setTimeout(() => {
        setError('name', {
          type: 'required',
          message: '그룹명을 입력해 주세요.',
        });
      }, 500);
      return;
    }

    if (clickGroupType === 'Test') {
      handleGroupCreation(
        testGroupList,
        createTestCaseGroup,
        testGroupListQuery.refetch,
        data,
      );
    } else if (clickGroupType === 'Service') {
      handleGroupCreation(
        serviceGroupList,
        createTestCaseServiceGroup,
        serviceGroupListQuery.refetch,
        data,
      );
    }
  };

  useEffect(() => {
    if (
      clickGroupType === 'Test' &&
      checkForDuplicateCreate(
        testGroupList,
        debouncedTitle,
        setError,
        clearErrors,
      )
    ) {
      createTestCaseGroup.reset();
    }
  }, [debouncedTitle, clickGroupType, testGroupList]);

  useEffect(() => {
    if (
      clickGroupType === 'Service' &&
      checkForDuplicateCreate(
        serviceGroupList,
        debouncedTitle,
        setError,
        clearErrors,
      )
    ) {
      createTestCaseServiceGroup.reset();
    }
  }, [debouncedTitle, clickGroupType, serviceGroupList]);

  return (
    <>
      <div className="fixed inset-0 bg-white/50" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="mx-auto w-[102rem] max-w-lg rounded-lg bg-white shadow-lg">
          <DialogTitle className="text-md mb-3 font-medium leading-6 text-gray-900">
            <header className="mb-4 rounded-t-lg border-b border-gray-200 bg-congress-blue p-4 text-base font-semibold leading-6 text-white">
              Create New {clickGroupType ?? `Test`} Group
            </header>
          </DialogTitle>
          <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="max-h-96 overflow-y-auto  border-gray-900/10 px-2 pb-5">
              <label
                htmlFor="create-group-title"
                className="mb-3 block text-sm font-medium leading-6 text-gray-900"
              >
                Title
              </label>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                id="create-group-title"
                {...register('name')}
                autoFocus
              />
              {createTestCaseGroup.isError ? (
                <p className="ml-1 mt-2 text-sm text-red-500">
                  {createTestCaseGroup.error.message}
                </p>
              ) : createTestCaseServiceGroup.isError ? (
                <p className="ml-1 mt-2 text-sm text-red-500">
                  {createTestCaseServiceGroup.error.message}
                </p>
              ) : (
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => (
                    <p className="ml-1 mt-2 text-sm text-red-500">{message}</p>
                  )}
                />
              )}
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-3 border-t px-4 pt-5">
              <Button
                type="button"
                variant="secondary"
                noRing={true}
                onClick={() => successHandler()}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </DialogPanel>
      </div>
    </>
  );
};

export default CreateNewGroup;
