import React, { ReactNode } from 'react';

type Props = { colSpan: number; children: ReactNode };

const ErrorMessageInTableBody = ({ colSpan, children }: Props) => {
  return (
    <tr>
      <td colSpan={colSpan} className="py-4 text-center text-sm text-red-500">
        {children}
      </td>
    </tr>
  );
};

export default ErrorMessageInTableBody;
