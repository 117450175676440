import { IDEAPI } from '@api/ideEndpoints';
import {
  CreateSpeedSchedulerData,
  DeleteSpeedSchedulerData,
  SpeedSchedulerDetailData,
  UpdateSpeedSchedulerData,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { speedSchedulerKeys } from '@queries/ide/speedSchedulerKeys';
import { axiosInstance, axiosLocalInstance } from '..';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  useMutation,
} from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

const speedSchedulerBaseUrl = IDEAPI.SPEED_TEST_SCHEDULER;
const speedSchedulerLocalBaseUrl = IDEAPI.SPEED_TEST_LOCAL_SCHEDULER;

// GET
const getSpeedSchedulerList = async (
  projectIdx: string,
): Promise<SpeedSchedulerDetailData[]> => {
  const response = await axiosInstance.get<SpeedSchedulerDetailData[]>(
    `${speedSchedulerBaseUrl}?project=${projectIdx}`,
  );
  return response.data;
};
export const useSpeedSchedulerListQuery = (
  options?: UseQueryOptions<SpeedSchedulerDetailData[], Error>,
) => {
  const { projectIdx } = useParams();

  return useQuery<SpeedSchedulerDetailData[], Error>({
    queryKey: speedSchedulerKeys.list(projectIdx),
    queryFn: () => getSpeedSchedulerList(projectIdx),
    ...options,
  });
};

const getSpeedSchedulerDetail = async (
  idx: string,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosInstance.get<SpeedSchedulerDetailData>(
    `${speedSchedulerBaseUrl}/${idx}`,
  );
  return response.data;
};
export const useSpeedSchedulerDetailQuery = (
  options?: UseQueryOptions<SpeedSchedulerDetailData, Error>,
) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<SpeedSchedulerDetailData, Error>({
    queryKey: speedSchedulerKeys.detail(idx),
    queryFn: () => getSpeedSchedulerDetail(idx),
    ...options,
  });
};

// POST
const createSpeedScheduler = async (
  cookie: string,
  data: CreateSpeedSchedulerData,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosLocalInstance.post<SpeedSchedulerDetailData>(
    speedSchedulerLocalBaseUrl,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useCreateSpeedSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<SpeedSchedulerDetailData, Error, CreateSpeedSchedulerData>(
    {
      mutationFn: (data) => createSpeedScheduler(cookie, data),
      onSuccess: () => {
        queryClient.invalidateQueries(speedSchedulerKeys.all);
        navigate(pathname.split('/').slice(0, -1).join('/'));
      },
    },
  );
};

// PUT
const updateSpeedScheduler = async (
  cookie: string,
  data: UpdateSpeedSchedulerData,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosLocalInstance.put<SpeedSchedulerDetailData>(
    speedSchedulerLocalBaseUrl,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useUpdateSpeedSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<SpeedSchedulerDetailData, Error, UpdateSpeedSchedulerData>(
    {
      mutationFn: (data) => updateSpeedScheduler(cookie, data),
      onSuccess: () => {
        queryClient.invalidateQueries(speedSchedulerKeys.all);
        navigate(pathname.split('/').slice(0, -1).join('/'));
      },
    },
  );
};

const updateSpeedSchedulerStatus = async (
  cookie: string,
  idx: number,
  isRunning: boolean,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosLocalInstance.put<SpeedSchedulerDetailData>(
    `${speedSchedulerLocalBaseUrl}/${idx}/${isRunning}`,
    undefined,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useUpdateSpeedSchedulerStatusMutation = () => {
  const queryClient = useQueryClient();

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<
    SpeedSchedulerDetailData,
    Error,
    { idx: number; isRunning: boolean }
  >({
    mutationFn: (data) =>
      updateSpeedSchedulerStatus(cookie, data.idx, data.isRunning),
    onSuccess: () => {
      queryClient.invalidateQueries(speedSchedulerKeys.lists());
    },
  });
};

const deleteSpeedScheduler = async (
  data: DeleteSpeedSchedulerData,
  cookie: string,
): Promise<unknown> => {
  const response = await axiosLocalInstance.put<unknown>(
    `${speedSchedulerLocalBaseUrl}/delete`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useDeleteSpeedSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<unknown, Error, DeleteSpeedSchedulerData>({
    mutationFn: (data) => deleteSpeedScheduler(data, cookie),
    onSuccess: () => {
      queryClient.invalidateQueries(speedSchedulerKeys.lists());
      navigate(`/ide/projects/${projectIdx}/speed-test/scheduler`);
    },
  });
};
