import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import {
  ArrowPathIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';

import { BrowserInfo } from '@customTypes/ide/browser/browser';
import { UseQueryResult } from '@tanstack/react-query';

interface Props {
  browserInfoDto: BrowserInfo;
  handleChange: (selectedBrowser: BrowserInfo) => void;
  webBrowserListQuery: UseQueryResult<BrowserInfo[], Error>;
  disabled?: boolean;
}

const BrowserListBox = ({
  browserInfoDto,
  handleChange,
  webBrowserListQuery,
  disabled = false,
}: Props) => {
  const browserList = webBrowserListQuery.data;

  const isDisabled =
    webBrowserListQuery.isLoading || webBrowserListQuery.isFetching || disabled;
  return (
    <>
      <Listbox
        value={browserInfoDto || null}
        onChange={handleChange}
        disabled={isDisabled}
      >
        {({ open }) => (
          <>
            <div className="relative w-full">
              <ListboxButton
                className={clsx(
                  'relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
                  isDisabled ? 'bg-gray-100' : 'bg-white',
                )}
              >
                <span className="block truncate font-normal">
                  {browserInfoDto
                    ? `${browserInfoDto?.browserName} - ${browserInfoDto?.version}`
                    : 'Select a test browser'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="size-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                  {browserList?.length === 0 ? (
                    <div className="mx-2 my-1 flex items-center justify-start">
                      연결된 디바이스가 없습니다.
                    </div>
                  ) : (
                    browserList?.map((browser) => (
                      <ListboxOption
                        key={browser.browserName}
                        className={({ focus }) =>
                          clsx(
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            focus
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                          )
                        }
                        value={browser}
                      >
                        {({ selected, focus }) => (
                          <>
                            <span
                              className={clsx(
                                'block truncate',
                                selected ? 'font-semibold' : 'font-normal',
                              )}
                            >
                              {browser.browserName} - {browser.version}
                            </span>
                            {selected && (
                              <span
                                className={clsx(
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                  focus ? 'text-white' : 'text-indigo-600',
                                )}
                              >
                                <CheckIcon
                                  className="size-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </ListboxOption>
                    ))
                  )}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <div
        className={clsx(
          'size-9 rounded border border-solid border-gray-300 px-2 py-1',
          isDisabled
            ? 'cursor-default bg-gray-100'
            : 'cursor-pointer hover:bg-gray-50',
        )}
      >
        <ArrowPathIcon
          className={clsx('size-full', {
            'animate-spin':
              webBrowserListQuery.isLoading || webBrowserListQuery.isFetching,
          })}
          onClick={() => {
            if (!isDisabled) {
              webBrowserListQuery.refetch();
            }
          }}
        />
      </div>
    </>
  );
};

export default BrowserListBox;
