import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@app/store';

import {
  getPageSource,
  getSelectedNumberArray,
} from '@store/ide/mobileTestStep/pageSourcesSlice';

import { DeviceInfoOs } from '@customTypes/ide/device/device';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { useFormContext } from 'react-hook-form';
import {
  CreateTestStepData,
  CreateTestStepDataOptionsDto,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

type ValueNameKeys =
  | keyof Pick<CreateTestStepData, 'value'>
  | `optionsDto.${keyof Pick<
      CreateTestStepDataOptionsDto,
      'actionValue' | 'passActionValue' | 'failActionValue'
    >}`;

interface Props {
  command: MobileCommandOptionServerName;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  valueName?: ValueNameKeys;
}

interface Value {
  class?: string;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface KeysArray {
  aos: string[];
  ios: string[];
}

const INITIAL_VALUES = {
  [MobileCommandOptionServerName.SaveText]: 'Select Element',
  [MobileCommandOptionServerName.ConfirmText]: 'Select Element',
  [MobileCommandOptionServerName.InputText]: 'Select Element',
  Default: 'Value',
};

type Command = 'common' | 'textCommand';

const KEYS_ARRAY: Record<Command, KeysArray> = {
  common: {
    aos: ['resource-id', 'content-desc', 'text'],
    ios: ['name', 'label', 'value'],
  },
  textCommand: {
    aos: ['resource-id', 'content-desc'],
    ios: ['name'],
  },
};

export const useTestStepValueList = ({
  command,
  setValue,
  valueName,
}: Props) => {
  const [totalValues, setTotalValues] = useState<string[]>([]);
  const [bounds, setBounds] = useState<string>('');

  const { setValue: setHookFormValue } = useFormContext<CreateTestStepData>();

  const dispatch = useDispatch();
  const selectedObj = useSelector(
    (state: RootState) => state.pageSources.pageSourceObj,
  );
  const selectedCoordinate = useSelector(
    (state: RootState) => state.pageSources.selectedCoordinate,
  );
  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const determineInitialValue = (commandName: MobileCommandOptionServerName) =>
    INITIAL_VALUES[commandName] || INITIAL_VALUES.Default;

  // React-Hook-Form Rednering 시 valueName 초기화
  useEffect(() => {
    if (valueName) {
      setHookFormValue(valueName, undefined);
      return () => {
        setHookFormValue(valueName, undefined);
      };
    }
  }, [valueName, setHookFormValue]);

  // React-Hook-Form 사용하지 않을 때 setValue 초기화
  useEffect(() => {
    if (setValue) {
      setValue(determineInitialValue(command));
      return () => {
        setValue(undefined);
      };
    }
  }, [command, setValue]);

  // 선택된 좌표 값 bounds에 저장
  useEffect(() => {
    setBounds(
      `${selectedCoordinate.x1},${selectedCoordinate.y1},${selectedCoordinate.x2},${selectedCoordinate.y2}`,
    );
  }, [selectedCoordinate]);

  // totalValues array 정제 과정
  useEffect(() => {
    const filterSubsets = (array: string[]): string[] =>
      array.filter((item, index) => array.indexOf(item) === index);

    if (
      command === MobileCommandOptionServerName.SaveText ||
      command === MobileCommandOptionServerName.ConfirmText
    ) {
      const deleteNoTextXpathList = [...selectedObj]?.filter(
        (obj) => obj.text || obj.label || obj.value,
      );

      let valueArray: string[] = [];
      let numbersArray: string[] = []; // save text에서 숫자를 추출할 때 사용
      const keysArray = KEYS_ARRAY.textCommand;
      if (deleteNoTextXpathList.length === 0) {
        valueArray = ['해당 객체는 텍스트를 저장할 수 없습니다.'];
      } else {
        deleteNoTextXpathList?.reverse().forEach((value) => {
          const newValueArray = getFilteredXpaths(value, keysArray, true);
          valueArray = [...valueArray, ...newValueArray];
        });

        const numberArray = deleteNoTextXpathList?.reduce((acc, item) => {
          const text =
            deviceInfo.os === DeviceInfoOs.Aos
              ? item.text
              : deviceInfo.os === DeviceInfoOs.Ios && item.label;

          const matches = text.toString().match(/\d+/g);
          if (matches) {
            acc.push(...matches);
          }
          return acc;
        }, []);
        numbersArray = numberArray;
      }
      setTotalValues(filterSubsets(valueArray));
      dispatch(getSelectedNumberArray(numbersArray));
    } else {
      let valueArray: string[] = [];
      const keysArray = KEYS_ARRAY.common;

      [...selectedObj]?.reverse().forEach((value) => {
        const newValueArray = getFilteredXpaths(value, keysArray, false);
        valueArray = [...valueArray, ...newValueArray];
      });
      setTotalValues(filterSubsets(valueArray));
    }
  }, [command, selectedObj]);

  useEffect(() => {
    return () => {
      dispatch(getPageSource([]));
      dispatch(getSelectedNumberArray([]));
    };
  }, []);

  const getFilteredXpaths = (
    value: Value,
    keysArray: KeysArray,
    isShort: boolean,
  ): string[] => {
    // eslint-disable-next-line prefer-const
    let valueArray: string[] = [];
    const keys: string[] =
      deviceInfo.os === DeviceInfoOs.Aos ? keysArray.aos : keysArray.ios;
    const nodeName: string =
      deviceInfo.os === DeviceInfoOs.Aos ? value.class : value.type;
    const xPathPrefix: string =
      deviceInfo.os === DeviceInfoOs.Aos
        ? `@package="${value.package}"`
        : undefined;

    keys.forEach((key) => {
      if (Object.hasOwn(value, key) && value?.[key] !== '') {
        const xpath = `//${nodeName}[@${key}="${value?.[key]}"`;
        if (xPathPrefix) {
          valueArray.push(`${xpath} and ${xPathPrefix}]`);
        } else {
          valueArray.push(`${xpath}]`);
        }
      }
    });

    valueArray.push(
      `${value?.['relative-xpath']}${xPathPrefix ? `[${xPathPrefix}]` : ''}`,
    );

    if (isShort) {
      valueArray.push(`//${nodeName}${xPathPrefix ? `[${xPathPrefix}]` : ''}`);
    }

    return valueArray;
  };

  return { totalValues, bounds };
};
