import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type IndexArray = {
  testCaseIndexArray: number[] | null;
  testScenarioIndexArray: number[] | null;
  testCaseIndexArrayInDetail: number[] | null;
};

const initialState: IndexArray = {
  testCaseIndexArray: [],
  testScenarioIndexArray: [],
  testCaseIndexArrayInDetail: [],
};

export const numberArraySlice = createSlice({
  name: "indexArr",
  initialState,
  reducers: {
    getTestCaseIndexArray: (state, action: PayloadAction<number[] | null>) => {
      state.testCaseIndexArray = action.payload;
    },
    getTestScenarioIndexArray: (
      state,
      action: PayloadAction<number[] | null>
    ) => {
      state.testScenarioIndexArray = action.payload;
    },
    getTestCaseIndexArrayInDetail: (
      state,
      action: PayloadAction<number[] | null>
    ) => {
      state.testCaseIndexArrayInDetail = action.payload;
    },
  },
});

export const {
  getTestCaseIndexArray,
  getTestScenarioIndexArray,
  getTestCaseIndexArrayInDetail,
} = numberArraySlice.actions;

export default numberArraySlice.reducer;
