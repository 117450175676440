import { UpdateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { parseCronCycle } from '@utils/static/parseCronCycle';
import { formatSelectedDays } from '@utils/static/selectedDays';

import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const SpeedSchedulerDetailPreview = () => {
  const { watch } = useFormContext<UpdateSpeedSchedulerData>();
  const startTime = watch('startTime');
  const cronCycle = watch('cronCycle');

  const formattedOnPart = formatSelectedDays(
    parseCronCycle(cronCycle).onPart.split(','),
  );
  const [onContent, setOnContent] = useState(formattedOnPart);

  useEffect(() => {
    const parsedCycle = parseCronCycle(cronCycle);
    const daysOfWeek =
      parsedCycle.daysOfWeek === '?'
        ? 'Every day'
        : formatSelectedDays(parsedCycle.daysOfWeek.split(','));
    setOnContent(daysOfWeek);
  }, [cronCycle]);

  const alert = onContent.includes('select');

  return (
    <div>
      <label
        htmlFor="preview"
        className="mb-2 block text-sm font-medium leading-6 text-gray-900"
      >
        Preview
      </label>
      <div className="group relative rounded-l-lg border p-3 text-sm">
        <div>
          <span className="mr-2">After:</span>
          <span className="mr-2">
            {dayjs(startTime).format('YYYY-MM-DD HH:mm')}
          </span>
        </div>
        <div>
          <span className="mr-2">On:</span>
          <span className={clsx('mr-2', alert && 'font-semibold text-red-500')}>
            {onContent}
          </span>
        </div>
        <div>
          <span className="mr-2">Run:</span>
          <span>{parseCronCycle(cronCycle).timeInterval}</span>
        </div>
        <div className="absolute bottom-20 right-5 mb-2 hidden min-w-max rounded bg-black px-2.5 py-1 text-xs text-white opacity-80 group-hover:block">
          {cronCycle}
        </div>
      </div>
    </div>
  );
};

export default SpeedSchedulerDetailPreview;
