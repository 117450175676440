import {
  DownloadButton,
  ImageSkeletonLoader,
  VideoSkeletonLoader,
} from '@autosquare/common';

import TestResultDetailError from '@components/shared/Error/TestResultDetailError';

import {
  useMobileTestResultImageData,
  useMobileTestResultLogData,
  useMobileTestResultVideoData,
} from '@lib/api/ide/mobile/mobileTestResult';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { downloadBlob } from '@utils/static/downloadBlob';

import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TabPanel } from '@headlessui/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type Props = {
  imagePaths: string[];
  videoPath: string;
  logPath: string;
};

const MobileResultsDetail = ({ videoPath, logPath, imagePaths }: Props) => {
  const videoData = useMobileTestResultVideoData(videoPath, {
    enabled: isWordInLocationPathname('mobile'),
  });
  const videoSrc = videoData?.data;

  const imageData = useMobileTestResultImageData(imagePaths, {
    enabled: isWordInLocationPathname('mobile'),
  });
  const imageSrc = imageData?.data;

  const logData = useMobileTestResultLogData(logPath, {
    enabled: isWordInLocationPathname('mobile'),
  });
  const logSrc = logData?.data;

  return (
    <>
      <TabPanel className="py-2">
        {videoData.isLoading ? (
          <VideoSkeletonLoader />
        ) : videoData.isError ? (
          <TestResultDetailError errorMessage={videoData.error.message} />
        ) : (
          <div className="flex flex-col items-center justify-center">
            {videoPath !== null && (
              <DownloadButton
                onClick={() =>
                  downloadBlob(videoSrc, videoPath.split('/').at(-1))
                }
              />
            )}
            <video
              autoPlay
              controls
              controlsList="nodownload"
              className="aspect-video"
            >
              <source src={videoSrc} type="video/mp4" />
              <track default kind="captions" />
            </video>
          </div>
        )}
      </TabPanel>
      <TabPanel className="py-2">
        {imageData.isLoading ? (
          <ImageSkeletonLoader />
        ) : imageData.isError ? (
          <TestResultDetailError errorMessage={imageData.error.message} />
        ) : (
          <Swiper
            slidesPerView={1}
            loop={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="size-full"
          >
            {imageSrc?.map((imagePath, index) => (
              <SwiperSlide
                key={imagePath}
                className="flex justify-center bg-white text-center text-sm"
              >
                <div className="flex items-center justify-center pb-8">
                  <div className="flex flex-col items-center justify-center">
                    {imagePath !== null && (
                      <DownloadButton
                        onClick={() =>
                          downloadBlob(
                            imagePath,
                            imagePaths[index].split('/').pop(),
                          )
                        }
                      />
                    )}
                    <img
                      src={imagePath}
                      className="block aspect-auto w-2/4 object-cover"
                      alt="screenshot"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </TabPanel>
      <TabPanel className="py-2">
        {logData.isError ? (
          <TestResultDetailError errorMessage={logData.error.message} />
        ) : (
          <div className="mt-4 text-center">
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              {logData.isLoading ? (
                <div className="flex w-full animate-pulse items-center justify-center gap-x-1.5 px-2.5 py-1.5">
                  <div className="h-6 w-72 rounded-md bg-gray-200 shadow-sm dark:bg-gray-700" />
                </div>
              ) : (
                logPath?.split('/')?.[logPath?.split('/').length - 1]
              )}
            </h3>
            <div className="mt-2">
              {logData.isLoading ? (
                <div className="mb-3 flex w-full animate-pulse items-center justify-center gap-x-1.5 px-2.5 py-1.5">
                  <div className="size-6 rounded-md bg-gray-200 shadow-sm dark:bg-gray-700" />
                </div>
              ) : (
                logPath !== null && (
                  <DownloadButton
                    onClick={() =>
                      downloadBlob(
                        logSrc,
                        logPath?.split('/')?.[logPath?.split('/').length - 1],
                      )
                    }
                  />
                )
              )}
            </div>
          </div>
        )}
      </TabPanel>
    </>
  );
};

export default MobileResultsDetail;
