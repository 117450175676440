import React, { useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import PointOrRectangleInput from '@components/shared/PointOrRectangleInput';

import {
  CreateTestStepData,
  CreateTestStepDataOptionsDto,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

type ValueKeys =
  | keyof CreateTestStepData
  | `optionsDto.${keyof CreateTestStepDataOptionsDto}`;

type Props = {
  value?: ValueKeys;
};

const ScrollSwipe = ({ value = 'value' }: Props) => {
  const {
    startX,
    startY,
    endX,
    endY,
    onChangeStartX,
    onChangeStartY,
    onChangeEndX,
    onChangeEndY,
    imageWidth,
    imageHeight,
  } = useContext(MobileTestStepContext);

  const { setValue } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setValue(value, `${startX},${startY},${endX},${endY}`);

    return () => {
      setValue(value, undefined);
    };
  }, [startX, startY, endX, endY, setValue]);

  const scrollSwipeProperties = [
    {
      labelTitle: 'startX',
      maxSize: imageWidth,
      value: startX,
      onChange: onChangeStartX,
    },
    {
      labelTitle: 'startY',
      maxSize: imageHeight,
      value: startY,
      onChange: onChangeStartY,
    },
    {
      labelTitle: 'endX',
      maxSize: imageWidth,
      value: endX,
      onChange: onChangeEndX,
    },
    {
      labelTitle: 'endY',
      maxSize: imageHeight,
      value: endY,
      onChange: onChangeEndY,
    },
  ];

  return (
    <PointOrRectangleInput
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      properties={scrollSwipeProperties}
      isHiddenInputArrow={true}
    />
  );
};

export default ScrollSwipe;
