import React from "react";
import { useLocation, Navigate, Link } from "react-router-dom";

import SignUpForm from "./components/SignUpForm";

import tsquareLogo from "@assets/img/tsquare_logo.png";

const SignUp = () => {
  const location = useLocation();

  const checkSignUpValidation = <Navigate to="/signup-error" />;

  return (
    <>
      {location.search.includes("?key=") ? (
        <>
          <div className="w-96 h-auto min-h-full mt-12">
            <div className="flex justify-center items-center w-full">
              <img
                src={tsquareLogo}
                alt="t-square logo"
                width={212}
                height={40}
              />
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <SignUpForm />
            </div>
            <hr className="mt-6 mb-10 border-t-[1px] border-solid border-border-lightGray" />
            <div className="text-center">
              <Link
                to="/login"
                className="text-sm leading-5 text-gray-500 hover:text-congress-blue"
              >
                로그인 화면으로 이동하기
              </Link>
            </div>
          </div>
        </>
      ) : (
        checkSignUpValidation
      )}
    </>
  );
};

export default SignUp;
