import { useInput } from '@autosquare/common';

import React, { useState, FormEvent } from 'react';
import { useParams } from 'react-router-dom';

import { useAddProjectMemberMutation } from '@lib/api/dashboard/project/project';

import { memberRole } from '@utils/static/memberRole';

import SelectMemberRole from '@components/shared/SelectMemberRole';

import EmailAddressInput from './EmailAddressInput';
import CancelSaveButton from './CancelSaveButton';

const AddProjectMemberForm = () => {
  const { projectIdx } = useParams();

  const [selectedRole, setSelectedRole] = useState(memberRole[0]?.name);
  const [emailAddress, onChangeEmailAddress] = useInput<string>('');

  // 에러검증
  const [checkData, setCheckData] = useState({ selectedRole: '', email: '' });
  const [roleErrorMessage, setRoleErrorMessage] = useState<string>(null);

  const data = {
    addUserId: emailAddress,
    addUserRole: selectedRole,
  };

  const addMemberMutation = useAddProjectMemberMutation(projectIdx);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCheckData({ selectedRole: selectedRole, email: emailAddress });
    setRoleErrorMessage(null);
    if (selectedRole !== memberRole[0]?.name) {
      addMemberMutation.mutate(data);
    } else {
      setRoleErrorMessage('Role을 선택해주세요');
    }
  };

  return (
    <div className="px-0">
      <div className="mt-8 flow-root">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12 sm:space-y-16">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Member Information
              </h2>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                프로젝트 멤버에 대한 정보를 입력 해주세요.
              </p>
              <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                <SelectMemberRole
                  selectedRole={selectedRole}
                  setSelectedRole={setSelectedRole}
                />
                <EmailAddressInput
                  onChangeEmailAddress={onChangeEmailAddress}
                />
              </div>
            </div>
          </div>
          <CancelSaveButton
            emailAddress={emailAddress}
            checkData={checkData}
            selectedRole={selectedRole}
            roleErrorMessage={roleErrorMessage}
            addMemberMutation={addMemberMutation}
          />
        </form>
      </div>
    </div>
  );
};

export default AddProjectMemberForm;
