import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const ScenarioDraggableElementContainer = ({ children }: Props) => {
  return (
    <div className="grid w-full grid-cols-2 items-center justify-between gap-x-4">
      {children}
    </div>
  );
};

export default ScenarioDraggableElementContainer;
