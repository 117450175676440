import { Breadcrumbs, useOnlyNumberInput } from '@autosquare/common';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import PagingHeaderWithTotalCount from '@components/TestExecution/PagingHeaderWithTotalCount';
import { BrowserInfo } from '@customTypes/ide/browser/browser';
import { getWebExecutionData } from '@store/ide/browser/selectedWebExecutionDataSlice';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import ExecutionInfo from './SpeedWebTestExecution/ExecutionInfo';
import Repeat from './SpeedWebTestExecution/Repeat';
import SelectBrowser from './SpeedWebTestExecution/SelectBrowser';

type Props = {
  idx: number;
  setIsReadyToNavigate: React.Dispatch<React.SetStateAction<boolean>>;
  testType: TestTypeCategory;
  divWidth: number;
};

const SpeedWebTestExecution = ({
  idx,
  setIsReadyToNavigate,
  testType,
  divWidth,
}: Props) => {
  const { projectIdx } = useParams();

  const {
    numberValue: numberRepeat,
    value: repeat,
    handleChange: onChangeRepeat,
  } = useOnlyNumberInput('12');

  const [selectedBrowser, setSelectedBrowser] = useState<BrowserInfo[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isSelectedBrowser, setIsSelectedBrowser] = useState(false);

  const checkedBrowserHandler = (value: BrowserInfo, isChecked: boolean) => {
    if (isChecked) {
      setSelectedBrowser([...selectedBrowser, value]);
    }
    if (!isChecked && selectedBrowser.includes(value)) {
      setSelectedBrowser(
        selectedBrowser.filter((selectedDevice) => selectedDevice !== value),
      );
    }
  };

  const checkHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: BrowserInfo,
  ) => {
    setIsChecked(!isChecked);
    checkedBrowserHandler(value, e.target.checked);
    setIsSelectedBrowser(false);
  };

  useEffect(() => {
    if (testType === 'case') {
      dispatch(
        getWebExecutionData({
          projectIdx: Number(projectIdx),
          caseIdx: idx,
          infos: undefined,
          repeat: numberRepeat,
        }),
      );
    }

    if (testType === 'scenario') {
      dispatch(
        getWebExecutionData({
          projectIdx: Number(projectIdx),
          scenarioIdx: idx,
          infos: undefined,
          repeat: numberRepeat,
        }),
      );
    }
  }, []);

  const dispatch = useDispatch();

  const executionTestOnClick = () => {
    if (
      selectedBrowser?.length === 0 ||
      numberRepeat < 3 ||
      numberRepeat > 50 ||
      repeat === undefined
    ) {
      setIsSelectedBrowser(true);
    } else {
      setIsSelectedBrowser(false);
      setIsReadyToNavigate(true);
    }
  };

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Speed Test' },
    { title: 'Web' },
    {
      title:
        testType === 'case'
          ? 'Test Case'
          : testType === 'scenario' && 'Test Scenario',
    },
    { title: 'Execution' },
  ];

  const pageHeadingButtons = [
    {
      title: 'Execution test',
      onClick: executionTestOnClick,
    },
  ];

  return (
    <>
      <PagingHeaderWithTotalCount
        breadcrumbs={breadcrumbs}
        pageTitle={
          testType === 'case'
            ? 'Test Case Execution'
            : testType === 'scenario' && 'Test Scenario Execution'
        }
        selectedDeviceLength={selectedBrowser.length}
        pageHeadingButtons={pageHeadingButtons}
      />
      <ExecutionInfo idx={idx} testType={testType} />
      <Repeat
        repeat={repeat}
        numberRepeat={numberRepeat}
        onChangeRepeat={onChangeRepeat}
        isSelectedBrowser={isSelectedBrowser}
      />
      <SelectBrowser
        selectedBrowser={selectedBrowser}
        setSelectedBrowser={setSelectedBrowser}
        checkHandler={checkHandler}
        isSelectedBrowser={isSelectedBrowser}
        setIsSelectedBrowser={setIsSelectedBrowser}
        divWidth={divWidth}
      />
    </>
  );
};

export default SpeedWebTestExecution;
