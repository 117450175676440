import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';
import {
  SpeedSchedulerTypeName,
  SpeedSchedulerTypeServerName,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { useSpeedSchedulerDetailQuery } from '@lib/api/ide/speedScheduler/speedScheduler';
import React from 'react';

const SpeedTestSchedulerDetailTestType = () => {
  const { data: speedSchedulerDetail } = useSpeedSchedulerDetailQuery();

  return (
    <SpeedSchedulerGridContainer title="Test Type">
      {speedSchedulerDetail.type === SpeedSchedulerTypeServerName.Mobile
        ? SpeedSchedulerTypeName.Mobile
        : SpeedSchedulerTypeName.Web}
    </SpeedSchedulerGridContainer>
  );
};

export default SpeedTestSchedulerDetailTestType;
