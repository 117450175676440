import { PasswordResetForm, regexLibrary } from '@autosquare/common';

export const passwordResetForm: PasswordResetForm[] = [
  {
    labelTitle: 'Password',
    id: 'password',
    name: 'password',
    pattern: regexLibrary.password,
    message: '8자~16자의 영문 대 소문자, 숫자, 기호를 사용해 주세요.',
  },
  {
    labelTitle: 'Confirm Password',
    id: 'confirm-password',
    name: 'confirmPassword',
  },
];
