import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface InitialLocation {
  clickLocation: string | null;
}

const initialState: InitialLocation = {
  clickLocation: null,
};

export const locationHistorySlice = createSlice({
  name: 'location history',
  initialState,
  reducers: {
    getClickLocation: (state, action: PayloadAction<string | null>) => {
      state.clickLocation = action.payload;
    },
  },
});

export const { getClickLocation } = locationHistorySlice.actions;

export default locationHistorySlice.reducer;
