import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import DetailDraggable from "./DetailDnd/DetailDraggable";

const DetailDnd = () => {
  return (
    <div className="flex w-full text-sm">
      <DndProvider backend={HTML5Backend}>
        <DetailDraggable />
      </DndProvider>
    </div>
  );
};

export default DetailDnd;
