import { RootState } from '@app/store';
import React from 'react';
import { useSelector } from 'react-redux';
import SettingsSidebarList from './SettingsSidebarList';

type Props = {
  settingsMenu?: string;
  setSettingsMenu?: React.Dispatch<React.SetStateAction<string>>;
};

const SidebarInSettings = ({ settingsMenu, setSettingsMenu }: Props) => {
  const accessToken: string | object = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  const settingsBarList = [
    {
      name: 'Settings',
      children: [
        {
          name: 'Application',
          base: `application-settings`,
          enable: true,
        },
        {
          name: 'iOS Test',
          enable: window.navigator.userAgentData.platform.startsWith('mac')
            ? true
            : false,
        },
        {
          name: 'Alarm',
          enable: true,
        },
      ],
    },
  ];

  const filteredSettingsBarList = settingsBarList
    .map((item) => {
      if (!accessToken && item.children) {
        const filteredChildren = item.children.filter(
          (child) => child.base === 'application-settings',
        );
        return filteredChildren.length > 0
          ? { ...item, children: filteredChildren }
          : null;
      }
      return item;
    })
    .filter((item) => item !== null);

  return (
    <aside className="mt-3 w-96 border-r border-gray-200 pl-6">
      <div className="flex flex-col gap-y-5 bg-white pr-6">
        <ul role="list" className="-mx-2 space-y-1">
          {filteredSettingsBarList?.map((item, index) => (
            <li key={index}>
              {!item.children ? (
                <>
                  <div
                    className={`block cursor-pointer rounded-md py-2 pl-10 pr-2 text-sm font-semibold leading-6 text-gray-700`}
                    onClick={() => setSettingsMenu(item.name)}
                  >
                    {item.name}
                  </div>
                </>
              ) : (
                <SettingsSidebarList
                  item={item}
                  settingsMenu={settingsMenu}
                  setSettingsMenu={setSettingsMenu}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default SidebarInSettings;
