import { useMobileTestCaseDetailQuery } from '@lib/api/ide/mobile/mobileTestCase';

import { UpdateMobileTestCaseData } from '@customTypes/testCase/type';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import TestCaseInfoTop from './TestCaseInfoForm/TestCaseInfoTop';
import TestCaseInfoBottom from './TestCaseInfoForm/TestCaseInfoBottom';

const TestCaseInfoForm = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const mobileTestCaseDetailQuery = useMobileTestCaseDetailQuery(idx);
  const mobileTestCaseDetail = mobileTestCaseDetailQuery.data;

  const { setValue, reset } = useFormContext<UpdateMobileTestCaseData>();

  useEffect(() => {
    setValue('caseGroupIdx', mobileTestCaseDetail?.caseGroupInfo?.caseGroupIdx);
    setValue('title', mobileTestCaseDetail?.title);
    setValue('description', mobileTestCaseDetail?.description);
    setValue('priority', mobileTestCaseDetail?.priority);
    setValue('platformType', mobileTestCaseDetail?.platformType);
    return () => {
      reset();
    };
  }, [setValue, reset, mobileTestCaseDetail]);

  return (
    <div className="mt-1">
      <TestCaseInfoTop />
      <TestCaseInfoBottom />
    </div>
  );
};

export default TestCaseInfoForm;
