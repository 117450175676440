import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { API } from "@api/endpoints";
import { axiosInstance } from "@lib/api/dashboard";

import { useInput } from "@autosquare/common";

import {
  isInputValidSignUp,
  validatePassword,
  startUserNameBlankCheck,
  validateUserName,
} from "@utils/validation";
import {
  useSignUpMutation,
  useUserInfoByDecodeKeyQuery,
} from "@lib/api/dashboard/user/users";

const SignUpForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const decodeKey = searchParams.get("key");

  const decodeKeyQuery = useUserInfoByDecodeKeyQuery(decodeKey);
  const emailAddress = decodeKeyQuery.data?.userId;

  const [name, nameOnChange] = useInput("");
  const [password, passwordOnChange] = useInput("");
  const [repeatPassword, repeatPasswordOnChange] = useInput("");
  const [compareInputValues, setCompareInputValues] = useState<{
    name: string;
    password: string;
  }>({
    name: null,
    password: null,
  });

  const data = {
    key: decodeKey,
    userId: emailAddress,
    name: name,
    password: password,
  };

  const signUpMutation = useSignUpMutation();

  // submit form event
  const submitSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signUpMutation.mutate(data, {
      onError: () => {
        setCompareInputValues({ name: name, password: password });
      },
    });
  };

  const formValues = [
    {
      title: "Email address",
      label: "userId",
      inputType: "text",
      inputClassName: "bg-nav-text",
      defaultValue: emailAddress,
      isDisabled: true,
      checkValidation: "",
    },
    {
      title: "Name",
      label: "name",
      inputType: "text",
      inputClassName: "",
      handleInput: nameOnChange,
      defaultValue: "",
      isDisabled: false,
      checkValidation: startUserNameBlankCheck(name)
        ? "공백으로 시작할 수 없습니다."
        : !validateUserName(name) && name.length > 0
        ? "한글 또는 영어 이름을 입력해주세요."
        : null,
    },
    {
      title: "Password",
      label: "password",
      inputType: "password",
      inputClassName: "",
      handleInput: passwordOnChange,
      defaultValue: "",
      isDisabled: false,
      checkValidation:
        !validatePassword(password) &&
        password.length > 0 &&
        "8자~16자의 영문 대 소문자, 숫자, 기호를 사용해주세요.",
    },
    {
      title: "Confirm Password",
      label: "repeatPassword",
      inputType: "password",
      inputClassName: "",
      handleInput: repeatPasswordOnChange,
      defaultValue: "",
      isDisabled: false,
      checkValidation: decodeKeyQuery.isError
        ? decodeKeyQuery.error.message
        : password !== repeatPassword && repeatPassword.length > 0
        ? "비밀번호와 일치하지 않습니다."
        : signUpMutation.isError &&
          name === compareInputValues?.name &&
          password === compareInputValues?.password &&
          signUpMutation.error.message,
    },
  ];

  return (
    <form
      onSubmit={submitSignUp}
      className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6"
    >
      {formValues.map((formValue) => (
        <div key={formValue.title}>
          <label
            htmlFor={formValue.label}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {formValue.title}
          </label>
          <div className="mt-2">
            <input
              type={formValue.inputType}
              className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50`}
              onChange={formValue.handleInput}
              name={formValue.label}
              id={formValue.label}
              defaultValue={formValue.defaultValue}
              disabled={formValue.isDisabled}
              required
            />
          </div>
          <p className="mt-2 text-center text-error-message">
            {formValue.checkValidation}
          </p>
        </div>
      ))}
      <button
        type="submit"
        className="flex w-full justify-center rounded-md bg-congress-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-allports focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        disabled={!isInputValidSignUp(name, password, repeatPassword)}
      >
        Sign up
      </button>
    </form>
  );
};

export default SignUpForm;
