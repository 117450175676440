import { useVariableTableBody } from '@hooks/mobileWebTestStep/useVariableTableBody';

import VariableTableBody from '@components/shared/MobileWebTestStep/CreateAndUpdate/VariableTableBody';

import { CreateMobileWebSpeedTestStepData } from '@customTypes/ide/speedTest/Mobile/webTestStep/type';

import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';

interface Props {
  idx: number;
  optionsDtoIdx: number;
  tableColsLength: number;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
}

const CreateVariableTableBody = ({
  idx,
  optionsDtoIdx,
  tableColsLength,
  selectArray,
  selectedArray,
}: Props) => {
  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateMobileWebSpeedTestStepData>();
  const { update } = useFieldArray({
    control,
    name: 'optionsDto',
  });

  const { handleKeyNameChange, optionsDtoKeyNameList } = useVariableTableBody({
    idx,
    watch,
    setValue,
    getValues,
    update,
  });

  return (
    <VariableTableBody
      optionsDtoIdx={optionsDtoIdx}
      selectedArray={selectedArray}
      selectArray={selectArray}
      idx={idx}
      tableColsLength={tableColsLength}
      handleKeyNameChange={handleKeyNameChange}
      optionsDtoKeyNameList={optionsDtoKeyNameList}
      watch={watch}
      control={control}
      register={register}
      errors={errors}
      setError={setError}
      clearErrors={clearErrors}
    />
  );
};

export default CreateVariableTableBody;
