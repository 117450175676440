import { TableNoList } from '@autosquare/common';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import {
  MobileTestStepByCase,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import TestStepListTableRow from './TestStepListTableBody/TestStepListTableRow';

interface Props {
  title: DeviceInfoOs;
  mobileTestStepList: MobileTestStepByCase;
  tableHeaderLength: number;
  handleOnDragEnd: (result: DropResult) => void;
  lists: MobileTestStepData[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
  setIsTestStepExecutionOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const TestStepListTableBody = ({
  title,
  mobileTestStepList,
  tableHeaderLength,
  handleOnDragEnd,
  selectArray,
  selectedArray,
  setIsTestStepExecutionOpened,
  lists,
}: Props) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId={`${title}-lists`}>
        {(provided) => (
          <tbody
            className="divide-y divide-gray-200"
            id={`${title}-lists`}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {title === DeviceInfoOs.Aos &&
            mobileTestStepList?.aos.length === 0 ? (
              <TableNoList colSpan={tableHeaderLength}>
                추가된 테스트 스텝이 없습니다.
              </TableNoList>
            ) : title === DeviceInfoOs.Ios &&
              mobileTestStepList?.ios.length === 0 ? (
              <TableNoList colSpan={tableHeaderLength}>
                추가된 테스트 스텝이 없습니다.
              </TableNoList>
            ) : title === DeviceInfoOs.Aos ? (
              lists?.map((mobileTestStep, index) => (
                <TestStepListTableRow
                  key={mobileTestStep.idx}
                  mobileTestStep={mobileTestStep}
                  mobileTestStepList={mobileTestStepList.aos}
                  index={index}
                  selectArray={selectArray}
                  selectedArray={selectedArray}
                  setIsTestStepExecutionOpened={setIsTestStepExecutionOpened}
                  title={title}
                />
              ))
            ) : (
              title === DeviceInfoOs.Ios &&
              lists?.map((mobileTestStep, index) => (
                <TestStepListTableRow
                  key={mobileTestStep.idx}
                  mobileTestStep={mobileTestStep}
                  mobileTestStepList={mobileTestStepList.ios}
                  index={index}
                  selectArray={selectArray}
                  selectedArray={selectedArray}
                  setIsTestStepExecutionOpened={setIsTestStepExecutionOpened}
                  title={title}
                />
              ))
            )}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TestStepListTableBody;
