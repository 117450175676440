import { RootState } from '@app/store';
import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';
import { TestExecutionContext } from '@contexts/ide/TestExecutionProvider';
import { useStopMobileTestExecutionMutation } from '@lib/api/ide/mobile/mobileExecution';

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import TestExecutionDetailLoading from '../TestExecutionDetailLoading';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

type Props = {
  testType: TestTypeCategory;
  isTestExecutionStopped: boolean;
  isTestRunning: boolean;
  setIsTestRunning: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTestExecutionStopped: React.Dispatch<React.SetStateAction<boolean>>;
  closeModalExecution: () => void;
};

const DistributedExecutionDetailStopAndLoading = ({
  testType,
  isTestExecutionStopped,
  isTestRunning,
  setIsTestRunning,
  setIsTestExecutionStopped,
  closeModalExecution,
}: Props) => {
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  const { isModalOpened, setIsModalOpened, openModal, closeModal } =
    useContext(TestExecutionContext);

  const stopMobileTestExecutionMutation =
    useStopMobileTestExecutionMutation(cookie);

  return (
    <>
      {stopMobileTestExecutionMutation.isLoading || isTestExecutionStopped ? (
        <TestExecutionDetailLoading />
      ) : stopMobileTestExecutionMutation.isError ? (
        <DoubleCheckDialogHeadlessUI
          isOpened={isModalOpened}
          setIsOpened={setIsModalOpened}
          type={'caution'}
          title={'Error'}
          subTitle={stopMobileTestExecutionMutation.error.message}
          buttonChildren={
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
          }
        />
      ) : (
        isTestRunning && (
          <DoubleCheckDialogHeadlessUI
            isOpened={isModalOpened}
            setIsOpened={setIsModalOpened}
            type={'caution'}
            title={'Stop Test'}
            subTitle={
              '테스트를 강제 종료하시겠습니까?\n(테스트 결과는 N/A 처리됩니다.)'
            }
            buttonChildren={
              <>
                <Button type="button" variant="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="caution"
                  onClick={() => {
                    stopMobileTestExecutionMutation.mutate(undefined, {
                      onSuccess: () => {
                        if (testType === 'step') {
                          closeModalExecution();
                        } else {
                          setIsTestExecutionStopped(true);
                        }
                      },
                      onError: () => {
                        setIsTestRunning(false);
                        openModal();
                      },
                    });
                    closeModal();
                  }}
                >
                  Stop
                </Button>
              </>
            }
          />
        )
      )}
    </>
  );
};

export default DistributedExecutionDetailStopAndLoading;
