import { SpeedMobileCaseExecution } from '@customTypes/speedTestExecution/speedTestExecution';
import { onOnlyNumberChangeHandler } from '@utils/static/mobileTestStep/onOnlyNumberChangeHandler';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  isInvalidRepeatCount: boolean;
  errorMessage: string;
};

const RepeatInfo = ({ isInvalidRepeatCount, errorMessage }: Props) => {
  const { register, setValue } = useFormContext<SpeedMobileCaseExecution>();

  return (
    <div className="flex flex-col gap-y-2">
      <label htmlFor="device-repeat" className="font-semibold text-gray-900">
        Repeat
      </label>
      <p className="text-sm text-gray-900">
        속도 측정을 몇 번 반복할지 횟수를 입력해 주세요. &#40;처음과 마지막 속도
        측정을 제외한 나머지 값들의 평균이 결과로 저장 됩니다.&#41;
      </p>
      <input
        type="text"
        id="device-repeat"
        className="input-base w-full"
        defaultValue={12}
        {...register('repeatCount', {
          onChange: (e) =>
            onOnlyNumberChangeHandler(e, 'repeatCount', setValue),
        })}
      />
      {isInvalidRepeatCount && (
        <div className="standard-error-message">{errorMessage}</div>
      )}
    </div>
  );
};

export default RepeatInfo;
