export const projectKeys = {
  all: ['project'] as const,
  lists: () => [...projectKeys.all, 'list'] as const,
  list: (projectIdx: string) => [...projectKeys.lists(), projectIdx] as const,
  details: () => [...projectKeys.all, 'detail'] as const,
  detail: (idx: string) => [...projectKeys.details(), idx] as const,
  projectMemberLists: () => [...projectKeys.all, 'projectMemberList'] as const,
  projectMemberList: (projectIdx: string) =>
    [...projectKeys.projectMemberLists(), projectIdx] as const,
  projectMemberDetails: () =>
    [...projectKeys.all, 'projectMemberDetail'] as const,
  projectMebmerDetail: (projectIdx: string, idx: string) => [
    ...projectKeys.projectMemberDetails(),
    projectIdx,
    idx,
  ],
  groupMemberLists: () => [...projectKeys.all] as const,
  groupMemberList: (projectIdx: string) =>
    [...projectKeys.groupMemberLists(), projectIdx] as const,
};
