import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Bars3Icon } from '@heroicons/react/24/outline';

import { RootState } from '@app/store';

import AdminSidebarUnderMonitorSize from './AdminSidebarUnderMonitorSize';
import AdminSidebarMonitorSize from './AdminSidebarMonitorSize';

import { decodeJwtToken, useViewport } from '@autosquare/common';

import { SidebarList } from '@customTypes/type';

type Props = {
  isToggledSidebar: boolean;
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const Administration = ({ isToggledSidebar, setIsToggledSidebar }: Props) => {
  const navigate = useNavigate();
  const { width } = useViewport();

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue,
  );
  const checkAdminRole = decodeJwtToken(accessToken)?.auth;

  useEffect(() => {
    checkAdminRole !== 'ROLE_ADMIN' && navigate('/not-admin');
  });

  return (
    <>
      {!isToggledSidebar && (
        <div className="flex items-center px-6 py-4 border-b lg:hidden">
          <button
            type="button"
            className="mr-6 "
            onClick={() => setIsToggledSidebar(!isToggledSidebar)}
          >
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
          </button>
          <div>Menu</div>
        </div>
      )}
      {width > 1024 ? (
        <AdminSidebarMonitorSize
          sidebarLists={sidebarLists}
          setIsToggledSidebar={setIsToggledSidebar}
        />
      ) : (
        isToggledSidebar && (
          <AdminSidebarUnderMonitorSize
            sidebarLists={sidebarLists}
            setIsToggledSidebar={setIsToggledSidebar}
          />
        )
      )}
    </>
  );
};

export default Administration;

const sidebarLists: SidebarList[] = [
  {
    name: 'Users',
    location: 'users',
    children: [
      {
        name: 'User List',
        href: '/admin/users/list',
        hrefBasic: '/admin/users',
      },
    ],
  },
  {
    name: 'Projects',
    location: 'project',
    children: [
      {
        name: 'Project List',
        href: '/admin/project/list',
        hrefBasic: '/admin/project',
      },
    ],
  },
  {
    name: 'Settings',
    location: 'settings',
    children: [
      {
        name: 'General',
        href: '/admin/settings/general',
        hrefBasic: '/admin/settings',
      },
      {
        name: 'WebDriver',
        href: '/admin/settings/webdriver',
        hrefBasic: '/admin/settings',
      },
    ],
  },
];
