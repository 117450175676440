import React from "react";
import { Outlet } from "react-router-dom";

const AutosquareRoot = () => {
  return (
    <main className="px-5 py-4">
      <Outlet />
    </main>
  );
};

export default AutosquareRoot;
