import React from 'react';

type Props = {
  message: string;
};

const MobileWebTestStepError = ({ message }: Props) => {
  return (
    <section className="py-6">
      <div className="flex items-center justify-between border-b border-solid border-gray-200 pb-3.5">
        <h3 className="text-base font-semibold leading-normal text-gray-900">
          Test Step
        </h3>
      </div>
      <div className="py-2.5 text-sm font-normal leading-9 text-black">
        <p className="error-message">{message}</p>
      </div>
    </section>
  );
};

export default MobileWebTestStepError;
