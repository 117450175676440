import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

export type Application = {
  application: string;
  isDownloadedData: boolean;
};

const initialState: Application = {
  application: null,
  isDownloadedData: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getApplicationUrl: (state, action: PayloadAction<string>) => {
      state.application = action.payload;
    },
    hasDownloadedData: (state, action: PayloadAction<boolean>) => {
      state.isDownloadedData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getApplicationUrl, hasDownloadedData } = settingsSlice.actions;

export default settingsSlice.reducer;
