import {
  AddServerName,
  FromServerName,
  SeparatorServerName,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';
import { CreateMobileWebSpeedTestStepData } from '@customTypes/ide/speedTest/Mobile/webTestStep/type';
import {
  CreateWebSpeedTestStepData,
  UpdateWebSpeedTestStepData,
} from '@customTypes/ide/speedTest/Web/testStep/type';

import { useEffect, useRef, useState } from 'react';
import {
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

type Props = {
  value: string;
  onChange: (value: string) => void;
  append: UseFieldArrayAppend<CreateMobileWebSpeedTestStepData, 'optionsDto'>;
  remove: UseFieldArrayRemove;
  getValues: UseFormGetValues<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
  watch: UseFormWatch<CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData>;
  setValue: UseFormSetValue<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
};

export const useEditableDiv = ({
  value,
  onChange,
  append,
  remove,
  getValues,
  watch,
  setValue,
}: Props) => {
  const url = watch('url');
  const optionsDto = watch('optionsDto');

  const divRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [selectedText, setSelectedText] = useState<string>('');
  const [buttonPosition, setButtonPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [isVariableSettingDialog, setIsVariableSettingDialog] = useState(false);
  const [increaseIdx, setIncreaseIdx] = useState(
    optionsDto ? optionsDto.length : 0,
  );
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);

  // {{}} 변수처리된 값의 시작과 끝의 index 위치를 확인하여 업데이트
  useEffect(() => {
    let lastIndex = 0;

    const updatedOptionsDto = optionsDto.map((option) => {
      const variableString = `{{${option.keyName}}}`;
      const index = url.indexOf(variableString, lastIndex);
      if (index !== -1) {
        lastIndex = index + variableString.length;
        return {
          ...option,
          start: index,
          end: lastIndex,
        };
      }
      return option;
    });

    setValue('optionsDto', updatedOptionsDto);
  }, [url]);

  // 기존 URL에 변수가 포함된 부분을 button 태그로 감싸는 로직
  useEffect(() => {
    if (divRef.current && divRef.current.innerText !== value) {
      renderUrlWithVariables(value);
    }
  }, [value]);

  // div를 input처럼 사용할 수 있게 하는 로직
  const handleInput = (event: React.FormEvent<HTMLDivElement>) => {
    const newValue = event.currentTarget.textContent || '';
    onChange(newValue);
  };

  // 드래그 했을 때 set as value 버튼 나타나는 로직
  const handleMouseUp = () => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = selection.toString();
      setSelectedText(selectedText);

      if (range && divRef.current) {
        const rect = range.getBoundingClientRect();
        const divRect = divRef.current.getBoundingClientRect();
        setButtonPosition({
          top: rect.top - divRect.top - 50,
          left: rect.left - divRect.left - 20,
        });

        // 선택 시작과 끝 위치 계산
        const preSelectionRange = range.cloneRange();
        preSelectionRange.selectNodeContents(divRef.current);
        preSelectionRange.setEnd(range.startContainer, range.startOffset);
        const start = preSelectionRange.toString().length;

        setSelectionStart(start);
        setSelectionEnd(start + selectedText.length);
      }
    }
  };

  // 버튼 바깥 클릭 시 버튼 사라지는 로직
  const handleClickOutside = (e: MouseEvent) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(e.target as Node) &&
      buttonPosition
    ) {
      setSelectedText('');
      setButtonPosition(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonPosition]);

  // 버튼 클릭 시 해당 keyName focus 이벤트
  const handleVariableClick = (uuid: string) => {
    const optionsDto = getValues('optionsDto');
    const findKeyNameIndex = optionsDto.findIndex(
      (option) => option.uuid === uuid,
    );

    const inputElement = document.querySelector(
      `input[name="optionsDto.${findKeyNameIndex}.keyName"]`,
    ) as HTMLInputElement;

    if (inputElement) inputElement.focus();
  };

  // {{}} 변수명 생성시 실행되는 로직
  const renderUrlWithVariables = (url: string) => {
    const parts = url.split(/(\{\{.*?\}\})/);
    const optionsDto = getValues('optionsDto');

    if (divRef.current) {
      // 기존 버튼을 uuid 기준으로 맵핑
      const existingButtons: Record<string, HTMLButtonElement> = {};
      divRef.current.querySelectorAll('button[data-uuid]').forEach((button) => {
        const uuid = button.getAttribute('data-uuid');
        if (uuid) {
          existingButtons[uuid] = button as HTMLButtonElement;
        }
      });

      const newElements: Node[] = []; // 새로운 요소들을 임시 저장할 배열

      parts.forEach((part) => {
        if (part.startsWith('{{') && part.endsWith('}}')) {
          const keyName = part.slice(2, -2);
          const option = optionsDto.find((opt) => opt.keyName === keyName);

          if (option) {
            const uuid = option.uuid;

            let button: HTMLButtonElement;

            if (existingButtons[uuid]) {
              // 버튼이 이미 존재한다면, 그 버튼을 재사용
              button = existingButtons[uuid];
              button.innerText = part; // 텍스트 업데이트
              delete existingButtons[uuid]; // 이 버튼은 사용했으니 목록에서 삭제
            } else {
              // 새로운 버튼을 생성
              button = document.createElement('button') as HTMLButtonElement;
              button.type = 'button';
              button.contentEditable = 'false';
              button.className =
                'text-congress-blue rounded-md hover:bg-[#c6ddff]';
              button.innerText = part;
              button.setAttribute('data-uuid', uuid);
              button.onclick = () => handleVariableClick(uuid);
            }

            // 새로운 요소 배열에 버튼 추가
            newElements.push(button);
          }
        } else {
          // 텍스트 노드를 그대로 배열에 추가
          newElements.push(document.createTextNode(part));
        }
      });

      // 모든 요소를 추가하기 전에 divRef를 초기화하고 새로운 요소들을 추가
      divRef.current.innerHTML = '';
      newElements.forEach((el) => divRef.current.appendChild(el));
    }
  };

  // {{}} 이 포함된 변수명을 matches 배열에 담는 로직
  const extractVariables = (
    url: string,
  ): { key: string; start: number; end: number }[] => {
    const regex = /{{([^}]*)}}/g;
    const matches: { key: string; start: number; end: number }[] = [];
    let match: RegExpExecArray | null;
    while ((match = regex.exec(url)) !== null) {
      matches.push({
        key: match[1],
        start: match.index,
        end: regex.lastIndex,
      });
    }
    return matches;
  };

  const findMissingIndices = (arr: number[], totalLength: number): number[] => {
    const set = new Set(arr);
    const result: number[] = [];

    for (let i = 0; i < totalLength; i++) {
      if (!set.has(i)) {
        result.push(i);
      }
    }

    return result;
  };

  const keyNames = getValues('optionsDto').map((item) => item.keyName);
  const keyNamesInUrl = extractVariables(watch('url'));

  // 삭제해야하는 배열의 index 찾는 로직
  const deletedIndexArray = findMissingIndices(
    keyNamesInUrl.map((item) => keyNames.indexOf(item.key)),
    keyNames.filter((key) => key).length,
  );

  // 변수명 삭제 시 삭제되는 변수명 optionsDto 배열에서 삭제하는 로직
  useEffect(() => {
    // Dialog가 열리지 않고 deletedIndexArray.length > 0 일 때
    if (!isVariableSettingDialog && deletedIndexArray.length > 0) {
      // 중복된 keyName이 있으면 삭제되지 않는 로직
      const uniqueKeyNames = Array.from(new Set(keyNames));
      if (uniqueKeyNames.length !== keyNames.length) {
        return;
      }

      remove(deletedIndexArray);
      if (divRef.current) {
        const buttonList = divRef.current.querySelectorAll(
          'button.text-congress-blue',
        );
        buttonList.forEach((button) => {
          // button의 텍스트가 '' 이 되었을 때 remove 되지 않는 조건
          if (!keyNames.includes(button.textContent?.slice(2, -2) || '')) {
            const textNode = document.createTextNode(button.textContent || '');
            button.replaceWith(textNode);
          }
        });
      }
    }
  }, [isVariableSettingDialog, deletedIndexArray, remove, keyNames]);

  // Set As Value 버튼 클릭 시 실행되는 로직
  const openDialog = (text: string) => {
    setSelectedText(text);
    append({
      keyName: '',
      from: FromServerName.Today,
      addKey: AddServerName.Day,
      addValue: 3,
      dateFormat: '',
      separator: SeparatorServerName.None,
      idx: increaseIdx,
      uuid: crypto.randomUUID(),
      start: selectionStart,
      end: selectionEnd,
    });
    setIncreaseIdx((prev) => prev + 1);
    setIsVariableSettingDialog(true);
  };

  return {
    handleInput,
    handleMouseUp,
    divRef,
    buttonRef,
    buttonPosition,
    openDialog,
    isVariableSettingDialog,
    setIsVariableSettingDialog,
    selectedText,
    setSelectedText,
    selectionStart,
    selectionEnd,
  };
};
