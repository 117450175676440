import React from 'react';
import { useLocation } from 'react-router-dom';

import AdminCreateProjectForm from './components/AdminCreateProjectForm';

import { PagingHeadings } from '@autosquare/common';

const AdminCreateProject = () => {
  const { pathname } = useLocation();

  const adminProjectListUrl = `/admin/project/list`;

  const breadcrumbs = [
    {
      title: 'Administration',
      href: adminProjectListUrl,
    },
    {
      title: 'Projects',
      href: adminProjectListUrl,
    },
    {
      title: 'Create',
      href: pathname,
    },
  ];

  return (
    <>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Project Create'} />
      <AdminCreateProjectForm />
    </>
  );
};

export default AdminCreateProject;
