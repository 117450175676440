import { ErrorMessage, ListSkeleton, TableRow } from '@autosquare/common';

import { projectListQuery } from '@lib/api/dashboard/project/project';

import React from 'react';
import { Link } from 'react-router-dom';

const ProjectLists = () => {
  const {
    data: projectLists,
    error,
    isLoading,
    isFetching,
    isError,
  } = projectListQuery();

  const projectListHeaders = [
    'Name',
    'Service Name',
    'Description',
    'Updated at',
  ];

  return (
    <>
      <div className="px-4 pb-5 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-bold leading-7 text-gray-900">
            Project List
          </h1>
        </div>
      </div>
      {isLoading || isFetching ? (
        <ListSkeleton />
      ) : isError ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <div className="px-4">
          <div className="flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      {projectListHeaders.map((header) => (
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 first:py-3.5 first:pl-4 first:pr-3 first:sm:pl-3"
                          key={header}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {projectLists?.map((projectList) => (
                      <TableRow key={projectList.idx}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-blue-600 sm:pl-3">
                          <Link to={`${projectList.idx}/overview`}>
                            <span className="hover:underline">
                              {projectList.name}
                            </span>
                          </Link>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {projectList.serviceName ?? '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {projectList.description === ''
                            ? '-'
                            : projectList.description}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {projectList.updatedAt}
                        </td>
                      </TableRow>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectLists;
