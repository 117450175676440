import React from 'react';

const NoSaveTestStep = () => {
  return (
    <div className="flex flex-col items-center justify-center border border-solid border-border-line p-3 text-sm">
      <p className="mb-2">선택 가능한 테스트 스텝이 없습니다.</p>
      <p>Save Text Step을 먼저 추가해주세요.</p>
    </div>
  );
};

export default NoSaveTestStep;
