export enum WebSpeedTestResultServiceChannel {
  Case = 'Case',
  Scenario = 'Scenario',
}

export enum WebSpeedTestResultServiceResult {
  NA = 'NA',
  Pass = 'PASS',
  Running = 'RUNNING',
  Fail = 'FAIL',
}

export interface WebSpeedTestResultService {
  idx: number;
  service_group_idx: number;
  service: string;
  load_speed_average: number;
  channel: WebSpeedTestResultServiceChannel;
  result_enum: WebSpeedTestResultServiceResult;
}

export interface WebSpeedTestResultList {
  services: WebSpeedTestResultService[];
  menu: string;
  group_idx: number;
  date_time: string;
}

export interface WebSpeedTestResultData {
  list: WebSpeedTestResultList[];
  totalCount: number;
}

export interface WebSpeedTestResultEnvData {
  browser: string;
  title: string;
  type: string;
  tester: string;
  startTime: string;
  endTime: string;
}

export interface SpeedDataArray {
  resultIdx: number;
  value: number;
}
export interface SpeedData {
  data: SpeedDataArray[];
  name: string;
}
export interface WebSpeedTestResultDetail {
  envData: WebSpeedTestResultEnvData;
  loadSpeedData: SpeedData[];
  domSpeedData: SpeedData[];
  date: string[];
  menu: string;
  failReason: string;
}

export interface WebSpeedTestResultInfo {
  channel: string;
  service: string;
  menu: string;
  url: string;
  domSpeedAverage: number;
  loadSpeedAverage: number;
  dateTime: string;
}

export interface WebSpeedTestResultExecutionDetail {
  idx: number;
  domSpeed: number;
  loadSpeed: number;
  createdAt: string;
  modifiedAt: string;
}
export interface WebSpeedTestResultTableDetailData {
  idx: number;
  createdAt: string;
  modifiedAt: string;
  uploadUserId: string;
  info: WebSpeedTestResultInfo;
  repeatCount: number;
  detail: WebSpeedTestResultExecutionDetail[];
}
