import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SpeedTestSchedulerDetailScenarioDraggableSection from './SpeedTestSchedulerDetailScenario/SpeedTestSchedulerDetailScenarioDraggableSection';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';

const SpeedTestSchedulerDetailScenario = () => {
  return (
    <SpeedSchedulerGridContainer title="Scenario" isTitleCenter={true}>
      <DndProvider backend={HTML5Backend}>
        <SpeedTestSchedulerDetailScenarioDraggableSection />
      </DndProvider>
    </SpeedSchedulerGridContainer>
  );
};

export default SpeedTestSchedulerDetailScenario;
