import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { ProjectListDetail } from "@customTypes/dashboard/project/type";

export type ProjectList = {
  projectList: ProjectListDetail | null;
};

const initialState: ProjectList = {
  projectList: null,
};

export const projectListSlice = createSlice({
  name: "projectList",
  initialState,
  reducers: {
    getProjectList: (
      state,
      action: PayloadAction<ProjectListDetail | null>
    ) => {
      state.projectList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getProjectList } = projectListSlice.actions;

export default projectListSlice.reducer;
