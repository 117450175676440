import {
  ErrorMessage,
  Pagination,
  PagingHeadingsInAutosquare,
  SpeedTestResultTableSkeleton,
} from '@autosquare/common';
import { useGetIdeSpeedMobileTestResult } from '@lib/api/ide/speedMobile/speedMobileTestResult';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import IdeMobileSpeedTestResultTable from './IdeMobileSpeedTestResultTable/IdeMobileSpeedTestResultTable';

const IdeMobileSpeedTestResult = () => {
  const PAGE_LIMIT = 5;
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Mobile', href: pathname + search },
    { title: 'Test Result', href: pathname + search },
    { title: 'List', href: pathname + search },
  ];

  const speedMobileTestResultQuery = useGetIdeSpeedMobileTestResult(
    page,
    PAGE_LIMIT,
  );
  const speedMobileTestResultData = speedMobileTestResultQuery.data;
  const total = speedMobileTestResultData?.totalElements;
  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      {speedMobileTestResultQuery.isLoading ||
      speedMobileTestResultQuery.isFetching ? (
        <SpeedTestResultTableSkeleton />
      ) : speedMobileTestResultQuery.isError ? (
        <ErrorMessage>{speedMobileTestResultQuery.error.message}</ErrorMessage>
      ) : (
        <IdeMobileSpeedTestResultTable resultData={speedMobileTestResultData} />
      )}
      {total > 5 && (
        <Pagination
          page={page}
          total={total}
          size={PAGE_LIMIT}
          isDashboard={false}
        />
      )}
    </>
  );
};

export default IdeMobileSpeedTestResult;
