export const webTestResultKeys = {
  all: ['webTestResult'] as const,
  lists: () => [...webTestResultKeys.all, 'list'] as const,
  list: (idx: string, page: number, size: number) =>
    [...webTestResultKeys.lists(), idx, page, size] as const,
  details: () => [...webTestResultKeys.all, 'detail'] as const,
  detail: (idx: string) => [...webTestResultKeys.details(), idx] as const,
  detailInfos: () => [...webTestResultKeys.all, 'detailInfo'] as const,
  detailInfo: (idx: string) =>
    [...webTestResultKeys.detailInfos(), idx] as const,
  executionDetails: () =>
    [...webTestResultKeys.all, 'executionDetail'] as const,
  executionDetail: (testresultidx: string, caseResultIdx: number) =>
    [
      ...webTestResultKeys.executionDetails(),
      testresultidx,
      caseResultIdx,
    ] as const,
  detailImage: (path: string) =>
    [[...webTestResultKeys.detailInfos(), path], 'image'] as const,
};
