import React from 'react';

import { projectListQuery } from '@lib/api/dashboard/project/project';

import ProjectLists from './components/ProjectLists';
import ProjectNotExist from './components/ProjectNotExist';

const Projects = () => {
  const { data: projectLists, isFetching } = projectListQuery();

  return (
    <>
      {projectLists?.length === 0 ? (
        <ProjectNotExist isFetching={isFetching} />
      ) : (
        <ProjectLists />
      )}
    </>
  );
};

export default Projects;
