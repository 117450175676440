import {
  Breadcrumbs,
  ErrorMessage,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import GroupProvider from '@contexts/ide/GroupProvider';

import { useWebSpeedTestCaseDetailQuery } from '@lib/api/ide/speedWeb/webSpeedTestCase';

import React from 'react';
import { useLocation } from 'react-router-dom';

import WebSpeedTestCaseInformation from './components/WebSpeedTestCaseInformation';
import WebSpeedTestStepList from './components/WebSpeedTestStepList';

const WebSpeedTestCaseDetail = () => {
  const { pathname, search } = useLocation();

  const webSpeedTestCaseDetailQuery = useWebSpeedTestCaseDetailQuery();

  const testCaseLink = pathname.split('/').slice(0, -1).join('/');

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -3).join('/')}/overview`,
    },
    { title: 'Web', href: testCaseLink },
    { title: 'Test Case', href: testCaseLink },
    { title: 'Detail', href: pathname + search },
  ];

  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case Detail'}
      />
      {webSpeedTestCaseDetailQuery.isError ? (
        <ErrorMessage>{webSpeedTestCaseDetailQuery.error.message}</ErrorMessage>
      ) : (
        <GroupProvider>
          <WebSpeedTestCaseInformation />
          <WebSpeedTestStepList />
        </GroupProvider>
      )}
    </>
  );
};

export default WebSpeedTestCaseDetail;
