import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axiosInstance } from '..';
import { API } from '@api/endpoints';
import { adminKeys } from '@queries/ide/adminKeys';

import {
  AdminProjectListDetail,
  AdminSystemSettings,
  AdminUserDetailData,
  AdminUserListData,
  CreateAdminProjectData,
  InviteUser,
  InviteUserResponse,
  UpdateAdminProjectDetailData,
  UpdateAdminUserInfoData,
  AdminWebDriverListData,
  UploadAdminWebDriverData,
  UploadAdminWebDriverResponse,
} from '@customTypes/dashboard/admin/type';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { ProjectListDetail } from '@customTypes/dashboard/project/type';
import { useNavigate } from 'react-router-dom';
import { projectKeys } from '@queries/dashboard/projectKeys';

// GET
const getAdminUserList = async (): Promise<AdminUserListData[]> => {
  const response = await axiosInstance.get(API.GET_ADMIN_USER_LIST);
  return response.data;
};
export const useAdminUserListQuery = () =>
  useQuery<AdminUserListData[], ServerResponseErrorDetails>({
    queryKey: adminKeys.userLists(),
    queryFn: () => getAdminUserList(),
  });

const getAdminUserDetail = async (
  idx: string,
): Promise<AdminUserDetailData> => {
  const response = await axiosInstance.get(
    API.GET_ADMIN_DETAIL_USER_INFO + idx,
  );
  return response.data;
};
export const useAdminUserDetailQuery = (idx: string) =>
  useQuery<AdminUserDetailData, ServerResponseErrorDetails>({
    queryKey: adminKeys.userDetail(idx),
    queryFn: () => getAdminUserDetail(idx),
  });

const getAllAdminProjectList = async (): Promise<AdminProjectListDetail[]> => {
  const response = await axiosInstance.get(API.GET_ADMIN_ALL_PROJECT_LIST);
  return response.data;
};
export const useAllAdminProjectListQuery = () =>
  useQuery<AdminProjectListDetail[], ServerResponseErrorDetails>({
    queryKey: adminKeys.projectLists(),
    queryFn: () => getAllAdminProjectList(),
  });

const getAdminProjectDetail = async (
  idx: string,
): Promise<ProjectListDetail> => {
  const response = await axiosInstance.get(API.GET_ADMIN_PROJECT + idx);
  return response.data;
};
export const useAdminProjectDetailQuery = (idx: string) => {
  return useQuery<ProjectListDetail, ServerResponseErrorDetails>({
    queryKey: adminKeys.projectDetail(idx),
    queryFn: () => getAdminProjectDetail(idx),
  });
};

const getAdminSystemSettings = async (): Promise<AdminSystemSettings> => {
  try {
    const response = await axiosInstance.get(API.GET_ADMIN_SYSTEM_SETTINGS);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching settings:', error.message);
    } else {
      console.error('Unknown error:', error);
    }
    throw error;
  }
};
export const useAdminSystemSettingsQuery = () => {
  return useQuery<AdminSystemSettings>({
    queryKey: adminKeys.systemSettings(),
    queryFn: getAdminSystemSettings,
    onError: (error) => {
      if (error instanceof Error) {
        console.error('Query error:', error.message);
      }
    },
  });
};
const getAdminWebDriverList = async (): Promise<AdminWebDriverListData[]> => {
  const response = await axiosInstance.get(API.GET_ADMIN_WEB_DRIVER_LIST);
  return response.data;
};
export const useAdminWebDriverListQuery = () =>
  useQuery<AdminWebDriverListData[], ServerResponseErrorDetails>({
    queryKey: adminKeys.webdriverLists(),
    queryFn: () => getAdminWebDriverList(),
    onError: (error) => {
      if (error instanceof Error) {
        console.error('Query error:', error.message);
      }
    },
  });

// POST
const postInviteUser = async (
  data: InviteUser,
): Promise<InviteUserResponse> => {
  const response = await axiosInstance.post(
    `${API.CREATE_INVITE_SIGNUP}`,
    data,
  );
  return response.data;
};
export const useInviteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    InviteUserResponse,
    ServerResponseErrorDetails,
    InviteUser
  >({
    mutationFn: (data) => postInviteUser(data),
    onSuccess: () => {
      queryClient.invalidateQueries(adminKeys.all);
    },
  });
};

export const createAdminProject = async (
  data: CreateAdminProjectData,
): Promise<unknown> => {
  const response = await axiosInstance.post(API.CREATE_ADMIN_PROJECT, data);
  return response.data;
};
export const useCreateAdminProjectMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    ServerResponseErrorDetails,
    CreateAdminProjectData
  >({
    mutationFn: (data) => createAdminProject(data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(adminKeys.all);
      navigate(`/admin/project/list`);
    },
  });
};

const uploadAdminWebDriver = async (
  data: UploadAdminWebDriverData,
): Promise<UploadAdminWebDriverResponse> => {
  const formData = new FormData();
  formData.append('uploadFile', data.uploadFile);
  formData.append('os', data.os);
  formData.append('browser', data.browser);
  formData.append('version', data.version);
  formData.append('cpuArch', data.cpuArch);

  const response = await axiosInstance.post(
    API.UPLOAD_ADMIN_WEB_DRIVER,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return response.data;
};

export const useUploadAdminWebDriverMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    UploadAdminWebDriverResponse,
    Error,
    UploadAdminWebDriverData
  >({
    mutationFn: (data) => uploadAdminWebDriver(data),
    onSuccess: (newWebDriver) => {
      queryClient.setQueryData<AdminWebDriverListData[]>(
        adminKeys.webdriverLists(),
        (oldData) => {
          if (oldData) {
            return [...oldData, newWebDriver];
          }
          return [newWebDriver];
        },
      );
    },
  });
};

// PUT
const updateAdminUserDetail = async (
  idx: string,
  data: UpdateAdminUserInfoData,
): Promise<unknown> => {
  const response = await axiosInstance.put(
    API.UPDATE_ADMIN_DETAIL_USER_INFO + idx,
    data,
  );
  return response.data;
};
export const useUpdateAdminUserDetailMutation = (idx: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    ServerResponseErrorDetails,
    UpdateAdminUserInfoData
  >({
    mutationFn: (data) => updateAdminUserDetail(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(adminKeys.all);
      navigate('/admin/users/list');
    },
  });
};

const updateAdminProjectDetail = async (
  idx: string,
  data: UpdateAdminProjectDetailData,
): Promise<unknown> => {
  const reseponse = await axiosInstance.put(
    API.UPDATE_ADMIN_PROJECT + idx,
    data,
  );
  return reseponse.data;
};
export const useUpdateAdminProjectDetailMutation = (idx: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    ServerResponseErrorDetails,
    UpdateAdminProjectDetailData
  >({
    mutationFn: (data) => updateAdminProjectDetail(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(adminKeys.all);
      navigate(`/admin/project/list`);
    },
  });
};

const updateAdminSettings = async (settings: {
  [key: string]: string;
}): Promise<unknown> => {
  const response = await axiosInstance.put(API.UPDATE_ADMIN_SYSTEM_SETTINGS, {
    settings,
  });
  return response.data;
};
export const useUpdateAdminSettings = () => {
  return useMutation(updateAdminSettings, {
    onSuccess: (data) => {},
    onError: (error) => {},
  });
};
