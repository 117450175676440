import { CreateWebTestStepData } from '@customTypes/testStep/type';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const TestStepLoop = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  return (
    <div className="grid grid-cols-3 items-start gap-4">
      <label
        htmlFor="loop"
        className="block pt-1 text-sm font-medium leading-6 text-gray-900"
      >
        Loop
      </label>
      <div className="col-span-2 mt-0">
        <input
          type="number"
          name="loop"
          id="loop"
          className="input-base"
          defaultValue={1}
          required
          {...register('optionsDto.loop', {
            required: 'Loop 값을 입력해주세요.',
            min: { value: 1, message: '1 이상 60 이하 입력해 주세요.' },
            max: { value: 60, message: '1 이상 60 이하 입력해 주세요.' },
          })}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name="optionsDto.loop"
        render={({ message }) => (
          <p className="error-message col-span-3">{message}</p>
        )}
      />
    </div>
  );
};

export default TestStepLoop;
