import { RepeatResultsData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import React from 'react';

type Props = {
  result: RepeatResultsData;
  isWeb: boolean;
};

const SpeedRepeatResultTableRow = ({ result, isWeb }: Props) => {
  return (
    <div className="flex h-[3.75rem] items-center border-b border-gray-300">
      <div className="flex-1 px-6 text-sm text-gray-900">{result.repeat}</div>
      <div className="flex-1 px-6 text-sm text-gray-500">{result.dateTime}</div>
      {isWeb && (
        <div className="flex-1 px-6 text-sm text-gray-500">
          {result.domTime}
        </div>
      )}
      <div className="flex-1 px-6 text-sm text-gray-500">{result.loadTime}</div>
    </div>
  );
};

export default SpeedRepeatResultTableRow;
