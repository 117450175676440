import { Button } from '@autosquare/common';

import { useCreateAdminProjectMutation } from '@lib/api/dashboard/admin/admin';

import { CreateAdminProjectData } from '@customTypes/dashboard/admin/type';

import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import Features from './AdminCreateProjectForm/Features';
import ProjectAdmin from './AdminCreateProjectForm/ProjectAdmin';

const AdminCreateProjectForm = () => {
  const methods = useForm<CreateAdminProjectData>();

  const createAdminProjectMutation = useCreateAdminProjectMutation();

  const onSubmit: SubmitHandler<CreateAdminProjectData> = (data) => {
    if (data.projectAdminList.length === 0) {
      methods.setError('projectAdminList', {
        type: 'required',
        message: 'Project Admin은 한 명 이상 필수로 등록해야합니다.',
      });
    } else if (data.features.length === 0) {
      methods.setError('features', {
        type: 'required',
        message: '프로젝트 기능을 1개 이상 선택해주세요.',
      });
    } else {
      createAdminProjectMutation.mutate(data);
    }
  };

  const inputValues = [
    {
      labelName:
        'Project name(필수 - 4~20자, 영문 소문자, 숫자[0-9], -_. 입력 가능)',
      id: 'name',
      placeHolder: 'Project name',
      register: {
        ...methods.register('name', {
          pattern: {
            value: /[a-z0-9-_.]{4,20}/,
            message: '4~20자, 영문 소문자, 숫자[0-9], -_. 만 입력 가능합니다.',
          },
          required: '필수 입력 사항입니다.',
          minLength: { value: 4, message: '4자 이상 입력 가능합니다.' },
          maxLength: { value: 20, message: '20자 이하 입력 가능합니다.' },
        }),
      },
    },
    {
      labelName: 'Service name(옵션 - 0~100자 입력 가능)',
      id: 'serviceName',
      placeHolder: 'Service name',
      minLength: 0,
      maxLength: 100,
      register: {
        ...methods.register('serviceName', {
          maxLength: { value: 100, message: '100자 이하 입력 가능합니다.' },
        }),
      },
    },
    {
      labelName: 'Description(옵션 - 0~100자 입력 가능)',
      id: 'description',
      placeHolder: 'Description',
      minLength: 0,
      maxLength: 100,
      register: {
        ...methods.register('description', {
          maxLength: { value: 100, message: '100자 이하 입력 가능합니다.' },
        }),
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <form className="pt-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="border-b border-gray-900/10 pb-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Project Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              프로젝트 정보를 입력해주세요.
            </p>
            <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-6">
              {inputValues?.map((value) => (
                <div className="col-span-full" key={value.id}>
                  <label
                    htmlFor={value.id}
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {value.labelName}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={value.id}
                      id={value.id}
                      className="input-base"
                      placeholder={value.placeHolder}
                      {...value.register}
                    />
                  </div>
                  <ErrorMessage
                    errors={methods.formState.errors}
                    name={value.id}
                    render={({ message }) => (
                      <p className="error-message py-1.5">{message}</p>
                    )}
                  />
                </div>
              ))}
              <ProjectAdmin />
            </div>
          </div>
          <Features />
        </div>
        <div className="mt-6 flex items-center justify-between gap-x-6">
          <div />
          {createAdminProjectMutation.isError && (
            <span className="error-message">
              {createAdminProjectMutation.error.message}
            </span>
          )}
          <Button type="submit" variant="primary">
            Create
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default AdminCreateProjectForm;
