import React, { useEffect, useState } from 'react';
import {
  BackToListIcon,
  ErrorMessage,
  SpeedTestResultDetailSkeleton,
  NoResults,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import dayjs from 'dayjs';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import InformationTable from '@components/MobileSpeedTestResultDetail/InformationTable/InformationTable';
import SpeedTestResultGraph from '@components/MobileSpeedTestResultDetail/SpeedTestResultGraph/SpeedTestResultGraph';
import RangePickEndCalendar from '@components/MobileSpeedTestResultDetail/RangePickCalendar/RangePickEndCalendar';
import RangePickStartCalendar from '@components/MobileSpeedTestResultDetail/RangePickCalendar/RangePickStartCalendar';
import { useMobileSpeedResultDetailQuery } from '@lib/api/dashboard/mobile/mobileSpeedResult';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

const MobileSpeedTestResultDetail = () => {
  const { pathname, search, state } = useLocation();
  const { projectIdx } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const entryPoint = useSelector(
    (state: RootState) => state.locationHistory.clickLocation,
  );
  const speedMobileTestResultDetailQuery = useMobileSpeedResultDetailQuery();
  const speedMobileTestResultDetailData = speedMobileTestResultDetailQuery.data;

  const start = searchParams.get('start');
  const defaultStartDate = dayjs(start).toDate();

  const end = searchParams.get('end');
  const defaultEndDate = dayjs(end).toDate();

  const resultIdx = searchParams.get('result');
  const groupIdx = searchParams.get('group');

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const isResultNA = state.status === 'NA';

  const failReason = speedMobileTestResultDetailData?.failReason;
  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -4).join('/')}/overview`,
    },
    {
      title: 'Mobile',
      href: `/projects/${projectIdx}/mobile-app/speed/test-result?page=1`,
    },
    {
      title: 'Test Result',
      href: `/projects/${projectIdx}/mobile-app/speed/test-result?page=1`,
    },
    { title: 'Detail', href: pathname + search, state: state },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: (
        <div className="flex items-center justify-between gap-1.5 text-sm font-semibold leading-5 text-gray-900">
          <BackToListIcon />
          Back to list
        </div>
      ),
      onClick: () => navigate(entryPoint),
      type: 'button',
      variant: 'secondary',
    },
  ];

  const location = isWordInLocationPathname('mobile') ? 'mobile' : 'web';
  const copyUrl = `${localStorage.getItem('applicationUrl')}/projects/${projectIdx}/${location}/test-result/detail${search}`;
  const NoData = speedMobileTestResultDetailData?.dateList.length === 0;

  useEffect(() => {
    setStartDate(new Date(dayjs(start).format()));
    speedMobileTestResultDetailQuery.refetch();
  }, [start]);

  useEffect(() => {
    setEndDate(new Date(dayjs(end).format()));
    speedMobileTestResultDetailQuery.refetch();
  }, [end]);

  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result Detail'}
        pageHeadingButtons={pageHeadingButtons}
        copy={true}
        url={copyUrl}
      />
      {speedMobileTestResultDetailQuery.isLoading ? (
        <SpeedTestResultDetailSkeleton />
      ) : speedMobileTestResultDetailQuery.isError ? (
        <ErrorMessage>
          {speedMobileTestResultDetailQuery.error.message}
        </ErrorMessage>
      ) : (
        <div className="mt-14 px-8">
          <div className="flex items-center justify-between">
            <h2 className="font-semibold">
              {speedMobileTestResultDetailData?.group} Loading Time 비교측정
              결과
            </h2>
            {!isResultNA && (
              <div className="flex items-center justify-center gap-x-3">
                <RangePickStartCalendar
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  resultIdx={resultIdx}
                  groupIdx={groupIdx}
                  minDate={speedMobileTestResultDetailData.dateList[0]}
                />
                <span>&#45;</span>
                <RangePickEndCalendar
                  endDate={endDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  resultIdx={resultIdx}
                  groupIdx={groupIdx}
                />
              </div>
            )}
          </div>
          {NoData ? (
            <NoResults />
          ) : (
            <>
              <InformationTable data={speedMobileTestResultDetailData} />
              {speedMobileTestResultDetailQuery.isRefetching ? (
                <SpeedTestResultDetailSkeleton
                  isRefetching={speedMobileTestResultDetailQuery.isRefetching}
                />
              ) : (
                <SpeedTestResultGraph
                  data={speedMobileTestResultDetailData}
                  isResultNA={isResultNA}
                  failReason={failReason}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MobileSpeedTestResultDetail;
