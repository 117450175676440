import {
  Button,
  Checkbox,
  DoubleCheckDialogHeadlessUI,
  TableBodyBasic,
  TableRowBackgroundGray,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '@app/store';

import { useUpdateSchedulerStatusMutation } from '@lib/api/ide/scheduler/scheduler';

import {
  SchedulerDetailData,
  SchedulerTypeName,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import { parseCronCycle } from '@utils/static/parseCronCycle';
import { formatDays } from '@utils/static/selectedDays';
import {
  ConnectionType,
  DeviceInfoOs,
  DeviceInfoOsFullName,
} from '@customTypes/ide/device/device';
import { LiaUsb } from 'react-icons/lia';
import { WifiIcon } from '@heroicons/react/20/solid';

interface Props {
  idx: number;
  schedulerList: SchedulerDetailData;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
}

const SchedulerListTableBody = ({
  idx,
  schedulerList,
  selectArray,
  selectedArray,
}: Props) => {
  const navigate = useNavigate();
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);

  const updateSchedulerStatusMutation = useUpdateSchedulerStatusMutation({
    idx: schedulerList.idx,
    isRunning: !schedulerList.running,
    cookie: cookie,
  });
  const environmentSplitArray = schedulerList.environment.split('/');

  return (
    <>
      <TableRowBackgroundGray isInvalid={!schedulerList.running}>
        <TableBodyBasic>
          <Checkbox
            checked={selectedArray.includes(schedulerList.idx)}
            onChange={(e) => selectArray(schedulerList.idx, e)}
          />
        </TableBodyBasic>
        <TableBodyBasic>{idx + 1}</TableBodyBasic>
        <TableBodyBasic>
          {schedulerList.type === SchedulerTypeServerName.Mobile
            ? SchedulerTypeName.Mobile
            : SchedulerTypeName.Web}
        </TableBodyBasic>
        <TableBodyBasic>
          {schedulerList.type === SchedulerTypeServerName.Mobile ? (
            <div className="flex items-start gap-2">
              {environmentSplitArray[3] === ConnectionType.USB ||
              environmentSplitArray[3] === 'null' ||
              environmentSplitArray[3] === undefined ? (
                <LiaUsb size={24} />
              ) : (
                <WifiIcon className="size-5" />
              )}
              <div>
                <p>{environmentSplitArray[1]}</p>
                <p className="text-xs text-gray-500">
                  {environmentSplitArray[2] === DeviceInfoOs.Aos
                    ? DeviceInfoOsFullName.Aos
                    : DeviceInfoOsFullName.Ios}
                </p>
              </div>
            </div>
          ) : (
            environmentSplitArray[0][0].toUpperCase() +
            environmentSplitArray[0].slice(1)
          )}
        </TableBodyBasic>
        <TableBodyBasic>{schedulerList.title}</TableBodyBasic>
        <TableBodyBasic>
          <p>{schedulerList.scenario_idx_list.length}</p>
        </TableBodyBasic>
        <TableBodyBasic>
          <div className="flex flex-col">
            <span>After: {schedulerList.start_time.slice(0, -4)}</span>
            <span>
              {`On: ${formatDays(parseCronCycle(schedulerList.cycle).onPart)}`}
            </span>
            <span>
              {`Run: ${parseCronCycle(schedulerList.cycle).timeInterval}`}
            </span>
          </div>
        </TableBodyBasic>
        <TableBodyBasic>
          <button
            type="button"
            className="rounded-md hover:text-congress-blue"
            onClick={() =>
              updateSchedulerStatusMutation.mutate(undefined, {
                onError: () => setIsErrorModalOpened(true),
              })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5"
            >
              {schedulerList.running ? (
                <>
                  <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                  <path
                    fillRule="evenodd"
                    d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                    clipRule="evenodd"
                  />
                </>
              ) : (
                <>
                  <path
                    fillRule="evenodd"
                    d="M3.28 2.22a.75.75 0 00-1.06 1.06l14.5 14.5a.75.75 0 101.06-1.06l-1.745-1.745a10.029 10.029 0 003.3-4.38 1.651 1.651 0 000-1.185A10.004 10.004 0 009.999 3a9.956 9.956 0 00-4.744 1.194L3.28 2.22zM7.752 6.69l1.092 1.092a2.5 2.5 0 013.374 3.373l1.091 1.092a4 4 0 00-5.557-5.557z"
                    clipRule="evenodd"
                  />
                  <path d="M10.748 13.93l2.523 2.523a9.987 9.987 0 01-3.27.547c-4.258 0-7.894-2.66-9.337-6.41a1.651 1.651 0 010-1.186A10.007 10.007 0 012.839 6.02L6.07 9.252a4 4 0 004.678 4.678z" />
                </>
              )}
            </svg>
          </button>
        </TableBodyBasic>
        <TableBodyBasic>
          <button
            type="button"
            className="rounded-md hover:text-congress-blue"
            onClick={() => navigate(`detail?idx=${schedulerList.idx}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
              />
            </svg>
          </button>
        </TableBodyBasic>
      </TableRowBackgroundGray>
      <DoubleCheckDialogHeadlessUI
        isOpened={isErrorModalOpened}
        setIsOpened={setIsErrorModalOpened}
        type="caution"
        title={'Error'}
        subTitle={
          updateSchedulerStatusMutation.isError &&
          updateSchedulerStatusMutation.error.message
        }
        buttonChildren={
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsErrorModalOpened(false)}
          >
            Cancel
          </Button>
        }
      />
    </>
  );
};

export default SchedulerListTableBody;
