import { ListBoxHeadlessInController } from '@autosquare/common';

import DefinitionList from '@components/shared/DefinitionList';
import DefinitionTextAreaByReactHookForm from '@components/shared/TestStepDetail/DefinitionTextAreaByReactHookForm';
import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';

import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
} from '@utils/static/mobileCommandOption';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const IfCondition = () => {
  const { control } = useFormContext<UpdateTestStepData>();

  return (
    <>
      <DetailDivisionLine
        title={'Condition'}
        description={'조건식을 설정합니다.'}
      />
      <DefinitionList
        term={'Command'}
        description={
          <Controller
            name={'optionsDto.conditionCommand'}
            control={control}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findMobileCommandNameFromServerName(field.value)}
                onChange={field.onChange}
                lists={mobileCommandOptions.filter(
                  (command) =>
                    command.name === MobileCommandOptionName.FindElement ||
                    command.name === MobileCommandOptionName.VerifyNoElement,
                )}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        }
      />
      <DefinitionTextAreaByReactHookForm
        term={'Value*'}
        name={'optionsDto.conditionValue'}
      />
    </>
  );
};

export default IfCondition;
