import { PriorityList } from '@customTypes/type';
import { PriorityNameEnum } from '@customTypes/testCase/type';

export const priorityLists: PriorityList[] = [
  {
    id: 1,
    name: PriorityNameEnum.High,
    color: 'bg-red-400',
    fillColor: 'fill-red-400',
  },
  {
    id: 2,
    name: PriorityNameEnum.Medium,
    color: 'bg-orange-400',
    fillColor: 'fill-orange-400',
  },
  {
    id: 3,
    name: PriorityNameEnum.Low,
    color: 'bg-blue-400',
    fillColor: 'fill-blue-400',
  },
];

export const findPriorityFromName = (name: PriorityNameEnum) =>
  priorityLists.find((priority) => priority.name === name);

export const findIdFromName = (name: PriorityNameEnum) =>
  priorityLists.find((priority) => priority.name === name)?.id;

export const findFillColorFromName = (name: PriorityNameEnum) =>
  priorityLists.find((priority) => priority.name === name)?.fillColor;

export const findColorFromName = (name: PriorityNameEnum) =>
  priorityLists.find((priority) => priority.name === name)?.color;
