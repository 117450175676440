import React from "react";
import { useLocation } from "react-router-dom";

import {
  MobileSizePagination,
  OverSevenPages,
  PaginationLeftButton,
  PaginationNumberButton,
  PaginationRightButton,
} from "@autosquare/common";

type Props = {
  page: number;
  total: number;
  limit: number;
};

const MobileTestResultPagination = ({ page, total, limit }: Props) => {
  const { pathname } = useLocation();

  const numPages = isNaN(total) ? 1 : Math.ceil(total / limit);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white py-3">
      <MobileSizePagination
        page={page}
        numPages={numPages}
        pathname={pathname}
      />
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{page} </span> to
            <span className="font-medium"> {numPages} </span> of
            <span className="font-medium"> {total}</span> results
          </p>
        </div>
        <div>
          <div
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <PaginationLeftButton page={page} />
            {numPages <= 7 ? (
              Array(numPages)
                ?.fill(undefined)
                ?.map((_, i) => (
                  <PaginationNumberButton key={i} page={page} item={i + 1}>
                    {i + 1}
                  </PaginationNumberButton>
                ))
            ) : (
              <OverSevenPages page={page} numPages={numPages} />
            )}
            <PaginationRightButton page={page} numPages={numPages} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileTestResultPagination;
