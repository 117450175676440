import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDrop } from "react-dnd";
import update from "react-addons-update";

import { getTestCaseIndexArray } from "@store/ide/numberArraySlice";

import DetailCard from "./DetailPaste/DetailCard";
import { TestCaseDetailDataWithoutSteps } from "@customTypes/testCase/type";

interface Props {
  copyTestCases: TestCaseDetailDataWithoutSteps[];
  setCopyTestCases: React.Dispatch<
    React.SetStateAction<TestCaseDetailDataWithoutSteps[]>
  >;
  changeCardList: (list: TestCaseDetailDataWithoutSteps[]) => void;
}

const DetailPaste = ({
  copyTestCases,
  setCopyTestCases,
  changeCardList,
}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestCaseIndexArray(copyTestCases.map((list) => list.idx)));
  }, [copyTestCases]);

  const [, drop] = useDrop({
    accept: "card",
  });

  const onRemove = useCallback((idx: number) => {
    setCopyTestCases((prev) =>
      update(prev, {
        $splice: [[idx, 1]],
      })
    );
  }, []);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === undefined) {
        const lessIndex = copyTestCases.findIndex(
          (item) => item.dndIndex === -1
        );
        changeCardList(
          update(copyTestCases, {
            $splice: [
              [lessIndex, 1],
              [hoverIndex, 0, { title: "Here", dndIndex: -1 }],
            ],
          })
        );
      } else {
        const dragCard = copyTestCases[dragIndex];
        changeCardList(
          update(copyTestCases, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          })
        );
      }
    },
    [copyTestCases]
  );

  return (
    <div ref={drop} className="w-full h-[calc(100%-0.5rem)]">
      {copyTestCases?.length === 0 ? (
        <div className="flex flex-col justify-center items-center w-full h-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
          <p className="mt-2">Drag and Drop here</p>
        </div>
      ) : (
        copyTestCases?.map((copyTestCase, index) => (
          <DetailCard
            key={index}
            index={index}
            title={copyTestCase?.title}
            moveCard={moveCard}
            onRemove={onRemove}
          />
        ))
      )}
    </div>
  );
};

export default DetailPaste;
