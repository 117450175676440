import {
  InputBasic,
  ScenarioListDetailData,
  SubHeaderTitle,
} from '@autosquare/common';

import React from 'react';

interface Props {
  title: string;
  scenarioDetails: ScenarioListDetailData;
  onChangeTitle: React.ChangeEventHandler<HTMLInputElement>;
  onChangeDescription: React.ChangeEventHandler<HTMLInputElement>;
  isWrited: boolean;
}

const DetailInput = ({
  title,
  scenarioDetails,
  onChangeTitle,
  onChangeDescription,
  isWrited,
}: Props) => {
  return (
    <div className="mt-8 pb-4">
      <SubHeaderTitle
        subTitle={'Test Scenario Information'}
        description={'테스트 시나리오 정보를 입력해주세요.'}
      />
      <div className="mt-4 flex flex-col">
        <div className="flex flex-row gap-4">
          <InputBasic
            state={title}
            labelName={'title'}
            inputName={'Title*'}
            onChange={onChangeTitle}
            defaultValue={scenarioDetails?.title}
            isWrited={isWrited}
            maxLength={50}
          />
          <InputBasic
            labelName="description"
            inputName="Description"
            onChange={onChangeDescription}
            defaultValue={scenarioDetails?.description}
            minLength={0}
            maxLength={200}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailInput;
