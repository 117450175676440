import React, { Fragment, useContext, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { MobileTestStepDetailContext } from '@contexts/ide/MobileTestStepDetailProvider';

import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

import {
  deviceKeyList,
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';

interface Props {
  command: string;
}

const TestStepSelectBox = ({ command }: Props) => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = Number(searchParams.get('idx'));

  const { control, watch, setValue } = useFormContext<UpdateTestStepData>();
  const { mobileOS } = useContext(MobileTestStepDetailContext);

  const value = watch('value');
  const isUiTestLocation = isWordInLocationPathname('ui-test');
  const mobileTestCaseList = isUiTestLocation
    ? useMobileTestCaseListQuery(projectIdx, {
        enabled:
          isUiTestLocation &&
          command === MobileCommandOptionServerName.ImportTestCase,
      })
    : useSpeedMobileTestCaseListQuery({
        enabled:
          !isUiTestLocation &&
          command === MobileCommandOptionServerName.ImportTestCase,
      });

  const mobileTestCaseListData = mobileTestCaseList.data?.filter(
    (mobileTestCase) => mobileTestCase?.idx !== idx,
  );

  // 초기 string인 value를 index로 변환
  useEffect(() => {
    if (
      command === MobileCommandOptionServerName.ImportTestCase &&
      mobileTestCaseListData?.some(
        (mobileTestCase) => mobileTestCase?.idx === Number(value),
      )
    ) {
      setValue(
        'value',
        mobileTestCaseListData
          ?.find((mobileTestCase) => mobileTestCase?.idx === Number(value))
          ?.idx.toString(),
      );
    }
  }, [command, value]);

  return (
    <div className="flex items-center border-b border-gray-200 sm:px-6 sm:py-5">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        Value
      </dt>
      <dd className="mt-1 w-[calc(100%-12rem)] text-sm text-gray-900 sm:ml-6 sm:mt-0">
        <Controller
          name="value"
          control={control}
          render={({ field }) => (
            <Listbox
              as="div"
              value={field.value}
              onChange={field.onChange}
              className="w-full"
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <span className="block truncate">
                        {command === MobileCommandOptionServerName.DeviceKey
                          ? findListNameFromServerName(
                              field.value as DeviceKeyServerName,
                            )
                          : mobileTestCaseListData?.some(
                                (mobileTestCase) =>
                                  mobileTestCase?.title === field.value,
                              )
                            ? field.value
                            : mobileTestCaseListData?.find(
                                (mobileTestCase) =>
                                  mobileTestCase?.idx === Number(field.value),
                              )?.title}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="size-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <ListboxOptions className="absolute z-10 mt-1 max-h-96 w-full overflow-y-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 scrollbar-thin focus:outline-none sm:text-sm">
                        {command === MobileCommandOptionServerName.DeviceKey &&
                        mobileOS === DeviceInfoOs.Aos
                          ? deviceKeyList.slice(1).map((deviceKey) => (
                              <ListboxOption
                                key={deviceKey.idx}
                                className={({ focus }) =>
                                  `relative cursor-default select-none py-2 pl-3 pr-9 ${
                                    focus
                                      ? 'bg-indigo-600 text-white'
                                      : 'text-gray-900'
                                  }`
                                }
                                value={deviceKey.serverName}
                              >
                                {({ selected, focus }) => (
                                  <>
                                    <span
                                      className={`block break-all ${
                                        selected ? 'font-medium' : 'font-normal'
                                      }`}
                                    >
                                      {deviceKey.listName}
                                    </span>
                                    {selected && (
                                      <span
                                        className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                          focus
                                            ? 'text-white'
                                            : 'text-indigo-600'
                                        }`}
                                      >
                                        <CheckIcon
                                          className="size-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </>
                                )}
                              </ListboxOption>
                            ))
                          : command ===
                                MobileCommandOptionServerName.DeviceKey &&
                              mobileOS === DeviceInfoOs.Ios
                            ? deviceKeyList.slice(2).map((deviceKey) => (
                                <ListboxOption
                                  key={deviceKey.idx}
                                  className={({ focus }) =>
                                    `relative cursor-default select-none py-2 pl-3 pr-9 ${
                                      focus
                                        ? 'bg-indigo-600 text-white'
                                        : 'text-gray-900'
                                    }`
                                  }
                                  value={deviceKey.serverName}
                                >
                                  {({ selected, focus }) => (
                                    <>
                                      <span
                                        className={`block break-all ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {deviceKey.listName}
                                      </span>
                                      {selected && (
                                        <span
                                          className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                            focus
                                              ? 'text-white'
                                              : 'text-indigo-600'
                                          }`}
                                        >
                                          <CheckIcon
                                            className="size-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </ListboxOption>
                              ))
                            : mobileTestCaseListData?.map((mobileTestCase) => (
                                <ListboxOption
                                  key={mobileTestCase?.idx}
                                  className={({ focus }) =>
                                    `relative cursor-default select-none py-2 pl-3 pr-9 ${
                                      focus
                                        ? 'bg-indigo-600 text-white'
                                        : 'text-gray-900'
                                    }`
                                  }
                                  value={mobileTestCase?.idx}
                                >
                                  {({ selected, focus }) => (
                                    <>
                                      <span
                                        className={`block break-all ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {mobileTestCase?.title}
                                      </span>
                                      {selected && (
                                        <span
                                          className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                            focus
                                              ? 'text-white'
                                              : 'text-indigo-600'
                                          }`}
                                        >
                                          <CheckIcon
                                            className="size-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </ListboxOption>
                              ))}
                      </ListboxOptions>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          )}
        />
      </dd>
    </div>
  );
};

export default TestStepSelectBox;
