import {
  TestCaseDetailData,
  WebTestCaseDetailData,
} from '@customTypes/testCase/type';

export const findTitleFromIdxByTestCase = (
  idx: number,
  testCaseList: (TestCaseDetailData | WebTestCaseDetailData)[],
) => testCaseList?.find((testCase) => testCase.idx === idx)?.title;

export const findItem = <T>(
  arr: T[],
  predicate: (item: T) => boolean,
): T | undefined => {
  return arr.find(predicate);
};
