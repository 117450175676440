import {
  DefaultSkeleton,
  ErrorMessage,
  Pagination,
  PagingHeadingsInAutosquare,
  TableSkeletonDefault,
} from '@autosquare/common';

import React from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useMobileTestResultListQuery } from '@lib/api/ide/mobile/mobileTestResult';

import TestResultTable from './components/TestResultTable';

const IdeMobileTestResult = () => {
  const PAGE_LIMIT = 10;
  const { projectIdx } = useParams();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));

  const mobileTestResultListQuery = useMobileTestResultListQuery(
    projectIdx,
    page,
    PAGE_LIMIT,
  );
  const mobileTestResultList = mobileTestResultListQuery.data;

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Mobile', href: pathname + search },
    { title: 'Test Result', href: pathname + search },
    { title: 'List', href: pathname + search },
  ];

  return (
    <>
      {
        <PagingHeadingsInAutosquare
          breadcrumbs={breadcrumbs}
          pageTitle={'Test Result List'}
        />
      }
      {mobileTestResultListQuery.isLoading ? (
        <TableSkeletonDefault number={1} even={true} />
      ) : mobileTestResultListQuery.isError ? (
        <ErrorMessage>{mobileTestResultListQuery.error.message}</ErrorMessage>
      ) : (
        <TestResultTable
          testResultList={mobileTestResultList}
          page={mobileTestResultList?.currentPage}
          PAGE_LIMIT={mobileTestResultList?.currentSize}
        />
      )}
      {mobileTestResultListQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        mobileTestResultList?.content.length !== 0 && (
          <Pagination
            page={page}
            total={mobileTestResultList?.totalElements}
            size={PAGE_LIMIT}
            isDashboard={false}
          />
        )
      )}
    </>
  );
};

export default IdeMobileTestResult;
