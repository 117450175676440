export enum FromName {
  Today = 'Today',
}

export enum FromServerName {
  Today = 'today',
}

export interface TextReplaceFromList {
  idx: number;
  name: FromName;
  serverName: FromServerName;
}

export enum AddName {
  Year = 'Year',
  Month = 'Month',
  Day = 'Day',
}

export enum AddServerName {
  Year = 'year',
  Month = 'month',
  Day = 'day',
}

export interface TextReplaceAddList {
  idx: number;
  name: AddName;
  serverName: AddServerName;
}

// Year
export enum YearName {
  None = 'None',
  YYYY = 'YYYY',
  YY = 'YY',
}

export enum YearServerName {
  None = '',
  YYYY = 'YYYY',
  YY = 'YY',
}

export interface DateFormatYearList {
  idx: number;
  name: YearName;
  serverName: YearServerName;
}

// Month
export enum MonthName {
  None = 'None',
  MM = 'MM',
  M = 'M',
}

export enum MonthServerName {
  None = '',
  MM = 'MM',
  M = 'M',
}

export interface DateFormatMonthList {
  idx: number;
  name: MonthName;
  serverName: MonthServerName;
}

// Day
export enum DayName {
  None = 'None',
  DD = 'DD',
  D = 'D',
}

export enum DayServerName {
  None = '',
  DD = 'DD',
  D = 'D',
}

export interface DateFormatDayList {
  idx: number;
  name: DayName;
  serverName: DayServerName;
}

export enum SeparatorName {
  None = 'None',
  Dot = '.',
  Hyphen = '-',
  Slash = '/',
  Space = 'Space',
}

export enum SeparatorServerName {
  None = 'none',
  Dot = 'dot',
  Hyphen = 'dash',
  Slash = 'slash',
  Space = 'space',
}

export interface DateFormatSeparatorList {
  idx: number;
  name: SeparatorName;
  serverName: SeparatorServerName | null;
}
