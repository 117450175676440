import { ListBoxHeadlessUI } from '@autosquare/common';
import { RunningCycle } from '@customTypes/ide/scheduler/scheduler';

import { hourCycle, minuteCycle, time } from '@utils/static/runningCycle';
import React, { useState } from 'react';

type Props = {
  selectedCycle: RunningCycle;
  setSelectedCycle: React.Dispatch<React.SetStateAction<RunningCycle>>;
};

const CreateSpeedSchedulerRunningCycle = ({
  selectedCycle,
  setSelectedCycle,
}: Props) => {
  const [timeUnit, setTimeUnit] = useState('Hour');

  const handleTimeUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unit = e.target.value;
    setTimeUnit(unit);
    if (unit === 'Minute') {
      setSelectedCycle(minuteCycle[0]);
    } else {
      setSelectedCycle(hourCycle[0]);
    }
  };

  return (
    <div className="col-span-2">
      <label
        htmlFor="cycle"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Cycle
      </label>
      <div className="mt-2 flex items-center justify-center space-x-4">
        <div className="flex gap-x-3">
          {time.map((item) => (
            <label
              htmlFor={`${item.id}`}
              className="mr-2 flex items-center text-sm font-medium"
              key={item.id}
            >
              <input
                id={`${item.id}`}
                name="cycle unit"
                type="radio"
                value={item.val}
                className="input-radio-congress-blue mr-3"
                defaultChecked={timeUnit === item.val}
                onChange={handleTimeUnitChange}
              />
              {item.val}
            </label>
          ))}
        </div>
        <div className="w-full">
          <ListBoxHeadlessUI
            state={selectedCycle}
            setState={setSelectedCycle}
            list={timeUnit === 'Minute' ? minuteCycle : hourCycle}
            wordInButton="title"
            wordInOptions="title"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateSpeedSchedulerRunningCycle;
