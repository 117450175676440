import SelectedEmailCardList from '@components/Scheduler/SelectedEmailCardList';
import { CreateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { useSchedulerEmailAlarm } from '@hooks/speedScheduler/useSchedulerEmailAlarm';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import SpeedEmailAlarmCombobox from '@components/Scheduler/SpeedEmailAlarmCombobox';
import SpeedSpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';

const CreateSpeedTestSchedulerEmailAlarm = () => {
  const { setValue, watch } = useFormContext<CreateSpeedSchedulerData>();

  const mailReceivers = watch('mailReceivers');

  const {
    wrapperRef,
    onChangeEmailAddress,
    setIsOpenedMemberList,
    inputRef,
    emailAddress,
    temporarySavedEmailAddress,
    setEmailAddress,
    setSelectedIndex,
    setEmailErrorMessage,
    filteredPeople,
    setTemporarySavedEmailAddress,
    selectedIndex,
    inputFocus,
    isOpenedMemberList,
    ulRef,
    onSelectMember,
    emailErrorMessage,
    deleteTagItem,
  } = useSchedulerEmailAlarm(mailReceivers, setValue);

  return (
    <SpeedSpeedSchedulerGridContainer title="Email Alarm" isTitleCenter={true}>
      <SpeedEmailAlarmCombobox
        wrapperRef={wrapperRef}
        onChangeEmailAddress={onChangeEmailAddress}
        setIsOpenedMemberList={setIsOpenedMemberList}
        inputRef={inputRef}
        emailAddress={emailAddress}
        temporarySavedEmailAddress={temporarySavedEmailAddress}
        setEmailAddress={setEmailAddress}
        setSelectedIndex={setSelectedIndex}
        setEmailErrorMessage={setEmailErrorMessage}
        filteredPeople={filteredPeople}
        setTemporarySavedEmailAddress={setTemporarySavedEmailAddress}
        selectedIndex={selectedIndex}
        inputFocus={inputFocus}
        isOpenedMemberList={isOpenedMemberList}
        ulRef={ulRef}
        onSelectMember={onSelectMember}
        mailReceivers={mailReceivers}
        setValue={setValue}
      />
      <p className="standard-error-message">{emailErrorMessage}</p>
      <SelectedEmailCardList
        mailReceivers={mailReceivers}
        deleteTagItem={deleteTagItem}
      />
    </SpeedSpeedSchedulerGridContainer>
  );
};

export default CreateSpeedTestSchedulerEmailAlarm;
