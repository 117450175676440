import {
  TableBodyBasic,
  Checkbox,
  Detail,
  StartAndFinishMark,
  SpeedTableBodyBasic,
} from '@autosquare/common';

import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import { getTestStepIdx } from '@store/ide/mobileTestStepDetail/mobileTestStepDetailSlice';

import { findMobileCommandNameFromServerName } from '@utils/static/mobileCommandOption';

import { MobileTestStepDetailContext } from '@contexts/ide/MobileTestStepDetailProvider';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import {
  MobileTestStepData,
  RepeatInfo,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import TestStepDetail from '@components/MobileTestCaseDetail/TableStepListTableRow/TestStepDetail';

interface Props {
  mobileTestStep: MobileTestStepData;
  mobileTestStepRepeatInfo: RepeatInfo;
  mobileTestStepList: MobileTestStepData[];
  index: number;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
  title: DeviceInfoOs;
}

const TestStepListTableRow = ({
  mobileTestStep,
  mobileTestStepRepeatInfo,
  mobileTestStepList,
  index,
  selectArray,
  selectedArray,
  title,
}: Props) => {
  const dispatch = useDispatch();

  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const { setMobileOS } = useContext(MobileTestStepDetailContext);

  const startStepIdx = mobileTestStepRepeatInfo?.startIdx;
  const endStepIdx = mobileTestStepRepeatInfo?.endIdx;

  return (
    <>
      <Draggable draggableId={mobileTestStep.idx.toString()} index={index}>
        {(provided) => (
          <tr
            className={` h-full hover:bg-gray-50 [&>*:nth-child(3)]:whitespace-pre-wrap`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <SpeedTableBodyBasic
              isFirstPaddingZero={
                Number(
                  provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
                ) === startStepIdx ||
                Number(
                  provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
                ) === endStepIdx
              }
              withPadding={
                Number(
                  provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
                ) !== startStepIdx &&
                Number(
                  provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
                ) !== endStepIdx
              }
            >
              <div className="flex items-center justify-start gap-x-[0.6rem]">
                {Number(
                  provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
                ) === startStepIdx && <StartAndFinishMark size="large" />}
                {Number(
                  provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
                ) === endStepIdx && <StartAndFinishMark size="large" />}
                <Checkbox
                  checked={selectedArray.includes(mobileTestStep.idx)}
                  onChange={(e) => selectArray(mobileTestStep.idx, e)}
                />
              </div>
            </SpeedTableBodyBasic>
            <TableBodyBasic>{index + 1}</TableBodyBasic>
            <TableBodyBasic>
              {findMobileCommandNameFromServerName(mobileTestStep.command)}
            </TableBodyBasic>
            <TableBodyBasic>{mobileTestStep.description}</TableBodyBasic>
            <TableBodyBasic>
              <Detail
                onClick={() => {
                  dispatch(getTestStepIdx(mobileTestStep.idx));
                  setIsDialogOpened(true);
                  setMobileOS(title);
                }}
              />
              <TestStepDetail
                isDialogOpened={isDialogOpened}
                setIsDialogOpened={setIsDialogOpened}
                mobileTestStep={mobileTestStep}
                mobileTestStepList={mobileTestStepList}
              />
            </TableBodyBasic>
          </tr>
        )}
      </Draggable>
    </>
  );
};

export default TestStepListTableRow;
