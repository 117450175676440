import {
  Checkbox,
  UseCheckboxAttribute,
  Detail,
  Execution,
  TableBodyBasic,
  TableHeader,
  TableNoList,
  TableWrapper,
  Time,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { findPlatformTypeNameFromServerName } from '@utils/static/platformTypeList';

import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';
import { TestCaseDetailData } from '@customTypes/testCase/type';
import { timePattern } from '@utils/static/timePattern';

interface Props extends UseCheckboxAttribute {
  mobileTestCaseList: TestCaseDetailData[];
}

const MobileTestCaseList = ({
  mobileTestCaseList,
  selectAllArray,
  selectArray,
  selectedArray,
  openDialog,
}: Props) => {
  const navigate = useNavigate();

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testCaseIdx, setTestCaseIdx] = useState<number>(0);

  const tableHeaders = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="w-4" /> : 'No',
    },
    {
      idx: 3,
      title: selectedArray.length > 0 ? <div className="w-14" /> : 'Platform',
    },
    { idx: 4, title: 'Test Group' },
    { idx: 5, title: 'Title' },
    { idx: 6, title: 'Step Count' },
    { idx: 7, title: 'Priority' },
    { idx: 8, title: 'Creator' },
    { idx: 9, title: 'Updated at' },
    { idx: 10, title: '' },
    { idx: 11, title: '' },
  ];

  return (
    <>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr className="">
            {tableHeaders.map((tableHeader) => (
              <TableHeader key={tableHeader.idx}>
                {tableHeader.title}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {mobileTestCaseList?.length === 0 ? (
            <TableNoList colSpan={tableHeaders.length}>
              해당 프로젝트에 생성된 테스트 케이스가 존재하지 않습니다.
            </TableNoList>
          ) : (
            mobileTestCaseList?.map((mobileTestCase, idx) => (
              <tr key={mobileTestCase.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(mobileTestCase.idx)}
                    onChange={(e) => selectArray(mobileTestCase.idx, e)}
                  />
                </TableBodyBasic>
                <TableBodyBasic>{idx + 1}</TableBodyBasic>
                <TableBodyBasic>
                  {mobileTestCase.platformType === null
                    ? '-'
                    : findPlatformTypeNameFromServerName(
                        mobileTestCase.platformType,
                      )
                        .replace(/mobile/i, '')
                        .trim()}
                </TableBodyBasic>
                <TableBodyBasic>
                  {mobileTestCase.caseGroupInfo?.name ?? '-'}
                </TableBodyBasic>
                <td className="table-data-basic max-w-[14.75rem]">
                  <div className="font-medium text-gray-900">
                    {mobileTestCase.title}
                  </div>
                  <div className={`mt-1 w-full break-words text-gray-500`}>
                    {mobileTestCase.description}
                  </div>
                </td>
                <TableBodyBasic>
                  <p>AOS: {mobileTestCase.steps.aosStepCount}</p>
                  <p>iOS: {mobileTestCase.steps.iosStepCount}</p>
                </TableBodyBasic>
                <TableBodyBasic>{mobileTestCase.priority}</TableBodyBasic>
                <TableBodyBasic>{mobileTestCase.creator}</TableBodyBasic>
                <TableBodyBasic>
                  <div>{mobileTestCase?.updatedAt?.split(' ')[0] ?? '-'}</div>
                  <div
                    className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                  >
                    {mobileTestCase.updatedAt && <Time />}
                    {RegExp(timePattern).exec(mobileTestCase.updatedAt)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    testType="case"
                    tooltipLocation={'right'}
                    disabled={
                      mobileTestCase.steps.aosStepCount === 0 &&
                      mobileTestCase.steps.iosStepCount === 0
                    }
                    onClick={() => {
                      if (
                        mobileTestCase.steps.aosStepCount > 0 ||
                        mobileTestCase.steps.iosStepCount > 0
                      ) {
                        setTestCaseIdx(mobileTestCase.idx);
                        setIsTestExecutionOpened(true);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: 'detail',
                        search: `idx=${mobileTestCase.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </tbody>
      </TableWrapper>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testCaseIdx}
          testType={'case'}
          deviceType={'mobile'}
        />
      )}
    </>
  );
};

export default MobileTestCaseList;
