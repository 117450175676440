import {
  AuthCode,
  ResetAndConfirmPassword,
  SendAuthCode,
} from '@autosquare/common/src/utils/type/resetPassword/type';
import { axiosInstance } from '..';
import { API } from '@api/endpoints';
import { useMutation } from '@tanstack/react-query';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { useNavigate } from 'react-router-dom';

//POST
const sendAuthCode = async (data: SendAuthCode): Promise<SendAuthCode> => {
  const response = await axiosInstance.post(API.SEND_AUTH_CODE, data);
  return response.data;
};

export const useSendAuthCode = () => {
  return useMutation<unknown, ServerResponseErrorDetails, SendAuthCode>({
    mutationFn: (data) => sendAuthCode(data),
  });
};

const confirmAuthCode = async (data: AuthCode): Promise<AuthCode> => {
  const response = await axiosInstance.post(API.CONFIRM_AUTH_CODE, data);
  return response.data;
};

export const useConfirmAuthCode = () => {
  const navigate = useNavigate();
  return useMutation<unknown, ServerResponseErrorDetails, AuthCode>({
    mutationFn: (data) => confirmAuthCode(data),
    onSuccess: () => {
      navigate('/reset-password');
    },
  });
};

const resetPassword = async (
  data: ResetAndConfirmPassword,
): Promise<ResetAndConfirmPassword> => {
  const response = await axiosInstance.post(API.RESET_PASSWORD, data);
  return response.data;
};

export const useResetPassword = () => {
  return useMutation<
    unknown,
    ServerResponseErrorDetails,
    ResetAndConfirmPassword
  >({
    mutationFn: (data) => resetPassword(data),
  });
};
