import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const WaitTime = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  return (
    <div className="grid grid-cols-3 items-start gap-4">
      <label
        htmlFor="waitTime"
        className="block pt-1 text-sm font-medium leading-6 text-gray-900"
      >
        WaitTime(sec)
      </label>
      <div className="col-span-2">
        <input
          type="number"
          name="waitTime"
          id="waitTime"
          className="input-base"
          defaultValue={2}
          {...register('optionsDto.waitingTime', {
            required: 'WaitTime을 입력해주세요.',
            min: {
              value: 0,
              message: '0 이상 60 이하 숫자만 입력 가능합니다.',
            },
            max: {
              value: 60,
              message: '0 이상 60 이하 숫자만 입력 가능합니다.',
            },
          })}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name={'optionsDto.waitingTime'}
        render={({ message }) => (
          <p className="error-message col-span-3">{message}</p>
        )}
      />
    </div>
  );
};

export default WaitTime;
