import { ListBoxHeadlessInController } from '@autosquare/common';
import DefinitionList from '@components/shared/DefinitionList';
import DivisionWord from '@components/shared/TestStepDetail/DivisionWord';
import ControllerTextArea from '@components/shared/TextArea/ControllerTextArea';
import { WebTestCaseDetailData } from '@customTypes/testCase/type';
import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import { findTitleFromIdxByTestCase } from '@utils/arrayUtils';
import {
  findKeyboardEventNameFromServerName,
  keyboardEventList,
  KeyboardEventServerName,
} from '@utils/static/keyboardEventList';

import {
  findWebCommandNameFromServerName,
  WebCommandOptionName,
  webCommandOptions,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  splitValue: (value: string, index: number) => WebCommandOptionServerName;
  selectedFailCommand: WebCommandOptionServerName;
  setSelectedFailCommand: React.Dispatch<
    React.SetStateAction<WebCommandOptionServerName>
  >;
  webTestStepData: WebTestStepData;
  filteredWebTestCaseList: WebTestCaseDetailData[];
};

const IfFailAction = ({
  splitValue,
  selectedFailCommand,
  setSelectedFailCommand,
  webTestStepData,
  filteredWebTestCaseList,
}: Props) => {
  const {
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const defaultFailCommand = splitValue(webTestStepData.value, 1);
  const commandNameFromServerName =
    findWebCommandNameFromServerName(defaultFailCommand);
  const failActionValue = webTestStepData.optionsDto?.failActionValue;

  const handleClickOption = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const target = e.target as HTMLElement;
    const textContent = target.textContent;

    if (textContent === commandNameFromServerName) {
      setValue('optionsDto.failActionValue', failActionValue);
    } else {
      setValue('optionsDto.failActionValue', '');
    }
  };

  useEffect(() => {
    if (
      selectedFailCommand !== defaultFailCommand ||
      (selectedFailCommand === defaultFailCommand &&
        watch('optionsDto.failActionValue') !== '')
    ) {
      clearErrors('optionsDto.failActionValue');
    }
  }, [selectedFailCommand, clearErrors]);

  return (
    <>
      <DivisionWord color="red">FAIL</DivisionWord>
      <DefinitionList
        term={'Command'}
        description={
          <ListBoxHeadlessInController
            value={selectedFailCommand}
            buttonValue={findWebCommandNameFromServerName(selectedFailCommand)}
            onChange={setSelectedFailCommand}
            lists={webCommandOptions.filter(
              (command) =>
                command.name === WebCommandOptionName.Click ||
                command.name === WebCommandOptionName.ImportTestCase ||
                command.name === WebCommandOptionName.KeyBoardEvent,
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            onClickOption={handleClickOption}
          />
        }
      />
      {
        <DefinitionList
          term={'Value*'}
          description={
            <Controller
              control={control}
              name="optionsDto.failActionValue"
              rules={{
                required:
                  selectedFailCommand === WebCommandOptionServerName.Click
                    ? 'Value 값을 입력해 주세요.'
                    : selectedFailCommand ===
                        WebCommandOptionServerName.KeyBoardEvent
                      ? 'Key Event를 선택해 주세요.'
                      : selectedFailCommand ===
                          WebCommandOptionServerName.ImportTestCase &&
                        'Test Case를 선택해 주세요',
              }}
              render={({ field }) =>
                selectedFailCommand === WebCommandOptionServerName.Click ? (
                  <ControllerTextArea field={field} />
                ) : selectedFailCommand ===
                  WebCommandOptionServerName.KeyBoardEvent ? (
                  <>
                    <ListBoxHeadlessInController
                      value={field.value}
                      buttonValue={
                        findKeyboardEventNameFromServerName(
                          field.value as KeyboardEventServerName,
                        ) || 'Select Keyboard Event'
                      }
                      onChange={field.onChange}
                      lists={keyboardEventList}
                      valueToSave={'serverName'}
                      valueToShow={'name'}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={'optionsDto.failActionValue'}
                      render={({ message }) => (
                        <p className="standard-error-message">{message}</p>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <ListBoxHeadlessInController
                      value={field.value}
                      buttonValue={
                        findTitleFromIdxByTestCase(
                          Number(field.value),
                          filteredWebTestCaseList,
                        ) || 'Select Test Case'
                      }
                      onChange={field.onChange}
                      lists={filteredWebTestCaseList}
                      valueToSave={'idx'}
                      valueToShow={'title'}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={'optionsDto.failActionValue'}
                      render={({ message }) => (
                        <p className="standard-error-message">{message}</p>
                      )}
                    />
                  </>
                )
              }
            />
          }
        />
      }
    </>
  );
};

export default IfFailAction;
