import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';
import { UseMutationResult } from '@tanstack/react-query';

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeviceSettingsContext } from '@contexts/ide/DeviceSettingsProvider';

import { InstallMandatoryApp } from '@customTypes/setting/type';

interface Props {
  previousUrl: string;
  installMandatoryAppMutation: UseMutationResult<
    unknown,
    Error,
    InstallMandatoryApp,
    unknown
  >;
}

const DoubleCheckDialog = ({
  previousUrl,
  installMandatoryAppMutation,
}: Props) => {
  const {
    checkFilenameExtension,
    setCheckFilenameExtension,
    isOpenedDialog,
    setIsOpenedDialog,
    closeDialog,
  } = useContext(DeviceSettingsContext);

  const navigate = useNavigate();

  return (
    <>
      <DoubleCheckDialogHeadlessUI
        isOpened={checkFilenameExtension}
        setIsOpened={setCheckFilenameExtension}
        type={'caution'}
        title={'File Error'}
        subTitle={'iOS 패키지 아카이브(.ipa)만 업로드 가능합니다.'}
        buttonChildren={
          <Button
            type="button"
            onClick={() => setCheckFilenameExtension(false)}
          >
            Close
          </Button>
        }
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedDialog}
        setIsOpened={setIsOpenedDialog}
        type={
          installMandatoryAppMutation.isSuccess
            ? 'checked'
            : installMandatoryAppMutation.isError
              ? 'caution'
              : 'yellow caution'
        }
        title={
          installMandatoryAppMutation.isSuccess
            ? 'Install Successful'
            : installMandatoryAppMutation.isError && 'Install Failure'
        }
        subTitle={
          installMandatoryAppMutation.isSuccess
            ? 'Integration App 설치가 완료되었습니다.'
            : installMandatoryAppMutation.isError &&
              `Integration App 설치에 실패하였습니다.\n사유: ${installMandatoryAppMutation.error.message}`
        }
        buttonChildren={
          installMandatoryAppMutation.isSuccess ? (
            <Button
              type="button"
              onClick={() => {
                closeDialog();
                navigate(previousUrl);
              }}
            >
              Close
            </Button>
          ) : (
            installMandatoryAppMutation.isError && (
              <Button type="button" onClick={closeDialog}>
                Close
              </Button>
            )
          )
        }
      />
    </>
  );
};

export default DoubleCheckDialog;
