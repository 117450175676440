import React from 'react';

import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import {
  MobileCommandOptionServerName,
  findMobileCommandNameFromServerName,
} from '@utils/static/mobileCommandOption';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import SpeedPropertyDetail from './SpeedPropertyDetail';

type Props = {
  list: MobileTestStepData;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
  findTitleFromIdx: (idx: number) => string;
};

const IfTestStepList = ({ list, setIsCopied, findTitleFromIdx }: Props) => {
  const passCondition = list.value.split(',')[0];
  const failCondition = list.value.split(',')[1];

  return (
    <>
      <SpeedPropertyDetail
        term={'Condition'}
        description={findMobileCommandNameFromServerName(
          list.optionsDto.conditionCommand,
        )}
      />
      <SpeedPropertyDetail
        term={'Value'}
        description={list.optionsDto.conditionValue}
        isRequiredCopy={true}
        setIsCopied={setIsCopied}
      />
      <SpeedPropertyDetail
        term={'Pass'}
        description={findMobileCommandNameFromServerName(
          passCondition as MobileCommandOptionServerName,
        )}
      />
      <SpeedPropertyDetail
        term={'Value'}
        description={
          passCondition === MobileCommandOptionServerName.DeviceKey
            ? findListNameFromServerName(
                list.optionsDto.passActionValue as DeviceKeyServerName,
              )
            : passCondition === MobileCommandOptionServerName.ImportTestCase
              ? findTitleFromIdx(Number(list.optionsDto.passActionValue))
              : list.optionsDto.passActionValue
        }
        isRequiredCopy={true}
        setIsCopied={setIsCopied}
      />
      {failCondition !== undefined &&
        list.optionsDto.failActionValue !== undefined && (
          <>
            <SpeedPropertyDetail
              term={'Fail'}
              description={findMobileCommandNameFromServerName(
                failCondition as MobileCommandOptionServerName,
              )}
            />
            <SpeedPropertyDetail
              term={'Value'}
              description={
                failCondition === MobileCommandOptionServerName.DeviceKey
                  ? findListNameFromServerName(
                      list.optionsDto.failActionValue as DeviceKeyServerName,
                    )
                  : failCondition ===
                      MobileCommandOptionServerName.ImportTestCase
                    ? findTitleFromIdx(Number(list.optionsDto.failActionValue))
                    : list.optionsDto.failActionValue
              }
              isRequiredCopy={true}
              setIsCopied={setIsCopied}
            />
          </>
        )}
    </>
  );
};

export default IfTestStepList;
