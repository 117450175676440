import {
  Breadcrumbs,
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import { RootState } from '@app/store';

import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

import { useCreateWebTestCaseMutation } from '@lib/api/ide/web/webTestCase';

import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';

import {
  CreateWebTestCaseData,
  PriorityNameEnum,
} from '@customTypes/testCase/type';

import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import CreateWebTestCaseInfo from './components/CreateWebTestCaseInfo';

const CreateWebTestCase = () => {
  const { projectIdx } = useParams();
  const { pathname, search } = useLocation();

  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );
  const dispatch = useDispatch();

  const methods = useForm<CreateWebTestCaseData>({
    defaultValues: {
      priority: PriorityNameEnum.Medium,
    },
  });

  const createWebTestCaseMutation = useCreateWebTestCaseMutation(projectIdx);

  const onSubmit: SubmitHandler<CreateWebTestCaseData> = (data) => {
    createWebTestCaseMutation.mutate(data);
  };

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -3).join('/')}/overview`,
    },
    { title: 'Web', href: `/ide/projects/${projectIdx}/ui-test/web/test-case` },
    {
      title: 'Test Case',
      href: `/ide/projects/${projectIdx}/ui-test/web/test-case`,
    },
    { title: 'Create', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    { title: 'Save', variant: 'primary', type: 'submit' },
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PagingHeadingsNavigate
            breadcrumbs={breadcrumbs}
            pageTitle={'Test Case Create'}
            pageHeadingButtons={pageHeadingButtons}
          />
          <CreateWebTestCaseInfo />
          {createWebTestCaseMutation.isError && (
            <ErrorMessage>
              {createWebTestCaseMutation.error.message}
            </ErrorMessage>
          )}
        </form>
      </FormProvider>
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
    </>
  );
};

export default CreateWebTestCase;
