import React from 'react';
import clsx from 'clsx';
import { PlatFormTypeServerName } from '@autosquare/common';
import { MobileSpeedTestResultTableDetailData } from '@customTypes/ide/speedTest/Mobile/testResult/type';

type Props = {
  speedMobileTestResultTableData: MobileSpeedTestResultTableDetailData;
  platformTypeName: string;
};

const SpeedInformationTable = ({
  speedMobileTestResultTableData,
  platformTypeName,
}: Props) => {
  const informationTable =
    speedMobileTestResultTableData?.platform ===
    PlatFormTypeServerName.MobileApp
      ? [
          {
            title: 'Platform',
            content: platformTypeName,
          },
          {
            title: 'App Version',
            content: speedMobileTestResultTableData?.version ?? '-',
          },
          {
            title: 'Avg.Time',
            content: `${speedMobileTestResultTableData?.averageTime}s`,
          },
        ]
      : [
          {
            title: 'Platform',
            content: platformTypeName,
          },
          {
            title: 'Browser',
            content: speedMobileTestResultTableData?.version ?? '-',
          },
          { title: 'URL', content: speedMobileTestResultTableData?.url },
          {
            title: 'Avg.Time',
            content: `${speedMobileTestResultTableData?.averageTime}s`,
          },
        ];
  return (
    <div className="mt-3">
      <p className="border-y border-gray-200 py-3 text-sm font-semibold text-gray-900">
        Information
      </p>
      <div className="min-w-full">
        <div className="flex flex-col">
          {informationTable.map((info, index) => (
            <div
              key={info.title}
              className={clsx(
                'flex items-center justify-start',
                platformTypeName === 'Web' &&
                  index === 2 &&
                  'border-b border-gray-200',
              )}
            >
              <div className="w-48 px-6 py-5 text-sm font-medium text-gray-500">
                {info.title}
              </div>
              <div className="px-6 py-5 text-sm font-normal text-gray-900">
                {info.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpeedInformationTable;
