import { RunningCycle } from '@customTypes/ide/scheduler/scheduler';

export const time = [
  {
    id: 1,
    val: 'Minute',
  },
  {
    id: 2,
    val: 'Hour',
  },
];

export const runningMinuteCycle = () => {
  const minuteCycle = Array.from({ length: 59 }, (_, i) => ({
    idx: i + 1,
    title: `Every ${i + 1} minute`,
  }));
  return minuteCycle;
};

export const minuteCycle: RunningCycle[] = runningMinuteCycle();

export const findTitleFromMinute = (cycle: string) => {
  return minuteCycle.find((item) => item.title === cycle)?.title;
};

export const runningHourCycle = () => {
  const hourCycle = Array.from({ length: 24 }, (_, i) => ({
    idx: i + 1,
    title: `Every ${i + 1} hour`,
  }));
  return hourCycle;
};

export const hourCycle: RunningCycle[] = runningHourCycle();

export const findTitleFromHour = (cycle: string) => {
  return hourCycle.find((item) => item.title === cycle)?.title;
};
