import React, { useEffect, useState } from 'react';
import { DeviceInfo, DeviceInfoOs } from '@customTypes/ide/device/device';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { useParams } from 'react-router-dom';
import { PagingHeadingButtonOnClick } from '@autosquare/common';
import { getSelectedDevices } from '@store/ide/device/selectedDevicesSlice';
import { hasDownloadedData } from '@store/ide/settings/settingsSlice';
import RepeatInfo from './SpeedMobileTestExecution/RepeatInfo';
import SpeedExecutionInfo from './SpeedMobileTestExecution/SpeedExecutionInfo';
import SpeedSelectedDevices from './SpeedMobileTestExecution/SpeedSelectedDevices';
import { useFormContext } from 'react-hook-form';
import { SpeedMobileCaseExecution } from '@customTypes/speedTestExecution/speedTestExecution';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

type Props = {
  idx: number;
  setIsReadyToNavigate: React.Dispatch<React.SetStateAction<boolean>>;
  testType: TestTypeCategory;
  divWidth: number;
  mobileOS?: DeviceInfoOs;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
};

const SpeedMobileTestExecution = ({
  idx,
  setIsReadyToNavigate,
  testType,
  divWidth,
  mobileOS,
  setIsSettingsOpen,
  setIsWirelessDeviceModal,
  setConnectionType,
}: Props) => {
  const { projectIdx } = useParams();

  const [selectedDevices, setSelectedDevices] = useState<DeviceInfo[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isSelectedDevices, setIsSelectedDevices] = useState(false);
  const [isXcodeApp, setIsXcodeApp] = useState(false);
  const [isWdaFile, setIsWdaFile] = useState(false);
  const [needIosSetting, setNeedIosSetting] = useState(false);
  const [isInvalidRepeatCount, setIsInvalidRepeatCount] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedBrowser, setSelectedBrowser] = useState<string>(null);

  const { watch } = useFormContext<SpeedMobileCaseExecution>();

  const checkedDeviceHandler = (value: DeviceInfo, isChecked: boolean) => {
    const updatedValue = {
      ...value,
      browser: selectedBrowser ? 'chrome' : 'safari',
    };
    if (isChecked) {
      setSelectedDevices([...selectedDevices, updatedValue]);
    }
    const isIncluded = selectedDevices.some(
      (device) => device.udid === value.udid,
    );
    if (!isChecked && isIncluded) {
      setSelectedDevices([]);
    }
  };

  const checkHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: DeviceInfo,
  ) => {
    setIsChecked(!isChecked);
    checkedDeviceHandler(value, e.target.checked);
    setIsSelectedDevices(false);
  };

  const dispatch = useDispatch();
  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  useEffect(() => {
    dispatch(getSelectedDevices(selectedDevices));
  }, [selectedDevices]);

  const repeatCount = Number(watch('repeatCount'));
  const errorState =
    !selectedDevices?.length ||
    repeatCount > 50 ||
    repeatCount < 3 ||
    repeatCount === 0;

  const executionTestOnClick = () => {
    if (errorState) {
      if (!selectedDevices?.length) {
        setIsSelectedDevices(true);
      }
      if (repeatCount === 0 || repeatCount < 3) {
        setIsInvalidRepeatCount(true);
        setErrorMessage('Repeat 값을 최소 3부터 입력해 주세요.');
      }
      if (repeatCount > 50) {
        setIsInvalidRepeatCount(true);
        setErrorMessage('Repeat 값은 최대 50까지 입력 가능합니다.');
      }

      return;
    }
    if (
      selectedDevices.some(
        (device) =>
          device.os === DeviceInfoOs.Ios && (!isXcodeApp || !isWdaFile),
      )
    ) {
      setNeedIosSetting(true);
      return;
    }

    setIsSelectedDevices(false);
    setIsReadyToNavigate(true);
  };
  useEffect(() => {
    if (repeatCount >= 3 || repeatCount <= 50) {
      setIsInvalidRepeatCount(false);
      setErrorMessage('');
    }
  }, [repeatCount]);

  useEffect(() => {
    const getXcodeLocation = async () => {
      const xcodeLocation: string | undefined =
        await window.electron.invoke('checkXcodeLocation');
      setIsXcodeApp(xcodeLocation?.toLowerCase().includes('xcode.app'));
    };
    const checkWdaFile = async () => {
      const existWdaFile: boolean = await window.electron.invoke(
        'existWdaFile',
        projectIdx,
      );
      setIsWdaFile(existWdaFile);
    };
    getXcodeLocation();
    checkWdaFile();

    if (isDownloadedData) {
      getXcodeLocation();
      checkWdaFile();
      dispatch(hasDownloadedData(false));
    }
  }, [isDownloadedData]);

  const breadcrumbs = [
    { title: 'Speed Test' },
    { title: 'Mobile' },
    {
      title:
        testType === 'scenario'
          ? 'Test Scenario'
          : testType === 'case'
            ? 'Test Case'
            : 'Test Step',
    },
    { title: 'Execution' },
  ];

  const pageHeadingButtons = [
    {
      title: 'Execution test',
      onClick: executionTestOnClick,
    },
  ];

  return (
    <>
      <PagingHeadingButtonOnClick
        breadcrumbs={breadcrumbs}
        pageTitle={
          testType === 'scenario'
            ? 'Test Scenario Execution'
            : testType === 'case'
              ? 'Test Case Execution'
              : 'Test Step Execution'
        }
        pageHeadingButtons={pageHeadingButtons}
        count={selectedDevices?.length}
      />
      <SpeedExecutionInfo idx={idx} testType={testType} />
      <RepeatInfo
        isInvalidRepeatCount={isInvalidRepeatCount}
        errorMessage={errorMessage}
      />
      <SpeedSelectedDevices
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        checkHandler={checkHandler}
        isSelectedDevices={isSelectedDevices}
        setIsSelectedDevices={setIsSelectedDevices}
        divWidth={divWidth}
        testType={testType}
        mobileOS={mobileOS}
        idx={idx}
        needIosSetting={needIosSetting}
        setNeedIosSetting={setNeedIosSetting}
        setIsSettingsOpen={setIsSettingsOpen}
        setSelectedBrowser={setSelectedBrowser}
        setIsWirelessDeviceModal={setIsWirelessDeviceModal}
        setConnectionType={setConnectionType}
      />
    </>
  );
};

export default SpeedMobileTestExecution;
