import {
  Checkbox,
  TableBodyBasic,
  TableRowBackgroundGray,
} from '@autosquare/common';
import {
  ConnectionType,
  DeviceInfoOs,
  DeviceInfoOsFullName,
} from '@customTypes/ide/device/device';
import {
  SpeedSchedulerDetailData,
  SpeedSchedulerTypeName,
  SpeedSchedulerTypeServerName,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import {
  DocumentMagnifyingGlassIcon,
  WifiIcon,
} from '@heroicons/react/24/outline';
import { UseMutationResult } from '@tanstack/react-query';
import { parseCronCycle } from '@utils/static/parseCronCycle';
import { formatDays } from '@utils/static/selectedDays';
import React from 'react';
import { LiaUsb } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';

type Props = {
  idx: number;
  speedScheduler: SpeedSchedulerDetailData;
  selectedArray: number[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  updateSpeedSchedulerStatusMutation: UseMutationResult<
    SpeedSchedulerDetailData,
    Error,
    { idx: number; isRunning: boolean }
  >;
  setIsErrorModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const SpeedTestSchedulerTableBody = ({
  idx,
  speedScheduler,
  selectedArray,
  selectArray,
  updateSpeedSchedulerStatusMutation,
  setIsErrorModalOpened,
}: Props) => {
  const navigate = useNavigate();
  return (
    <TableRowBackgroundGray
      isInvalid={!speedScheduler.running}
      key={speedScheduler.idx}
    >
      <TableBodyBasic>
        <Checkbox
          checked={selectedArray.includes(speedScheduler.idx)}
          onChange={(e) => selectArray(speedScheduler.idx, e)}
        />
      </TableBodyBasic>
      <TableBodyBasic>{idx + 1}</TableBodyBasic>
      <TableBodyBasic>
        {speedScheduler.type === SpeedSchedulerTypeServerName.Mobile
          ? SpeedSchedulerTypeName.Mobile
          : SpeedSchedulerTypeName.Web}
      </TableBodyBasic>
      <TableBodyBasic>
        {speedScheduler.type === SpeedSchedulerTypeServerName.Mobile ? (
          <div className="flex items-start gap-2">
            {speedScheduler.environment.split('/')[3] === ConnectionType.USB ? (
              <LiaUsb size={24} />
            ) : (
              <WifiIcon className="size-5" />
            )}
            <div>
              <p>{speedScheduler.environment.split('/')[1]}</p>
              <p className="text-xs text-gray-500">
                {speedScheduler.environment.split('/')[2] === DeviceInfoOs.Aos
                  ? DeviceInfoOsFullName.Aos
                  : DeviceInfoOsFullName.Ios}
              </p>
            </div>
          </div>
        ) : (
          speedScheduler.environment.split('/')[0][0].toUpperCase() +
          speedScheduler.environment.split('/')[0].slice(1)
        )}
      </TableBodyBasic>
      <TableBodyBasic>{speedScheduler.title}</TableBodyBasic>
      <TableBodyBasic>
        <p>{speedScheduler.scenario_idx_list.length}</p>
      </TableBodyBasic>
      <TableBodyBasic>
        <div className="flex flex-col">
          <span>After: {speedScheduler.start_time.slice(0, -4)}</span>
          <span>
            {`On: ${formatDays(parseCronCycle(speedScheduler.cycle).onPart)}`}
          </span>
          <span>
            {`Run: ${parseCronCycle(speedScheduler.cycle).timeInterval}`}
          </span>
        </div>
      </TableBodyBasic>
      <TableBodyBasic>
        <button
          type="button"
          className="rounded-md hover:text-congress-blue"
          onClick={() =>
            updateSpeedSchedulerStatusMutation.mutate(
              {
                idx: speedScheduler.idx,
                isRunning: !speedScheduler.running,
              },
              { onError: () => setIsErrorModalOpened(true) },
            )
          }
          disabled={updateSpeedSchedulerStatusMutation.isLoading}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="size-5"
          >
            {speedScheduler.running ? (
              <>
                <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                <path
                  fillRule="evenodd"
                  d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clipRule="evenodd"
                />
              </>
            ) : (
              <>
                <path
                  fillRule="evenodd"
                  d="M3.28 2.22a.75.75 0 00-1.06 1.06l14.5 14.5a.75.75 0 101.06-1.06l-1.745-1.745a10.029 10.029 0 003.3-4.38 1.651 1.651 0 000-1.185A10.004 10.004 0 009.999 3a9.956 9.956 0 00-4.744 1.194L3.28 2.22zM7.752 6.69l1.092 1.092a2.5 2.5 0 013.374 3.373l1.091 1.092a4 4 0 00-5.557-5.557z"
                  clipRule="evenodd"
                />
                <path d="M10.748 13.93l2.523 2.523a9.987 9.987 0 01-3.27.547c-4.258 0-7.894-2.66-9.337-6.41a1.651 1.651 0 010-1.186A10.007 10.007 0 012.839 6.02L6.07 9.252a4 4 0 004.678 4.678z" />
              </>
            )}
          </svg>
        </button>
      </TableBodyBasic>
      <TableBodyBasic>
        <button
          type="button"
          className="size-6 rounded-md hover:text-congress-blue"
          onClick={() => navigate(`detail?idx=${speedScheduler.idx}`)}
        >
          <DocumentMagnifyingGlassIcon className="size-full" />
        </button>
      </TableBodyBasic>
    </TableRowBackgroundGray>
  );
};

export default SpeedTestSchedulerTableBody;
