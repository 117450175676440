import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

export interface SessionId {
  sessionIdValue: string | null;
}

const initialState: SessionId = {
  sessionIdValue: null,
};

export const sessionIdSlice = createSlice({
  name: "sessionId",
  initialState,
  reducers: {
    getSessionId: (state, action: PayloadAction<string | null>) => {
      state.sessionIdValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getSessionId } = sessionIdSlice.actions;

export default sessionIdSlice.reducer;
