import React from 'react';

interface Props {
  tableTitles: (
    | {
        idx: number;
        element: React.JSX.Element;
      }
    | {
        idx: number;
        element: string;
      }
  )[];
}

const TestStepListTableHeader = ({ tableTitles }: Props) => {
  return (
    <thead>
      <tr className="border-t border-gray-300">
        {tableTitles.map((tableTitle) => (
          <th
            scope="col"
            className={`table-header-basic first:pl-4`}
            key={tableTitle.idx}
          >
            {tableTitle.element}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TestStepListTableHeader;
