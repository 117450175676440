import TestStepHeader from '@components/shared/MobileWebTestStep/CreateAndUpdate/TestStepHeader';

import { useCreateMobileWebSpeedTestStepMutation } from '@lib/api/ide/speedMobile/mobileWebTestStep';

import { hasDuplicateTitle } from '@utils/static/speedTest/mobileWebTestStep/hasDuplicateTitle';

import {
  CreateMobileWebSpeedTestStepData,
  CreateMobileWebSpeedTestStepDataSchema,
} from '@customTypes/ide/speedTest/Mobile/webTestStep/type';

import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import EditableDiv from './CreateMobileWebSpeedTestStep/EditableDiv';
import CreateVariableList from './CreateMobileWebSpeedTestStep/CreateVariableList';

interface Props {
  endCreatingTestStep: () => void;
}

const CreateMobileWebSpeedTestStep = ({ endCreatingTestStep }: Props) => {
  const [searchParams] = useSearchParams();
  const [errorIndex, setErrorIndex] = useState(0);

  const createMobileWebSpeedTestStepMutation =
    useCreateMobileWebSpeedTestStepMutation();

  const idx = searchParams.get('idx');

  const methods = useForm<CreateMobileWebSpeedTestStepData>({
    defaultValues: {
      url: '',
      speedTestCaseIdx: Number(idx),
      description: '',
      optionsDto: [],
    },
    resolver: zodResolver(CreateMobileWebSpeedTestStepDataSchema),
  });

  const onSubmit: SubmitHandler<CreateMobileWebSpeedTestStepData> = (data) => {
    if (hasDuplicateTitle(data.optionsDto, setErrorIndex)) {
      methods.setError(`optionsDto.${errorIndex}.keyName`, {
        type: 'duplication',
        message: '이미 존재하는 key Name입니다. 다른 Key Name을 입력해 주세요.',
      });
      methods.setFocus(`optionsDto.${errorIndex}.keyName`);
      return;
    }

    const dataWithoutIdx: CreateMobileWebSpeedTestStepData = {
      ...data,
      optionsDto: data.optionsDto?.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ idx, uuid, start, end, ...rest }) => rest,
      ),
    };

    createMobileWebSpeedTestStepMutation.mutate(dataWithoutIdx, {
      onSuccess: () => {
        endCreatingTestStep();
      },
    });
  };

  const clickCancelButton = () => {
    createMobileWebSpeedTestStepMutation.reset();
    endCreatingTestStep();
  };

  const optionsDtoArray = methods.watch('optionsDto');

  return (
    <FormProvider {...methods}>
      <form className="py-6" onSubmit={methods.handleSubmit(onSubmit)}>
        <TestStepHeader
          cancelOnClick={clickCancelButton}
          saveDisabled={createMobileWebSpeedTestStepMutation.isLoading}
        />
        {createMobileWebSpeedTestStepMutation.isError && (
          <p className="error-message pb-1 pt-2.5">
            {createMobileWebSpeedTestStepMutation.error.message}
          </p>
        )}
        <div className="py-2.5 text-sm font-normal leading-9 text-black">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-8 gap-4">
              <p className="col-span-1">URL*</p>
              <Controller
                name={'url'}
                control={methods.control}
                render={({ field }) => (
                  <EditableDiv value={field.value} onChange={field.onChange} />
                )}
              />
            </div>
            <ErrorMessage
              errors={methods.formState.errors}
              name={'url'}
              render={({ message }) => (
                <p className="error-message">{message}</p>
              )}
            />
            <label htmlFor={'description'} className="grid grid-cols-8 gap-4">
              <p className="col-span-1">Description*</p>
              <input
                id={'description'}
                name={'description'}
                className="input-base col-span-7"
                {...methods.register('description')}
              />
            </label>
            <ErrorMessage
              errors={methods.formState.errors}
              name={'description'}
              render={({ message }) => (
                <p className="error-message">{message}</p>
              )}
            />
          </div>
        </div>
        {optionsDtoArray.length > 0 && <CreateVariableList />}
      </form>
    </FormProvider>
  );
};

export default CreateMobileWebSpeedTestStep;
