import TestScenarioInformation from '@components/SpeedTestScenario/TestScenarioInformation';
import { UpdateWebSpeedTestScenarioData } from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const WebSpeedTestScenarioDetailInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateWebSpeedTestScenarioData>();

  return <TestScenarioInformation register={register} errors={errors} />;
};

export default WebSpeedTestScenarioDetailInformation;
