import {
  QuestionsMarkCircle,
  TooltipTop,
  useDeviceSize,
} from '@autosquare/common';

import { isMacOs } from '@utils/static/isMacOs';

import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const ExtractTextFormatTitle = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const { width: deviceWidth } = useDeviceSize();

  const { control, watch, setValue, resetField } =
    useFormContext<CreateTestStepData>();

  const saveTextFrom = watch('optionsDto.from');

  useEffect(() => {
    setValue('optionsDto.from', SaveTextFrom.Element);

    return () => {
      resetField('optionsDto.from');
    };
  }, [setValue, resetField]);

  return (
    <div
      className={`relative flex h-5 w-full items-center justify-between text-sm font-medium leading-normal text-gray-900`}
    >
      <div className="flex items-center justify-between gap-2">
        <p>Extract Text Format</p>
        <div className="flex items-center">
          <button
            type="button"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
          >
            <QuestionsMarkCircle />
          </button>
        </div>
        {tooltipVisible && deviceWidth > 1300 ? (
          <TooltipTop
            positionClass={`left-0 bottom-7 ${isMacOs ? 'after:left-[43%]' : 'after:left-[38%]'}`}
          >
            사용자가 지정한 형식에 맞는 텍스트를 추출하는 기능입니다.
          </TooltipTop>
        ) : (
          tooltipVisible &&
          deviceWidth <= 1300 && (
            <TooltipTop
              positionClass={`left-14 bottom-7 ${isMacOs ? 'after:left-[50%]' : 'after:left-[44.25%]'}`}
            >
              <p>사용자가 지정한 형식에 맞는</p>
              <p>텍스트를 추출하는 기능입니다.</p>
            </TooltipTop>
          )
        )}
      </div>
      <div className={`${saveTextFrom === SaveTextFrom.Sms ? 'hidden' : ''}`}>
        <Controller
          control={control}
          name={'optionsDto.textExtract'}
          render={({ field }) => (
            <SwitchInController
              checked={field.value === 'true'}
              onChange={(checked: boolean) =>
                field.onChange(checked ? 'true' : 'false')
              }
              size="sm"
            />
          )}
        />
      </div>
    </div>
  );
};

export default ExtractTextFormatTitle;
