import { RootState } from '@app/store';
import { useInput } from '@autosquare/common';

import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

interface WebTestStepProviderContext {
  startX: number;
  onChangeStartX: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setStartX: React.Dispatch<React.SetStateAction<number>>;
  startY: number;
  onChangeStartY: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setStartY: React.Dispatch<React.SetStateAction<number>>;
  endX: number;
  onChangeEndX: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setEndX: React.Dispatch<React.SetStateAction<number>>;
  endY: number;
  onChangeEndY: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setEndY: React.Dispatch<React.SetStateAction<number>>;
  imageWidth: number;
  setImageWidth: React.Dispatch<React.SetStateAction<number>>;
  imageHeight: number;
  setImageHeight: React.Dispatch<React.SetStateAction<number>>;
  isListboxOpened: boolean;
  setIsListboxOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isPassListboxOpened: boolean;
  setIsPassListboxOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isFailListboxOpened: boolean;
  setIsFailListboxOpened: React.Dispatch<React.SetStateAction<boolean>>;
  currentPageUrl: string[];
  fullXPath: string[];
}

export const WebTestStepContext = createContext<WebTestStepProviderContext>({
  startX: null,
  onChangeStartX: () => {},
  setStartX: () => {},
  startY: null,
  onChangeStartY: () => {},
  setStartY: () => {},
  endX: null,
  onChangeEndX: () => {},
  setEndX: () => {},
  endY: null,
  onChangeEndY: () => {},
  setEndY: () => {},
  imageWidth: 0,
  setImageWidth: () => {},
  imageHeight: 0,
  setImageHeight: () => {},
  isListboxOpened: true,
  setIsListboxOpened: () => {},
  isPassListboxOpened: true,
  setIsPassListboxOpened: () => {},
  isFailListboxOpened: true,
  setIsFailListboxOpened: () => {},
  currentPageUrl: [],
  fullXPath: [],
});

export const useWebTestStepContext = () => {
  const context = useContext(WebTestStepContext);
  if (context === undefined) {
    throw new Error(
      'useWebTestStepContext must be used within a WebTestProvider',
    );
  }
  return context;
};

interface Props {
  children: ReactNode;
}

const WebTestStepProvider = ({ children }: Props) => {
  const [startX, onChangeStartX, setStartX] = useInput<number>(null);
  const [startY, onChangeStartY, setStartY] = useInput<number>(null);
  const [endX, onChangeEndX, setEndX] = useInput<number>(null);
  const [endY, onChangeEndY, setEndY] = useInput<number>(null);

  // image width heigth 저장
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);

  const [conditionValue, setConditionValue] = useState<string>(undefined);

  const [isListboxOpened, setIsListboxOpened] = useState(true);
  const [isPassListboxOpened, setIsPassListboxOpened] = useState(true);
  const [isFailListboxOpened, setIsFailListboxOpened] = useState(true);

  const [currentPageUrl, setCurrentPageUrl] = useState<string[]>([]);
  const [fullXPath, setFullXPath] = useState<string[]>([]);

  const browserUrl = useSelector(
    (state: RootState) => state.webTestStepInfo.currentPageUrl,
  );
  const reduxFullXPath = useSelector(
    (state: RootState) => state.webTestStepInfo.browserXpath,
  );

  useEffect(() => {
    setCurrentPageUrl([browserUrl]);
    setFullXPath([`${reduxFullXPath}`]);
  }, [browserUrl, reduxFullXPath]);

  useEffect(() => {
    window.electron.on<string>('sendCurrentPageURL', (message) => {
      setCurrentPageUrl([message]);
    });

    window.electron.on<string>('websiteXPath', (message: string) => {
      setFullXPath([`xpath=${message}`]);
    });

    return () => {
      window.electron.removeAllListeners('sendCurrentPageURL');
      window.electron.removeAllListeners('websiteXPath');
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      startX,
      onChangeStartX,
      setStartX,
      startY,
      onChangeStartY,
      setStartY,
      endX,
      onChangeEndX,
      setEndX,
      endY,
      onChangeEndY,
      setEndY,
      imageWidth,
      setImageWidth,
      imageHeight,
      setImageHeight,
      isListboxOpened,
      setIsListboxOpened,
      isPassListboxOpened,
      setIsPassListboxOpened,
      isFailListboxOpened,
      setIsFailListboxOpened,
      conditionValue,
      setConditionValue,
      currentPageUrl,
      fullXPath,
    }),
    [
      startX,
      startY,
      endX,
      endY,
      imageWidth,
      imageHeight,
      isListboxOpened,
      isPassListboxOpened,
      isFailListboxOpened,
      conditionValue,
      currentPageUrl,
      fullXPath,
    ],
  );

  return (
    <WebTestStepContext.Provider value={contextValue}>
      {children}
    </WebTestStepContext.Provider>
  );
};

export default WebTestStepProvider;
