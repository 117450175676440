import { RootState } from '@app/store';
import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';
import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useDeleteTestStepMutation } from '@lib/api/ide/mobile/mobileTestStep';

import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

interface Props {
  isTestStepExecutionOpened: boolean;
  setIsTestStepExecutionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  title: DeviceInfoOs;
  isOpenedCautionDialog: boolean;
  setIsOpenedCautionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedArray: number[];
  openDialog: () => void;
  closeDialog: () => void;
  resetSelectedArray: () => void;
}

const ExecutionAndDialog = ({
  isTestStepExecutionOpened,
  setIsTestStepExecutionOpened,
  title,
  isOpenedCautionDialog,
  setIsOpenedCautionDialog,
  selectedArray,
  openDialog,
  closeDialog,
  resetSelectedArray,
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const testStepIdx = useSelector(
    (state: RootState) => state.mobileTestStepDetail.testStepIdx,
  );

  const deleteTestStepMutation = useDeleteTestStepMutation(idx, selectedArray);

  return (
    <>
      {isTestStepExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestStepExecutionOpened}
          idx={testStepIdx}
          testType={'step'}
          deviceType={'mobile'}
          mobileOS={title}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={deleteTestStepMutation.isError ? 'Error' : 'Delete Test Step'}
        subTitle={
          deleteTestStepMutation.isError
            ? deleteTestStepMutation.error.message
            : '해당 테스트 스텝을 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteTestStepMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteTestStepMutation.mutate(undefined, {
                    onSuccess: () => {
                      closeDialog();
                      resetSelectedArray();
                    },
                    onError: () => {
                      openDialog();
                    },
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

export default ExecutionAndDialog;
