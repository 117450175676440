import {
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingButtonOnClick,
  useInput,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';
import { useCreateMobileTestScenarioMutation } from '@lib/api/ide/mobile/mobileTestScenario';
import { useCreateWebTestScenarioMutation } from '@lib/api/ide/web/webTestScenario';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import InputSpace from './components/InputSpace';
import DndSpace from './components/DndSpace';

import { CreateTestScenarioData } from '@customTypes/testScenario/type';

const CreateTestScenario = () => {
  const location = useLocation();
  const { projectIdx } = useParams();

  const [title, onChangeTitle] = useInput('');
  const [description, onChangeDescription] = useInput('');
  const [isWrited, setIsWrited] = useState(false);

  const testCaseIdxs = useSelector(
    (state: RootState) => state.numberArray.testCaseIndexArray,
  );

  const data: CreateTestScenarioData = {
    title: title,
    description: description,
    testCaseIdxs: testCaseIdxs,
  };

  const mutationData = {
    projectIdx: projectIdx,
  };

  const createTestScenarioMutation = isWordInLocationPathname('mobile')
    ? useCreateMobileTestScenarioMutation(mutationData)
    : useCreateWebTestScenarioMutation(mutationData);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsWrited(true);
    if (title?.length !== 0) {
      createTestScenarioMutation.mutate(data);
    }
  };

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: location.pathname.split('/').slice(0, -3).join('/') + '/overview',
    },
    {
      title: isWordInLocationPathname('mobile') ? 'Mobile' : 'Web',
      href: location.pathname.split('/').slice(0, -1).join('/'),
    },
    {
      title: 'Test Scenario',
      href: location.pathname.split('/').slice(0, -1).join('/'),
    },
    {
      title: 'Create',
      href: location.pathname,
    },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    { title: 'Save', type: 'submit' },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <PagingHeadingButtonOnClick
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Scenario Create'}
        pageHeadingButtons={pageHeadingButtons}
      />
      {createTestScenarioMutation.isError && (
        <ErrorMessage>{createTestScenarioMutation.error.message}</ErrorMessage>
      )}
      <InputSpace
        title={title}
        onChangeTitle={onChangeTitle}
        onChangeDescription={onChangeDescription}
        isWrited={isWrited}
      />
      <DndSpace />
    </form>
  );
};

export default CreateTestScenario;
