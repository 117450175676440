import React from 'react';
import { InputBasic, SubHeaderTitle } from '@autosquare/common';

interface Props {
  title: string;
  onChangeTitle: React.ChangeEventHandler<HTMLInputElement>;
  onChangeDescription: React.ChangeEventHandler<HTMLInputElement>;
  isWrited: boolean;
}

const InputSpace = ({
  title,
  onChangeTitle,
  onChangeDescription,
  isWrited,
}: Props) => {
  return (
    <div className="mt-8 pb-4">
      <SubHeaderTitle
        subTitle={'Test Scenario Information'}
        description={'테스트 시나리오 정보를 입력해주세요.'}
      />
      <div className="mt-4 flex flex-col">
        <div className="flex flex-row gap-4">
          <InputBasic
            state={title}
            labelName={'title'}
            inputName={'Title*'}
            onChange={onChangeTitle}
            maxLength={50}
            isWrited={isWrited}
          />
          <InputBasic
            labelName={'description'}
            inputName={'Description'}
            onChange={onChangeDescription}
            minLength={0}
            maxLength={200}
          />
        </div>
      </div>
    </div>
  );
};

export default InputSpace;
