import React from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  DetailArrow,
  SpeedTestResultTableHeader,
  Time,
} from '@autosquare/common';
import { MobileSpeedTestResultData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { timePattern } from '@utils/static/timePattern';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { getClickLocation } from '@store/locationHistorySlice';

type Props = {
  resultData: MobileSpeedTestResultData;
};

const IdeMobileSpeedTestResultTable = ({ resultData }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get('page');
  const { pathname, search } = useLocation();

  const resultWithUUID = resultData?.content?.map((item) => ({
    ...item,
    index: crypto.randomUUID(),
  }));

  return (
    <>
      {resultData?.content?.length === 0 ? (
        <div className="my-10 text-center text-sm text-gray-900">
          진행된 테스트 결과가 없습니다.
        </div>
      ) : (
        <div className="mt-6">
          {resultWithUUID?.map((item) => (
            <div key={item.index}>
              <div className="flex items-center justify-between leading-tight">
                <div className="my-3 text-sm font-semibold">{item.group}</div>
                <div
                  className={clsx(
                    'font-semibold',
                    item.resultEnum === 'RUNNING'
                      ? 'text-gray-400'
                      : 'text-gray-900',
                  )}
                >
                  <button
                    type="button"
                    className="flex items-center justify-center text-xs"
                    disabled={item.resultEnum === 'RUNNING'}
                    onClick={() => {
                      dispatch(getClickLocation(pathname + search));
                      navigate(
                        `detail?result=${item.resultIdx}&group=${item.groupIdx}&start=${dayjs(item.dateTime).subtract(14, 'days').format('YYYYMMDD')}&end=${dayjs(item.dateTime).format('YYYYMMDD')}`,
                        {
                          state: { status: item.resultEnum, page: currentPage },
                        },
                      );
                    }}
                  >
                    Detail
                    <DetailArrow />
                  </button>
                </div>
              </div>
              <div className="gray-xy-scrollbar">
                <table className="min-w-full bg-white">
                  <thead className="bg-gray-50">
                    <tr className="border-b border-gray-200">
                      <SpeedTestResultTableHeader width="w-40">
                        Type
                      </SpeedTestResultTableHeader>
                      <SpeedTestResultTableHeader width="w-40">
                        DateTime
                      </SpeedTestResultTableHeader>
                      {item.loadSpeedData.map((data, index) => (
                        <SpeedTestResultTableHeader key={index}>
                          {data.name}
                        </SpeedTestResultTableHeader>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b border-gray-400">
                      <td className="px-4 py-2 text-sm text-gray-900">
                        {item.testType}
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-900">
                        <div>{item?.dateTime?.split(' ')[0] ?? '-'}</div>
                        <div
                          className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                        >
                          {item?.dateTime && <Time />}
                          {RegExp(timePattern).exec(item.dateTime)[0]}
                        </div>
                      </td>
                      {item.loadSpeedData.map((data, index) => (
                        <td
                          key={index}
                          className={clsx(
                            'px-4 py-2 text-sm',
                            data.value > 3 ? 'text-red-500' : 'text-gray-900',
                          )}
                        >
                          {item.resultEnum === 'PASS'
                            ? data.value
                            : item.resultEnum === 'NA'
                              ? 'N/A'
                              : item.resultEnum === 'FAIL'
                                ? 'Fail'
                                : 'Running'}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default IdeMobileSpeedTestResultTable;
