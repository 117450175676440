import VariableList from '@components/shared/MobileWebTestStep/CreateAndUpdate/VariableList';
import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import UpdateWebSpeedVariableTableBody from './UpdateWebSpeedVariableList/UpdateWebSpeedVariableTableBody';
import { CreateWebSpeedTestStepData } from '@customTypes/ide/speedTest/Web/testStep/type';
import { useSpeedWebVariableList } from '@hooks/speedWebTestStep/useSpeedWebVariableList';

const UpdateWebSpeedVariableList = () => {
  const { watch, control, setValue, getValues } =
    useFormContext<CreateWebSpeedTestStepData>();

  const { remove } = useFieldArray({ control, name: 'optionsDto' });

  const optionsDtoArray = watch('optionsDto');

  const {
    tableHeaders,
    removeOptionsDto,
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
  } = useSpeedWebVariableList({ optionsDtoArray, getValues, setValue, remove });

  return (
    <VariableList
      removeOptionsDto={removeOptionsDto}
      isOpenedCautionDialog={isOpenedCautionDialog}
      setIsOpenedCautionDialog={setIsOpenedCautionDialog}
      openDialog={openDialog}
      closeDialog={closeDialog}
      selectedArray={selectedArray}
      selectAllArray={selectAllArray}
    >
      {optionsDtoArray?.map((optionsDto, idx) => (
        <UpdateWebSpeedVariableTableBody
          key={optionsDto.idx}
          idx={idx}
          optionsDtoIdx={optionsDto.idx}
          tableColsLength={tableHeaders.length}
          selectArray={selectArray}
          selectedArray={selectedArray}
        />
      ))}
    </VariableList>
  );
};

export default UpdateWebSpeedVariableList;
