import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface RefreshToken {
  refreshToken: string | null;
}

const initialState: RefreshToken = {
  refreshToken: null,
};

export const refreshTokenSlice = createSlice({
  name: "cookie",
  initialState,
  reducers: {
    getRefreshToken: (state, action: PayloadAction<string | null>) => {
      state.refreshToken = action.payload;
    },
  },
});

export const { getRefreshToken } = refreshTokenSlice.actions;

export default refreshTokenSlice.reducer;
