import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';
import { useWebBrowserListQuery } from '@lib/api/ide/web/webBrowser';

import {
  SchedulerTypeName,
  SchedulerTypeServerName,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { DeviceInfo } from '@customTypes/ide/device/device';
import { BrowserInfo } from '@customTypes/ide/browser/browser';
import { ErrorMessage } from '@hookform/error-message';
import DeviceListEditBox from '@components/Scheduler/DeviceListEditBox';
import BrowserListEditBox from '@components/Scheduler/BrowserListEditBox';

interface Props {
  type: string;
  isRunning: boolean;
}

const SchedulerDetailDevice = ({ type, isRunning }: Props) => {
  const deviceListQuery = useDeviceListQuery({
    enabled: type === SchedulerTypeName.Mobile,
  });

  const browserListQuery = useWebBrowserListQuery({
    enabled: type === SchedulerTypeName.Web,
  });

  const {
    control,
    formState: { errors },
  } = useFormContext<UpdateSchedulerData>();
  const filteredDevice =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deviceListQuery.data?.map(({ enabled, ...rest }) => rest) ?? [];

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
      <label
        htmlFor="device"
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
      >
        {type === SchedulerTypeServerName.Mobile ? 'Device' : 'Browser'}
      </label>
      <div className="mt-2 flex items-center justify-start gap-x-4 sm:col-span-2 sm:mt-0">
        {type === SchedulerTypeServerName.Mobile ? (
          <Controller
            name="infos"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DeviceListEditBox
                selectedDevice={field.value as DeviceInfo}
                setSelectedDevice={field.onChange}
                deviceListQuery={deviceListQuery}
                deviceLists={filteredDevice}
                isRunning={isRunning}
              />
            )}
          />
        ) : (
          <Controller
            name="infos"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <BrowserListEditBox
                selectedBrowser={field.value as BrowserInfo}
                setSelectedBrowser={field.onChange}
                browserListQuery={browserListQuery}
                browserList={browserListQuery.data}
                isRunning={isRunning}
              />
            )}
          />
        )}
      </div>
      <ErrorMessage
        name="infos"
        errors={errors}
        render={({ message }) => (
          <p className="standard-error-message">{message}</p>
        )}
      />
    </div>
  );
};

export default SchedulerDetailDevice;
