import React from "react";

import { useInput } from "@autosquare/common";

const Details = () => {
  const [name, onChangeName] = useInput(null);

  return (
    <div>
      <h2 className="mb-[0.5rem] text-base font-medium">
        Change organization name
      </h2>
      <form>
        <label className="text-sm">Organization name *</label>
        <br />
        <input
          className="my-[1.25rem] bg-input-bg border border-solid border-border-line"
          onChange={onChangeName}
        />
        <br />
        <button
          type="submit"
          className="px-[0.5rem] py-[0.25rem] bg-congress-blue text-base font-bold text-white"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default Details;
