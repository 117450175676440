import KeyNameAndFromAndValue from '@components/shared/SpeedWebTestStep/KeyNameAndFromAndValue';
import { UpdateWebSpeedTestStepData } from '@customTypes/ide/speedTest/Web/testStep/type';

import React from 'react';
import { useFormContext } from 'react-hook-form';

const UpdateKeyNameAndFromAndValue = () => {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateWebSpeedTestStepData>();

  return (
    <KeyNameAndFromAndValue
      watch={watch}
      setValue={setValue}
      register={register}
      errors={errors}
      control={control}
    />
  );
};

export default UpdateKeyNameAndFromAndValue;
