import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import { SidebarList } from "@customTypes/type";

type Props = {
  sidebarList: SidebarList;
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectSidebarSubList = ({ sidebarList, setIsToggledSidebar }: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (location.pathname.includes(sidebarList.location)) {
      setIsOpened(true);
    }
  }, [location.pathname]);

  return (
    <div>
      <button
        className={`flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 ${
          location.pathname.includes(sidebarList.hrefBasic)
            ? "bg-gray-50"
            : "hover:bg-gray-50"
        }`}
        onClick={() => setIsOpened(!isOpened)}
      >
        <ChevronRightIcon
          className={`h-5 w-5 shrink-0 ${
            isOpened ? "rotate-90 transform text-gray-500" : "text-gray-400"
          }`}
          aria-hidden="true"
        />
        {sidebarList.name}
      </button>
      {isOpened && (
        <ul className="mt-1 px-2">
          {sidebarList.children.map((item) => (
            <li key={item.name}>
              <Link to={item.href} onClick={() => setIsToggledSidebar(false)}>
                <div
                  className={`block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700 cursor-pointer ${
                    location.pathname.includes(item.hrefBasic)
                      ? "bg-gray-50"
                      : "hover:bg-gray-50"
                  }`}
                >
                  {item.name}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProjectSidebarSubList;
