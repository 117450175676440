import React, { useContext } from 'react';
import { Circle } from 'react-konva';
import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import { canvasHandleCursor } from '@hooks/konva/canvasHandleCursor';

type Props = {
  realScreenRatio: number;
};

const ClickCicle = ({ realScreenRatio }: Props) => {
  const { startX, setStartX, startY, setStartY } = useContext(
    MobileTestStepContext,
  );

  return (
    <>
      <Circle
        x={Math.round(startX * realScreenRatio)}
        y={Math.round(startY * realScreenRatio)}
        radius={50}
        fill="#FFA500"
        width={30}
        onDragMove={(e) => {
          setStartX(Math.round(e.target.position().x / realScreenRatio));
          setStartY(Math.round(e.target.position().y / realScreenRatio));
        }}
        draggable
        opacity={0.8}
        onMouseEnter={canvasHandleCursor}
        onMouseLeave={canvasHandleCursor}
      />
    </>
  );
};

export default ClickCicle;
