export const parseCronCycle = (cronExpression: string) => {
  const parts = cronExpression.split(' ');

  const minutes = parts[1];
  const hours = parts[2];
  const daysOfWeek = parts[5] === 'MON-FRI' ? 'MON,TUE,WED,THU,FRI' : parts[5];
  const baseParts = parts.slice(0, parts.length - 3);
  let timeInterval: string;

  if (!minutes.includes('/') && !hours.includes('/')) {
    timeInterval = 'Once a day';
  } else if (!minutes.includes('/')) {
    timeInterval = `Every ${hours.split('/')[1]} hour(s)`;
  } else {
    timeInterval = `Every ${minutes.split('/')[1]} minute(s)`;
  }

  let onPart: string;
  if (daysOfWeek === '?') {
    onPart = 'Every day';
  } else {
    onPart = daysOfWeek;
  }
  return { timeInterval, onPart, daysOfWeek, baseParts };
};
