import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';
import {
  useMobileOverviewQuery,
  useWebOverviewQuery,
} from '@lib/api/dashboard/overview/overview';

import { ErrorMessage, MainHeader, TableSkeleton } from '@autosquare/common';

import MobileRecentResult from './components/MobileRecentResult';
import WebRecentTestResult from './components/WebRecentTestResult';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import WebSpeedTestOverviewList from '@components/Overview/WebSpeedTestOverviewList';
import { useGetMobileSpeedTestResultList } from '@lib/api/dashboard/mobile/mobileSpeedResult';
import { useWebSpeedTestResultListQuery } from '@lib/api/dashboard/web/webSpeedResult';
import MobileSpeedTestOverviewList from '@components/Overview/MobileSpeedTestOverviewList';

const Overview = () => {
  const projectList = useSelector(
    (state: RootState) => state.projectList.projectList,
  );

  const { projectIdx } = useParams();

  const mobileOverviewQuery = useMobileOverviewQuery(projectIdx);
  const webOverviewQuery = useWebOverviewQuery(projectIdx);
  const mobileFunctionalResults = mobileOverviewQuery.data?.functionalResults;
  const webFunctionalResults = webOverviewQuery.data?.functionalResults;
  const PAGE = 1;
  const SIZE = 5;
  const mobileSpeedResultQuery = useGetMobileSpeedTestResultList(PAGE, SIZE);
  const mobileSpeedResult = mobileSpeedResultQuery?.data;

  const webSpeedResultQuery = useWebSpeedTestResultListQuery(PAGE);
  const webSpeedResult = webSpeedResultQuery?.data;
  return (
    <>
      <MainHeader firstHeader={'Overview'} headerTitle={'Overview'} />
      {mobileOverviewQuery.isLoading ||
      mobileOverviewQuery.isFetching ||
      webOverviewQuery.isLoading ||
      webOverviewQuery.isFetching ? (
        <TableSkeleton />
      ) : mobileOverviewQuery.isError ? (
        <ErrorMessage>{mobileOverviewQuery.error.message}</ErrorMessage>
      ) : webOverviewQuery.isError ? (
        <ErrorMessage>{webOverviewQuery.error.message}</ErrorMessage>
      ) : (
        <>
          {checkIsProjectFeatureIncluded(
            projectList,
            ProjectListDetailFeaturesCategory.Mobile,
            ProjectListDetailFeaturesFeature.Functional,
          ) && (
            <MobileRecentResult
              mobileFunctionalResults={mobileFunctionalResults}
              tableHeaders={tableHeaders}
            />
          )}
          {checkIsProjectFeatureIncluded(
            projectList,
            ProjectListDetailFeaturesCategory.Web,
            ProjectListDetailFeaturesFeature.Functional,
          ) && (
            <WebRecentTestResult
              webFunctionalResults={webFunctionalResults}
              tableHeaders={tableHeaders}
            />
          )}
          {checkIsProjectFeatureIncluded(
            projectList,
            ProjectListDetailFeaturesCategory.Mobile,
            ProjectListDetailFeaturesFeature.Speed,
          ) && <MobileSpeedTestOverviewList resultData={mobileSpeedResult} />}
          {checkIsProjectFeatureIncluded(
            projectList,
            ProjectListDetailFeaturesCategory.Web,
            ProjectListDetailFeaturesFeature.Speed,
          ) && <WebSpeedTestOverviewList resultData={webSpeedResult} />}
        </>
      )}
    </>
  );
};

export default Overview;

export const tableHeaders = ['No', 'Title', 'DateTime', 'Result', 'Detail'];
