import { ListBoxHeadlessUI } from '@autosquare/common';

import KeyboardEventListbox from '@components/shared/IDE/WebTestStep/KeyboardEventListbox';

import { useWebTestStepContext } from '@contexts/ide/WebTestStepProvider';

import { CreateWebTestStepData } from '@customTypes/testStep/type';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const KeyboardEventList = () => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const { fullXPath } = useWebTestStepContext();

  const filteredValueArray = fullXPath.filter((value) => value !== 'Value');

  useEffect(() => {
    return () => {
      resetField('value');
      resetField('optionsDto.targetElement');
    };
  }, []);

  return (
    <>
      <KeyboardEventListbox value="value" />
      <Controller
        control={control}
        name={'optionsDto.targetElement'}
        rules={{ required: 'Target Element를 선택해 주세요.' }}
        render={({ field }) => (
          <ListBoxHeadlessUI
            state={field.value || 'Select Target Element'}
            setState={field.onChange}
            list={filteredValueArray}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name="optionsDto.targetElement"
        render={({ message }) => <p className="error-message">{message}</p>}
      />
    </>
  );
};

export default KeyboardEventList;
