import { ErrorMessage, SubHeaderTitle } from '@autosquare/common';

import FormField from '@components/shared/Input/FormField';
import TestGroupBox from '@components/shared/IDE/TestCase/TestGroupBox';
import ServiceGroupBox from '@components/shared/IDE/TestCase/ServiceGroupBox';
import PriorityListbox from '@components/shared/HeadlessUI/Listbox/PriorityListbox';

import { CreateWebSpeedTestCaseInfo } from '@customTypes/ide/speedTest/Web/testCase/type';
import { ServerResponseErrorDetails } from '@customTypes/type';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  isError: boolean;
  error: ServerResponseErrorDetails;
}

const WebSpeedTestCaseInfo = ({ isError, error }: Props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<CreateWebSpeedTestCaseInfo>();

  return (
    <div className="mt-8 pb-4">
      <SubHeaderTitle
        subTitle={'Test Case Information'}
        description={'테스트 케이스 정보를 입력해주세요.'}
      />
      <div className="mt-6 flex flex-col">
        <div className="grid grid-cols-3 gap-4">
          <div className="flex flex-col">
            <div className="block text-sm font-medium leading-6 text-gray-900">
              Priority
            </div>
            <div className="mt-2">
              <Controller
                control={control}
                name={'priority'}
                render={({ field }) => (
                  <PriorityListbox
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
          <TestGroupBox />
          <ServiceGroupBox />
        </div>
        <div className="mt-4 flex flex-row gap-4">
          <FormField
            name={'title'}
            labelTitle={'Title*'}
            errors={errors}
            register={register('title', {
              required: '필수 입력 사항 입니다.',
              maxLength: { value: 50, message: '50자를 초과할 수 없습니다.' },
            })}
          />
          <FormField
            name={'description'}
            labelTitle={'Description'}
            errors={errors}
            register={register('description', {
              maxLength: {
                value: 200,
                message: '200자를 초과할 수 없습니다.',
              },
            })}
          />
        </div>
        {isError && <ErrorMessage>{error.message}</ErrorMessage>}
      </div>
    </div>
  );
};

export default WebSpeedTestCaseInfo;
