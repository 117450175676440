import {
  Button,
  DoubleCheckDialogHeadlessUI,
  Execution,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';
import { ClickId } from '@utils/static/clickOptions';

import { useExecutionMobileTestStepMutation } from '@lib/api/ide/mobile/mobileExecution';

import { DeviceInfo } from '@customTypes/ide/device/device';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import TestStepListProperty from './TestStepDragList/TestStepListProperty';

interface Props {
  list: MobileTestStepData;
  index: number;
  lists: MobileTestStepData[];
  deviceInfo: DeviceInfo;
  refreshToken: string;
}

const TestStepDragList = ({
  list,
  index,
  lists,
  deviceInfo,
  refreshToken,
}: Props) => {
  const { projectIdx } = useParams();
  const [isOpenedDetail, setIsOpenedDetails] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const toggledDetails = () => {
    setIsOpenedDetails(!isOpenedDetail);
  };

  const saveTextIndex =
    lists
      ?.map((item) => item.idx)
      ?.indexOf(parseInt(list.optionsDto.compareIdx)) + 1;

  const executeMobileTestStepMutation = useExecutionMobileTestStepMutation(
    refreshToken,
    { infos: [deviceInfo], stepIdx: list.idx, projectIdx: Number(projectIdx) },
  );

  const executeMobileTestStep = () => {
    executeMobileTestStepMutation.mutate();
    closeModal();
  };

  const clickExecutionIcon = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    openModal();
  };

  const mobileTestCaseListQuery = useMobileTestCaseListQuery(projectIdx, {
    enabled: list.command === MobileCommandOptionServerName.ImportTestCase,
  });
  const mobileTestCaseList = mobileTestCaseListQuery.data;

  const findTitleFromIdx = (idx: number) =>
    mobileTestCaseList?.find((testCase) => testCase.idx === idx)?.title;

  const ifPassAction =
    list.command === MobileCommandOptionServerName.IF
      ? list.value.split(',')[0]
      : undefined;
  const ifFailAction =
    list.command === MobileCommandOptionServerName.IF
      ? list.value.split(',')[1]
      : undefined;

  const disabledExcution =
    (list.command === MobileCommandOptionServerName.ConfirmText &&
      list.optionsDto.compareBy === 'id') ||
    (list.command === MobileCommandOptionServerName.InputText &&
      list.optionsDto.compareBy === 'id') ||
    list.command === MobileCommandOptionServerName.ImportTestCase ||
    (list.command === MobileCommandOptionServerName.IF &&
      ifPassAction === MobileCommandOptionServerName.ImportTestCase) ||
    (list.command === MobileCommandOptionServerName.IF &&
      ifFailAction === MobileCommandOptionServerName.ImportTestCase);

  return (
    <>
      <Draggable draggableId={list.idx.toString()} index={index}>
        {(provided) => (
          <li
            className="flex w-full flex-col"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onDragStart={() => setIsOpenedDetails(false)}
          >
            <div
              className={`${
                isOpenedDetail && 'border-b-0'
              } flex w-full justify-between border border-gray-200 bg-gray-50 p-3 text-left text-xs font-medium text-gray-900`}
              onClick={toggledDetails}
            >
              <div className="flex w-3/4 flex-row items-center gap-2">
                <Execution
                  size={5}
                  onClick={clickExecutionIcon}
                  disabled={disabledExcution}
                />
                <div className="w-full">
                  <p className="w-full truncate">
                    {list.description},{' '}
                    {findMobileCommandNameFromServerName(list.command)},{' '}
                    {(list.command === MobileCommandOptionServerName.Click &&
                      list.optionsDto.clickBy === ClickId.Image) ||
                    list.command === MobileCommandOptionServerName.CompareImage
                      ? 'Crop Image'
                      : list.command ===
                            MobileCommandOptionServerName.ScrollSwipe ||
                          (list.command ===
                            MobileCommandOptionServerName.Click &&
                            list.value?.split(',').length >= 2)
                        ? `[${list.value}]`
                        : list.command ===
                            MobileCommandOptionServerName.DeviceKey
                          ? findListNameFromServerName(
                              list.value as DeviceKeyServerName,
                            )
                          : list.command === MobileCommandOptionServerName.Loop
                            ? `Condition="${findMobileCommandNameFromServerName(
                                list.optionsDto.conditionCommand,
                              )}" Action="${findMobileCommandNameFromServerName(
                                list.value as MobileCommandOptionServerName,
                              )}"`
                            : list.command === MobileCommandOptionServerName.IF
                              ? list.optionsDto.conditionValue
                              : list.command ===
                                  MobileCommandOptionServerName.ImportTestCase
                                ? findTitleFromIdx(Number(list.value))
                                : list.command ===
                                    MobileCommandOptionServerName.UnlockPattern
                                  ? 'points'
                                  : list.value}
                  </p>
                </div>
              </div>
              <ChevronUpIcon
                // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
                className={`${
                  isOpenedDetail ? 'transform' : 'rotate-180'
                } size-5 cursor-pointer text-gray-900`}
              />
            </div>
            {isOpenedDetail && (
              <TestStepListProperty
                list={list}
                saveTextIndex={saveTextIndex}
                findTitleFromIdx={findTitleFromIdx}
              />
            )}
          </li>
        )}
      </Draggable>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={'Execute Step Test'}
        subTitle={
          '선택한 스텝에 대한 테스트를 진행하시겠습니까?\n(테스트 결과는 저장되지 않으며,\n테스트 실행 완료 후 현재 스크린샷과 객체 정보가 자동 갱신됩니다.)'
        }
        buttonChildren={
          <>
            <Button
              type="button"
              variant={'caution'}
              onClick={executeMobileTestStep}
            >
              Execute
            </Button>
            <Button type="button" variant={'secondary'} onClick={closeModal}>
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

export default TestStepDragList;
