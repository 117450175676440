import { useMobileWebSpeedTestStepQuery } from '@lib/api/ide/speedMobile/mobileWebTestStep';

import React, { useState } from 'react';

import CreateMobileWebSpeedTestStep from './MobileWebTestStep/CreateMobileWebSpeedTestStep';
import MobileWebTestStepDetail from './MobileWebTestStep/MobileWebTestStepDetail';
import MobileWebTestStepError from '@components/shared/MobileWebTestStep/MobileWebTestStepError';
import MobileWebTestStepSkeleton from '@components/shared/MobileWebTestStep/MobileWebTestStepSkeleton';
import UpdateMobileWebSpeedTestStep from './MobileWebTestStep/UpdateMobileWebSpeedTestStep';

const MobileWebTestStep = () => {
  const [isCreateTestStep, setIsCreateTestStep] = useState(false);
  const [isUpdateTestStep, setIsUpdateTestStep] = useState(false);

  const mobileWebSpeedTestStepQuery = useMobileWebSpeedTestStepQuery();
  const mobileWebSpeedTestStep = mobileWebSpeedTestStepQuery.data;

  const startCreatingTestStep = () => {
    setIsCreateTestStep(true);
  };

  const endCreatingTestStep = () => {
    setIsCreateTestStep(false);
  };

  const startUpdateTestStep = () => {
    setIsUpdateTestStep(true);
  };

  const endUpdateTestStep = () => {
    setIsUpdateTestStep(false);
  };

  return mobileWebSpeedTestStepQuery.isLoading ||
    mobileWebSpeedTestStepQuery.isFetching ? (
    <MobileWebTestStepSkeleton />
  ) : mobileWebSpeedTestStepQuery.isError ? (
    <MobileWebTestStepError
      message={mobileWebSpeedTestStepQuery.error.message}
    />
  ) : isCreateTestStep ? (
    <CreateMobileWebSpeedTestStep endCreatingTestStep={endCreatingTestStep} />
  ) : isUpdateTestStep ? (
    <UpdateMobileWebSpeedTestStep
      endUpdateTestStep={endUpdateTestStep}
      mobileWebSpeedTestStep={mobileWebSpeedTestStep}
    />
  ) : (
    <MobileWebTestStepDetail
      startCreatingTestStep={startCreatingTestStep}
      startUpdateTestStep={startUpdateTestStep}
      mobileWebSpeedTestStep={mobileWebSpeedTestStep}
    />
  );
};

export default MobileWebTestStep;
