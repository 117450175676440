import SetAsVariablePanel from '@components/shared/MobileWebTestStep/CreateAndUpdate/SetAsVariablePanel';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import WebSpeedCreateKeyNameAndFromAndValue from './WebSpeedCreateSetAsVariablePanel/WebSpeedCreateKeyNameAndFromAndValue';
import WebSpeedCreateDateFormat from './WebSpeedCreateSetAsVariablePanel/WebSpeedCreateDateFormat';
import { CreateWebSpeedTestStepData } from '@customTypes/ide/speedTest/Web/testStep/type';
import { useSpeedWebSetAsVariablePanel } from '@hooks/speedWebTestStep/useSpeedWebSetAsVariablePanel';

interface Props {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedText: React.Dispatch<React.SetStateAction<string>>;
  isSaved: boolean;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  selectionStart: number;
  selectionEnd: number;
}

const WebSpeedCreateSetAsVariablePanel = ({
  isOpened,
  setIsOpened,
  setSelectedText,
  isSaved,
  setIsSaved,
  closeDialog,
  selectionStart,
  selectionEnd,
}: Props) => {
  const { watch, setValue, setFocus, setError, clearErrors } =
    useFormContext<CreateWebSpeedTestStepData>();

  const { handleSave } = useSpeedWebSetAsVariablePanel({
    watch,
    setSelectedText,
    setFocus,
    isOpened,
    setIsOpened,
    setError,
    clearErrors,
    isSaved,
    setIsSaved,
    setValue,
    selectionStart,
    selectionEnd,
  });

  return (
    <SetAsVariablePanel closeDialog={closeDialog} handleSave={handleSave}>
      <WebSpeedCreateKeyNameAndFromAndValue />
      <WebSpeedCreateDateFormat />
    </SetAsVariablePanel>
  );
};

export default WebSpeedCreateSetAsVariablePanel;
