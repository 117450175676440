/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import clsx from 'clsx';
import SpeedTestResultTableDetail from './SpeedTestResultTableDetail/SpeedTestResultTableDetail';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import WebSpeedTestResultTableDetail from '../WebSpeedTestResultTableDetail/WebSpeedTestResultTableDetail';

type Props = {
  data: unknown[];
  columns: ColumnDef<unknown, unknown>[];
};

const SpeedTestResultGraphInfoTable = ({ data, columns }: Props) => {
  const [isDetailOpened, setIsDetailOpened] = useState(false);
  const [sorting, setSorting] = useState<SortingState>();
  const [chartIndex, setChartIndex] = useState<number>(null);
  const isDashboard = !isWordInLocationPathname('speed-test');
  const location = isWordInLocationPathname('web') ? 'web' : 'mobile';

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div className="max-h-72 overflow-auto rounded-lg border border-gray-200 scrollbar-thin">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="sticky top-0 bg-white">
            {table?.getHeaderGroups()?.map((headerGroup) => (
              <tr key={headerGroup?.id}>
                {headerGroup?.headers?.map((header) => (
                  <th
                    key={header?.id}
                    scope="col"
                    className={`px-3 py-2 text-left text-sm font-semibold text-gray-900 ${
                      header?.column?.getCanSort() &&
                      'cursor-pointer select-none'
                    }`}
                    onClick={header?.column?.getToggleSortingHandler()}
                  >
                    <span className="flex items-center gap-9">
                      {header?.isPlaceholder
                        ? null
                        : flexRender(
                            header?.column?.columnDef?.header,
                            header?.getContext(),
                          )}
                      {{
                        asc: <FiChevronUp size={16} />,
                        desc: <FiChevronDown size={16} />,
                      }[header?.column?.getIsSorted() as string] ?? null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {table?.getRowModel()?.rows?.map((row) => (
              <tr key={row?.id} className="even:bg-gray-50 ">
                {row?.getVisibleCells()?.map((cell) => {
                  const cellData = (cell?.getValue() as any)?.data;
                  const cellIdx = (cell?.getValue() as any)?.idx;
                  return (
                    <td
                      key={cell?.id}
                      className={clsx(
                        'whitespace-nowrap p-2 text-sm text-gray-500',
                        Number(cellData) > 3 && 'text-red-500',
                      )}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          if (
                            cellIdx !== 0 &&
                            cellIdx !== -1 &&
                            cellIdx !== '-' &&
                            Number(cellData) !== 0
                          ) {
                            setIsDetailOpened(true);
                            setChartIndex(cellIdx);
                          }
                        }}
                        className={clsx(
                          'p-2',
                          cellIdx === 0 ||
                            cellIdx === -1 ||
                            cellIdx === '-' ||
                            Number(cellData) === 0
                            ? 'cursor-default'
                            : 'cursor-pointer:hover rounded-2xl hover:bg-sky-100',
                        )}
                      >
                        {Number(cellData) === 0 ? '-' : cellData}
                      </button>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDetailOpened &&
        (location === 'mobile' ? (
          <SpeedTestResultTableDetail
            chartIndex={chartIndex}
            isDashboard={isDashboard}
          />
        ) : (
          location === 'web' && (
            <WebSpeedTestResultTableDetail
              chartIndex={chartIndex}
              isDashboard={isDashboard}
            />
          )
        ))}
    </>
  );
};

export default SpeedTestResultGraphInfoTable;
