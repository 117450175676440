import React, { Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import {
  findTitleFromMinute,
  findTitleFromHour,
  minuteCycle,
  time,
  hourCycle,
} from '@utils/static/runningCycle';

import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { parseCronCycle } from '@utils/static/parseCronCycle';

interface Props {
  isRunning: boolean;
}
const SchedulerDetailRunningCycle = ({ isRunning }: Props) => {
  const { watch, setValue } = useFormContext<UpdateSchedulerData>();

  const cycleValue = watch('cron_cycle');
  const startTimeValue = watch('start_time');
  const initialTimeUnit = cycleValue.split(' ')[1].includes('/')
    ? 'Minute'
    : 'Hour';
  const [timeUnit, setTimeUnit] = useState(initialTimeUnit);
  const selectedTime =
    parseCronCycle(cycleValue).timeInterval === 'Once a day'
      ? 'Every 24 hour'
      : parseCronCycle(cycleValue).timeInterval;
  const formattedSelectedTime = selectedTime.replace(/(\(s\))/, '');

  const selectedDays = parseCronCycle(cycleValue).daysOfWeek;

  const findTitle = (cycle: string) => {
    if (cycle.split(' ')[2].includes('minute')) {
      return findTitleFromMinute(cycle);
    } else {
      return findTitleFromHour(cycle);
    }
  };

  const [hours, minutes] = startTimeValue.split(' ')[1].split(':');
  let cronExpression: string;
  const reCreateCronExpression = (timeCycle: string) => {
    const formattedDays =
      selectedDays.split(',').length === 7 ? '?' : selectedDays;
    const formattedTimeCycle = timeCycle.match(/\d+/)?.[0];

    const startHours = +hours < 10 ? hours.split('')[1] : hours;
    const startMinutes = +minutes < 10 ? minutes.split('')[1] : minutes;

    if (timeUnit === 'Hour') {
      if (formattedTimeCycle === '24') {
        if (formattedDays === '?') {
          cronExpression = `0 ${startMinutes} ${startHours} * * ?`;
        } else {
          cronExpression = `0 ${startMinutes} ${startHours} ? * ${formattedDays}`;
        }
      } else {
        if (formattedDays === '?') {
          cronExpression = `0 ${startMinutes} 0/${formattedTimeCycle} * * ?`;
        } else {
          cronExpression = `0 ${startMinutes} 0/${formattedTimeCycle} ? * ${formattedDays}`;
        }
      }
    } else {
      if (formattedDays === '?') {
        cronExpression = `0 0/${formattedTimeCycle} * * * ?`;
      } else {
        cronExpression = `0 0/${formattedTimeCycle} * ? * ${formattedDays}`;
      }
    }
    return cronExpression;
  };

  const [selectedCycle, setSelectedCycle] = useState(formattedSelectedTime);
  const handleTimeUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unit = e.target.value;
    setTimeUnit(unit);
    if (unit === 'Minute') {
      setSelectedCycle('Every 1 minute');
    } else {
      setSelectedCycle('Every 1 hour');
    }
  };
  useEffect(() => {
    const newCronCycle = reCreateCronExpression(selectedCycle);
    setValue('cron_cycle', newCronCycle);
  }, [timeUnit, selectedCycle, startTimeValue]);

  return (
    <div className="mt-2 sm:col-span-5 sm:mt-0">
      <div>
        <label
          htmlFor="cycle"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Cycle
        </label>
        <div className="mt-2 flex items-center justify-center space-x-4">
          <div className="flex gap-x-3">
            {time.map((item) => (
              <label
                htmlFor={`${item.id}`}
                className="mr-2 flex items-center text-sm font-medium"
                key={item.id}
              >
                <input
                  id={`${item.id}`}
                  name="cycle unit"
                  type="radio"
                  value={item.val}
                  className="input-radio-congress-blue mr-3"
                  checked={timeUnit === item.val}
                  onChange={handleTimeUnitChange}
                  disabled={!isRunning}
                />
                {item.val}
              </label>
            ))}
          </div>
          <Listbox
            value={selectedCycle}
            onChange={setSelectedCycle}
            disabled={!isRunning}
          >
            {({ open }) => (
              <div className="relative grow">
                <ListboxButton
                  className={`${
                    isRunning ? 'bg-white' : 'bg-gray-100'
                  } relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                >
                  <span className="block truncate">
                    {findTitle(selectedCycle)}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="size-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {(timeUnit === 'Minute' ? minuteCycle : hourCycle)?.map(
                      (cycle) => (
                        <ListboxOption
                          key={cycle?.idx}
                          className={({ focus }) =>
                            `relative cursor-default select-none py-2 pl-3 pr-9 ${
                              focus
                                ? 'bg-indigo-600 text-white'
                                : 'text-gray-900'
                            }`
                          }
                          value={cycle.title}
                        >
                          {({ selected, focus }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-semibold' : 'font-normal'
                                }`}
                              >
                                {cycle?.title}
                              </span>
                              {selected && (
                                <span
                                  className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                    focus ? 'text-white' : 'text-indigo-600'
                                  }`}
                                >
                                  <CheckIcon
                                    className="size-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </ListboxOption>
                      ),
                    )}
                  </ListboxOptions>
                </Transition>
              </div>
            )}
          </Listbox>
        </div>
      </div>
    </div>
  );
};

export default SchedulerDetailRunningCycle;
