import React from 'react';
import clsx from 'clsx';

import logoIcon from '@assets/img/logo_icon.png';
import { Button } from '@autosquare/common';

type Props = {
  closeModal: () => void;
  width?:
    | 'w-1/2'
    | 'w-1/3'
    | 'w-2/3'
    | 'w-1/4'
    | 'w-2/4'
    | 'w-3/4'
    | 'w-1/5'
    | 'w-2/5'
    | 'w-3/5'
    | 'w-4/5'
    | 'w-1/6'
    | 'w-2/6'
    | 'w-3/6'
    | 'w-4/6'
    | 'w-5/6'
    | 'w-1/12'
    | 'w-2/12'
    | 'w-3/12'
    | 'w-4/12'
    | 'w-5/12'
    | 'w-6/12'
    | 'w-7/12'
    | 'w-8/12'
    | 'w-9/12'
    | 'w-10/12'
    | 'w-11/12'
    | 'w-full';
};

const TitleBar = ({ closeModal, width }: Props) => {
  return (
    <div
      className={clsx(
        'fixed flex h-8 items-center justify-between rounded-t-md bg-gray-100 pl-2',
        width ? width : 'w-4/5',
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <img src={logoIcon} alt="t-square logo" width={16} height={16} />
        <span className="cursor-default text-xs">Autosquare</span>
      </div>
      <div>
        <Button
          type="button"
          variant="imageEscape"
          buttonSize="md"
          onClick={() => closeModal()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default TitleBar;
