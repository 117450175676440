import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const ScenarioTestCaseListContainer = ({ children }: Props) => {
  return (
    <div>
      <div className="mb-2 flex justify-between">
        <p className="font-medium leading-6">Scenario Test Case List</p>
      </div>
      <div className="gray-scrollbar h-[calc(100vh-21rem)] rounded-md border border-solid border-gray-200">
        {children}
      </div>
    </div>
  );
};

export default ScenarioTestCaseListContainer;
