import {
  TableRow,
  TableHeader,
  TableBodyBasic,
  TableNoList,
  TableContainer,
} from '@autosquare/common';
import {
  DeviceInfoOs,
  DeviceInfoOsFullName,
} from '@customTypes/ide/device/device';
import { MobileTestResultPagingData } from '@customTypes/testResult/type';

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  testResultList: MobileTestResultPagingData;
  page: number;
  PAGE_LIMIT: number;
};

const TestResultTable = ({ testResultList, page, PAGE_LIMIT }: Props) => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  return (
    <TableContainer>
      <thead>
        <tr>
          {tableHeaders.map((tableHeader) => (
            <TableHeader key={tableHeader}>{tableHeader}</TableHeader>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {testResultList?.content.length === 0 ? (
          <TableNoList colSpan={tableHeaders.length}>
            해당 프로젝트에 생성된 테스트 결과가 존재하지 않습니다.
          </TableNoList>
        ) : (
          testResultList?.content.map((testResult, idx) => (
            <TableRow key={testResult.idx}>
              <TableBodyBasic>
                {(page - 1) * PAGE_LIMIT + idx + 1}
              </TableBodyBasic>
              <TableBodyBasic>{testResult.type}</TableBodyBasic>
              <TableBodyBasic>
                {testResult.mobileOs === DeviceInfoOs.Aos
                  ? DeviceInfoOsFullName.Aos
                  : DeviceInfoOsFullName.Ios}
              </TableBodyBasic>
              <TableBodyBasic>{testResult.scenarioName}</TableBodyBasic>
              <TableBodyBasic>{testResult.startTime}</TableBodyBasic>
              <TableBodyBasic>{testResult.device}</TableBodyBasic>
              <TableBodyBasic>{testResult.result}</TableBodyBasic>
              <TableBodyBasic>
                {testResult.result !== 'Running' && (
                  <button
                    type="button"
                    className="flex items-center justify-center rounded-md hover:text-congress-blue"
                    onClick={() =>
                      navigate({
                        pathname: `/ide/projects/${projectIdx}/ui-test/mobile/test-result/detail`,
                        search: `idx=${testResult.idx}`,
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                      />
                    </svg>
                  </button>
                )}
              </TableBodyBasic>
            </TableRow>
          ))
        )}
      </tbody>
    </TableContainer>
  );
};

export default TestResultTable;

const tableHeaders = [
  'No',
  'Type',
  'OS',
  'Title',
  'DateTime',
  'Device',
  'Result',
  'Detail',
];
