import {
  Checkbox,
  Detail,
  ErrorMessage,
  Execution,
  PlatFormTypeServerName,
  TableBodyBasic,
  TableHeader,
  TableNoList,
  TableWrapper,
  Time,
  UseCheckboxAttribute,
  findPlatformTypeNameFromServerName,
} from '@autosquare/common';
import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';
import { SpeedMobileTestCaseData } from '@customTypes/speedTestCase/type';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseQueryResult } from '@tanstack/react-query';
import { timePattern } from '@utils/static/timePattern';

interface Props extends UseCheckboxAttribute {
  speedMobileTestCaseList: SpeedMobileTestCaseData[];
  mobileTestCaseListQuery: UseQueryResult<SpeedMobileTestCaseData[], Error>;
}
const MobileSpeedTestCaseList = ({
  speedMobileTestCaseList,
  selectAllArray,
  selectArray,
  selectedArray,
  openDialog,
  mobileTestCaseListQuery,
}: Props) => {
  const tableHeaders = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="w-[1.125rem]" /> : 'No',
    },
    {
      idx: 3,
      title:
        selectedArray.length > 0 ? <div className="w-[3.75rem]" /> : 'Platform',
    },
    { idx: 4, title: 'Test Group' },
    { idx: 5, title: 'Service Group' },
    { idx: 6, title: 'Title' },
    { idx: 7, title: 'Step Count' },
    { idx: 8, title: 'Priority' },
    { idx: 9, title: 'Creator' },
    { idx: 10, title: 'Updated at' },
    { idx: 11, title: '' },
    { idx: 12, title: '' },
  ];

  const [testCaseIdx, setTestCaseIdx] = useState<number>(0);
  const [platformType, setPlatformType] =
    useState<PlatFormTypeServerName>(null);
  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {tableHeaders.map((tableHeader) => (
              <TableHeader key={tableHeader.idx}>
                {tableHeader.title}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {mobileTestCaseListQuery.isLoading ||
          mobileTestCaseListQuery.isFetching ? (
            <>
              {Array.from({ length: 6 }, (_, i) => (
                <tr key={i} className="w-full animate-pulse">
                  <td colSpan={11} className="px-3 py-4">
                    <div className="h-4 rounded-md bg-gray-300"></div>
                  </td>
                </tr>
              ))}
            </>
          ) : mobileTestCaseListQuery.isError ? (
            <tr>
              <td colSpan={11}>
                <ErrorMessage>
                  {mobileTestCaseListQuery.error.message}
                </ErrorMessage>
              </td>
            </tr>
          ) : speedMobileTestCaseList?.length === 0 ? (
            <TableNoList colSpan={tableHeaders.length}>
              해당 프로젝트에 생성된 테스트 케이스가 존재하지 않습니다.
            </TableNoList>
          ) : (
            speedMobileTestCaseList?.map((mobileTestCase, idx) => (
              <tr key={mobileTestCase.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(Number(mobileTestCase.idx))}
                    onChange={(e) => selectArray(Number(mobileTestCase.idx), e)}
                  />
                </TableBodyBasic>
                <TableBodyBasic>{idx + 1}</TableBodyBasic>
                <TableBodyBasic>
                  {mobileTestCase.platformType === null
                    ? '-'
                    : findPlatformTypeNameFromServerName(
                        mobileTestCase.platformType,
                      )
                        .replace(/mobile/i, '')
                        .trim()}
                </TableBodyBasic>
                <TableBodyBasic>
                  {mobileTestCase.caseGroupInfo?.name ?? '-'}
                </TableBodyBasic>
                <TableBodyBasic>
                  {mobileTestCase.serviceGroupInfo?.name ?? '-'}
                </TableBodyBasic>
                <td className="table-data-basic max-w-[14.75rem]">
                  <div className="font-medium text-gray-900">
                    {mobileTestCase.title}
                  </div>
                  <div className={`mt-1 w-full break-words text-gray-500`}>
                    {mobileTestCase.description}
                  </div>
                </td>
                <TableBodyBasic>
                  {mobileTestCase.platformType ===
                  PlatFormTypeServerName.MobileApp ? (
                    <>
                      <p>AOS:{mobileTestCase.steps?.aosStepCount ?? 0}</p>
                      <p>iOS:{mobileTestCase.steps?.aosStepCount ?? 0}</p>
                    </>
                  ) : (
                    mobileTestCase.steps.stepCount
                  )}
                </TableBodyBasic>
                <TableBodyBasic>{mobileTestCase.priority}</TableBodyBasic>
                <TableBodyBasic>{mobileTestCase.creator}</TableBodyBasic>
                <TableBodyBasic>
                  <div>{mobileTestCase?.updatedAt?.split(' ')[0] ?? '-'}</div>
                  <div
                    className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                  >
                    {mobileTestCase.updatedAt && <Time />}
                    {RegExp(timePattern).exec(mobileTestCase.updatedAt)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    tooltipLocation={'right'}
                    testType="case"
                    disabled={
                      mobileTestCase.steps?.aosStepCount === 0 &&
                      mobileTestCase.steps?.iosStepCount === 0
                    }
                    onClick={() => {
                      if (
                        mobileTestCase.steps?.aosStepCount > 0 ||
                        mobileTestCase.steps?.iosStepCount > 0 ||
                        mobileTestCase.steps?.stepCount > 0
                      ) {
                        setTestCaseIdx(Number(mobileTestCase.idx));
                        setIsTestExecutionOpened(true);
                        setPlatformType(mobileTestCase.platformType);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: 'detail',
                        search: `idx=${mobileTestCase.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </tbody>
      </TableWrapper>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testCaseIdx}
          testType={'case'}
          deviceType={'mobile'}
          testLocation="speed-test"
          platformType={platformType}
        />
      )}
    </>
  );
};

export default MobileSpeedTestCaseList;
