import {
  BackToListIcon,
  Breadcrumbs,
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingsNavigate,
  TestResultDetailSkeleton,
} from '@autosquare/common';

import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import TestResultDetailSummary from './components/TestResultDetailSummary';
import Information from './components/Information';
import ExecutionDetails from './components/ExecutionDetails';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import {
  useMobileTestResultDetailInfoQuery,
  useMobileTestResultDetailQuery,
} from '@lib/api/ide/mobile/mobileTestResult';
import {
  useWebTestResultDetailInfoQuery,
  useWebTestResultDetailQuery,
} from '@lib/api/ide/web/webTestResult';

const TestResultDetail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();
  const idx = searchParams.get('idx');

  const testResultDetailQuery = isWordInLocationPathname('mobile')
    ? useMobileTestResultDetailQuery(idx)
    : useWebTestResultDetailQuery(idx);
  const testResultDetail = testResultDetailQuery.data;

  const testResultDetailInfoQuery = isWordInLocationPathname('mobile')
    ? useMobileTestResultDetailInfoQuery(idx)
    : useWebTestResultDetailInfoQuery(idx);

  const testResultDetailInfo = testResultDetailInfoQuery.data;
  const uiOverviewPage = `${pathname.split('/').slice(0, -3).join('/')}/overview`;

  const breadcrumbs: Breadcrumbs[] = isWordInLocationPathname('mobile')
    ? [
        {
          title: 'UI Test',
          href: uiOverviewPage,
        },
        {
          title: 'Mobile',
          href: `/ide/projects/${projectIdx}/ui-test/mobile/test-result?page=1`,
        },
        {
          title: 'Test Result',
          href: `/ide/projects/${projectIdx}/ui-test/mobile/test-result?page=1`,
        },
        {
          title: 'Detail',
          href: pathname + search,
        },
      ]
    : [
        { title: 'UI Test', href: uiOverviewPage },
        {
          title: 'Web',
          href: `/ide/projects/${projectIdx}/ui-test/web/test-result?page=1`,
        },
        {
          title: 'Test Result',
          href: `/ide/projects/${projectIdx}/ui-test/web/test-result?page=1`,
        },
        { title: 'Detail', href: pathname + search },
      ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: (
        <div className="flex items-center justify-between gap-1.5 text-sm font-semibold leading-5 text-gray-900">
          <BackToListIcon />
          Back to list
        </div>
      ),
      onClick: () => navigate(-1),
      type: 'button',
      variant: 'secondary',
    },
  ];
  const location = isWordInLocationPathname('mobile') ? 'mobile-app' : 'web';

  const uiTestUrl = `${localStorage.getItem('applicationUrl')}/projects/${projectIdx}/${location}/test-result/detail${search}`;
  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result Detail'}
        pageHeadingButtons={pageHeadingButtons}
        url={uiTestUrl}
        copy={true}
      />
      {testResultDetailQuery.isLoading ||
      testResultDetailQuery.isFetching ||
      testResultDetailInfoQuery.isLoading ||
      testResultDetailInfoQuery.isFetching ? (
        <TestResultDetailSkeleton />
      ) : testResultDetailQuery.isError ? (
        <ErrorMessage>{testResultDetailQuery.error.message}</ErrorMessage>
      ) : testResultDetailInfoQuery.isError ? (
        <ErrorMessage>{testResultDetailInfoQuery.error.message}</ErrorMessage>
      ) : (
        <>
          <TestResultDetailSummary
            summary={testResultDetail?.summary}
            result={testResultDetailInfo?.result}
            reason={testResultDetailInfo?.failReason}
          />
          <Information testResult={testResultDetailInfo} />
          <ExecutionDetails resultDetails={testResultDetail?.resultDetail} />
        </>
      )}
    </>
  );
};

export default TestResultDetail;
