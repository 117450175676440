import { useEffect, useState } from 'react';

export const useGetWebSocketMessage = () => {
  const [webSocketMessages, setWebSocketMessages] = useState<string[]>([]);
  const [testSessionId, setTestSessionId] = useState('');
  const [isTestRunning, setIsTestRunning] = useState<boolean>(false);
  const [testStepResult, setTestStepResult] = useState<{
    result: 'Pass' | 'Fail' | 'N/A';
    fail_reason?: string;
    load_speed?: string;
  }>();
  const [testTarget, setTestTarget] = useState<'mobile' | 'web'>(undefined);

  useEffect(() => {
    window.electron.on(
      'webSocketMessage',
      (message: {
        category: 'test_log' | 'test_step_result';
        contents: {
          log: string;
          test_session_id: string;
          is_running: boolean;
          result: 'Pass' | 'Fail' | 'N/A';
          test_target: 'mobile' | 'web';
          fail_reason?: string;
          load_speed?: string;
        };
      }) => {
        setIsTestRunning(message.contents.is_running);
        setTestTarget(message.contents.test_target);
        setTestSessionId(message.contents.test_session_id);

        if (message.contents.is_running) {
          setWebSocketMessages((prev) => [...prev, message.contents.log]);
        }
        if (message.category === 'test_step_result') {
          setTestStepResult(message.contents);
        }
      },
    );
  }, []);

  useEffect(() => {
    window.electron.send('isTestRunning', isTestRunning);
  }, [isTestRunning]);

  return {
    webSocketMessages,
    isTestRunning,
    setIsTestRunning,
    testStepResult,
    testTarget,
    testSessionId,
  };
};
