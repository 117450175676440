import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { useFormContext } from 'react-hook-form';

import ExtractTextFormatBody from './ExtractTextFormat/ExtractTextFormatBody';
import ExtractTextFormatTitle from './ExtractTextFormat/ExtractTextFormatTitle';

const ExtractTextFormat = () => {
  const { watch } = useFormContext<CreateTestStepData>();

  const textExtract = watch('optionsDto.textExtract');

  return (
    <>
      <ExtractTextFormatTitle />
      {textExtract === 'true' && <ExtractTextFormatBody />}
    </>
  );
};

export default ExtractTextFormat;
