import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface MobileTestStepDetail {
  testStepIdx: number | null;
}

const initialState: MobileTestStepDetail = {
  testStepIdx: null,
};

export const mobileTestStepDetailSlice = createSlice({
  name: "mobile test step detail",
  initialState,
  reducers: {
    getTestStepIdx: (state, action: PayloadAction<number | null>) => {
      state.testStepIdx = action.payload;
    },
  },
});

export const { getTestStepIdx } = mobileTestStepDetailSlice.actions;

export default mobileTestStepDetailSlice.reducer;
