import { Checkbox, ListBoxHeadlessInController } from '@autosquare/common';

import {
  CreateMobileWebSpeedTestStepData,
  UpdateMobileWebSpeedTestStepData,
} from '@customTypes/ide/speedTest/Mobile/webTestStep/type';

import {
  findTextReplaceAddNameByServerName,
  textReplaceAddList,
} from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';
import {
  findTextReplaceFromNameByServerName,
  textReplaceFromList,
} from '@utils/static/mobileTestStep/textReplace/textReplaceFromList';

import React, { useEffect } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormWatch,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import WebTestStepTableBodyDataCell from '../WebTestStepTableBodyDataCell';

type Props = {
  optionsDtoIdx: number;
  selectedArray: number[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  idx: number;
  tableColsLength: number;
  handleKeyNameChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<
      CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData,
      `optionsDto.${number}.keyName`
    >,
  ) => void;
  optionsDtoKeyNameList: string[];
  watch: UseFormWatch<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
  control: Control<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
  register: UseFormRegister<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
  errors: FieldErrors<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
  setError: UseFormSetError<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
  clearErrors: UseFormClearErrors<
    CreateMobileWebSpeedTestStepData | UpdateMobileWebSpeedTestStepData
  >;
};

const VariableTableBody = ({
  optionsDtoIdx,
  selectedArray,
  selectArray,
  idx,
  tableColsLength,
  handleKeyNameChange,
  optionsDtoKeyNameList,
  watch,
  control,
  register,
  clearErrors,
  errors,
}: Props) => {
  const keyName = watch(`optionsDto.${idx}.keyName`);

  useEffect(() => {
    if (
      !optionsDtoKeyNameList.some(
        (optionsDtoKeyName) => optionsDtoKeyName === keyName,
      )
    ) {
      clearErrors(`optionsDto.${idx}.keyName`);
    }
  }, [keyName, idx, clearErrors]);

  const keyNameError = errors?.optionsDto?.[idx]?.keyName?.message;
  const addValueError = errors?.optionsDto?.[idx]?.addValue?.message;
  const dateFormatError = errors?.optionsDto?.[idx]?.dateFormat?.message;
  const separatorError = errors?.optionsDto?.[idx]?.separator?.message;

  return (
    <>
      <tr>
        <WebTestStepTableBodyDataCell>
          <Checkbox
            checked={selectedArray.includes(optionsDtoIdx)}
            onChange={(e) => selectArray(optionsDtoIdx, e)}
            checkboxSize="sm"
          />
        </WebTestStepTableBodyDataCell>
        <WebTestStepTableBodyDataCell>
          <Controller
            name={`optionsDto.${idx}.keyName`}
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className="input-base"
                {...field}
                onChange={(e) => {
                  handleKeyNameChange(e, field);
                }}
              />
            )}
          />
        </WebTestStepTableBodyDataCell>
        <WebTestStepTableBodyDataCell>
          <Controller
            control={control}
            name={`optionsDto.${idx}.from` as const}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findTextReplaceFromNameByServerName(field.value)}
                onChange={field.onChange}
                lists={textReplaceFromList}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        </WebTestStepTableBodyDataCell>
        <WebTestStepTableBodyDataCell>
          <Controller
            control={control}
            name={`optionsDto.${idx}.addKey` as const}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findTextReplaceAddNameByServerName(field.value)}
                onChange={field.onChange}
                lists={textReplaceAddList}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        </WebTestStepTableBodyDataCell>
        <WebTestStepTableBodyDataCell>
          <input
            type="number"
            className="input-base"
            {...register(`optionsDto.${idx}.addValue` as const, {
              valueAsNumber: true,
            })}
          />
        </WebTestStepTableBodyDataCell>
        <WebTestStepTableBodyDataCell>
          <input
            type="text"
            className="input-base"
            {...register(`optionsDto.${idx}.dateFormat` as const)}
          />
        </WebTestStepTableBodyDataCell>
      </tr>
      <ErrorMessage
        errors={errors}
        name={
          keyNameError
            ? `optionsDto.${idx}.keyName`
            : addValueError
              ? `optionsDto.${idx}.addValue`
              : dateFormatError
                ? `optionsDto.${idx}.dateFormat`
                : separatorError && `optionsDto.${idx}.separator`
        }
        render={({ message }) => (
          <tr>
            <td
              colSpan={tableColsLength}
              className="w-full whitespace-pre-wrap px-3 py-1.5 text-center text-sm text-red-500"
            >
              {message}
            </td>
          </tr>
        )}
      />
    </>
  );
};

export default VariableTableBody;
