import { findExtractTextFormatTypeByServerName } from '@utils/static/mobileTestStep/extractTextFormat/extractTextFormatTypeList';
import { getDisplayTextBySaveTextFrom } from '@utils/mapping/mobileTestStep/saveTextMapping';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import React from 'react';

import PropertyDetail from './PropertyDetail';

type Props = {
  list: MobileTestStepData;
};

const SaveTextList = ({ list }: Props) => {
  return (
    <>
      <PropertyDetail
        term={'From'}
        description={getDisplayTextBySaveTextFrom(list.optionsDto.from)}
      />
      {list.optionsDto.from === SaveTextFrom.Sms && (
        <PropertyDetail term={'Phone Number'} description={list.value} />
      )}
      <PropertyDetail
        term={'Extract Text Format'}
        description={list.optionsDto.textExtract === 'true' ? 'Y' : 'N'}
      />
      {list.optionsDto.textExtract === 'true' && (
        <>
          <PropertyDetail
            term={'Type / Length'}
            description={`${findExtractTextFormatTypeByServerName(list.optionsDto.textExtractOptions.type)} / ${list.optionsDto.textExtractOptions.length}`}
          />
          <PropertyDetail
            term={'Index'}
            description={list.optionsDto.textExtractOptions.index}
          />
        </>
      )}
    </>
  );
};

export default SaveTextList;
