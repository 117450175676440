import React from "react";
import { Outlet } from "react-router-dom";

import { CopyRight } from "@autosquare/common";

const MiddleAlignRoot = () => {
  return (
    <>
      <main className="flex flex-col justify-center items-center m-auto min-h-[calc(100vh-10rem)] h-auto text-sm">
        <Outlet />
      </main>
      <CopyRight />
    </>
  );
};

export default MiddleAlignRoot;
