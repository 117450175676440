import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import {
  ArrowPoint,
  CoordinateData,
  RectOrTwoPointsCoordinate,
  SourceProperties,
} from '@customTypes/testStep/type';

import {
  getPageSource,
  getSelectedCoordinate,
} from '@store/ide/mobileTestStep/pageSourcesSlice';
import {
  setDimensions,
  setImageDimensions,
} from '@store/ide/mobileTestStep/screenSlice';
import { addPoint } from '@store/ide/mobileTestStep/unlockPatternSlice';
import { clearUuid } from '@store/ide/mobileTestStep/uuidSlice';

import { ClickId } from '@utils/static/clickOptions';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const useScreenshotLogic = (
  getCoordinate: RectOrTwoPointsCoordinate[],
  coordinateInfo: SourceProperties[],
) => {
  const {
    startX,
    startY,
    endX,
    endY,
    imageWidth,
    imageHeight,
    isLandscapeMode,
    setIsListboxOpened,
    setIsPassListboxOpened,
    setIsFailListboxOpened,
  } = useContext(MobileTestStepContext);

  const { watch } = useFormContext<CreateTestStepData>();

  const dispatch = useDispatch();

  const command = watch('command');
  const clickBy = watch('optionsDto.clickBy');

  const [isRectSelected, setIsRectSelected] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [addRectWidthCenterPointArray, setAddRectWidthCenterPointArray] =
    useState<CoordinateData[]>([]);
  const [minLengthWidthArray, setMinLengthWidthArray] = useState<
    CoordinateData[]
  >([]);
  const [startNode, setStartNode] = useState<ArrowPoint>();
  const [endNode, setEndNode] = useState<ArrowPoint>();
  const [isSelected, setIsSelected] = useState(false);

  const imageRef = useRef<Konva.Image>(null);

  const divRef = useRef<HTMLDivElement>(null);

  const isMobileObjectNeeded =
    command !== MobileCommandOptionServerName.ScrollSwipe &&
    command !== MobileCommandOptionServerName.CompareImage &&
    clickBy !== ClickId.Coordinate &&
    clickBy !== ClickId.Image;

  useEffect(() => {
    if (divRef.current) {
      const divRefStyle = window?.getComputedStyle(divRef.current);
      const divWidth = divRefStyle?.getPropertyValue('width');
      const divHeight = divRefStyle?.getPropertyValue('height');
      setWidth(parseInt(divWidth?.slice(0, -2)) - 2);
      setHeight(parseInt(divHeight?.slice(0, -2)) - 2);
    }
  }, [isLandscapeMode]);

  const realScreenRatio =
    imageWidth > imageHeight && imageWidth !== 0
      ? width / imageWidth
      : imageHeight > imageWidth && imageHeight !== 0
        ? height / imageHeight
        : 1;

  useEffect(() => {
    dispatch(setDimensions({ width: width, height: height }));
    dispatch(
      setImageDimensions({ imageWidth: imageWidth, imageHeight: imageHeight }),
    );
  }, [dispatch, width, height, imageWidth, imageHeight]);

  const stageWidth = isLandscapeMode
    ? Math.floor(width)
    : Math.floor(imageWidth * realScreenRatio);
  const stageHeight = isLandscapeMode
    ? Math.floor(imageHeight * realScreenRatio)
    : Math.floor(height);

  useEffect(() => {
    setAddRectWidthCenterPointArray(
      getCoordinate?.map((item, index) => ({
        idx: index,
        x1: item?.x1 * realScreenRatio,
        x2: item?.x2 * realScreenRatio,
        y1: item?.y1 * realScreenRatio,
        y2: item?.y2 * realScreenRatio,
        x: Math.abs(
          (item?.x2 * realScreenRatio + item?.x1 * realScreenRatio) / 2,
        ),
        y: Math.abs(
          (item?.y2 * realScreenRatio + item?.y1 * realScreenRatio) / 2,
        ),
        width: Math.abs(
          (item?.x2 * realScreenRatio - item?.x1 * realScreenRatio) *
            (item?.y2 * realScreenRatio - item?.y1 * realScreenRatio),
        ),
      })),
    );
  }, [getCoordinate, realScreenRatio]);

  const calculateMinLengthWidth = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    const stageLocation = stage?.getPointerPosition();
    const clickX = Math.floor(stageLocation?.x);
    const clickY = Math.floor(stageLocation?.y);

    const clickedArray = [...addRectWidthCenterPointArray]?.filter(
      (item) =>
        clickX >= item?.x1 &&
        clickX <= item?.x2 &&
        clickY >= item?.y1 &&
        clickY <= item?.y2,
    );

    const minLength = Math.min(
      ...clickedArray.map((item) =>
        Math.sqrt(
          Math.pow(Math.abs(clickX - item?.x), 2) +
            Math.pow(Math.abs(clickY - item?.y), 2),
        ),
      ),
    );

    const selectedArray = [...clickedArray].filter(
      (item) =>
        minLength ===
        Math.sqrt(Math.pow(clickX - item?.x, 2) + Math.pow(clickY - item.y, 2)),
    );

    const minWidth = Math.min(...selectedArray.map((item) => item?.width));
    setMinLengthWidthArray(
      selectedArray.length >= 2
        ? selectedArray.filter((item) => item?.width === minWidth)
        : selectedArray,
    );
  };

  useEffect(() => {
    const sendClickedInfo = minLengthWidthArray
      ?.map((item) => item?.idx)
      ?.map((item) => coordinateInfo?.[item]);

    if (isMobileObjectNeeded) {
      dispatch(getPageSource(sendClickedInfo));
    }

    if (minLengthWidthArray.length > 0) {
      dispatch(
        getSelectedCoordinate(getCoordinate[minLengthWidthArray[0].idx]),
      );
    }
  }, [minLengthWidthArray]);

  const startPoint = {
    x: endX * realScreenRatio,
    y: endY * realScreenRatio,
    fill: 'blue',
    width: 20,
    height: 20,
    draggable: true,
  };

  const endPoint = {
    x: startX * realScreenRatio,
    y: startY * realScreenRatio,
    fill: 'blue',
    width: 20,
    height: 20,
    draggable: true,
  };

  useEffect(() => {
    setStartNode(startPoint);
    setEndNode(endPoint);
  }, [realScreenRatio, height]);

  useEffect(() => {
    setStartNode({
      ...startNode,
      x: endX * realScreenRatio,
      y: endY * realScreenRatio,
    });
    setEndNode({
      ...endNode,
      x: startX * realScreenRatio,
      y: startY * realScreenRatio,
    });
  }, [startX, startY, endX, endY]);

  const checkDeselect = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    if (e.target === imageRef.current) {
      setIsSelected(false);
    }
  };

  const handleClick = (e: KonvaEventObject<MouseEvent>) => {
    if (isRectSelected && isMobileObjectNeeded) {
      setIsRectSelected(false);
    }

    if (!isRectSelected && isMobileObjectNeeded) {
      calculateMinLengthWidth(e);
      setIsRectSelected(true);
    }
  };

  const handleMouseOver = (e: KonvaEventObject<MouseEvent>) => {
    if (!isRectSelected && isMobileObjectNeeded) {
      calculateMinLengthWidth(e);
    }
  };

  // Unlock Pattern
  const handlePoints = (e: KonvaEventObject<MouseEvent>) => {
    dispatch(
      addPoint({
        uuid: crypto.randomUUID(),
        pointsCoordinate: { x: e.evt.offsetX, y: e.evt.offsetY },
      }),
    );
    dispatch(clearUuid());
  };

  const openListbox = () => {
    setIsListboxOpened(true);
    setIsPassListboxOpened(true);
    setIsFailListboxOpened(true);
  };

  const stageOnClick = (e: KonvaEventObject<MouseEvent>) => {
    if (command === MobileCommandOptionServerName.UnlockPattern) {
      handlePoints(e);
    } else {
      handleClick(e);
      checkDeselect(e);
      openListbox();
    }
  };

  return {
    isSelected,
    setIsSelected,
    startNode,
    setStartNode,
    endNode,
    setEndNode,
    minLengthWidthArray,
    divRef,
    stageWidth,
    stageHeight,
    imageRef,
    checkDeselect,
    handleClick,
    handleMouseOver,
    openListbox,
    realScreenRatio,
    handlePoints,
    stageOnClick,
  };
};

export default useScreenshotLogic;
