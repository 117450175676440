import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';
import { CreateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorMessage } from '@hookform/error-message';
import { isMacOs } from '@utils/static/isMacOs';
import { clsx } from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const CreateSpeedTestSchedulerRepeat = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateSpeedSchedulerData>();

  return (
    <SpeedSchedulerGridContainer
      title="Repeat"
      isTooltip={true}
      tooltipClass={clsx(
        '-left-14 bottom-7 text-xs',
        isMacOs ? 'after:left-[10.5%]' : 'after:left-[9.2%]',
      )}
      tooltipWord={
        '속도 측정을 몇 번 반복할지 횟수를 입력해 주세요. (처음과 마지막 속도 측정을 제외한 나머지 값들의 평균이 결과로 저장 됩니다.)'
      }
    >
      <input
        type="number"
        className="input-base hide-input-number-arrow"
        {...register('repeatNum', { valueAsNumber: true })}
      />
      <ErrorMessage
        errors={errors}
        name={'repeatNum'}
        render={({ message }) => (
          <p className="standard-error-message">{message}</p>
        )}
      />
    </SpeedSchedulerGridContainer>
  );
};

export default CreateSpeedTestSchedulerRepeat;
