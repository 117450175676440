import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { DeviceSettingsContext } from "../../../../../contexts/ide/DeviceSettingsProvider";
import { InstallMandatoryApp } from "../../../../../types/setting/type";

interface ElectronFile extends File {
  path: string;
}

const FilePath = () => {
  const { setValue } = useFormContext<InstallMandatoryApp>();
  const {
    fileName,
    isInsideFile,
    setCheckFilenameExtension,
    setFileName,
    setIsInsideFile,
  } = useContext(DeviceSettingsContext);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const files = [...e.dataTransfer.files];

    if (files && files.length > 0) {
      const file = files ? (files[0] as ElectronFile) : undefined;

      if (file.name.includes(".ipa")) {
        setCheckFilenameExtension(false);
        setValue("app_path", file.path);
        setFileName(file.name);
      } else {
        setCheckFilenameExtension(true);
        setIsInsideFile(false);
      }
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsInsideFile(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsInsideFile(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files
      ? (e.target.files[0] as ElectronFile)
      : undefined;

    if (file?.name.includes(".ipa")) {
      setCheckFilenameExtension(false);
      setValue("app_path", file.path);
      setFileName(file.name);
    } else {
      setCheckFilenameExtension(true);
    }

    e.target.value = "";
  };

  return (
    <div
      className="flex flex-col justify-center items-center gap-y-6 h-40 mt-10 rounded-lg border border-dashed border-gray-900/25 text-sm"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {isInsideFile ? (
        <div className="flex flex-col justify-center items-center gap-y-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="mx-auto h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
            />
          </svg>
          <div>
            <label
              htmlFor="file-upload"
              className={`relative cursor-pointer rounded-md bg-white font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 hover:text-indigo-500 text-gray-900 focus-within::ring-gray-900`}
            >
              <span>{fileName === "" ? "Drop File" : fileName}</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                accept=".ipa"
                onChange={onChange}
              />
            </label>
          </div>
        </div>
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="mx-auto h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className={`relative cursor-pointer rounded-md bg-white font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 hover:text-indigo-500 ${
                fileName === ""
                  ? "text-indigo-600 focus-within:ring-indigo-600"
                  : "text-gray-900 focus-within::ring-gray-900"
              }}`}
            >
              <span>
                {fileName === "" ? "Select a intergration app" : fileName}
              </span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                accept=".ipa"
                onChange={onChange}
              />
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default FilePath;
