import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

import NoChildrenSidebarList from "./NoChildrenSidebarList";
import ProjectSidebarSubList from "./ProjectSidebarSubList";

import { ProjectListDetail } from "@customTypes/dashboard/project/type";
import { SidebarList } from "@customTypes/type";

type Props = {
  sidebarList: SidebarList[];
  projectList: ProjectListDetail;
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectSidebarUnderMonitorSize = ({
  sidebarList,
  projectList,
  setIsToggledSidebar,
}: Props) => {
  return (
    <div className="w-72 flex grow flex-col overflow-y-auto bg-white px-6 h-full inset-0">
      <div className="flex h-16 shrink-0 items-center justify-between">
        <span className="text-sm font-semibold">{projectList?.name}</span>
        <XMarkIcon
          className="block h-6 w-6 cursor-pointer"
          aria-hidden="true"
          onClick={() => setIsToggledSidebar(false)}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {sidebarList?.map((sidebar) => (
                <li key={sidebar.name}>
                  {!sidebar?.children ? (
                    <NoChildrenSidebarList
                      sidebarList={sidebar}
                      setIsToggledSidebar={setIsToggledSidebar}
                    />
                  ) : (
                    <ProjectSidebarSubList
                      sidebarList={sidebar}
                      setIsToggledSidebar={setIsToggledSidebar}
                    />
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProjectSidebarUnderMonitorSize;
