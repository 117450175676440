export type BrowserKeyListType = {
  id: number;
  name: string;
  serverName: string;
};

export enum BrowserKeyName {
  PageRefresh = 'Page Refresh',
  ClearBrowserCache = 'Clear Browser Cache',
}

export enum BrowserServerName {
  PageRefresh = 'page_refresh',
  ClearBrowserCache = 'clear_browser_cache',
}

export const browserKeyList: BrowserKeyListType[] = [
  {
    id: 1,
    name: BrowserKeyName.PageRefresh,
    serverName: BrowserServerName.PageRefresh,
  },
  {
    id: 2,
    name: BrowserKeyName.ClearBrowserCache,
    serverName: BrowserServerName.ClearBrowserCache,
  },
];

export const browserKeyNameList = browserKeyList.map(
  (browserKey) => browserKey.name,
);

export const findBrowserKeyServerNameFromName = (name: string) =>
  browserKeyList.find((browserKey) => browserKey.name === name)?.serverName;

export const findBrowserKeyNameFromServerName = (serverName: string) =>
  browserKeyList.find((browserKey) => browserKey.serverName === serverName)
    ?.name;
