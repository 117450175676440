import { Button, ErrorMessage, NoDevice } from '@autosquare/common';

import React, { useEffect, useState } from 'react';

import Device from './SelectedDevices/Device';

import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';
import { useMobileTestCaseDetailQuery } from '@lib/api/ide/mobile/mobileTestCase';

import { DeviceInfo, DeviceInfoOs } from '@customTypes/ide/device/device';
import DeviceSkeleton from './SelectedDevices/DeviceSkeleton';
import SelectedDevicesHeader from './SelectedDevices/SelectedDevicesHeader';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

interface Props {
  selectedDevices: DeviceInfo[];
  setSelectedDevices: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
  checkHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: DeviceInfo,
  ) => void;
  isSelectedDevices: boolean;
  setIsSelectedDevices: React.Dispatch<React.SetStateAction<boolean>>;
  divWidth: number;
  testType: TestTypeCategory;
  mobileOS: DeviceInfoOs;
  idx: number;
  needIosSetting: boolean;
  setNeedIosSetting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
}

const SelectedDevices = ({
  selectedDevices,
  setSelectedDevices,
  checkHandler,
  isSelectedDevices,
  setIsSelectedDevices,
  divWidth,
  testType,
  mobileOS,
  idx,
  needIosSetting,
  setNeedIosSetting,
  setIsSettingsOpen,
  setIsWirelessDeviceModal,
  setConnectionType,
}: Props) => {
  const [gridCols, setGridCols] = useState('grid-cols-3');

  useEffect(() => {
    if (divWidth > 1024) {
      setGridCols('grid-cols-4');
    } else {
      setGridCols('grid-cols-3');
    }
  }, [divWidth]);

  const { data: mobileTestCase } =
    testType === 'case' && useMobileTestCaseDetailQuery(idx.toString());

  const aosCount = mobileTestCase?.steps.aosStepCount;
  const iosCount = mobileTestCase?.steps.iosStepCount;

  const {
    data: deviceLists,
    isFetching,
    isLoading,
    error,
    isError,
    refetch,
    isSuccess,
  } = useDeviceListQuery();

  return (
    <>
      <div>
        <SelectedDevicesHeader
          selectedDevices={selectedDevices}
          isSuccess={isSuccess}
          isSelectedDevices={isSelectedDevices}
          setIsSelectedDevices={setIsSelectedDevices}
          setSelectedDevices={setSelectedDevices}
          refetch={refetch}
          deviceLists={deviceLists}
          testType={testType}
          mobileOS={mobileOS}
          aosCount={aosCount}
          iosCount={iosCount}
          setNeedIosSetting={setNeedIosSetting}
          setIsWirelessDeviceModal={setIsWirelessDeviceModal}
          setConnectionType={setConnectionType}
        />
        {isLoading || isFetching ? (
          <DeviceSkeleton gridCols={gridCols} />
        ) : isError ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : deviceLists?.length === 0 ? (
          <NoDevice />
        ) : needIosSetting ? (
          <div className="mt-3 flex w-full flex-col items-center justify-center gap-4 rounded-md border border-solid border-gray-200 bg-gray-50 p-2 text-base leading-5">
            <p className="text-red-500">Requires setup for iOS testing.</p>
            <Button
              type="button"
              variant="primary"
              onClick={() => setIsSettingsOpen(true)}
            >
              Open iOS Test Settings
            </Button>
          </div>
        ) : (
          <ul role="list" className={`my-3 grid gap-2 ${gridCols}`}>
            {deviceLists?.map((deviceList) => (
              <Device
                deviceList={deviceList}
                testType={testType}
                mobileOS={mobileOS}
                aosCount={aosCount}
                iosCount={iosCount}
                selectedDevices={selectedDevices}
                checkHandler={checkHandler}
                key={deviceList.udid}
              />
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SelectedDevices;
