import React from "react";

import ErrorComponents from "@components/shared/ErrorComponents";

const InternalServerError = () => {
  return (
    <ErrorComponents
      errorCode={500}
      errorTitle={"Internal Server Error"}
      errorMessage={[
        "일시적인 서버 에러가 발생했습니다.",
        "문제가 지속되면 관리자에게 문의해주세요.",
      ]}
    />
  );
};

export default InternalServerError;
