import { IDEAPI } from '@api/ideEndpoints';
import {
  CreateMobileWebSpeedTestStepData,
  MobileWebSpeedTestStepData,
  UpdateMobileWebSpeedTestStepData,
} from '@customTypes/ide/speedTest/Mobile/webTestStep/type';
import { mobileWebSpeedTestStepKeys } from '@queries/ide/speedMobile/mobileWebSpeedTestStepKeys';
import { speedMobileTestCaseKeys } from '@queries/ide/speedMobile/speedMobileTestCaseKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { axiosInstance } from '..';

// GET
const getMobileWebSpeedTestStep = async (
  idx: string,
): Promise<MobileWebSpeedTestStepData> => {
  const response = await axiosInstance.get<MobileWebSpeedTestStepData>(
    `${IDEAPI.MOBILE_WEB_SPEED_TEST_STEP}?case=${idx}`,
  );
  return response.data;
};
export const useMobileWebSpeedTestStepQuery = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<MobileWebSpeedTestStepData, Error>({
    queryKey: mobileWebSpeedTestStepKeys.detail(idx),
    queryFn: () => getMobileWebSpeedTestStep(idx),
  });
};

// POST
const createMobileWebSpeedTestStep = async (
  data: CreateMobileWebSpeedTestStepData,
): Promise<MobileWebSpeedTestStepData> => {
  const response = await axiosInstance.post<MobileWebSpeedTestStepData>(
    `${IDEAPI.MOBILE_WEB_SPEED_TEST_STEP}`,
    data,
  );
  return response.data;
};
export const useCreateMobileWebSpeedTestStepMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    MobileWebSpeedTestStepData,
    Error,
    CreateMobileWebSpeedTestStepData
  >({
    mutationFn: (data) => createMobileWebSpeedTestStep(data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileWebSpeedTestStepKeys.all);
      queryClient.invalidateQueries(speedMobileTestCaseKeys.lists());
    },
  });
};

// PUT
const updateMobileWebSpeedTestStep = async (
  idx: number,
  data: UpdateMobileWebSpeedTestStepData,
): Promise<MobileWebSpeedTestStepData> => {
  const response = await axiosInstance.put<MobileWebSpeedTestStepData>(
    `${IDEAPI.MOBILE_WEB_SPEED_TEST_STEP}/${idx}`,
    data,
  );
  return response.data;
};
export const useUpdateMobileWebSpeedTestStepMutation = (idx: number) => {
  const queryClient = useQueryClient();

  return useMutation<
    MobileWebSpeedTestStepData,
    Error,
    UpdateMobileWebSpeedTestStepData
  >({
    mutationFn: (data) => updateMobileWebSpeedTestStep(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileWebSpeedTestStepKeys.all);
      queryClient.invalidateQueries(speedMobileTestCaseKeys.lists());
    },
  });
};

// DELETE
const deleteMobileWebSpeedTestStep = async (idx: number): Promise<string> => {
  const response = await axiosInstance.delete<string>(
    `${IDEAPI.MOBILE_WEB_SPEED_TEST_STEP}/${idx}`,
  );
  return response.data;
};
export const useDeleteMobileWebSpeedTestStepMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<string, Error, number>({
    mutationFn: (idx) => deleteMobileWebSpeedTestStep(idx),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileWebSpeedTestStepKeys.all);
      queryClient.invalidateQueries(speedMobileTestCaseKeys.lists());
    },
  });
};
