import {
  DefaultSkeleton,
  ErrorMessage,
  PagingHeadings,
  TableSkeleton,
} from '@autosquare/common';

import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useWebTestResultListQuery } from '@lib/api/dashboard/web/webTestResult';

import WebTestResultTable from './components/WebTestResultTable';
import WebTestResultPagination from './components/WebTestResultPagination';

const WebTestResult = () => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page'));

  const limit = 10;

  const webTestResultQuery = useWebTestResultListQuery(
    projectIdx,
    currentPage,
    limit,
  );

  const webTestResult = webTestResultQuery.data?.content;

  useEffect(() => {
    if (!currentPage) {
      navigate('?page=1');
    }
  }, [currentPage]);

  const currentPageLink = `/projects/${projectIdx}/web/test-result?page=${currentPage}`;
  const breadcrumbs = [
    {
      title: 'Web',
      href: currentPageLink,
    },
    {
      title: 'Test Result',
      href: currentPageLink,
    },
    {
      title: 'List',
      href: currentPageLink,
    },
  ];

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      <div className="px-0">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {webTestResultQuery.isLoading ? (
                <TableSkeleton />
              ) : webTestResultQuery.isError ? (
                <ErrorMessage>{webTestResultQuery.error.message}</ErrorMessage>
              ) : (
                <WebTestResultTable
                  webTestResult={webTestResult}
                  page={currentPage}
                />
              )}
            </div>
          </div>
        </div>
        {webTestResultQuery.isLoading ? (
          <DefaultSkeleton number={1} />
        ) : (
          webTestResult?.length !== 0 && (
            <WebTestResultPagination
              page={currentPage}
              total={webTestResultQuery.data?.totalElements}
              limit={limit}
            />
          )
        )}
      </div>
    </>
  );
};

export default WebTestResult;
