import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { axiosInstance } from '..';

import { IDEAPI } from '@api/ideEndpoints';
import { mobileTestStepKeys } from '@queries/ide/mobile/mobileTestStepKeys';
import {
  CreateTestStepData,
  MobileTestStepByCase,
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import { mobileTestCaseKeys } from '@queries/ide/mobile/mobileTestCaseKeys';
import { MobileCommandOptionApiName } from '@utils/static/mobileCommandOption';

export interface UseDeleteTestStepMutation {
  idxList: number[];
}

// GET
const mobileTestStep = async (idx: number): Promise<MobileTestStepData> => {
  const response = await axiosInstance.get<MobileTestStepData>(
    IDEAPI.GET_EACH_MOBILE_TEST_STEP + idx,
  );
  return response.data;
};
export const useMobileTestStepQuery = (
  idx: number,
  options?: UseQueryOptions<MobileTestStepData, Error>,
) =>
  useQuery<MobileTestStepData, Error>({
    queryKey: mobileTestStepKeys.detail(idx),
    queryFn: () => mobileTestStep(idx),
    ...options,
  });

const getMobileTestStepByCase = async (
  idx: string,
): Promise<MobileTestStepByCase> => {
  const response = await axiosInstance.get<MobileTestStepByCase>(
    IDEAPI.GET_MOBILE_TEST_STEP_IN_TEST_CASE + idx,
  );
  return response.data;
};
export const useMobileTestStepByCaseQuery = (idx: string) =>
  useQuery<MobileTestStepByCase, Error>({
    queryKey: mobileTestStepKeys.case(idx),
    queryFn: () => getMobileTestStepByCase(idx),
  });

const mobileTestStepBySaveText = async (
  idx: number,
): Promise<MobileTestStepData[]> => {
  const response = await axiosInstance.get<MobileTestStepData[]>(
    `${IDEAPI.getMobileTestStepsByCommand(
      MobileCommandOptionApiName.SaveText,
      idx,
    )}`,
  );
  return response.data;
};
export const useMobileTestStepBySaveTextQuery = (idx: number) =>
  useQuery<MobileTestStepData[], Error>({
    queryKey: mobileTestStepKeys.command(
      MobileCommandOptionApiName.SaveText,
      idx,
    ),
    queryFn: () => mobileTestStepBySaveText(idx),
  });

// POST
const createMobileTestStep = async (
  idx: string,
  data: CreateTestStepData,
): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    IDEAPI.CREATE_MOBILE_TEST_STEP + idx,
    data,
  );
  return response.data;
};
export const useCreateMobileTestStepMutation = (idx: string) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, CreateTestStepData>({
    mutationFn: (data) => createMobileTestStep(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestStepKeys.all);
    },
  });
};

// PUT
const updateTestStep = async (
  idx: number,
  data: UpdateTestStepData,
): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.UPDATE_MOBILE_TEST_STEP + idx,
    data,
  );
  return response.data;
};
export const useUpdateTestStepMutation = (idx: number) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, UpdateTestStepData>({
    mutationFn: (data) => updateTestStep(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestStepKeys.all);
    },
  });
};

const deleteTestStep = async (
  idx: string,
  idxList: number[],
): Promise<UseDeleteTestStepMutation> => {
  const response = await axiosInstance.delete<UseDeleteTestStepMutation>(
    IDEAPI.DELETE_MOBILE_TEST_STEP + idx,
    {
      data: {
        idx_list: idxList,
      },
    },
  );
  return response.data;
};
export const useDeleteTestStepMutation = (idx: string, idxList: number[]) => {
  const queryClient = useQueryClient();

  return useMutation<UseDeleteTestStepMutation, Error>({
    mutationFn: () => deleteTestStep(idx, idxList),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestStepKeys.cases());
      queryClient.invalidateQueries(mobileTestCaseKeys.lists());
    },
  });
};
