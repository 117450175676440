import BrowserListBox from '@components/Scheduler/BrowserListBox';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { BrowserInfo } from '@customTypes/ide/browser/browser';
import {
  CreateSchedulerData,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import { ErrorMessage } from '@hookform/error-message';
import { useWebBrowserListQuery } from '@lib/api/ide/web/webBrowser';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const CreateSchedulerBrowser = () => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSchedulerData>();
  const [browserInfoDto, setBrowserInfoDto] = useState<BrowserInfo>(undefined);

  const testType = watch('type');
  const webBrowserListQuery = useWebBrowserListQuery({
    enabled: testType === SchedulerTypeServerName.Web,
  });

  useEffect(() => {
    if (testType === SchedulerTypeServerName.Mobile) {
      setValue('browser_info_dto', undefined);
      setBrowserInfoDto(undefined);
    }

    return () => {
      setValue('browser_info_dto', undefined);
    };
  }, [testType]);

  // 브라우저 정보 저장
  const handleChange = (selectedBrowser: BrowserInfo) => {
    setBrowserInfoDto(selectedBrowser);
    setValue('browser_info_dto', selectedBrowser);
  };

  // browser error 초기화
  useEffect(() => {
    if (browserInfoDto) {
      clearErrors('browser_info_dto');
    }
  }, [clearErrors, browserInfoDto]);
  return (
    testType === SchedulerTypeServerName.Web && (
      <SchedulerGridContainer
        title="Browser"
        isTitleCenter={testType === SchedulerTypeServerName.Web}
        classNames="flex gap-x-4"
      >
        <BrowserListBox
          browserInfoDto={browserInfoDto}
          handleChange={handleChange}
          webBrowserListQuery={webBrowserListQuery}
        />
        <ErrorMessage
          errors={errors}
          name={'browser_info_dto'}
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </SchedulerGridContainer>
    )
  );
};

export default CreateSchedulerBrowser;
