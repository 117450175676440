import React from 'react';

const BrowserCardSkeleton = () => {
  return (
    <li className={'flex animate-pulse rounded-md shadow-sm'}>
      <div className="flex w-full rounded-md shadow-sm">
        <div className="flex w-16 shrink-0 items-center justify-center gap-2 rounded-l-md border-y border-l border-gray-200">
          <div
            className={
              'size-4 rounded border border-solid border-gray-300 bg-white'
            }
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
          >
            <path
              fillRule="evenodd"
              d="M2.25 6a3 3 0 013-3h13.5a3 3 0 013 3v12a3 3 0 01-3 3H5.25a3 3 0 01-3-3V6zm18 3H3.75v9a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V9zm-15-3.75A.75.75 0 004.5 6v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75H5.25zm1.5.75a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V6zm3-.75A.75.75 0 009 6v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75H9.75z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div
          className={
            'flex flex-1 items-center justify-between rounded-r-md border-y border-r border-gray-200 bg-white'
          }
        >
          <div className="flex-1 py-2">
            <div className="mb-1.5 h-4 w-12 rounded bg-gray-200 text-gray-500 dark:bg-gray-700" />
            <div className="h-4 w-20 rounded bg-gray-200 text-gray-500 dark:bg-gray-700" />
          </div>
        </div>
      </div>
    </li>
  );
};

export default BrowserCardSkeleton;
