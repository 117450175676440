import { RootState } from '@app/store';

import { ErrorMessage } from '@autosquare/common';

import { useUserInfoQuery } from '@lib/api/ide/user/user';

import React from 'react';
import { useSelector } from 'react-redux';

const SidebarInHelp = () => {
  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  const userInfoQuery = useUserInfoQuery({
    enabled: accessToken !== null,
  });
  const userInfo = userInfoQuery.data;

  return (
    <aside className="min-w-60 border-r border-gray-200 px-6 text-sm leading-6">
      <div className="flex w-full flex-col gap-2 px-2 py-8">
        <button
          type="button"
          className="flex w-full items-center justify-start rounded-md bg-gray-50 p-2"
        >
          Information
        </button>
        <button
          type="button"
          className={`flex w-full items-center justify-between rounded-md p-2 hover:bg-gray-50`}
          onClick={() => window.electron.send('openExternalPage', 'docs/')}
        >
          <p>Document</p>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.25 5.5C4.05109 5.5 3.86032 5.57902 3.71967 5.71967C3.57902 5.86032 3.5 6.05109 3.5 6.25V14.75C3.5 15.164 3.836 15.5 4.25 15.5H12.75C12.9489 15.5 13.1397 15.421 13.2803 15.2803C13.421 15.1397 13.5 14.9489 13.5 14.75V10.75C13.5 10.5511 13.579 10.3603 13.7197 10.2197C13.8603 10.079 14.0511 10 14.25 10C14.4489 10 14.6397 10.079 14.7803 10.2197C14.921 10.3603 15 10.5511 15 10.75V14.75C15 15.3467 14.7629 15.919 14.341 16.341C13.919 16.7629 13.3467 17 12.75 17H4.25C3.65326 17 3.08097 16.7629 2.65901 16.341C2.23705 15.919 2 15.3467 2 14.75V6.25C2 5.65326 2.23705 5.08097 2.65901 4.65901C3.08097 4.23705 3.65326 4 4.25 4H9.25C9.44891 4 9.63968 4.07902 9.78033 4.21967C9.92098 4.36032 10 4.55109 10 4.75C10 4.94891 9.92098 5.13968 9.78033 5.28033C9.63968 5.42098 9.44891 5.5 9.25 5.5H4.25Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.194 12.753C6.26009 12.8261 6.33994 12.8855 6.429 12.9278C6.51806 12.97 6.61457 12.9943 6.71302 12.9992C6.81146 13.0041 6.90992 12.9896 7.00274 12.9565C7.09557 12.9233 7.18095 12.8722 7.254 12.806L16.5 4.44V7.25C16.5 7.44891 16.579 7.63968 16.7197 7.78033C16.8603 7.92098 17.0511 8 17.25 8C17.4489 8 17.6397 7.92098 17.7803 7.78033C17.921 7.63968 18 7.44891 18 7.25V2.75C18 2.55109 17.921 2.36032 17.7803 2.21967C17.6397 2.07902 17.4489 2 17.25 2H12.75C12.5511 2 12.3603 2.07902 12.2197 2.21967C12.079 2.36032 12 2.55109 12 2.75C12 2.94891 12.079 3.13968 12.2197 3.28033C12.3603 3.42098 12.5511 3.5 12.75 3.5H15.303L6.247 11.694C6.17387 11.7601 6.11448 11.8399 6.07223 11.929C6.02998 12.0181 6.0057 12.1146 6.00078 12.213C5.99585 12.3115 6.01039 12.4099 6.04354 12.5027C6.0767 12.5956 6.12782 12.6799 6.194 12.753Z"
                fill="black"
              />
            </svg>
          </div>
        </button>
      </div>
      <div role="list">
        {userInfoQuery.isLoading || userInfoQuery.isFetching ? (
          <div className="ml-5 p-5">
            <div className="h-5 w-32 animate-pulse rounded bg-gray-300" />
          </div>
        ) : userInfoQuery.isError ? (
          <ErrorMessage>{userInfoQuery.error.message}</ErrorMessage>
        ) : (
          <div className="ml-5 p-5 text-sm font-semibold text-gray-700">
            {userInfo.name}
          </div>
        )}
      </div>
    </aside>
  );
};

export default SidebarInHelp;
