import {
  TableBodyBasic,
  TableBodyBasicNoData,
  TableHeader,
  TableRow,
} from '@autosquare/common';

import { WebResult } from '@customTypes/testResult/type';

import React from 'react';
import { Link, useParams } from 'react-router-dom';

type Props = {
  webTestResult: WebResult[];
  page: number;
};

const WebTestResultTable = ({ webTestResult, page }: Props) => {
  const { projectIdx } = useParams();

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          {tableHeaders.map((tableHeader) => (
            <TableHeader key={tableHeader}>{tableHeader}</TableHeader>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {webTestResult?.length === 0 ? (
          <TableBodyBasicNoData colSpan={tableHeaders?.length}>
            진행된 테스트 결과가 없습니다.
          </TableBodyBasicNoData>
        ) : (
          webTestResult?.map((webTest, idx) => (
            <TableRow key={webTest.idx}>
              <TableBodyBasic>{(page - 1) * 10 + idx + 1}</TableBodyBasic>
              <TableBodyBasic>{webTest.type}</TableBodyBasic>
              <TableBodyBasic>{webTest.scenarioName}</TableBodyBasic>
              <TableBodyBasic>{webTest.startTime}</TableBodyBasic>
              <TableBodyBasic>
                {webTest.browserName[0].toUpperCase() +
                  webTest.browserName.slice(1)}
              </TableBodyBasic>
              <TableBodyBasic>{webTest.result}</TableBodyBasic>
              <TableBodyBasic>
                {webTest.result !== 'Running' ? (
                  <Link
                    to={`/projects/${projectIdx}/web/test-result/detail?idx=${webTest.idx}`}
                    className="rounded-md shadow-sm hover:text-congress-blue"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                      />
                    </svg>
                  </Link>
                ) : (
                  <div className="w-6 h-6" />
                )}
              </TableBodyBasic>
            </TableRow>
          ))
        )}
      </tbody>
    </table>
  );
};

export default WebTestResultTable;

export const tableHeaders = [
  'No',
  'Type',
  'Title',
  'DateTime',
  'Browser',
  'Result',
  'Detail',
];
