import { RootState } from '@app/store';
import { UpdateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { parseCronCycle } from '@utils/static/parseCronCycle';
import { days } from '@utils/static/selectedDays';
import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const SpeedSchedulerDetailDays = () => {
  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const { watch, setValue } = useFormContext<UpdateSpeedSchedulerData>();
  const cronCycle = watch('cronCycle');
  const initialSelected =
    parseCronCycle(cronCycle).daysOfWeek === '?'
      ? days.join(',')
      : parseCronCycle(cronCycle).daysOfWeek;
  const [selectedDays, setSelectedDays] = useState<string>(initialSelected);

  let newDayParts = selectedDays.split(',');
  const parsedCycle = parseCronCycle(cronCycle);
  const handleDaysSelector = (day: string, isChecked: boolean) => {
    if (isChecked) {
      if (!selectedDays.includes(day)) {
        newDayParts.push(day);
      }
    } else {
      newDayParts = selectedDays.split(',').filter((select) => select !== day);
    }
    setSelectedDays(() => newDayParts.join(','));
    let middle: string;
    if (newDayParts.length === 7) {
      middle = '* *';
    } else {
      middle = '? *';
    }
    const newCronCycle = `${parsedCycle.baseParts.join(' ')} ${middle} ${
      newDayParts.length === days.length
        ? '?'
        : newDayParts.filter((item) => item !== '').join(',')
    }`;
    setValue('cronCycle', newCronCycle);
  };

  return (
    <div className="col-span-2">
      <label
        htmlFor="days"
        className="mb-2 block text-sm font-medium leading-6 text-gray-900"
      >
        Days
      </label>
      <div className="mr-3 mt-4 flex flex-wrap gap-x-3 gap-y-2">
        {days.map((day) => (
          <Fragment key={day}>
            <div className="flex items-center">
              <input
                id={`day-${day}`}
                name="selDays"
                type="checkbox"
                className={clsx(
                  'input-checkbox-congress-blue border-gray-30 size-4 cursor-pointer rounded border border-solid',
                  !isRunning && 'bg-gray-100 text-gray-100',
                )}
                checked={selectedDays.includes(day)}
                onChange={(e) => handleDaysSelector(day, e.target.checked)}
                disabled={!isRunning}
              />
            </div>
            <div className="text-sm leading-6">
              <label
                htmlFor={`day-${day}`}
                className="cursor-pointer font-medium text-gray-900"
              >
                {day}
              </label>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default SpeedSchedulerDetailDays;
