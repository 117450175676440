import {
  CreateMobileSpeedTestScenarioData,
  CreateWebSpeedTestScenarioData,
  UpdateMobileSpeedTestScenarioData,
  UpdateWebSpeedTestScenarioData,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { SpeedTestCaseDataInScenario } from '@customTypes/speedTestCase/type';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import { useCallback, useEffect } from 'react';
import update from 'react-addons-update';
import { useDrop } from 'react-dnd';
import { UseFormSetValue } from 'react-hook-form';

export const useSpeedTestScenarioListDrop = (
  setValue: UseFormSetValue<
    | CreateMobileSpeedTestScenarioData
    | UpdateMobileSpeedTestScenarioData
    | CreateWebSpeedTestScenarioData
    | UpdateWebSpeedTestScenarioData
  >,
  copiedTestCaseList: SpeedTestCaseDataInScenario[],
  setCopiedTestCaseList: React.Dispatch<
    React.SetStateAction<SpeedTestCaseDataInScenario[]>
  >,
  changeCardList: (list: SpeedTestCaseDataInScenario[]) => void,
) => {
  useEffect(() => {
    isWordInLocationPathname('mobile')
      ? setValue(
          'steps',
          [...copiedTestCaseList]?.map((list) => list.idx),
        )
      : isWordInLocationPathname('web') &&
        setValue(
          'testCaseIdxs',
          [...copiedTestCaseList]?.map((list) => list.idx),
        );
  }, [copiedTestCaseList]);

  const [, drop] = useDrop({
    accept: 'card',
  });

  const onRemove = useCallback((index: number) => {
    setCopiedTestCaseList((prev) =>
      update(prev, {
        $splice: [[index, 1]],
      }),
    );
  }, []);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === undefined) {
        const lessIndex = copiedTestCaseList.findIndex(
          (item) => item.dndIndex === -1,
        );
        changeCardList(
          update(copiedTestCaseList, {
            $splice: [
              [lessIndex, 1],
              [
                hoverIndex,
                0,
                { title: 'Here', dndIndex: -1, uniqueId: crypto.randomUUID() },
              ],
            ],
          }),
        );
      } else {
        const dragCard = copiedTestCaseList[dragIndex];
        changeCardList(
          update(copiedTestCaseList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        );
      }
    },
    [copiedTestCaseList],
  );

  return { drop, onRemove, moveCard };
};
