import {
  Coordinate,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { UseFormSetError, UseFormSetFocus } from 'react-hook-form';

export const checkCoordinateRange = (
  screenSize: string,
  value: Coordinate[],
  setError: UseFormSetError<UpdateTestStepData>,
  setFocus: UseFormSetFocus<UpdateTestStepData>,
) => {
  const [xRange, yRange] = screenSize.split('x').map(Number);

  const outOfRangeIndex = value.findIndex(
    (item) => Number(item.x) > xRange || Number(item.y) > yRange,
  );

  if (outOfRangeIndex > -1) {
    setError('parsedValue', {
      type: 'range',
      message: '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요.',
    });
    if (Number(value[outOfRangeIndex].x) > xRange) {
      setFocus(`parsedValue.${outOfRangeIndex}.x`);
    } else {
      setFocus(`parsedValue.${outOfRangeIndex}.y`);
    }
    return true;
  }

  return false;
};

export const checkIsOutOfRange = (value: Coordinate[], screenSize: string) => {
  const [xRange, yRange] = screenSize.split('x').map(Number);
  return value?.some(
    (input) => Number(input.x) > xRange || Number(input.y) > yRange,
  );
};

export const checkEmptyInput = (
  value: Coordinate[],
  setError: UseFormSetError<UpdateTestStepData>,
  setFocus: UseFormSetFocus<UpdateTestStepData>,
) => {
  const emptyIndex = value.findIndex(
    (input) =>
      input.x === null ||
      input.y === null ||
      input.x === '' ||
      input.y === '' ||
      isNaN(Number(input.x)) ||
      isNaN(Number(input.y)),
  );
  const emptyValue = value.find(
    (input) =>
      input.x === null ||
      input.y === null ||
      input.x === '' ||
      input.y === '' ||
      isNaN(Number(input.x)) ||
      isNaN(Number(input.y)),
  );
  setError('parsedValue', {
    type: 'empty',
    message: 'Points 좌표 위치를 모두 입력해주세요.',
  });
  if (Number(emptyValue.x) > 0) {
    setFocus(`parsedValue.${emptyIndex}.y`);
  }
  if (Number(emptyValue.y) > 0) {
    setFocus(`parsedValue.${emptyIndex}.x`);
  }
};

export const checkIsEmptyInput = (value: Coordinate[]) =>
  value?.some(
    (input) =>
      input.x === null ||
      input.y === null ||
      input.x === '' ||
      input.y === '' ||
      isNaN(Number(input.x)) ||
      isNaN(Number(input.y)),
  );
