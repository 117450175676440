import {
  ArrowSpinner,
  Breadcrumbs,
  Button,
  DoubleCheckDialogHeadlessUI,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useExportTestCaseMutation,
  useMobileTestCaseDataExportMutation,
} from '@lib/api/ide/mobile/mobileTestCase';

import MobileTestCaseExportTable from './components/MobileTestCaseExportTable';

import { useProjectDetailQuery } from '@lib/api/ide/project/project';

const MobileTestCaseExport = () => {
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();

  const [selectedTestCase, setSelectedTestCase] = useState<number[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [isOpenedCautionDialog, setIsOpenedCautionDialog] = useState(false);
  const [isSavedData, setIsSavedData] = useState(false);

  const openDialog = () => {
    setIsOpenedCautionDialog(true);
  };

  const closeDialog = () => {
    setIsOpenedCautionDialog(false);
  };

  const projectDetail = useProjectDetailQuery(projectIdx);
  const projectName = projectDetail.data?.name;

  const mobileTestCaseDataExportData = {
    exportCaseIdx: selectedTestCase.sort((a, b) => a - b),
    projectIdx: Number(projectIdx),
  };

  const mobileTestCaseDataExportMutation =
    useMobileTestCaseDataExportMutation();

  const mobileTestCaseExportMutation = useExportTestCaseMutation();

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -3).join('/')}/overview`,
    },
    {
      title: 'Mobile',
      href: `/ide/projects/${projectIdx}/ui-test/mobile/test-case`,
    },
    {
      title: 'Test Case',
      href: `/ide/projects/${projectIdx}/ui-test/mobile/test-case`,
    },
    { title: 'Export', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Export',
      variant: 'primary',
      type: 'button',
      onClick: () => {
        if (selectedTestCase.length === 0) {
          setIsSavedData(false);
          setErrorMessage('테스트 케이스를 선택해주세요.');
          openDialog();
        } else {
          setErrorMessage(null);
          mobileTestCaseDataExportMutation.mutate(
            mobileTestCaseDataExportData,
            {
              onSuccess: async (exportData) => {
                const filePath =
                  await window.electron.invokeExportMobileTestCase(projectName);
                if (filePath) {
                  mobileTestCaseExportMutation.mutate(
                    {
                      path: filePath,
                      exportData: exportData,
                    },
                    {
                      onSuccess: (response) => {
                        setIsSavedData(response.saved);
                        openDialog();
                      },
                      onError: () => {
                        openDialog();
                      },
                    },
                  );
                }
              },
              onError: () => {
                openDialog();
              },
            },
          );
        }
      },
    },
  ];

  return (
    <>
      {(mobileTestCaseDataExportMutation.isLoading ||
        mobileTestCaseExportMutation.isLoading) && <ArrowSpinner />}
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case Export'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <MobileTestCaseExportTable
        selectedTestCase={selectedTestCase}
        setSelectedTestCase={setSelectedTestCase}
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type={isSavedData ? 'checked' : 'caution'}
        title={isSavedData ? 'Export Success' : 'Export Failure'}
        subTitle={
          selectedTestCase.length === 0
            ? errorMessage
            : isSavedData
              ? '테스트 케이스 Export가 완료되었습니다.'
              : mobileTestCaseDataExportMutation.isError
                ? `서버 에러 ${mobileTestCaseDataExportMutation.error.message}`
                : mobileTestCaseExportMutation.isError
                  ? `서버 에러 ${mobileTestCaseExportMutation.error.message}`
                  : !isSavedData && '테스트 케이스 Export가 실패했습니다.'
        }
        buttonChildren={
          <Button type="button" onClick={closeDialog} variant={'primary'}>
            Close
          </Button>
        }
      />
    </>
  );
};

export default MobileTestCaseExport;
