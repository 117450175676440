import { SubHeaderTitle } from '@autosquare/common';
import React from 'react';

const SpeedTestScenaioDetailSkeleton = () => {
  return (
    <>
      <div className="mt-8 pb-4">
        <SubHeaderTitle
          subTitle={'Test Scenario Information'}
          description={'테스트 시나리오 정보를 입력해주세요.'}
        />
        <div className="mt-4 grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium leading-normal text-gray-900">
              Title*
            </label>
            <div className="input-base h-9">
              <div className="h-full w-44 animate-pulse rounded bg-gray-200" />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium leading-normal text-gray-900">
              Description
            </label>
            <div className="input-base h-9">
              <div className="h-full w-44 animate-pulse rounded bg-gray-200" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpeedTestScenaioDetailSkeleton;
