import {
  DefaultSkeleton,
  ErrorMessage,
  Pagination,
  PagingHeadingsInAutosquare,
  SpeedTestResultTableSkeleton,
} from '@autosquare/common';
import { useIdeWebSpeedTestResultListQuery } from '@lib/api/ide/speedWeb/webSpeedTestResult';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import WebSpeedTestResultList from '@components/shared/WebSpeedTestResultList/WebSpeedTestResultList';

const IdeWebSpeedTestResult = () => {
  const PAGE_LIMIT = 5;

  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));

  const webSpeedTestResultListQuery = useIdeWebSpeedTestResultListQuery(page);

  const webSpeedTestResultList = webSpeedTestResultListQuery.data;
  const { pathname, search } = useLocation();

  const currentUrl = pathname + search;

  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Web', href: currentUrl },
    { title: 'Test Result', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      {webSpeedTestResultListQuery.isLoading ? (
        <SpeedTestResultTableSkeleton />
      ) : webSpeedTestResultListQuery.isError ? (
        <ErrorMessage>{webSpeedTestResultListQuery.error.message}</ErrorMessage>
      ) : webSpeedTestResultList?.totalCount === 0 ? (
        <p className="py-8 text-center text-sm font-normal leading-tight text-gray-900">
          진행된 테스트 결과가 없습니다.
        </p>
      ) : (
        <WebSpeedTestResultList
          webSpeedTestResultList={webSpeedTestResultList}
        />
      )}
      {webSpeedTestResultListQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        webSpeedTestResultList?.totalCount > 5 && (
          <Pagination
            page={page}
            total={webSpeedTestResultList?.totalCount}
            size={PAGE_LIMIT}
            isDashboard={false}
          />
        )
      )}
    </>
  );
};

export default IdeWebSpeedTestResult;
