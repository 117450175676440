import clsx from 'clsx';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {
  subItem: {
    name: string;
    href: string;
    base: string;
  };
}

const SidebarChildren = ({ subItem }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    if (subItem.name === 'Logout') {
      window.electron.send('logout');
    } else {
      navigate(subItem.href);
    }
  };
  return (
    <li>
      <button
        type="button"
        onClick={handleNavigate}
        className={clsx(
          'flex w-full cursor-pointer rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700',
          location.pathname.includes(subItem.base)
            ? 'bg-gray-50'
            : 'hover:bg-gray-50',
        )}
      >
        {subItem.name}
      </button>
    </li>
  );
};

export default SidebarChildren;
