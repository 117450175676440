import React, { Fragment } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";

import { memberRole } from "../../utils/static/memberRole";

type Props = {
  selectedRole: string;
  setSelectedRole: React.Dispatch<React.SetStateAction<string>>;
  isActivated: boolean | null;
};

const SelectRoleListBox = ({
  selectedRole,
  setSelectedRole,
  isActivated,
}: Props) => {
  return (
    <Listbox
      value={selectedRole}
      onChange={setSelectedRole}
      disabled={!isActivated || isActivated === null}
    >
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={`relative w-full cursor-default rounded-md  py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                !isActivated ? " bg-gray-100" : "bg-white"
              }`}
            >
              <span className="block truncate">{selectedRole}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {memberRole?.slice(1).map((role) => (
                  <Listbox.Option
                    key={role.idx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-3 pr-9 ${
                        active ? "bg-congress-blue text-white" : "text-gray-900"
                      }`
                    }
                    value={role.name}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-semibold" : "font-normal"
                          }`}
                        >
                          {role.name}
                        </span>
                        {selected && (
                          <span
                            className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                              active ? "text-white" : "text-congress-blue"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SelectRoleListBox;
