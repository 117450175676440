export enum ClickId {
  Default = 'default',
  Coordinate = 'coordinate',
  Image = 'image',
}

export enum ClickLabel {
  Default = '객체',
  Coordinate = '좌표',
  Image = '이미지 비교',
}

export type ClickOptionsType = {
  idx: number;
  id: ClickId;
  labelTitle: ClickLabel;
  defaultChecked: boolean;
};

export const clickOptions: ClickOptionsType[] = [
  {
    idx: 1,
    id: ClickId.Default,
    labelTitle: ClickLabel.Default,
    defaultChecked: true,
  },
  {
    idx: 2,
    id: ClickId.Coordinate,
    labelTitle: ClickLabel.Coordinate,
    defaultChecked: false,
  },
  {
    idx: 3,
    id: ClickId.Image,
    labelTitle: ClickLabel.Image,
    defaultChecked: false,
  },
];
