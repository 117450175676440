import React, { useState } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';

interface Props {
  imageValue: string;
}

const TestStepListValue = ({ imageValue }: Props) => {
  const [imageWidth, setImageWidth] = useState<number>(null);
  const [imageHeight, setImageHeight] = useState<number>(null);

  // Mobile Image konva
  const MobileImage = () => {
    const [image] = useImage(`data:image/png;base64,${imageValue}`);

    // eslint-disable-next-line prefer-const
    let imageObj = new window.Image();
    imageObj.src = `data:image/png;base64,${imageValue}`;
    setImageWidth(imageObj?.width / 4);
    setImageHeight(imageObj?.height / 4);

    return <Image image={image} width={imageWidth} height={imageHeight} />;
  };

  return (
    <div className="flex items-center">
      <div className="border border-solid border-border-line">
        <Stage width={imageWidth} height={imageHeight}>
          <Layer>
            <MobileImage />
          </Layer>
        </Stage>
      </div>
    </div>
  );
};

export default TestStepListValue;
