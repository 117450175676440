import React from "react";

type Props = {
  spanRef: React.MutableRefObject<any>;
  signUpLink: string;
  handleCopy: () => Promise<void>;
};

const SuccessToSendEmail = ({ spanRef, signUpLink, handleCopy }: Props) => {
  return (
    <>
      <p className="mt-6 text-sm text-gray-500">
        사용자 이메일 주소로 가입 링크가 전송되었습니다.
      </p>
      <p className="mt-1 text-sm text-gray-500">
        이메일을 사용 할 수 없는 경우 아래 가입 링크를 사용자에게 직접
        전송해주세요.
      </p>
      <div className="mt-2 flex flex-row justify-start gap-2 items-center">
        <span className="whitespace-nowrap text-sm" ref={spanRef}>
          {signUpLink}
        </span>
        <button
          type="button"
          className="rounded-full p-1 shadow-sm hover:text-congress-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleCopy}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default SuccessToSendEmail;
