import { Button } from '@autosquare/common';
import { DialogPanel, DialogTitle } from '@headlessui/react';
import React, { ReactNode } from 'react';

type Props = {
  closeDialog: () => void;
  handleSave: () => void;
  children: ReactNode;
};

const SetAsVariablePanel = ({ closeDialog, handleSave, children }: Props) => {
  return (
    <DialogPanel className="w-1/2 space-y-4 rounded-lg border border-solid border-gray-300 bg-white p-6 text-sm font-normal leading-normal text-black">
      <DialogTitle className="text-base font-bold">Set As Variable</DialogTitle>
      <div className="space-y-4">
        {children}
        <div className="flex items-center justify-end gap-4 pt-4">
          <Button
            type="button"
            variant="secondary"
            onClick={() => closeDialog()}
          >
            Cancel
          </Button>
          <Button type="button" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </DialogPanel>
  );
};

export default SetAsVariablePanel;
