import React from 'react';

import HelpInformation from './Help/HelpInformation';
import SidebarInHelp from './Help/SidebarInHelp';

const Help = () => {
  return (
    <div className="flex w-full">
      <SidebarInHelp />
      <HelpInformation />
    </div>
  );
};

export default Help;
