import React from "react";
import { Link } from "react-router-dom";

const IdeNotFound = () => {
  return (
    <div className="mx-auto max-w-full sm:px-0 lg:px-0 min-h-screen flex flex-col">
      <div className="w-full my-auto px-4 h-full flex flex-col items-center">
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-indigo-600">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Page not found
            </h1>
            <div className="mt-6 text-base leading-7 text-gray-600">
              <p>요청하신 페이지를 찾을 수 없습니다.</p>
              <div className="mt-4 text-sm text-gray-500">
                <p>Settings {">"} Application, URL 확인부탁드리며</p>
                <p>지속적인 에러 발생시 관리자에게 문의부탁드립니다.</p>
              </div>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to={"/ide/"}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                홈 화면으로 이동
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default IdeNotFound;
