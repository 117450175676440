import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '@app/store';

import { useImportTestCaseMutation } from '@lib/api/ide/mobile/mobileTestCase';
import { ImportedFileData } from '@customTypes/testCase/type';

interface Props {
  setStepNumber: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
  setFileData: React.Dispatch<React.SetStateAction<ImportedFileData[]>>;
}

interface ElectronFile extends File {
  path: string;
}

const TestCaseImportUploadFile = ({ setStepNumber, setFileData }: Props) => {
  const { projectIdx } = useParams();

  const [checkFilenameExtension, setCheckFilenameExtension] = useState(false);
  const [isInsideFile, setIsInsideFile] = useState(false);
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  const importMobileTestCaseListMutation = useImportTestCaseMutation(cookie);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files
      ? (e.target.files[0] as ElectronFile)
      : undefined;

    if (file?.name.includes('.dat')) {
      setCheckFilenameExtension(false);
      importMobileTestCaseListMutation.mutate(
        {
          projectIdx: Number(projectIdx),
          path: file.path,
        },
        {
          onSuccess: (response) => {
            setFileData(response);
            setStepNumber(2);
          },
          onError: () => {
            setIsOpenedDialog(true);
            setIsInsideFile(false);
          },
        },
      );
    } else {
      setCheckFilenameExtension(true);
      setIsOpenedDialog(true);
    }

    e.target.value = '';
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const files = [...e.dataTransfer.files];

    if (files && files.length > 0) {
      const file = files ? (files[0] as ElectronFile) : undefined;

      if (file.name.includes('.dat')) {
        setCheckFilenameExtension(false);
        importMobileTestCaseListMutation.mutate(
          {
            projectIdx: Number(projectIdx),
            path: file.path,
          },
          {
            onSuccess: (response) => {
              setFileData(response);
              setStepNumber(2);
            },
            onError: () => {
              setIsOpenedDialog(true);
              setIsInsideFile(false);
            },
          },
        );
      } else {
        setCheckFilenameExtension(true);
        setIsInsideFile(false);
      }
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsInsideFile(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsInsideFile(false);
  };

  return (
    <>
      <form>
        <div
          className="mt-10 flex h-40 flex-col items-center justify-center gap-y-6 rounded-lg border border-dashed border-gray-900/25"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          {isInsideFile ? (
            <div className="-z-50">Drop File</div>
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="mx-auto size-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    accept=".dat"
                    onChange={onChange}
                  />
                </label>
              </div>
            </>
          )}
        </div>
      </form>
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedDialog}
        setIsOpened={setIsOpenedDialog}
        type={'caution'}
        title={'Import Failure'}
        subTitle={
          importMobileTestCaseListMutation.isError
            ? importMobileTestCaseListMutation.error.message
            : checkFilenameExtension &&
              'dat 형식의 파일만 Import 할 수 있습니다.'
        }
        buttonChildren={
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              setIsOpenedDialog(false);
              setCheckFilenameExtension(false);
            }}
          >
            Close
          </Button>
        }
      />
      {importMobileTestCaseListMutation.isLoading && <ArrowSpinner />}
    </>
  );
};

export default TestCaseImportUploadFile;
