import React from "react";
import { Outlet } from "react-router-dom";

import { CopyRight } from "@autosquare/common";
import Nav from "@components/Nav/Nav";
import Sidebar from "@components/Sidebar/Sidebar";

const SidebarRoot = () => {
  return (
    <div className="mx-auto max-w-full sm:px-0 lg:px-0 min-h-screen flex flex-col">
      <Nav />
      <div className="min-h-full mt-16">
        <Sidebar />
        <div className="min-h-[calc(100vh-4rem)] lg:pl-72 flex flex-col justify-between">
          <main className="flex flex-col max-w-full min-h-full mb-auto px-4 py-6 sm:px-6 lg:px-8">
            <Outlet />
          </main>
          <CopyRight />
        </div>
      </div>
    </div>
  );
};

export default SidebarRoot;
