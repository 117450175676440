import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams } from 'react-router-dom';

import DetailDraggable from './SchedulerDetailScenario/DetailDraggable';

import { useMobileTestScenarioQuery } from '@lib/api/ide/mobile/mobileTestScenario';
import { useWebTestScenarioQuery } from '@lib/api/ide/web/webTestScenario';

import {
  SchedulerTypeServerName,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { useFormContext } from 'react-hook-form';

interface Props {
  testType: SchedulerTypeServerName;
  scenarioList: number[];
}

const SchedulerDetailScenario = ({ testType, scenarioList }: Props) => {
  const { projectIdx } = useParams();
  const { watch, clearErrors } = useFormContext<UpdateSchedulerData>();
  const testScenarioQuery =
    testType === SchedulerTypeServerName.Mobile
      ? useMobileTestScenarioQuery(projectIdx, { staleTime: 20 })
      : useWebTestScenarioQuery({ idx: projectIdx, staleTime: 20 });
  const addScenarioList = watch('scenario_idx_list');
  useEffect(() => {
    if (scenarioList.length > 0) {
      clearErrors('scenario_idx_list');
    }
  }, [clearErrors, addScenarioList]);
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
      <p className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
        Scenario
      </p>
      <div className="mt-2 text-sm sm:col-span-2 sm:mt-0">
        <DndProvider backend={HTML5Backend}>
          <DetailDraggable
            testScenarioQuery={testScenarioQuery}
            scenarioList={scenarioList}
          />
        </DndProvider>
      </div>
    </div>
  );
};

export default SchedulerDetailScenario;
