import React, { createContext, ReactNode, useContext, useState } from "react";

import { DeviceInfoOs } from "@customTypes/ide/device/device";

interface MobileTestStepDetailProviderContext {
  mobileOS: DeviceInfoOs;
  setMobileOS: React.Dispatch<React.SetStateAction<DeviceInfoOs>>;
}

export const MobileTestStepDetailContext =
  createContext<MobileTestStepDetailProviderContext>({
    mobileOS: undefined,
    setMobileOS: () => {},
  });

export const useMobileTestStepDetailContext = () => {
  useContext(MobileTestStepDetailContext);
};

interface Props {
  children: ReactNode;
}

const MobileTestStepDetailProvider = ({ children }: Props) => {
  const [mobileOS, setMobileOS] = useState<DeviceInfoOs>();

  return (
    <MobileTestStepDetailContext.Provider value={{ mobileOS, setMobileOS }}>
      {children}
    </MobileTestStepDetailContext.Provider>
  );
};

export default MobileTestStepDetailProvider;
