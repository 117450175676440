import { Button } from '@autosquare/common';
import {
  AuthCode,
  SendAuthCode,
} from '@autosquare/common/src/utils/type/resetPassword/type';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { ErrorMessage } from '@hookform/error-message';
import { UseMutationResult } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  sendAuthCode: UseMutationResult<
    unknown,
    ServerResponseErrorDetails,
    SendAuthCode,
    unknown
  >;
  resetTimer: () => void;
  resetErrorMessage: () => void;
};

const SendAuthCodeForm = ({
  sendAuthCode,
  resetTimer,
  resetErrorMessage,
}: Props) => {
  const methods = useForm<SendAuthCode>();

  const authCodeInput = methods.watch('userId');

  const onSubmit: SubmitHandler<AuthCode> = (data) => {
    sendAuthCode.mutate(data, {
      onSuccess: () => {
        sessionStorage.setItem('userId', authCodeInput);
        resetTimer();
        resetErrorMessage();
      },
    });
  };

  useEffect(() => {
    sendAuthCode.reset();
  }, [authCodeInput]);

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col pb-5">
          <div className="flex items-center justify-center space-x-3 px-3">
            <div className="relative grow">
              <label htmlFor="email" className="my-1 block">
                Email
              </label>
              <input
                type="email"
                id="email"
                placeholder="이메일 주소를 입력해 주세요."
                className="input-base"
                {...methods.register('userId', {
                  required: '이메일 주소를 입력해 주세요.',
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i,
                    message: '잘못된 이메일 형식입니다. 다시 입력해 주세요.',
                  },
                })}
              />
            </div>
            <div className="mt-7">
              <Button
                type="submit"
                buttonSize="xs"
                className="w-24 py-2"
                disabled={sendAuthCode.isLoading}
              >
                {sendAuthCode.isSuccess ? '재전송' : '인증번호 전송'}
              </Button>
            </div>
          </div>
          {sendAuthCode.isSuccess ? (
            <p className="standard-error-message mx-auto text-congress-blue">
              인증번호를 발송했습니다. 이메일을 확인해 주세요.
            </p>
          ) : (
            sendAuthCode.isError && (
              <p className="standard-error-message mx-auto">
                {sendAuthCode.error.message}
              </p>
            )
          )}
          <ErrorMessage
            name="userId"
            errors={methods.formState.errors}
            render={({ message }) => (
              <p className="standard-error-message mx-auto">{message}</p>
            )}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default SendAuthCodeForm;
