import React from 'react';

const MobileWebTestStepSkeleton = () => {
  return (
    <section className="py-6">
      <div className="flex items-center justify-between border-b border-solid border-gray-200 pb-3.5">
        <h3 className="text-base font-semibold leading-normal text-gray-900">
          Test Step
        </h3>
        <div className="h-8 w-16 animate-pulse rounded-md bg-gray-200 dark:bg-gray-700" />
      </div>
      <div className="flex animate-pulse flex-col items-center justify-center gap-4 py-2.5">
        <div className="h-8 w-full rounded-md bg-gray-200 dark:bg-gray-700" />
        <div className="h-8 w-full rounded-md bg-gray-200 dark:bg-gray-700" />
      </div>
    </section>
  );
};

export default MobileWebTestStepSkeleton;
