import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CreateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { useSchedulerEmailAlarm } from '@hooks/scheduler/useSchedulerEmailAlarm';
import SelectedEmailCardList from '@components/Scheduler/SelectedEmailCardList';
import EmailAlarmCombobox from '@components/Scheduler/EmailAlarmCombobox';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';

const CreateSchedulerEmailAlarm = () => {
  const { setValue, watch } = useFormContext<CreateSchedulerData>();
  const mailReceivers = watch('mail_receivers');

  const {
    wrapperRef,
    onChangeEmailAddress,
    setIsOpenedMemberList,
    inputRef,
    emailAddress,
    temporarySavedEmailAddress,
    setEmailAddress,
    setSelectedIndex,
    setEmailErrorMessage,
    filteredPeople,
    setTemporarySavedEmailAddress,
    selectedIndex,
    inputFocus,
    isOpenedMemberList,
    ulRef,
    onSelectMember,
    emailErrorMessage,
    deleteTagItem,
  } = useSchedulerEmailAlarm(mailReceivers, setValue);

  return (
    <SchedulerGridContainer title="Email Alarm" isTitleCenter={true}>
      <EmailAlarmCombobox
        wrapperRef={wrapperRef}
        onChangeEmailAddress={onChangeEmailAddress}
        setIsOpenedMemberList={setIsOpenedMemberList}
        inputRef={inputRef}
        emailAddress={emailAddress}
        temporarySavedEmailAddress={temporarySavedEmailAddress}
        setEmailAddress={setEmailAddress}
        setSelectedIndex={setSelectedIndex}
        setEmailErrorMessage={setEmailErrorMessage}
        filteredPeople={filteredPeople}
        setTemporarySavedEmailAddress={setTemporarySavedEmailAddress}
        selectedIndex={selectedIndex}
        inputFocus={inputFocus}
        isOpenedMemberList={isOpenedMemberList}
        ulRef={ulRef}
        onSelectMember={onSelectMember}
        mailReceivers={mailReceivers}
        setValue={setValue}
      />
      <p className="standard-error-message">{emailErrorMessage}</p>
      <SelectedEmailCardList
        mailReceivers={mailReceivers}
        deleteTagItem={deleteTagItem}
      />
    </SchedulerGridContainer>
  );
};

export default CreateSchedulerEmailAlarm;
