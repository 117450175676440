import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { useProjectDetailQuery } from '@lib/api/dashboard/project/project';
import { getProjectList } from '@store/data/projectListSlice';

import ProjectSidebarMonitorSize from './ProjectSidebarMonitorSize';
import ProjectSidebarUnderMonitorSize from './ProjectSidebarUnderMonitorSize';

import { DefaultSkeleton, ErrorMessage, useViewport } from '@autosquare/common';

import { SidebarList } from '@customTypes/type';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';

type Props = {
  isToggledSidebar: boolean;
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectsSidebar = ({ isToggledSidebar, setIsToggledSidebar }: Props) => {
  const { projectIdx } = useParams();

  const { width } = useViewport();

  const projectData = useProjectDetailQuery(projectIdx);
  const projectList = projectData.data;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjectList(projectList));
  }, [projectList]);

  const sidebarMobileAppChildren = [
    {
      name: 'Test Result',
      href: `/projects/${projectIdx}/mobile-app/test-result?page=1`,
      hrefBasic: `/projects/${projectIdx}/mobile-app/test-result`,
    },
    {
      name: 'Speed Test Result',
      href: `/projects/${projectIdx}/mobile-app/speed/test-result?page=1`,
      hrefBasic: `/projects/${projectIdx}/mobile-app/speed/test-result`,
    },
  ];

  const sidebarWebChildren = [
    {
      name: 'Test Result',
      href: `/projects/${projectIdx}/web/test-result?page=1`,
      hrefBasic: `/projects/${projectIdx}/web/test-result`,
    },
    {
      name: 'Speed Test Result',
      href: `/projects/${projectIdx}/web/speed/test-result?page=1`,
      hrefBasic: `/projects/${projectIdx}/web/speed/test-result`,
    },
  ];

  const sidebarListArray = [
    {
      name: 'Overview',
      href: `/projects/${projectIdx}/overview`,
      hrefBasic: `/projects/${projectIdx}/overview`,
    },
    {
      name: 'Mobile App',
      location: ProjectListDetailFeaturesCategory.Mobile,
      children: sidebarMobileAppChildren.filter((value, i) =>
        checkIsProjectFeatureIncluded(
          projectList,
          ProjectListDetailFeaturesCategory.Mobile,
          ProjectListDetailFeaturesFeature.Functional,
        ) === false
          ? i !== 0
          : checkIsProjectFeatureIncluded(
                projectList,
                ProjectListDetailFeaturesCategory.Mobile,
                ProjectListDetailFeaturesFeature.Speed,
              ) === false
            ? i !== 1
            : value,
      ),
    },
    {
      name: 'Web',
      location: ProjectListDetailFeaturesCategory.Web,
      children: sidebarWebChildren.filter((value, i) =>
        checkIsProjectFeatureIncluded(
          projectList,
          ProjectListDetailFeaturesCategory.Web,
          ProjectListDetailFeaturesFeature.Functional,
        ) === false
          ? i !== 0
          : checkIsProjectFeatureIncluded(
                projectList,
                ProjectListDetailFeaturesCategory.Web,
                ProjectListDetailFeaturesFeature.Speed,
              ) === false
            ? i !== 1
            : value,
      ),
    },
    {
      name: 'Members',
      href: `/projects/${projectIdx}/members/list`,
      hrefBasic: `/projects/${projectIdx}/members`,
    },
  ];

  const sidebarList: SidebarList[] = sidebarListArray.filter((value, i) =>
    checkIsProjectFeatureIncluded(
      projectList,
      ProjectListDetailFeaturesCategory.Mobile,
      ProjectListDetailFeaturesFeature.Functional,
    ) === false &&
    checkIsProjectFeatureIncluded(
      projectList,
      ProjectListDetailFeaturesCategory.Mobile,
      ProjectListDetailFeaturesFeature.Speed,
    ) === false
      ? i !== 1
      : checkIsProjectFeatureIncluded(
            projectList,
            ProjectListDetailFeaturesCategory.Web,
            ProjectListDetailFeaturesFeature.Functional,
          ) === false &&
          checkIsProjectFeatureIncluded(
            projectList,
            ProjectListDetailFeaturesCategory.Web,
            ProjectListDetailFeaturesFeature.Speed,
          ) === false
        ? i !== 2
        : value,
  );

  return (
    <>
      {!isToggledSidebar && (
        <div className="flex items-center border-b px-6 py-4 lg:hidden">
          <button
            type="button"
            className="mr-6 "
            onClick={() => setIsToggledSidebar(!isToggledSidebar)}
          >
            <Bars3Icon className="block size-6" aria-hidden="true" />
          </button>
          <div>Menu</div>
        </div>
      )}
      {width > 1024 ? (
        <div className="flex h-full grow flex-col overflow-y-auto bg-white px-6">
          {projectData.isLoading ? (
            <div className="my-6">
              <DefaultSkeleton
                number={5}
                height={'h-3.5'}
                outMargin={'m-0'}
                margin={'my-6'}
              />
            </div>
          ) : projectData.isError ? (
            <ErrorMessage>{projectData.error.message}</ErrorMessage>
          ) : (
            <ProjectSidebarMonitorSize
              sidebarList={sidebarList}
              projectList={projectList}
              setIsToggledSidebar={setIsToggledSidebar}
            />
          )}
        </div>
      ) : (
        isToggledSidebar && (
          <ProjectSidebarUnderMonitorSize
            sidebarList={sidebarList}
            projectList={projectList}
            setIsToggledSidebar={setIsToggledSidebar}
          />
        )
      )}
    </>
  );
};

export default ProjectsSidebar;
