import { ComboboxHeadlessInController } from '@autosquare/common';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';

import { RootState } from '@app/store';

import { useMobileAppListQuery } from '@lib/api/ide/mobile/mobileDevice';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

const SearchTestStepValue = () => {
  const [searchParams] = useSearchParams();
  const udid = searchParams.get('udid');

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const mobileAppListQuery = useMobileAppListQuery(udid);
  const appLists = mobileAppListQuery.data;

  const { control, watch, setValue } = useFormContext<CreateTestStepData>();

  const value = watch('value');

  useEffect(() => {
    setValue('value', '');
    setValue('optionsDto.elementOptions', undefined);
    return () => setValue('value', undefined);
  }, [setValue]);

  const filteredAppLists = appLists?.filter((appList) =>
    appList
      .toLocaleLowerCase()
      .includes(value ? value.toLocaleLowerCase() : ''),
  );

  return (
    <Controller
      control={control}
      name="value"
      rules={{ required: 'App을 선택해주세요.' }}
      render={({ field }) => {
        return (
          <ComboboxHeadlessInController
            value={field.value || ''}
            onChange={field.onChange}
            filteredList={filteredAppLists}
            placeholder={
              deviceInfo.os === DeviceInfoOs.Aos
                ? 'Search Package Name'
                : 'Search Bundle Identifier'
            }
            reset={() => {
              setValue('value', '');
              mobileAppListQuery.refetch();
            }}
            isError={mobileAppListQuery.isError}
            error={mobileAppListQuery.error}
          />
        );
      }}
    />
  );
};

export default SearchTestStepValue;
