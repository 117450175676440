import { z } from 'zod';

export enum SupportedBrowsers {
  Safari = 'safari',
  Chrome = 'chrome',
  Edge = 'edge',
  Initial = 'Select Test Browser',
}

export enum SupportedBrowserList {
  Safari = 'Safari',
  Chrome = 'Chrome',
  Edge = 'Edge',
  Initial = 'Select Test Browser',
}

export const BrowserInfoSchema = z.object({
  browserName: z.nativeEnum(SupportedBrowserList).optional(),
  version: z.string().optional(),
});

export interface BrowserInfo {
  browserName: SupportedBrowserList;
  version: string;
}
