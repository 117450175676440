import TestStepHeader from '@components/shared/MobileWebTestStep/CreateAndUpdate/TestStepHeader';

import { hasDuplicateTitle } from '@utils/static/speedTest/mobileWebTestStep/hasDuplicateTitle';

import React, { useState } from 'react';
import {
  SubmitHandler,
  useForm,
  FormProvider,
  Controller,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import UpdateWebSpeedEditableDiv from './UpdateWebSpeedTestStep/UpdateWebSpeedEditableDiv';
import UpdateWebSpeedVariableList from './UpdateWebSpeedTestStep/UpdateWebSpeedVariableList';
import {
  UpdateWebSpeedTestStepData,
  UpdateWebSpeedTestStepDataSchema,
  WebSpeedTestStepData,
} from '@customTypes/ide/speedTest/Web/testStep/type';
import { useUpdateSpeedWebStepMutation } from '@lib/api/ide/speedWeb/speedWebTestStep';

interface Props {
  endUpdateTestStep: () => void;
  speedWebStep: WebSpeedTestStepData;
}

const UpdateWebSpeedTestStep = ({ endUpdateTestStep, speedWebStep }: Props) => {
  const [errorIndex, setErrorIndex] = useState(0);

  const url = speedWebStep.url;

  const updateWebSpeedTestStepMutation = useUpdateSpeedWebStepMutation(
    speedWebStep.idx,
  );

  const optionsDtoUpdateIdxAndUuid = speedWebStep.optionsDto.map(
    (item, idx) => ({
      ...item,
      idx: idx,
      uuid: crypto.randomUUID(),
      start: url.indexOf(item.keyName) - 2,
      end: url.indexOf(item.keyName) + item.keyName.length + 2,
      addValue: Number(item.addValue),
    }),
  );

  const methods = useForm<UpdateWebSpeedTestStepData>({
    defaultValues: {
      url: speedWebStep.url,
      description: speedWebStep.description,
      optionsDto: optionsDtoUpdateIdxAndUuid,
    },
    resolver: zodResolver(UpdateWebSpeedTestStepDataSchema),
  });

  const onSubmit: SubmitHandler<UpdateWebSpeedTestStepData> = (data) => {
    if (hasDuplicateTitle(data.optionsDto, setErrorIndex)) {
      methods.setError(`optionsDto.${errorIndex}.keyName`, {
        type: 'duplication',
        message: '이미 존재하는 key Name입니다. 다른 Key Name을 입력해 주세요.',
      });
      methods.setFocus(`optionsDto.${errorIndex}.keyName`);
      return;
    }

    const dataWithoutIdx: UpdateWebSpeedTestStepData = {
      ...data,
      optionsDto: data.optionsDto?.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ idx, uuid, start, end, ...rest }) => rest,
      ),
    };

    updateWebSpeedTestStepMutation.mutate(dataWithoutIdx, {
      onSuccess: () => {
        endUpdateTestStep();
        methods.reset();
      },
    });
  };

  const clickCancelButton = () => {
    endUpdateTestStep();
    updateWebSpeedTestStepMutation.reset();
  };

  const optionsDtoArray = methods.watch('optionsDto');

  return (
    <FormProvider {...methods}>
      <form className="py-6" onSubmit={methods.handleSubmit(onSubmit)}>
        <TestStepHeader
          cancelOnClick={clickCancelButton}
          saveDisabled={updateWebSpeedTestStepMutation.isLoading}
        />
        {updateWebSpeedTestStepMutation.isError && (
          <p className="error-message pb-1 pt-2.5">
            {updateWebSpeedTestStepMutation.error.message}
          </p>
        )}
        <div className="py-2.5 text-sm font-normal leading-9 text-black">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-8 gap-4">
              <p className="col-span-1">URL*</p>
              <Controller
                name={'url'}
                control={methods.control}
                render={({ field }) => (
                  <UpdateWebSpeedEditableDiv
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <ErrorMessage
              errors={methods.formState.errors}
              name={'url'}
              render={({ message }) => (
                <p className="error-message">{message}</p>
              )}
            />
            <label htmlFor={'description'} className="grid grid-cols-8 gap-4">
              <p className="col-span-1">Description*</p>
              <input
                id={'description'}
                name={'description'}
                className="input-base col-span-7"
                {...methods.register('description')}
              />
            </label>
            <ErrorMessage
              errors={methods.formState.errors}
              name={'description'}
              render={({ message }) => (
                <p className="error-message">{message}</p>
              )}
            />
          </div>
        </div>
        {optionsDtoArray.length > 0 && <UpdateWebSpeedVariableList />}
      </form>
    </FormProvider>
  );
};

export default UpdateWebSpeedTestStep;
