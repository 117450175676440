import React, { ReactElement } from 'react';

type Props = {
  term: string;
  description: string | ReactElement | boolean;
};

const DefinitionList = ({ term, description }: Props) => {
  return (
    <div className="sm:flex sm:items-center sm:px-6 sm:py-4 border-b border-gray-200">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
        {term}
      </dt>
      <dd className="mt-1 text-sm text-gray-900 break-all sm:w-full sm:col-span-2 sm:ml-6 sm:mt-0">
        {description}
      </dd>
    </div>
  );
};

export default DefinitionList;
