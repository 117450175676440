import { MobileTestStepData } from '@customTypes/testStep/type';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';

export enum PriorityNameEnum {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum PriortyServerName {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export interface PostExportMobileTestCaseData {
  projectIdx: number;
  exportCaseIdx: number[];
}

export interface PostExportMobileTestCase {
  path: string;
  exportData: MobileTestCaseExportDataResponse;
}

export interface MobileTestCaseExportDataResponse {
  exportDate: string;
  exportProjectIdx: number;
  exportServer: string;
  requestUser: string;
  testCases: TestCaseData[];
}

export interface MobileTestCaseExportResponse {
  saved: boolean;
  savedPath: string;
}

export interface PostMobileTestCaseExportListToLocal {
  projectIdx: number;
  path: string;
}

export interface WebTestCaseDetailData {
  idx: number;
  title: string;
  creator: string;
  description?: string;
  step: number[];
  stepCount: number;
  dndIndex?: number;
  priority: PriorityNameEnum;
  updatedAt: string;
  platformType?: PlatFormTypeServerName;
  caseGroupInfo?: null | CaseGroupInfo;
}

export interface CaseGroupInfo {
  name: string;
  caseGroupIdx: number;
}

export interface TestCaseDetailData {
  idx: number;
  title: string;
  description?: string;
  steps: TestCaseDetailStep;
  dndIndex?: number;
  priority: PriorityNameEnum;
  creator: string;
  updatedAt: string;
  platformType?: PlatFormTypeServerName;
  caseGroupInfo?: null | CaseGroupInfo;
}

export interface CreateMobileTestCaseData {
  title: string;
  description?: string;
  priority: PriorityNameEnum;
  platformType: PlatFormTypeServerName;
  caseGroupIdx?: number;
}

export interface UpdateMobileTestCaseData {
  title: string;
  description?: string;
  priority: PriorityNameEnum;
  platformType: PlatFormTypeServerName;
  creator: string;
  caseGroupIdx?: number;
}

export type TestCaseDetailDataWithoutSteps = Omit<
  TestCaseDetailData,
  'steps' | 'description' | 'stepCount' | 'creator'
>;

export interface TestCaseDetailStep {
  aos: number[];
  aosStepCount: number;
  ios: number[];
  iosStepCount: number;
  stepCount: number;
}

// import test case type
export enum ImportedFileDataStatus {
  Done = 'done',
  Fail = 'fail',
  Update = 'Update',
  New = 'New',
}

export interface TestCaseData {
  createdAt: string;
  creator: string;
  description: string;
  modifiedAt: string;
  ownerUuid: string;
  platformType: string;
  priority: PriorityNameEnum;
  projectIdx: number;
  status: string;
  title: string;
  uniqueKey: string;
  aosStepCount: number;
  iosStepCount: number;
  testSteps: MobileTestStepData[];
}

export interface ImportedFileData {
  status: ImportedFileDataStatus;
  testCaseData: TestCaseData;
}

export interface ImportTestCaseResponse {
  description: string;
  failReason: string | null;
  importResult: 'done' | 'fail' | 'doing' | 'wait';
  priority: PriorityNameEnum;
  stepCount: number;
  title: string;
}

export interface CreateWebTestCaseData {
  priority: PriorityNameEnum;
  title: string;
  description?: string;
  caseGroupIdx?: number;
}

export interface UpdateWebTestCaseData {
  priority: PriorityNameEnum;
  title: string;
  description?: string;
  caseGroupIdx?: number;
}
