import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

import WebTestStepDetailConfirmInputTextarea from '@components/shared/IDE/WebTestStepDetail/WebTestStepDetailConfirmInputTextarea';
import WebTestStepConfirmInputRadio from '@components/shared/IDE/WebTestStepDetail/WebTestStepConfirmInputRadio';

import { useWebTestStepByCommandQuery } from '@lib/api/ide/web/webTestStep';

import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';

import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import TestStepSelectSaveText from '../TestStepSelectSaveText/TestStepSelectSaveText';
import { CompareBy } from '@customTypes/ide/mobileTestStep/mobileTestStep';

interface Props {
  webTestStep: WebTestStepData;
}

const TestStepConfirmOrInputText = ({ webTestStep }: Props) => {
  const [searchParams] = useSearchParams();
  const testCaseIdx = Number(searchParams.get('idx'));

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const webTestStepBySaveTextQuery = useWebTestStepByCommandQuery(
    testCaseIdx,
    WebCommandOptionServerName.SaveText,
  );
  const saveTextLists = webTestStepBySaveTextQuery.data;

  const isCompareById = webTestStep.optionsDto.compareBy === CompareBy.Id;
  const isCompareByText = webTestStep.optionsDto.compareBy === CompareBy.Text;

  const compareBy = watch('optionsDto.compareBy');

  const radioOptions = [
    {
      id: 'id',
      title: '테스트 스텝 선택',
      defaultChecked: isCompareById,
      onClick: () => {
        if (isCompareById) {
          setValue('optionsDto.compareIdx', webTestStep.optionsDto.compareIdx);
        }
        if (isCompareByText) {
          setValue('optionsDto.compareIdx', undefined);
        }
        setValue('optionsDto.compareText', undefined);
      },
    },
    {
      id: 'text',
      title: '직접 입력',
      defaultChecked: isCompareByText,
      onClick: () => {
        if (isCompareById) {
          setValue('optionsDto.compareText', '');
        }
        if (isCompareByText) {
          setValue(
            'optionsDto.compareText',
            webTestStep.optionsDto.compareText,
          );
        }
        setValue('optionsDto.compareIdx', undefined);
      },
    },
  ];

  const equalAndContainRadioOptions = [
    {
      title: '동일',
      id: 'equal',
      defaultChecked: webTestStep.optionsDto.compareRange === 'equal',
    },
    {
      title: '포함',
      id: 'contain',
      defaultChecked: webTestStep.optionsDto.compareRange === 'contain',
    },
  ];

  return (
    <div className="items-center sm:flex sm:px-6 sm:py-4">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        Value*
      </dt>
      <dd className="mt-1 grid w-full grid-rows-1 gap-2 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        <WebTestStepDetailConfirmInputTextarea name={'value'} />
        <div className="space-y-4 sm:flex sm:items-center sm:justify-between sm:space-x-10 sm:space-y-0">
          <WebTestStepConfirmInputRadio
            name={'optionsDto.compareBy'}
            radioOptions={radioOptions}
          />
        </div>
        {compareBy === 'id' && saveTextLists?.length === 0 ? (
          <div className="flex flex-col items-center justify-center border border-solid border-border-line p-3 text-sm">
            <p className="mb-2">선택 가능한 테스트 스텝이 없습니다.</p>
            <p>Save Text Step을 먼저 추가해주세요.</p>
          </div>
        ) : compareBy === 'id' && saveTextLists?.length !== 0 ? (
          <TestStepSelectSaveText saveTextLists={saveTextLists} />
        ) : (
          compareBy === 'text' && (
            <>
              <input
                type={'text'}
                className="input-base"
                placeholder={
                  webTestStep.command === WebCommandOptionServerName.ConfirmText
                    ? '비교할 텍스트를 직접 입력해주세요.'
                    : '텍스트를 입력해주세요.'
                }
                {...register('optionsDto.compareText', {
                  required: '텍스트를 입력해 주세요.',
                })}
              />
              <ErrorMessage
                name={'optionsDto.compareText'}
                errors={errors}
                render={({ message }) => (
                  <p className="standard-error-message py-0">{message}</p>
                )}
              />
            </>
          )
        )}
        {webTestStep.command === WebCommandOptionServerName.ConfirmText && (
          <WebTestStepConfirmInputRadio
            name={'optionsDto.compareRange'}
            radioOptions={equalAndContainRadioOptions}
          />
        )}
      </dd>
    </div>
  );
};

export default TestStepConfirmOrInputText;
