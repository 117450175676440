import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { RootState } from '@app/store';

import { useModifyWebTestStepMutation } from '@lib/api/ide/web/webTestStep';

import {
  findWebCommandNameFromServerName,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';

import WebTestStepInputByReactHookForm from '@components/shared/IDE/WebTestStepDetail/WebTestStepInputByReactHookForm';
import WebTestStepTextareaByReactHookForm from '@components/shared/IDE/WebTestStepDetail/WebTestStepTextareaByReactHookForm';
import DefinitionList from '@components/shared/DefinitionList';
import TestStepDetailEditButton from '@components/shared/TestStepDetail/TestStepDetailEditButton';

import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';

import TestStepValue from './TestStepDetailEdit/TestStepValue/TestStepValue';
import DefinitionInputOrTextAreaByReactHookForm from './TestStepDetailEdit/DefinitionInputOrTextAreaByReactHookForm';
import EditIfCommand from './TestStepDetailEdit/EditIfCommand';

interface Props {
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  webTestStepData: WebTestStepData;
}

const TestStepDetailEdit = ({ setIsEdited, webTestStepData }: Props) => {
  const testStepIdx = useSelector(
    (state: RootState) => state.webTestStepDetail.testStepIdx,
  );

  const [searchParams] = useSearchParams();
  const caseIdx = Number(searchParams.get('idx'));

  const modifyTestStepMutation = useModifyWebTestStepMutation(testStepIdx);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { idx, ...restWebTestStepData } = webTestStepData;

  const methods = useForm<UpdateWebTestStepData>({
    defaultValues: { caseIdx: caseIdx, ...restWebTestStepData },
  });

  const onSubmit: SubmitHandler<UpdateWebTestStepData> = (data) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { startX, startY, endX, endY, ...restData } = data;

    modifyTestStepMutation.mutate(restData, {
      onSuccess: () => {
        setIsEdited(false);
        methods.reset();
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TestStepDetailEditButton
          isError={modifyTestStepMutation.isError}
          error={modifyTestStepMutation.error}
          setIsEdited={setIsEdited}
        />
        <div className="divide-y divide-gray-200">
          <div className="items-center px-4 py-5 sm:p-0">
            <dl className="space-y-8 sm:space-y-0">
              <DefinitionList
                term={'Command'}
                description={findWebCommandNameFromServerName(
                  webTestStepData.command,
                )}
              />
              {webTestStepData.command !== WebCommandOptionServerName.IF && (
                <TestStepValue webTestStep={webTestStepData} />
              )}
              {webTestStepData.command ===
                WebCommandOptionServerName.KeyBoardEvent && (
                <WebTestStepTextareaByReactHookForm
                  term={'TargetElement*'}
                  name={'optionsDto.targetElement'}
                  message={'TargetElement를 입력해 주세요.'}
                />
              )}
              <WebTestStepInputByReactHookForm
                term={'Description*'}
                name={'description'}
                type={'text'}
                register={methods.register('description', {
                  required: 'Description을 입력해 주세요.',
                })}
              />
              {webTestStepData.command === WebCommandOptionServerName.IF ? (
                <EditIfCommand webTestStepData={webTestStepData} />
              ) : (
                <DefinitionInputOrTextAreaByReactHookForm
                  term={'Required'}
                  name={'required'}
                  type={'checkbox'}
                />
              )}
              {webTestStepData.command ===
                WebCommandOptionServerName.ScrollAndSwipe && (
                <WebTestStepInputByReactHookForm
                  term={'Loop*'}
                  name={'optionsDto.loop'}
                  type={'number'}
                  register={methods.register('optionsDto.loop', {
                    required: 'Loop를 입력해 주세요.',
                    min: { value: 1, message: '1 이상 입력해 주세요.' },
                  })}
                />
              )}
              <WebTestStepInputByReactHookForm
                term={'WaitTime*'}
                name={'optionsDto.waitingTime'}
                type={'number'}
                register={methods.register('optionsDto.waitingTime', {
                  required: 'Wait Time을 입력해 주세요.',
                  min: { value: 0, message: '0 이상 60 이하 입력해 주세요.' },
                  max: { value: 60, message: '0 이상 60 이하 입력해 주세요.' },
                })}
              />
            </dl>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default TestStepDetailEdit;
