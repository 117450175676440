import React from 'react';
import CoordinateEdit from './TestStepValue/CoordinatePointsEdit';

const UnlockPatternEdit = () => {
  return (
    <div className="items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        Points*
      </dt>
      <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        <CoordinateEdit />
      </dd>
    </div>
  );
};

export default UnlockPatternEdit;
