import { ClickId } from '@utils/static/clickOptions';

import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  id: ClickId;
  name: string;
  defaultChecked: boolean;
  labelTitle: string;
  register: UseFormRegisterReturn;
  onClick?: () => void;
};

const CheckboxHookForm = ({
  id,
  name,
  defaultChecked,
  labelTitle,
  register,
  onClick,
}: Props) => {
  return (
    <div key={id} className="flex items-center">
      <input
        id={id}
        name={name}
        type="radio"
        defaultChecked={defaultChecked}
        value={id}
        onClick={onClick}
        className="top-1 size-4 appearance-none rounded-[50%] border border-solid border-gray-300 align-top text-indigo-600 transition-all duration-200 ease-linear checked:border-[6px] checked:border-solid checked:border-indigo-600 focus:ring-indigo-600"
        {...register}
      />
      <label
        htmlFor={id}
        className="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900"
      >
        {labelTitle}
      </label>
    </div>
  );
};

export default CheckboxHookForm;
