import {
  Breadcrumbs,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getSelectedBrowser } from '@store/ide/browser/selectedBrowserSlice';

import WebExecutionInfo from './WebTestExecution/WebExecutionInfo';
import SelectBrowser from './WebTestExecution/SelectBrowser';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { BrowserInfo } from '@customTypes/ide/browser/browser';

type Props = {
  idx: number;
  setIsReadyToNavigate: React.Dispatch<React.SetStateAction<boolean>>;
  testType: TestTypeCategory;
  divWidth: number;
};

const WebTestExecution = ({
  idx,
  setIsReadyToNavigate,
  testType,
  divWidth,
}: Props) => {
  const [selectedBrowser, setSelectedBrowser] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isSelectedBrowser, setIsSelectedBrowser] = useState(false);

  const checkedBrowserHandler = (value: BrowserInfo, isChecked: boolean) => {
    if (isChecked) {
      setSelectedBrowser([...selectedBrowser, value]);
    }
    if (!isChecked && selectedBrowser.includes(value)) {
      setSelectedBrowser(
        selectedBrowser.filter((selectedDevice) => selectedDevice !== value),
      );
    }
  };

  const checkHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: BrowserInfo,
  ) => {
    setIsChecked(!isChecked);
    checkedBrowserHandler(value, e.target.checked);
    setIsSelectedBrowser(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSelectedBrowser(selectedBrowser));
  }, [selectedBrowser]);

  const executionTestOnClick = () => {
    if (selectedBrowser?.length === 0) {
      setIsSelectedBrowser(true);
    } else {
      setIsSelectedBrowser(false);
      setIsReadyToNavigate(true);
    }
  };

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'UI Test' },
    { title: 'Web' },
    {
      title:
        testType === 'step'
          ? 'Test Step'
          : testType === 'case'
            ? 'Test Case'
            : 'Test Scenario',
    },
    { title: 'Execution' },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Execution Test',
      type: 'button',
      variant: 'primary',
      onClick: executionTestOnClick,
    },
  ];

  return (
    <div className="flex flex-col gap-8">
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={
          testType === 'step'
            ? 'Test Step Execution'
            : testType === 'case'
              ? 'Test Case Execution'
              : 'Test Scenario Execution'
        }
        pageHeadingButtons={pageHeadingButtons}
      />
      <WebExecutionInfo idx={idx} testType={testType} />
      <SelectBrowser
        selectedBrowser={selectedBrowser}
        setSelectedBrowser={setSelectedBrowser}
        checkHandler={checkHandler}
        isSelectedBrowser={isSelectedBrowser}
        setIsSelectedBrowser={setIsSelectedBrowser}
        divWidth={divWidth}
      />
    </div>
  );
};

export default WebTestExecution;
