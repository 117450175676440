import { RootState } from '@app/store';
import { ErrorMessage } from '@autosquare/common';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useUserInfoQuery } from '@lib/api/ide/user/user';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  item: {
    name: string;
    number?: number;
    children?: {
      name: string;
      enable: boolean;
      number?: number;
    }[];
  };
  settingsMenu: string;
  setSettingsMenu: React.Dispatch<React.SetStateAction<string>>;
};
const SettingsSidebarList = ({
  item,
  settingsMenu,
  setSettingsMenu,
}: Props) => {
  const [isOpened, setIsOpened] = useState(true);

  const accessToken: string | object = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  const userInfoQuery = useUserInfoQuery({
    enabled: accessToken !== null,
  });
  const userInfo = userInfoQuery?.data;

  return (
    <div className="px-3">
      <button
        className={`flex items-center w-full rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 ${
          settingsMenu === item.name ? 'hover:bg-gray-50' : ''
        } transition-all duration-700`}
        onClick={() => setIsOpened(!isOpened)}
      >
        <ChevronRightIcon
          className={`h-5 w-5 shrink-0 ${
            isOpened ? 'rotate-90 transform text-gray-500' : 'text-gray-400'
          })
              `}
          aria-hidden="true"
        />
        {item.name}
      </button>
      {isOpened && (
        <ul className="mt-1 flex-col items-center justify-start gap-x-3">
          {item.children.map((subItem) => (
            <li className="mb-1" key={subItem.name}>
              {(accessToken && userInfoQuery.isLoading) ||
              userInfoQuery.isFetching ? (
                <div className="bg-gray-300 animate-pulse h-8 mb-3 rounded-md"></div>
              ) : (
                <button
                  type="button"
                  className={`rounded-md py-2 text-sm leading-6 w-full flex item-center justify-stretch
                    ${
                      settingsMenu === subItem.name
                        ? 'bg-gray-50'
                        : subItem.enable
                        ? 'text-gray-700 cursor-pointer hover:bg-gray-50'
                        : 'text-gray-300 cursor-auto'
                    } `}
                  onClick={() => setSettingsMenu(subItem.name)}
                  disabled={!subItem.enable}
                >
                  <span className="ml-14">{subItem.name}</span>
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
      <ul role="list">
        {(accessToken && userInfoQuery?.isLoading) ||
        userInfoQuery?.isFetching ? (
          <div className="p-5 ml-5">
            <li className="h-5 w-14 bg-gray-300 rounded animate-pulse"></li>
          </div>
        ) : userInfoQuery?.isError ? (
          <ErrorMessage>{userInfoQuery.error.message}</ErrorMessage>
        ) : (
          <li className="text-sm text-gray-700 font-semibold p-5 ml-5">
            {userInfo?.name}
          </li>
        )}
      </ul>
    </div>
  );
};

export default SettingsSidebarList;
