import { API } from "@api/endpoints";
import { ServerResponseErrorDetails } from "@customTypes/type";
import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "..";

interface AccessLogData {
  accessTime: string;
  url: string;
  details: { key: "projectIdx"; value: string }[];
}

// POST
const sendAccessLog = async (data: AccessLogData): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(API.SAVE_ACCESS_LOG, data);
  return response.data;
};
export const useSendAccessLogMutation = () => {
  return useMutation<unknown, ServerResponseErrorDetails, AccessLogData>({
    mutationFn: (data) => sendAccessLog(data),
  });
};
