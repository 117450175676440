import {
  RepeatStepComboboxHeadlessUI,
  StartAndFinishMark,
} from '@autosquare/common';
import { FilteredList } from '@autosquare/common/src/utils/type/repeatBox/type';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useRepeatSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import React, { useEffect, useState } from 'react';

type Props = {
  filteredList: FilteredList[];
  startRepeatIdx: number;
  endRepeatIdx: number;
  deviceInfo: DeviceInfoOs;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const TestStepRepeatStartBox = ({
  filteredList,
  startRepeatIdx,
  endRepeatIdx,
  deviceInfo,
  setError,
}: Props) => {
  const initialValue: FilteredList =
    startRepeatIdx === 0
      ? { idx: null, label: '', disabled: false }
      : filteredList?.find((item) => item.idx === startRepeatIdx);

  const [selectedValue, setSelectedValue] = useState(initialValue);

  const endIndex = filteredList?.findIndex((item) => item.idx === endRepeatIdx);
  const filteredStartList =
    startRepeatIdx === 0 || endRepeatIdx === 0
      ? filteredList
      : filteredList?.map((item, index) => ({
          ...item,
          disabled: index >= endIndex || item.disabled,
        }));

  const saveRepeatTestStep = useRepeatSpeedMobileTestStep();

  const handleChange = (value: FilteredList) => {
    setSelectedValue(value);
    if (value === null) {
      saveRepeatTestStep.mutate({
        startStepIdx: 0,
        endStepIdx: 0,
        mobileOs: deviceInfo,
      });
      setError(false);
    }

    if (value !== null) {
      saveRepeatTestStep.mutate({
        startStepIdx: value.idx,
        endStepIdx: endRepeatIdx,
        mobileOs: deviceInfo,
      });
      if (endRepeatIdx === 0) {
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (startRepeatIdx === 0) {
      setSelectedValue(initialValue);
    }
  }, [startRepeatIdx]);

  return (
    <div className="flex items-center gap-2.5">
      <StartAndFinishMark />
      <p className="w-12 text-xs font-medium  text-gray-900">Start</p>
      <div className="w-full">
        <RepeatStepComboboxHeadlessUI
          value={selectedValue}
          filteredList={filteredStartList}
          onChange={handleChange}
          findIndex={
            filteredStartList?.find((item) => item?.idx === selectedValue?.idx)
              ?.idx
          }
        />
      </div>
    </div>
  );
};

export default TestStepRepeatStartBox;
