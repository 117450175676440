import { ListBoxHeadlessInController } from '@autosquare/common';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  saveTextLists: WebTestStepData[];
};

const TestStepSelectSaveText = ({ saveTextLists }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const findDescriptionByIdx = (idx: number): string =>
    saveTextLists?.find((list) => list.idx === idx)?.description;

  return (
    <>
      <Controller
        control={control}
        name="optionsDto.compareIdx"
        rules={{ required: 'Test Step을 선택해 주세요.' }}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              findDescriptionByIdx(Number(field.value))
                ? findDescriptionByIdx(Number(field.value))
                : 'Select Test Step'
            }
            onChange={field.onChange}
            lists={saveTextLists}
            valueToSave={'idx'}
            valueToShow={'description'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={'optionsDto.compareIdx'}
        render={({ message }) => (
          <p className="standard-error-message py-0">{message}</p>
        )}
      />
    </>
  );
};

export default TestStepSelectSaveText;
