import { ErrorMessage } from '@autosquare/common';

import React, { useState } from 'react';

import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';

import Device from './components/Device';
import DeviceListHeader from './components/DeviceListHeader';
import DeviceListSkeleton from './components/DeviceListSkeleton';

const DeviceList = () => {
  const [isElectronErrorMessage, setIsElectronErrorMessage] = useState('');

  const deviceListQuery = useDeviceListQuery();
  const deviceLists = deviceListQuery.data;

  return (
    <>
      <DeviceListHeader
        deviceListQuery={deviceListQuery}
        setIsElectronErrorMessage={setIsElectronErrorMessage}
      />
      {deviceListQuery.isLoading || deviceListQuery.isFetching ? (
        <DeviceListSkeleton />
      ) : deviceListQuery.isError ? (
        <ErrorMessage>{deviceListQuery.error.message}</ErrorMessage>
      ) : isElectronErrorMessage ? (
        <ErrorMessage>{isElectronErrorMessage}</ErrorMessage>
      ) : (
        <Device deviceLists={deviceLists} />
      )}
    </>
  );
};

export default DeviceList;
