import { RootState } from '@app/store';
import { Button } from '@autosquare/common';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';
import { resetExecutionItem } from '@store/ide/device/distributionExecutionSlice';
import { hasDownloadedData } from '@store/ide/settings/settingsSlice';

import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Device from './DistributedExecutionSettings/Device';
import DeviceSkeleton from './DistributedExecutionSettings/DeviceSkeleton';
import NoDeviceList from './DistributedExecutionSettings/NoDeviceList';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import AddWirelessDeviceButtonInModal from '@components/AddWirelessDeviceButtonInModal/AddWirelessDeviceButtonInModal';

type Props = {
  testType: TestTypeCategory;
  divWidth: number;
  isSelectedDevices: boolean;
  needIosSetting: boolean;
  setNeedIosSetting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
};

const DistributedExecutionSettings = ({
  testType,
  divWidth,
  isSelectedDevices,
  needIosSetting,
  setNeedIosSetting,
  setIsSettingsOpen,
  setIsWirelessDeviceModal,
  setConnectionType,
}: Props) => {
  const dispatch = useDispatch();

  const selectedExecutionList = useSelector(
    (state: RootState) => state.distributionExecution.selectedExecutionList,
  );

  const deviceListQuery = useDeviceListQuery();
  const deviceList = deviceListQuery.data;

  const aosDeviceList = deviceList?.filter(
    (device) => device.os === DeviceInfoOs.Aos,
  );
  const iosDeviceList = deviceList?.filter(
    (device) => device.os === DeviceInfoOs.Ios,
  );

  const refreshButton = () => {
    dispatch(resetExecutionItem());
    deviceListQuery.refetch();
    setNeedIosSetting(false);
    setIsSettingsOpen(false);
  };

  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  useEffect(() => {
    if (isDownloadedData) {
      refreshButton();
      dispatch(hasDownloadedData(false));
    }
  }, [isDownloadedData]);

  const separatedDeviceList = [
    {
      os: 'Android',
      selectedLength: selectedExecutionList?.filter(
        (execution) => execution.device.os === DeviceInfoOs.Aos,
      )?.length,
      contents:
        deviceListQuery.isLoading || deviceListQuery.isFetching ? (
          <DeviceSkeleton />
        ) : deviceListQuery.isError ? (
          <p className="error-message">{deviceListQuery.error.message}</p>
        ) : aosDeviceList?.length === 0 ? (
          <NoDeviceList />
        ) : (
          aosDeviceList?.map((device) => (
            <Device
              device={device}
              testType={testType}
              key={device.serialNumber}
            />
          ))
        ),
    },
    {
      os: 'iOS',
      selectedLength: selectedExecutionList?.filter(
        (execution) => execution.device.os === DeviceInfoOs.Ios,
      )?.length,
      contents:
        deviceListQuery.isLoading || deviceListQuery.isFetching ? (
          <DeviceSkeleton />
        ) : deviceListQuery.isError ? (
          ''
        ) : iosDeviceList?.length === 0 ? (
          <NoDeviceList />
        ) : needIosSetting ? (
          <div className="col-span-full mt-3 flex w-full flex-col items-center justify-center gap-4 rounded-md border border-solid border-gray-200 bg-gray-50 p-2 text-base leading-5">
            <p className="text-red-500">Requires setup for iOS testing.</p>
            <Button
              type="button"
              variant="primary"
              onClick={() => setIsSettingsOpen(true)}
            >
              Open iOS Test Settings
            </Button>
          </div>
        ) : (
          iosDeviceList?.map((device) => (
            <Device
              device={device}
              testType={testType}
              key={device.serialNumber}
            />
          ))
        ),
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-5">
        {isSelectedDevices && selectedExecutionList?.length === 0 && (
          <p className="error-message py-0">
            테스트를 실행할 수 없습니다. 디바이스 연결 상태를 확인해 주세요.
          </p>
        )}
        <div className="flex items-center justify-between">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            {`Test ${testType === 'case' ? 'Case' : testType === 'scenario' && 'Scenario'} Distributes Execution Settings`}
          </h3>
          <div className="flex gap-x-4">
            <AddWirelessDeviceButtonInModal
              setIsWirelessDeviceModal={setIsWirelessDeviceModal}
              setConnectionType={setConnectionType}
              refresh={refreshButton}
            />
            <Button
              type="button"
              variant="black"
              buttonSize="sm"
              onClick={refreshButton}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="size-5"
              >
                <path
                  fillRule="evenodd"
                  d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                  clipRule="evenodd"
                />
              </svg>
              Refresh
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-5 text-sm font-medium leading-7 text-gray-900">
          {separatedDeviceList.map((separatedDevice) => (
            <div key={separatedDevice.os}>
              <div className="border-b border-solid border-gray-200 py-1.5">
                <div className="flex items-center gap-1.5">
                  <h4>{separatedDevice.os}</h4>
                  <span className="flex-center h-5 w-8 rounded-xl bg-gray-100">
                    {separatedDevice.selectedLength}
                  </span>
                </div>
              </div>
              <div
                className={clsx(
                  'grid gap-4 pt-4',
                  divWidth < 1280 ? 'grid-cols-3' : 'grid-cols-4',
                )}
              >
                {separatedDevice.contents}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DistributedExecutionSettings;
