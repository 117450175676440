import { useWebTestCaseDetailQuery } from '@lib/api/ide/web/webTestCase';

import PriorityListbox from '@components/shared/HeadlessUI/Listbox/PriorityListbox';

import { UpdateWebTestCaseData } from '@customTypes/testCase/type';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import TestGroupBox from '@components/shared/IDE/TestCase/TestGroupBox';

const WebTestCaseInfoDetailTop = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const { control } = useFormContext<UpdateWebTestCaseData>();

  const webTestCaseDetailQuery = useWebTestCaseDetailQuery(idx);
  const webTestCaseDetail = webTestCaseDetailQuery.data;

  return (
    <div className="grid grid-cols-1 gap-4 border-t border-gray-100 sm:grid-cols-3">
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Priority
        </dt>
        <Controller
          name="priority"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <PriorityListbox value={field.value} onChange={field.onChange} />
          )}
        />
      </div>
      <div className="py-6 sm:col-span-1">
        <TestGroupBox isRequired={false} />
      </div>
      <div className="py-6">
        <dt className="text-sm font-medium leading-6 text-gray-900">Creator</dt>
        <div className="relative mt-3 w-full text-sm leading-6 text-gray-700">
          {webTestCaseDetail?.creator}
        </div>
      </div>
    </div>
  );
};

export default WebTestCaseInfoDetailTop;
