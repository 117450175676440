import { CreateTestStepData } from "@customTypes/ide/mobileTestStep/mobileTestStep";

import React from "react";
import { useFormContext } from "react-hook-form";

const TestStepDescriptionHookForm = () => {
  const { register } = useFormContext<CreateTestStepData>();

  return (
    <div className="w-full">
      <label htmlFor="url" className="sr-only">
        Description
      </label>
      <input
        type="text"
        name="description"
        id="description"
        className="input-base"
        placeholder="Description"
        {...register("description", {
          required: "Description을 기입해주세요.",
        })}
      />
    </div>
  );
};

export default TestStepDescriptionHookForm;
