import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';

const IdeMain = () => {
  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  return (
    <>
      {accessToken === null ? (
        <Navigate to="/ide/login" state="!200" />
      ) : (
        <Navigate to="/ide/projects/list" />
      )}
    </>
  );
};

export default IdeMain;
