import { ScenarioListDetailData } from '@autosquare/common';
import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';

import React, { useCallback, useEffect } from 'react';
import update from 'react-addons-update';
import { useDrop } from 'react-dnd';
import { useFormContext } from 'react-hook-form';

import DetailCard from './DetailPaste/DetailCard';

interface Props {
  copyTestScenarios: ScenarioListDetailData[];
  setCopyTestScenarios: React.Dispatch<
    React.SetStateAction<ScenarioListDetailData[]>
  >;
  changeCardList: (list: ScenarioListDetailData[]) => void;
}
const DetailPaste = ({
  copyTestScenarios,
  setCopyTestScenarios,
  changeCardList,
}: Props) => {
  const { setValue } = useFormContext<UpdateSchedulerData>();

  useEffect(() => {
    setValue(
      'scenario_idx_list',
      [...copyTestScenarios].map((list) => list?.idx),
    );
  }, [copyTestScenarios]);

  const [, drop] = useDrop({
    accept: 'card',
  });

  const onRemove = useCallback((index: number) => {
    setCopyTestScenarios((prev) =>
      update(prev, {
        $splice: [[index, 1]],
      }),
    );
  }, []);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === undefined) {
        const lessIndex = copyTestScenarios.findIndex(
          (item) => item.dndIndex === -1,
        );
        changeCardList(
          update(copyTestScenarios, {
            $splice: [
              [lessIndex, 1],
              [hoverIndex, 0, { title: 'Here', dndIndex: -1 }],
            ],
          }),
        );
      } else {
        const dragCard = copyTestScenarios[dragIndex];
        changeCardList(
          update(copyTestScenarios, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        );
      }
    },
    [copyTestScenarios],
  );

  return (
    <div
      ref={drop}
      className="h-[calc(100%-0.5rem)] w-full overflow-y-scroll scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
    >
      {copyTestScenarios?.length === 0 ? (
        <div className="flex size-full flex-col items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
          <p className="mt-2">Drag and Drop here</p>
        </div>
      ) : (
        copyTestScenarios?.map((copyTestScenario, index) => (
          <DetailCard
            key={index}
            index={index}
            title={copyTestScenario?.title}
            moveCard={moveCard}
            onRemove={onRemove}
          />
        ))
      )}
    </div>
  );
};

export default DetailPaste;
