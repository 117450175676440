import ScenarioTestCaseListCard from '@components/SpeedTestScenario/ScenarioTestCaseListCard';
import SpeedTestScenarioTestCaseListContainer from '@components/SpeedTestScenario/SpeedTestScenarioTestCaseListContainer';

import { CreateMobileSpeedTestScenarioData } from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { SpeedTestCaseDataInScenario } from '@customTypes/speedTestCase/type';

import { useSpeedTestScenarioListDrop } from '@hooks/speedTestScenario/useSpeedTestScenarioListDrop';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import NoTestDropList from '@components/DragAndDrop/NoTestDropList';

type Props = {
  copiedTestCaseList: SpeedTestCaseDataInScenario[];
  setCopiedTestCaseList: React.Dispatch<
    React.SetStateAction<SpeedTestCaseDataInScenario[]>
  >;
  changeCardList: (list: SpeedTestCaseDataInScenario[]) => void;
};

const ScenarioTestCaseList = ({
  copiedTestCaseList,
  setCopiedTestCaseList,
  changeCardList,
}: Props) => {
  const { setValue } = useFormContext<CreateMobileSpeedTestScenarioData>();

  const { drop, onRemove, moveCard } = useSpeedTestScenarioListDrop(
    setValue,
    copiedTestCaseList,
    setCopiedTestCaseList,
    changeCardList,
  );

  return (
    <SpeedTestScenarioTestCaseListContainer drop={drop}>
      {copiedTestCaseList?.length === 0 ? (
        <NoTestDropList />
      ) : (
        copiedTestCaseList?.map((copiedTestCase, index) => (
          <ScenarioTestCaseListCard
            key={copiedTestCase.uniqueId}
            index={index}
            copiedTestCase={copiedTestCase}
            onRemove={onRemove}
            moveCard={moveCard}
          />
        ))
      )}
    </SpeedTestScenarioTestCaseListContainer>
  );
};
export default ScenarioTestCaseList;
