import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

export type AccessToken = {
  accessTokenValue: string | null;
  ideAccessToken: string | null;
};

const initialState: AccessToken = {
  accessTokenValue: null,
  ideAccessToken: null,
};

export const accessTokenSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    getAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessTokenValue = action.payload;
    },
    clearAccessToken: (state) => {
      state.accessTokenValue = undefined;
    },
    getIdeAccessToken: (state, action: PayloadAction<string | null>) => {
      state.ideAccessToken = action.payload;
    },
    clearIdeAccessToken: (state) => {
      state.ideAccessToken = undefined;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  getAccessToken,
  clearAccessToken,
  getIdeAccessToken,
  clearIdeAccessToken,
} = accessTokenSlice.actions;

export default accessTokenSlice.reducer;
