import { findColorFromName, priorityLists } from '@utils/static/priorityLists';

import { PriorityNameEnum } from '@customTypes/testCase/type';

import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

type Props = {
  value: PriorityNameEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
};

const PriorityListbox = ({ value, onChange }: Props) => {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className={`relative`}>
          <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span className="flex items-center">
              <span
                aria-label={value}
                className={clsx(
                  'inline-block size-2 shrink-0 rounded-full',
                  findColorFromName(value),
                )}
              />
              <span className="ml-3 block truncate">{value}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {priorityLists.map((priority) => (
                <ListboxOption
                  key={priority.id}
                  className={({ focus }) =>
                    `relative cursor-default select-none py-2 pl-3 pr-9 ${
                      focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    }`
                  }
                  value={priority.name}
                >
                  {({ selected, focus }) => (
                    <>
                      <div className="flex items-center">
                        <span
                          className={clsx(
                            'inline-block size-2 shrink-0 rounded-full',
                            priority?.color,
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={clsx(
                            'ml-3 block truncate',
                            selected ? 'font-semibold' : 'font-normal',
                          )}
                        >
                          {priority.name}
                          <span className="sr-only"> is {priority.name}</span>
                        </span>
                      </div>
                      {selected ? (
                        <span
                          className={clsx(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            focus ? 'text-white' : 'text-indigo-600',
                          )}
                        >
                          <CheckIcon className="size-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default PriorityListbox;
