import React from 'react';

type Props = {
  header: string;
  description: string;
};

const AddTestStepConditionHeader = ({ header, description }: Props) => {
  return (
    <div className="mt-2 border-b border-gray-200 pb-2">
      <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
        <h3 className="ml-2 mt-2 text-sm font-medium text-gray-900">
          {header}
        </h3>
        <p className="ml-2 mt-1 truncate text-xs text-gray-500">
          {description}
        </p>
      </div>
    </div>
  );
};

export default AddTestStepConditionHeader;
