import { RootState } from '@app/store';
import EmailAlarmCombobox from '@components/Scheduler/SpeedEmailAlarmCombobox';
import SelectedEmailCardList from '@components/Scheduler/SelectedEmailCardList';
import { UpdateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { useSchedulerEmailAlarm } from '@hooks/speedScheduler/useSchedulerEmailAlarm';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';

const SpeedTestSchedulerDetailEmailAlarm = () => {
  const { setValue, watch } = useFormContext<UpdateSpeedSchedulerData>();

  const mailReceivers = watch('mailReceivers');
  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const {
    wrapperRef,
    onChangeEmailAddress,
    setIsOpenedMemberList,
    inputRef,
    emailAddress,
    temporarySavedEmailAddress,
    setEmailAddress,
    setSelectedIndex,
    setEmailErrorMessage,
    filteredPeople,
    setTemporarySavedEmailAddress,
    selectedIndex,
    inputFocus,
    isOpenedMemberList,
    ulRef,
    onSelectMember,
    emailErrorMessage,
    deleteTagItem,
  } = useSchedulerEmailAlarm(mailReceivers, setValue);

  return (
    <SpeedSchedulerGridContainer title="Email Alarm" isTitleCenter={true}>
      <EmailAlarmCombobox
        wrapperRef={wrapperRef}
        onChangeEmailAddress={onChangeEmailAddress}
        setIsOpenedMemberList={setIsOpenedMemberList}
        inputRef={inputRef}
        emailAddress={emailAddress}
        temporarySavedEmailAddress={temporarySavedEmailAddress}
        setEmailAddress={setEmailAddress}
        setSelectedIndex={setSelectedIndex}
        setEmailErrorMessage={setEmailErrorMessage}
        filteredPeople={filteredPeople}
        setTemporarySavedEmailAddress={setTemporarySavedEmailAddress}
        selectedIndex={selectedIndex}
        inputFocus={inputFocus}
        isOpenedMemberList={isOpenedMemberList}
        ulRef={ulRef}
        onSelectMember={onSelectMember}
        mailReceivers={mailReceivers}
        setValue={setValue}
        disabled={!isRunning}
      />
      <p className="standard-error-message">{emailErrorMessage}</p>
      <SelectedEmailCardList
        mailReceivers={mailReceivers}
        deleteTagItem={deleteTagItem}
        disabled={!isRunning}
      />
    </SpeedSchedulerGridContainer>
  );
};

export default SpeedTestSchedulerDetailEmailAlarm;
