import React from 'react';
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelTitle: string;
  register: UseFormRegisterReturn;
  errors: FieldErrors;
}

const FormField = ({
  name,
  labelTitle,
  errors,
  register,
  ...attributes
}: Props) => {
  return (
    <div className="w-full sm:col-span-3">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {labelTitle}
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <span className="ml-2 text-error-message">{message}</span>
          )}
        />
      </label>
      <div className="mt-2">
        <input
          type="text"
          name={name}
          id={name}
          className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          {...register}
          {...attributes}
        />
      </div>
    </div>
  );
};

export default FormField;
