import { regexLibrary } from '@autosquare/common';

import {
  CreateWebSpeedTestStepData,
  UpdateWebSpeedTestStepData,
} from '@customTypes/ide/speedTest/Web/testStep/type';

import { useEffect } from 'react';
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

type Props = {
  setSelectedText: React.Dispatch<React.SetStateAction<string>>;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isSaved: boolean;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
  selectionStart: number;
  selectionEnd: number;
  setValue: UseFormSetValue<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
  watch: UseFormWatch<CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData>;
  setFocus: UseFormSetFocus<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
  setError: UseFormSetError<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
  clearErrors: UseFormClearErrors<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
};

export const useSpeedWebSetAsVariablePanel = ({
  watch,
  setSelectedText,
  setFocus,
  isOpened,
  setIsOpened,
  setError,
  clearErrors,
  isSaved,
  setIsSaved,
  setValue,
  selectionStart,
  selectionEnd,
}: Props) => {
  const optionsDto = watch('optionsDto');
  const currentIndex = optionsDto?.length - 1;
  const optionsDtoKeyNameList = optionsDto
    ?.map((option) => option.keyName)
    .slice(0, -1);

  const keyName = watch(`optionsDto.${currentIndex}.keyName`);
  const addValue = watch(`optionsDto.${currentIndex}.addValue`);
  const dateFormat = watch(`optionsDto.${currentIndex}.dateFormat`);

  useEffect(() => {
    if (isOpened) {
      setFocus(`optionsDto.${currentIndex}.keyName`);
    }
  }, [isOpened, setFocus, currentIndex]);

  const validateKeyName = () => {
    if (keyName?.length === 0) {
      setError(`optionsDto.${currentIndex}.keyName`, {
        type: 'required',
        message: 'Key Name을 입력해 주세요.',
      });
      return false;
    }
    if (keyName?.length > 50) {
      setError(`optionsDto.${currentIndex}.keyName`, {
        type: 'under length',
        message: '50자 이하 입력해 주세요.',
      });
      return false;
    }
    if (!regexLibrary.alphaNumericDashUnderscore.test(keyName)) {
      setError(`optionsDto.${currentIndex}.keyName`, {
        type: 'regex',
        message: 'dash(-), underbar(_), 영 대소문자, 숫자만 입력해 주세요.',
      });
      return false;
    }
    if (
      optionsDtoKeyNameList.some(
        (optionsDtoKeyName) => optionsDtoKeyName === keyName,
      )
    ) {
      setError(`optionsDto.${currentIndex}.keyName`, {
        type: 'duplication key name',
        message: '이미 존재하는 key Name입니다. 다른 Key Name을 입력해 주세요.',
      });
      return false;
    }
    clearErrors(`optionsDto.${currentIndex}.keyName`);
    return true;
  };

  const validateAddValue = () => {
    if (addValue < 0) {
      setError(`optionsDto.${currentIndex}.addValue`, {
        type: 'under zero',
        message: 'Value 값을 최소 0부터 입력해 주세요.',
      });
      return false;
    }
    if (addValue > 50) {
      setError(`optionsDto.${currentIndex}.addValue`, {
        type: 'over fifty',
        message: 'Value 값은 최대 50까지 입력가능합니다.',
      });
      return false;
    }
    if (isNaN(addValue)) {
      setError(`optionsDto.${currentIndex}.addValue`, {
        type: 'NaN Error',
        message: 'Value 값을 입력해 주세요.',
      });
      return false;
    }
    clearErrors(`optionsDto.${currentIndex}.addValue`);
    return true;
  };

  const validateDateFormat = () => {
    if (!dateFormat) {
      setError(`optionsDto.${currentIndex}.dateFormat`, {
        type: 'required',
        message: 'Date Format의 년, 월, 일 중 1가지 이상 선택해 주세요.',
      });
      return false;
    }
    clearErrors(`optionsDto.${currentIndex}.dateFormat`);
    return true;
  };

  const handleSave = () => {
    setIsSaved(true);

    const isKeyNameValid = validateKeyName();
    const isAddValueValid = validateAddValue();
    const isDateFormatValid = validateDateFormat();

    if (isKeyNameValid && isAddValueValid && isDateFormatValid) {
      const url = watch('url');
      const keyName = watch(`optionsDto.${currentIndex}.keyName`);

      const variable = `{{${keyName}}}`;

      const newUrl =
        url.slice(0, selectionStart) + variable + url.slice(selectionEnd);

      setValue('url', newUrl);
      setValue(`optionsDto.${currentIndex}.keyName`, keyName);
      setValue(
        'optionsDto',
        optionsDto.sort((a, b) => a.start - b.start),
      );

      setSelectedText('');
      setIsOpened(false);
      setIsSaved(false);
    }
  };

  useEffect(() => {
    if (isSaved) {
      validateKeyName();
      validateAddValue();
      validateDateFormat();
    }
  }, [
    keyName,
    addValue,
    dateFormat,
    isSaved,
    currentIndex,
    setError,
    clearErrors,
  ]);

  return { handleSave };
};
