import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import type { PayloadAction } from '@reduxjs/toolkit';

import { BrowserInfo } from '@customTypes/ide/browser/browser';

export type SelectedBrowser = {
  selectedBrowserArray: BrowserInfo[];
};

const initialState: SelectedBrowser = {
  selectedBrowserArray: [],
};

export const selectedBrowserSlice = createSlice({
  name: 'Selected Browser',
  initialState,
  reducers: {
    getSelectedBrowser: (state, action: PayloadAction<BrowserInfo[]>) => {
      state.selectedBrowserArray = action.payload;
    },
    resetSelectedBrowser: (state) => {
      state.selectedBrowserArray = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getSelectedBrowser, resetSelectedBrowser } =
  selectedBrowserSlice.actions;

export default selectedBrowserSlice.reducer;
