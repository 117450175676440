import React from "react";
import { Link } from "react-router-dom";

import {
  TableBodyBasic,
  TableBodyBasicNoData,
  TableHeader,
} from "@autosquare/common";

import { AdminProjectListDetail } from "@customTypes/dashboard/admin/type";

type Props = {
  adminProjectList: AdminProjectListDetail[];
};

const AdminProjectListTable = ({ adminProjectList }: Props) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          {tableHeaders?.map((tableHeader) => (
            <TableHeader key={tableHeader}>{tableHeader}</TableHeader>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {adminProjectList?.length === 0 ? (
          <TableBodyBasicNoData colSpan={tableHeaders?.length}>
            프로젝트가 존재하지 않습니다.
          </TableBodyBasicNoData>
        ) : (
          adminProjectList?.map((adminProject) => (
            <tr
              key={adminProject?.idx}
              className={`border-y-[1px] border-y-solid border-y-gray-200 last:border-y-0 ${
                adminProject?.status === "Deactivate" && "bg-gray-100"
              }`}
            >
              <TableBodyBasic>{adminProject?.name}</TableBodyBasic>
              <TableBodyBasic isBreakAll={false}>
                {adminProject?.description}
              </TableBodyBasic>
              <TableBodyBasic>{adminProject?.userCount}</TableBodyBasic>
              <TableBodyBasic>{adminProject?.projectAdmin}</TableBodyBasic>
              <TableBodyBasic>{adminProject?.status}</TableBodyBasic>
              <TableBodyBasic>{adminProject?.updatedAt}</TableBodyBasic>
              <TableBodyBasic>
                <Link to={`/admin/project/details?idx=${adminProject?.idx}`}>
                  <button
                    type="button"
                    className="text-gray-900 hover:text-congress-blue"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                      />
                    </svg>
                  </button>
                </Link>
              </TableBodyBasic>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default AdminProjectListTable;

const tableHeaders = [
  "Name",
  "Description",
  "User Count",
  "Project Admin",
  "Status",
  "UpdatedAt",
  "Detail",
];
