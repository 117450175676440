import AllTestCaseListInDraggableElement from '@components/SpeedTestScenario/AllTestCaseListInDraggableElement';
import DraggableElementContainer from '@components/SpeedTestScenario/DraggableElementContainer';
import ScenarioTestCaseListContainer from '@components/SpeedTestScenario/ScenarioTestCaseListContainer';
import { useSpeedTestScenarioDraggableSpace } from '@hooks/speedTestScenario/useSpeedTestScenarioDraggableSpace';
import React from 'react';
import WebSpeedTestScenarioDetailScenarioTestCaseList from './WebSpeedTestScenarioDetailDraggableSpace/WebSpeedTestScenarioDetailScenarioTestCaseList';

const WebSpeedTestScenarioDetailDraggableSpace = () => {
  const {
    copiedTestCaseList,
    setCopiedTestCaseList,
    searchWord,
    onChangeSearchWord,
    setSearchWord,
    changeCardList,
    searchResultTestCaseList,
    webSpeedTestCaseList,
    isLoading,
  } = useSpeedTestScenarioDraggableSpace('detail', 'web');

  return (
    <DraggableElementContainer>
      <AllTestCaseListInDraggableElement
        isLoading={isLoading}
        speedTestCaseList={webSpeedTestCaseList}
        searchResultTestCaseList={searchResultTestCaseList}
        copiedTestCaseList={copiedTestCaseList}
        changeCardList={changeCardList}
        searchWord={searchWord}
        onChangeSearchWord={onChangeSearchWord}
        setSearchWord={setSearchWord}
      />
      <ScenarioTestCaseListContainer>
        <WebSpeedTestScenarioDetailScenarioTestCaseList
          isLoading={isLoading}
          copiedTestCaseList={copiedTestCaseList}
          setCopiedTestCaseList={setCopiedTestCaseList}
          changeCardList={changeCardList}
        />
      </ScenarioTestCaseListContainer>
    </DraggableElementContainer>
  );
};

export default WebSpeedTestScenarioDetailDraggableSpace;
