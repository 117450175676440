import { UpdateTestCaseServiceGroup } from '@customTypes/serviceGroup/type';
import {
  CreateTestCaseGroup,
  TestCaseGroup,
  UpdateTestCaseGroup,
} from '@customTypes/testCaseGroup/type';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form';

export const checkForDuplicateCreate = (
  groupList: TestCaseGroup[],
  title: string,
  setError: UseFormSetError<CreateTestCaseGroup>,
  clearErrors: UseFormClearErrors<CreateTestCaseGroup>,
) => {
  const isDuplicated = groupList.some((item) => item.name === title);
  if (isDuplicated) {
    setError('name', {
      type: 'manual',
      message: '해당 이름으로 이미 그룹이 생성되어 있습니다',
    });
    return true;
  } else {
    clearErrors('name');
    return false;
  }
};

export const checkForDuplicateEdit = (
  groupList: TestCaseGroup[],
  selectedData: TestCaseGroup,
  title: string,
  setError: UseFormSetError<UpdateTestCaseGroup | UpdateTestCaseServiceGroup>,
  clearErrors: UseFormClearErrors<
    UpdateTestCaseGroup | UpdateTestCaseServiceGroup
  >,
) => {
  const isDuplicated = groupList
    ?.filter((item) => item?.name !== selectedData?.name)
    .some((item) => item.name === title);

  if (isDuplicated) {
    setError('name', {
      type: 'manual',
      message: '해당 이름으로 이미 그룹이 생성되어 있습니다',
    });
    return true;
  } else {
    clearErrors('name');
    return false;
  }
};
