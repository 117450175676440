import { DeviceInfoOs } from '@customTypes/ide/device/device';
import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { Fragment, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Switch } from '@headlessui/react';

import From from './ClickTextReplace/From';
import Add from './ClickTextReplace/Add';
import DateFormat from './ClickTextReplace/DateFormat';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const ClickTextReplace = ({ mobileTestStepData }: Props) => {
  const { control, watch, setValue, resetField } =
    useFormContext<UpdateTestStepData>();

  const value = watch('value');
  const textReplace = watch('optionsDto.textReplace');

  const isTextReplaceDisabled =
    mobileTestStepData.mobileOs === DeviceInfoOs.Aos
      ? !value.includes('@text')
      : !value.includes('@label');

  useEffect(() => {
    if (isTextReplaceDisabled) {
      setValue('optionsDto.textReplace', 'false');
    }
  }, [value, setValue, resetField]);

  return (
    <>
      <div
        className={`items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4`}
      >
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
          Text Replace
        </dt>
        <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
          <div className="w-full">
            <Controller
              control={control}
              name={'optionsDto.textReplace'}
              defaultValue={mobileTestStepData.optionsDto.textReplace}
              render={({ field }) => (
                <Switch
                  checked={field.value === 'true'}
                  onChange={(checked: boolean) =>
                    field.onChange(checked ? 'true' : 'false')
                  }
                  disabled={isTextReplaceDisabled}
                  as={Fragment}
                >
                  {({ checked, disabled }) => (
                    <button
                      className={`group inline-flex h-6 w-11 items-center rounded-full ${checked ? 'bg-congress-blue' : 'bg-gray-200'} ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
                    >
                      <span className="sr-only">Switch Enabled</span>
                      <span
                        className={`size-4 rounded-full bg-white transition ${checked ? 'translate-x-6' : 'translate-x-1'}`}
                      />
                    </button>
                  )}
                </Switch>
              )}
            />
          </div>
        </dd>
      </div>
      {textReplace === 'true' && (
        <>
          <From
            from={mobileTestStepData?.optionsDto?.textReplaceOptions?.from}
          />
          <Add
            addKey={mobileTestStepData?.optionsDto?.textReplaceOptions?.addKey}
            addValue={
              mobileTestStepData?.optionsDto?.textReplaceOptions?.addValue
            }
          />
          <DateFormat
            textReplaceOptions={
              mobileTestStepData?.optionsDto?.textReplaceOptions
            }
          />
        </>
      )}
    </>
  );
};

export default ClickTextReplace;
