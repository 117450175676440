import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getRefreshToken } from '@store/api/refreshTokenSlice';

export const useGetRefreshToken = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [cookie, setCookie] = useState<string | null>(null);

  useEffect(() => {
    window.electron.on('cookieValue', (value: string) => {
      setCookie(value);
      dispatch(getRefreshToken(value));
    });

    return () => {
      window.electron.removeAllListeners('cookieValue');
    };
  }, [pathname]);

  return { cookie };
};
