import { ErrorMessage, PagingHeadingsInAutosquare } from '@autosquare/common';

import React from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useMobileTestCaseDetailQuery } from '@lib/api/ide/mobile/mobileTestCase';

import MobileTestStepDetailProvider from '@contexts/ide/MobileTestStepDetailProvider';
import MobileTestCaseDetailProvider from '@contexts/ide/MobileTestCaseDetailProvider';

import TestCaseInfo from './components/TestCaseInfo';
import TestStepList from './components/TestStepList';

const MobileTestCaseDetail = () => {
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const mobileTestCaseDetailQuery = useMobileTestCaseDetailQuery(idx);

  const testCasePage = `/ide/projects/${projectIdx}/ui-test/mobile/test-case`;

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: testCasePage.split('/').slice(0, -2).join('/') + '/overview',
    },
    { title: 'Mobile', href: testCasePage },
    { title: 'Test Case', href: testCasePage },
    { title: 'Detail', href: pathname + search },
  ];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case Detail'}
      />
      {mobileTestCaseDetailQuery.isError ? (
        <ErrorMessage>{mobileTestCaseDetailQuery.error.message}</ErrorMessage>
      ) : (
        <MobileTestCaseDetailProvider>
          <TestCaseInfo idx={idx} />
          <MobileTestStepDetailProvider>
            <TestStepList />
          </MobileTestStepDetailProvider>
        </MobileTestCaseDetailProvider>
      )}
    </>
  );
};

export default MobileTestCaseDetail;
