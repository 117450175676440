import { useGroupContext } from '@contexts/ide/GroupProvider';
import React from 'react';

type Props = {
  closeModal?: (value: boolean) => void;
};
const GroupModalHeader = ({ closeModal }: Props) => {
  const { setTabGroupType, setIsTestGroupClicked, setIsServiceGroupClicked } =
    useGroupContext();

  return (
    <div className="border-b border-gray-200 mb-4 bg-congress-blue p-4 rounded-t-lg">
      <div className="flex flex-wrap items-center justify-between text-white">
        <div className="flex flex-row items-center gap-4">
          <h3 className="text-base font-semibold leading-6">
            Group Management
          </h3>
        </div>
        <button
          type="button"
          className="inline-flex items-center rounded-md px-2 py-2 text-sm font-semibold text-white hover:text-gray-200"
          onClick={() => {
            closeModal(false);
            setTabGroupType('Test');
            setIsTestGroupClicked(true);
            setIsServiceGroupClicked(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default GroupModalHeader;
