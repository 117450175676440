import { PURGE } from "redux-persist";

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface MobileScreenshotSource {
  screenshotSource: string | null;
  refetch: () => void;
}

const initialState: MobileScreenshotSource = {
  screenshotSource: null,
  refetch: null,
};

export const mobileScreenshotSourceSlice = createSlice({
  name: "mobile screenshot source",
  initialState,
  reducers: {
    getMobileScreenshotSource: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.screenshotSource = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getMobileScreenshotSource } =
  mobileScreenshotSourceSlice.actions;

export default mobileScreenshotSourceSlice.reducer;
