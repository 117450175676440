import { ScenarioListDetailData } from '@autosquare/common';
import { CreateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import React, { useEffect } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useFormContext } from 'react-hook-form';

interface Props {
  copyTestScenarios: ScenarioListDetailData[];
  title: string;
  step: number[] | null;
  idx: number;
  description: string;
  caseCount: number;
  createdAt: string;
  modifiedAt: string;
  changeCardList: (list: ScenarioListDetailData[]) => void;
}

let id = 1;

const ListItemCard = ({
  copyTestScenarios,
  title,
  step,
  idx,
  caseCount,
  description,
  createdAt,
  modifiedAt,
  changeCardList,
}: Props) => {
  const box = {
    title,
    description,
    step,
    caseCount,
    idx,
  };
  const { setValue } = useFormContext<CreateSchedulerData>();
  useEffect(() => {
    const scenarioIdxList = copyTestScenarios?.map(
      (testScenario) => testScenario.idx,
    );
    setValue('scenario_idx_list', scenarioIdxList);
  }, [copyTestScenarios, setValue]);

  const [, drag] = useDrag({
    type: 'card',
    item() {
      const useless = copyTestScenarios.find(
        (copyTestCase) => copyTestCase.idx === -1,
      );
      if (!useless) {
        changeCardList([
          ...copyTestScenarios,
          {
            title: 'Move',
            idx: idx,
            description: description,
            step: step,
            caseCount: caseCount,
            createdAt: createdAt,
            modifiedAt: modifiedAt,
            dndIndex: -1,
          },
        ]);
      }
      return box;
    },
    end(_: unknown, monitor: DragSourceMonitor) {
      const uselessIndex = copyTestScenarios.findIndex(
        (copyTestCase) => copyTestCase.dndIndex === -1,
      );

      if (monitor.didDrop()) {
        copyTestScenarios.splice(uselessIndex, 1, {
          ...monitor.getItem(),
          dndIndex: id++,
        });
      } else {
        copyTestScenarios.splice(uselessIndex, 1);
      }
      changeCardList(copyTestScenarios);
    },
  });

  return (
    <div
      ref={drag}
      className={
        'm-2 flex cursor-move items-center border border-solid border-border-line bg-gray-50 px-3 py-2'
      }
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 9h16.5m-16.5 6.75h16.5"
          />
        </svg>
      </div>
      <div className="ml-7">{title}</div>
    </div>
  );
};

export default ListItemCard;
