import {
  Button,
  Checkbox,
  ErrorMessage,
  useCheckbox,
} from '@autosquare/common';

import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ExecutionAndDialog from './TestStepListTable/ExecutionAndDialog';
import TestStepListTableHeader from './TestStepListTable/TestStepListTableHeader';
import TestStepListTableBody from './TestStepListTable/TestStepListTableBody';

import { useMobileTestStepByCaseQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useUpdateTestCaseListSequenceMutation } from '@lib/api/ide/mobile/mobileTestCase';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { DropResult } from 'react-beautiful-dnd';

interface Props {
  title: DeviceInfoOs;
}

const TestStepListTable = ({ title }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isTestStepExecutionOpened, setIsTestStepExecutionOpened] =
    useState(false);
  const [lists, setLists] = useState<MobileTestStepData[]>([]);
  const [indexArray, setIndexArray] = useState<number[]>(null);

  const mobileTestStepInTestCaseQuery = useMobileTestStepByCaseQuery(idx);
  const mobileTestStepList = mobileTestStepInTestCaseQuery.data;

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(
    title === DeviceInfoOs.Aos
      ? mobileTestStepList?.aos
      : mobileTestStepList?.ios,
    'idx',
  );

  useEffect(() => {
    setLists(
      title === DeviceInfoOs.Aos
        ? mobileTestStepList?.aos
        : mobileTestStepList?.ios,
    );
  }, [mobileTestStepList]);

  const updateTestCaseListSequenceMutation =
    useUpdateTestCaseListSequenceMutation({
      idx: idx,
      mobileOs: title,
      indexArray: indexArray,
    });

  useEffect(() => {
    updateTestCaseListSequenceMutation.mutate();
  }, [indexArray]);

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const items = Array.from(lists);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLists(items);
      setIndexArray(items.map((items) => items.idx));
    },
    [lists],
  );

  const tableTitles = [
    {
      idx: 1,
      element: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
        />
      ),
    },
    { idx: 2, element: selectedArray.length > 0 ? '' : '#' },
    { idx: 3, element: selectedArray.length > 0 ? '' : 'Command' },
    { idx: 4, element: 'Description' },
  ];

  return (
    <>
      {mobileTestStepInTestCaseQuery.isError ? (
        <ErrorMessage>
          {mobileTestStepInTestCaseQuery.error.message}
        </ErrorMessage>
      ) : (
        <div className="mt-6 grid-cols-1">
          <h3 className="text-base font-semibold">
            {title === DeviceInfoOs.Aos ? 'Android' : DeviceInfoOs.Ios && 'iOS'}
          </h3>
          <div className="flow-root">
            <div className="-mx-4 -my-2 overflow-x-hidden scrollbar-thin sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {selectedArray ? (
                  <div className="relative">
                    {selectedArray.length > 0 && (
                      <div className="div-delete-all left-16 2xl:left-20">
                        <Button
                          type="button"
                          variant="secondary"
                          buttonSize="xs"
                          onClick={openDialog}
                        >
                          Delete all
                        </Button>
                      </div>
                    )}
                  </div>
                ) : null}
                <table className="h-full min-w-full divide-y divide-gray-300">
                  <TestStepListTableHeader tableTitles={tableTitles} />
                  <TestStepListTableBody
                    title={title}
                    mobileTestStepList={mobileTestStepList}
                    tableHeaderLength={tableTitles.length}
                    handleOnDragEnd={handleOnDragEnd}
                    selectArray={selectArray}
                    selectedArray={selectedArray}
                    setIsTestStepExecutionOpened={setIsTestStepExecutionOpened}
                    lists={lists}
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <ExecutionAndDialog
        isTestStepExecutionOpened={isTestStepExecutionOpened}
        setIsTestStepExecutionOpened={setIsTestStepExecutionOpened}
        title={title}
        isOpenedCautionDialog={isOpenedCautionDialog}
        setIsOpenedCautionDialog={setIsOpenedCautionDialog}
        selectedArray={selectedArray}
        openDialog={openDialog}
        closeDialog={closeDialog}
        resetSelectedArray={resetSelectedArray}
      />
    </>
  );
};

export default TestStepListTable;
