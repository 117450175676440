import {
  ListBoxHeadlessInController,
  QuestionsMarkCircle,
  TooltipTop,
} from '@autosquare/common';
import {
  DayServerName,
  MonthServerName,
  SeparatorServerName,
  YearServerName,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

import {
  CreateWebSpeedTestStepData,
  UpdateWebSpeedTestStepData,
} from '@customTypes/ide/speedTest/Web/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import { isMacOs } from '@utils/static/isMacOs';
import {
  dateFormatDayList,
  dateFormatMonthList,
  dateFormatSeparatorList,
  dateFormatYearList,
  findDateFormatDayNameByServerName,
  findDateFormatMonthNameByServerName,
  findDateFormatSeparatorNameByServerName,
  findDateFormatYearNameByServerName,
} from '@utils/static/mobileTestStep/textReplace/dateFormatList';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

type Props = {
  watch: UseFormWatch<CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData>;
  setValue: UseFormSetValue<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
  control: Control<CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData>;
  errors: FieldErrors<CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData>;
};

const DateFormat = ({ watch, setValue, control, errors }: Props) => {
  const [selectedYear, setSelectedYear] = useState<YearServerName>(
    YearServerName.None,
  );
  const [selectedMonth, setSelectedMonth] = useState<MonthServerName>(
    MonthServerName.None,
  );
  const [selectedDay, setSelectedDay] = useState<DayServerName>(
    DayServerName.None,
  );
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const currentIndex = watch('optionsDto')?.length - 1;

  const separator = watch(`optionsDto.${currentIndex}.separator`);

  useEffect(() => {
    const dateFormat =
      separator === SeparatorServerName.None
        ? `${selectedYear}${selectedMonth}${selectedDay}`
        : separator === SeparatorServerName.Space
          ? `${selectedYear}${(selectedYear && selectedMonth) || (selectedYear && selectedDay) ? ' ' : ''}${selectedMonth}${selectedMonth && selectedDay ? ' ' : ''}${selectedDay}`
          : `${selectedYear}${(selectedYear && selectedMonth) || (selectedYear && selectedDay) ? findDateFormatSeparatorNameByServerName(separator) : ''}${selectedMonth}${selectedMonth && selectedDay ? findDateFormatSeparatorNameByServerName(separator) : ''}${selectedDay}`;

    setValue(`optionsDto.${currentIndex}.dateFormat` as const, dateFormat);
  }, [
    selectedYear,
    selectedMonth,
    selectedDay,
    separator,
    setValue,
    currentIndex,
  ]);

  const dateFormatList = [
    {
      idx: 1,
      title: 'Year',
      listbox: (
        <ListBoxHeadlessInController
          value={selectedYear}
          buttonValue={findDateFormatYearNameByServerName(selectedYear)}
          onChange={setSelectedYear}
          lists={dateFormatYearList}
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      ),
    },
    {
      idx: 2,
      title: 'Month',
      listbox: (
        <ListBoxHeadlessInController
          value={selectedMonth}
          buttonValue={findDateFormatMonthNameByServerName(selectedMonth)}
          onChange={setSelectedMonth}
          lists={dateFormatMonthList}
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      ),
    },
    {
      idx: 3,
      title: 'Day',
      listbox: (
        <ListBoxHeadlessInController
          value={selectedDay}
          buttonValue={findDateFormatDayNameByServerName(selectedDay)}
          onChange={setSelectedDay}
          lists={dateFormatDayList}
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      ),
    },
    {
      idx: 4,
      title: 'Separator',
      listbox: (
        <Controller
          control={control}
          name={`optionsDto.${currentIndex}.separator` as const}
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={findDateFormatSeparatorNameByServerName(field.value)}
              onChange={field.onChange}
              lists={dateFormatSeparatorList}
              valueToSave={'serverName'}
              valueToShow={'name'}
            />
          )}
        />
      ),
    },
  ];

  return (
    <>
      <div className="relative grid grid-cols-5 items-start justify-between">
        <div className="flex items-center gap-1 pt-5">
          <span>Date Format*</span>
          <div className="flex items-center">
            <button
              type="button"
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
            >
              <QuestionsMarkCircle />
            </button>
          </div>
          {tooltipVisible && (
            <TooltipTop
              positionClass={clsx(
                '-top-6 left-0',
                isMacOs ? 'after:left-[30%]' : 'after:left-[27%]',
              )}
            >
              사용자가 치환한 값의 날짜 형식을 지정해 주는 기능입니다.
            </TooltipTop>
          )}
        </div>
        <div className="col-span-4 grid grid-cols-2 gap-4 text-xs font-normal leading-3 text-gray-500">
          {dateFormatList?.map((dateFormat) => (
            <div key={dateFormat.idx}>
              <p className="py-1.5 pl-3 pr-1">{dateFormat.title}</p>
              {dateFormat.listbox}
            </div>
          ))}
        </div>
      </div>
      <div className="h-20">
        <ErrorMessage
          errors={errors}
          name={`optionsDto.${currentIndex}.dateFormat`}
          render={({ message }) => <p className="error-message">{message}</p>}
        />
      </div>
    </>
  );
};

export default DateFormat;
