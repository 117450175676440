import NoTestDropList from '@components/DragAndDrop/NoTestDropList';
import SchedulerCopiedTestScenarioListContainer from '@components/Scheduler/SchedulerCopiedTestScenarioListContainer';
import SchedulerTestScenarioListCard from '@components/Scheduler/SchedulerTestScenarioListCard';
import DraggableCardSkeleton from '@components/shared/Skeleton/DraggableCardSkeleton';
import { UpdateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { SpeedTestScenarioDataInScheduler } from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { useSpeedTestSchedulerListDrop } from '@hooks/speedTestScheduler/useSpeedTestSchedulerListDrop';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  isLoading: boolean;
  copiedTestScenarioList: SpeedTestScenarioDataInScheduler[];
  setCopiedTestScenarioList: React.Dispatch<
    React.SetStateAction<SpeedTestScenarioDataInScheduler[]>
  >;
  changeCardList: (list: SpeedTestScenarioDataInScheduler[]) => void;
};

const SpeedSchedulerDetailTestScenarioList = ({
  isLoading,
  copiedTestScenarioList,
  setCopiedTestScenarioList,
  changeCardList,
}: Props) => {
  const { setValue } = useFormContext<UpdateSpeedSchedulerData>();

  const { drop, onRemove, moveCard } = useSpeedTestSchedulerListDrop(
    setValue,
    copiedTestScenarioList,
    setCopiedTestScenarioList,
    changeCardList,
  );

  return (
    <SchedulerCopiedTestScenarioListContainer drop={drop}>
      {isLoading ? (
        <DraggableCardSkeleton
          cardLength={4}
          hasProperty={false}
          hasBinIcon={true}
        />
      ) : copiedTestScenarioList?.length === 0 ? (
        <NoTestDropList />
      ) : (
        copiedTestScenarioList?.map((testScenario, index) => (
          <SchedulerTestScenarioListCard
            key={index}
            index={index}
            testScenario={testScenario}
            onRemove={onRemove}
            moveCard={moveCard}
          />
        ))
      )}
    </SchedulerCopiedTestScenarioListContainer>
  );
};

export default SpeedSchedulerDetailTestScenarioList;
