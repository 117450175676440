import { createSlice } from '@reduxjs/toolkit';

export type OpenCreateTestGroup = {
  isOpen: boolean;
};

const initialState: OpenCreateTestGroup = {
  isOpen: false,
};

const createGroupSlice = createSlice({
  name: 'createGroup',
  initialState,
  reducers: {
    openCreateGroup: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openCreateGroup } = createGroupSlice.actions;
export default createGroupSlice.reducer;
