import React from 'react';

type Props = {
  title: string;
  description: string;
};

const DescriptionList = ({ title, description }: Props) => {
  const parts = description.split(/(\{\{.*?\}\})/);

  return (
    <dl className="grid grid-cols-8 py-1.5 text-sm font-medium leading-normal text-gray-900">
      <dt className="col-span-1">{title}</dt>
      <dd className="col-span-7 font-normal">
        {parts.map((part, index) =>
          part.match(/\{\{.*?\}\}/) ? (
            <span key={index} className="text-congress-blue">
              {part}
            </span>
          ) : (
            <span key={index} className="break-words">
              {part}
            </span>
          ),
        )}
      </dd>
    </dl>
  );
};

export default DescriptionList;
