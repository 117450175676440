import { RootState } from '@app/store';

import { updatePoint } from '@store/ide/mobileTestStep/unlockPatternSlice';
import { addUuid, clearUuid } from '@store/ide/mobileTestStep/uuidSlice';

import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import React from 'react';
import { Circle, Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  stageWidth: number;
  stageHeight: number;
}

const PatternDrawer = ({ stageWidth, stageHeight }: Props) => {
  const dispatch = useDispatch();

  const selectedUuid = useSelector((state: RootState) => state.uuid.uuid);
  const unlockPatternPoints = useSelector(
    (state: RootState) => state.unlockPattern.points,
  );

  const flatPoints: number[] = unlockPatternPoints?.reduce(
    (acc, point) =>
      acc.concat([point.pointsCoordinate.x, point.pointsCoordinate.y]),
    [],
  );

  const clampPosition = (
    pos: Vector2d,
    stageWidth: number,
    stageHeight: number,
  ): Vector2d => {
    let newX = pos.x;
    let newY = pos.y;

    if (newX < 0) newX = 0;
    if (newX > stageWidth) newX = stageWidth;
    if (newY < 0) newY = 0;
    if (newY > stageHeight) newY = stageHeight;

    return { x: newX, y: newY };
  };

  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    e.target.moveToTop();
    const layer = e.target.getLayer();
    if (layer) {
      layer.draw();
    }
  };

  const handleDragEnd = (e: KonvaEventObject<DragEvent>, index: number) => {
    const newPosition = clampPosition(
      { x: e.target.x(), y: e.target.y() },
      stageWidth,
      stageHeight,
    );
    dispatch(
      updatePoint({
        index,
        pointsCoordinate: { x: newPosition.x, y: newPosition.y },
      }),
    );
    dispatch(clearUuid());

    const layer = e.target.getLayer();
    if (layer) {
      layer.draw();
    }
  };

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>, uuid: string) => {
    e.target.getStage().container().style.cursor = 'grabbing';
    dispatch(addUuid(uuid));
  };

  const handleMouseUp = (e: KonvaEventObject<MouseEvent>) => {
    e.target.getStage().container().style.cursor = 'grab';
    dispatch(clearUuid());
  };

  const handleMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    e.target.getStage().container().style.cursor = 'grab';
  };

  const handleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    e.target.getStage().container().style.cursor = 'default';
  };

  return (
    <>
      <Line
        points={flatPoints}
        stroke={'#EF4444'}
        strokeWidth={4}
        lineCap={'round'}
        lineJoin={'round'}
        listening={false}
      />
      {unlockPatternPoints?.map((point, index) => (
        <Circle
          key={point.uuid}
          x={point.pointsCoordinate.x}
          y={point.pointsCoordinate.y}
          fill={selectedUuid === point.uuid ? '#facc15' : '#EF4444'}
          radius={10}
          draggable
          dragBoundFunc={(pos) => clampPosition(pos, stageWidth, stageHeight)}
          onDragStart={handleDragStart}
          onDragEnd={(e) => handleDragEnd(e, index)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseDown={(e) => handleMouseDown(e, point.uuid)}
          onMouseUp={handleMouseUp}
          stroke={selectedUuid === point.uuid ? '#eab308' : '#DC2626'}
          strokeWidth={3}
        />
      ))}
    </>
  );
};

export default PatternDrawer;
