import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UuidState {
  uuid: string | undefined;
}

const initialState: UuidState = {
  uuid: undefined,
};

const uuidSlice = createSlice({
  name: 'uuid',
  initialState,
  reducers: {
    addUuid: (state, action: PayloadAction<string>) => {
      state.uuid = action.payload;
    },
    clearUuid: (state) => {
      state.uuid = undefined;
    },
  },
});

export const { addUuid, clearUuid } = uuidSlice.actions;

export default uuidSlice.reducer;
