export enum ProjectListDetailFeaturesCategory {
  Mobile = 'mobile_app',
  Web = 'web',
}

export enum ProjectListDetailFeaturesFeature {
  Functional = 'functional_test',
  Speed = 'speed_test',
}

export interface ProjectListDetailFeatures {
  category: ProjectListDetailFeaturesCategory;
  feature: ProjectListDetailFeaturesFeature;
  activate: boolean;
}

export interface ProjectListDetail {
  idx: number;
  name: string;
  description?: string;
  updatedAt: string;
  serviceName?: null | string;
  projectAdminList: { userIdx: number; name: string; email: string }[];
  features: ProjectListDetailFeatures[];
}

export interface ProjectMember {
  idx: number;
  name: string;
  userId: string;
  groupName: string;
}

export type ProjectMemberList = {
  idx: number;
  name: string;
  userId: string;
  projectRole: string;
  lastSeen: string;
};

export interface AddMemberData {
  addUserId: string;
  addUserRole: string;
}

export interface UpdateMemberInfoData {
  projectRole: string;
}
