import {
  UpdateWebTestStepData,
  UpdateWebTestStepDataKeys,
} from '@customTypes/testStep/type';

import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  name: UpdateWebTestStepDataKeys;
};

const WebTestStepDetailConfirmInputTextarea = ({ name }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const { ref, ...rest } = register(name, {
    required: 'Value값을 입력해 주세요.',
  });

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (textareaRef.current) {
      setHeight(textareaRef.current.scrollHeight);
    }
  }, []);

  useImperativeHandle(ref, () => textareaRef.current);

  return (
    <div className="w-full">
      <label htmlFor={name} className="sr-only">
        {name}
      </label>
      <textarea
        name={name}
        id={name}
        style={{ height }}
        className="block w-full overflow-y-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 scrollbar-thin placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        ref={textareaRef}
        {...rest}
      />
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => (
          <p className="standard-error-message">{message}</p>
        )}
      />
    </div>
  );
};

export default WebTestStepDetailConfirmInputTextarea;
