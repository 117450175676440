import axios from 'axios';
import * as Sentry from '@sentry/react';

import { store } from '@app/store';
import { getAccessToken, getIdeAccessToken } from '@store/api/accessTokenSlice';
import { decodeJwtToken } from '@autosquare/common';
import { getPreviousLink } from '@store/data/previousLinkSlice';

export const axiosInstance = axios.create({
  baseURL: `${location.protocol}//${location.port === '3000' || location.port === '3002' ? location.hostname : location.host}`,
  timeout: 6000,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().accessTokenValue.ideAccessToken;
    if (accessToken) {
      config.headers.Authorization = accessToken;
      const userId = decodeJwtToken(accessToken)?.sub;
      Sentry.setUser({ id: userId });
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch(getIdeAccessToken(response.headers.authorization));
    return response.data;
  },

  (error) => {
    if (
      (error.response && error.response?.status >= 500) ||
      (error.isAxiosError && !error.response)
    ) {
      Sentry.captureException(error);
    }

    if (error.response?.status === 404 || error.response?.status === 405) {
      window.location.href = `/ide/not-found`;
    }

    if (error.response?.status === 401) {
      window.location.href = `/ide/invalid-token`;
    }

    if (error.response?.status === 500) {
      window.location.href = `/ide/internal-server-error`;
    }

    return Promise.reject(
      error.response?.data?.errorDetails
        ? error.response?.data?.errorDetails
        : {
            message:
              '알 수 없는 에러가 발생했습니다.\n에러가 지속된다면 관리자에게 문의 부탁드립니다.',
          },
    );
  },
);

export const axiosLocalInstance = axios.create({
  baseURL: `${location.protocol}//${location.port === '3000' || location.port === '3002' ? location.hostname : location.host}`,
  timeout: 3 * 60 * 1000,
  withCredentials: true,
});

axiosLocalInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().accessTokenValue.ideAccessToken;
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

axiosLocalInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },

  (error) => {
    if (
      (error.response && error.response?.status >= 500) ||
      (error.isAxiosError && !error.response)
    ) {
      Sentry.captureException(error);
    }

    if (error.response?.status === 401) {
      window.location.href = `/invalid-token`;
    }

    return Promise.reject(
      error.response?.data?.errorDetails ?? {
        message:
          '알 수 없는 에러가 발생했습니다.\n에러가 지속된다면 관리자에게 문의 부탁드립니다.',
      },
    );
  },
);

export const axiosMediaInstance = axios.create({
  baseURL: `${location.protocol}//${location.port === '3000' || location.port === '3002' ? location.hostname : location.host}`,
  timeout: 60000,
  withCredentials: true,
});

axiosMediaInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().accessTokenValue.ideAccessToken;
    if (accessToken) {
      config.headers.Authorization = accessToken;
      const userId = decodeJwtToken(accessToken)?.sub;
      Sentry.setUser({ id: userId });
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

axiosMediaInstance.interceptors.response.use(
  (response) => {
    store.dispatch(getAccessToken(response.headers.authorization));
    return response;
  },

  (error) => {
    if (error.response.status === 401) {
      store.dispatch(
        getPreviousLink(
          window.location.pathname +
            window.location.search +
            window.location.hash,
        ),
      );
      window.location.href = '/invalidtoken';
      return;
    }

    if (error.response && error.response.status >= 500) {
      Sentry.captureException(error);
      window.location.href = '/internal-server-error';
      return;
    }

    if (error.response.status === 404) {
      throw Error('404');
    }
    if (error.code === 'ERR_NETWORK' || error.response.status === 405) {
      window.location.href = `/ide/not-found`;
      return;
    }
    if (error.isAxiosError && !error.response) {
      Sentry.captureException(error);
      return;
    }

    return Promise.reject(
      error.response?.data?.errorDetails ??
        '알 수 없는 에러가 발생했습니다.\n에러가 지속된다면 관리자에게 문의 부탁드립니다.',
    );
  },
);
