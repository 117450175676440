import {
  ExtractTextFormatType,
  ExtractTextFormatTypeName,
  ExtractTextFormatTypeServerName,
} from '@customTypes/ide/mobileTestStep/extractTextFormat/extractTextFormat';

export const extractTextFormatTypeList: ExtractTextFormatType[] = [
  {
    idx: 1,
    name: ExtractTextFormatTypeName.Number,
    serverName: ExtractTextFormatTypeServerName.Number,
  },
];

export const findExtractTextFormatTypeByServerName = (
  serverName: ExtractTextFormatTypeServerName,
) =>
  extractTextFormatTypeList.find(
    (extractTextFormatType) => extractTextFormatType.serverName === serverName,
  )?.name;
