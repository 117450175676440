import React from 'react';
import { LiaUsb } from 'react-icons/lia';

const DeviceListSkeleton = () => {
  return (
    <div className="mt-3 grid grid-cols-4 gap-6">
      <div className="animate-pulse">
        <div className="group relative flex items-center justify-center space-x-3 rounded-md border border-gray-200 p-4">
          <div className="shrink-0">
            <LiaUsb size={24} />
          </div>
          <div className="flex min-w-0 flex-1 flex-col gap-2">
            <div className="h-4 w-20 rounded-2xl bg-gray-200 dark:bg-gray-700" />
            <div className="h-3 w-28 rounded-2xl bg-gray-200 dark:bg-gray-700" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceListSkeleton;
