import { Button, ErrorMessage } from '@autosquare/common';

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCreateSchedulerMutation } from '@lib/api/ide/scheduler/scheduler';
import {
  CreateSchedulerData,
  CreateSchedulerSchema,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import CreateSchedulerTestType from './CreateSchedulerForm/CreateSchedulerTestType';
import CreateSchedulerScenario from './CreateSchedulerForm/CreateSchedulerScenario';
import CreateSchedulerSchedule from './CreateSchedulerForm/CreateSchedulerSchedule';
import CreateSchedulerEmailAlarm from './CreateSchedulerForm/CreateSchedulerEmailAlarm';
import CreateSchedulerTitle from './CreateSchedulerForm/CreateSchedulerTitle';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import CreateSchedulerBrowser from './CreateSchedulerBrowser';
import CreateSchedulerDevice from './CreateSchedulerForm/CreateSchedulerDevice';

const CreateSchedulerForm = () => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();

  const methods = useForm<CreateSchedulerData>({
    defaultValues: {
      project_idx: Number(projectIdx),
      type: SchedulerTypeServerName.Mobile,
      mail_receivers: [],
      auto_register: true,
    },
    resolver: zodResolver(CreateSchedulerSchema),
  });

  const createSchedulerMutation = useCreateSchedulerMutation();
  const onSubmit: SubmitHandler<CreateSchedulerData> = (data) => {
    if (
      data.type === SchedulerTypeServerName.Mobile &&
      data?.device_info_dto === undefined
    ) {
      methods.setError('device_info_dto', {
        type: 'required',
        message: 'Device를 선택해 주세요.',
      });
    } else if (
      data.type === SchedulerTypeServerName.Web &&
      !data.browser_info_dto
    ) {
      methods.setError('browser_info_dto', {
        type: 'required',
        message: 'Browser를 선택해 주세요.',
      });
    } else if (data.scenario_idx_list.length === 0) {
      methods.setError('scenario_idx_list', {
        type: 'required',
        message: '시나리오를 선택해 주세요.',
      });
    } else {
      createSchedulerMutation.mutate(data);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
        className="mt-4 flow-root"
      >
        <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:pb-0">
          <CreateSchedulerTestType />
          <CreateSchedulerTitle />
          <CreateSchedulerScenario />
          <CreateSchedulerDevice />
          <CreateSchedulerBrowser />
          <CreateSchedulerSchedule />
          <CreateSchedulerEmailAlarm />
        </div>
        <div className="flex items-center justify-end gap-6 border-t border-solid border-gray-200 py-4">
          {createSchedulerMutation.isError && (
            <ErrorMessage>{createSchedulerMutation.error.message}</ErrorMessage>
          )}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <div className="flex items-center justify-between gap-x-6">
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={createSchedulerMutation.isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateSchedulerForm;
