import React, { useState } from 'react';

import {
  useDeleteSpeedMobileTestScenarioMutation,
  useSpeedMobileTestScenarioListQuery,
} from '@lib/api/ide/speedMobile/speedMobileTestScenario';
import {
  Checkbox,
  Detail,
  Execution,
  TableBodyBasic,
  TableHeader,
  TableNoList,
  TableWrapper,
  Time,
  useCheckbox,
} from '@autosquare/common';
import { useNavigate } from 'react-router';
import { timePattern } from '@utils/static/timePattern';
import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';
import TableSkeleton from '@components/SpeedTestScenario/TableSkeleton';
import ErrorMessageInTableBody from '@components/SpeedTestScenario/ErrorMessageInTableBody';
import DeleteDialogInSpeedTestScenarioList from '@components/SpeedTestScenario/DeleteDialogInSpeedTestScenarioList';

const MobileSpeedTestScenarioTable = () => {
  const navigate = useNavigate();

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testScenarioIdx, setTestScenarioIdx] = useState<number>(0);

  const speedMobileTestScenarioListQuery =
    useSpeedMobileTestScenarioListQuery();
  const speedMobileTestScenarioList = speedMobileTestScenarioListQuery.data;

  const deleteSpeedMobileTestScenarioMutation =
    useDeleteSpeedMobileTestScenarioMutation();

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(speedMobileTestScenarioList, 'idx');

  const tableHeaders = [
    {
      idx: 1,
      header: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          key="selected-checkbox"
        />
      ),
    },
    { idx: 2, header: 'No' },
    { idx: 3, header: selectedArray.length > 0 ? '     ' : 'Title' },
    { idx: 4, header: 'Test Case Count' },
    { idx: 5, header: 'Updated at' },
    { idx: 6, header: '' },
    { idx: 7, header: '' },
  ];

  return (
    <>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {tableHeaders.map((tableHeader) => (
              <TableHeader key={tableHeader.idx}>
                {tableHeader.header}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {speedMobileTestScenarioListQuery.isLoading ||
          speedMobileTestScenarioListQuery.isFetching ? (
            <TableSkeleton dataLength={tableHeaders.length} />
          ) : speedMobileTestScenarioListQuery.isError ? (
            <ErrorMessageInTableBody colSpan={tableHeaders.length}>
              {speedMobileTestScenarioListQuery.error.message}
            </ErrorMessageInTableBody>
          ) : speedMobileTestScenarioList?.length === 0 ? (
            <TableNoList colSpan={tableHeaders.length}>
              해당 프로젝트에 생성된 테스트 시나리오가 존재하지 않습니다.
            </TableNoList>
          ) : (
            speedMobileTestScenarioList?.map((speedMobileTestScenario, idx) => (
              <tr key={speedMobileTestScenario.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(
                      speedMobileTestScenario.idx,
                    )}
                    onChange={(e) =>
                      selectArray(speedMobileTestScenario.idx, e)
                    }
                  />
                </TableBodyBasic>
                <TableBodyBasic>{idx + 1}</TableBodyBasic>
                <TableBodyBasic>
                  <div className="font-medium text-gray-900">
                    {speedMobileTestScenario.title}
                  </div>
                  <div className="mt-1 text-gray-500">
                    {speedMobileTestScenario.description}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  {speedMobileTestScenario.caseCount}
                </TableBodyBasic>
                <TableBodyBasic>
                  <div>
                    {speedMobileTestScenario.modifiedAt.split(' ')[0] ?? '-'}
                  </div>
                  <div
                    className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                  >
                    {speedMobileTestScenario.modifiedAt && <Time />}
                    {speedMobileTestScenario.modifiedAt.match(timePattern)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    testType="scenario"
                    tooltipLocation="right"
                    disabled={speedMobileTestScenario.caseCount === 0}
                    onClick={() => {
                      if (speedMobileTestScenario.caseCount > 0) {
                        setTestScenarioIdx(speedMobileTestScenario.idx);
                        setIsTestExecutionOpened(true);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: 'detail',
                        search: `idx=${speedMobileTestScenario.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </tbody>
      </TableWrapper>
      <DeleteDialogInSpeedTestScenarioList
        isOpenedCautionDialog={isOpenedCautionDialog}
        setIsOpenedCautionDialog={setIsOpenedCautionDialog}
        deleteSpeedMobileTestScenarioMutation={
          deleteSpeedMobileTestScenarioMutation
        }
        selectedArray={selectedArray}
        openDialog={openDialog}
        closeDialog={closeDialog}
        resetSelectedArray={resetSelectedArray}
      />
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testScenarioIdx}
          testType={'scenario'}
          deviceType={'mobile'}
          testLocation={'speed-test'}
        />
      )}
    </>
  );
};

export default MobileSpeedTestScenarioTable;
