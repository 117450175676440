import React from "react";

import { useAllAdminProjectListQuery } from "@lib/api/dashboard/admin/admin";

import AdminProjectListTable from "./components/AdminProjectListTable";

import {
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadings,
  TableSkeleton,
} from "@autosquare/common";

const AdminProjectList = () => {
  const {
    data: adminProjectList,
    isLoading,
    isFetching,
    isError,
    error,
  } = useAllAdminProjectListQuery();

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={"Project List"}
        pageHeadingButtons={pageHeadingButtons}
      />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto scrollbar-thin sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {isLoading || isFetching ? (
              <TableSkeleton />
            ) : isError ? (
              <ErrorMessage>{error.message}</ErrorMessage>
            ) : (
              <AdminProjectListTable adminProjectList={adminProjectList} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProjectList;

const breadcrumbs = [
  { title: "Administration", href: "/admin/project/list" },
  { title: "Projects", href: "/admin/project/list" },
  { title: "list", href: "/admin/project/list" },
];

const pageHeadingButtons: PageHeadingButtons[] = [
  {
    title: "Create Project",
    href: "/admin/project/create",
    type: "button",
    variant: "primary",
  },
];
