import { ListBoxHeadlessInController } from '@autosquare/common';

import { RootState } from '@app/store';

import {
  deviceKeyList,
  deviceKeyListInIos,
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import {
  CreateTestStepData,
  CreateTestStepDataOptionsDto,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

type ValueKeys =
  | keyof Pick<CreateTestStepData, 'value'>
  | `optionsDto.${keyof Pick<
      CreateTestStepDataOptionsDto,
      'actionValue' | 'passActionValue' | 'failActionValue'
    >}`;

type Props = {
  value?: ValueKeys;
  rules?: Omit<
    RegisterOptions<CreateTestStepData, ValueKeys>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

const DeviceKeyListBox = ({
  value = 'value',
  rules = { required: 'Device Key를 선택해주세요.' },
}: Props) => {
  const { control } = useFormContext<CreateTestStepData>();

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);
  const deviceOs = deviceInfo.os;

  return (
    <Controller
      control={control}
      name={value}
      rules={rules}
      render={({ field }) => (
        <ListBoxHeadlessInController
          value={field.value}
          buttonValue={
            findListNameFromServerName(field.value as DeviceKeyServerName) ||
            'Select Key Event'
          }
          onChange={field.onChange}
          lists={
            deviceOs === DeviceInfoOs.Aos
              ? deviceKeyList.slice(1)
              : deviceKeyListInIos.slice(1)
          }
          valueToSave={'serverName'}
          valueToShow={'listName'}
        />
      )}
    />
  );
};

export default DeviceKeyListBox;
