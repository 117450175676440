import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import {
  ArrowPathIcon,
  CheckIcon,
  ChevronUpDownIcon,
  WifiIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';

import { ConnectionType, DeviceInfo } from '@customTypes/ide/device/device';
import { LiaUsb } from 'react-icons/lia';
import { UseQueryResult } from '@tanstack/react-query';

interface Props {
  selectedDevice: DeviceInfo;
  setSelectedDevice: React.Dispatch<React.SetStateAction<DeviceInfo>>;
  deviceListQuery: UseQueryResult<DeviceInfo[], Error>;

  deviceLists: DeviceInfo[];
  isRunning?: boolean;
}

const DeviceListEditBox = ({
  selectedDevice,
  setSelectedDevice,
  deviceListQuery,
  deviceLists,
  isRunning,
}: Props) => {
  const disabledOption =
    deviceListQuery.isLoading || deviceListQuery.isFetching || !isRunning;
  return (
    <div className="flex w-full gap-x-4">
      <Listbox
        value={selectedDevice}
        onChange={setSelectedDevice}
        disabled={disabledOption}
      >
        {({ open }) => (
          <>
            <div className="relative mt-2 w-full">
              <ListboxButton
                className={clsx(
                  'relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
                  disabledOption ? 'bg-gray-100' : 'bg-white',
                )}
              >
                <span className="block truncate">
                  {selectedDevice?.serialNumber === null
                    ? selectedDevice?.model
                    : `${selectedDevice?.model} - ${selectedDevice?.serialNumber}`}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="size-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                  {deviceLists?.length === 0 ? (
                    <div className="mx-2 my-1 flex items-center justify-start">
                      연결된 디바이스가 없습니다.
                    </div>
                  ) : (
                    deviceLists?.map((device) => (
                      <ListboxOption
                        key={device.serialNumber}
                        className={({ focus }) =>
                          clsx(
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            focus
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                          )
                        }
                        value={device}
                      >
                        {({ selected, focus }) => (
                          <div className="flex items-center justify-start gap-x-2">
                            {device.connectionType === ConnectionType.USB ? (
                              <LiaUsb size="24" />
                            ) : (
                              <WifiIcon className="size-6" />
                            )}
                            <span
                              className={clsx(
                                'block truncate',
                                selected ? 'font-semibold' : 'font-normal',
                              )}
                            >
                              {device.model} - {device.serialNumber}
                            </span>
                            {selected && (
                              <span
                                className={clsx(
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                  focus ? 'text-white' : 'text-indigo-600',
                                )}
                              >
                                <CheckIcon
                                  className="size-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </div>
                        )}
                      </ListboxOption>
                    ))
                  )}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <div
        className={clsx(
          'mt-2 size-9 rounded border border-solid border-gray-300 px-2 py-1',
          isRunning
            ? 'cursor-pointer hover:bg-gray-50'
            : 'cursor-default bg-gray-100',
        )}
      >
        <ArrowPathIcon
          className={clsx('size-full', {
            'animate-spin':
              (isRunning && deviceListQuery.isLoading) ||
              (isRunning && deviceListQuery.isFetching),
          })}
          onClick={() => {
            if (isRunning) {
              deviceListQuery.refetch();
            }
          }}
        />
      </div>
    </div>
  );
};

export default DeviceListEditBox;
