import { Menu, MenuButton } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { GroupContextMenu } from './GroupContextMenu';
import { TestCaseGroup } from '@customTypes/testCaseGroup/type';

type Props = {
  item: TestCaseGroup;
  setOpenCaution: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupIdx: React.Dispatch<React.SetStateAction<number>>;
};
const GroupContentCard = ({ item, setOpenCaution, setGroupIdx }: Props) => {
  return (
    <li className="overflow-hidden rounded-xl border border-gray-200">
      <div className="flex items-center justify-between bg-gray-50 rounded-t-md border-b text-gray-800">
        <h5 className="flex items-center gap-x-4 border-b border-gray-900/5 p-6 text-sm truncate">
          {item.name}
        </h5>
        <Menu as="div" className="relative m-5">
          <MenuButton
            className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500"
            onClick={() => setGroupIdx(item.idx)}
          >
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </MenuButton>
          <GroupContextMenu setOpenCaution={setOpenCaution} />
        </Menu>
      </div>
      <div className="-my-3 px-6 py-7 text-sm leading-6 text-gray-500">
        <p className="mb-2 border-b border-gray-100 pb-3">Case Count</p>
        <div className="flex justify-between pt-2 mb-1">
          <p>Mobile App</p>
          <p>{item.mobileCaseCount}</p>
        </div>
        <div className="flex justify-between">
          <p>Web</p>
          <p>{item.webCaseCount}</p>
        </div>
      </div>
    </li>
  );
};

export default GroupContentCard;
