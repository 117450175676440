import React from 'react';
import { RepeatResultsData } from '@customTypes/ide/speedTest/Mobile/testResult/type';

import SpeedRepeatResultTableRow from './SpeedRepeatResultTable/SpeedRepeatResultTableRow';
import SpeedRepeatResultTableHeader from './SpeedRepeatResultTable/SpeedRepeatResultTableHeader';
import VideoAndLogTabPanel from './SpeedRepeatResultTable/VideoAndLogTabPanel';

type Props = {
  platformTypeName: string;
  repeatResults: RepeatResultsData[];
  logPath: string;
  chartIndex: number;
};

const SpeedRepeatResultTable = ({
  platformTypeName,
  repeatResults,
  logPath,
  chartIndex,
}: Props) => {
  const headers = [
    'Repeat',
    'DateTime',
    ...(platformTypeName === 'Web' ? ['DomTime'] : []),
    'LoadTime(s)',
  ];

  return (
    <div className="grid grid-cols-2 gap-x-8">
      <div className="min-w-1/2">
        <SpeedRepeatResultTableHeader headers={headers} />
        {repeatResults?.map((result) => (
          <SpeedRepeatResultTableRow
            key={result.repeat}
            result={result}
            isWeb={platformTypeName === 'Web'}
          />
        ))}
      </div>
      <div className="min-w-1/2">
        <VideoAndLogTabPanel
          repeatResults={repeatResults}
          chartIndex={chartIndex}
          logPath={logPath}
        />
      </div>
    </div>
  );
};

export default SpeedRepeatResultTable;
