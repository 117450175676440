import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '..';

import { API } from '@api/endpoints';

import { Login } from '@customTypes/login/type';
import { ServerResponseErrorDetails, UserInfoType } from '@customTypes/type';

import { projectKeys } from '@queries/ide/projectKeys';
import { userKeys } from '@queries/dashboard/userKeys';
import {
  SignUpData,
  UserInfoByDecodeKey,
} from '@customTypes/dashboard/user/type';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '@store/data/userInfoSlice';
import { getPreviousLink } from '@store/data/previousLinkSlice';
import { RootState } from '@app/store';

// GET
const userInfo = async (): Promise<UserInfoType> => {
  const response = await axiosInstance.get<UserInfoType>(API.USER_INFO);
  return response.data;
};
export const useUserInfoQuery = () => {
  return useQuery<UserInfoType, ServerResponseErrorDetails>({
    queryKey: userKeys.userList(),
    queryFn: () => userInfo(),
    refetchOnMount: false,
  });
};

const getUserInfoByDecodeKey = async (
  decodekey: string,
): Promise<UserInfoByDecodeKey> => {
  const response = await axiosInstance.get(
    API.CHECK_USER_INFO_DECODE_KEY + decodekey,
  );
  return response.data;
};
export const useUserInfoByDecodeKeyQuery = (decodeKey: string) => {
  return useQuery<UserInfoByDecodeKey, ServerResponseErrorDetails>({
    queryKey: userKeys.decode(decodeKey),
    queryFn: () => getUserInfoByDecodeKey(decodeKey),
  });
};

// POST
const dashboardPostLogin = async (data: Login): Promise<UserInfoType> => {
  const response = await axiosInstance.post<UserInfoType>(API.LOGIN, data);
  return response.data;
};
export const useDashboardLoginMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const previousLink = useSelector(
    (state: RootState) => state.previousLink.link,
  );

  return useMutation<UserInfoType, ServerResponseErrorDetails, Login>({
    mutationFn: (data) => dashboardPostLogin(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries(projectKeys.all);
      dispatch(getUserInfo(response));
      previousLink ? navigate(previousLink) : navigate('/projects');
      dispatch(getPreviousLink(null));
    },
  });
};

const dashboardLogout = async (): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(API.LOGOUT);
  return response.data;
};
export const useDashboardLogoutMutation = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: () => dashboardLogout(),
    onSuccess: () => {
      navigate('/');
    },
  });
};

// PUT
const signUp = async (data: SignUpData): Promise<unknown> => {
  const response = await axiosInstance.put(API.SIGN_UP, data);
  return response.data;
};
export const useSignUpMutation = () => {
  const navigate = useNavigate();

  return useMutation<unknown, ServerResponseErrorDetails, SignUpData>({
    mutationFn: (data) => signUp(data),
    onSuccess: () => {
      navigate('/login');
    },
  });
};
