import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  useMobileTestCaseResultQuery,
  useMobileTestResultDetailQuery,
} from '@lib/api/dashboard/mobile/mobileTestResult';

import Information from '@components/shared/TestResultDetail/Information';
import Summary from '@components/shared/TestResultDetail/Summary';
import ExecutionDetails from '@components/shared/TestResultDetail/ExecutionDetails';
import TestCaseResultListSidebar from '@components/shared/TestResultDetail/TestCaseResultListSidebar';

import {
  ErrorMessage,
  PagingHeadings,
  TableSkeleton,
  useDetectScrollLocation,
} from '@autosquare/common';

const MobileTestResultDetail = () => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const mobileTestCaseResultQuery = useMobileTestCaseResultQuery(idx);
  const mobileTestResultDetailQuery = useMobileTestResultDetailQuery(idx);

  const testResultSummary = mobileTestCaseResultQuery.data?.summary;
  const resultDetails = mobileTestCaseResultQuery.data?.resultDetail;

  const { elementRefs, activeSection, handleClick } = useDetectScrollLocation({
    offsetY: -100,
    dependencyArray: resultDetails,
  });

  const breadcrumbs = [
    {
      title: 'Mobile App',
      href: `/projects/${projectIdx}/mobile-app/test-result?page=1`,
    },
    {
      title: 'Test Result',
      href: `/projects/${projectIdx}/mobile-app/test-result?page=1`,
    },
    {
      title: 'Detail',
      href: `/projects/${projectIdx}/mobile-app/test-result/detail?idx=${idx}`,
    },
  ];

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result Detail'}
      />
      {mobileTestCaseResultQuery.isLoading ||
      mobileTestCaseResultQuery.isFetching ||
      mobileTestResultDetailQuery.isLoading ||
      mobileTestResultDetailQuery.isFetching ? (
        <TableSkeleton />
      ) : mobileTestCaseResultQuery.isError ||
        mobileTestResultDetailQuery.isError ? (
        <ErrorMessage>
          {mobileTestCaseResultQuery.error.message ||
            mobileTestResultDetailQuery.error.message}
        </ErrorMessage>
      ) : (
        <>
          <div className="xl:mr-96">
            <Summary
              testResultSummary={testResultSummary}
              result={mobileTestResultDetailQuery.data?.result}
              reason={mobileTestResultDetailQuery.data?.failReason}
            />
            <Information testResult={mobileTestResultDetailQuery.data} />
            <ExecutionDetails
              resultDetails={resultDetails}
              elementRefs={elementRefs}
            />
          </div>
          <TestCaseResultListSidebar
            resultDetails={resultDetails}
            handleClick={handleClick}
            activeSection={activeSection}
          />
        </>
      )}
    </>
  );
};

export default MobileTestResultDetail;
