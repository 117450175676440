import React, { useState } from 'react';
import {
  ExecutionDetail,
  MobileSpeedTestResultTableDetailData,
} from '@customTypes/ide/speedTest/Mobile/testResult/type';
import clsx from 'clsx';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';
import { UseQueryResult } from '@tanstack/react-query';
import { ListSkeleton } from '@autosquare/common';

type Props = {
  executionDetail: ExecutionDetail;
  speedMobileTestResultTableQuery: UseQueryResult<
    MobileSpeedTestResultTableDetailData,
    Error
  >;
};

const SpeedExecutionDetails = ({
  executionDetail,
  speedMobileTestResultTableQuery,
}: Props) => {
  const [isExecutionDetailsOpened, setIsExecutionDetailsOpened] =
    useState(false);
  return (
    <>
      <button
        type="button"
        className="w-full border-b border-gray-200 py-3 text-left text-sm font-semibold text-gray-900"
      >
        <p>Execution Details</p>
      </button>
      <button
        type="button"
        onClick={() => setIsExecutionDetailsOpened(!isExecutionDetailsOpened)}
        className="w-full"
      >
        <div className="flex items-center justify-start px-6">
          <div
            className={clsx(
              'flex h-5 flex-col items-start justify-start border px-1.5 py-[0.2rem]',
              {
                'border-green-600 bg-green-50':
                  executionDetail?.result === 'PASS',
                'border-red-600 bg-red-50': executionDetail?.result === 'FAIL',
              },
            )}
          >
            <div
              className={clsx('text-xs font-medium leading-none', {
                'text-green-700': executionDetail?.result === 'PASS',
                'text-red-700': executionDetail?.result === 'FAIL',
              })}
            >
              {executionDetail?.result}
            </div>
          </div>
          <div className="flex items-center justify-center px-6 py-5 text-sm font-medium text-gray-900">
            <p className="mr-3">#1</p>
            <p>{executionDetail?.title}</p>
          </div>
        </div>
      </button>
      {isExecutionDetailsOpened &&
        (speedMobileTestResultTableQuery.isLoading ||
        speedMobileTestResultTableQuery.isFetching ? (
          <ListSkeleton />
        ) : speedMobileTestResultTableQuery.isError ? (
          <p>{speedMobileTestResultTableQuery.error.message}</p>
        ) : (
          <>
            <div className="border-b border-gray-200 pb-3 pt-5">
              <p className="px-6 text-sm font-medium text-gray-900">
                Test Step Result &#40;
                {executionDetail?.stepList.length}
                &#41;
              </p>
            </div>
            <div>
              <ul role="list" className="space-y-6 px-6 py-3">
                {executionDetail?.stepList.map((step) => (
                  <li key={step.stepIdx} className="flex flex-col pt-1">
                    <div className="flex items-center justify-start gap-x-2">
                      <div
                        className={clsx('size-2.5 rounded-full', {
                          'bg-green-400': step.result === 'PASS',
                          'bg-red-500': step.result === 'FAIL',
                          'bg-gray-300': step.result === 'NA',
                        })}
                      />
                      <p className="text-xs font-medium text-gray-500">
                        {findMobileCommandNameFromServerName(
                          step.command as MobileCommandOptionServerName,
                        )}
                      </p>
                      <p className="text-xs font-medium text-gray-500">&#45;</p>
                      <p className="text-xs font-medium text-gray-900">
                        {step.description}
                      </p>
                    </div>
                    {step.result === 'FAIL' && (
                      <div className="flex gap-x-1 px-5 pt-3 text-xs font-medium text-gray-900">
                        <p>Fail Reason:</p>
                        <p>{step.failReason}</p>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </>
        ))}
    </>
  );
};

export default SpeedExecutionDetails;
