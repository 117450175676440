import { ArrowSpinner, Button, ErrorMessage } from '@autosquare/common';

import React, { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '@app/store';

import { DeviceSettingsContext } from '@contexts/ide/DeviceSettingsProvider';

import { useDeviceSettingQuery } from '@lib/api/ide/setting/settings';
import { useInstallMandatoryAppMutation } from '@lib/api/ide/mobile/mobileDevice';

import { InstallMandatoryApp } from '@customTypes/setting/type';

import DeviceSettingsSkeleton from './DeviceSettingsSkeleton';
import DeviceSettingDescriptionList from './DeviceSettingsForm/DeviceSettingDescriptionList';
import FilePath from './DeviceSettingsForm/FilePath';
import DoubleCheckDialog from './DeviceSettingsForm/DoubleCheckDialog';

interface Props {
  previousUrl: string;
}

const DeviceSettingsForm = ({ previousUrl }: Props) => {
  const navigate = useNavigate();

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  const deviceSettingQuery = useDeviceSettingQuery();
  const deviceSetting = deviceSettingQuery.data;

  const installMandatoryAppMutation = useInstallMandatoryAppMutation(cookie);

  const { openDialog } = useContext(DeviceSettingsContext);

  const method = useForm<InstallMandatoryApp>({
    defaultValues: {
      udid: deviceInfo.udid,
      team_id: deviceSetting?.ios_team_id,
      app_path: '',
    },
  });

  const teamIdWatch = method.watch('team_id');
  const appPathWatch = method.watch('app_path');

  const onSubmit: SubmitHandler<InstallMandatoryApp> = (data) => {
    if (deviceSetting?.ios_team_id === teamIdWatch) {
      data.team_id = '';
    }

    installMandatoryAppMutation.mutate(data, {
      onSuccess: () => {
        openDialog();
      },
      onError: () => {
        openDialog();
      },
    });
  };

  return (
    <FormProvider {...method}>
      {deviceSettingQuery.isLoading || deviceSettingQuery.isFetching ? (
        <DeviceSettingsSkeleton />
      ) : (
        <form
          className="my-8 px-6 text-sm"
          onSubmit={method.handleSubmit(onSubmit)}
        >
          <div className="border-b border-solid border-gray-200 pb-5">
            <DeviceSettingDescriptionList />
            <FilePath />
          </div>
          {deviceSettingQuery.isError && (
            <ErrorMessage>{deviceSettingQuery.error.message}</ErrorMessage>
          )}
          <div className="flex items-center justify-end gap-4 pt-5">
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => navigate(previousUrl)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant={'primary'}
              disabled={teamIdWatch === '' || appPathWatch === ''}
            >
              Save & Install
            </Button>
          </div>
        </form>
      )}
      <DoubleCheckDialog
        previousUrl={previousUrl}
        installMandatoryAppMutation={installMandatoryAppMutation}
      />
      {installMandatoryAppMutation.isLoading && <ArrowSpinner />}
    </FormProvider>
  );
};

export default DeviceSettingsForm;
