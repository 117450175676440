import {
  DetailArrow,
  SpeedTestResultTableDataCell,
  SpeedTestResultTableHeader,
  Time,
} from '@autosquare/common';
import {
  WebSpeedTestResultData,
  WebSpeedTestResultServiceResult,
} from '@customTypes/ide/speedTest/Web/testResult/type';
import { getClickLocation } from '@store/locationHistorySlice';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

type Props = {
  resultData: WebSpeedTestResultData;
};

const WebSpeedTestResultsOverviewTable = ({ resultData }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectIdx } = useParams();
  const { pathname, search } = useLocation();

  const resultWithUUID = resultData?.list?.map((item) => ({
    ...item,
    index: crypto.randomUUID(),
  }));

  const isDashboard =
    !isWordInLocationPathname('speed-test') &&
    !isWordInLocationPathname('dashboard');

  const navigateUrl = (
    resultIdx: number,
    groupIdx: number,
    channel: string,
    dateTime: string,
  ) => {
    return isDashboard
      ? `/projects/${projectIdx}/web/speed/test-result/detail?result=${resultIdx}&group=${groupIdx}&order-by=decs&channel=${channel.toLocaleLowerCase()}&start=${dayjs(dateTime.split(' ')[0]).subtract(14, 'day').format('YYYY-MM-DD')}&end=${dateTime.split(' ')[0]}`
      : `/ide/projects/${projectIdx}/speed-test/web/test-result/detail?result=${resultIdx}&group=${groupIdx}&order-by=desc&channel=${channel.toLowerCase()}&start=${dayjs(dateTime.split(' ')[0]).subtract(14, 'day').format('YYYY-MM-DD')}&end=${dateTime.split(' ')[0]}`;
  };

  return (
    <div className="flex flex-col gap-4">
      {resultWithUUID?.map((webSpeedTestResult) => (
        <div
          key={webSpeedTestResult.index}
          className="flex w-full flex-col gap-3.5"
        >
          <div className="flex w-full items-center justify-between">
            <h2 className="text-sm font-semibold leading-tight text-black">
              {webSpeedTestResult.menu} Loading Time 비교측정 결과
            </h2>
            <button
              type="button"
              className="flex gap-1.5"
              onClick={() => {
                dispatch(getClickLocation(pathname + search));
                navigate(
                  navigateUrl(
                    webSpeedTestResult.services[0].idx,
                    webSpeedTestResult.group_idx,
                    webSpeedTestResult.services[0].channel,
                    webSpeedTestResult.date_time,
                  ),
                  {
                    state: {
                      status: webSpeedTestResult.services[0].result_enum,
                      page: 1,
                    },
                  },
                );
              }}
            >
              <span className="text-center text-xs font-semibold leading-tight text-gray-900">
                Detail
              </span>
              <DetailArrow />
            </button>
          </div>
          <div className="w-full overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="text-sm font-semibold leading-tight text-gray-900">
                <tr className="border-b border-solid border-gray-200 bg-gray-50">
                  <SpeedTestResultTableHeader width="w-40">
                    Type
                  </SpeedTestResultTableHeader>
                  <SpeedTestResultTableHeader width="w-40">
                    DateTime
                  </SpeedTestResultTableHeader>
                  {webSpeedTestResult.services.map((service) => (
                    <SpeedTestResultTableHeader key={service.service_group_idx}>
                      {service.service}
                    </SpeedTestResultTableHeader>
                  ))}
                </tr>
              </thead>
              <tbody className="text-sm font-normal leading-tight text-gray-900">
                <tr className="border-b border-solid border-gray-400">
                  <SpeedTestResultTableDataCell>
                    {webSpeedTestResult.services[0].channel}
                  </SpeedTestResultTableDataCell>
                  <SpeedTestResultTableDataCell>
                    <p>{webSpeedTestResult.date_time.split(' ')[0]}</p>
                    <div className="flex items-center gap-1 text-sm font-normal leading-tight text-gray-500">
                      <Time />
                      {webSpeedTestResult.date_time.split(' ')[1]}
                    </div>
                  </SpeedTestResultTableDataCell>
                  {webSpeedTestResult.services.map((service) => (
                    <SpeedTestResultTableDataCell
                      key={service.service_group_idx}
                      isOverThreeSeconds={
                        service.result_enum ===
                          WebSpeedTestResultServiceResult.Pass &&
                        service.load_speed_average > 3
                      }
                    >
                      {service.result_enum ===
                      WebSpeedTestResultServiceResult.Pass
                        ? service.load_speed_average
                        : service.result_enum ===
                            WebSpeedTestResultServiceResult.NA
                          ? 'N/A'
                          : WebSpeedTestResultServiceResult.Running
                            ? 'Running'
                            : WebSpeedTestResultServiceResult.Fail && 'Fail'}
                    </SpeedTestResultTableDataCell>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WebSpeedTestResultsOverviewTable;
