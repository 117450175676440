import { MailReceiver } from '@customTypes/ide/speedScheduler/speedScheduler';
import clsx from 'clsx';
import React from 'react';

type Props = {
  mailReceivers: MailReceiver[];
  deleteTagItem: (indexToDelete: number) => void;
  disabled?: boolean;
};

const SelectedEmailCardList = ({
  mailReceivers,
  deleteTagItem,
  disabled = false,
}: Props) => {
  return (
    <div className="h-12">
      {mailReceivers?.map((mailReceiver, index) => (
        <span
          key={mailReceiver.email}
          className="m-1 inline-flex items-center justify-between gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"
        >
          {mailReceiver.name === null
            ? mailReceiver.email
            : `${mailReceiver.email}(${mailReceiver.name})`}
          <button
            type="button"
            className={clsx(
              'group relative -mr-1 size-3.5 rounded-sm',
              !disabled && 'hover:bg-gray-500/20',
            )}
            onClick={() => deleteTagItem(index)}
            disabled={disabled}
          >
            <span className="sr-only">Remove</span>
            <svg
              viewBox="0 0 14 14"
              className="size-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75"
            >
              <path d="M4 4l6 6m0-6l-6 6" />
            </svg>
            <span className="absolute -inset-1" />
          </button>
        </span>
      ))}
    </div>
  );
};

export default SelectedEmailCardList;
