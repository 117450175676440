import React from 'react';

import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import SpeedPropertyDetail from './SpeedPropertyDetail';

type Props = {
  list: MobileTestStepData;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
};

const SpeedLoopTestStepList = ({ list, setIsCopied }: Props) => {
  return (
    <>
      <SpeedPropertyDetail
        term={'Condition'}
        description={findMobileCommandNameFromServerName(
          list.optionsDto.conditionCommand,
        )}
      />
      <SpeedPropertyDetail
        term={'Value'}
        description={list.optionsDto.conditionValue}
        isRequiredCopy={true}
        setIsCopied={setIsCopied}
      />
      <SpeedPropertyDetail
        term={'Repeat'}
        description={list.optionsDto.repeatLimit}
        setIsCopied={setIsCopied}
      />
      <SpeedPropertyDetail
        term={'Action'}
        description={findMobileCommandNameFromServerName(
          list.value as MobileCommandOptionServerName,
        )}
      />
      <SpeedPropertyDetail
        term={'Value'}
        description={
          list.value === MobileCommandOptionServerName.DeviceKey
            ? findListNameFromServerName(
                list.optionsDto.actionValue as DeviceKeyServerName,
              )
            : list.value === MobileCommandOptionServerName.ScrollSwipe
              ? `[${list.optionsDto.actionValue}]`
              : list.optionsDto.actionValue
        }
        isRequiredCopy={true}
        setIsCopied={setIsCopied}
      />
      {list.value === MobileCommandOptionServerName.ScrollSwipe && (
        <SpeedPropertyDetail
          term={'Repeat'}
          description={list.optionsDto.actionOptions.repeatLimit}
          setIsCopied={setIsCopied}
        />
      )}
    </>
  );
};

export default SpeedLoopTestStepList;
