import { CreateMobileWebSpeedTestStepData } from '@customTypes/ide/speedTest/Mobile/webTestStep/type';

import SetAsVariablePanel from '@components/shared/MobileWebTestStep/CreateAndUpdate/SetAsVariablePanel';

import { useSetAsVariablePanel } from '@hooks/mobileWebTestStep/useSetAsVariablePanel';

import React from 'react';
import { useFormContext } from 'react-hook-form';

import CreateDateFormat from './CreateSetAsVariablePanel/CreateDateFormat';
import CreateKeyNameAndFromAndValue from './CreateSetAsVariablePanel/CreateKeyNameAndFromAndValue';

interface Props {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedText: React.Dispatch<React.SetStateAction<string>>;
  isSaved: boolean;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  selectionStart: number;
  selectionEnd: number;
}

const CreateSetAsVariablePanel = ({
  isOpened,
  setIsOpened,
  setSelectedText,
  isSaved,
  setIsSaved,
  closeDialog,
  selectionStart,
  selectionEnd,
}: Props) => {
  const { watch, setValue, setFocus, setError, clearErrors } =
    useFormContext<CreateMobileWebSpeedTestStepData>();

  const { handleSave } = useSetAsVariablePanel({
    watch,
    setSelectedText,
    setFocus,
    isOpened,
    setIsOpened,
    setError,
    clearErrors,
    isSaved,
    setIsSaved,
    setValue,
    selectionStart,
    selectionEnd,
  });

  return (
    <SetAsVariablePanel closeDialog={closeDialog} handleSave={handleSave}>
      <CreateKeyNameAndFromAndValue />
      <CreateDateFormat />
    </SetAsVariablePanel>
  );
};

export default CreateSetAsVariablePanel;
