import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import React from 'react';

import { Tab, TabGroup, TabList } from '@headlessui/react';

import MobileResultsDetail from './MediaAndLogHandler/MobileResultsDetail';
import WebResultDetail from './MediaAndLogHandler/WebResultDetail';

type Props = {
  imagePaths: string[];
  videoPath: string;
  logPath: string;
};

const MediaAndLogHandler = ({ videoPath, logPath, imagePaths }: Props) => {
  const tabs = isWordInLocationPathname('mobile')
    ? [
        { name: 'Video', current: true },
        { name: 'Screenshot', current: false },
        { name: 'Log File', current: false },
      ]
    : [{ name: 'Screenshot', current: true }];

  return (
    <div className="px-4">
      <TabGroup>
        <TabList className="-mb-px flex space-x-8 border-b border-gray-200">
          {tabs.map((tab) => (
            <Tab key={tab.name}>
              {({ selected }) => (
                <div
                  className={
                    'whitespace-nowrap border-b-2 py-5 px-1 text-sm font-medium ' +
                    (selected
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700')
                  }
                >
                  {tab.name}
                </div>
              )}
            </Tab>
          ))}
        </TabList>
        {isWordInLocationPathname('mobile') ? (
          <MobileResultsDetail
            imagePaths={imagePaths}
            videoPath={videoPath}
            logPath={logPath}
          />
        ) : (
          <WebResultDetail imagePaths={imagePaths} />
        )}
      </TabGroup>
    </div>
  );
};

export default MediaAndLogHandler;
