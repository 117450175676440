import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';
import {
  DeleteMobileSpeedTestScenarioData,
  DeleteWebSpeedTestScenarioData,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { UseMutationResult } from '@tanstack/react-query';
import React from 'react';

type Props = {
  isOpenedCautionDialog: boolean;
  setIsOpenedCautionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  deleteSpeedMobileTestScenarioMutation: UseMutationResult<
    string,
    ServerResponseErrorDetails,
    DeleteMobileSpeedTestScenarioData | DeleteWebSpeedTestScenarioData
  >;
  selectedArray: number[];
  openDialog: () => void;
  closeDialog: () => void;
  resetSelectedArray: () => void;
};

const DeleteDialog = ({
  isOpenedCautionDialog,
  setIsOpenedCautionDialog,
  deleteSpeedMobileTestScenarioMutation,
  selectedArray,
  openDialog,
  closeDialog,
  resetSelectedArray,
}: Props) => {
  return (
    <DoubleCheckDialogHeadlessUI
      isOpened={isOpenedCautionDialog}
      setIsOpened={setIsOpenedCautionDialog}
      type="caution"
      title={
        deleteSpeedMobileTestScenarioMutation.isError
          ? 'Error'
          : 'Delete Test Scenario'
      }
      subTitle={
        deleteSpeedMobileTestScenarioMutation.isError
          ? deleteSpeedMobileTestScenarioMutation.error.message
          : '해당 테스트 시나리오를 삭제하시겠습니까?'
      }
      buttonChildren={
        <>
          {deleteSpeedMobileTestScenarioMutation.isError ? undefined : (
            <Button
              type="button"
              variant="caution"
              onClick={() =>
                deleteSpeedMobileTestScenarioMutation.mutate(
                  { idxList: selectedArray },
                  {
                    onSuccess: () => {
                      closeDialog();
                      resetSelectedArray();
                    },
                    onError: () => {
                      openDialog();
                    },
                  },
                )
              }
            >
              Delete
            </Button>
          )}
          <Button type="button" variant="secondary" onClick={closeDialog}>
            Cancel
          </Button>
        </>
      }
    />
  );
};

export default DeleteDialog;
