import { TableHeader } from "@autosquare/common";

import React, { useState } from "react";

import TestCaseImportStepThreeTable from "./TestCaseImportStepThreeTable";
import { ImportedFileData } from "@customTypes/testCase/type";

type Props = {
  fileData: ImportedFileData[];
};

const TestCaseImportCompleteResults = ({ fileData }: Props) => {
  const [successfulImportNumber, setSuccessfulImportNumber] =
    useState<number>(0);

  const testCaseData = fileData.map((data) => data.testCaseData);

  return (
    <div className="space-y-12">
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="col-span-full">
          <div>
            <p className="text-sm text-gray-700">
              Complete{" "}
              <span className="font-medium">{successfulImportNumber}</span> of{" "}
              <span className="font-medium">{testCaseData.length}</span> results
            </p>
          </div>
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="first:pl-0">
                {tableHeader.map((header) => (
                  <TableHeader isFirstPaddingZero={true} key={header}>
                    {header}
                  </TableHeader>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {testCaseData.map((testCase, index) => (
                <TestCaseImportStepThreeTable
                  testCase={testCase}
                  index={index}
                  key={testCase.uniqueKey}
                  setSuccessfulImportNumber={setSuccessfulImportNumber}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TestCaseImportCompleteResults;

const tableHeader = [
  "No",
  "Title",
  "Description",
  "Test Step Count",
  "Priority",
  "Result",
];
