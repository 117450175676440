import {
  Breadcrumbs,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import { useCreateMobileTestCaseMutation } from '@lib/api/ide/mobile/mobileTestCase';

import { PlatFormTypeServerName } from '@utils/static/platformTypeList';

import {
  CreateMobileTestCaseData,
  PriorityNameEnum,
} from '@customTypes/testCase/type';

import CreateMobileTestForm from './components/CreateMobileTestForm';
import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { useDispatch } from 'react-redux';
import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

const CreateMobileTestCase = () => {
  const { pathname } = useLocation();
  const { projectIdx } = useParams();

  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );
  const dispatch = useDispatch();

  const methods = useForm<CreateMobileTestCaseData>({
    defaultValues: {
      platformType: PlatFormTypeServerName.MobileApp,
      priority: PriorityNameEnum.Medium,
    },
  });

  const createMobileTestCaseMutation = useCreateMobileTestCaseMutation({
    projectIdx: projectIdx,
  });

  const onSubmit: SubmitHandler<CreateMobileTestCaseData> = (data) => {
    createMobileTestCaseMutation.mutate(data);
  };

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'UI Test',
      href: pathname.split('/').slice(0, -3).join('/') + '/overview',
    },
    {
      title: 'Mobile',
      href: `/ide/projects/${projectIdx}/ui-test/mobile/test-case`,
    },
    {
      title: 'Test Case',
      href: `/ide/projects/${projectIdx}/ui-test/mobile/test-case`,
    },
    { title: 'Create', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    { title: 'Save', variant: 'primary', type: 'submit' },
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PagingHeadingsNavigate
            breadcrumbs={breadcrumbs}
            pageTitle={'Test Case Create'}
            pageHeadingButtons={pageHeadingButtons}
          />
          <CreateMobileTestForm
            isError={createMobileTestCaseMutation.isError}
            error={createMobileTestCaseMutation.error}
          />
        </form>
      </FormProvider>
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
    </>
  );
};

export default CreateMobileTestCase;
