import React from 'react';

const NoSpeedTestCaseList = () => {
  return (
    <div className="flex h-[calc(100%-3rem)] items-center justify-center">
      추가 가능한 테스트 케이스가 존재하지 않습니다.
    </div>
  );
};

export default NoSpeedTestCaseList;
