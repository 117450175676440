import SetAsVariablePanel from '@components/shared/MobileWebTestStep/CreateAndUpdate/SetAsVariablePanel';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { UpdateWebSpeedTestStepData } from '@customTypes/ide/speedTest/Web/testStep/type';
import UpdateWebSpeedDateFormat from './UpdateWebSpeedSetAsVariablePanel/UpdateWebSpeedDateFormat';
import UpdateWebSpeedKeyNameAndFromAndValue from './UpdateWebSpeedSetAsVariablePanel/UpdateWebSpeedKeyNameAndFromAndValue';
import { useSpeedWebSetAsVariablePanel } from '@hooks/speedWebTestStep/useSpeedWebSetAsVariablePanel';

type Props = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedText: React.Dispatch<React.SetStateAction<string>>;
  isSaved: boolean;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  selectionStart: number;
  selectionEnd: number;
};

const UpdateWebSpeedSetAsVariablePanel = ({
  isOpened,
  setIsOpened,
  setSelectedText,
  isSaved,
  setIsSaved,
  closeDialog,
  selectionStart,
  selectionEnd,
}: Props) => {
  const { watch, setValue, setFocus, setError, clearErrors } =
    useFormContext<UpdateWebSpeedTestStepData>();

  const { handleSave } = useSpeedWebSetAsVariablePanel({
    watch,
    setSelectedText,
    setFocus,
    isOpened,
    setIsOpened,
    setError,
    clearErrors,
    isSaved,
    setIsSaved,
    setValue,
    selectionStart,
    selectionEnd,
  });

  return (
    <SetAsVariablePanel closeDialog={closeDialog} handleSave={handleSave}>
      <UpdateWebSpeedKeyNameAndFromAndValue />
      <UpdateWebSpeedDateFormat />
    </SetAsVariablePanel>
  );
};

export default UpdateWebSpeedSetAsVariablePanel;
