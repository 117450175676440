import { useDeviceSize } from '@autosquare/common';

import React from 'react';

const StreamingCaution = () => {
  const { width } = useDeviceSize();

  return (
    <div className="flex flex-col justify-center items-center gap-2 w-full h-dvh text-black text-sm font-normal leading-loose tracking-tight">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="#EF4444"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
        />
      </svg>
      {width > 300 ? (
        <p>Android 화면 미러링은 OS 10 이상에서만 지원합니다.</p>
      ) : (
        <div className="leading-5">
          <p>Android 화면 미러링은</p>
          <p>OS 10 이상에서만 지원합니다.</p>
        </div>
      )}
    </div>
  );
};

export default StreamingCaution;
