import { useWebTestCaseDetailQuery } from '@lib/api/ide/web/webTestCase';

import { UpdateWebTestCaseData } from '@customTypes/testCase/type';

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import WebTestCaseInfoDetailTop from './WebTestCaseInfoForm/WebTestCaseInfoDetailTop';
import WebTestCaseDetailInfoBottom from './WebTestCaseInfoForm/WebTestCaseDetailInfoBottom';

const WebTestCaseInfoForm = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const webTestCaseDetailQuery = useWebTestCaseDetailQuery(idx);
  const webTestCaseDetailData = webTestCaseDetailQuery.data;

  const { setValue, reset } = useFormContext<UpdateWebTestCaseData>();

  useEffect(() => {
    setValue(
      'caseGroupIdx',
      webTestCaseDetailData?.caseGroupInfo?.caseGroupIdx,
    );
    setValue('title', webTestCaseDetailData?.title);
    setValue('description', webTestCaseDetailData?.description);
    setValue('priority', webTestCaseDetailData?.priority);
    return () => {
      reset();
    };
  }, [reset, setValue, webTestCaseDetailData]);

  return (
    <div className="mt-1">
      <WebTestCaseInfoDetailTop />
      <WebTestCaseDetailInfoBottom />
    </div>
  );
};

export default WebTestCaseInfoForm;
