import React, { useRef, useState } from "react";

import { useInviteUserMutation } from "@lib/api/dashboard/admin/admin";

import ClipBoardAlert from "./components/ClipBoardAlert";
import SuccessToSendEmail from "./components/SuccessToSendEmail";
import AddSystemUsersForm from "./components/AddSystemUsersForm";

import {
  ErrorMessage,
  PagingHeadings,
  useInput,
  validateEmail,
} from "@autosquare/common";

const AddUsers = () => {
  const [email, onChangeEmail] = useInput<string>(null);
  const [signUpLink, setSignUpLink] = useState<string>(null);
  const [compareError, setCompareError] = useState<string>(null);
  const [isCopyToClipBoard, setIsCopyToClipBoard] = useState(false);

  const inviteUserMutation = useInviteUserMutation();

  const data = {
    inviteUserEmail: email,
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail(email)) {
      inviteUserMutation.mutate(data, {
        onSuccess: (response) => {
          setSignUpLink(response.signupPath);
        },
      });
      setCompareError(email);
    } else {
      setCompareError(null);
    }
  };

  const spanRef = useRef(null);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(signUpLink);
      setIsCopyToClipBoard(true);
      setTimeout(() => {
        setIsCopyToClipBoard(false);
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const breadcrumbs = [
    { title: "Administration", href: `/admin/users/list` },
    { title: "Users", href: `/admin/users/list` },
    { title: "Add", href: `/admin/users/add` },
  ];

  return (
    <>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={"Add Users"} />
      <div className="max-w-lg mt-8">
        <div>
          <div>
            <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">
              Add system users
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              이메일 주소로 시스템 사용자를 초대 할 수 있습니다.
            </p>
          </div>
          <AddSystemUsersForm
            email={email}
            handleSubmit={handleSubmit}
            onChangeEmail={onChangeEmail}
          />
          <div className="w-full">
            {signUpLink ? (
              <SuccessToSendEmail
                spanRef={spanRef}
                signUpLink={signUpLink}
                handleCopy={handleCopy}
              />
            ) : (
              inviteUserMutation.isError &&
              email === compareError && (
                <div>
                  <ErrorMessage>
                    {inviteUserMutation.error.message}
                  </ErrorMessage>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {isCopyToClipBoard && <ClipBoardAlert />}
    </>
  );
};

export default AddUsers;
