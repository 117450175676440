export const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
export const formatSelectedDays = (selectedDays: string[]) => {
  const result = [];

  const selectedIndexes = selectedDays
    .filter((item) => item !== '')
    .map((day) => days.indexOf(day))
    .sort((a, b) => a - b);
  let start = selectedIndexes[0];
  let end = start;

  for (let i = 0; i < selectedIndexes.length; i++) {
    const current = selectedIndexes[i];
    const next = selectedIndexes[i + 1];

    if (current + 1 === next) {
      end = next;
    } else {
      if (start === end) {
        result.push(days[start]);
      } else {
        result.push(`${days[start]}-${days[end]}`);
      }
      start = next;
      end = next;
    }
  }

  if (selectedDays.length === days.length) {
    return 'Every day';
  } else if (result.length === 0) {
    return 'Please select one or more days of the week.';
  }
  return result.join(', ');
};

export const formatDays = (dayString: string) => {
  const daysArray = dayString
    .split(',')
    .filter((item) => item !== '')
    .sort((a, b) => days.indexOf(a) - days.indexOf(b));
  const formattedDays = [];
  let rangeStart = null;

  for (let i = 0; i < daysArray.length; i++) {
    const dayIndex = days.indexOf(daysArray[i]);

    if (rangeStart === null || dayIndex !== days.indexOf(daysArray[i])) {
      rangeStart = daysArray[i];
    }

    if (
      i === daysArray.length - 1 ||
      days.indexOf(daysArray[i + 1]) !== dayIndex + 1
    ) {
      if (rangeStart === daysArray[i]) {
        formattedDays.push(rangeStart);
      } else {
        formattedDays.push(`${rangeStart}-${daysArray[i]}`);
      }
      rangeStart = null;
    }
  }
  return formattedDays.join(',');
};
