import { ListBoxHeadlessInController } from '@autosquare/common';

import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

import {
  CreateTestStepData,
  CreateTestStepDataOptionsDto,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

type ValueKeys =
  | keyof Pick<CreateTestStepData, 'value'>
  | `optionsDto.${keyof Pick<
      CreateTestStepDataOptionsDto,
      'actionValue' | 'passActionValue' | 'failActionValue'
    >}`;

type Props = {
  value?: ValueKeys;
  rules?: Omit<
    RegisterOptions<CreateTestStepData, ValueKeys>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

const ImportTestCaseListBoxHookForm = ({
  value = 'value',
  rules = { required: 'Test Case를 선택해주세요.' },
}: Props) => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const uiTestLocation = isWordInLocationPathname('ui-test');

  const { control } = useFormContext<CreateTestStepData>();

  const mobileTestCaseListQuery = uiTestLocation
    ? useMobileTestCaseListQuery(projectIdx)
    : useSpeedMobileTestCaseListQuery();

  const mobileTestCaseList = mobileTestCaseListQuery.data?.filter(
    (mobileTestCase) => mobileTestCase.idx !== Number(idx),
  );

  const findTitleFromIdx = (idx: string) =>
    mobileTestCaseList?.find(
      (mobileTestCase) => mobileTestCase.idx.toString() === idx,
    )?.title;

  return (
    <Controller
      control={control}
      name={value}
      rules={rules}
      render={({ field }) => (
        <ListBoxHeadlessInController
          value={field.value || ''}
          buttonValue={findTitleFromIdx(field.value) || 'Select Test Case'}
          onChange={field.onChange}
          lists={mobileTestCaseList}
          savedType={'string'}
          valueToSave={'idx'}
          valueToShow={'title'}
        />
      )}
    />
  );
};

export default ImportTestCaseListBoxHookForm;
