import {
  WebSpeedTestResultData,
  WebSpeedTestResultDetail,
  WebSpeedTestResultTableDetailData,
} from '@customTypes/ide/speedTest/Web/testResult/type';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosInstance } from '..';
import { useParams, useSearchParams } from 'react-router-dom';
import { API } from '@api/endpoints';
import { webSpeedTestResultKeys } from '@queries/dashboard/webSpeedTestResultKeys';

// GET
const webSpeedTestResultList = async (
  projectIdx: string,
  page: number,
): Promise<WebSpeedTestResultData> => {
  const response = await axiosInstance.get<WebSpeedTestResultData>(
    API.getWebSpeedTestResultList(projectIdx, page),
  );
  return response.data;
};
export const useWebSpeedTestResultListQuery = (page: number) => {
  const { projectIdx } = useParams();
  return useQuery<WebSpeedTestResultData, Error>({
    queryKey: webSpeedTestResultKeys.list(projectIdx, page),
    queryFn: () => webSpeedTestResultList(projectIdx, page),
  });
};

const webSpeedTestResultDetail = async (
  projectIdx: string,
  groupIdx: string,
  idx: string,
  start: string,
  end: string,
  orderBy: string,
  channel: string,
): Promise<WebSpeedTestResultDetail> => {
  const response = await axiosInstance.get<WebSpeedTestResultDetail>(
    API.getWebSpeedTestResultDetail(
      projectIdx,
      groupIdx,
      idx,
      start,
      end,
      orderBy,
      channel,
    ),
  );
  return response.data;
};

export const useWebSpeedTestResultDetailQuery = () => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const groupIdx = searchParams.get('group');
  const idx = searchParams.get('result');
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  const orderBy = searchParams.get('order-by');
  const LowerCaseChannel = searchParams.get('channel');
  const channel =
    LowerCaseChannel.charAt(0).toUpperCase() + LowerCaseChannel.slice(1);

  return useQuery<WebSpeedTestResultDetail, Error>({
    queryKey: webSpeedTestResultKeys.detail(idx),
    queryFn: () =>
      webSpeedTestResultDetail(
        projectIdx,
        groupIdx,
        idx,
        start,
        end,
        orderBy,
        channel,
      ),
  });
};
const webSpeedTestResultTableDetail = async (
  chartIdx: string,
): Promise<WebSpeedTestResultTableDetailData> => {
  const response = await axiosInstance.get<WebSpeedTestResultTableDetailData>(
    API.getWebSpeedTestResultByIdx(chartIdx),
  );
  return response.data;
};

export const useWebSpeedTestResultTableDetailQuery = (
  chartIdx: string,
  options?: UseQueryOptions<WebSpeedTestResultTableDetailData>,
) => {
  return useQuery<WebSpeedTestResultTableDetailData, Error>({
    queryFn: () => webSpeedTestResultTableDetail(chartIdx),
    queryKey: webSpeedTestResultKeys.detailInfo(chartIdx),
    ...options,
  });
};
