import {
  CardListSkeleton,
  ScenarioListDetailData,
  useInput,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import DetailListItemCard from './DetailDraggable/DetailListItemCard';
import DetailPaste from './DetailDraggable/DetailPaste';
import clsx from 'clsx';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';

interface Props {
  testScenarioQuery: UseQueryResult<ScenarioListDetailData[], Error>;
  scenarioList: number[];
}

const DetailDraggable = ({ testScenarioQuery, scenarioList }: Props) => {
  const {
    formState: { errors },
  } = useFormContext<UpdateSchedulerData>();

  const [copyTestScenarios, setCopyTestScenarios] = useState<
    ScenarioListDetailData[]
  >([]);
  const [testScenarios, setTestScenarios] = useState<
    ScenarioListDetailData[] | null
  >([]);
  const [searchWord, onChangeSearchWord, setSearchWord] = useInput('');

  const searchResultArray = testScenarios?.filter((testScenario) =>
    testScenario.title.toLowerCase().includes(searchWord.toLowerCase()),
  );

  const changeCardList = (list: ScenarioListDetailData[]) => {
    setCopyTestScenarios([...list]);
  };

  const allTestScenarios = testScenarioQuery.data;

  const stepArrLists = scenarioList?.map((scenario) =>
    allTestScenarios?.find(
      (allTestScenario) => allTestScenario.idx === scenario,
    ),
  );

  useEffect(() => {
    setCopyTestScenarios(
      stepArrLists?.map((stepArrList, index) => ({
        ...stepArrList,
        dndIndex: index + 1,
      })),
    );
    setTestScenarios(
      allTestScenarios?.map((testScenario, index) => ({
        ...testScenario,
        dndIndex: index + 1,
      })),
    );
  }, [allTestScenarios, scenarioList]);

  const copyAndDropList = [
    {
      title: 'All Test Scenario List',
      list:
        testScenarios?.length === 0 ? (
          <div className="flex h-2/3 items-center justify-center">
            추가 가능한 테스트 시나리오가 존재하지 않습니다.
          </div>
        ) : (
          searchResultArray?.map((testScenario) => (
            <DetailListItemCard
              copyTestScenarios={copyTestScenarios}
              idx={testScenario.idx}
              title={testScenario.title}
              step={testScenario.step}
              caseCount={testScenario.caseCount}
              description={testScenario.description}
              createdAt={testScenario.createdAt}
              modifiedAt={testScenario.modifiedAt}
              changeCardList={changeCardList}
              key={testScenario.idx}
            />
          ))
        ),
    },
    {
      title: 'Scheduler Scenario List',
      list: (
        <DetailPaste
          copyTestScenarios={copyTestScenarios}
          setCopyTestScenarios={setCopyTestScenarios}
          changeCardList={changeCardList}
        />
      ),
    },
  ];
  return (
    <>
      {testScenarioQuery.isLoading ? (
        <div className="flex size-full items-center justify-between">
          <CardListSkeleton />
          <CardListSkeleton />
        </div>
      ) : testScenarioQuery.isError ? (
        <div className="flex items-center justify-center">
          {testScenarioQuery.error.message}
        </div>
      ) : (
        <>
          <div className="flex w-full items-center justify-between gap-4">
            {copyAndDropList?.map((item, index) => (
              <div className="w-full" key={item.title}>
                <div className="mb-2 flex justify-between">
                  <p className="font-medium leading-6">{item.title}</p>
                </div>
                <div
                  className={clsx(
                    'h-52 rounded-md border border-solid border-border-line',
                    {
                      'gray-scrollbar': testScenarios?.length > 0,
                    },
                  )}
                >
                  {index === 0 && (
                    <div className="relative w-full px-2 pb-1.5 pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="absolute left-4 top-4 size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                      <input
                        className="input-sm px-8"
                        value={searchWord}
                        onChange={onChangeSearchWord}
                      />
                      <button
                        type="button"
                        className="absolute right-4 top-4"
                        onClick={() => setSearchWord('')}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                  {item.list}
                </div>
              </div>
            ))}
          </div>
          <ErrorMessage
            name="scenario_idx_list"
            errors={errors}
            render={({ message }) => (
              <p className="standard-error-message">{message}</p>
            )}
          />
        </>
      )}
    </>
  );
};

export default DetailDraggable;
