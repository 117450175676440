import { Button } from '@autosquare/common';
import React, { ChangeEvent } from 'react';

type Props = {
  inputRepeat: string;
  setInputRepeat: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  executeMobileTestStep: () => void;
};

const RepeatStepExecutionRepeatInput = ({
  inputRepeat,
  setInputRepeat,
  errorMessage,
  setErrorMessage,
  setOpenModal,
  executeMobileTestStep,
}: Props) => {
  const inputValueChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    const { value } = e.target;
    const newValue = value.replaceAll(/\D/g, '');
    setInputRepeat(newValue ?? '');
  };
  const cancelHandler = () => {
    setOpenModal(false);
    setInputRepeat(null);
  };
  return (
    <>
      <div className="flex w-full flex-col items-start gap-4 px-4">
        <div className="flex w-full items-center justify-center">
          <label className="w-[86px] text-sm" htmlFor="repeat-input">
            Repeat
          </label>
          <input
            type="text"
            id="repeat-input"
            className="input-base rounded-md p-2"
            value={inputRepeat ?? ''}
            onChange={inputValueChangeHandler}
          />
        </div>
        {errorMessage && (
          <p className="error-message mx-auto">{errorMessage}</p>
        )}
        <div className="flex w-full items-end justify-end gap-x-3">
          <Button type="button" variant={'secondary'} onClick={cancelHandler}>
            Cancel
          </Button>
          <Button
            type="button"
            variant={'caution'}
            onClick={executeMobileTestStep}
          >
            Execute
          </Button>
        </div>
      </div>
    </>
  );
};

export default RepeatStepExecutionRepeatInput;
