import { PagingHeadingsInAutosquare } from '@autosquare/common';

import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import SchedulerListTable from './components/SchedulerListTable';

const SchedulerList = () => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();

  const breadcrumbs = [
    { title: 'UI Test', href: `/ide/projects/${projectIdx}/ui-test/overview` },
    { title: 'Scheduler', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons = [{ title: 'Create scheduler', href: 'create' }];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Scheduler List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <SchedulerListTable />
    </>
  );
};

export default SchedulerList;
