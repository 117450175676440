import { useInput } from '@autosquare/common';
import { SpeedTestCaseDataInScenario } from '@customTypes/speedTestCase/type';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { useSpeedMobileTestScenarioDetailQuery } from '@lib/api/ide/speedMobile/speedMobileTestScenario';
import { useWebSpeedTestCaseListQuery } from '@lib/api/ide/speedWeb/webSpeedTestCase';
import { useSpeedWebTestScenarioDetailQuery } from '@lib/api/ide/speedWeb/webSpeedTestScenario';
import { useEffect, useMemo, useState } from 'react';

export const useSpeedTestScenarioDraggableSpace = (
  type: 'create' | 'detail',
  platform: 'mobile' | 'web',
) => {
  const [copiedTestCaseList, setCopiedTestCaseList] = useState<
    SpeedTestCaseDataInScenario[]
  >([]);
  const [processedTestCaseList, setProcessedTestCaseList] = useState<
    SpeedTestCaseDataInScenario[] | null
  >([]);
  const [searchWord, onChangeSearchWord, setSearchWord] = useInput('');

  const changeCardList = (list: SpeedTestCaseDataInScenario[]) => {
    setCopiedTestCaseList([...list]);
  };

  const speedMobileTestCaseListQuery = useSpeedMobileTestCaseListQuery({
    enabled: platform === 'mobile',
  });
  const speedMobileTestCaseList = speedMobileTestCaseListQuery.data;

  const webSpeedTestCaseListQuery = useWebSpeedTestCaseListQuery({
    enabled: platform === 'web',
  });
  const webSpeedTestCaseList = webSpeedTestCaseListQuery.data;

  const speedMobileTestScenarioDetailQuery =
    useSpeedMobileTestScenarioDetailQuery(undefined, {
      enabled: type === 'detail' && platform === 'mobile',
    });

  const speedWebTestScenarioDetailQuery = useSpeedWebTestScenarioDetailQuery(
    undefined,
    { enabled: type === 'detail' && platform === 'web' },
  );

  useEffect(() => {
    if (platform === 'mobile') {
      setProcessedTestCaseList(
        speedMobileTestCaseList?.map((speedTestCase, index) => ({
          idx: speedTestCase.idx,
          title: speedTestCase.title,
          caseGroupInfo: speedTestCase.caseGroupInfo,
          serviceGroupInfo: speedTestCase.serviceGroupInfo,
          platformType: speedTestCase.platformType,
          dndIndex: index + 1,
          uniqueId: crypto.randomUUID(),
        })),
      );
    }
  }, [speedMobileTestCaseList, platform]);

  useEffect(() => {
    if (platform === 'web') {
      setProcessedTestCaseList(
        webSpeedTestCaseList?.map((speedTestCase, index) => ({
          idx: speedTestCase.idx,
          title: speedTestCase.title,
          caseGroupInfo: speedTestCase.caseGroupInfo,
          serviceGroupInfo: speedTestCase.serviceGroupInfo,
          dndIndex: index + 1,
          uniqueId: crypto.randomUUID(),
        })),
      );
    }
  }, [webSpeedTestCaseList, platform]);

  useEffect(() => {
    if (
      type === 'detail' &&
      platform === 'mobile' &&
      speedMobileTestScenarioDetailQuery.isSuccess &&
      speedMobileTestCaseListQuery.isSuccess
    ) {
      const steps = speedMobileTestScenarioDetailQuery.data?.steps;

      const filteredSpeedMobileTestCaseList = steps?.map((step) =>
        speedMobileTestCaseList?.find((testCase) => testCase.idx === step),
      );

      setCopiedTestCaseList(
        filteredSpeedMobileTestCaseList?.map((speedTestCase, index) => ({
          idx: speedTestCase.idx,
          title: speedTestCase.title,
          caseGroupInfo: speedTestCase.caseGroupInfo,
          serviceGroupInfo: speedTestCase.serviceGroupInfo,
          platformType: speedTestCase.platformType,
          dndIndex: index + 1,
          uniqueId: crypto.randomUUID(),
        })),
      );
    }
  }, [
    speedMobileTestCaseListQuery.isSuccess,
    speedMobileTestScenarioDetailQuery.isSuccess,
    platform,
    type,
  ]);

  useEffect(() => {
    if (
      type === 'detail' &&
      platform === 'web' &&
      webSpeedTestCaseListQuery.isSuccess &&
      speedWebTestScenarioDetailQuery.isSuccess
    ) {
      const steps = speedWebTestScenarioDetailQuery.data?.step;

      const filteredSpeedWebTestCaseList = steps?.map((step) =>
        webSpeedTestCaseList?.find((testCase) => testCase.idx === step),
      );

      setCopiedTestCaseList(
        filteredSpeedWebTestCaseList?.map((speedTestCase, index) => ({
          idx: speedTestCase.idx,
          title: speedTestCase.title,
          caseGroupInfo: speedTestCase.caseGroupInfo,
          serviceGroupInfo: speedTestCase.serviceGroupInfo,
          dndIndex: index + 1,
          uniqueId: crypto.randomUUID(),
        })),
      );
    }
  }, [
    webSpeedTestCaseListQuery.isSuccess,
    speedWebTestScenarioDetailQuery.isSuccess,
    platform,
    type,
  ]);

  const searchResultTestCaseList = useMemo(
    () =>
      processedTestCaseList?.filter((testCase) =>
        testCase.title.toLowerCase().includes(searchWord.toLowerCase()),
      ),
    [processedTestCaseList, searchWord],
  );

  return {
    copiedTestCaseList,
    setCopiedTestCaseList,
    searchWord,
    onChangeSearchWord,
    setSearchWord,
    changeCardList,
    searchResultTestCaseList,
    speedMobileTestCaseList,
    webSpeedTestCaseList,
    isLoading:
      platform === 'mobile'
        ? speedMobileTestCaseListQuery.isLoading
        : webSpeedTestCaseListQuery.isLoading,
    isError:
      platform === 'mobile'
        ? speedMobileTestCaseListQuery.isError
        : webSpeedTestCaseListQuery.isError,
    error:
      platform === 'mobile'
        ? speedMobileTestCaseListQuery.error
        : webSpeedTestCaseListQuery.error,
  };
};
