import { ListBoxHeadlessInController } from '@autosquare/common';

import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTestStepValueList } from '@hooks/testStep/useTestStepValueList';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
} from '@utils/static/mobileCommandOption';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import AddTestStepConditionHeader from '@components/shared/TestStep/AddTestStepConditionHeader';
import TestStepValueListbox from '@components/shared/IDE/TestStepValueListbox';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

const IfCondition = () => {
  const { setConditionValue, isListboxOpened, setIsListboxOpened } = useContext(
    MobileTestStepContext,
  );

  const { watch, control, resetField, setValue } =
    useFormContext<CreateTestStepData>();

  const conditionCommand = watch('optionsDto.conditionCommand');
  const conditionValue = watch('optionsDto.conditionValue');

  const { totalValues, bounds } = useTestStepValueList({
    setValue: setConditionValue,
    command: conditionCommand,
  });

  useEffect(() => {
    return () => {
      resetField('optionsDto.conditionCommand');
      resetField('optionsDto.conditionValue');
    };
  }, [resetField]);

  useEffect(() => {
    setValue('optionsDto.conditionOptions.elementOptions.bounds', bounds);
    return () => setValue('optionsDto.conditionOptions', undefined);
  }, [setValue, conditionValue]);

  return (
    <>
      <AddTestStepConditionHeader
        header={'Condition'}
        description={'조건식을 설정합니다.'}
      />
      <Controller
        control={control}
        name={'optionsDto.conditionCommand'}
        rules={{ required: 'Condition Command를 선택해주세요.' }}
        defaultValue={undefined}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              findMobileCommandNameFromServerName(field.value) || 'Command'
            }
            onChange={field.onChange}
            lists={mobileCommandOptions.filter(
              (mobileCommand) =>
                mobileCommand.name === MobileCommandOptionName.FindElement ||
                mobileCommand.name === MobileCommandOptionName.VerifyNoElement,
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
          />
        )}
      />
      <Controller
        control={control}
        name={'optionsDto.conditionValue'}
        rules={{ required: 'Condition Value를 선택해주세요.' }}
        render={({ field }) => (
          <TestStepValueListbox
            valueText={field.value || 'Value'}
            setValueText={field.onChange}
            totalValues={totalValues}
            isListboxOpened={isListboxOpened}
            setIsListboxOpened={setIsListboxOpened}
            resetOnClick={() => {
              resetField('optionsDto.conditionCommand');
              setValue('optionsDto.conditionValue', undefined);
            }}
            disabled={
              conditionCommand === undefined || conditionValue !== undefined
            }
          />
        )}
      />
    </>
  );
};

export default IfCondition;
