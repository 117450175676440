import { ProjectTitleListUI } from '@autosquare/common';
import { useProjectListQuery } from '@lib/api/ide/project/project';
import React, { useState } from 'react';

type Props = {
  projectName: string;
};

const SidebarTitle = ({ projectName }: Props) => {
  const { data } = useProjectListQuery();

  const [projectTitle, setProjectTitle] = useState(projectName);

  return (
    <div className="mt-6 flex items-center font-semibold ">
      <ProjectTitleListUI
        state={projectTitle}
        setState={setProjectTitle}
        list={data}
        wordInButton="name"
        wordInOptions="name"
      />
    </div>
  );
};

export default SidebarTitle;
