import React, { useContext } from 'react';
import { Arrow, Circle, Group } from 'react-konva';
import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import { canvasHandleCursor } from '@hooks/konva/canvasHandleCursor';
import { ArrowPoint } from '@customTypes/testStep/type';

type Props = {
  startNode: ArrowPoint;
  endNode: ArrowPoint;
  setStartNode: React.Dispatch<React.SetStateAction<ArrowPoint>>;
  setEndNode: React.Dispatch<React.SetStateAction<ArrowPoint>>;
  realScreenRatio: number;
};

const MovingArrow = ({
  startNode,
  endNode,
  setStartNode,
  setEndNode,
  realScreenRatio,
}: Props) => {
  const { setStartX, setStartY, setEndX, setEndY } = useContext(
    MobileTestStepContext,
  );

  const dx = startNode?.x - endNode?.x;
  const dy = startNode?.y - endNode?.y;

  const angle = Math.atan2(-dy, dx);

  const radius = 20;

  const arrowStart = {
    x: endNode?.x + -radius * Math.cos(angle + Math.PI),
    y: endNode?.y + radius * Math.sin(angle + Math.PI),
  };

  const arrowEnd = {
    x: startNode?.x + -radius * Math.cos(angle),
    y: startNode?.y + radius * Math.sin(angle),
  };

  const arrowMiddle = {
    x: (arrowStart.x + arrowEnd.x) / 2,
    y: (arrowStart.y + arrowEnd.y) / 2,
  };

  return (
    <>
      <Group>
        <Arrow
          points={[
            arrowStart?.x,
            arrowStart?.y,
            arrowMiddle?.x,
            arrowMiddle?.y,
            arrowEnd?.x,
            arrowEnd?.y,
          ]}
          stroke="#FFA500"
          fill="#FFA500"
          strokeWidth={7}
          pointerWidth={14}
        />
        <Circle
          {...startNode}
          onDragMove={(e) => {
            setStartNode({ ...startNode, ...e.target.position() });
            setEndX(Math.floor(e.target.position().x / realScreenRatio));
            setEndY(Math.floor(e.target.position().y / realScreenRatio));
          }}
          onMouseEnter={canvasHandleCursor}
          onMouseLeave={canvasHandleCursor}
        />
        <Circle
          {...endNode}
          onDragMove={(e) => {
            setEndNode({ ...endNode, ...e.target.position() });
            setStartX(Math.floor(e.target.position().x / realScreenRatio));
            setStartY(Math.floor(e.target.position().y / realScreenRatio));
          }}
          onMouseEnter={canvasHandleCursor}
          onMouseLeave={canvasHandleCursor}
        />
      </Group>
    </>
  );
};

export default MovingArrow;
