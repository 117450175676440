import {
  BasicAlert,
  DoubleCheckDialogHeadlessUI,
  Button,
  ArrowSpinner,
} from '@autosquare/common';

import React, { useState } from 'react';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';
import { ClickId } from '@utils/static/clickOptions';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';
import SpeedPropertyDetail from './SpeedTestStepListProperty/SpeedPropertyDetail';
import PropertyValue from '@components/MobileTestStep/components/MainTestStep/TestStepList/TestStepDragList/TestStepListProperty/PropertyValue';
import ClickTextReplace from '@components/MobileTestStep/components/MainTestStep/TestStepList/TestStepDragList/TestStepListProperty/ClickTextReplace';
import SaveTextList from '@components/MobileTestStep/components/MainTestStep/TestStepList/TestStepDragList/TestStepListProperty/SaveTextList';
import UnLockPatternList from '@components/MobileTestStep/components/MainTestStep/TestStepList/TestStepDragList/TestStepListProperty/UnLockPatternList';
import SpeedLoopTestStepList from './SpeedTestStepListProperty/SpeedLoopTestStepList';
import SpeedIfTestStepList from './SpeedTestStepListProperty/SpeedIfTestStepList';
import { useDeleteSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

type Props = {
  list: MobileTestStepData;
  saveTextIndex: number;
  findTitleFromIdx: (idx: number) => string;
};

const TestStepListProperty = ({
  list,
  saveTextIndex,
  findTitleFromIdx,
}: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const { command, value, description, required, idx, optionsDto } = list;
  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);
  const deleteTestStepMutation = useDeleteSpeedMobileTestStep();

  return (
    <>
      <div className="text-sm text-gray-500">
        <div className="border border-gray-200">
          <dl className="divide-y divide-gray-100">
            <SpeedPropertyDetail
              term={'Command'}
              description={findMobileCommandNameFromServerName(command)}
            />
            {list.command !== MobileCommandOptionServerName.Loop &&
              list.command !== MobileCommandOptionServerName.IF &&
              list.command !== MobileCommandOptionServerName.UnlockPattern &&
              !(
                list.command === MobileCommandOptionServerName.SaveText &&
                list.optionsDto.from === SaveTextFrom.Sms
              ) && (
                <PropertyValue
                  commandText={command}
                  valueText={value}
                  setIsCopied={setIsCopied}
                  optionsDto={optionsDto}
                  saveTextIndex={saveTextIndex}
                  findTitleFromIdx={findTitleFromIdx}
                />
              )}
            <SpeedPropertyDetail
              term={'Description'}
              description={description}
            />
            {list.command === MobileCommandOptionServerName.Click &&
            list.optionsDto.clickBy === ClickId.Default ? (
              <ClickTextReplace list={list} />
            ) : list.command === MobileCommandOptionServerName.ScrollSwipe ? (
              <SpeedPropertyDetail
                term={'Repeat'}
                description={optionsDto.repeatLimit}
              />
            ) : list.command === MobileCommandOptionServerName.SaveText ? (
              <SaveTextList list={list} />
            ) : (
              list.command === MobileCommandOptionServerName.UnlockPattern && (
                <UnLockPatternList list={list} />
              )
            )}
            {list.command !== MobileCommandOptionServerName.Loop &&
              list.command !== MobileCommandOptionServerName.IF &&
              list.command !== MobileCommandOptionServerName.Wait && (
                <SpeedPropertyDetail
                  term={'Required'}
                  description={required ? 'Y' : 'N'}
                />
              )}
            {list.command === MobileCommandOptionServerName.Loop ? (
              <SpeedLoopTestStepList list={list} setIsCopied={setIsCopied} />
            ) : (
              list.command === MobileCommandOptionServerName.IF && (
                <SpeedIfTestStepList
                  list={list}
                  setIsCopied={setIsCopied}
                  findTitleFromIdx={findTitleFromIdx}
                />
              )
            )}
            {!(
              list.command === MobileCommandOptionServerName.Loop &&
              list.value === MobileCommandOptionServerName.Wait
            ) &&
              list.command !== MobileCommandOptionServerName.Wait && (
                <SpeedPropertyDetail
                  term={'WaitTime'}
                  description={optionsDto.waitingTime}
                />
              )}

            <div className="grid grid-cols-3 bg-white px-3 py-2">
              <dt className="text-xs font-medium leading-6 text-gray-900"></dt>
              <dd className="col-span-2 mt-0 flex justify-end text-xs leading-6 text-gray-700">
                <button type="button" onClick={openModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="size-5 text-gray-700"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={deleteTestStepMutation.isError ? 'Error' : 'Delete Test Step'}
        subTitle={
          deleteTestStepMutation.isError
            ? deleteTestStepMutation.error.message
            : `해당 테스트 스텝을 삭제하시겠습니까?\n설정한 Repeat Step 옵션도 같이 삭제 처리됩니다.`
        }
        buttonChildren={
          <>
            {!deleteTestStepMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteTestStepMutation.mutate(
                    {
                      stepIdxList: [idx],
                      mobileOs: deviceInfo.os,
                    },
                    {
                      onError: () => {
                        openModal();
                      },
                    },
                  )
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </>
        }
      />
      {isCopied && <BasicAlert phrase="클립보드에 복사되었습니다." />}
      {deleteTestStepMutation.isLoading && <ArrowSpinner />}
    </>
  );
};

export default TestStepListProperty;
