import { ListBoxHeadlessInController } from '@autosquare/common';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

interface Props {
  saveTextLists: MobileTestStepData[];
}

const TestStepSelectSaveText = ({ saveTextLists }: Props) => {
  const { control } = useFormContext<UpdateTestStepData>();

  const findDescriptionFromIdx = (idx: number) =>
    saveTextLists?.find((saveText) => saveText.idx === idx)?.description;

  return (
    <Controller
      control={control}
      name={'optionsDto.compareIdx'}
      rules={{ required: 'Test Step을 선택해주세요.' }}
      render={({ field }) => (
        <ListBoxHeadlessInController
          value={field.value}
          buttonValue={
            findDescriptionFromIdx(Number(field.value)) || 'Select Test Step'
          }
          onChange={field.onChange}
          lists={saveTextLists}
          valueToSave={'idx'}
          valueToShow={'description'}
        />
      )}
    />
  );
};

export default TestStepSelectSaveText;
