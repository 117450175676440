import { Button } from '@autosquare/common';

import clsx from 'clsx';
import React from 'react';

interface Props {
  isSuccess: boolean;
  onClick: () => void;
  connectingType: 'wifi' | 'adb';
  errorMessage?: string;
}

const IsSuccessConnection = ({
  isSuccess,
  onClick,
  connectingType,
  errorMessage,
}: Props) => {
  return (
    <div className="flex-center flex-col gap-8 text-center text-sm font-normal leading-tight tracking-tight">
      <div className="flex-center flex-col gap-2">
        <h4
          className={clsx(
            'text-base font-semibold',
            isSuccess ? 'text-[#043e8d]' : 'text-red-500',
          )}
        >
          {isSuccess ? 'Successful' : 'Failure'}
        </h4>
        {isSuccess ? (
          <>
            <p className="text-gray-500">
              디바이스 무선 연결이 완료 되었습니다.
            </p>
            {connectingType === 'adb' && (
              <p className="text-gray-500">USB 연결을 해제해 주세요.</p>
            )}
          </>
        ) : (
          <>
            <p className="text-gray-500">디바이스 무선 연결이 실패했습니다.</p>
            <p className="text-gray-500">
              {errorMessage ||
                '무선 디버깅 설정 활성화 상태와 입력 정보가 정확한지 확인해 주세요.'}
            </p>
          </>
        )}
      </div>
      <Button type="button" buttonSize="sm" onClick={onClick}>
        OK
      </Button>
    </div>
  );
};

export default IsSuccessConnection;
