export const mobileTestStepKeys = {
  all: ["mobileTestStep"] as const,
  lists: () => [...mobileTestStepKeys.all, "list"] as const,
  list: (idx: string) => [...mobileTestStepKeys.lists(), idx] as const,
  details: () => [...mobileTestStepKeys.all, "detail"] as const,
  detail: (testStepIdx: number) =>
    [...mobileTestStepKeys.details(), testStepIdx] as const,
  cases: () => [...mobileTestStepKeys.all, "case"] as const,
  case: (idx: string) => [...mobileTestStepKeys.cases(), idx] as const,
  commands: () => [...mobileTestStepKeys.all, "command"] as const,
  command: (command: string, idx: number) =>
    [...mobileTestStepKeys.commands(), command, idx] as const,
};
