import DateFormat from '@components/shared/SpeedWebTestStep/DateFormat';
import { UpdateWebSpeedTestStepData } from '@customTypes/ide/speedTest/Web/testStep/type';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const UpdateWebSpeedDateFormat = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateWebSpeedTestStepData>();

  return (
    <DateFormat
      watch={watch}
      setValue={setValue}
      control={control}
      errors={errors}
    />
  );
};

export default UpdateWebSpeedDateFormat;
