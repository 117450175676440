import { PagingHeadingsInAutosquare } from '@autosquare/common';

import GroupProvider from '@contexts/ide/GroupProvider';

import { useSpeedMobileTestCaseDetailQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';

import { PlatFormTypeServerName } from '@utils/static/platformTypeList';

import React from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import TestStepList from './components/TestStepList';
import TestCaseInfo from './components/TestCaseInfo';
import MobileWebTestStep from './components/TestStepList/MobileWebTestStep';
import MobileTestStepDetailProvider from '@contexts/ide/MobileTestStepDetailProvider';

const MobileSpeedTestCaseDetail = () => {
  const { projectIdx } = useParams();
  const { pathname, search } = useLocation();
  const [searchParm] = useSearchParams();

  const idx = searchParm.get('idx');

  const speedMobileTestCaseDetailQuery = useSpeedMobileTestCaseDetailQuery(idx);
  const speedMobileTestCaseDetail = speedMobileTestCaseDetailQuery.data;
  const platFormType = speedMobileTestCaseDetail?.platformType;

  const testCasePage = `/ide/projects/${projectIdx}/speed-test/mobile/test-case`;
  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -3).join('/')}/overview`,
    },
    { title: 'Mobile', href: testCasePage },
    { title: 'Test Case', href: testCasePage },
    { title: 'Detail', href: pathname + search },
  ];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case Detail'}
      />
      <GroupProvider>
        <TestCaseInfo idx={idx} />
        {platFormType === PlatFormTypeServerName.MobileApp ? (
          <MobileTestStepDetailProvider>
            <TestStepList platformType={platFormType} />
          </MobileTestStepDetailProvider>
        ) : (
          platFormType === PlatFormTypeServerName.MobileWeb && (
            <MobileWebTestStep />
          )
        )}
      </GroupProvider>
    </>
  );
};

export default MobileSpeedTestCaseDetail;
