import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { axiosInstance, axiosLocalInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { schedulerKeys } from '@queries/ide/schedulerKeys';

import {
  CreateSchedulerData,
  DeleteSchedulerData,
  SchedulerDetailData,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

interface UseUpdateSchedulerMutation
  extends UseMutationOptions<UpdateSchedulerData> {
  cookie: string;
  url: string;
}

interface UseUpdateSchedulerStatus {
  idx: number;
  isRunning: boolean;
  cookie: string;
}

// GET
const schedulerList = async (idx: string): Promise<SchedulerDetailData[]> => {
  const response = await axiosInstance.get<SchedulerDetailData[]>(
    IDEAPI.GET_ALL_SCHEDULER_LIST + idx,
  );
  return response.data;
};
export const useSchedulerListQuery = () => {
  const { projectIdx } = useParams();
  return useQuery<SchedulerDetailData[], Error>({
    queryKey: schedulerKeys.list(projectIdx),
    queryFn: () => schedulerList(projectIdx),
  });
};

const schedulerDetail = async (idx: string): Promise<SchedulerDetailData> => {
  const response = await axiosInstance.get<SchedulerDetailData>(
    IDEAPI.GET_SCHEDULER + idx,
  );
  return response.data;
};
export const useSchedulerDetailQuery = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  return useQuery<SchedulerDetailData, Error>({
    queryKey: schedulerKeys.detail(idx),
    queryFn: () => schedulerDetail(idx),
    staleTime: 0,
  });
};

const schedulerInit = async (): Promise<unknown> => {
  const response = await axiosLocalInstance.get(IDEAPI.SCHEDULER_INIT);
  return response.data;
};
export const useSchedulerInitQuery = () =>
  useQuery<unknown, Error>({
    queryKey: schedulerKeys.init(),
    queryFn: () => schedulerInit(),
  });

// POST
const createScheduler = async (
  cookie: string,
  data: CreateSchedulerData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.CREATE_SCHEDULER,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useCreateSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<unknown, Error, CreateSchedulerData>({
    mutationFn: (data) => createScheduler(cookie, data),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.all);
      navigate(pathname.split('/').slice(0, -1).join('/'));
    },
  });
};

// PUT
const updateScheduler = async (
  cookie: string,
  data: UpdateSchedulerData,
): Promise<UpdateSchedulerData> => {
  const response = await axiosLocalInstance.put<UpdateSchedulerData>(
    IDEAPI.UPDATE_SCHEDULER,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useUpdateSchedulerMutation = ({
  cookie,
  url,
}: UseUpdateSchedulerMutation) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<UpdateSchedulerData, Error, UpdateSchedulerData>({
    mutationFn: (data) => updateScheduler(cookie, data),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.all);
      navigate(url);
    },
  });
};

const updateSchedulerStatus = async ({
  idx,
  isRunning,
  cookie,
}: UseUpdateSchedulerStatus): Promise<void> => {
  const response = await axiosLocalInstance.put<void>(
    `${IDEAPI.UPDATE_SCHEDULER_STATUS}${idx}/${isRunning}`,
    undefined,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useUpdateSchedulerStatusMutation = ({
  idx,
  isRunning,
  cookie,
}: UseUpdateSchedulerStatus) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error>({
    mutationFn: () =>
      updateSchedulerStatus({ idx: idx, isRunning: isRunning, cookie: cookie }),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.lists());
    },
  });
};

const deleteScheduler = async (
  data: DeleteSchedulerData,
  cookie: string,
): Promise<DeleteSchedulerData> => {
  const response = await axiosLocalInstance.put<DeleteSchedulerData>(
    IDEAPI.DELETE_SCHEDULER,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useDeleteSchedulerMutation = (cookie: string) => {
  const queryClient = useQueryClient();

  return useMutation<DeleteSchedulerData, Error, DeleteSchedulerData>({
    mutationFn: (data) => deleteScheduler(data, cookie),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.all);
    },
  });
};
