import React from "react";
import { useNavigate } from "react-router-dom";

const SignUpError = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col items-center justify-center my-[1rem]">
        <p className="text-3xl font-bold">잘못된 접근입니다.</p>
        <p className="text-3xl font-bold">관리자에게 문의해주세요.</p>
      </div>
      <button
        className="w-[7rem] h-[3rem] text-base bg-login-blue text-white rounded-md font-bold"
        onClick={() => navigate("/")}
      >
        홈으로
      </button>
    </>
  );
};

export default SignUpError;
