import { StartAndFinishMark } from '@autosquare/common';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';
import { ClickId } from '@utils/static/clickOptions';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import SpeedTestStepListProperty from './SpeedTestStepDragList/SpeedTestStepListProperty';
import clsx from 'clsx';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';

interface Props {
  list: MobileTestStepData;
  index: number;
  lists: MobileTestStepData[];
  startStepIdx: number;
  endStepIdx: number;
}

const SpeedTestStepDragList = ({
  list,
  index,
  lists,
  startStepIdx,
  endStepIdx,
}: Props) => {
  const [isOpenedDetail, setIsOpenedDetails] = useState(false);

  const toggledDetails = () => {
    setIsOpenedDetails(!isOpenedDetail);
  };

  const saveTextIndex =
    lists
      ?.map((item) => item.idx)
      .indexOf(parseInt(list.optionsDto.compareIdx)) + 1;

  const mobileTestCaseListQuery = useSpeedMobileTestCaseListQuery({
    enabled: list.command === MobileCommandOptionServerName.ImportTestCase,
  });
  const mobileTestCaseList = mobileTestCaseListQuery.data;

  const findTitleFromIdx = (idx: number) =>
    mobileTestCaseList?.find((testCase) => testCase.idx === idx)?.title;
  return (
    <>
      <Draggable draggableId={list.idx.toString()} index={index}>
        {(provided) => (
          <li
            className="flex w-full flex-col"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onDragStart={() => setIsOpenedDetails(false)}
          >
            <div className="flex items-center justify-center">
              {Number(
                provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
              ) === startStepIdx && (
                <div className="w-1">
                  <StartAndFinishMark size="medium" />
                </div>
              )}
              {Number(
                provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
              ) === endStepIdx && (
                <div className="w-1">
                  <StartAndFinishMark size="medium" />
                </div>
              )}

              <div
                className={clsx(
                  'flex w-full justify-between border border-gray-200 bg-gray-50 p-3 text-left text-xs font-medium text-gray-900',
                  { 'border-b-0': isOpenedDetail },
                )}
                onClick={toggledDetails}
              >
                <div className="flex w-3/4 flex-row items-center gap-2">
                  <div className="w-[43.607rem] truncate">
                    <p className="truncate">
                      {list.description},{' '}
                      {findMobileCommandNameFromServerName(list.command)},{' '}
                      {(list.command === MobileCommandOptionServerName.Click &&
                        list.optionsDto.clickBy === ClickId.Image) ||
                      list.command ===
                        MobileCommandOptionServerName.CompareImage
                        ? 'Crop Image'
                        : list.command ===
                              MobileCommandOptionServerName.ScrollSwipe ||
                            (list.command ===
                              MobileCommandOptionServerName.Click &&
                              list.value?.split(',').length >= 2)
                          ? `[${list.value}]`
                          : list.command ===
                              MobileCommandOptionServerName.DeviceKey
                            ? findListNameFromServerName(
                                list.value as DeviceKeyServerName,
                              )
                            : list.command ===
                                MobileCommandOptionServerName.Loop
                              ? `Condition="${findMobileCommandNameFromServerName(
                                  list.optionsDto.conditionCommand,
                                )}" Action="${findMobileCommandNameFromServerName(
                                  list.value as MobileCommandOptionServerName,
                                )}"`
                              : list.command ===
                                  MobileCommandOptionServerName.IF
                                ? list.optionsDto.conditionValue
                                : list.command ===
                                    MobileCommandOptionServerName.ImportTestCase
                                  ? findTitleFromIdx(Number(list.value))
                                  : list.command ===
                                      MobileCommandOptionServerName.UnlockPattern
                                    ? 'points'
                                    : list.value}
                    </p>
                  </div>
                </div>
                <ChevronUpIcon
                  // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
                  className={`${
                    isOpenedDetail ? 'transform' : 'rotate-180'
                  } size-5 cursor-pointer text-gray-900`}
                />
              </div>
            </div>
            {isOpenedDetail && (
              <SpeedTestStepListProperty
                list={list}
                saveTextIndex={saveTextIndex}
                findTitleFromIdx={findTitleFromIdx}
              />
            )}
          </li>
        )}
      </Draggable>
    </>
  );
};

export default SpeedTestStepDragList;
