import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SpeedMobileCaseExecution } from '@customTypes/speedTestExecution/speedTestExecution';
import { Path, PathValue, UseFormSetValue } from 'react-hook-form';

type ScrollAndSwipeValueKey = `startX` | `startY` | `endX` | `endY`;
type ParsedValueKey = `parsedValue.${number}.x` | `parsedValue.${number}.y`;
type FieldName = ScrollAndSwipeValueKey | ParsedValueKey | 'repeatCount';

export const onOnlyNumberChangeHandler = <
  T extends UpdateTestStepData | SpeedMobileCaseExecution,
>(
  e: React.ChangeEvent<HTMLInputElement>,
  name: FieldName,
  setValue: UseFormSetValue<T>,
) => {
  const { value } = e.target;
  const newValue = value.replaceAll(/\D/g, '');
  setValue(name as Path<T>, newValue as PathValue<T, Path<T>>);
};
