import React from 'react';
import SkeletonDeviceLabel from './SpeedDeviceSkeleton/SkeletonDeviceLabel';
import SkeletonDeviceItem from './SpeedDeviceSkeleton/SkeletonDeviceItem';
type Props = {
  gridCols: string;
};
const DeviceSkeleton = ({ gridCols }: Props) => {
  const num = Number(gridCols.split('-')[2]);
  return (
    <div className="my-3 grid grid-rows-1 gap-y-3">
      <SkeletonDeviceLabel label="Android" />
      <SkeletonDeviceItem gridCols={num} />
      <SkeletonDeviceLabel label="iOS" />
      <SkeletonDeviceItem gridCols={num} />
    </div>
  );
};

export default DeviceSkeleton;
