import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "@app/store";

const Main = () => {
  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue
  );

  return (
    <>{accessToken ? <Navigate to="/projects" /> : <Navigate to="/login" />}</>
  );
};

export default Main;
