import React from 'react';

import Steps from './HowToUseWifiConnection/Steps';

const HowToUseWifiConnection = () => {
  const preparation = [
    {
      title:
        '페어링 코드 방식은 안드로이드 11 버전 이상만 무선 디버깅 연결이 가능합니다.',
    },
    { title: 'PC와 디바이스가 동일한 Wi-Fi에 연결되어 있어야 합니다.' },
    {
      title: '핸드폰 개발자 옵션을 활성화 상태로 변경해 주세요.',
      subTitle:
        '개발자 옵션 활성화 하는법: 설정 > 휴대전화 정보 > 빌드 번호 연속으로 7회 클릭 > 개발자 옵션 활성화 버튼 클릭',
    },
  ];

  return (
    <section className="py-6">
      <h3 className="text-base font-semibold leading-7 text-gray-900">
        How to use
      </h3>
      <ul className="flex flex-col gap-2.5 pb-8 pt-2.5">
        {preparation.map((item) => (
          <li
            key={item.title}
            className="flex items-center gap-2.5 text-sm font-normal leading-tight text-gray-900 first:text-red-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-congress-blue"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            <div>
              <p>{item.title}</p>
              <p className="text-gray-400">{item.subTitle}</p>
            </div>
          </li>
        ))}
      </ul>
      <Steps />
    </section>
  );
};

export default HowToUseWifiConnection;
