import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  TableBodyContainer,
  TableHeader,
  TableSkeleton,
} from '@autosquare/common';

import { hasDownloadedData } from '@store/ide/settings/settingsSlice';

import {
  useDownloadDerivedDataQuery,
  useUploadDerivedDataMutation,
} from '@lib/api/ide/setting/webDriverAgentController';

import { StatusDerivedData } from '@customTypes/setting/type';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import WebDriverAgentTableBodyRow from './WebDriverAgent/WebDriverAgentTableBodyRow';

interface FilesMap {
  [key: number]: File[];
}

type Props = {
  accessToken: string;
  closeModal?: () => void;
};

const WebDriverAgent = ({ accessToken, closeModal }: Props) => {
  const [filteredDerivedData, setFilteredDerivedData] =
    useState<StatusDerivedData[]>(undefined);
  const [isDownloadDerivedData, setIsDownloadDerivedData] = useState(false);
  const [files, setFiles] = useState<FilesMap>({});
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');

  const dispatch = useDispatch();

  const downloadDerivedDataQuery = useDownloadDerivedDataQuery({
    enabled: typeof accessToken === 'string',
  });
  const downloadDerivedData = downloadDerivedDataQuery.data;

  useEffect(() => {
    const getStatusDerivedData = async () => {
      const updateDerivedData: StatusDerivedData[] =
        await window.electron.invoke(
          'filteredDerivedData',
          downloadDerivedData,
        );

      setFilteredDerivedData(updateDerivedData);
    };

    getStatusDerivedData();
  }, [downloadDerivedData, isDownloadDerivedData]);

  useEffect(() => {
    window.electron.on('successToDownload', () => {
      downloadDerivedDataQuery.refetch();
      setIsDownloadDerivedData(false);
    });

    window.electron.on('failToDownloadWda', (errorMessage: string) => {
      setIsDownloadDerivedData(false);
      setIsModalOpened(true);
      setUploadErrorMessage(errorMessage);
    });
  }, []);

  const uploadDerivedDataMutation = useUploadDerivedDataMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const uploadPromises = [];

    for (const [idx, fileList] of Object.entries(files)) {
      const typedFileList: File[] = fileList as File[];
      const formData = new FormData();
      typedFileList.forEach((file) => formData.append('file', file));

      const uploadPromise = await uploadDerivedDataMutation.mutateAsync({
        idx: Number(idx),
        data: formData,
      });

      uploadPromises.push(uploadPromise);
    }

    try {
      await Promise.all(uploadPromises);
      dispatch(hasDownloadedData(true));
      closeModal();
    } catch (error) {
      setUploadErrorMessage(error);
      setIsModalOpened(true);
    }
  };

  return (
    <>
      <div className="mt-10 pb-5">
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            WebDriverAgent
          </h3>
        </div>
        {downloadDerivedDataQuery.isLoading ? (
          <div className="py-4">
            <TableSkeleton />
          </div>
        ) : downloadDerivedDataQuery.isError ? (
          <ErrorMessage>{downloadDerivedDataQuery.error.message}</ErrorMessage>
        ) : (
          <form className="mt-4 flow-root" onSubmit={handleSubmit}>
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    {tableHeaders.map((tableHeader) => (
                      <TableHeader key={tableHeader}>{tableHeader}</TableHeader>
                    ))}
                  </tr>
                </thead>
                <TableBodyContainer>
                  {filteredDerivedData?.map((derivedData) => (
                    <WebDriverAgentTableBodyRow
                      key={derivedData.project_idx}
                      derivedData={derivedData}
                      setIsDownloadDerivedData={setIsDownloadDerivedData}
                      setFiles={setFiles}
                    />
                  ))}
                </TableBodyContainer>
              </table>
            </div>
            <div className="flex items-center justify-end">
              <Button type="submit">Save</Button>
            </div>
          </form>
        )}
      </div>
      {(isDownloadDerivedData || uploadDerivedDataMutation.isLoading) && (
        <ArrowSpinner />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={'Error'}
        subTitle={uploadErrorMessage}
        buttonChildren={
          <Button
            type="button"
            variant={'primary'}
            onClick={() => {
              setIsModalOpened(false);
              setUploadErrorMessage('');
            }}
          >
            Close
          </Button>
        }
      />
    </>
  );
};

export default WebDriverAgent;

const tableHeaders = ['Project Name', 'Status', 'Download', 'Upload'];
