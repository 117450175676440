export const mobileSpeedTestResultKeys = {
  all: ['mobileSpeedTestResultKeys'] as const,
  lists: () => [...mobileSpeedTestResultKeys.all, 'list'] as const,
  list: (projectIdx: string, page: number, size: number) =>
    [...mobileSpeedTestResultKeys.lists(), projectIdx, page, size] as const,
  result: (projectIdx: string, startDateFormat: string) =>
    [...mobileSpeedTestResultKeys.all, projectIdx, startDateFormat] as const,
  details: () => [...mobileSpeedTestResultKeys.all, 'detail'] as const,
  detail: (idx: string) =>
    [...mobileSpeedTestResultKeys.details(), idx] as const,

  detailInfos: () => [...mobileSpeedTestResultKeys.all, 'detailInfo'] as const,
  detailInfo: (idx: string) =>
    [...mobileSpeedTestResultKeys.detailInfos(), idx] as const,
  detailVideo: (path: string[], chartIndex: number) =>
    [
      [...mobileSpeedTestResultKeys.detailInfos(), path, chartIndex],
      'video',
    ] as const,
  detailLog: (path: string, chartIndex: number) =>
    [
      [...mobileSpeedTestResultKeys.detailInfos(), path, chartIndex],
      'log',
    ] as const,
};
