import { Button } from '@autosquare/common';
import SettingsAndHelpModal from '@components/SettingsAndHelpModal/SettingsAndHelpModal';

import React from 'react';

type Props = {
  errorMessage: string;
  isSettingsOpen: boolean;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const XcodeError = ({
  errorMessage = 'Requires setup for iOS testing.',
  isSettingsOpen,
  setIsSettingsOpen,
}: Props) => {
  return (
    <>
      <div className="mt-3 flex w-full flex-col items-center justify-center gap-4 rounded-md border border-solid border-gray-200 bg-gray-50 p-2 text-base leading-5">
        <p className="text-red-500">{errorMessage}</p>
        <Button
          type="button"
          variant="primary"
          onClick={() => setIsSettingsOpen(true)}
        >
          Open iOS Test Settings
        </Button>
      </div>
      {isSettingsOpen && (
        <SettingsAndHelpModal
          setState={setIsSettingsOpen}
          type="settings"
          menu="iOS Test"
        />
      )}
    </>
  );
};

export default XcodeError;
