import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ScrollAndSwipeInputOptions } from '@customTypes/testStep/type';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { onOnlyNumberChangeHandler } from '@utils/static/mobileTestStep/onOnlyNumberChangeHandler';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  mobileTestStep: MobileTestStepData;
  keyName: 'value' | 'optionsDto.actionValue';
};

const TestStepDetailScrollAndSwipe = ({ mobileTestStep, keyName }: Props) => {
  const [mobileScreenWidth, mobileScreenHeight] =
    mobileTestStep.optionsDto.screenSize.split('x');

  const imageWidth = Number(mobileScreenWidth);
  const imageHeight = Number(mobileScreenHeight);

  const {
    register,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();

  const [startX, startY, endX, endY] =
    keyName === 'value'
      ? mobileTestStep.value.split(',')
      : keyName === 'optionsDto.actionValue' &&
        mobileTestStep.optionsDto.actionValue.split(',');

  const isScrollAndSwipe =
    keyName === 'value'
      ? mobileTestStep.command === MobileCommandOptionServerName.ScrollSwipe
      : keyName === 'optionsDto.actionValue' &&
        mobileTestStep.value === MobileCommandOptionServerName.ScrollSwipe;

  const watchStartX = watch('startX');
  const watchStartY = watch('startY');
  const watchEndX = watch('endX');
  const watchEndY = watch('endY');

  useEffect(() => {
    if (isScrollAndSwipe) {
      setValue('startX', startX);
      setValue('startY', startY);
      setValue('endX', endX);
      setValue('endY', endY);
    } else {
      setValue('startX', Math.round(imageWidth / 2).toString());
      setValue('startY', (Math.round(imageHeight * 2) / 3).toString());
      setValue('endX', Math.round(imageWidth / 2).toString());
      setValue('endY', Math.round(imageHeight / 3).toString());
    }
  }, [
    setValue,
    isScrollAndSwipe,
    startX,
    startY,
    endX,
    endY,
    imageWidth,
    imageHeight,
  ]);

  useEffect(() => {
    if (!watchStartX && !watchStartY && !watchEndX && !watchEndY) {
      trigger(['startX', 'startY', 'endX', 'endY']);
    }
  }, [trigger, watchStartX, watchStartY, watchEndX, watchEndY]);

  useEffect(() => {
    setValue(
      keyName,
      `${watchStartX},${watchStartY},${watchEndX},${watchEndY}`,
    );
  }, [setValue, watchStartX, watchStartY, watchEndX, watchEndY]);

  const coordinateFields: ScrollAndSwipeInputOptions[] = [
    { labelTitle: 'StartX', max: imageWidth, name: 'startX' },
    { labelTitle: 'StartY', max: imageHeight, name: 'startY' },
    { labelTitle: 'EndX', max: imageWidth, name: 'endX' },
    { labelTitle: 'EndY', max: imageHeight, name: 'endY' },
  ];

  return (
    <div className="items-center sm:flex sm:px-6 sm:py-4">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        Value*
      </dt>
      <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        <div className="text-sm">
          <div className="mb-4 grid w-full grid-cols-4 gap-4 text-center">
            <p>Width</p>
            <p>{mobileScreenWidth}</p>
            <p>Height</p>
            <p>{mobileScreenHeight}</p>
          </div>
          <div className="grid w-full grid-cols-4 gap-4 text-center">
            {coordinateFields.map(({ labelTitle, max, name }) => (
              <div className="flex flex-col items-center" key={labelTitle}>
                <label htmlFor={labelTitle}>{labelTitle}</label>
                <input
                  type="text"
                  id={labelTitle}
                  className="mt-2 w-full rounded-md border border-solid border-gray-300 px-3 py-1 text-center text-sm"
                  {...register(name, {
                    onChange: (e) =>
                      onOnlyNumberChangeHandler(e, name, setValue),
                    required: '좌표 값을 모두 입력해주세요.',
                    max: {
                      value: max,
                      message:
                        '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요. ',
                    },
                  })}
                />
              </div>
            ))}
          </div>
          <ErrorMessage
            name={
              errors.startX
                ? 'startX'
                : errors.startY
                  ? 'startY'
                  : errors.endX
                    ? 'endX'
                    : errors.endY && 'endY'
            }
            errors={errors}
            render={({ message }) => (
              <p className="standard-error-message">{message}</p>
            )}
          />
        </div>
      </dd>
    </div>
  );
};

export default TestStepDetailScrollAndSwipe;
