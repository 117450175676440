import { ConnectionType, DeviceInfo } from '@customTypes/ide/device/device';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  WifiIcon,
} from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { UseQueryResult } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { LiaUsb } from 'react-icons/lia';

type Props = {
  deviceInfoDto: DeviceInfo;
  handleChange: (selectedDevice: DeviceInfo) => void;
  deviceListQuery: UseQueryResult<DeviceInfo[], Error>;
  disabled?: boolean;
};

const SpeedSchedulerDeviceListbox = ({
  deviceInfoDto,
  handleChange,
  deviceListQuery,
  disabled = false,
}: Props) => {
  const deviceList = deviceListQuery.data;

  const disabledOption =
    deviceListQuery.isLoading || deviceListQuery.isFetching || disabled;

  return (
    <div className="flex items-center gap-4">
      <div className="w-full">
        <Listbox
          value={deviceInfoDto || null}
          onChange={handleChange}
          disabled={disabledOption}
        >
          <div className="relative">
            <ListboxButton
              className={clsx(
                'relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
                disabledOption ? 'bg-gray-100' : 'bg-white',
              )}
            >
              <span className="block truncate">
                {deviceInfoDto
                  ? deviceInfoDto?.serialNumber === null
                    ? deviceInfoDto?.model
                    : `${deviceInfoDto?.model} - ${deviceInfoDto?.serialNumber}`
                  : 'Select a test device'}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                {deviceList?.length === 0 ? (
                  <div className="mx-2 my-1 flex items-center justify-start">
                    연결된 디바이스가 없습니다.
                  </div>
                ) : (
                  deviceList?.map((device) => (
                    <ListboxOption
                      key={device.serialNumber}
                      className="group relative flex cursor-default select-none items-center gap-2.5 py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[disabled]:opacity-50"
                      value={device}
                      disabled={
                        !device.enabled || !device.model || !device.serialNumber
                      }
                    >
                      {device.connectionType === ConnectionType.USB ? (
                        <LiaUsb size="24" />
                      ) : (
                        <WifiIcon className="size-6" />
                      )}
                      <span className="block truncate font-normal group-data-[selected]:font-semibold">
                        {device.model} - {device.serialNumber}
                      </span>
                      <span className="invisible absolute inset-y-0 right-0 flex items-center pr-4 group-data-[selected]:visible">
                        <CheckIcon className="size-5" aria-hidden="true" />
                      </span>
                    </ListboxOption>
                  ))
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </Listbox>
      </div>

      <div
        className={clsx(
          'size-9 rounded border border-solid border-gray-300 px-2 py-1',
          disabledOption
            ? 'cursor-default bg-gray-100'
            : 'cursor-pointer hover:bg-gray-50',
        )}
      >
        <ArrowPathIcon
          className={clsx('size-full', {
            'animate-spin':
              deviceListQuery.isLoading || deviceListQuery.isFetching,
          })}
          onClick={() => {
            if (!disabledOption) {
              deviceListQuery.refetch();
            }
          }}
        />
      </div>
    </div>
  );
};

export default SpeedSchedulerDeviceListbox;
