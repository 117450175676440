import React, { Fragment, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';
import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import {
  browserKeyList,
  BrowserKeyName,
  findBrowserKeyNameFromServerName,
} from '@utils/static/browserKeyList';
import {
  findKeyboardEventNameFromServerName,
  keyboardEventList,
  KeyboardEventServerName,
} from '@utils/static/keyboardEventList';

import { WebTestStepData } from '@customTypes/testStep/type';

interface Props {
  command: string;
}

const TestStepSelectBox = ({ command }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = Number(searchParams.get('idx'));

  const { control, watch, setValue } = useFormContext<WebTestStepData>();

  const value = watch('value');

  const webTestCaseList = useWebTestCaseListQuery({
    enabled: command === WebCommandOptionServerName.ImportTestCase,
  });

  const webTestCaseListData = webTestCaseList.data?.filter(
    (webTestCase) => webTestCase?.idx !== idx,
  );
  // 초기 string인 value를 index로 변환
  useEffect(() => {
    if (
      command === WebCommandOptionServerName.ImportTestCase &&
      webTestCaseListData?.some((webTestCase) => webTestCase?.title === value)
    ) {
      setValue(
        'value',
        webTestCaseListData
          ?.find((webTestCase) => webTestCase?.title === value)
          ?.idx.toString(),
      );
    }
  }, [webTestCaseList]);

  return (
    <div className="items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-5">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        Value*
      </dt>
      <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        <Controller
          name="value"
          control={control}
          render={({ field }) => (
            <Listbox as="div" value={field.value} onChange={field.onChange}>
              {({ open }) => (
                <>
                  <div className="relative">
                    <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pb-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <span className="block truncate">
                        {command === WebCommandOptionServerName.BrowserKey
                          ? findBrowserKeyNameFromServerName(
                              field.value as BrowserKeyName,
                            )
                          : command === WebCommandOptionServerName.KeyBoardEvent
                            ? findKeyboardEventNameFromServerName(
                                field.value as KeyboardEventServerName,
                              )
                            : webTestCaseListData?.find(
                                (webTestCase) =>
                                  webTestCase?.idx === Number(field.value),
                              )?.title}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="size-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <ListboxOptions className="absolute z-10 mt-1 max-h-96 w-full overflow-y-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                        {command === WebCommandOptionServerName.BrowserKey
                          ? browserKeyList.map((browserkey) => (
                              <ListboxOption
                                key={browserkey.id}
                                className={({ focus }) =>
                                  clsx(
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                    focus
                                      ? 'bg-indigo-600 text-white'
                                      : 'text-gray-900',
                                  )
                                }
                                value={browserkey.serverName}
                              >
                                {({ selected, focus }) => (
                                  <>
                                    <span
                                      className={`block break-all ${
                                        selected ? 'font-medium' : 'font-normal'
                                      }`}
                                    >
                                      {browserkey.name}
                                    </span>
                                    {selected && (
                                      <span
                                        className={clsx(
                                          'absolute inset-y-0 right-0 flex items-center pr-4',
                                          focus
                                            ? 'text-white'
                                            : 'text-indigo-600',
                                        )}
                                      >
                                        <CheckIcon
                                          className="size-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </>
                                )}
                              </ListboxOption>
                            ))
                          : command === WebCommandOptionServerName.KeyBoardEvent
                            ? keyboardEventList.map((keyboardKey) => (
                                <ListboxOption
                                  key={keyboardKey.id}
                                  value={keyboardKey.serverName}
                                  className={({ focus }) =>
                                    clsx(
                                      'relative cursor-default select-none py-2 pl-3 pr-9',
                                      focus
                                        ? 'bg-indigo-600 text-white'
                                        : 'text-gray-900',
                                    )
                                  }
                                >
                                  {({ selected, focus }) => (
                                    <>
                                      <span
                                        className={`block break-all ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {keyboardKey.name}
                                      </span>
                                      {selected && (
                                        <span
                                          className={clsx(
                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                            focus
                                              ? 'text-white'
                                              : 'text-indigo-600',
                                          )}
                                        >
                                          <CheckIcon
                                            className="size-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </ListboxOption>
                              ))
                            : webTestCaseListData?.map((webTestCase) => (
                                <ListboxOption
                                  key={webTestCase?.idx}
                                  className={({ focus }) =>
                                    `relative cursor-default select-none py-2 pl-3 pr-9 ${
                                      focus
                                        ? 'bg-indigo-600 text-white'
                                        : 'text-gray-900'
                                    }`
                                  }
                                  value={webTestCase?.idx}
                                >
                                  {({ selected, focus }) => (
                                    <>
                                      <span
                                        className={`block break-all ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {webTestCase?.title}
                                      </span>
                                      {selected && (
                                        <span
                                          className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                            focus
                                              ? 'text-white'
                                              : 'text-indigo-600'
                                          }`}
                                        >
                                          <CheckIcon
                                            className="size-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </ListboxOption>
                              ))}
                      </ListboxOptions>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          )}
        />
      </dd>
    </div>
  );
};

export default TestStepSelectBox;
