import React from 'react';
import { useParams, Link } from 'react-router-dom';

import {
  OverviewFunctionalResultType,
  TableBodyBasic,
  TableBodyBasicNoData,
  TableHeader,
  TableRow,
} from '@autosquare/common';

type Props = {
  webFunctionalResults: OverviewFunctionalResultType[];
  tableHeaders: string[];
};

const WebRecentTestResult = ({ webFunctionalResults, tableHeaders }: Props) => {
  const { projectIdx } = useParams();

  return (
    <div className="mt-8 flow-root">
      <div className="pb-3">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-semibold leading-6 text-gray-900">Web</p>
          <svg
            className="h-5 w-5 flex-shrink-0 text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <p className="truncate text-sm text-gray-500">
            최근 테스트 결과 목록
          </p>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            {tableHeaders?.map((tableHeader) => (
              <TableHeader key={tableHeader}>{tableHeader}</TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {webFunctionalResults?.length === 0 ? (
            <TableBodyBasicNoData colSpan={tableHeaders?.length}>
              진행된 테스트 결과가 없습니다.
            </TableBodyBasicNoData>
          ) : (
            webFunctionalResults?.map((webFunctionalResult, idx) => (
              <TableRow key={webFunctionalResult.idx}>
                <TableBodyBasic>{idx + 1}</TableBodyBasic>
                <TableBodyBasic>
                  {webFunctionalResult.scenarioName}
                </TableBodyBasic>
                <TableBodyBasic>{webFunctionalResult.startTime}</TableBodyBasic>
                <TableBodyBasic>{webFunctionalResult.result}</TableBodyBasic>
                <TableBodyBasic>
                  {webFunctionalResult.result !== 'Running' && (
                    <Link
                      to={`/projects/${projectIdx}/web/test-result/detail?idx=${webFunctionalResult.idx}`}
                    >
                      <button
                        type="button"
                        className="rounded-md shadow-sm hover:text-congress-blue"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                          />
                        </svg>
                      </button>
                    </Link>
                  )}
                </TableBodyBasic>
              </TableRow>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default WebRecentTestResult;
