import {
  Breadcrumbs,
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingButtonOnClick,
} from '@autosquare/common';
import {
  CreateWebSpeedTestScenarioData,
  CreateWebSpeedTestScenarioDataSchema,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateSpeedWebTestScenarioMutation } from '@lib/api/ide/speedWeb/webSpeedTestScenario';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import WebSpeedTestScenarioDndSpace from './components/WebSpeedTestScenarioDndSpace';
import WebSpeedTestScenarioInformation from './components/WebSpeedTestScenarioInformation';

const CreateWebSpeedTestScenario = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const methods = useForm<CreateWebSpeedTestScenarioData>({
    resolver: zodResolver(CreateWebSpeedTestScenarioDataSchema),
  });

  const createSpeedWebTestScenarioMutation =
    useCreateSpeedWebTestScenarioMutation();

  const overviewPath = `${pathname.split('/').slice(0, -2).join('/')}/overview`;
  const listPath = pathname.split('/').slice(0, -1).join('/');

  const onSubmit: SubmitHandler<CreateWebSpeedTestScenarioData> = (data) => {
    createSpeedWebTestScenarioMutation.mutate(data, {
      onSuccess: () => {
        navigate(listPath);
      },
    });
  };

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Speed Test', href: overviewPath },
    { title: 'Web', href: listPath },
    { title: 'Test Scenario', href: listPath },
    { title: 'Create', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Save',
      type: 'submit',
      variant: 'primary',
    },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PagingHeadingButtonOnClick
          breadcrumbs={breadcrumbs}
          pageTitle={'Test Scenario Create'}
          pageHeadingButtons={pageHeadingButtons}
        />
        {createSpeedWebTestScenarioMutation.isError && (
          <ErrorMessage>
            {createSpeedWebTestScenarioMutation.error.message}
          </ErrorMessage>
        )}
        <WebSpeedTestScenarioInformation />
        <WebSpeedTestScenarioDndSpace />
      </form>
    </FormProvider>
  );
};

export default CreateWebSpeedTestScenario;
