import React from 'react';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useMobileTestScenarioQuery } from '@lib/api/ide/mobile/mobileTestScenario';
import { useWebTestScenarioQuery } from '@lib/api/ide/web/webTestScenario';

import DraggableElement from './CreateSchedulerScenario/DraggableElement';

import {
  CreateSchedulerData,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import { useFormContext } from 'react-hook-form';

const CreateSchedulerScenario = () => {
  const { projectIdx } = useParams();
  const { watch } = useFormContext<CreateSchedulerData>();
  const testType = watch('type');
  const testScenarioQuery =
    testType === SchedulerTypeServerName.Mobile
      ? useMobileTestScenarioQuery(projectIdx, { staleTime: 20 })
      : useWebTestScenarioQuery({ idx: projectIdx, staleTime: 20 });

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
      <label
        htmlFor="scenario"
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
      >
        Scenario
      </label>
      <div className="mt-2 text-sm sm:col-span-2 sm:mt-0">
        <DndProvider backend={HTML5Backend}>
          <DraggableElement testScenarioQuery={testScenarioQuery} />
        </DndProvider>
      </div>
    </div>
  );
};

export default CreateSchedulerScenario;
