import { z } from 'zod';
import { SupportedBrowsers } from '../browser/browser';

export interface CreateSessionIdResponse {
  sessionId: string;
  value: unknown;
}

export enum DeviceInfoOs {
  Aos = 'aos',
  Ios = 'ios',
}

export enum DeviceInfoOsFullName {
  Aos = 'Android',
  Ios = 'iOS',
}

export enum ConnectionType {
  USB = 'usb',
  Wireless = 'wireless',
  TCP = 'tcp',
}

export const DeviceInfoSchema = z.object({
  udid: z.string().optional(),
  model: z.string().optional(),
  os: z.nativeEnum(DeviceInfoOs).optional(),
  serialNumber: z.string().optional(),
  connectionType: z.nativeEnum(ConnectionType).optional(),
  enabled: z.boolean().optional(),
  installedBrowserList: z.array(z.nativeEnum(SupportedBrowsers)).optional(),
  browser: z.string().nullable().optional(),
});

export interface DeviceInfo {
  udid: string;
  model: string;
  os: DeviceInfoOs;
  serialNumber: string;
  connectionType: ConnectionType;
  enabled?: boolean;
  installedBrowserList?: SupportedBrowsers[];
  browser?: string;
}

export interface WifiConnectRequest {
  pairingIpAddressAndPort: string;
  deviceIpAddressAndPort: string;
  pairingCode: string;
}

export interface TcpConnectionRequest {
  serialNumber: string;
  ipAddress: string;
}

export enum TestExecutionTestType {
  Manual = 'manual',
  Monitoring = 'monitoring',
  Unit = 'unit',
  Command = 'command',
  Unit_speed = 'unit_speed',
  Command_speed = 'command_speed',
}

export interface DistributionTestExecutionTestInfo {
  testInfo: DistributionTestExecutionTestData[];
}

export interface DistributionTestExecutionTestData
  extends BaseDistributionTestExecutionTestData {
  scheduleIdx?: number;
  repeatCount?: number;
}

export interface BaseDistributionTestExecutionTestData {
  testType: TestExecutionTestType;
  idx: number;
  device: DeviceInfo;
  projectIdx: number;
}
