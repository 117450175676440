import { Button, Checkbox } from '@autosquare/common';
import React from 'react';

type Props = {
  selectedArray: number[];
  selectAllArray: (e: React.ChangeEvent<HTMLInputElement>) => void;
  openDialog: () => void;
};

const OptionsDtoTableHeader = ({
  selectedArray,
  selectAllArray,
  openDialog,
}: Props) => {
  const tableHeaders = [
    {
      idx: 1,
      header: (
        <Checkbox
          checkboxSize="sm"
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
        />
      ),
    },
    {
      idx: 2,
      header:
        selectedArray.length > 0 ? (
          <div className={'flex h-full items-center'}>
            <Button
              type="button"
              variant="secondary"
              buttonSize="xs"
              onClick={openDialog}
            >
              Delete all
            </Button>
          </div>
        ) : (
          'Key Name*'
        ),
    },
    { idx: 3, header: 'From' },
    { idx: 4, header: 'Value*' },
    { idx: 5, header: '' },
    { idx: 6, header: 'Date Format*' },
  ];

  return tableHeaders;
};

export default OptionsDtoTableHeader;
