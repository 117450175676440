import { Transition } from '@headlessui/react';
import React from 'react';

interface Props {
  isTestStepListOpened: boolean;
  setIsTestStepListOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isTooltipOpened: boolean;
  setIsTooltipOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const LandScapeTestStepListButton = ({
  isTestStepListOpened,
  setIsTestStepListOpened,
  isTooltipOpened,
  setIsTooltipOpened,
}: Props) => {
  return (
    <Transition
      appear={true}
      show={!isTestStepListOpened}
      enter="transition-opacity duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="absolute right-0 flex gap-1">
        {isTooltipOpened && (
          <div className="flex items-center justify-center rounded-md bg-gray-900 px-3 py-1.5 text-white">
            Test Step List 열림
          </div>
        )}
        <button
          type="button"
          className="right-0 flex h-9 w-7 items-center justify-center rounded-l-md bg-gray-900 text-white"
          onMouseEnter={() => setIsTooltipOpened(true)}
          onMouseLeave={() => setIsTooltipOpened(false)}
          onClick={() => {
            setIsTestStepListOpened(!isTestStepListOpened);
            setIsTooltipOpened(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
            />
          </svg>
        </button>
      </div>
    </Transition>
  );
};

export default LandScapeTestStepListButton;
