import React from 'react';

const NoProjectList = () => {
  return (
    <>
      <div className="mx-auto h-screen max-w-full sm:px-0 lg:px-0">
        <div className="mx-auto flex h-screen max-w-full px-4 pt-8">
          <div className="m-auto text-center">
            <svg
              className="mx-auto size-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              프로젝트가 없습니다.
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              대시보드 웹 사이트에서 새로운 프로젝트를 생성하여 시작할 수
              있습니다.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoProjectList;
