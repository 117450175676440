import { ScenarioListDetailData } from '@autosquare/common';

import React from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../../../app/store';

interface Props {
  copyTestScenarios: ScenarioListDetailData[];
  title: string;
  step: number[] | null;
  idx: number;
  description: string;
  caseCount: number;
  createdAt: string;
  modifiedAt: string;
  changeCardList: (list: ScenarioListDetailData[]) => void;
}

let id = 1;

const DetailListItemCard = ({
  copyTestScenarios,
  title,
  step,
  idx,
  caseCount,
  description,
  createdAt,
  modifiedAt,
  changeCardList,
}: Props) => {
  const hasSchedulerRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const box = {
    title,
    description,
    step,
    caseCount,
    idx,
  };

  const [, drag] = useDrag({
    type: 'card',
    canDrag: hasSchedulerRunning,
    item() {
      const useless = copyTestScenarios.find(
        (copyTestCase) => copyTestCase.idx === -1,
      );
      if (!useless) {
        changeCardList([
          ...copyTestScenarios,
          {
            title: 'Move',
            idx: idx,
            description: description,
            step: step,
            caseCount: caseCount,
            createdAt: createdAt,
            modifiedAt: modifiedAt,
            dndIndex: -1,
          },
        ]);
      }
      return box;
    },
    end(_: unknown, monitor: DragSourceMonitor) {
      const uselessIndex = copyTestScenarios.findIndex(
        (copyTestCase) => copyTestCase.dndIndex === -1,
      );

      if (monitor.didDrop()) {
        copyTestScenarios.splice(uselessIndex, 1, {
          ...monitor.getItem(),
          dndIndex: id++,
        });
      } else {
        copyTestScenarios.splice(uselessIndex, 1);
      }
      changeCardList(copyTestScenarios);
    },
  });

  return (
    <div
      ref={drag}
      className={`flex items-center m-2 px-3 py-2 bg-gray-50 border border-solid border-border-line ${
        hasSchedulerRunning && 'cursor-move'
      }`}
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 9h16.5m-16.5 6.75h16.5"
          />
        </svg>
      </div>
      <div className="ml-7">{title}</div>
    </div>
  );
};

export default DetailListItemCard;
