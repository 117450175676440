import React from 'react';
import { LiaUsb } from 'react-icons/lia';

const DeviceSkeleton = () => {
  return (
    <div className="flex w-full animate-pulse flex-col gap-4 rounded-md border border-solid border-gray-200 p-2.5">
      <div className="flex gap-2.5">
        <div className="flex-center">
          <LiaUsb size="1.5rem" />
        </div>
        <div className="flex flex-col gap-1">
          <div className="h-5 w-24 rounded bg-gray-200 text-sm font-normal leading-tight text-black dark:bg-gray-700" />
          <div className="h-5 w-32 rounded bg-gray-200 text-sm font-normal leading-tight text-gray-500 dark:bg-gray-700" />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2.5">
        <div className="w-full">
          <div className="h-8 w-full rounded bg-gray-200 dark:bg-gray-700" />
        </div>
        <div>
          <div className="size-8 rounded bg-gray-200 dark:bg-gray-700" />
        </div>
      </div>
    </div>
  );
};

export default DeviceSkeleton;
