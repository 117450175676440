import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import axios, { AxiosError } from 'axios';

import { axiosLocalInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { mobileDeviceKeys } from '@queries/ide/mobile/mobileDeviceKeys';
import { getSessionId } from '@store/ide/device/sessionIdSlice';

import {
  CreateSessionIdResponse,
  DeviceInfo,
  TcpConnectionRequest,
  WifiConnectRequest,
} from '@customTypes/ide/device/device';
import { InstallMandatoryApp } from '@customTypes/setting/type';
import { ServerErrorData } from '@customTypes/type';
import { MobileScreenshotPageSource } from '@customTypes/testStep/type';
import { SmsData } from '@customTypes/ide/device/sms';

// GET
const deviceList = async (): Promise<DeviceInfo[]> => {
  const response = await axiosLocalInstance.get<DeviceInfo[]>(
    IDEAPI.GET_DEVICE_LIST,
  );
  return response.data;
};
export const useDeviceListQuery = (
  options?: UseQueryOptions<DeviceInfo[], Error>,
) =>
  useQuery<DeviceInfo[], Error>({
    queryKey: mobileDeviceKeys.lists(),
    queryFn: () => deviceList(),
    ...options,
  });

const mobileScreenshot = async (): Promise<string> => {
  const response = await axiosLocalInstance.get<string>(IDEAPI.TAKE_SCREENSHOT);
  return response.data;
};
export const useMobileScreenshotQuery = () => {
  return useQuery<string, Error>({
    queryKey: mobileDeviceKeys.screenshot(),
    queryFn: () => mobileScreenshot(),
  });
};

const mobileSource = async (): Promise<MobileScreenshotPageSource> => {
  const response = await axiosLocalInstance.get<MobileScreenshotPageSource>(
    IDEAPI.GET_PAGE_SOURCE,
  );
  return response.data;
};
export const useMobileSourceQuery = () => {
  return useQuery<MobileScreenshotPageSource, Error>({
    queryKey: mobileDeviceKeys.source(),
    queryFn: () => mobileSource(),
  });
};

const mobileAppList = async (udid: string): Promise<string[]> => {
  const response = await axiosLocalInstance.get<string[]>(
    IDEAPI.GET_MOBILE_APP_LIST + udid,
  );
  return response.data;
};
export const useMobileAppListQuery = (udid: string) => {
  return useQuery<string[], Error>({
    queryKey: mobileDeviceKeys.appList(),
    queryFn: () => mobileAppList(udid),
  });
};

const getSmsList = async (): Promise<SmsData[]> => {
  const response = await axiosLocalInstance.get<SmsData[]>(IDEAPI.GET_SMS_LIST);
  return response.data;
};
export const useMobileSmsListQuery = (
  options?: UseQueryOptions<SmsData[], Error>,
) => {
  return useQuery<SmsData[], Error>({
    queryKey: mobileDeviceKeys.smsList(),
    queryFn: () => getSmsList(),
    ...options,
  });
};

// post
const createSessionId = async (
  projectIdx: string,
  cookie: string,
  deviceInfo: DeviceInfo,
): Promise<CreateSessionIdResponse> => {
  const response = await axios.post(
    IDEAPI.CREATE_SESSION_ID + projectIdx,
    deviceInfo,
    {
      headers: { 'Token-Value': cookie },
      timeout: 5 * 60 * 1000,
    },
  );
  return response.data?.data;
};
export const useCreateSessionIdMutation = (
  projectIdx: string,
  cookie: string,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation<
    CreateSessionIdResponse,
    AxiosError<ServerErrorData>,
    DeviceInfo
  >({
    mutationFn: (data) => createSessionId(projectIdx, cookie, data),
    onSuccess: (response) => {
      dispatch(getSessionId(response.sessionId));
      queryClient.invalidateQueries(mobileDeviceKeys.all);
    },
  });
};

const mobileDeleteDriver = async (): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.DELETE_MOBILE_DRIVE,
  );
  return response.data;
};
export const useMobileDeleteDriverMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error>({
    mutationFn: () => mobileDeleteDriver(),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileDeviceKeys.all);
    },
  });
};

const installMandatoryApp = async (
  cookie: string,
  data: InstallMandatoryApp,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.INSTALL_MANDATORY_APP_IN_IOS,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useInstallMandatoryAppMutation = (cookie: string) => {
  return useMutation<unknown, Error, InstallMandatoryApp>({
    mutationFn: (data) => installMandatoryApp(cookie, data),
  });
};

const settingWifiWirelessDevice = async (
  data: WifiConnectRequest,
): Promise<WifiConnectRequest> => {
  const response = await axiosLocalInstance.post<WifiConnectRequest>(
    `${IDEAPI.SETTING_WIRELESS_DEVICE}/wifi`,
    data,
  );
  return response.data;
};
export const useSettingWifiWirelessDeviceMutation = () => {
  return useMutation<WifiConnectRequest, Error, WifiConnectRequest>({
    mutationFn: (data) => settingWifiWirelessDevice(data),
  });
};

const settingTcpWirelessDevice = async (
  data: TcpConnectionRequest,
): Promise<TcpConnectionRequest> => {
  const response = await axiosLocalInstance.post<TcpConnectionRequest>(
    `${IDEAPI.SETTING_WIRELESS_DEVICE}/tcp`,
    data,
  );
  return response.data;
};
export const useSettingTcpWirelessDeviceMutation = () => {
  return useMutation<TcpConnectionRequest, Error, TcpConnectionRequest>({
    mutationFn: (data) => settingTcpWirelessDevice(data),
  });
};
