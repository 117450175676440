import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DraggableElement from './DraggableElement';

const DndSpace = () => {
  return (
    <div className="flex w-full text-sm">
      <DndProvider backend={HTML5Backend}>
        <DraggableElement />
      </DndProvider>
    </div>
  );
};

export default DndSpace;
