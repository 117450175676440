import { days } from '@utils/static/selectedDays';
import React from 'react';

interface Props {
  selectedDays: string[];
  setSelectedDays: React.Dispatch<React.SetStateAction<string[]>>;
}

const CreateSchedulerDays = ({ selectedDays, setSelectedDays }: Props) => {
  const handleDaysSelector = (day: string, isChecked: boolean) => {
    setSelectedDays((prev: string[]) => {
      if (isChecked) {
        return [...prev, day];
      } else {
        return prev.filter((select: string) => select !== day);
      }
    });
  };

  return (
    <div className="mt-2 sm:col-span-5 sm:mt-0">
      <div>
        <label
          htmlFor="days"
          className="mb-2 block text-sm font-medium leading-6 text-gray-900"
        >
          Days
        </label>
      </div>
      <div className="mr-3 mt-4 flex flex-wrap gap-x-3 gap-y-2">
        {days.map((day) => (
          <React.Fragment key={day}>
            <div className="flex items-center">
              <input
                id={`day-${day}`}
                name="selDays"
                type="checkbox"
                className="input-checkbox-congress-blue border-gray-30 size-4 cursor-pointer rounded border border-solid"
                checked={selectedDays.includes(day)}
                onChange={(e) => handleDaysSelector(day, e.target.checked)}
              />
            </div>
            <div className="text-sm leading-6">
              <label
                htmlFor={`day-${day}`}
                className="cursor-pointer font-medium text-gray-900"
              >
                {day}
              </label>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CreateSchedulerDays;
