import React from "react";
import { Link, useLocation } from "react-router-dom";

import { SidebarList } from "@customTypes/type";

type Props = {
  sidebarList: SidebarList;
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const NoChildrenSidebarList = ({ sidebarList, setIsToggledSidebar }: Props) => {
  const location = useLocation();

  return (
    <Link to={sidebarList.href} onClick={() => setIsToggledSidebar(false)}>
      <div
        className={`block rounded-md py-2 pr-2 pl-10 text-sm leading-6 font-semibold text-gray-700 cursor-pointer ${
          location.pathname.includes(sidebarList.hrefBasic)
            ? "bg-gray-50"
            : "hover:bg-gray-50"
        }`}
      >
        {sidebarList.name}
      </div>
    </Link>
  );
};

export default NoChildrenSidebarList;
