import React from "react";
import { Outlet } from "react-router-dom";

import Nav from "@components/Nav/Nav";

import { CopyRight } from "@autosquare/common";

const NavRoot = () => {
  return (
    <div className="mx-auto max-w-full sm:px-0 lg:px-0 min-h-screen flex flex-col">
      <Nav />
      <main className="mt-16 mb-auto px-4 pt-8 max-w-full min-h-full flex flex-col">
        <Outlet />
      </main>
      <CopyRight />
    </div>
  );
};

export default NavRoot;
