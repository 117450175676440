import React from 'react';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';

import AddWirelessDeviceButton from '@components/AddWirelessDeviceButton/AddWirelessDeviceButton';
import { webDriverAgentControllerKeys } from '@queries/ide/webDriverAgentControllerKeys';

type Props = {
  deviceListQuery: UseQueryResult<unknown, unknown>;
  setIsElectronErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};

const DeviceListHeader = ({
  deviceListQuery,
  setIsElectronErrorMessage,
}: Props) => {
  const queryClient = useQueryClient();

  const refreshButtonClick = () => {
    setIsElectronErrorMessage('');
    queryClient.resetQueries(webDriverAgentControllerKeys.downloads());
    deviceListQuery.refetch();
  };

  return (
    <>
      <div className="mt-8 flex items-center justify-between">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          Select Devices
        </h3>
        <div className="flex items-center gap-4">
          <AddWirelessDeviceButton refresh={refreshButtonClick} />
          <button
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-black px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-congress-blue"
            onClick={refreshButtonClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5"
            >
              <path
                fillRule="evenodd"
                d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                clipRule="evenodd"
              />
            </svg>
            Refresh
          </button>
        </div>
      </div>
    </>
  );
};

export default DeviceListHeader;
