import { DeviceInfoSchema } from '@customTypes/ide/device/device';
import { BrowserInfoSchema } from '@customTypes/ide/browser/browser';
import { z } from 'zod';
import { MailReceiverSchema } from '../speedScheduler/speedScheduler';

export enum SchedulerTypeName {
  Mobile = 'Mobile',
  Web = 'Web',
}

export enum SchedulerTypeServerName {
  Mobile = 'mobile app',
  Web = 'web',
}

export const BaseSchedulerSchema = z.object({
  title: z.string(),
  type: z.nativeEnum(SchedulerTypeServerName),
  start_time: z.string(),
  auto_register: z.boolean(),
  mail_receivers: z.array(MailReceiverSchema),
});

export const SchedulerDetailSchema = BaseSchedulerSchema.extend({
  idx: z.number(),
  cycle: z.string(),
  running: z.boolean(),
  environment: z.string(),
  project_idx: z.number(),
  scenario_idx_list: z.array(z.number()),
  creator: z.string(),
  owner_uuid: z.string(),
});

export const BaseLocalSchedulerSchema = z.object({
  title: z
    .string()
    .min(1, { message: 'Title을 입력해 주세요.' })
    .max(50, { message: '50자 이하 입력해 주세요.' }),
  scenario_idx_list: z.array(z.number()),
  start_time: z.string(),
  auto_register: z.boolean(),
  mail_receivers: z.array(MailReceiverSchema).optional(),
});

export const CreateSchedulerSchema = BaseLocalSchedulerSchema.extend({
  type: z.nativeEnum(SchedulerTypeServerName),
  project_idx: z.number(),
  device_info_dto: DeviceInfoSchema.optional(),
  browser_info_dto: BrowserInfoSchema.optional(),
  cron_cycle: z.string(),
});

const InfoSchema = DeviceInfoSchema.merge(BrowserInfoSchema).optional();

export const UpdateSchedulerSchema = BaseLocalSchedulerSchema.extend({
  schedule_idx: z.number(),
  infos: InfoSchema,
  cron_cycle: z.string(),
});
export const DeleteIndexListSchema = z.object({
  idxList: z.array(z.number()),
});
export type SchedulerDetailData = z.infer<typeof SchedulerDetailSchema>;
export type CreateSchedulerData = z.infer<typeof CreateSchedulerSchema>;
export type UpdateSchedulerData = z.infer<typeof UpdateSchedulerSchema>;
export type DeleteSchedulerData = z.infer<typeof DeleteIndexListSchema>;

export interface RunningCycle {
  idx: number;
  title: string;
}
