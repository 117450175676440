import React, { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  useAdminUserListQuery,
  useAdminProjectDetailQuery,
} from '@lib/api/dashboard/admin/admin';

import AdminProjectDetailForm from './components/AdminProjectDetailForm';

import {
  ErrorMessage,
  ListSkeleton,
  PageHeadingButtons,
  PagingHeadings,
} from '@autosquare/common';

const AdminProjectDetail = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const adminUserListQuery = useAdminUserListQuery();
  const adminProjectDetailQuery = useAdminProjectDetailQuery(idx);

  const adminProjectData = adminProjectDetailQuery.data;
  const adminUserListData = adminUserListQuery.data?.filter(
    (adminUser) => adminUser.status === 'Activate',
  );

  const [isEdited, setIsEdited] = useState(false);

  const breadcrumbs = [
    {
      title: 'Administration',
      href: `${location.pathname.split('/').slice(0, -1).join('/')}/list`,
    },
    {
      title: 'Projects',
      href: `${location.pathname.split('/').slice(0, -1).join('/')}/list`,
    },
    { title: isEdited ? 'Edit' : 'Details', href: location.search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Edit',
      onClick: () => setIsEdited(true),
      type: 'button',
      variant: 'secondary',
    },
  ];

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={isEdited ? 'Project Edit' : 'Project Details'}
        pageHeadingButtons={isEdited ? null : pageHeadingButtons}
      />
      {adminProjectDetailQuery.isLoading || adminUserListQuery.isLoading ? (
        <ListSkeleton margin="my-8" />
      ) : adminProjectDetailQuery.isError || adminUserListQuery.isError ? (
        <ErrorMessage>
          {adminProjectDetailQuery.error.message ||
            adminUserListQuery.error.message}
        </ErrorMessage>
      ) : (
        <AdminProjectDetailForm
          idx={idx}
          isEdited={isEdited}
          setIsEdited={setIsEdited}
          adminProjectData={adminProjectData}
          adminUserListData={adminUserListData}
        />
      )}
    </>
  );
};

export default AdminProjectDetail;
