import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';

import React, { ReactElement, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDeleteWebTestStepMutation } from '@lib/api/ide/web/webTestStep';

import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';

import { WebTestStepData } from '@customTypes/testStep/type';
import {
  findWebCommandNameFromServerName,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';
import IfDetailBasic from './TestStepDetailBasic/IfDetailBasic';

type Props = {
  webTestStepData: WebTestStepData;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  testStepDetailListValue: string | ReactElement;
};

const TestStepDetailBasic = ({
  webTestStepData,
  setIsEdited,
  closeDialog,
  testStepDetailListValue,
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const idxList = [webTestStepData.idx];

  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const deleteTestStepMutation = useDeleteWebTestStepMutation({
    caseIdx: idx,
    idxList: idxList,
  });
  return (
    <>
      <div className="shrink-0 border-b border-gray-200 px-4 py-5 sm:px-6">
        <div className="flex justify-end space-x-3">
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsEdited(true)}
          >
            Edit
          </Button>
          <Button type="button" variant="caution" onClick={openModal}>
            Delete
          </Button>
        </div>
      </div>
      <div className="divide-y divide-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="space-y-8 sm:space-y-0">
            <TestStepDetailBasicDefinition
              term={'Command'}
              description={findWebCommandNameFromServerName(
                webTestStepData?.command,
              )}
            />
            {webTestStepData?.command !== WebCommandOptionServerName.IF && (
              <TestStepDetailBasicDefinition
                term={'Value*'}
                description={testStepDetailListValue}
              />
            )}
            {webTestStepData?.command ===
              WebCommandOptionServerName.KeyBoardEvent && (
              <TestStepDetailBasicDefinition
                term={'TargetElement*'}
                description={webTestStepData?.optionsDto?.targetElement}
              />
            )}
            <TestStepDetailBasicDefinition
              term={'Description*'}
              description={webTestStepData?.description}
            />
            {webTestStepData?.command === WebCommandOptionServerName.IF ? (
              <IfDetailBasic webTestStepData={webTestStepData} />
            ) : (
              <TestStepDetailBasicDefinition
                term={'Required'}
                description={webTestStepData?.required ? 'Y' : 'N'}
              />
            )}

            {webTestStepData?.command ===
              WebCommandOptionServerName.ScrollAndSwipe && (
              <TestStepDetailBasicDefinition
                term={'Loop*'}
                description={webTestStepData?.optionsDto?.loop.toString()}
              />
            )}

            <TestStepDetailBasicDefinition
              term={'WaitTime*'}
              description={webTestStepData?.optionsDto?.waitingTime}
            />
          </dl>
        </div>
      </div>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={deleteTestStepMutation.isError ? 'Error' : 'Delete Test Step'}
        subTitle={
          deleteTestStepMutation.isError
            ? deleteTestStepMutation.error.message
            : '해당 테스트 스텝을 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteTestStepMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteTestStepMutation.mutate(undefined, {
                    onSuccess: () => {
                      closeModal();
                      closeDialog();
                    },
                    onError: () => {
                      openModal();
                    },
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

export default TestStepDetailBasic;
