import { WebSpeedTestResultExecutionDetail } from '@customTypes/ide/speedTest/Web/testResult/type';
import React from 'react';

type Props = {
  result: WebSpeedTestResultExecutionDetail;
  index: number;
};

const SpeedRepeatResultTableRow = ({ result, index }: Props) => {
  return (
    <div className="flex h-[3.75rem] items-center border-b border-gray-300">
      <div className="w-48 px-6 text-sm text-gray-900">{index + 1}</div>
      <div className="flex-1 px-6 text-sm text-gray-500">
        {result.createdAt}
      </div>
      <div className="flex-1 px-6 text-sm text-gray-500">{result.domSpeed}</div>
      <div className="flex-1 px-6 text-sm text-gray-500">
        {result.loadSpeed}
      </div>
    </div>
  );
};

export default SpeedRepeatResultTableRow;
