import { CreatePlusButton, RemoveButton } from '@autosquare/common';
import {
  Coordinate,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import {
  checkCoordinateRange,
  checkIsEmptyInput,
  checkIsOutOfRange,
} from '@utils/static/mobileTestStep/checkCoordinateValidation/checkCoordinateValidation';
import { onOnlyNumberChangeHandler } from '@utils/static/mobileTestStep/onOnlyNumberChangeHandler';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

const CoordinatePointsEdit = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    register,
    setFocus,
  } = useFormContext<UpdateTestStepData>();

  const rawValue = watch('value');
  const parsedValue: Coordinate[] = JSON.parse(rawValue || '[]');
  const watchParsedValue = useWatch({ control, name: 'parsedValue' });
  const [internalParsedValue, setInternalParsedValue] =
    useState<Coordinate[]>(parsedValue);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `parsedValue`,
  });

  useEffect(() => {
    setInternalParsedValue(watchParsedValue || []);
  }, [watchParsedValue, setInternalParsedValue, remove, append]);

  // 내부 상태가 변경될 때마다 'value' 필드를 업데이트하는 useEffect
  useEffect(() => {
    setValue('value', JSON.stringify(internalParsedValue));
  }, [internalParsedValue, setValue]);

  // 컴포넌트가 처음 렌더링될 때 'parsedValue' 필드를 설정하는 useEffect
  useEffect(() => {
    if (fields.length === 0) {
      setValue('parsedValue', parsedValue);
    }
  }, [parsedValue, fields, setValue]);

  useEffect(() => {
    if (checkIsEmptyInput(watchParsedValue)) {
      setError('parsedValue', {
        type: 'empty',
        message: 'Points 좌표 위치를 모두 입력해주세요.',
      });
      return;
    }
    if (
      checkIsOutOfRange(
        watchParsedValue,
        control._defaultValues.optionsDto.screenSize,
      )
    ) {
      setError('parsedValue', {
        type: 'range',
        message:
          '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요.',
      });
      return;
    }

    clearErrors('parsedValue');
  }, [watchParsedValue]);

  const handleCreate = () => {
    const isEmptyInput = checkIsEmptyInput(watchParsedValue);

    if (isEmptyInput) {
      setError('parsedValue', {
        type: 'manual',
        message: 'Points 좌표 위치를 모두 입력해주세요.',
      });
      return;
    }
    if (
      checkCoordinateRange(
        control._defaultValues.optionsDto.screenSize,
        watchParsedValue,
        setError,
        setFocus,
      )
    )
      return;
    append({ x: null, y: null });
  };
  const handleRemove = (index: number) => {
    if (fields.length <= 2) {
      setError('parsedValue', {
        type: 'range',
        message: '최소 2개 이상의 좌표 위치를 입력해 주세요.',
      });

      return;
    }
    remove(index);
  };

  return (
    <div className="text-sm">
      <div className="mb-4 grid w-full grid-cols-5 gap-2 text-center">
        <p className="col-start-2">X 좌표</p>
        <p className="col-start-3">Y 좌표</p>
      </div>
      {fields.map((field, index) => (
        <div
          key={field.id}
          className="mb-2 grid grid-cols-5 items-center gap-2"
        >
          <p className="text-center">{index + 1}</p>
          <div className="col-span-1 flex flex-col items-center">
            <input
              type="text"
              className="w-full rounded-md border border-solid border-gray-300 px-3 py-1 text-center text-sm"
              {...register(`parsedValue.${index}.x` as const, {
                valueAsNumber: true,
                setValueAs: (value) =>
                  value === '' || value === null ? null : Number(value),
                onChange: (e) =>
                  onOnlyNumberChangeHandler(
                    e,
                    `parsedValue.${index}.x`,
                    setValue,
                  ),
              })}
            />
          </div>
          <div className="col-span-1 flex flex-col items-center">
            <input
              type="text"
              className="w-full rounded-md border border-solid border-gray-300 px-3 py-1 text-center text-sm"
              {...register(`parsedValue.${index}.y` as const, {
                valueAsNumber: true,
                setValueAs: (value) =>
                  value === '' || value === null ? null : Number(value),
                onChange: (e) =>
                  onOnlyNumberChangeHandler(
                    e,
                    `parsedValue.${index}.y`,
                    setValue,
                  ),
              })}
            />
          </div>
          <button
            type="button"
            className="flex items-center justify-center"
            onClick={() => handleRemove(index)}
          >
            <RemoveButton />
          </button>
        </div>
      ))}
      <div className="col-span-7 mt-4 grid grid-cols-10 items-center justify-center gap-2">
        <button
          type="button"
          className="col-span-4 col-start-3 flex items-center justify-center rounded-md border-2 border-dashed border-gray-300 p-2"
          onClick={() => handleCreate()}
        >
          <CreatePlusButton color="#043E8D" />
        </button>
      </div>
      <div className="grid grid-cols-9 items-center justify-center">
        <ErrorMessage
          errors={errors}
          name="parsedValue"
          render={({ message }) => (
            <p
              className={clsx(
                'col-span-5 mt-5 whitespace-nowrap text-center text-sm text-error-message',
                {
                  'col-start-1': errors?.parsedValue?.message?.length === 36,
                  'col-start-2': errors?.parsedValue?.message?.length < 36,
                },
              )}
            >
              {message}
            </p>
          )}
        />
      </div>
    </div>
  );
};
export default CoordinatePointsEdit;
