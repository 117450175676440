import {
  BaseWebSpeedTestStepDataSchema,
  CreateWebSpeedTestStepData,
  CreateWebSpeedTestStepDataSchema,
  UpdateWebSpeedTestStepData,
  UpdateWebSpeedTestStepDataSchema,
  WebSpeedTestStepData,
  WebSpeedTestStepDataOptionsDto,
  WebSpeedTestStepDataOptionsDtoSchema,
} from '../../Web/testStep/type';

export const BaseMobileWebSpeedTestStepDataSchema =
  BaseWebSpeedTestStepDataSchema;

export const MobileWebSpeedTestStepDataOptionsDtoSchema =
  WebSpeedTestStepDataOptionsDtoSchema;

export const CreateMobileWebSpeedTestStepDataSchema =
  CreateWebSpeedTestStepDataSchema;

export const UpdateMobileWebSpeedTestStepDataSchema =
  UpdateWebSpeedTestStepDataSchema;

export type MobileWebTestStepDataOptionsDto = WebSpeedTestStepDataOptionsDto;

export type MobileWebSpeedTestStepData = WebSpeedTestStepData;

export type CreateMobileWebSpeedTestStepData = CreateWebSpeedTestStepData;

export type UpdateMobileWebSpeedTestStepData = UpdateWebSpeedTestStepData;
