import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '@app/store';
import { useProjectMemberListQuery } from '@lib/api/dashboard/project/project';

import ProjectMembersTable from './components/ProjectMembersTable';

import {
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadings,
  PrType,
  TableSkeleton,
  decodeJwtToken,
} from '@autosquare/common';

const ProjectMembersList = () => {
  const { projectIdx } = useParams();

  const {
    data: members,
    isError,
    error,
    isLoading,
    isFetching,
  } = useProjectMemberListQuery();

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue,
  );

  const userInProject = decodeJwtToken(accessToken)?.pr?.filter(
    (pr: PrType) => pr.idx === parseInt(projectIdx),
  )[0];

  const breadcrumbs = [
    { title: 'Members', href: `/projects/${projectIdx}/members/list` },
    { title: 'List', href: `/projects/${projectIdx}/members/list` },
  ];

  const pageHeadingButtons = (): PageHeadingButtons[] =>
    userInProject?.role === 0
      ? [
          {
            title: 'Add Member',
            href: `/projects/${projectIdx}/members/add-member`,
            type: 'button',
            variant: 'primary',
          },
        ]
      : null;

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Project Members'}
        pageHeadingButtons={pageHeadingButtons()}
      />
      {isLoading || isFetching ? (
        <TableSkeleton />
      ) : isError ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <ProjectMembersTable members={members} />
      )}
    </>
  );
};

export default ProjectMembersList;
