import { ErrorMessage, PagingHeadingsInAutosquare } from '@autosquare/common';
import { useOverviewQuery } from '@lib/api/ide/overview/overview';
import { useProjectDetailQuery } from '@lib/api/ide/project/project';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import OverviewSkeleton from '../../Dashboard/Overview/components/OverviewSkeleton';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import MobileTestResults from '@components/Overview/MobileUITestResults';
import WebTestResults from '@components/Overview/WebUITestResults';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';

const UITestOverview = () => {
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();

  const overviewQuery = useOverviewQuery(projectIdx);
  const overview = overviewQuery.data;

  const projectDetailQuery = useProjectDetailQuery(projectIdx);
  const projectDetail = projectDetailQuery.data;

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={[
          { title: 'UI Test', href: pathname + search },
          { title: 'Overview', href: pathname + search },
        ]}
        pageTitle={'Overview'}
      />
      {overviewQuery.isLoading ||
      overviewQuery.isFetching ||
      projectDetailQuery.isLoading ||
      projectDetailQuery.isFetching ? (
        <OverviewSkeleton />
      ) : overviewQuery.isError ? (
        <ErrorMessage>{overviewQuery.error.message}</ErrorMessage>
      ) : projectDetailQuery.isError ? (
        <ErrorMessage>{projectDetailQuery.error.message}</ErrorMessage>
      ) : (
        <>
          {checkIsProjectFeatureIncluded(
            projectDetail,
            ProjectListDetailFeaturesCategory.Mobile,
            ProjectListDetailFeaturesFeature.Functional,
          ) && (
            <MobileTestResults
              tableHeaders={tableHeaders}
              mobileResults={overview?.mobileResults}
            />
          )}
          {checkIsProjectFeatureIncluded(
            projectDetail,
            ProjectListDetailFeaturesCategory.Web,
            ProjectListDetailFeaturesFeature.Functional,
          ) && (
            <WebTestResults
              tableHeaders={tableHeaders}
              webResults={overview?.webResults}
            />
          )}
        </>
      )}
    </>
  );
};
export default UITestOverview;

const tableHeaders = ['No', 'Title', 'DateTime', 'Result', 'Detail'];
