import {
  DefaultSkeleton,
  ErrorMessage,
  PlatFormTypeServerName,
} from '@autosquare/common';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

import { useSpeedMobileTestCaseDetailQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { useSpeedMobileTestScenarioDetailQuery } from '@lib/api/ide/speedMobile/speedMobileTestScenario';

import clsx from 'clsx';
import React from 'react';

type Props = {
  idx: number;
  testType: Omit<TestTypeCategory, 'step'>;
};

const ExecutionInfo = ({ idx, testType }: Props) => {
  const speedMobileTestCaseDetailQuery = useSpeedMobileTestCaseDetailQuery(
    idx.toString(),
    { enabled: testType === 'case' },
  );
  const speedMobileTestScenarioDetailQuery =
    useSpeedMobileTestScenarioDetailQuery(idx, {
      enabled: testType === 'scenario',
    });

  const speedMobileTestCaseDetail = speedMobileTestCaseDetailQuery.data;
  const speedMobileTestScenarioDetail = speedMobileTestScenarioDetailQuery.data;

  const executionInfo =
    testType === 'case'
      ? [
          {
            title: 'Platform',
            description:
              speedMobileTestCaseDetail?.platformType ===
              PlatFormTypeServerName.MobileApp
                ? 'App'
                : 'Web',
          },
          {
            title: 'Test Group',
            description: speedMobileTestCaseDetail?.caseGroupInfo?.name ?? '-',
          },
          {
            title: 'Service Group',
            description:
              speedMobileTestCaseDetail?.serviceGroupInfo?.name ?? '-',
          },
          {
            title: 'Title',
            description:
              testType === 'case' && speedMobileTestCaseDetail?.title,
          },
          {
            title: 'Execution Count',
            description:
              testType === 'case' &&
              speedMobileTestCaseDetail?.platformType ===
                PlatFormTypeServerName.MobileApp
                ? `AOS: ${speedMobileTestCaseDetail?.steps.aosStepCount} / iOS: ${speedMobileTestCaseDetail?.steps.iosStepCount}`
                : testType === 'case' &&
                  speedMobileTestCaseDetail?.platformType ===
                    PlatFormTypeServerName.MobileWeb &&
                  speedMobileTestCaseDetail?.steps.stepCount,
          },
        ]
      : [
          { title: 'Title', description: speedMobileTestScenarioDetail?.title },
          {
            title: 'Execution Count',
            description: speedMobileTestScenarioDetail?.caseCount,
          },
        ];

  return (
    <>
      <h3 className="text-base font-semibold leading-7 text-gray-900">
        Execution Info
      </h3>
      <div className="mt-4">
        {speedMobileTestCaseDetailQuery.isError ? (
          <ErrorMessage>
            {speedMobileTestCaseDetailQuery.error.message}
          </ErrorMessage>
        ) : speedMobileTestScenarioDetailQuery.isError ? (
          <ErrorMessage>
            {speedMobileTestScenarioDetailQuery.error.message}
          </ErrorMessage>
        ) : (
          <dl
            className={clsx(
              'grid',
              testType === 'case' ? 'grid-cols-5' : 'grid-cols-2',
            )}
          >
            {executionInfo.map((info) => (
              <div
                key={info.title}
                className="col-span-1 border-t border-gray-100 px-0 py-4"
              >
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {info.title}
                </dt>
                <dd className="mt-2 text-sm leading-6 text-gray-700">
                  {(testType === 'case' &&
                    speedMobileTestCaseDetailQuery.isLoading) ||
                  (testType === 'scenario' &&
                    speedMobileTestScenarioDetailQuery.isLoading) ? (
                    <DefaultSkeleton
                      number={1}
                      outMargin={'m-0'}
                      margin={'m-0'}
                    />
                  ) : (
                    info.description
                  )}
                </dd>
              </div>
            ))}
          </dl>
        )}
      </div>
    </>
  );
};

export default ExecutionInfo;
