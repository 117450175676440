import React, { Fragment } from 'react';
import MobileSpeedTestResultsOverviewTable from './MobileSpeedTestOverviwList/MobileSpeedTestResultsOverviewTable';
import { MobileSpeedTestResultData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

type Props = {
  resultData: MobileSpeedTestResultData;
};

const MobileSpeedTestOverviewList = ({ resultData }: Props) => {
  const isDashboardOverview =
    isWordInLocationPathname('dashboard') ||
    (isWordInLocationPathname('overview') && !isWordInLocationPathname('ide'));

  const subTitle = isDashboardOverview
    ? [{ title: 'Speed Test' }, { title: 'Mobile' }]
    : [{ title: 'Mobile' }];
  const total = resultData?.totalElements;

  return (
    <div className="mt-8 flow-root">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center space-x-2">
          {subTitle.map((item) => (
            <Fragment key={item.title}>
              <p className="text-sm font-semibold leading-6 text-gray-900">
                {item.title}
              </p>
              <svg
                className="size-5 shrink-0 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </Fragment>
          ))}
          <p className="truncate text-sm text-gray-500">
            최근 테스트 결과 목록
          </p>
        </div>
      </div>
      <div className="overflow-auto scrollbar-thin">
        {total === 0 ? (
          <div className="my-10 text-center text-sm text-gray-900">
            진행된 테스트 결과가 없습니다.
          </div>
        ) : (
          <MobileSpeedTestResultsOverviewTable resultData={resultData} />
        )}
      </div>
    </div>
  );
};

export default MobileSpeedTestOverviewList;
