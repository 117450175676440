import { FieldErrors } from 'react-hook-form';

export const getErrorHookForm = (
  errors: FieldErrors<unknown>,
): string | null => {
  if (!errors) return null;

  const keys = Object.keys(errors);
  if (keys.length === 0) return null;

  const firstKey = keys[0];
  if (errors[firstKey]?.message) {
    return `${firstKey}`;
  }

  const nestedError = getErrorHookForm(errors[firstKey]);
  return nestedError ? `${firstKey}.${nestedError}` : null;
};
