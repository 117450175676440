import React from "react";

import ErrorComponents from "@components/shared/ErrorComponents";

const NotAdmin = () => {
  return (
    <ErrorComponents
      errorTitle={"접근 권한이 없습니다."}
      errorMessage={["시스템 권한이 필요합니다."]}
    />
  );
};

export default NotAdmin;
