export const speedMobileTestStepKeys = {
  all: ['speedMobileTestStep'] as const,
  lists: () => [...speedMobileTestStepKeys.all, 'list'] as const,
  list: (idx: string) => [...speedMobileTestStepKeys.lists(), idx] as const,
  details: () => [...speedMobileTestStepKeys.all, 'detail'] as const,
  detail: (testStepIdx: number) =>
    [...speedMobileTestStepKeys.details(), testStepIdx] as const,
  cases: () => [...speedMobileTestStepKeys.all, 'case'] as const,
  case: (idx: string) => [...speedMobileTestStepKeys.cases(), idx] as const,
  commands: () => [...speedMobileTestStepKeys.all, 'command'] as const,
  command: (command: string, idx: number) =>
    [...speedMobileTestStepKeys.commands(), command, idx] as const,
};
