import React from 'react';
import GroupModalHeader from './GroupContainer/GroupModalHeader';
import GroupModalContent from './GroupContainer/GroupModalContent';

type Props = {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const GroupContainer = ({ closeModal }: Props) => {
  return (
    <div className="inset-0 z-50 flex flex-col items-center justify-center backdrop-blur-sm bg-gray-500/10 fixed drop-shadow-lg">
      <div className="sm:mx-auto w-4/6">
        <div className="bg-white rounded-lg pb-4">
          <GroupModalHeader closeModal={closeModal} />
          <GroupModalContent />
        </div>
      </div>
    </div>
  );
};

export default GroupContainer;
