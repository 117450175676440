import React, { useState } from 'react';

import WebSpeedTestStepListTable from './WebSpeedTestStepList/WebSpeedTestStepListTable';
import { useSpeedWebStepQuery } from '@lib/api/ide/speedWeb/speedWebTestStep';
import CreateWebSpeedTestStep from './WebSpeedTestStepList/CreateWebSpeedTestStep';
import UpdateWebSpeedTestStep from './WebSpeedTestStepList/UpdateWebSpeedTestStep';
import MobileWebTestStepSkeleton from '@components/shared/MobileWebTestStep/MobileWebTestStepSkeleton';
import MobileWebTestStepError from '@components/shared/MobileWebTestStep/MobileWebTestStepError';

const WebSpeedTestStepList = () => {
  const [isCreateTestStep, setIsCreateTestStep] = useState(false);
  const [isUpdateTestStep, setIsUpdateTestStep] = useState(false);

  const speedWebStepQuery = useSpeedWebStepQuery();
  const speedWebStep = speedWebStepQuery.data;

  const startCreatingTestStep = () => {
    setIsCreateTestStep(true);
  };

  const endCreatingTestStep = () => {
    setIsCreateTestStep(false);
  };

  const startUpdateTestStep = () => {
    setIsUpdateTestStep(true);
  };

  const endUpdateTestStep = () => {
    setIsUpdateTestStep(false);
  };
  return speedWebStepQuery.isLoading || speedWebStepQuery.isFetching ? (
    <MobileWebTestStepSkeleton />
  ) : speedWebStepQuery.isError ? (
    <MobileWebTestStepError message={speedWebStepQuery.error.message} />
  ) : isCreateTestStep ? (
    <CreateWebSpeedTestStep endCreatingTestStep={endCreatingTestStep} />
  ) : isUpdateTestStep ? (
    <UpdateWebSpeedTestStep
      endUpdateTestStep={endUpdateTestStep}
      speedWebStep={speedWebStep}
    />
  ) : (
    <div className="py-2.5">
      <WebSpeedTestStepListTable
        startCreatingTestStep={startCreatingTestStep}
        startUpdateTestStep={startUpdateTestStep}
        speedWebStep={speedWebStep}
      />
    </div>
  );
};

export default WebSpeedTestStepList;
