import { decodeJwtToken } from '@autosquare/common';

import { RootState } from '@app/store';

import { useProjectListQuery } from '@lib/api/ide/project/project';
import {
  useUserInfoQuery,
  usePostUserSettingMutation,
} from '@lib/api/ide/user/user';
import { useSchedulerInitQuery } from '@lib/api/ide/scheduler/scheduler';

import NewSidebar from '@components/NewSidebar/NewSidebar';

import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProjectLists from './components/ProjectLists';
import NoProjectList from './components/NoProjectList';

const IdeProject = () => {
  const projectListQuery = useProjectListQuery();
  const projectLists = projectListQuery.data;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const schedulerInitQuery = useSchedulerInitQuery();

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );
  const applicationUrl = localStorage.getItem('applicationUrl');

  const userInfoQuery = useUserInfoQuery();
  const userInfo = userInfoQuery.data;

  const postUserSettingMutation = usePostUserSettingMutation();

  useEffect(() => {
    if (userInfo) {
      postUserSettingMutation.mutate(userInfo);
    }
  }, [userInfo]);

  useEffect(() => {
    if (!applicationUrl) {
      window.electron.sendNoApplicationUrl();
    }

    if (accessToken) {
      const userId = decodeJwtToken(accessToken)?.sub;
      window.electron.send('userId', userId);
    }
  }, []);

  return (
    <>
      {accessToken ? (
        <>
          <NewSidebar />
          <main className="flex max-w-full sm:px-0 lg:px-0">
            <div
              className={`w-full px-4 lg:ml-20 ${
                projectLists?.length === 0 ? 'pt-0' : 'pt-8'
              }`}
            >
              {projectLists?.length === 0 ? (
                <NoProjectList />
              ) : (
                <ProjectLists
                  isLoading={projectListQuery.isLoading}
                  isFetching={projectListQuery.isFetching}
                  isError={projectListQuery.isError}
                  error={projectListQuery.error}
                  projectLists={projectLists}
                />
              )}
            </div>
          </main>
        </>
      ) : (
        <Navigate to="/ide/login" state="!200" />
      )}
    </>
  );
};

export default IdeProject;
