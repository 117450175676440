import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface webTestStepDetail {
  testStepIdx: number | null;
  testCommand: string | null;
}

const initialState: webTestStepDetail = {
  testStepIdx: null,
  testCommand: null,
};

export const webTestStepDetailSlice = createSlice({
  name: "web test step detail",
  initialState,
  reducers: {
    getTestStepIdx: (state, action: PayloadAction<number | null>) => {
      state.testStepIdx = action.payload;
    },
    setTestCommand: (state, action: PayloadAction<string | null>) => {
      state.testCommand = action.payload;
    }
  },
});

export const { getTestStepIdx, setTestCommand } = webTestStepDetailSlice.actions;

export default webTestStepDetailSlice.reducer;
