import React from 'react';

const Steps = () => {
  const steps = [
    {
      title: 'Step 1',
      explanation:
        '설정 > 개발자 옵션 > 무선 디버깅 옵션 활성화 버튼을 클릭해 주세요.',
      imageDescription: (
        <div className="flex h-80 flex-col gap-4 border border-gray-200 bg-white p-4">
          <p className="text-base font-semibold leading-normal text-gray-900">
            무선 디버깅
          </p>
          <div className="flex items-center justify-between rounded-xl border border-gray-200 px-4 py-2">
            <p>사용 안 함</p>
            <div className="relative h-6 w-10 rounded-full border-2 border-solid border-black">
              <div className="absolute left-0.5 top-0.5 size-4 rounded-full bg-black" />
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Step 2',
      explanation: '무선 디버깅 옵션을 허용해 주세요.',
      imageDescription: (
        <div className="flex h-80 flex-col gap-4 border border-gray-200 bg-gray-400 p-4">
          <p className="text-base font-semibold leading-normal text-gray-900">
            무선 디버깅
          </p>
          <div className="flex items-center justify-between rounded-xl border border-gray-200/15 px-4 py-2">
            <p>사용 안 함</p>
            <div className="relative h-6 w-10 rounded-full border-2 border-solid border-black">
              <div className="absolute left-0.5 top-0.5 size-4 rounded-full bg-black" />
            </div>
          </div>
          <div className="flex-center">
            <div className="w-2/3 rounded-lg border border-solid border-gray-200 bg-white">
              <div className="flex flex-col gap-2.5 p-4 text-sm font-normal leading-normal text-black">
                <div className="font-semibold">
                  이 네트워크에서 무선 디버깅을 허용하시겠습니까?
                </div>
                <div>네트워크 이름(SSID)</div>
                <div>Wi-Fi 주소</div>
              </div>
              <div className="grid grid-cols-2 text-center">
                <div className="rounded-bl-lg border-r border-t border-solid border-gray-200 p-3">
                  취소
                </div>
                <div className="rounded-br-lg border-t border-solid border-gray-200 p-3">
                  확인
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Step 3',
      explanation:
        "디바이스 화면에 보이는 IP 주소와 포트 번호를 '디바이스 IP 주소 및 포트' 입력창에 입력한 후, '페어링 코드로 기기 페어링' 메뉴를 클릭하세요.",
      imageDescription: (
        <div className="flex h-80 flex-col gap-4 border border-gray-200 bg-white p-4">
          <p className="text-base font-semibold leading-normal text-gray-900">
            무선 디버깅
          </p>
          <div className="flex items-center justify-between rounded-xl border border-gray-200 px-4 py-2">
            <p className="text-base font-semibold leading-normal text-black">
              사용 중
            </p>
            <div className="relative h-6 w-10 rounded-full border-2 border-solid border-black bg-black">
              <div className="absolute right-0.5 top-0.5 size-4 rounded-full bg-white" />
            </div>
          </div>
          <div className="rounded-xl text-sm font-normal leading-normal text-black">
            <div className="rounded-t-xl border border-solid border-gray-200 px-4 pb-3 pt-4">
              Device name
            </div>
            <div className="flex flex-col gap-3 border-x border-solid border-gray-200 px-4 py-3">
              <div>IP 주소 및 포트</div>
              <div className="text-red-500 underline">123.456.0.78:12345</div>
            </div>
            <div className="rounded-b-xl border border-solid border-gray-200 px-4 pb-4 pt-3">
              페어링 코드로 기기 페어링
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Step 4',
      explanation:
        '‘페어링 IP 주소 및 포트’ 입력창에 디바이스 화면에 보이는 페어링 코드와 IP 주소 및 포트 번호를 그대로 입력해 주세요.',
      imageDescription: (
        <div className="relative flex h-80 flex-col gap-4 border border-gray-200 bg-gray-400 p-4">
          <p className="text-base font-semibold leading-normal text-gray-900">
            무선 디버깅
          </p>
          <div className="flex items-center justify-between rounded-xl border border-gray-200/15 px-4 py-2">
            <p className="text-base font-semibold leading-normal text-black">
              사용 중
            </p>
            <div className="relative h-6 w-10 rounded-full border-2 border-solid border-black bg-black">
              <div className="absolute right-0.5 top-0.5 size-4 rounded-full bg-gray-400" />
            </div>
          </div>
          <div className="rounded-xl text-sm font-normal leading-normal text-black">
            <div className="rounded-t-xl border border-solid border-gray-200/15 px-4 pb-3 pt-4">
              Device name
            </div>
            <div className="flex flex-col gap-3 border-x border-solid border-gray-200/15 px-4 py-3">
              <div>IP 주소 및 포트</div>
              <div className="text-red-500/60 underline">
                123.456.0.78:12345
              </div>
            </div>
            <div className="rounded-b-xl border border-solid border-gray-200/15 px-4 pb-4 pt-3">
              페어링 코드로 기기 페어링
            </div>
          </div>
          <div className="absolute left-16 top-12 w-3/4 rounded-xl border border-solid border-gray-200 bg-white text-sm font-normal leading-normal text-black">
            <div className="flex flex-col gap-2.5 p-4">
              <div className="font-semibold">기기 페어링</div>
              <div>Wi-fi 페어링 코드</div>
              <div className="text-base font-semibold text-red-500 underline">
                123456
              </div>
              <div>IP주소 및 포트</div>
              <div className="text-red-500 underline">123.456.7.891:12345</div>
            </div>
            <div className="border-t border-solid border-gray-200 p-3 text-center font-semibold">
              취소
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <dl className="grid grid-cols-2 gap-x-14 gap-y-5">
      {steps.map((step) => (
        <div key={step.title}>
          <dt className="text-sm font-semibold leading-normal text-black">
            {step.title}
          </dt>
          <dd>
            <p className="py-2.5 text-sm font-medium leading-normal text-black">
              {step.explanation}
            </p>
            {step.imageDescription}
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default Steps;
