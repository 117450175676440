import { Button } from '@autosquare/common';

import React, { useContext } from 'react';
import { VscChromeMaximize } from 'react-icons/vsc';

import { TestExecutionContext } from '@contexts/ide/TestExecutionProvider';
import { useGetWebSocketMessage } from '@hooks/electron/useGetWebSocketMessage';

type Props = {
  closeModalExecution: () => void;
  maximizeWindow: () => void;
};

const ModalExecutionFrame = ({
  closeModalExecution,
  maximizeWindow,
}: Props) => {
  const { openModal } = useContext(TestExecutionContext);

  const { isTestRunning } = useGetWebSocketMessage();

  const closeExecutionModalProcess = () => {
    if (!isTestRunning) {
      closeModalExecution();
    } else {
      openModal();
    }
  };

  return (
    <header
      className="draggable-handle flex h-7 w-full cursor-move items-center justify-end rounded-md bg-gray-100 pl-2"
      id="popup-header"
    >
      <div>
        <Button
          type="button"
          variant="imageBasic"
          buttonSize="sm"
          onClick={() => {
            maximizeWindow();
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <VscChromeMaximize size={16} />
        </Button>
        <Button
          type="button"
          variant="imageEscape"
          buttonSize="sm"
          onClick={closeExecutionModalProcess}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </Button>
      </div>
    </header>
  );
};

export default ModalExecutionFrame;
