import { IDEAPI } from '@api/ideEndpoints';
import {
  CreateMobileSpeedTestScenarioData,
  DeleteMobileSpeedTestScenarioData,
  MobileSpeedTestScenarioData,
  UpdateMobileSpeedTestScenarioData,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { speedMobileTestScenarioKeys } from '@queries/ide/speedMobile/speedMobileTestScenarioKeys';
import {
  UseQueryOptions,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { axiosInstance } from '..';

const speedTestScenarioApi = IDEAPI.MOBILE_SPEED_TEST_SCENARIO;

//GET
const getSpeedMobileTestScenarioList = async (
  projectIdx: string,
): Promise<MobileSpeedTestScenarioData[]> => {
  const response = await axiosInstance.get<MobileSpeedTestScenarioData[]>(
    `${speedTestScenarioApi}?project=${projectIdx}`,
  );
  return response.data;
};

export const useSpeedMobileTestScenarioListQuery = (
  options?: UseQueryOptions<MobileSpeedTestScenarioData[], Error>,
) => {
  const { projectIdx } = useParams();

  return useQuery<
    MobileSpeedTestScenarioData[],
    Error,
    MobileSpeedTestScenarioData[]
  >({
    queryKey: speedMobileTestScenarioKeys.list(projectIdx),
    queryFn: () => getSpeedMobileTestScenarioList(projectIdx),
    ...options,
  });
};

const getSpeedMobileTestScenarioDetail = async (idx: string) => {
  const response = await axiosInstance.get<MobileSpeedTestScenarioData>(
    `${speedTestScenarioApi}/${idx}`,
  );
  return response.data;
};

export const useSpeedMobileTestScenarioDetailQuery = (
  scenarioIndex?: number | undefined,
  options?: UseQueryOptions<
    MobileSpeedTestScenarioData,
    Error,
    MobileSpeedTestScenarioData
  >,
) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<
    MobileSpeedTestScenarioData,
    Error,
    MobileSpeedTestScenarioData
  >({
    queryKey: speedMobileTestScenarioKeys.detail(
      scenarioIndex ? scenarioIndex.toString() : idx,
    ),
    queryFn: () =>
      getSpeedMobileTestScenarioDetail(
        scenarioIndex ? scenarioIndex.toString() : idx,
      ),
    ...options,
  });
};

//POST
export const createSpeedMobileTestScenario = async (
  data: CreateMobileSpeedTestScenarioData,
): Promise<MobileSpeedTestScenarioData> => {
  const response = await axiosInstance.post<MobileSpeedTestScenarioData>(
    speedTestScenarioApi,
    data,
  );
  return response.data;
};

export const useCreateSpeedMobileTestScenarioMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<
    MobileSpeedTestScenarioData,
    ServerResponseErrorDetails,
    CreateMobileSpeedTestScenarioData
  >({
    mutationFn: (data) => createSpeedMobileTestScenario(data),
    onSuccess: () => {
      queryClient.invalidateQueries(speedMobileTestScenarioKeys.all);
    },
  });
};

//PUT
export const updateSpeedMobileTestScenario = async (
  idx: string,
  data: UpdateMobileSpeedTestScenarioData,
): Promise<MobileSpeedTestScenarioData> => {
  const response = await axiosInstance.put<MobileSpeedTestScenarioData>(
    `${speedTestScenarioApi}/${idx}`,
    data,
  );
  return response.data;
};

export const useUpdateSpeedMobileTestScenarioMutation = (
  scenarioIdx?: string,
) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useMutation<
    MobileSpeedTestScenarioData,
    ServerResponseErrorDetails,
    UpdateMobileSpeedTestScenarioData
  >({
    mutationFn: (data) =>
      updateSpeedMobileTestScenario(scenarioIdx ? scenarioIdx : idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(speedMobileTestScenarioKeys.all);
    },
  });
};

//DELETE
export const deleteSpeedMobileTestScenarioMutation = async (
  data: DeleteMobileSpeedTestScenarioData,
): Promise<string> => {
  const response = await axiosInstance.delete<string>(speedTestScenarioApi, {
    data: data,
  });
  return response.data;
};

export const useDeleteSpeedMobileTestScenarioMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  return useMutation<
    string,
    ServerResponseErrorDetails,
    DeleteMobileSpeedTestScenarioData
  >({
    mutationFn: (data) => deleteSpeedMobileTestScenarioMutation(data),
    onSuccess: () => {
      navigate(`/ide/projects/${projectIdx}/speed-test/mobile/test-scenario`);
      queryClient.invalidateQueries(speedMobileTestScenarioKeys.lists());
    },
  });
};
