import React from 'react';

const NoDeviceList = () => {
  return (
    <div className="flex-center col-span-full h-12 gap-2.5 rounded-md border border-gray-200 bg-gray-50 p-2.5">
      <div className="text-sm font-normal leading-7 text-black">
        연결된 디바이스가 없습니다.
      </div>
    </div>
  );
};

export default NoDeviceList;
