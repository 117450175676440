import clsx from 'clsx';
import React, { ReactNode } from 'react';

type Props = {
  title: string;
  children: ReactNode;
  isTooltip?: boolean;
  tooltipClass?: string;
  tooltipWord?: string;
  classNames?: string;
  isTitleCenter?: boolean;
};

const SchedulerGridContainer = ({
  title,
  children,
  classNames,
  isTitleCenter = false,
}: Props) => {
  return (
    <div
      className={clsx(
        'text-sm font-medium text-gray-900 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4',
      )}
    >
      <p className={clsx(isTitleCenter && 'pt-2')}>{title}</p>

      <div className={clsx('col-span-2', classNames)}>{children}</div>
    </div>
  );
};

export default SchedulerGridContainer;
