import { DeepKeys } from '@autosquare/common';

import React, {
  HTMLInputTypeAttribute,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { WebTestStepData } from '@customTypes/testStep/type';

interface Props {
  term: string;
  name: DeepKeys<WebTestStepData>;
  type?: HTMLInputTypeAttribute;
  tag?: 'input' | 'textarea';
  min?: number;
  max?: number;
}

const DefinitionInputOrTextAreaByReactHookForm = ({
  term,
  name,
  type,
  min,
  max,
  tag = 'input',
}: Props) => {
  const { register } = useFormContext<WebTestStepData>();
  const { ref, ...rest } = register(name);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (tag === 'textarea' && textareaRef.current) {
      setHeight(textareaRef.current.scrollHeight);
    }
  }, []);

  useImperativeHandle(ref, () => textareaRef.current);

  return (
    <div className="items-center border-b border-gray-200 last:border-0 sm:flex sm:px-6 sm:py-4">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        {term}
      </dt>
      <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        <div className="w-full">
          <label htmlFor={name} className="sr-only">
            {term}
          </label>
          {tag === 'input' && type === 'checkbox' ? (
            <input
              type={type}
              name={name}
              id={name}
              className="input-checkbox-blue"
              {...register(name)}
            />
          ) : tag === 'input' ? (
            <input
              type={type}
              name={name}
              id={name}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              {...register(name, { required: true })}
              min={type === 'number' ? min : null}
              max={type === 'number' ? max : null}
              required
            />
          ) : (
            tag === 'textarea' && (
              <textarea
                name={name}
                id={name}
                style={{ height }}
                className="block w-full overflow-y-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 scrollbar-thin placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ref={textareaRef}
                {...rest}
                required
              />
            )
          )}
        </div>
      </dd>
    </div>
  );
};

export default DefinitionInputOrTextAreaByReactHookForm;
