import React from 'react';
import GroupTotal from './GroupTotal/GroupTotal';
import GroupCreateSelectBox from './CreateSelectBox/GroupCreateSelectBox';
import GroupTab from './GroupTab/GroupTab';

type Props = {
  setOpenCreateGroup: React.Dispatch<React.SetStateAction<boolean>>;
};
const GroupControl = ({ setOpenCreateGroup }: Props) => {
  return (
    <>
      <div className="flex justify-between items-center px-8">
        <GroupTotal />
        <GroupCreateSelectBox />
      </div>
      <GroupTab />
    </>
  );
};

export default GroupControl;
