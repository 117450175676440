import React from 'react';

interface Props {
  title: string;
  description?: string;
  titleColor?: 'default' | 'green' | 'red';
}

const DetailDivisionLine = ({
  title,
  description,
  titleColor = 'default',
}: Props) => {
  const textColor = {
    default: 'text-gray-900',
    green: 'text-green-600',
    red: 'text-red-500',
  };

  return (
    <div className="border-b border-gray-200 last:border-b-0 sm:flex sm:px-6 sm:py-5">
      <div className="flex flex-wrap items-baseline gap-x-4">
        <h3 className={`text-sm font-medium ${textColor[titleColor]}`}>
          {title}
        </h3>
        <p className="ml-2 truncate text-xs text-gray-500">{description}</p>
      </div>
    </div>
  );
};

export default DetailDivisionLine;
