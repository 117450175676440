import { CreateTestCaseGroup } from '@customTypes/testCaseGroup/type';
import { Dialog } from '@headlessui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import CreateNewGroup from './CreateNewGroup';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateNewGroupDialog = ({ isOpen, setIsOpen }: Props) => {
  const { reset } = useForm<CreateTestCaseGroup>();
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        reset();
      }}
      className="relative z-50"
    >
      <CreateNewGroup setIsOpen={setIsOpen} />
    </Dialog>
  );
};

export default CreateNewGroupDialog;
