import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';
import {
  CreateSpeedSchedulerData,
  SpeedSchedulerTypeName,
} from '@customTypes/ide/speedScheduler/speedScheduler';

import React from 'react';
import { useFormContext } from 'react-hook-form';

const CreateSpeedTestSchedulerTestType = () => {
  const { register } = useFormContext<CreateSpeedSchedulerData>();

  const testTypeList = [
    { name: SpeedSchedulerTypeName.Mobile },
    { name: SpeedSchedulerTypeName.Web },
  ];

  return (
    <SpeedSchedulerGridContainer title="Test Type">
      <fieldset className="flex items-center gap-10">
        {testTypeList.map((testType) => (
          <label
            key={testType.name}
            className="flex cursor-pointer items-center gap-3"
          >
            <input
              type="radio"
              value={testType.name}
              className="input-radio-congress-blue"
              {...register('type')}
            />
            {testType.name}
          </label>
        ))}
      </fieldset>
    </SpeedSchedulerGridContainer>
  );
};

export default CreateSpeedTestSchedulerTestType;
