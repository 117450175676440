import { RootState } from '@app/store';
import {
  ErrorMessage,
  PagingHeadingsInAutosquare,
  useScrollEvent,
} from '@autosquare/common';
import { useGetWebSocketMessage } from '@hooks/electron/useGetWebSocketMessage';
import { useDistributionMobileTestExecutionMutation } from '@lib/api/ide/mobile/mobileExecution';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DistributedExecutionDetailStopAndLoading from './MobileDistributedExecutionDetail/DistributedExecutionDetailStopAndLoading';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

type Props = {
  testType: TestTypeCategory;
  testLocation: 'ui-test' | 'speed-test';
  closeModalExecution: () => void;
};

const MobileDistributedExecutionDetail = ({
  testType,
  testLocation,
  closeModalExecution,
}: Props) => {
  const selectedExecutionList = useSelector(
    (state: RootState) => state.distributionExecution.selectedExecutionList,
  );

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  const [isTestExecutionStopped, setIsTestExecutionStopped] = useState(false);
  const [totalTestsToComplete, setTotalTestsToComplete] = useState<number>(0);
  const [currentCompletedTests, setCurrentCompletedTests] = useState<number>(0);

  const { webSocketMessages, isTestRunning, setIsTestRunning } =
    useGetWebSocketMessage();
  const { scrollRef } = useScrollEvent(webSocketMessages);

  const distributionMobileTestExecutionMutation =
    useDistributionMobileTestExecutionMutation(cookie);

  useEffect(() => {
    if (cookie !== null) {
      distributionMobileTestExecutionMutation.mutate({
        testInfo: selectedExecutionList,
      });
    }
  }, [cookie]);

  useEffect(() => {
    if (
      webSocketMessages[webSocketMessages.length - 1]?.includes(
        '테스트 진행을 위해 디바이스 세션 연결을 진행합니다',
      )
    ) {
      setTotalTestsToComplete((prev) => prev + 1);
    }
  }, [webSocketMessages]);

  useEffect(() => {
    if (
      isTestExecutionStopped &&
      webSocketMessages[webSocketMessages.length - 1]?.includes(
        '테스트가 완전히 종료되었습니다',
      )
    ) {
      setCurrentCompletedTests((prev) => prev + 1);
    }
  }, [webSocketMessages, isTestExecutionStopped]);

  useEffect(() => {
    if (
      isTestExecutionStopped &&
      currentCompletedTests === totalTestsToComplete
    ) {
      closeModalExecution();
      setIsTestExecutionStopped(false);
    }
  }, [currentCompletedTests, totalTestsToComplete, isTestExecutionStopped]);

  const breadcrumbs = [
    { title: testLocation === 'ui-test' ? 'UI Test' : 'Speed Test' },
    { title: 'Mobile' },
    {
      title:
        testType === 'scenario'
          ? 'Test Scenario'
          : testType === 'case' && 'Test Case',
    },
    { title: 'Distributed Execution' },
    { title: 'Detail' },
  ];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Distributed Execution'}
      />
      <div className="h-[calc(100%-7rem)]">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Execution Log
        </h2>
        <div
          className={`flex h-[calc(100%-3rem)] flex-col gap-2 overflow-auto overflow-y-scroll rounded-md border border-gray-200 bg-gray-50 px-4 py-2 scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
          ref={scrollRef}
        >
          {distributionMobileTestExecutionMutation.isError ? (
            <ErrorMessage margin="my-0">
              {distributionMobileTestExecutionMutation.error.message}
            </ErrorMessage>
          ) : (
            webSocketMessages?.map((webSocketMessage, idx) => (
              <p key={idx} className="text-sm leading-6">
                {webSocketMessage}
              </p>
            ))
          )}
        </div>
        <DistributedExecutionDetailStopAndLoading
          testType={testType}
          isTestExecutionStopped={isTestExecutionStopped}
          isTestRunning={isTestRunning}
          setIsTestRunning={setIsTestRunning}
          setIsTestExecutionStopped={setIsTestExecutionStopped}
          closeModalExecution={closeModalExecution}
        />
      </div>
    </>
  );
};

export default MobileDistributedExecutionDetail;
