import React, { useState } from 'react';

import {
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadings,
  TableSkeleton,
} from '@autosquare/common';

import { useAdminWebDriverListQuery } from '@lib/api/dashboard/admin/admin';

import AdminWebDriverListTable from './components/AdminWebDriverListTable';
import WebDriverUploadModal from './components/WebDriverUploadModal';

const AdminWebDriver = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleUploadSuccess = () => {
    setIsModalOpen(false);
    //fetchWebDrivers();
  };
  const breadcrumbs = [
    {
      title: 'Administration',
      href: `#`,
    },
    {
      title: 'Settings',
      href: `#`,
    },
    { title: 'WebDriver', href: `#` },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Upload',
      type: 'button',
      variant: 'primary',
      onClick: () => setIsModalOpen(true),
    },
  ];

  const {
    data: adminWebDriverList,
    isError,
    error,
    isLoading,
    isFetching,
  } = useAdminWebDriverListQuery();

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'WebDriver List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              {isLoading || isFetching ? (
                <TableSkeleton />
              ) : isError ? (
                <ErrorMessage>{error.message}</ErrorMessage>
              ) : (
                <AdminWebDriverListTable
                  adminWebDriverList={adminWebDriverList}
                />
              )}
            </div>
          </div>
        </div>
        <WebDriverUploadModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onUploadSuccess={handleUploadSuccess}
        />
      </div>
    </>
  );
};

export default AdminWebDriver;
