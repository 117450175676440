import {
  PageHeadingButtons,
  PagingHeadings,
  decodeJwtToken,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';
import {
  useProjectMemberDetailQuery,
  useUpdateMemberInfoMutation,
} from '@lib/api/dashboard/project/project';

import MemberInformation from './components/MemberInformation';
import RemoveDialog from './components/RemoveDialog';

const ProjectMemberDetail = () => {
  const location = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isEdited, setIsEdited] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue,
  );

  const isAdminMemberInProject =
    decodeJwtToken(accessToken)?.pr?.find(
      (pr) => pr?.idx === Number(projectIdx),
    )?.role === 0;

  const [selectedRole, setSelectedRole] = useState<string>();

  const getProjectMemberDetail = useProjectMemberDetailQuery(projectIdx, idx);

  const loginUserId = decodeJwtToken(accessToken)?.sub;
  const memberDetailUserId = getProjectMemberDetail.data?.userId;

  const data = {
    projectRole: selectedRole,
  };

  const updateMemberInfoMutation = useUpdateMemberInfoMutation(projectIdx, idx);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateMemberInfoMutation.mutate(data, {
      onSuccess: () => {
        setIsEdited(false);
      },
    });
  };

  const breadcrumbs = isEdited
    ? [
        {
          title: 'Members',
          href: `${location.pathname.split('/').slice(0, -1).join('/')}/list`,
        },
        { title: 'Detail', href: location.pathname + location.search },
        { title: 'Edit', href: location.pathname + location.search },
      ]
    : [
        {
          title: 'Members',
          href: `${location.pathname.split('/').slice(0, -1).join('/')}/list`,
        },
        { title: 'Detail', href: location.pathname + location.search },
      ];

  const pageHeadingButtons: PageHeadingButtons[] =
    getProjectMemberDetail.isLoading || getProjectMemberDetail.isFetching
      ? [{ title: 'Loading...', type: 'button', variant: 'secondary' }]
      : isEdited
        ? [
            {
              title: 'Cancel',
              onClick: () => setIsEdited(false),
              type: 'button',
              variant: 'secondary',
            },
            {
              title: 'Save',
              type: 'submit',
              variant: 'primary',
            },
          ]
        : [
            {
              title: 'Edit',
              onClick: () => setIsEdited(true),
              type: 'button',
              variant: 'secondary',
            },
            {
              title: 'Remove',
              onClick: () => setIsRemoved(true),
              type: 'button',
              variant: 'caution',
            },
          ];

  return (
    <form onSubmit={handleSubmit}>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={isEdited ? 'Edit Member Info' : 'Member Detail'}
        pageHeadingButtons={
          loginUserId === memberDetailUserId
            ? null
            : isAdminMemberInProject
              ? pageHeadingButtons
              : null
        }
      />
      <MemberInformation
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        isEdited={isEdited}
      />
      <RemoveDialog isRemoved={isRemoved} setIsRemoved={setIsRemoved} />
    </form>
  );
};

export default ProjectMemberDetail;
