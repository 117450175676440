import { RootState } from '@app/store';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';
import { UpdateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const SpeedTestSchedulerDetailTitle = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateSpeedSchedulerData>();

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  return (
    <SpeedSchedulerGridContainer title="Title" isTitleCenter={true}>
      <input
        className={clsx('input-base', !isRunning && 'bg-gray-100')}
        placeholder="Enter scheduler title"
        {...register('title')}
        disabled={!isRunning}
      />
      <ErrorMessage
        errors={errors}
        name="title"
        render={({ message }) => (
          <p className="standard-error-message">{message}</p>
        )}
      />
    </SpeedSchedulerGridContainer>
  );
};

export default SpeedTestSchedulerDetailTitle;
