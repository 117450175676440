import {
  UpdateSpeedSchedulerData,
  UpdateSpeedSchedulerSchema,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useSpeedSchedulerDetailQuery,
  useUpdateSpeedSchedulerMutation,
  useUpdateSpeedSchedulerStatusMutation,
} from '@lib/api/ide/speedScheduler/speedScheduler';
import SpeedTestSchedulerDetailStatus from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailStatus';
import SpeedTestSchedulerDetailTestType from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailTestType';
import SpeedTestSchedulerDetailTitle from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailTitle';
import SpeedTestSchedulerDetailScenario from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailScenario';
import SpeedTestSchedulerDetailDevice from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailDevice';
import SpeedTestSchedulerDetailBrowser from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailBrowser';
import SpeedTestSchedulerDetailRepeat from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailRepeat';
import SpeedTestSchedulerDetailSchedule from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailSchedule';
import SpeedTestSchedulerDetailEmailAlarm from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailEmailAlarm';
import { Button, ErrorMessage } from '@autosquare/common';
import { useDispatch, useSelector } from 'react-redux';
import { hasSchedulerRunning } from '@store/ide/scheduler/schedulerSlice';
import { RootState } from '@app/store';

const SpeedTestSchedulerDetailForm = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const speedSchedulerDetailQuery = useSpeedSchedulerDetailQuery();
  const speedSchedulerDetail = speedSchedulerDetailQuery.data;

  const methods = useForm<UpdateSpeedSchedulerData>({
    defaultValues: {
      scheduleIdx: speedSchedulerDetail.idx,
      title: speedSchedulerDetail.title,
      scenarioIdxList: speedSchedulerDetail.scenario_idx_list,
      startTime: speedSchedulerDetail.start_time,
      cronCycle: speedSchedulerDetail.cycle,
      mailReceivers: speedSchedulerDetail.mail_receivers,
      repeatNum: speedSchedulerDetail.repeat_num,
      autoRegister: true,
    },
    resolver: zodResolver(UpdateSpeedSchedulerSchema),
  });

  const updateSpeedSchedulerMutation = useUpdateSpeedSchedulerMutation();
  const updateSpeedSchedulerStatusMutation =
    useUpdateSpeedSchedulerStatusMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hasSchedulerRunning(speedSchedulerDetail.running));
  }, []);

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const onSubmit: SubmitHandler<UpdateSpeedSchedulerData> = (data) => {
    if (data.scenarioIdxList.length === 0) {
      methods.setError('scenarioIdxList', {
        type: 'required',
        message: '시나리오를 선택해 주세요.',
      });
    } else {
      updateSpeedSchedulerStatusMutation.mutate(
        { idx: speedSchedulerDetail.idx, isRunning: isRunning },
        {
          onSuccess: () => {
            if (isRunning) {
              updateSpeedSchedulerMutation.mutate(data);
            } else {
              navigate(pathname.split('/').slice(0, -1).join('/'));
            }
          },
        },
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="mt-4 flex flex-col gap-8 py-4 text-sm font-medium leading-normal text-gray-900"
      >
        <SpeedTestSchedulerDetailStatus />
        <SpeedTestSchedulerDetailTestType />
        <SpeedTestSchedulerDetailTitle />
        <SpeedTestSchedulerDetailScenario />
        <SpeedTestSchedulerDetailDevice />
        <SpeedTestSchedulerDetailBrowser />
        <SpeedTestSchedulerDetailRepeat />
        <SpeedTestSchedulerDetailSchedule />
        <SpeedTestSchedulerDetailEmailAlarm />
        <div className="flex items-center justify-end gap-6 border-t border-solid border-gray-200 py-4">
          {updateSpeedSchedulerMutation.isError && (
            <ErrorMessage>
              {updateSpeedSchedulerMutation.error.message}
            </ErrorMessage>
          )}
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={updateSpeedSchedulerMutation.isLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default SpeedTestSchedulerDetailForm;
