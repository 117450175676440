import { ListBoxHeadlessInController } from '@autosquare/common';

import DefinitionList from '@components/shared/DefinitionList';
import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';
import ControllerTextArea from '@components/shared/TextArea/ControllerTextArea';
import DefinitionInputByReactHookForm from '@components/shared/TestStepDetail/DefinitionInputByReactHookForm';

import {
  deviceKeyList,
  deviceKeyListInIos,
  DeviceKeyServerName,
  findListNameFromServerName,
  findListNameFromServerNameInIos,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TestStepDetailScrollAndSwipe from '@components/shared/TestStepDetail/TestStepDetailScrollAndSwipe';

type Props = {
  mobileTestStep: MobileTestStepData;
};

const LoopAction = ({ mobileTestStep }: Props) => {
  const { watch, control, setValue, register } =
    useFormContext<UpdateTestStepData>();

  const value = watch('value');
  const actionRepeatLimit = watch('optionsDto.actionOptions.repeatLimit');

  const commandNameFromServerName = findMobileCommandNameFromServerName(
    mobileTestStep?.value as MobileCommandOptionServerName,
  );
  const actionValue = mobileTestStep?.optionsDto?.actionValue;

  const handleClickOption = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const target = e.target as HTMLElement;
    const textContent = target.textContent;

    if (textContent === commandNameFromServerName) {
      setValue('optionsDto.actionValue', actionValue);
    } else {
      setValue('optionsDto.actionValue', '');
    }
  };
  return (
    <>
      <DetailDivisionLine
        title={'Action'}
        description={'반복 실행 될 동작을 설정합니다.'}
      />
      <DefinitionList
        term={'Command'}
        description={
          <Controller
            name={'value'}
            control={control}
            rules={{ required: 'Action Command를 선택해주세요.' }}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findMobileCommandNameFromServerName(
                  field.value as MobileCommandOptionServerName,
                )}
                onChange={field.onChange}
                lists={mobileCommandOptions.filter(
                  (command) =>
                    command.name === MobileCommandOptionName.DeviceKey ||
                    command.name === MobileCommandOptionName.ScrollSwipe ||
                    command.name === MobileCommandOptionName.Click ||
                    command.name === MobileCommandOptionName.Wait,
                )}
                valueToSave={'serverName'}
                valueToShow={'name'}
                onClickOption={handleClickOption}
              />
            )}
          />
        }
      />
      {value === MobileCommandOptionServerName.ScrollSwipe ? (
        <>
          <TestStepDetailScrollAndSwipe
            mobileTestStep={mobileTestStep}
            keyName={'optionsDto.actionValue'}
          />
          <div className={`items-center sm:flex sm:px-6 sm:py-4`}>
            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
              Repeat
            </dt>
            <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
              <div className="w-full">
                <label htmlFor={'repeatLimit'} className="sr-only">
                  Repeat
                </label>
                <input
                  type={'number'}
                  name={'repeatLimit'}
                  id={'repeatLimit'}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  min={1}
                  max={99}
                  defaultValue={actionRepeatLimit || 1}
                  {...register('optionsDto.actionOptions.repeatLimit', {
                    required: 'Action의 Repeat 항목을 확인해주세요.',
                    min: { value: 1, message: '1이상 숫자를 입력해주세요.' },
                    max: { value: 99, message: '99이하 숫자를 입력해주세요.' },
                  })}
                />
              </div>
            </dd>
          </div>
        </>
      ) : value === MobileCommandOptionServerName.Wait ? (
        <DefinitionInputByReactHookForm
          term={'Value(sec)*'}
          name={'optionsDto.actionValue'}
          type={'number'}
          min={3}
          max={180}
          register={register('optionsDto.actionValue', {
            required: `Value 값을 최소 3초 이상 입력해 주세요.`,
          })}
        />
      ) : (
        <DefinitionList
          term={'Value'}
          description={
            <Controller
              control={control}
              name={'optionsDto.actionValue'}
              rules={{ required: 'Action항목의 Value를 확인해주세요.' }}
              render={({ field }) =>
                value === MobileCommandOptionServerName.DeviceKey ? (
                  <ListBoxHeadlessInController
                    value={field.value}
                    buttonValue={
                      mobileTestStep.mobileOs === 'aos'
                        ? findListNameFromServerName(
                            field.value as DeviceKeyServerName,
                          ) || 'Select Key Event'
                        : findListNameFromServerNameInIos(
                            field.value as DeviceKeyServerName,
                          ) || 'Select Key Event'
                    }
                    onChange={field.onChange}
                    lists={
                      mobileTestStep.mobileOs === 'aos'
                        ? deviceKeyList.slice(1)
                        : deviceKeyListInIos.slice(1)
                    }
                    valueToSave={'serverName'}
                    valueToShow={'listName'}
                  />
                ) : (
                  value === MobileCommandOptionServerName.Click && (
                    <ControllerTextArea field={field} />
                  )
                )
              }
            />
          }
        />
      )}
    </>
  );
};

export default LoopAction;
