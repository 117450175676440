import { decodeJwtToken } from '@autosquare/common';

import React, { Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';

import {
  DisclosureButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import companyLogo from '@assets/img/logo_icon.png';

import { useDashboardLogoutMutation } from '@lib/api/dashboard/user/users';

import { UserInfoType } from '@customTypes/type';

type Props = {
  userInfo: UserInfoType;
  accessToken: string;
  open: boolean;
};

const BasicNav = ({ userInfo, accessToken, open }: Props) => {
  const location = useLocation();

  const dashboardLogoutMutation = useDashboardLogoutMutation();

  const navbarTitles = [
    { title: 'Projects', type: 'internal', link: '/projects' },
    { title: 'Download', type: 'internal', link: '/download' },
    {
      title: 'Documents',
      type: 'external',
      link:
        window.location.origin === 'http://localhost:3002'
          ? 'http://localhost:3000/docs/'
          : `${window.location.origin}/docs/`,
    },
  ];

  return (
    <div className="max-w-full px-4 sm:px-6 lg:px-8">
      <div className="flex h-16 justify-between">
        <div className="flex">
          <div className="flex shrink-0 items-center">
            <img
              className="block h-6 w-auto lg:hidden"
              src={companyLogo}
              alt="T-SQUARE"
            />
            <img
              className="hidden h-6 w-auto lg:block"
              src={companyLogo}
              alt="T-SQUARE"
            />
            <span className="ml-4 font-semibold text-gray-800">TSQUARE</span>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
            {navbarTitles?.map((navBarTitle) =>
              navBarTitle.type === 'internal' ? (
                <Link
                  to={navBarTitle.link}
                  key={navBarTitle.title}
                  className={
                    'inline-flex items-center border-congress-blue px-1 pt-1 text-sm font-medium text-gray-900 hover:text-gray-700 ' +
                    (location.pathname.includes(
                      navBarTitle.title?.toLowerCase(),
                    ) && 'border-b-2')
                  }
                >
                  {navBarTitle.title}
                </Link>
              ) : (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a
                  href={navBarTitle.link}
                  key={navBarTitle.title}
                  className={
                    'inline-flex items-center border-congress-blue px-1 pt-1 text-sm font-medium text-gray-900 hover:text-gray-700'
                  }
                  target="_blank"
                >
                  {navBarTitle.title}
                </a>
              ),
            )}
          </div>
        </div>
        <div className="hidden sm:ml-6 sm:flex sm:items-center">
          {/* Profile dropdown */}
          <Menu as="div" className="relative ml-3">
            <div>
              <MenuButton className="flex rounded-full bg-white text-sm focus:outline-none">
                <div className="flex items-center">
                  <div>
                    <span className="sr-only">Open user menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-8"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {userInfo?.name}
                    </div>
                  </div>
                </div>
              </MenuButton>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                {decodeJwtToken(accessToken)?.auth === 'ROLE_ADMIN' && (
                  <MenuItem>
                    {({ focus }) => (
                      <Link to={'/admin/users/list'}>
                        <div
                          className={
                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer ' +
                            (focus && 'bg-gray-100')
                          }
                        >
                          Administration
                        </div>
                      </Link>
                    )}
                  </MenuItem>
                )}
                <MenuItem>
                  {({ focus }) => (
                    <div
                      className={
                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer ' +
                        (focus && 'bg-gray-100')
                      }
                      onClick={() => dashboardLogoutMutation.mutate()}
                    >
                      Sign out
                    </div>
                  )}
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
        <div className="-mr-2 flex items-center sm:hidden">
          {/* Mobile menu button */}
          <DisclosureButton className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open main menu</span>
            {open ? (
              <XMarkIcon className="block size-6" aria-hidden="true" />
            ) : (
              <Bars3Icon className="block size-6" aria-hidden="true" />
            )}
          </DisclosureButton>
        </div>
      </div>
    </div>
  );
};

export default BasicNav;
