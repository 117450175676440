import React from 'react';

const NoSpeedTestScenarioList = () => {
  return (
    <div className="flex h-[calc(100%-3rem)] items-center justify-center">
      추가 가능한 테스트 시나리오가 존재하지 않습니다.
    </div>
  );
};

export default NoSpeedTestScenarioList;
