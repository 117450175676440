import {
  AddName,
  AddServerName,
  TextReplaceAddList,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

export const textReplaceAddList: TextReplaceAddList[] = [
  { idx: 1, name: AddName.Year, serverName: AddServerName.Year },
  { idx: 2, name: AddName.Month, serverName: AddServerName.Month },
  { idx: 3, name: AddName.Day, serverName: AddServerName.Day },
];

export const findTextReplaceAddNameByServerName = (serverName: AddServerName) =>
  textReplaceAddList.find(
    (addProperty) => addProperty.serverName === serverName,
  )?.name;

export const findTextReplaceFromServerNameByName = (name: AddName) =>
  textReplaceAddList.find((addProperty) => addProperty.name === name)
    ?.serverName;
