import {
  Breadcrumbs,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import TestCaseImportStep from './components/TestCaseImportStep';
import TestCaseImportUploadFile from './components/TestCaseImportUploadFile';
import TestCaseImportShowingResults from './components/TestCaseImportShowingResults';
import TestCaseImportCompleteResults from './components/TestCaseImportCompleteResults';
import { ImportedFileData } from '@customTypes/testCase/type';

const MobileTestCaseImport = () => {
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();

  const [stepNumber, setStepNumber] = useState<1 | 2 | 3>(1);
  const [fileData, setFileData] = useState<ImportedFileData[]>([]);

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -3).join('/')}/overview`,
    },
    {
      title: 'Mobile',
      href: `/ide/projects/${projectIdx}/ui-test/mobile/test-case`,
    },
    {
      title: 'Test Case',
      href: `/ide/projects/${projectIdx}/ui-test/mobile/test-case`,
    },
    { title: 'Import', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] =
    stepNumber === 2
      ? [
          {
            title: 'Clear',
            variant: 'caution',
            type: 'button',
            onClick: () => {
              setStepNumber(1);
              setFileData(null);
            },
          },
          {
            title: 'Import',
            variant: 'primary',
            type: 'button',
            onClick: () => {
              setStepNumber(3);
            },
          },
        ]
      : null;

  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case Import'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <TestCaseImportStep stepNumber={stepNumber} />
              {stepNumber === 1 ? (
                <TestCaseImportUploadFile
                  setStepNumber={setStepNumber}
                  setFileData={setFileData}
                />
              ) : stepNumber === 2 ? (
                <TestCaseImportShowingResults fileData={fileData} />
              ) : (
                <TestCaseImportCompleteResults fileData={fileData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileTestCaseImport;
