import { DescriptionListSkeleton, ErrorMessage } from '@autosquare/common';

import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import SelectRoleListBox from '@components/shared/SelectRoleListBox';

import { useProjectMemberDetailQuery } from '@lib/api/dashboard/project/project';

type Props = {
  selectedRole: string;
  setSelectedRole: React.Dispatch<React.SetStateAction<string>>;
  isEdited: boolean;
};

const MemberInformation = ({
  selectedRole,
  setSelectedRole,
  isEdited,
}: Props) => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const getProjectMemberDetail = useProjectMemberDetailQuery(projectIdx, idx);
  const projectMemberDetails = getProjectMemberDetail.data;

  useEffect(() => {
    setSelectedRole(projectMemberDetails?.projectRole);
  }, [projectMemberDetails]);

  const descriptionList = [
    { term: 'Name', description: projectMemberDetails?.name },
    { term: 'Email', description: projectMemberDetails?.userId },
    {
      term: 'Role',
      description: isEdited ? (
        <SelectRoleListBox
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          isActivated={isEdited}
        />
      ) : (
        projectMemberDetails?.projectRole
      ),
    },
    { term: 'Last Seen', description: projectMemberDetails?.lastSeen },
  ];

  return (
    <div className="mt-8">
      <h2 className="text-base font-semibold">Member Information</h2>
      <dl className="mt-8 pt-2 pb-6 border-y border-solid border-gray-300 text-sm font-medium">
        {getProjectMemberDetail?.isLoading ||
        getProjectMemberDetail?.isFetching ? (
          <DescriptionListSkeleton
            number={4}
            isLine={false}
            outPadding={'py-2.5'}
          />
        ) : getProjectMemberDetail?.isError ? (
          <ErrorMessage>{getProjectMemberDetail.error.message}</ErrorMessage>
        ) : (
          descriptionList?.map((description) => (
            <div
              className="grid grid-cols-3 gap-4 items-center py-4"
              key={description?.term}
            >
              <dt>{description?.term}</dt>
              <dd className="col-span-2 sm:col-span-1">
                {description?.description}
              </dd>
            </div>
          ))
        )}
      </dl>
    </div>
  );
};

export default MemberInformation;
