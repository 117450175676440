import { findTextReplaceFromNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceFromList';
import { findTextReplaceAddNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';

import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const BasicTextReplace = ({ mobileTestStepData }: Props) => {
  return (
    <>
      <TestStepDetailBasicDefinition
        term={'Text Replace'}
        description={
          mobileTestStepData.optionsDto.textReplace === 'true' ? 'Y' : 'N'
        }
      />
      {mobileTestStepData.optionsDto.textReplace === 'true' && (
        <>
          <TestStepDetailBasicDefinition
            term={'From'}
            description={findTextReplaceFromNameByServerName(
              mobileTestStepData.optionsDto.textReplaceOptions.from,
            )}
          />
          <TestStepDetailBasicDefinition
            term={'Add'}
            description={`${findTextReplaceAddNameByServerName(mobileTestStepData.optionsDto.textReplaceOptions.addKey)} / ${mobileTestStepData.optionsDto.textReplaceOptions.addValue}`}
          />
          <TestStepDetailBasicDefinition
            term={'Date Format'}
            description={
              mobileTestStepData.optionsDto.textReplaceOptions.dateFormat
            }
          />
        </>
      )}
    </>
  );
};

export default BasicTextReplace;
