import React from 'react';
import SpeedRepeatResultTable from './SpeedRepeatResultTable/SpeedRepeatResultTable';
import { WebSpeedTestResultTableDetailData } from '@customTypes/ide/speedTest/Web/testResult/type';

type Props = {
  speedWebTestResultTableData: WebSpeedTestResultTableDetailData;
};

const SpeedRepeatResult = ({ speedWebTestResultTableData }: Props) => {
  return (
    <div className="mt-3">
      <p className="border-b border-gray-200 py-3 text-sm font-semibold text-gray-900">
        Repeat Result &#40;
        {speedWebTestResultTableData?.repeatCount}&#41;
      </p>
      {speedWebTestResultTableData?.repeatCount === 0 ? (
        <div className="flex items-center justify-center px-6 py-5">
          <p className="text-sm font-normal text-gray-900">
            진행된 테스트 결과가 없습니다.
          </p>
        </div>
      ) : (
        <SpeedRepeatResultTable
          repeatResults={speedWebTestResultTableData?.detail}
        />
      )}
    </div>
  );
};

export default SpeedRepeatResult;
