import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useDashboardLoginMutation } from '@lib/api/dashboard/user/users';

import { Button, ErrorMessage } from '@autosquare/common';

import { Login } from '@customTypes/login/type';
import {
  isInputValidLogin,
  validateEmail,
  validatePassword,
} from '@utils/validation';

const LoginForm = () => {
  const location = useLocation();

  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [comparingValues, setComparingValues] = useState<Login>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
  } = useForm<Login>();

  const userId = watch('userId');
  const password = watch('password');

  const dashboardLoginMutation = useDashboardLoginMutation();

  const onSubmit: SubmitHandler<Login> = (data) => {
    dashboardLoginMutation.mutate(data, {
      onError: () => {
        setComparingValues(data);
      },
    });
  };

  const checkCapsLock = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  useEffect(() => {
    setFocus('userId');
  }, [setFocus]);

  const formValues = [
    {
      title: 'Email address',
      label: 'userId',
      inputType: 'email',
      checkValidation:
        userId?.length > 0 && !validateEmail(userId)
          ? '이메일 형식에 맞게 입력해주세요.'
          : errors.userId?.type === 'required' && '필수 입력 사항입니다.',
      register: {
        ...register('userId', {
          required: true,
        }),
      },
    },
    {
      title: 'Password',
      label: 'password',
      inputType: 'password',
      checkValidation: isCapsLockOn
        ? 'Caps Lock이 켜져 있습니다.'
        : !validatePassword(password) && password?.length > 0
          ? '8자~16자의 영문 대 소문자, 숫자, 기호를 사용해주세요.'
          : errors.password?.type === 'required' && '필수 입력 사항입니다.',
      register: {
        ...register('password', { required: true }),
      },
      onkeydown: checkCapsLock,
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
      {formValues.map((formValue) => (
        <div key={formValue.title}>
          <label
            htmlFor={formValue.label}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {formValue.title}
          </label>
          <input
            type={formValue.inputType}
            placeholder={formValue.title}
            className="input-base"
            name={formValue.label}
            id={formValue.label}
            onKeyDown={formValue?.onkeydown}
            {...formValue.register}
          />
          <ErrorMessage margin="my-2">{formValue.checkValidation}</ErrorMessage>
        </div>
      ))}
      <Button
        type="submit"
        variant={'primary'}
        className="w-full"
        disabled={!isInputValidLogin(userId, password)}
      >
        Log In
      </Button>
      <ErrorMessage>
        {comparingValues?.userId === userId &&
        comparingValues?.password === password &&
        dashboardLoginMutation.isError
          ? dashboardLoginMutation.error.message
          : location.state === '401!' && '로그인이 필요합니다.'}
      </ErrorMessage>
    </form>
  );
};

export default LoginForm;
