import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { axiosLocalInstance } from "..";
import { IDEAPI } from "@api/ideEndpoints";
import { browserKeys } from "@queries/ide/web/browserKeys";
import { BrowserInfo } from "@customTypes/ide/browser/browser";

// GET
const webBrowserList = async (): Promise<BrowserInfo[]> => {
  const reponse = await axiosLocalInstance.get<BrowserInfo[]>(
    IDEAPI.GET_BROWSER_LIST
  );
  return reponse.data;
};
export const useWebBrowserListQuery = (
  options?: UseQueryOptions<BrowserInfo[], Error>
) =>
  useQuery<BrowserInfo[], Error>({
    queryKey: browserKeys.all,
    queryFn: () => webBrowserList(),
    ...options,
  });
