import React from "react";

type Props = {
  onChangeEmailAddress: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

const EmailAddressInput = ({ onChangeEmailAddress }: Props) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
      <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
        Email address
      </label>
      <div className="mt-2 col-span-2 sm:mt-0">
        <input
          className="input-base"
          type="email"
          onChange={onChangeEmailAddress}
          required
        />
      </div>
    </div>
  );
};

export default EmailAddressInput;
