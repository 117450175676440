import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { axiosInstance, axiosLocalInstance } from '../';
import { IDEAPI } from '@api/ideEndpoints';
import { settingKeys } from '@queries/ide/settingKeys';

import {
  DeviceSetting,
  EmailSetting,
  UpdateSettings,
} from '@customTypes/setting/type';

import { ServerResponseErrorDetails } from '@customTypes/type';

import { MutationDefault } from '@autosquare/common';
import axios from 'axios';

// GET
export const getEmailAlarmSettingQuery = () =>
  useQuery({
    queryKey: ['settings', 'settingsEmailAlarm'],
    queryFn: () => axiosInstance.get(IDEAPI.EMAIL_ALARM_SETTING),
  });

const getEmailAlarmSetting = async (): Promise<EmailSetting> => {
  const response = await axiosInstance.get<EmailSetting>(
    IDEAPI.EMAIL_ALARM_SETTING,
  );
  return response.data;
};
export const useEmailAlarmSettingQuery = (
  options?: UseQueryOptions<EmailSetting, ServerResponseErrorDetails>,
) =>
  useQuery<EmailSetting, ServerResponseErrorDetails>({
    queryKey: settingKeys.email(),
    queryFn: () => getEmailAlarmSetting(),
    ...options,
  });

const getDeviceSetting = async (): Promise<DeviceSetting> => {
  const response = await axiosInstance.get<DeviceSetting>(
    IDEAPI.TEST_DEVICE_SETTING,
  );
  return response.data;
};
export const useDeviceSettingQuery = (
  options?: UseQueryOptions<DeviceSetting, Error>,
) => {
  return useQuery<DeviceSetting, Error>({
    queryKey: settingKeys.device(),
    queryFn: () => getDeviceSetting(),
    ...options,
  });
};

// POST
const postSettingUrl = async (data: UpdateSettings[]): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.POST_APP_URL,
    data,
  );
  return response.data;
};
export const usePostSettingUrlMutation = () => {
  return useMutation<unknown, Error, UpdateSettings[]>({
    mutationFn: (data) => postSettingUrl(data),
  });
};

export const postAppUrl = (data: { key: string; value: string }[]) => {
  return () => axiosLocalInstance.post(IDEAPI.POST_APP_URL, data);
};

interface SendAppUrlMutation extends MutationDefault {
  url: string;
}

export const sendAppUrlMutation = ({
  url,
  onSuccess,
  onError,
}: SendAppUrlMutation) => {
  return useMutation({
    mutationFn: postAppUrl([{ key: 'dashboard_server_url', value: url }]),
    onSuccess: (...params) => {
      if (onSuccess) {
        onSuccess(...params);
      }
    },
    onError: (...params) => {
      if (onError) {
        onError(...params);
      }
    },
  });
};

interface CheckValidateUrlMutation extends MutationDefault {
  applicationUrl: string;
}

export const checkValidateUrlMutation = ({
  applicationUrl,
  onSuccess,
  onError,
}: CheckValidateUrlMutation) => {
  return useMutation({
    mutationFn: () => axios.post(`${applicationUrl}/api/settings/url`),
    onSuccess: (...params) => {
      if (onSuccess) {
        onSuccess(...params);
      }
    },
    onError: (...params) => {
      if (onError) {
        onError(...params);
      }
    },
  });
};

// PUT
const updateEmailAlarm = async (data: UpdateSettings[]): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.EMAIL_ALARM_SETTING,
    data,
  );
  return response.data;
};
export const useUpdateEmailAlarmMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, ServerResponseErrorDetails, UpdateSettings[]>({
    mutationFn: (data) => updateEmailAlarm(data),
    onSuccess: () => {
      queryClient.invalidateQueries(settingKeys.all);
    },
  });
};

const updateDeviceSetting = async (
  data: UpdateSettings[] | [],
): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.TEST_DEVICE_SETTING,
    data,
  );
  return response.data;
};
export const useUpdateDeviceSettingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, UpdateSettings[] | []>({
    mutationFn: (data) => updateDeviceSetting(data),
    onSuccess: () => {
      queryClient.invalidateQueries(settingKeys.all);
    },
  });
};
