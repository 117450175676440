import { Button, ErrorMessage } from '@autosquare/common';

import { useCreateWebTestStepMutation } from '@lib/api/ide/web/webTestStep';

import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

import TestStepLoop from '@components/shared/TestStep/TestStepLoop';

import { CreateWebTestStepData } from '@customTypes/testStep/type';

import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import WebTestStepCommandLine from './WebAddTestStep/WebTestStepCommandLine';
import WebTestStepValueLine from './WebAddTestStep/WebTestStepValueLine';
import ConfirmAndInputText from './WebAddTestStep/ConfirmAndInputText';
import KeyboardEventList from './WebAddTestStep/KeyboardEventList';
import WaitTime from './WebAddTestStep/WaitTime';
import TestStepRequired from './WebAddTestStep/TestStepRequired';
import TestStepDescription from './WebAddTestStep/TestStepDescription';
import IfCommand from './WebAddTestStep/IfCommand';
import ScrollAndSwipe from './WebAddTestStep/ScrollAndSwipe';

const WebAddTestStep = () => {
  const [searchParams] = useSearchParams();
  const caseIdx = Number(searchParams.get('idx'));

  const methods = useForm<CreateWebTestStepData>({
    defaultValues: {
      caseIdx: caseIdx,
    },
  });

  const command = methods.watch('command');

  const needToDefaultValues = () => {
    methods.setValue('optionsDto.waitingTime', '2');
    methods.setValue('required', true);
  };

  useEffect(() => {
    needToDefaultValues();
  }, [methods.setValue]);

  const createWebTestStepMutation = useCreateWebTestStepMutation();

  const removeXpathPrefix = (value: string): string => {
    const xpath = 'xpath=';

    return value?.startsWith(xpath) ? value.slice(xpath.length) : value;
  };

  const onSubmit: SubmitHandler<CreateWebTestStepData> = (data) => {
    data.value = removeXpathPrefix(data.value.toString());

    const fieldsToProcess = [
      'targetElement',
      'conditionValue',
      'passActionValue',
      'failActionValue',
    ];

    fieldsToProcess.forEach((field) => {
      if (field in data.optionsDto) {
        data.optionsDto[field] = removeXpathPrefix(
          data.optionsDto[field]?.toString(),
        );
      }
    });

    createWebTestStepMutation.mutate(data, {
      onSuccess: () => {
        methods.reset();
        needToDefaultValues();
      },
    });
  };

  return (
    <div className="h-full w-1/2">
      <FormProvider {...methods}>
        <form
          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="h-0 flex-1 overflow-auto scrollbar-thin scrollbar-thumb-scrollbar-bg scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
            <div className="bg-congress-blue p-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="text-base font-semibold leading-6 text-white">
                  Add Test Step
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col justify-between">
              <div className="divide-y divide-gray-200 px-4 sm:px-6">
                <div className="space-y-6 pb-5 pt-6 text-sm">
                  <WebTestStepCommandLine />
                  {command === WebCommandOptionServerName.KeyBoardEvent ? (
                    <KeyboardEventList />
                  ) : command === WebCommandOptionServerName.ScrollAndSwipe ? (
                    <ScrollAndSwipe />
                  ) : (
                    command !== WebCommandOptionServerName.IF && (
                      <WebTestStepValueLine />
                    )
                  )}
                  {(command === WebCommandOptionServerName.ConfirmText ||
                    command === WebCommandOptionServerName.InputText) && (
                    <ConfirmAndInputText />
                  )}
                  <TestStepDescription />
                  {command === WebCommandOptionServerName.ScrollAndSwipe && (
                    <TestStepLoop />
                  )}
                  {command === WebCommandOptionServerName.IF ? (
                    <IfCommand />
                  ) : (
                    <TestStepRequired />
                  )}
                  <WaitTime />
                </div>
              </div>
            </div>
          </div>
          <div className="flex shrink-0 items-center justify-between p-4">
            <div />
            {createWebTestStepMutation.isError && (
              <ErrorMessage margin="m-0">
                {createWebTestStepMutation.error.message}
              </ErrorMessage>
            )}
            <Button type="submit" variant="primary">
              Add
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default WebAddTestStep;
