import {
  MobileTestStepCoordinate,
  RectOrTwoPointsCoordinate,
  SourceProperties,
} from '@customTypes/testStep/type';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface PageSource {
  pageSourceObj: SourceProperties[] | null;
  arrowCoordinate: MobileTestStepCoordinate | null;
  selectedCoordinate: RectOrTwoPointsCoordinate;
  selectedNumberArray: string[];
  smsContents: string;
}

const initialState: PageSource = {
  pageSourceObj: [],
  arrowCoordinate: null,
  selectedCoordinate: { x1: 0, y1: 0, x2: 0, y2: 0 },
  selectedNumberArray: [],
  smsContents: '',
};

export const pageSourcesSlice = createSlice({
  name: 'pageSource',
  initialState,
  reducers: {
    getPageSource: (
      state,
      action: PayloadAction<SourceProperties[] | null>,
    ) => {
      state.pageSourceObj = action.payload;
    },
    getArrowCoordinate: (
      state,
      action: PayloadAction<MobileTestStepCoordinate | null>,
    ) => {
      state.arrowCoordinate = action.payload;
    },
    getSelectedCoordinate: (
      state,
      action: PayloadAction<RectOrTwoPointsCoordinate>,
    ) => {
      state.selectedCoordinate = action.payload;
    },
    getSelectedNumberArray: (state, action: PayloadAction<string[]>) => {
      state.selectedNumberArray = action.payload;
    },
    getSmsContents: (state, action: PayloadAction<string>) => {
      state.smsContents = action.payload;
    },
  },
});

export const {
  getPageSource,
  getArrowCoordinate,
  getSelectedCoordinate,
  getSelectedNumberArray,
  getSmsContents,
} = pageSourcesSlice.actions;

export default pageSourcesSlice.reducer;
