import { Button } from '@autosquare/common';
import React from 'react';

interface Props {
  setIsEdited: (value: React.SetStateAction<boolean>) => void;
  isError: boolean;
  error: Error;
}

const TestStepDetailEditButton = ({ setIsEdited, isError, error }: Props) => {
  return (
    <div className="shrink-0 border-b border-gray-200 px-4 py-5 sm:px-6">
      <div className="flex w-full justify-end">
        {isError && <p className="error-message w-full">{error.message}</p>}
        <div className="flex-center gap-3">
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsEdited(false)}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TestStepDetailEditButton;
