import { useGetTestCaseServiceGroupListQuery } from '@lib/api/ide/serviceGroup/serviceGroup';
import { useTestCaseGroupListQuery } from '@lib/api/ide/testGroup/testCaseGroup';
import React from 'react';

const GroupTotal = () => {
  const testGroupListQuery = useTestCaseGroupListQuery();
  const testGroupListCount = testGroupListQuery?.data?.length;

  const serviceGroupListQuery = useGetTestCaseServiceGroupListQuery();
  const serviceGroupListCount = serviceGroupListQuery?.data?.length;

  const totalCount = testGroupListCount + serviceGroupListCount;

  return (
    <div className="flex gap-3 text-sm font-semibold text-gray-900">
      <span>Total</span>
      {testGroupListQuery?.isLoading ||
      testGroupListQuery?.isFetching ||
      serviceGroupListQuery?.isLoading ||
      serviceGroupListQuery?.isFetching ? (
        <span className="h-4 w-5 animate-pulse rounded-md bg-gray-300"></span>
      ) : (
        <span>{totalCount}</span>
      )}
    </div>
  );
};

export default GroupTotal;
