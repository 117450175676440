import { z } from 'zod';

// Mobile
export const BaseMobileSpeedTestScenarioDataSchema = z.object({
  title: z
    .string()
    .min(1, { message: 'Title을 입력해 주세요.' })
    .max(50, { message: '50자 이하로 입력해 주세요.' }),
  description: z.string().max(200, { message: '200자 이하로 입력해 주세요.' }),
  steps: z.array(z.number()).optional(),
});

export const MobileSpeedTestScenarioDataSchema =
  BaseMobileSpeedTestScenarioDataSchema.extend({
    idx: z.number().int(),
    createdAt: z.string(),
    modifiedAt: z.string(),
    caseCount: z.number().int(),
  });

export const CreateMobileSpeedTestScenarioDataSchema =
  BaseMobileSpeedTestScenarioDataSchema.extend({
    projectIdx: z.number().int(),
  });

export const DeleteMobileSpeedTestScenarioDataSchema = z.object({
  idxList: z.array(z.number().int()),
});

export type MobileSpeedTestScenarioData = z.infer<
  typeof MobileSpeedTestScenarioDataSchema
>;

export type CreateMobileSpeedTestScenarioData = z.infer<
  typeof CreateMobileSpeedTestScenarioDataSchema
>;

export type UpdateMobileSpeedTestScenarioData = z.infer<
  typeof CreateMobileSpeedTestScenarioDataSchema
>;

export type DeleteMobileSpeedTestScenarioData = z.infer<
  typeof DeleteMobileSpeedTestScenarioDataSchema
>;

// Web
export const WebSpeedTestScenarioDataSchema =
  MobileSpeedTestScenarioDataSchema.extend({
    step: z.array(z.number()).optional(),
  }).omit({ steps: true });

export const CreateWebSpeedTestScenarioDataSchema =
  BaseMobileSpeedTestScenarioDataSchema.extend({
    testCaseIdxs: z.array(z.number()).optional(),
  }).omit({ steps: true });

export type WebSpeedTestScenarioData = z.infer<
  typeof WebSpeedTestScenarioDataSchema
>;

export type CreateWebSpeedTestScenarioData = z.infer<
  typeof CreateWebSpeedTestScenarioDataSchema
>;

export type UpdateWebSpeedTestScenarioData = z.infer<
  typeof CreateWebSpeedTestScenarioDataSchema
>;

export type DeleteWebSpeedTestScenarioData = z.infer<
  typeof DeleteMobileSpeedTestScenarioDataSchema
>;

// Scenario Drag & Drop Type in Scheduler
export const SpeedTestScenarioDataInSchedulerSchema =
  MobileSpeedTestScenarioDataSchema.pick({ idx: true, title: true }).extend({
    dndIndex: z.number().optional(),
  });

export type SpeedTestScenarioDataInScheduler = z.infer<
  typeof SpeedTestScenarioDataInSchedulerSchema
>;
