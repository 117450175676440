import { useCallback, useEffect, useState } from 'react';

export const useAuthCodeTimer = () => {
  const INITIAL_TIME = 10 * 60 * 1000;
  const [remainingTime, setRemainingTime] = useState<number>(INITIAL_TIME);
  const [timerErrorMessage, setTimerErrorMessage] = useState(null);

  const minutes = String(Math.floor(remainingTime / (1000 * 60))).padStart(
    2,
    '0',
  );

  const seconds = String(Math.floor((remainingTime / 1000) % 60)).padStart(
    2,
    '0',
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prev) => prev - 1000);
    }, 1000);
    if (remainingTime <= 0) {
      setTimerErrorMessage(
        '인증번호가 만료 되었습니다. 인증번호를 다시 전송해 주세요.',
      );
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [remainingTime, setTimerErrorMessage]);

  const resetTimer = useCallback(() => {
    setRemainingTime(INITIAL_TIME);
  }, []);

  const resetErrorMessage = () => {
    setTimerErrorMessage(null);
  };

  return {
    minutes,
    seconds,
    resetTimer,
    timerErrorMessage,
    resetErrorMessage,
  };
};
