import { AppInfoSource } from "@customTypes/testStep/type";
import React from "react";

type Props = {
  refetch: () => void;
  appInfo: AppInfoSource;
};

const ToolButton = ({ refetch, appInfo }: Props) => {
  return (
    <div className="flex flex-col items-center gap-2 ml-4">
      <button type="button" className="rounded px-2 py-1" disabled>
        {appInfo?.secure_flag ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.339 0.237231C8.24363 0.158375 8.12375 0.115234 8 0.115234C7.87625 0.115234 7.75637 0.158375 7.661 0.237231C5.66352 1.89925 3.17862 2.8647 0.583001 2.98723C0.466048 2.99083 0.354061 3.03533 0.266537 3.11299C0.179013 3.19064 0.121497 3.29654 0.104001 3.41223C0.0345536 3.93871 -0.000188118 4.46919 7.66031e-07 5.00023C7.66031e-07 10.1632 3.26 14.5642 7.834 16.2572C7.94114 16.2967 8.05886 16.2967 8.166 16.2572C12.74 14.5642 16 10.1632 16 5.00123C16 4.46123 15.965 3.93123 15.896 3.41123C15.8785 3.29537 15.8208 3.18935 15.7331 3.11167C15.6454 3.034 15.5331 2.9896 15.416 2.98623C12.8207 2.86349 10.3362 1.89905 8.339 0.237231ZM8 4.00023C8.19891 4.00023 8.38968 4.07925 8.53033 4.2199C8.67098 4.36055 8.75 4.55132 8.75 4.75023V8.25023C8.75 8.44914 8.67098 8.63991 8.53033 8.78056C8.38968 8.92121 8.19891 9.00023 8 9.00023C7.80109 9.00023 7.61032 8.92121 7.46967 8.78056C7.32902 8.63991 7.25 8.44914 7.25 8.25023V4.75023C7.25 4.55132 7.32902 4.36055 7.46967 4.2199C7.61032 4.07925 7.80109 4.00023 8 4.00023ZM8 13.0002C8.26522 13.0002 8.51957 12.8949 8.70711 12.7073C8.89464 12.5198 9 12.2654 9 12.0002C9 11.735 8.89464 11.4807 8.70711 11.2931C8.51957 11.1056 8.26522 11.0002 8 11.0002C7.73478 11.0002 7.48043 11.1056 7.29289 11.2931C7.10536 11.4807 7 11.735 7 12.0002C7 12.2654 7.10536 12.5198 7.29289 12.7073C7.48043 12.8949 7.73478 13.0002 8 13.0002Z"
              fill="#EF4444"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.661 2.23753C9.75629 2.15849 9.8762 2.11523 10 2.11523C10.1238 2.11523 10.2437 2.15849 10.339 2.23753C12.3366 3.89918 14.8215 4.86428 17.417 4.98653C17.534 4.99013 17.6459 5.03463 17.7335 5.11229C17.821 5.18994 17.8785 5.29584 17.896 5.41153C17.965 5.93153 18 6.46153 18 7.00153C18 12.1635 14.74 16.5645 10.166 18.2575C10.0589 18.297 9.94114 18.297 9.834 18.2575C5.26 16.5645 2 12.1635 2 7.00053C2 6.46253 2.035 5.93153 2.104 5.41153C2.12153 5.29567 2.17919 5.18965 2.26692 5.11197C2.35465 5.0343 2.46687 4.9899 2.584 4.98653C5.17927 4.86378 7.66379 3.89934 9.661 2.23753ZM13.857 8.19153C13.974 8.03054 14.0222 7.82969 13.991 7.63315C13.9599 7.43661 13.852 7.26049 13.691 7.14353C13.53 7.02657 13.3292 6.97835 13.1326 7.00948C12.9361 7.04061 12.76 7.14854 12.643 7.30953L9.16 12.0995L7.28 10.2195C7.21078 10.1479 7.128 10.0908 7.03647 10.0516C6.94495 10.0123 6.84653 9.99166 6.74694 9.99085C6.64736 9.99003 6.54861 10.0091 6.45646 10.0468C6.3643 10.0846 6.28059 10.1403 6.2102 10.2107C6.13982 10.2812 6.08417 10.365 6.0465 10.4571C6.00883 10.5493 5.9899 10.6481 5.99081 10.7477C5.99173 10.8473 6.01246 10.9457 6.05181 11.0372C6.09116 11.1286 6.14834 11.2114 6.22 11.2805L8.72 13.7805C8.79663 13.8572 8.88896 13.9163 8.99065 13.9539C9.09233 13.9915 9.20094 14.0065 9.30901 13.9981C9.41708 13.9896 9.52203 13.9578 9.61663 13.9049C9.71123 13.852 9.79324 13.7792 9.857 13.6915L13.857 8.19153Z"
              fill="#22C55E"
            />
          </svg>
        )}
      </button>
      <div className="flex-none">
        <button
          className="rounded bg-gray-50 px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
          onClick={refetch}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ToolButton;
