import React from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DetailArrow,
  SpeedTestResultTableHeader,
  Time,
} from '@autosquare/common';
import {
  MobileSpeedTestResultData,
  SpeedExecutionResult,
} from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { timePattern } from '@utils/static/timePattern';
import dayjs from 'dayjs';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useDispatch } from 'react-redux';
import { getClickLocation } from '@store/locationHistorySlice';

type Props = {
  resultData: MobileSpeedTestResultData;
};

const MobileSpeedTestResultsOverviewTable = ({ resultData }: Props) => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const isDashboard =
    !isWordInLocationPathname('speed-test') &&
    !isWordInLocationPathname('dashboard');

  const navigateUrl = (
    resultIdx: number,
    groupIdx: number,
    dateTime: string,
  ) => {
    return isDashboard
      ? `/projects/${projectIdx}/mobile-app/speed/test-result/detail?result=${resultIdx}&group=${groupIdx}&start=${dayjs(dateTime).subtract(14, 'days').format('YYYYMMDD')}&end=${dayjs(dateTime).format('YYYYMMDD')}`
      : `/ide/projects/${projectIdx}/speed-test/mobile/test-result/detail?result=${resultIdx}&group=${groupIdx}&start=${dayjs(dateTime).subtract(14, 'days').format('YYYYMMDD')}&end=${dayjs(dateTime).format('YYYYMMDD')}`;
  };

  const resultWithUUID = resultData?.content?.map((item) => ({
    ...item,
    index: crypto.randomUUID(),
  }));

  return (
    <div className="flex flex-col gap-4">
      {resultWithUUID?.map((item) => (
        <div key={item.index}>
          <div className="flex items-center justify-between leading-tight">
            <div className="mb-3 text-sm font-semibold">
              {item.group} Loading Time 비교측정 결과
            </div>
            <div
              className={clsx(
                'font-semibold',
                item.resultEnum === 'RUNNING'
                  ? 'text-gray-400'
                  : 'text-gray-900',
              )}
            >
              <button
                type="button"
                className="flex items-center justify-center text-xs"
                disabled={item.resultEnum === 'RUNNING'}
                onClick={() => {
                  dispatch(getClickLocation(pathname + search));
                  navigate(
                    navigateUrl(item.resultIdx, item.groupIdx, item.dateTime),
                    {
                      state: { status: item.resultEnum, page: 1 },
                    },
                  );
                }}
              >
                Detail
                <DetailArrow />
              </button>
            </div>
          </div>
          <div className="gray-xy-scrollbar">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-50">
                <tr className="border-b border-gray-200">
                  <SpeedTestResultTableHeader width="w-40">
                    Type
                  </SpeedTestResultTableHeader>
                  <SpeedTestResultTableHeader width="w-40">
                    DateTime
                  </SpeedTestResultTableHeader>
                  {item.loadSpeedData.map((data, index) => (
                    <SpeedTestResultTableHeader key={index}>
                      {data.name}
                    </SpeedTestResultTableHeader>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-400">
                  <td className="px-4 py-2 text-sm text-gray-900">
                    {item.testType}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-900">
                    <div>{item?.dateTime?.split(' ')[0] ?? '-'}</div>
                    <div
                      className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                    >
                      {item?.dateTime && <Time />}
                      {RegExp(timePattern).exec(item.dateTime)[0]}
                    </div>
                  </td>
                  {item.loadSpeedData.map((data, index) => (
                    <td
                      key={index}
                      className={clsx(
                        'px-4 py-2 text-sm',
                        data.value > 3 ? 'text-red-500' : 'text-gray-900',
                      )}
                    >
                      {item.resultEnum === SpeedExecutionResult.Pass
                        ? data.value
                        : item.resultEnum === SpeedExecutionResult.Na
                          ? 'N/A'
                          : item.resultEnum === SpeedExecutionResult.Fail
                            ? 'Fail'
                            : 'Running'}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileSpeedTestResultsOverviewTable;
