import { DescriptionListSkeleton, ErrorMessage } from '@autosquare/common';

import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { RootState } from '@app/store';

import { useMobileTestStepQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import { ClickId } from '@utils/static/clickOptions';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import { useGetSpeedMobileTestStepDetail } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import TestStepDetailEdit from './TestStepDetail/TestStepDetailEdit';
import TestStepDetailBasic from './TestStepDetail/TestStepDetailBasic';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';

interface Props {
  isDialogOpened: boolean;
  setIsDialogOpened: React.Dispatch<React.SetStateAction<boolean>>;
  mobileTestStep: MobileTestStepData;
  mobileTestStepList: MobileTestStepData[];
}

const TestStepDetail = ({
  isDialogOpened,
  setIsDialogOpened,
  mobileTestStep,
  mobileTestStepList,
}: Props) => {
  const { projectIdx } = useParams();
  const location = isWordInLocationPathname('ui-test')
    ? 'ui-test'
    : 'speed-test';

  const [isEdited, setIsEdited] = useState(false);
  const testStepIdx = useSelector(
    (state: RootState) => state.mobileTestStepDetail.testStepIdx,
  );

  const mobileTestStepQuery =
    location === 'ui-test'
      ? useMobileTestStepQuery(testStepIdx, {
          enabled: isDialogOpened,
        })
      : useGetSpeedMobileTestStepDetail(testStepIdx, {
          enabled: isDialogOpened,
        });

  const mobileTestStepData = mobileTestStepQuery.data as MobileTestStepData;

  const closeDialog = () => {
    setIsDialogOpened(false);
    const timer = setTimeout(() => {
      setIsEdited(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  const mobileTestCaseListQuery = useMobileTestCaseListQuery(projectIdx, {
    enabled: location === 'ui-test',
  });

  const speedMobileCaseListQuery = useSpeedMobileTestCaseListQuery({
    enabled: location === 'speed-test',
  });

  const mobileTestCaseList =
    location === 'ui-test'
      ? mobileTestCaseListQuery.data
      : speedMobileCaseListQuery.data;

  const testStepDetailListValue =
    (mobileTestStep.optionsDto.clickBy === ClickId.Coordinate &&
      mobileTestStep.command === MobileCommandOptionServerName.Click) ||
    mobileTestStep.command === MobileCommandOptionServerName.ScrollSwipe ? (
      `[${mobileTestStep.value}]`
    ) : (mobileTestStep.command === MobileCommandOptionServerName.ConfirmText ||
        mobileTestStep.command === MobileCommandOptionServerName.InputText) &&
      mobileTestStep.optionsDto.compareBy === 'text' &&
      mobileTestStep.optionsDto.textEncrypt === 'true' ? (
      `${mobileTestStep.value}\ntarget text="**********"`
    ) : mobileTestStep.optionsDto.compareBy === 'text' &&
      mobileTestStep.optionsDto.textEncrypt === 'false' ? (
      `${mobileTestStep.value}\ntarget text="${mobileTestStep.optionsDto.compareText}"`
    ) : (mobileTestStep.command === MobileCommandOptionServerName.ConfirmText ||
        mobileTestStep.command === MobileCommandOptionServerName.InputText) &&
      mobileTestStep.optionsDto.compareBy === 'id' ? (
      `${mobileTestStep.value}\ntarget text=#${
        mobileTestStepList
          ?.map((item) => item?.idx)
          .indexOf(parseInt(mobileTestStep.optionsDto.compareIdx)) + 1
      }`
    ) : mobileTestStep.command === MobileCommandOptionServerName.DeviceKey ? (
      findListNameFromServerName(mobileTestStep.value as DeviceKeyServerName)
    ) : mobileTestStep.command === MobileCommandOptionServerName.Loop ? (
      <>
        <p>
          Condition=&quot;
          {findMobileCommandNameFromServerName(
            mobileTestStep.optionsDto.conditionCommand,
          )}
          &quot;
        </p>
        <p>
          Action=&quot;
          {findMobileCommandNameFromServerName(
            mobileTestStep.value as MobileCommandOptionServerName,
          )}
          &quot;
        </p>
      </>
    ) : mobileTestStep.command === MobileCommandOptionServerName.IF ? (
      <>
        <div>
          <p>Condition</p>
          <p>
            {findMobileCommandNameFromServerName(
              mobileTestStep.optionsDto.conditionCommand,
            )}
            ,{mobileTestStep.optionsDto.conditionValue}
          </p>
        </div>
        <br />
        <div>
          <p>PASS</p>
          {findMobileCommandNameFromServerName(
            mobileTestStep.value.split(',')[0] as MobileCommandOptionServerName,
          )}
          , {mobileTestStep.optionsDto.passActionValue}
        </div>
        <br />
        <div>
          <p>FAIL</p>
          {mobileTestStep.value.split(',')[1] !== undefined &&
          mobileTestStep.optionsDto.failActionValue !== undefined
            ? `${findMobileCommandNameFromServerName(
                mobileTestStep.value.split(
                  ',',
                )[1] as MobileCommandOptionServerName,
              )}, ${mobileTestStep.optionsDto.failActionValue}`
            : 'None'}
        </div>
      </>
    ) : mobileTestStep.command ===
      MobileCommandOptionServerName.ImportTestCase ? (
      mobileTestCaseList?.find(
        (mobileTestCase) => mobileTestCase.idx === Number(mobileTestStep.value),
      )?.title
    ) : (
      mobileTestStep.value
    );
  return (
    <Transition show={isDialogOpened} as={Fragment}>
      <Dialog as="div" className="relative z-50" static onClose={closeDialog}>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-[43.75rem]">
                  <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl scrollbar-thin">
                    <div className="bg-congress-blue px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <DialogTitle className="text-base font-semibold leading-6 text-white">
                          Test Step Detail
                        </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-congress-blue text-indigo-200 hover:text-white"
                            onClick={closeDialog}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="size-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {mobileTestStepQuery.isLoading ||
                    mobileTestStepQuery.isFetching ? (
                      <DescriptionListSkeleton
                        number={5}
                        isButtonSkeleton={true}
                      />
                    ) : mobileTestStepQuery.isError ? (
                      <ErrorMessage>
                        {mobileTestStepQuery.error.message}
                      </ErrorMessage>
                    ) : isEdited ? (
                      <TestStepDetailEdit
                        setIsEdited={setIsEdited}
                        mobileTestStepData={mobileTestStepData}
                        isEdited={isEdited}
                      />
                    ) : (
                      <TestStepDetailBasic
                        mobileTestStepData={mobileTestStepData}
                        setIsEdited={setIsEdited}
                        closeDialog={closeDialog}
                        testStepDetailListValue={testStepDetailListValue}
                      />
                    )}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TestStepDetail;
