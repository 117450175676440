import { CardListSkeleton, ErrorMessage, useInput } from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import DetailListItemCard from './DetailDraggable/DetailListItemCard';
import DetailPaste from './DetailDraggable/DetailPaste';
import {
  TestCaseDetailData,
  TestCaseDetailDataWithoutSteps,
} from '@customTypes/testCase/type';

const DetailDraggable = () => {
  const { projectIdx } = useParams();

  const [copyTestCases, setCopyTestCases] = useState<
    TestCaseDetailDataWithoutSteps[] | null
  >([]);
  const [testCases, setTestCases] = useState<
    TestCaseDetailDataWithoutSteps[] | null
  >([]);

  const testCaseListQuery = isWordInLocationPathname('mobile')
    ? useMobileTestCaseListQuery(projectIdx)
    : useWebTestCaseListQuery();

  const allTestCases = testCaseListQuery.data;

  const testCaseIndexArrayInDetail = useSelector(
    (state: RootState) => state.numberArray.testCaseIndexArrayInDetail,
  );

  const stepArrayLists = testCaseIndexArrayInDetail?.map((step) =>
    (allTestCases as Partial<TestCaseDetailData[]>)?.find(
      (allTestCase) => allTestCase.idx === step,
    ),
  );

  const changeCardList = (list: TestCaseDetailDataWithoutSteps[]) => {
    setCopyTestCases([...list]);
  };

  useEffect(() => {
    setCopyTestCases(
      stepArrayLists?.map((stepArrayList, index) => ({
        ...stepArrayList,
        dndIndex: index + 1,
      })),
    );
    setTestCases(
      allTestCases?.map((allTestCase, index) => ({
        ...allTestCase,
        dndIndex: stepArrayLists?.length + index + 1,
      })),
    );
  }, [allTestCases, testCaseIndexArrayInDetail]);

  const [searchWord, onChangeSearchWord, setSearchWord] = useInput('');

  const searchResultArray = testCases?.filter((testCase) =>
    testCase.title.toLowerCase().includes(searchWord.toLowerCase()),
  );

  const copyAndDropList = [
    {
      title: 'All Test Case List',
      list:
        testCases?.length === 0 ? (
          <div className="flex h-full items-center justify-center">
            추가 가능한 테스트 케이스가 존재하지 않습니다.
          </div>
        ) : (
          searchResultArray?.map((testCase) => (
            <DetailListItemCard
              copyTestCases={copyTestCases}
              idx={testCase.idx}
              title={testCase.title}
              priority={testCase.priority}
              updatedAt={testCase.updatedAt}
              changeCardList={changeCardList}
              key={testCase.idx}
            />
          ))
        ),
    },
    {
      title: 'Scenario Test Case List',
      list: (
        <DetailPaste
          copyTestCases={copyTestCases}
          setCopyTestCases={setCopyTestCases}
          changeCardList={changeCardList}
        />
      ),
    },
  ];

  return (
    <>
      {testCaseListQuery.isLoading || testCaseListQuery.isFetching ? (
        <div className="flex size-full items-center justify-between">
          <CardListSkeleton />
          <CardListSkeleton />
        </div>
      ) : testCaseListQuery.isError ? (
        <ErrorMessage>{testCaseListQuery.error.message}</ErrorMessage>
      ) : (
        <div className="flex w-full justify-between gap-4">
          {copyAndDropList?.map((item, index) => (
            <div className="w-full" key={item.title}>
              <p className="mb-3 font-medium leading-6">{item.title}</p>
              <div className="h-[calc(100vh-21rem)] overflow-y-auto rounded-md border border-solid border-border-line scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
                {index === 0 && (
                  <div className="relative w-full px-2 pb-1.5 pt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="absolute left-4 top-4 size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                    <input
                      className="input-sm px-8"
                      value={searchWord}
                      onChange={onChangeSearchWord}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="absolute right-4 top-4 size-4 cursor-pointer"
                      onClick={() => setSearchWord('')}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                )}
                {item.list}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DetailDraggable;
