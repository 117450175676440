import React from 'react';

import LoginForm from './components/LoginForm';

import tsquare_logo from '@assets/img/tsquare_logo.png';
import { Link } from 'react-router-dom';

const Login = () => {
  return (
    <div className="mt-20 min-h-full w-96">
      <div className="mb-8 flex justify-center">
        <img
          src={tsquare_logo}
          alt="tsquare_logo"
          className="w-60"
          width={240}
          height={42}
        />
      </div>
      <LoginForm />
      <hr className="my-6 border-t border-solid border-border-lightGray" />
      <div className="text-center">
        <Link to={'/forgot-password'}>
          <button type="button" className="text-allports">
            비밀번호를 잊으셨나요?
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Login;
