import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DefaultSkeleton,
  ErrorMessage,
  Pagination,
  PagingHeadings,
  SpeedTestResultTableSkeleton,
} from '@autosquare/common';

import { useWebSpeedTestResultListQuery } from '@lib/api/dashboard/web/webSpeedResult';
import WebSpeedTestResultList from '@components/shared/WebSpeedTestResultList/WebSpeedTestResultList';

const WebSpeedTestResult = () => {
  const PAGE_LIMIT = 5;
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));

  const webSpeedTestResultListQuery = useWebSpeedTestResultListQuery(page);
  const webSpeedTestResultList = webSpeedTestResultListQuery.data;
  const currentUrl = location.pathname + location.search;

  const breadcrumbs = [
    { title: 'Speed Test', href: currentUrl },
    { title: 'Web', href: currentUrl },
    { title: 'Speed Test Result', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Speed Test Result List'}
      />
      {webSpeedTestResultListQuery.isLoading ? (
        <SpeedTestResultTableSkeleton />
      ) : webSpeedTestResultListQuery.isError ? (
        <ErrorMessage>{webSpeedTestResultListQuery.error.message}</ErrorMessage>
      ) : webSpeedTestResultList?.totalCount === 0 ? (
        <p className="py-8 text-center text-sm font-normal leading-tight text-gray-900">
          진행된 테스트 결과가 없습니다.
        </p>
      ) : (
        <WebSpeedTestResultList
          webSpeedTestResultList={webSpeedTestResultList}
        />
      )}
      {webSpeedTestResultListQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        webSpeedTestResultList?.totalCount > 5 && (
          <Pagination
            page={page}
            total={webSpeedTestResultList?.totalCount}
            size={PAGE_LIMIT}
            isDashboard={false}
          />
        )
      )}
    </>
  );
};

export default WebSpeedTestResult;
