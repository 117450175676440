import {
  MobileTestResultPagingData,
  TestResult,
  TestResultDetailInquiryByCase,
  TestResultInquiryByCase,
} from '@customTypes/testResult/type';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { axiosInstance, axiosMediaInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { mobileTestResultKeys } from '@queries/ide/mobile/mobileTestResultKeys';

// GET
const mobileTestResultList = async (
  projectIdx: string,
  page: number,
  size: number,
): Promise<MobileTestResultPagingData> => {
  const response = await axiosInstance.get<MobileTestResultPagingData>(
    IDEAPI.getMobileTestResultList(projectIdx, page, size),
  );
  return response.data;
};
export const useMobileTestResultListQuery = (
  projectIdx: string,
  page: number,
  size: number,
) =>
  useQuery<MobileTestResultPagingData, Error>({
    queryKey: mobileTestResultKeys.list(projectIdx, page, size),
    queryFn: () => mobileTestResultList(projectIdx, page, size),
    keepPreviousData: true,
  });

const mobileTestResultDetail = async (
  testResultIdx: string,
): Promise<TestResultInquiryByCase> => {
  const response = await axiosInstance.get<TestResultInquiryByCase>(
    IDEAPI.getMobileTestResultDetail(testResultIdx),
  );
  return response.data;
};
export const useMobileTestResultDetailQuery = (idx: string) => {
  return useQuery<TestResultInquiryByCase, Error>({
    queryKey: mobileTestResultKeys.detail(idx),
    queryFn: () => mobileTestResultDetail(idx),
  });
};

const mobileTestResultDetailInfo = async (
  testResultIdx: string,
): Promise<TestResult> => {
  const response = await axiosInstance.get<TestResult>(
    IDEAPI.getMobileTestResultDetailInfo(testResultIdx),
  );
  return response.data;
};
export const useMobileTestResultDetailInfoQuery = (idx: string) => {
  return useQuery<TestResult, Error>({
    queryKey: mobileTestResultKeys.detailInfo(idx),
    queryFn: () => mobileTestResultDetailInfo(idx),
  });
};

//image
const mobileTestResultImageData = async (path: string[]): Promise<string[]> => {
  const requests = path.map((path) =>
    axiosMediaInstance.get<MediaSource>(path, {
      responseType: 'blob',
    }),
  );

  const responses = await Promise.all(requests);
  const objectUrls = responses.map((response) =>
    URL.createObjectURL(response.data),
  );

  return objectUrls;
};

export const useMobileTestResultImageData = (
  path: string[],
  options?: UseQueryOptions<string[], Error>,
) => {
  return useQuery<string[], Error>({
    queryKey: mobileTestResultKeys.detailImage(path[0]),
    queryFn: () => mobileTestResultImageData(path),
    ...options,
  });
};

//video
const mobileTestResultVideoAndLogData = async (
  path: string,
): Promise<string> => {
  const response = await axiosMediaInstance.get<MediaSource | Blob>(path, {
    responseType: 'blob',
  });

  const objectUrl = URL.createObjectURL(response.data);
  return objectUrl;
};

export const useMobileTestResultVideoData = (
  path: string,
  options?: UseQueryOptions<string, Error>,
) => {
  return useQuery<string, Error>({
    queryKey: mobileTestResultKeys.detailVideo(path),
    queryFn: () => mobileTestResultVideoAndLogData(path),
    ...options,
  });
};

//log
export const useMobileTestResultLogData = (
  path: string,
  options?: UseQueryOptions<string, Error>,
) => {
  return useQuery<string, Error>({
    queryKey: mobileTestResultKeys.detailLog(path),
    queryFn: () => mobileTestResultVideoAndLogData(path),
    ...options,
  });
};

const mobileTestCaseExecutionDetail = async (
  idx: string,
  caseResultIdx: number,
): Promise<TestResultDetailInquiryByCase[]> => {
  const response = await axiosInstance.get<TestResultDetailInquiryByCase[]>(
    IDEAPI.getMobileTestCaseExecutionDetail(idx, caseResultIdx),
  );
  return response.data;
};
export const useMobileTestCaseExecutionDetailQuery = (
  idx: string,
  caseResultIdx: number,
) =>
  useQuery<TestResultDetailInquiryByCase[], Error>({
    queryKey: mobileTestResultKeys.executionDetail(idx, caseResultIdx),
    queryFn: () => mobileTestCaseExecutionDetail(idx, caseResultIdx),
    refetchOnMount: true,
    staleTime: 1000 * 20,
  });
