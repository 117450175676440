import { Breadcrumbs, PagingHeadings } from '@autosquare/common';
import React from 'react';
import SettingsEmailForm from '@components/SettingsAndHelpModal/components/Settings/Alarm/SettingsEmailForm';
type Props = {
  closeModal: () => void;
};
const Alarm = ({ closeModal }: Props) => {
  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Settings', href: '' },
    { title: 'Alarm', href: '' },
  ];
  return (
    <main className="gray-scrollbar w-full py-5 pr-10">
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Alarm Settings'} />
      <SettingsEmailForm closeModal={closeModal} />
    </main>
  );
};

export default Alarm;
