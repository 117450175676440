import { RootState } from '@app/store';

import {
  Breadcrumbs,
  Button,
  ErrorMessage,
  PagingHeadingsNavigate,
  useInput,
} from '@autosquare/common';

import {
  checkValidateUrlMutation,
  sendAppUrlMutation,
} from '@lib/api/ide/setting/settings';

import { clearIdeAccessToken } from '@store/api/accessTokenSlice';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ApplicationAlert from './ApplicationAlert';

type Props = {
  closeModal: () => void;
};

const Application = ({ closeModal }: Props) => {
  const [url, onChangeUrl, setUrl] = useInput('');
  const [isValidateUrlError, setIsValidateUrlError] = useState(false);

  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  const applicationUrl = localStorage.getItem('applicationUrl');

  useEffect(() => {
    setUrl(applicationUrl);
  }, [applicationUrl]);

  const sendAppUrl = sendAppUrlMutation({
    url: url,
    onSuccess: async () => {
      if (accessToken) {
        dispatch(clearIdeAccessToken());
      }
      window.electron.send('editServerUrl', url);
    },
  });

  const checkValidateUrl = checkValidateUrlMutation({
    applicationUrl: url,
    onSuccess: () => {
      sendAppUrl.mutate();
      setIsValidateUrlError(false);
    },
    onError: () => {
      setIsValidateUrlError(true);
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (applicationUrl === url) {
      closeModal();
    } else {
      checkValidateUrl.mutate();
    }
  };

  useEffect(() => {
    if (url?.endsWith('/')) {
      setUrl(url.slice(0, -1));
    }
  }, [url]);

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Settings', disabled: true },
    { title: 'Application', disabled: true },
  ];

  return (
    <div className="relative w-full">
      {isValidateUrlError && (
        <ApplicationAlert
          status="error"
          message="유효하지 않은 대시보드 서버 URL 입니다."
          onClick={() => setIsValidateUrlError(false)}
        />
      )}
      <main className="w-full pr-10 pt-5">
        <div className="flex grow flex-col">
          <PagingHeadingsNavigate
            breadcrumbs={breadcrumbs}
            pageTitle={'Application Settings'}
          />
          <form className="mt-10" onSubmit={handleSubmit}>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Dashboard Server URL
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              로그인, 테스트 데이터 동기화 등 Dashboard 서버와 연동을 위한
              Server URL을 입력해주세요.
            </p>
            <div className="mt-5">
              <label htmlFor="dashboard_url" className="sr-only">
                Dashboard URL
              </label>
              <input
                name={'dashboard_url'}
                id={'dasboard_url'}
                onChange={onChangeUrl}
                placeholder={'http://localhost:8080'}
                className="input-base"
                defaultValue={applicationUrl}
                pattern="https?://.+"
                title="Please enter a valid URL"
                required
              />
            </div>
            <div className="sm:flex-center mt-6 flex flex-col items-end justify-between gap-y-3 sm:flex-row sm:gap-x-6">
              <div className="hidden sm:visible" />
              <ErrorMessage margin={'m-0'}>
                {sendAppUrl?.isError && (sendAppUrl.error as string)}
              </ErrorMessage>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default Application;
