import { Breadcrumbs, PagingHeadingsNavigate } from '@autosquare/common';

import React from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import DeviceSettingsProvider from '@contexts/ide/DeviceSettingsProvider';

import DeviceSettingsForm from './components/DeviceSettingsForm';

const DeviceSettings = () => {
  const { projectIdx } = useParams();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const previousUrl = `/ide/projects/${projectIdx}/ui-test/mobile/test-case/device-list?idx=${idx}`;

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Mobile',
      href: `/ide/projects/${projectIdx}/ui-test/mobile/test-case?page=1`,
    },
    {
      title: 'Device Settings',
      href: previousUrl,
    },
    { title: 'iOS', href: pathname + search },
  ];

  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Device Settings - iOS'}
      />
      <DeviceSettingsProvider>
        <DeviceSettingsForm previousUrl={previousUrl} />
      </DeviceSettingsProvider>
    </>
  );
};

export default DeviceSettings;
