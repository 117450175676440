import {
  CreatePlusButton,
  GroupManagement,
  PageHeadingButtons,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import GroupContainer from '@components/shared/TestCaseGroup/GroupContainer';

import GroupProvider from '@contexts/ide/GroupProvider';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import WebSpeedTestCaseList from './components/WebSpeedTestCaseList';

const WebSpeedTestCase = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const [isGroupOpened, setIsGroupOpened] = useState(false);

  const currentUrl = pathname + search;

  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Web', href: currentUrl },
    { title: 'Test Case', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      icon: <CreatePlusButton />,
      variant: 'imageBasic',
      description: 'Create Case',
      onClick: () => navigate('create'),
    },
    {
      icon: <GroupManagement />,
      variant: 'imageBasic',
      description: 'Group Management',
      onClick: () => setIsGroupOpened(true),
    },
  ];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <WebSpeedTestCaseList />
      <GroupProvider>
        {isGroupOpened && <GroupContainer closeModal={setIsGroupOpened} />}
      </GroupProvider>
    </>
  );
};

export default WebSpeedTestCase;
