import {
  Checkbox,
  Detail,
  Execution,
  TableBodyBasic,
  TableHeader,
  TableNoList,
  TableWrapper,
  Time,
  useCheckbox,
} from '@autosquare/common';
import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';
import DeleteDialogInSpeedTestScenarioList from '@components/SpeedTestScenario/DeleteDialogInSpeedTestScenarioList';
import ErrorMessageInTableBody from '@components/SpeedTestScenario/ErrorMessageInTableBody';
import TableSkeleton from '@components/SpeedTestScenario/TableSkeleton';
import {
  useDeleteSpeedWebTestScenarioMutation,
  useSpeedWebTestScenarioListQuery,
} from '@lib/api/ide/speedWeb/webSpeedTestScenario';
import { timePattern } from '@utils/static/timePattern';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WebSpeedTestScenarioTable = () => {
  const navigate = useNavigate();

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testScenarioIdx, setTestScenarioIdx] = useState<number>(0);

  const speedWebTestScenarioListQuery = useSpeedWebTestScenarioListQuery();
  const speedWebTestScenarioList = speedWebTestScenarioListQuery.data;

  const deleteSpeedWebTestScenarioMutation =
    useDeleteSpeedWebTestScenarioMutation();

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(speedWebTestScenarioList, 'idx');

  const tableHeaders = [
    {
      idx: 1,
      header: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          key="selected-checkbox"
        />
      ),
    },
    { idx: 2, header: 'No' },
    { idx: 3, header: selectedArray.length > 0 ? '     ' : 'Title' },
    { idx: 4, header: 'Test Case Count' },
    { idx: 5, header: 'Updated at' },
    { idx: 6, header: '' },
    { idx: 7, header: '' },
  ];

  return (
    <>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {tableHeaders.map((tableHeader) => (
              <TableHeader key={tableHeader.idx}>
                {tableHeader.header}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {speedWebTestScenarioListQuery.isLoading ||
          speedWebTestScenarioListQuery.isFetching ? (
            <TableSkeleton dataLength={tableHeaders.length} />
          ) : speedWebTestScenarioListQuery.isError ? (
            <ErrorMessageInTableBody colSpan={tableHeaders.length}>
              {speedWebTestScenarioListQuery.error.message}
            </ErrorMessageInTableBody>
          ) : speedWebTestScenarioList?.length === 0 ? (
            <TableNoList colSpan={tableHeaders.length}>
              해당 프로젝트에 생성된 테스트 시나리오가 존재하지 않습니다.
            </TableNoList>
          ) : (
            speedWebTestScenarioList?.map((speedWebTestScenario, idx) => (
              <tr key={speedWebTestScenario.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(speedWebTestScenario.idx)}
                    onChange={(e) => selectArray(speedWebTestScenario.idx, e)}
                  />
                </TableBodyBasic>
                <TableBodyBasic>{idx + 1}</TableBodyBasic>
                <TableBodyBasic>
                  <div className="font-medium text-gray-900">
                    {speedWebTestScenario.title}
                  </div>
                  <div className="mt-1 text-gray-500">
                    {speedWebTestScenario.description}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  {speedWebTestScenario.caseCount}
                </TableBodyBasic>
                <TableBodyBasic>
                  <div>
                    {speedWebTestScenario.modifiedAt.split(' ')[0] ?? '-'}
                  </div>
                  <div
                    className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                  >
                    {speedWebTestScenario.modifiedAt && <Time />}
                    {speedWebTestScenario.modifiedAt.match(timePattern)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    testType="scenario"
                    tooltipLocation="right"
                    disabled={speedWebTestScenario.caseCount === 0}
                    onClick={() => {
                      if (speedWebTestScenario.caseCount > 0) {
                        setTestScenarioIdx(speedWebTestScenario.idx);
                        setIsTestExecutionOpened(true);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: 'detail',
                        search: `idx=${speedWebTestScenario.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </tbody>
      </TableWrapper>
      <DeleteDialogInSpeedTestScenarioList
        isOpenedCautionDialog={isOpenedCautionDialog}
        setIsOpenedCautionDialog={setIsOpenedCautionDialog}
        deleteSpeedMobileTestScenarioMutation={
          deleteSpeedWebTestScenarioMutation
        }
        selectedArray={selectedArray}
        openDialog={openDialog}
        closeDialog={closeDialog}
        resetSelectedArray={resetSelectedArray}
      />
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testScenarioIdx}
          testType={'scenario'}
          deviceType={'web'}
          testLocation={'speed-test'}
        />
      )}
    </>
  );
};

export default WebSpeedTestScenarioTable;
