import {
  AdminUserListData,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';

import ProjectEditAdmin from './ProjectEditAdmin';

interface Props {
  adminUserListData: AdminUserListData[];
}

const ProjectEditInformation = ({ adminUserListData }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();

  const inputValues = [
    {
      labelName: 'Project name(수정 불가)',
      id: 'name',
      register: {
        ...register('name'),
      },
      disabled: true,
    },
    {
      labelName: 'Service name(옵션 - 0~100자 입력 가능)',
      id: 'serviceName',
      placeHolder: 'Service name',
      register: {
        ...register('serviceName', {
          maxLength: { value: 100, message: '100자 이하 입력 가능합니다.' },
        }),
      },
    },
    {
      labelName: 'Description(옵션 - 0~100자 입력 가능)',
      id: 'description',
      placeHolder: 'Description',
      register: {
        ...register('description', {
          maxLength: { value: 100, message: '100자 이하 입력 가능합니다.' },
        }),
      },
    },
  ];

  return (
    <div className="border-b border-gray-900/10 pb-6">
      <div>
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Project Information
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          프로젝트 정보를 입력해주세요.
        </p>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-6">
        {inputValues?.map((value) => (
          <div className="col-span-full" key={value.id}>
            <label
              htmlFor={value.id}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {value.labelName}
            </label>
            <div className="mt-2">
              <input
                type="text"
                name={value.id}
                id={value.id}
                className={clsx('input-base', {
                  'bg-gray-50': value.disabled,
                })}
                placeholder={value.placeHolder}
                disabled={value.disabled}
                {...value.register}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name={value.id}
              render={({ message }) => (
                <p className="error-message py-1.5">{message}</p>
              )}
            />
          </div>
        ))}
        <ProjectEditAdmin adminUserListData={adminUserListData} />
      </div>
    </div>
  );
};

export default ProjectEditInformation;
