import { RootState } from '@app/store';

import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  TestCaseDetailSkeleton,
} from '@autosquare/common';

import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';

import { UpdateWebSpeedTestCaseInfo } from '@customTypes/ide/speedTest/Web/testCase/type';
import {
  useDeleteWebSpeedTestCaseMutation,
  useUpdateWebSpeedTestCaseMutation,
  useWebSpeedTestCaseDetailQuery,
} from '@lib/api/ide/speedWeb/webSpeedTestCase';

import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import WebSpeedTestCaseInfoDefault from './WebSpeedTestCaseInformation/WebSpeedTestCaseInfoDefault';
import WebSpeedTestCaseInfoEdit from './WebSpeedTestCaseInformation/WebSpeedTestCaseInfoEdit';

const WebSpeedTestCaseInformation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const [isEdited, setIsEdited] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );

  const webSpeedTestCaseDetailQuery = useWebSpeedTestCaseDetailQuery();
  const webSpeedTestCaseDetailData = webSpeedTestCaseDetailQuery.data;

  const updateWebSpeedTestCaseMutation = useUpdateWebSpeedTestCaseMutation();

  const deleteWebSpeedTestCaseMutation = useDeleteWebSpeedTestCaseMutation();

  const method = useForm<UpdateWebSpeedTestCaseInfo>({
    defaultValues: {
      projectIdx: projectIdx,
    },
  });

  useEffect(() => {
    method.reset({
      title: webSpeedTestCaseDetailData?.title,
      description: webSpeedTestCaseDetailData?.description,
      priority: webSpeedTestCaseDetailData?.priority,
      caseGroupIdx: webSpeedTestCaseDetailData?.caseGroupInfo?.caseGroupIdx,
      serviceGroupIdx:
        webSpeedTestCaseDetailData?.serviceGroupInfo?.serviceGroupIdx,
    });
  }, [webSpeedTestCaseDetailData]);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const closeEdit = () => {
    setIsEdited(false);
    method.reset();
  };

  const onSubmit: SubmitHandler<UpdateWebSpeedTestCaseInfo> = (data) => {
    updateWebSpeedTestCaseMutation.mutate(data, {
      onSuccess: () => {
        setIsEdited(false);
      },
    });
  };

  const idx: number = Number(searchParams.get('idx'));

  return (
    <FormProvider {...method}>
      <form onSubmit={method.handleSubmit(onSubmit)}>
        <div className="mt-8 pb-5 sm:flex sm:items-center sm:justify-between">
          <h2 className="text-base font-semibold leading-6 text-gray-900">
            Test Case Information
          </h2>
          <div className="mt-3 flex gap-3 sm:ml-4  sm:mt-0">
            {isEdited ? (
              <>
                <Button type="button" variant="secondary" onClick={closeEdit}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={updateWebSpeedTestCaseMutation.isLoading}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => setIsEdited(true)}
                >
                  Edit
                </Button>
                <Button type="button" variant="caution" onClick={openModal}>
                  Delete
                </Button>
              </>
            )}
          </div>
        </div>
        {updateWebSpeedTestCaseMutation.isError ? (
          <ErrorMessage>
            updateWebSpeedTestCaseMutation.error.message
          </ErrorMessage>
        ) : (
          deleteWebSpeedTestCaseMutation.isError && (
            <ErrorMessage>
              deleteWebSpeedTestCaseMutation.error.message
            </ErrorMessage>
          )
        )}
        {webSpeedTestCaseDetailQuery.isLoading ||
        webSpeedTestCaseDetailQuery.isFetching ? (
          <TestCaseDetailSkeleton testCategory={'speed'} platform={'web'} />
        ) : isEdited ? (
          <WebSpeedTestCaseInfoEdit />
        ) : (
          <WebSpeedTestCaseInfoDefault />
        )}
      </form>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={'Delete Test Case'}
        subTitle={'해당 테스트 케이스를 삭제하시겠습니까?'}
        buttonChildren={
          <>
            <Button
              type="button"
              variant="caution"
              onClick={() =>
                deleteWebSpeedTestCaseMutation.mutate(
                  { idxList: [idx] },
                  {
                    onSuccess: () => {
                      closeModal();
                      navigate({
                        pathname: pathname.split('/').slice(0, -1).join('/'),
                        search: 'page=1',
                      });
                    },
                  },
                )
              }
            >
              Delete
            </Button>
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </>
        }
      />
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
    </FormProvider>
  );
};

export default WebSpeedTestCaseInformation;
