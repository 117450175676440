import React from 'react';

const HowToUseTcpConnection = () => {
  const preparation = [
    {
      title:
        'ADB 포트 방식은 최초 1회 PC에 USB를 연결한 상태에서 진행되어야 합니다.',
    },
    { title: 'PC와 디바이스가 동일한 Wi-Fi에 연결되어 있어야 합니다.' },
    {
      title: '핸드폰 개발자 옵션을 활성화 상태로 변경해 주세요.',
      subTitle:
        '개발자 옵션 활성화 하는법: 설정 > 휴대전화 정보 > 빌드 번호 연속으로 7회 클릭 > 개발자 옵션 활성화 버튼 클릭',
    },
  ];

  const preparationList = [
    {
      header: 'USB 연결',
      contents: <p>PC와 디바이스를 USB 케이블로 연결해 주세요.</p>,
    },
    {
      header: '시리얼 넘버 확인',
      contents: (
        <p>
          설정 {'>'} 휴대폰 정보 {'>'} 시리얼 넘버를 확인 후 입력창에 입력해
          주세요.
        </p>
      ),
    },
    {
      header: 'IP 주소 확인',
      contents: (
        <ol className="list-decimal px-4">
          <li>PC와 디바이스를 USB 케이블로 연결해 주세요.</li>
          <li>디바이스의 IP 주소를 확인 후 입력창에 입력해 주세요.</li>
        </ol>
      ),
    },
  ];

  const steps = [
    {
      title: 'Step 1',
      explanation:
        '무선 디버깅 옵션을 활성화 상태로 변경하고 “페어링 코드로 기기 페어링” 메뉴를 클릭해 주세요.',
      imageDescription: (
        <div className="flex h-72 flex-col gap-4 border border-gray-200 bg-white p-4">
          <p className="text-base font-semibold leading-normal text-gray-900">
            무선 디버깅
          </p>
          <div className="flex items-center justify-between rounded-xl border border-gray-200 px-4 py-2">
            <p className="text-base font-semibold leading-normal text-black">
              사용 중
            </p>
            <div className="relative h-6 w-10 rounded-full border-2 border-solid border-black bg-black">
              <div className="absolute right-0.5 top-0.5 size-4 rounded-full bg-white" />
            </div>
          </div>
          <div className="rounded-xl text-sm font-normal leading-normal text-black">
            <div className="rounded-t-xl border border-solid border-gray-200 px-4 pb-3 pt-4">
              Device name
            </div>
            <div className="flex flex-col gap-3 border-x border-solid border-gray-200 px-4 py-3">
              IP 주소 및 포트
            </div>
            <div className="rounded-b-xl border border-solid border-gray-200 px-4 pb-4 pt-3">
              페어링 코드로 기기 페어링
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Step 2',
      explanation:
        '입력창에 IP 주소 및 포트 넘버 중에 IP 주소만 입력해 주세요.',
      imageDescription: (
        <div className="relative flex h-72 flex-col gap-4 border border-gray-200 bg-gray-400 p-4">
          <p className="text-base font-semibold leading-normal text-gray-900">
            무선 디버깅
          </p>
          <div className="flex items-center justify-between rounded-xl border border-gray-200/15 px-4 py-2">
            <p className="text-base font-semibold leading-normal text-black">
              사용 중
            </p>
            <div className="relative h-6 w-10 rounded-full border-2 border-solid border-black bg-black">
              <div className="absolute right-0.5 top-0.5 size-4 rounded-full bg-gray-400" />
            </div>
          </div>
          <div className="rounded-xl text-sm font-normal leading-normal text-black">
            <div className="rounded-t-xl border border-solid border-gray-200/15 px-4 pb-3 pt-4">
              Device name
            </div>
            <div className="flex flex-col gap-3 border-x border-solid border-gray-200/15 px-4 py-3">
              IP 주소 및 포트
            </div>
            <div className="rounded-b-xl border border-solid border-gray-200/15 px-4 pb-4 pt-3">
              페어링 코드로 기기 페어링
            </div>
          </div>
          <div className="absolute left-16 top-12 w-3/4 rounded-xl bg-white">
            <div className="flex flex-col gap-2.5 p-4 text-sm font-normal leading-normal text-black">
              <div className="font-semibold">기기 페어링</div>
              <div>Wi-fi 페어링 코드</div>
              <div className="font-semibold">123456</div>
              <div>IP주소 및 포트</div>
              <div>
                <span className="border border-solid border-red-500">
                  123.456.7.891
                </span>
                :12345
              </div>
            </div>
            <div className="flex-center border-t border-solid border-gray-200 p-3 text-sm font-semibold leading-normal">
              취소
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <section className="py-6">
      <h3 className="text-base font-semibold leading-7 text-gray-900">
        How to use
      </h3>
      <ul className="flex flex-col gap-2.5 pb-8 pt-2.5">
        {preparation.map((item) => (
          <li
            key={item.title}
            className="flex items-center gap-2.5 text-sm font-normal leading-tight text-gray-900 first:text-red-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-congress-blue"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            <div>
              <p>{item.title}</p>
              <p className="text-gray-400">{item.subTitle}</p>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex flex-col gap-5 text-sm font-normal leading-normal text-black">
        {preparationList.map((item) => (
          <div
            key={item.header}
            className="flex flex-col gap-2.5 border-b border-solid border-gray-200 px-10 pb-2.5"
          >
            <h4 className="text-base font-semibold">{item.header}</h4>
            {item.contents}
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-2.5 px-10 py-2.5 text-sm font-medium leading-normal text-black">
        <div className="flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5 text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>
          <p>안드로이드 11 이상 IP 주소 확인 방법</p>
        </div>
        <dl className="grid grid-cols-2 gap-x-14 gap-y-5">
          {steps.map((step) => (
            <div key={step.title}>
              <dt className="text-sm font-semibold leading-normal text-black">
                {step.title}
              </dt>
              <dd>
                <p className="py-2.5 text-sm font-normal leading-normal text-black">
                  {step.explanation}
                </p>
                {step.imageDescription}
              </dd>
            </div>
          ))}
        </dl>
        <div className="flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5 text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>
          <p>안드로이드 10 이하 IP 주소 확인 방법</p>
        </div>
        <p>
          설정 {'>'} Wi-Fi {'>'} 연결된 Wi-Fi 선택 {'>'} 고급 설정 또는 네트워크
          정보 {'>'} IP 주소
        </p>
      </div>
    </section>
  );
};

export default HowToUseTcpConnection;
