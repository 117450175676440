import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DefaultSkeleton,
  ErrorMessage,
  Pagination,
  PagingHeadingsInAutosquare,
  SpeedTestResultTableSkeleton,
} from '@autosquare/common';
import { useGetMobileSpeedTestResultList } from '@lib/api/dashboard/mobile/mobileSpeedResult';
import MobileSpeedTestResultTable from './components/MobileSpeedTestResultTable';

const MobileSpeedTestResult = () => {
  const PAGE_LIMIT = 5;
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const speedMobileTestResultQuery = useGetMobileSpeedTestResultList(
    page,
    PAGE_LIMIT,
  );
  const speedMobileTestResultData = speedMobileTestResultQuery.data;
  const currentUrl = location.pathname + location.search;
  const total = speedMobileTestResultData?.totalElements;

  const breadcrumbs = [
    { title: 'Speed Test', href: currentUrl },
    { title: 'Mobile', href: currentUrl },
    { title: 'Test Result', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      {speedMobileTestResultQuery.isLoading ||
      speedMobileTestResultQuery.isFetching ? (
        <SpeedTestResultTableSkeleton />
      ) : speedMobileTestResultQuery.isError ? (
        <ErrorMessage>{speedMobileTestResultQuery.error.message}</ErrorMessage>
      ) : (
        <MobileSpeedTestResultTable resultData={speedMobileTestResultData} />
      )}
      {speedMobileTestResultQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        total > 0 && (
          <Pagination
            page={page}
            total={total}
            size={PAGE_LIMIT}
            isDashboard={false}
          />
        )
      )}
    </>
  );
};

export default MobileSpeedTestResult;
