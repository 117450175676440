import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

export type WebTestStepInfo = {
  browserXpath: string;
  currentPageUrl: string;
};

const initialState: WebTestStepInfo = {
  browserXpath: null,
  currentPageUrl: null,
};

export const webTestStepInfoSlice = createSlice({
  name: 'Web Test Step Info',
  initialState,
  reducers: {
    getBrowserXPath: (state, action: PayloadAction<string | null>) => {
      state.browserXpath = action.payload;
    },
    getBrowserCurrentPageUrl: (state, action: PayloadAction<string | null>) => {
      state.currentPageUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getBrowserXPath, getBrowserCurrentPageUrl } =
  webTestStepInfoSlice.actions;

export default webTestStepInfoSlice.reducer;
