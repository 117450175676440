import {
  Button,
  ErrorMessage,
  SelectMobileAndBrowserSkeleton,
} from '@autosquare/common';

import React, { useEffect, useRef, useState } from 'react';

import { useWebBrowserListQuery } from '@lib/api/ide/web/webBrowser';
import NoBrowserList from '@components/TestExecution/NoBrowserList';
import { BrowserInfo } from '@customTypes/ide/browser/browser';

type Props = {
  selectedBrowser: BrowserInfo[];
  setSelectedBrowser: React.Dispatch<React.SetStateAction<BrowserInfo[]>>;
  checkHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: BrowserInfo,
  ) => void;
  isSelectedBrowser: boolean;
  setIsSelectedBrowser: React.Dispatch<React.SetStateAction<boolean>>;
  divWidth: number;
};

const SelectBrowser = ({
  selectedBrowser,
  setSelectedBrowser,
  checkHandler,
  isSelectedBrowser,
  setIsSelectedBrowser,
  divWidth,
}: Props) => {
  const [gridCols, setGridCols] = useState('grid-cols-3');

  useEffect(() => {
    if (divWidth > 1024) {
      setGridCols('grid-cols-4');
    } else {
      setGridCols('grid-cols-3');
    }
  }, [divWidth]);

  const browserQuery = useWebBrowserListQuery();
  const browserList = browserQuery.data;

  const resetList = () => {
    setSelectedBrowser([]);
    setIsSelectedBrowser(false);
    browserQuery.refetch();
  };
  const selectAllRef = useRef(null);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedBrowser(browserList);
    } else {
      setSelectedBrowser([]);
    }
  };

  useEffect(() => {
    const isIndeterminate =
      selectedBrowser.length > 0 && selectedBrowser.length < browserList.length;
    selectAllRef.current.indeterminate = isIndeterminate;
  }, [browserList, selectedBrowser]);

  return (
    <div className="px-0">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Select Browser
          </h3>
          <label
            className="flex items-center justify-between gap-2 text-sm"
            htmlFor="select-all"
          >
            <input
              type="checkbox"
              className="flex size-4 items-center justify-center rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600"
              id="select-all"
              name="select-all"
              onChange={handleSelectAll}
              ref={selectAllRef}
              checked={selectedBrowser.length > 0}
            />
            Select All
          </label>
        </div>
        {isSelectedBrowser && (
          <ErrorMessage width="max-w-full" margin="m-0">
            브라우저를 선택해주세요.
          </ErrorMessage>
        )}
        <Button
          type="button"
          variant="black"
          buttonSize="sm"
          onClick={resetList}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="size-5"
          >
            <path
              fillRule="evenodd"
              d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
              clipRule="evenodd"
            />
          </svg>
          Refresh
        </Button>
      </div>
      {browserQuery.isLoading || browserQuery.isFetching ? (
        <SelectMobileAndBrowserSkeleton />
      ) : browserQuery.isError ? (
        <ErrorMessage>{browserQuery.error.message}</ErrorMessage>
      ) : browserList?.length === 0 ? (
        <NoBrowserList />
      ) : (
        <ul role="list" className={`mt-3 grid gap-6 ${gridCols}`}>
          {browserList?.map((browser) => (
            <li className="flex rounded-md shadow-sm" key={browser.browserName}>
              <label
                className="flex w-full rounded-md shadow-sm"
                htmlFor={`comments-${browser.browserName}`}
              >
                <div className="flex w-16 shrink-0 items-center justify-center gap-2 rounded-l-md border-y border-l border-gray-200 text-sm font-medium">
                  <input
                    id={`comments-${browser.browserName}`}
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    className="size-4 rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={selectedBrowser?.includes(browser)}
                    onChange={(e) => checkHandler(e, browser)}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 6a3 3 0 013-3h13.5a3 3 0 013 3v12a3 3 0 01-3 3H5.25a3 3 0 01-3-3V6zm18 3H3.75v9a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V9zm-15-3.75A.75.75 0 004.5 6v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75H5.25zm1.5.75a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V6zm3-.75A.75.75 0 009 6v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75H9.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="flex flex-1 items-center justify-between rounded-r-md border-y border-r border-gray-200 bg-white">
                  <div className="flex-1 px-0 py-2 text-sm">
                    {browser.browserName[0].toUpperCase() +
                      browser.browserName.slice(1)}
                    <p className="break-all text-gray-500">{browser.version}</p>
                  </div>
                </div>
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectBrowser;
