import VariableList from '@components/shared/MobileWebTestStep/CreateAndUpdate/VariableList';
import { CreateMobileWebSpeedTestStepData } from '@customTypes/ide/speedTest/Mobile/webTestStep/type';
import { useVariableList } from '@hooks/mobileWebTestStep/useVariableList';
import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import UpdateVariableTableBody from './UpdateVariableList/UpdateVariableTableBody';

const UpdateVariableList = () => {
  const { watch, control, setValue, getValues } =
    useFormContext<CreateMobileWebSpeedTestStepData>();

  const { remove } = useFieldArray({ control, name: 'optionsDto' });

  const optionsDtoArray = watch('optionsDto');

  const {
    tableHeaders,
    removeOptionsDto,
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
  } = useVariableList({ optionsDtoArray, getValues, setValue, remove });

  return (
    <VariableList
      removeOptionsDto={removeOptionsDto}
      isOpenedCautionDialog={isOpenedCautionDialog}
      setIsOpenedCautionDialog={setIsOpenedCautionDialog}
      openDialog={openDialog}
      closeDialog={closeDialog}
      selectedArray={selectedArray}
      selectAllArray={selectAllArray}
    >
      {optionsDtoArray?.map((optionsDto, idx) => (
        <UpdateVariableTableBody
          key={optionsDto.idx}
          idx={idx}
          optionsDtoIdx={optionsDto.idx}
          tableColsLength={tableHeaders.length}
          selectArray={selectArray}
          selectedArray={selectedArray}
        />
      ))}
    </VariableList>
  );
};

export default UpdateVariableList;
