import React from 'react';
import TestCaseInfoTop from './TestCaseInfoForm/TestCaseInfoTop';
import TestCaseInfoBottom from './TestCaseInfoForm/TestCaseInfoBottom';

const TestCaseInfoForm = () => {
  return (
    <div className="mt-1">
      <TestCaseInfoTop />
      <TestCaseInfoBottom />
    </div>
  );
};

export default TestCaseInfoForm;
