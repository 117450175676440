import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';

import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import IfFailAction from './IfAction/IfFailAction';
import IfPassAction from './IfAction/IfPassAction';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const IfAction = ({ mobileTestStepData }: Props) => {
  const { projectIdx } = useParams();

  const { setValue } = useFormContext<UpdateTestStepData>();

  const value = mobileTestStepData?.value;

  const splitValue = (
    value: string,
    index: number,
  ): MobileCommandOptionServerName =>
    value?.split(',')[index] as MobileCommandOptionServerName;

  const [selectedPassCommand, setSelectedPassCommand] = useState(
    splitValue(value, 0) as MobileCommandOptionServerName,
  );
  const [selectedFailCommand, setSelectedFailCommand] = useState(
    splitValue(value, 1) as MobileCommandOptionServerName,
  );

  useEffect(() => {
    setSelectedPassCommand(
      splitValue(value, 0) as MobileCommandOptionServerName,
    );
    setSelectedFailCommand(
      splitValue(value, 1) as MobileCommandOptionServerName,
    );
  }, []);

  useEffect(() => {
    if (splitValue(value, 1) === undefined) {
      setValue('value', selectedPassCommand);
    } else {
      setValue('value', `${selectedPassCommand},${selectedFailCommand}`);
    }
  }, [selectedPassCommand, selectedFailCommand]);

  const mobileTestCaseListQuery = useMobileTestCaseListQuery(projectIdx);
  const mobileTestCaseList = mobileTestCaseListQuery.data;
  const filteredMobileTestCaseList = mobileTestCaseList?.filter(
    (testCase) => testCase.idx !== Number(projectIdx),
  );

  return (
    <>
      <DetailDivisionLine
        title={'Action'}
        description={'조건식의 결과에 따라 실행될 동작을 지정합니다.'}
      />
      <IfPassAction
        selectedPassCommand={selectedPassCommand}
        setSelectedPassCommand={setSelectedPassCommand}
        splitValue={splitValue}
        mobileTestStepData={mobileTestStepData}
        filteredMobileTestCaseList={filteredMobileTestCaseList}
      />
      {splitValue(value, 1) !== undefined && (
        <IfFailAction
          splitValue={splitValue}
          selectedFailCommand={selectedFailCommand}
          setSelectedFailCommand={setSelectedFailCommand}
          mobileTestStepData={mobileTestStepData}
          filteredMobileTestCaseList={filteredMobileTestCaseList}
        />
      )}
    </>
  );
};

export default IfAction;
