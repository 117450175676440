import {
  SaveTextFrom,
  SaveTextFromDisplay,
} from '@customTypes/ide/mobileTestStep/SaveTextFrom';

// 매핑 객체 선언
export const SaveTextFromToViewMap: {
  [key in SaveTextFrom]: SaveTextFromDisplay;
} = {
  [SaveTextFrom.Element]: SaveTextFromDisplay.Element,
  [SaveTextFrom.Sms]: SaveTextFromDisplay.Sms,
};

// 매핑 함수 선언
export const getDisplayTextBySaveTextFrom = (
  from: SaveTextFrom,
): SaveTextFromDisplay => {
  return SaveTextFromToViewMap[from];
};
