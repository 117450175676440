import React, { useState, useEffect } from 'react';

import { Button, ListSkeleton, PagingHeadings } from '@autosquare/common';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useAdminSystemSettingsQuery } from '@lib/api/dashboard/admin/admin';
import { useUpdateAdminSettings } from '@lib/api/dashboard/admin/admin';

const AdminGeneralSettings = () => {
  const adminSystemSettingsQuery = useAdminSystemSettingsQuery();
  const { data: settings, error, isLoading } = adminSystemSettingsQuery;

  const adminSystemSettingsMutation = useUpdateAdminSettings();

  const [testResultsDataPeriodInputValue, setTestResultsDataPeriodInputValue] =
    useState<string>('');
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const testResultsDataPeriodKey = 'test_results_data_period';

  useEffect(() => {
    if (settings && settings[testResultsDataPeriodKey] !== undefined) {
      setTestResultsDataPeriodInputValue(settings[testResultsDataPeriodKey]);
    }
  }, [settings]);

  const handletestResultsDataPeriodInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTestResultsDataPeriodInputValue(event.target.value);
  };

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    const updatedData = {
      [testResultsDataPeriodKey]: testResultsDataPeriodInputValue,
    };

    adminSystemSettingsMutation.mutate(updatedData, {
      onSuccess: () => {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      },
      onError: () => {
        setShowNotification(false);
      },
    });
  };

  const breadcrumbs = [
    {
      title: 'Administration',
      href: `#`,
    },
    {
      title: 'Settings',
      href: `#`,
    },
    { title: 'General', href: `#` },
  ];

  if (isLoading) {
    return <ListSkeleton margin="my-8" />;
  }

  if (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unexpected error occurred';
    return <div>Error loading settings: {errorMessage}</div>;
  }

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'General Settings'}
      />
      <form onSubmit={handleSave}>
        <div className="space-y-12 pt-4">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              System Storage
            </h2>
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="test-results-data-period"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  테스트 결과 데이터 보관 기간 설정 (일)
                </label>
                <div className="mt-2">
                  <input
                    id={testResultsDataPeriodKey}
                    name={testResultsDataPeriodKey}
                    type="text"
                    autoComplete={testResultsDataPeriodKey}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={testResultsDataPeriodInputValue}
                    onChange={handletestResultsDataPeriodInputChange}
                    required
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  입력 숫자는 데이터 보관 일수를 의미하며, 값이 0인 경우 데이터
                  자동 삭제 기능 비활성화
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <Button type="submit" variant="primary">
              Save
            </Button>
          </div>
        </div>
      </form>
      {/* Notification component */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50" // z-index 추가
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={showNotification}
            enter="transform transition duration-300 ease-out"
            enterFrom="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
            enterTo="opacity-100 translate-y-0 sm:translate-x-0"
            leave="transition duration-100 ease-in"
            leaveFrom="opacity-100 translate-y-0 sm:translate-x-0"
            leaveTo="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-green-400"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully saved!
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Your settings have been updated.
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => setShowNotification(false)}
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default AdminGeneralSettings;
