import { useInput, Button, ErrorMessage } from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { usePostLoginMutation } from '@lib/api/ide/user/user';

import {
  isInputValidLogin,
  passwordTotalValidation,
  validateEmail,
} from '@utils/validation';

import { Login } from '@customTypes/login/type';
import SettingsAndHelpModal from '@components/SettingsAndHelpModal/SettingsAndHelpModal';

interface Props {
  applicationUrl: string;
}

const LoginForm = ({ applicationUrl }: Props) => {
  const location = useLocation();

  const [isPersistentLogin, onChangeIsPersistentLogin] =
    useInput<boolean>(false);
  const [isSavedIdChecked, onChageIsSavedIdChecked] = useInput<boolean>(
    localStorage.getItem('isSavedIdChecked') === 'true',
  );
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [comparingValues, setComparingValues] = useState<Login>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
  } = useForm<Login>({
    defaultValues: {
      userId: isSavedIdChecked ? localStorage.getItem('savedUserId') : '',
      password: '',
    },
  });

  const userId = watch('userId');
  const password = watch('password');

  const postLoginMutation = usePostLoginMutation();

  const onSubmit: SubmitHandler<Login> = (data) => {
    postLoginMutation.mutate(data, {
      onSuccess: () => {
        localStorage.setItem('isPersistentLogin', isPersistentLogin.toString());
        localStorage.setItem('isSavedIdChecked', isSavedIdChecked.toString());
        isSavedIdChecked
          ? localStorage.setItem('savedUserId', userId)
          : localStorage.removeItem('savedUserId');
      },
      onError: () => {
        setComparingValues(data);
      },
    });
  };

  useEffect(() => {
    setFocus('userId');
  }, [setFocus]);

  const checkCapsLock = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const formValues = [
    {
      title: 'Email address',
      label: 'userId',
      inputType: 'email',
      checkValidation:
        userId?.length > 0 && !validateEmail(userId)
          ? '이메일 형식에 맞게 입력해주세요.'
          : errors.userId?.type === 'required' && '필수 입력 사항입니다.',
      register: {
        ...register('userId', {
          required: true,
        }),
      },
    },
    {
      title: 'Password',
      label: 'password',
      inputType: 'password',
      checkValidation: isCapsLockOn
        ? 'Caps Lock이 켜져 있습니다.'
        : password?.length > 0 && !passwordTotalValidation(password)
          ? '8자~16자의 영문 대 소문자, 숫자, 기호를 사용해주세요.'
          : errors.password?.type === 'required' && '필수 입력 사항입니다.',
      register: {
        ...register('password', { required: true }),
      },
      onkeydown: checkCapsLock,
    },
  ];

  const inputCheckboxValues = [
    {
      label: '아이디 저장',
      name: 'remember-id',
      handleOnChange: onChageIsSavedIdChecked,
      checked: isSavedIdChecked,
    },
    {
      label: '로그인 상태 유지',
      name: 'auto-login',
      handleOnChange: onChangeIsPersistentLogin,
      checked: isPersistentLogin,
    },
  ];

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
        {formValues.map((formValue) => (
          <div key={formValue.title}>
            <label
              className="mb-2 block text-sm font-medium leading-6 text-gray-900"
              htmlFor={formValue.label}
            >
              {formValue.title}
            </label>
            <input
              type={formValue.inputType}
              placeholder={formValue.title}
              className="input-base"
              name={formValue.label}
              id={formValue.label}
              onKeyDown={formValue?.onkeydown}
              {...formValue.register}
            />
            <ErrorMessage margin="my-2">
              {formValue.checkValidation}
            </ErrorMessage>
          </div>
        ))}
        <div className="flex-start flex items-center">
          {inputCheckboxValues.map((inputCheckboxValue) => (
            <div key={inputCheckboxValue.label} className="flex items-center">
              <input
                id={inputCheckboxValue.name}
                name={inputCheckboxValue.name}
                type="checkbox"
                className="input-checkbox-blue"
                onChange={inputCheckboxValue.handleOnChange}
                checked={inputCheckboxValue.checked}
              />
              <label
                htmlFor={inputCheckboxValue.name}
                className="label-checkbox-gray mr-6"
              >
                {inputCheckboxValue.label}
              </label>
            </div>
          ))}
        </div>
        <Button
          type="submit"
          variant={'primary'}
          className="w-full"
          disabled={
            !isInputValidLogin(userId, password) || applicationUrl === undefined
          }
        >
          Log In
        </Button>
        <ErrorMessage>
          {comparingValues?.userId === userId &&
          comparingValues?.password === password &&
          postLoginMutation.isError
            ? postLoginMutation.error.message
            : location.state === '!200' &&
              password?.length === 0 &&
              '로그인이 필요합니다.'}
        </ErrorMessage>
      </form>
      <hr className="m-3 mx-auto w-full border-gray-200" />
      <div className="flex items-center justify-end gap-x-8">
        <button
          type="button"
          className="mt-3 block cursor-pointer text-center text-sm text-allports"
          onClick={() => setIsSettingsOpen(true)}
        >
          Settings
        </button>
        {isSettingsOpen && (
          <SettingsAndHelpModal setState={setIsSettingsOpen} type="settings" />
        )}
        <button
          type="button"
          className="mt-3 block cursor-pointer text-center text-sm text-allports"
          onClick={() =>
            window.electron.send('openExternalPage', 'forgot-password')
          }
        >
          비밀번호를 잊으셨나요?
        </button>
      </div>
    </>
  );
};

export default LoginForm;
