import {
  TestResult,
  TestResultCaseDetail,
  TestResultInquiryByCase,
  WebTestResultPagingData,
} from '@customTypes/testResult/type';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { axiosFileInstance, axiosInstance } from '..';
import { API } from '@api/endpoints';
import { webTestResultKeys } from '@queries/ide/web/webTestResultKeys';

// GET
const getWebTestResultList = async (
  projectIdx: string,
  currentPage: number,
  limit: number,
): Promise<WebTestResultPagingData> => {
  const response = await axiosInstance.get(
    API.getWebTestResultList(projectIdx, currentPage, limit),
  );
  return response.data;
};
export const useWebTestResultListQuery = (
  projectIdx: string,
  currentPage: number,
  limit: number,
  options?: UseQueryOptions<
    WebTestResultPagingData,
    ServerResponseErrorDetails
  >,
) =>
  useQuery<WebTestResultPagingData, ServerResponseErrorDetails>({
    queryKey: webTestResultKeys.list(projectIdx, currentPage, limit),
    queryFn: () => getWebTestResultList(projectIdx, currentPage, limit),
    staleTime: 10000,
    ...options,
  });

const getWebTestCaseExecutionDetail = async (
  idx: string,
  caseResultIdx: number,
): Promise<TestResultCaseDetail[]> => {
  const response = await axiosInstance.get(
    API.getWebTestCaseExecutionDetail(idx, caseResultIdx),
  );
  return response.data;
};
export const useWebTestCaseExecutionDetailQuery = (
  idx: string,
  caseResultIdx: number,
  options?: UseQueryOptions<TestResultCaseDetail[], ServerResponseErrorDetails>,
) =>
  useQuery<TestResultCaseDetail[], ServerResponseErrorDetails>({
    queryKey: webTestResultKeys.executionDetail(idx, caseResultIdx),
    queryFn: () => getWebTestCaseExecutionDetail(idx, caseResultIdx),
    ...options,
  });

const getWebTestResultByTestCase = async (
  idx: string,
): Promise<TestResultInquiryByCase> => {
  const response = await axiosInstance.get(`${API.getWebTestCaseResult(idx)}`);
  return response.data;
};
export const useWebTestCaseResultQuery = (idx: string) => {
  return useQuery<TestResultInquiryByCase, ServerResponseErrorDetails>({
    queryKey: webTestResultKeys.detailInfo(idx),
    queryFn: () => getWebTestResultByTestCase(idx),
  });
};

const getWebTestResultDetail = async (idx: string): Promise<TestResult> => {
  const response = await axiosInstance.get(`${API.GET_WEB_TEST_RESULT}${idx}`);
  return response.data;
};
export const useWebTestResultDetailQuery = (idx: string) => {
  return useQuery<TestResult, ServerResponseErrorDetails>({
    queryKey: webTestResultKeys.detail(idx),
    queryFn: () => getWebTestResultDetail(idx),
  });
};

const webTestResultImageData = async (path: string[]): Promise<string[]> => {
  const requests = path.map((path) =>
    axiosFileInstance.get<MediaSource>(path, {
      responseType: 'blob',
    }),
  );

  const responses = await Promise.all(requests);
  const objectUrls = responses.map((response) =>
    URL.createObjectURL(response.data),
  );

  return objectUrls;
};

//image
export const useWebTestResultImageData = (
  path: string[],
  options?: UseQueryOptions<string[], Error>,
) => {
  return useQuery<string[], Error>({
    queryKey: webTestResultKeys.detailImage(path[0]),
    queryFn: () => webTestResultImageData(path),
    ...options,
  });
};
