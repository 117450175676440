import React from "react";

import {
  SchedulerTypeName,
  SchedulerTypeServerName,
} from "@customTypes/ide/scheduler/scheduler";

interface Props {
  type: string;
}

const SchedulerDetailTestType = ({ type }: Props) => {
  return (
    <div>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
        <div
          className="text-sm font-medium leading-6 text-gray-900"
          aria-hidden="true"
        >
          Test Type
        </div>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          <div className="flex items-center">
            <p className="block text-sm font-medium leading-6 text-gray-900">
              {type === SchedulerTypeServerName.Mobile
                ? SchedulerTypeName.Mobile
                : SchedulerTypeName.Web}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulerDetailTestType;
