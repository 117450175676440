import {
  DefaultSkeleton,
  ErrorMessage,
  ScenarioListDetailData,
} from '@autosquare/common';

import React from 'react';

import { useMobileTestCaseDetailQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { useMobileTestScenarioDetailQuery } from '@lib/api/ide/mobile/mobileTestScenario';
import { useMobileTestStepQuery } from '@lib/api/ide/mobile/mobileTestStep';

import { TestCaseDetailData } from '@customTypes/testCase/type';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

type Props = {
  idx: number;
  testType: TestTypeCategory;
};

const ExecutionInfo = ({ idx, testType }: Props) => {
  const { data, isError, error, isLoading } =
    testType === 'case'
      ? useMobileTestCaseDetailQuery(idx.toString())
      : testType === 'scenario'
        ? useMobileTestScenarioDetailQuery(idx.toString())
        : useMobileTestStepQuery(idx);

  const mobileTestStep = testType === 'step' && (data as MobileTestStepData);
  const mobileTestCase = testType === 'case' && (data as TestCaseDetailData);
  const scenarioList =
    testType === 'scenario' && (data as ScenarioListDetailData);

  return (
    <div>
      <h3 className="text-base font-semibold leading-7 text-gray-900">
        Execution Info
      </h3>
      <div className="mt-4">
        {isError ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : (
          <dl className="grid grid-cols-2">
            <div className="col-span-1 border-t border-gray-100 px-0 py-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Title
              </dt>
              <dd className="mt-2 text-sm leading-6 text-gray-700">
                {isLoading ? (
                  <DefaultSkeleton
                    number={1}
                    outMargin={'m-0'}
                    margin={'m-0'}
                  />
                ) : testType === 'step' ? (
                  mobileTestStep?.description
                ) : testType === 'case' ? (
                  mobileTestCase?.title
                ) : (
                  scenarioList?.title
                )}
              </dd>
            </div>
            <div className="col-span-1 border-t border-gray-100 px-0 py-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Execution Count
              </dt>
              <dd className="mt-2 text-sm leading-6 text-gray-700">
                {isLoading ? (
                  <DefaultSkeleton
                    number={1}
                    outMargin={'m-0'}
                    margin={'m-0'}
                  />
                ) : testType === 'step' ? (
                  1
                ) : testType === 'case' ? (
                  `AOS: ${mobileTestCase?.steps.aosStepCount} / iOS: ${mobileTestCase?.steps.iosStepCount}`
                ) : (
                  scenarioList?.caseCount
                )}
              </dd>
            </div>
          </dl>
        )}
      </div>
    </div>
  );
};

export default ExecutionInfo;
