import React from 'react';
import SpeedSchedulerDetailDays from './SpeedTestSchedulerDetailSchedule/SpeedSchedulerDetailDays';
import SpeedSchedulerDetailPreview from './SpeedTestSchedulerDetailSchedule/SpeedSchedulerDetailPreview';

import SpeedSchedulerDetailRunningCycle from './SpeedTestSchedulerDetailSchedule/SpeedSchedulerDetailRunningCycle';
import SpeedSchedulerDetailStartDate from './SpeedTestSchedulerDetailSchedule/SpeedSchedulerDetailStartDate';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';

const SpeedTestSchedulerDetailSchedule = () => {
  return (
    <SpeedSchedulerGridContainer title="Schedule">
      <div className="grid grid-cols-3 items-start gap-x-8 gap-y-2">
        <SpeedSchedulerDetailStartDate />
        <SpeedSchedulerDetailRunningCycle />
        <SpeedSchedulerDetailPreview />
        <SpeedSchedulerDetailDays />
      </div>
    </SpeedSchedulerGridContainer>
  );
};

export default SpeedTestSchedulerDetailSchedule;
