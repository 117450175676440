import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useRef, useState } from 'react';
import { GrPowerReset } from 'react-icons/gr';

interface Props {
  valueText: string;
  setValueText: React.Dispatch<React.SetStateAction<string>>;
  totalValues: string[];
  isListboxOpened: boolean;
  setIsListboxOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isReset?: boolean;
  resetOnClick?: () => void;
  disabled?: boolean;
}

const TestStepValueListbox = ({
  valueText,
  setValueText,
  totalValues,
  isListboxOpened,
  setIsListboxOpened,
  resetOnClick,
  isReset = true,
  disabled = false,
}: Props) => {
  const [currentLocation, setCurrentLocation] = useState<number>(0);

  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);

  const closeListbox = () => {
    setIsListboxOpened(false);
    if (valueText) {
      setCurrentLocation(totalValues.indexOf(valueText));
    } else {
      setCurrentLocation(-1);
    }
  };

  const openListbox = () => {
    setIsListboxOpened(true);
  };

  const toggledListbox = () => {
    if (isListboxOpened) {
      closeListbox();
    } else {
      openListbox();
    }
  };

  const arrowDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      openListbox();
      if (totalValues.length - 1 === currentLocation) {
        setCurrentLocation(0);
      } else {
        setCurrentLocation((prev) => prev + 1);
      }
    }
  };

  const arrowUp = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowUp') {
      if (isListboxOpened) {
        if (currentLocation === 0) {
          setCurrentLocation(totalValues.length - 1);
        } else {
          setCurrentLocation((prev) => prev - 1);
        }
      }
    }
  };

  const enter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setValueText(totalValues[currentLocation]);
    }
  };

  const escape = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeListbox();
    }
  };

  const scrollToCurrentLocation = () => {
    if (itemRefs.current[currentLocation]) {
      itemRefs.current[currentLocation]?.scrollIntoView({
        block: 'nearest',
      });
    }
  };

  const handleList = (e: React.KeyboardEvent) => {
    arrowDown(e);
    arrowUp(e);
    escape(e);
    enter(e);
    scrollToCurrentLocation();
  };

  useEffect(() => {
    scrollToCurrentLocation();
  }, [currentLocation]);

  return (
    <div className="flex h-9 w-full items-center justify-between">
      <div className={`relative ${isReset ? 'w-[calc(100%-2rem)]' : 'w-full'}`}>
        <button
          type="button"
          className={`relative w-full cursor-default bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
            isReset ? 'rounded-l-md' : 'rounded-md'
          }`}
          onClick={() => toggledListbox()}
          onKeyDown={handleList}
        >
          <span className="block truncate">{valueText}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="size-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </button>
        {isListboxOpened && !disabled && (
          <ul className="absolute z-[1] mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
            {totalValues?.length === 0 ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Not Founded
              </div>
            ) : totalValues.every((value) =>
                value.includes('해당 객체는 텍스트를 저장할 수 없습니다.'),
              ) ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                {totalValues.at(0)}
              </div>
            ) : (
              totalValues?.map((value, index) => (
                <li
                  key={index}
                  ref={(el) => (itemRefs.current[index] = el)}
                  className={`relative cursor-default select-none py-2 pl-3 pr-9 ${
                    index === currentLocation && !value.includes('Android Only')
                      ? 'bg-indigo-600 text-white'
                      : value.includes('Android Only')
                        ? 'text-gray-400'
                        : 'text-gray-900'
                  }`}
                  onClick={() => {
                    if (!value.includes('Android Only')) {
                      setValueText(value);
                      setIsListboxOpened(false);
                    }
                  }}
                  onMouseEnter={() => {
                    if (!value.includes('Android Only')) {
                      setCurrentLocation(index);
                    }
                  }}
                  onMouseLeave={() => {
                    setCurrentLocation(-1);
                  }}
                >
                  {value.includes('Android Only') ? (
                    <>
                      {value.replace('- Android Only', '')}
                      <span className="text-xs text-gray-400">
                        - Android only
                      </span>
                    </>
                  ) : (
                    <span
                      className={`block break-all ${
                        value === valueText ? 'font-semibold' : 'font-normal'
                      }`}
                    >
                      {value}
                    </span>
                  )}
                  {value === valueText ? (
                    <span
                      className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                        index === currentLocation
                          ? 'text-white'
                          : 'text-indigo-600'
                      }`}
                    >
                      <CheckIcon className="size-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
      {isReset && (
        <button
          type="button"
          className="h-full w-8 rounded-r-md border-y border-r border-solid border-gray-300 px-2 shadow-sm hover:bg-gray-50"
          onClick={resetOnClick}
        >
          <GrPowerReset style={{ color: '#9CA3AF' }} />
        </button>
      )}
    </div>
  );
};

export default TestStepValueListbox;
