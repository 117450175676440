import {
  CreateTestCaseGroup,
  TestCaseGroup,
} from '@customTypes/testCaseGroup/type';
import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { serviceGroupKeys } from '@queries/ide/groupKeys';
import { speedMobileTestCaseKeys } from '@queries/ide/speedMobile/speedMobileTestCaseKeys';
import {
  DeleteTestCaseServiceGroup,
  UpdateTestCaseServiceGroup,
} from '@customTypes/serviceGroup/type';
import { useParams } from 'react-router-dom';
import { webSpeedTestCaseKeys } from '@queries/ide/speedWeb/webSpeedTestCaseKeys';

//GET
export const getTestCaseServiceGroupList = async (
  projectIdx: string,
): Promise<TestCaseGroup[]> => {
  const response = await axiosInstance.get<TestCaseGroup[]>(
    IDEAPI.GET_SERVICE_GROUP_LIST + projectIdx,
  );
  return response.data;
};

export const useGetTestCaseServiceGroupListQuery = (
  options?: UseQueryOptions<TestCaseGroup[], Error>,
) => {
  const { projectIdx } = useParams();

  return useQuery<TestCaseGroup[], Error, TestCaseGroup[]>({
    queryKey: serviceGroupKeys.list(projectIdx),
    queryFn: () => getTestCaseServiceGroupList(projectIdx),
    ...options,
  });
};

//POST
export const createTestCaseServiceGroup = async (data: CreateTestCaseGroup) => {
  const response = await axiosInstance.post<unknown>(
    IDEAPI.CRUD_SERVICE_GROUP,
    data,
  );
  return response.data;
};

export const useCreateTestCaseServiceGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, CreateTestCaseGroup>({
    mutationFn: (data) => createTestCaseServiceGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries(serviceGroupKeys.lists());
    },
  });
};

//PUT
export const updateTestCaseServiceGroup = async (
  data: UpdateTestCaseServiceGroup,
) => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.CRUD_SERVICE_GROUP,
    data,
  );
  return response.data;
};

export const useUpdateTestCaseServiceGroupMutation = (projectIdx: string) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, UpdateTestCaseServiceGroup>({
    mutationFn: (data) => updateTestCaseServiceGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries(serviceGroupKeys.list(projectIdx));
      queryClient.invalidateQueries(speedMobileTestCaseKeys.all);
      queryClient.invalidateQueries(webSpeedTestCaseKeys.all);
    },
  });
};

//DELETE
export const deleteTestCaseServiceGroup = async (
  data: DeleteTestCaseServiceGroup,
): Promise<DeleteTestCaseServiceGroup> => {
  const response = await axiosInstance.delete<DeleteTestCaseServiceGroup>(
    IDEAPI.CRUD_SERVICE_GROUP,
    { data: data },
  );
  return response.data;
};

export const useDeleteTestCaseServiceGroupMutation = (projcetIdx: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    DeleteTestCaseServiceGroup,
    Error,
    DeleteTestCaseServiceGroup
  >({
    mutationFn: (data) => deleteTestCaseServiceGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries(serviceGroupKeys.list(projcetIdx));
      queryClient.invalidateQueries(speedMobileTestCaseKeys.all);
      queryClient.invalidateQueries(webSpeedTestCaseKeys.all);
    },
  });
};
