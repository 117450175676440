import {
  TestResult,
  TestResultDetailInquiryByCase,
  TestResultInquiryByCase,
  WebTestResultPagingData,
} from '@customTypes/testResult/type';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { axiosInstance, axiosMediaInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { webTestResultKeys } from '@queries/ide/web/webTestResultKeys';

// GET
const webTestResultList = async (
  projectIdx: string,
  page: number,
  size: number,
): Promise<WebTestResultPagingData> => {
  const response = await axiosInstance.get<WebTestResultPagingData>(
    IDEAPI.getWebTestResultList(projectIdx, page, size),
  );
  return response.data;
};
export const useWebTestResultListQuery = (
  projectIdx: string,
  page: number,
  size: number,
) =>
  useQuery<WebTestResultPagingData, Error>({
    queryKey: webTestResultKeys.list(projectIdx, page, size),
    queryFn: () => webTestResultList(projectIdx, page, size),
  });

const webTestResultDetail = async (
  idx: string,
): Promise<TestResultInquiryByCase> => {
  const reponse = await axiosInstance.get<TestResultInquiryByCase>(
    IDEAPI.getWebTestResultDetailInfo(idx),
  );
  return reponse.data;
};
export const useWebTestResultDetailQuery = (idx: string) => {
  return useQuery<TestResultInquiryByCase, Error>({
    queryKey: webTestResultKeys.detail(idx),
    queryFn: () => webTestResultDetail(idx),
  });
};

const webTestResultDetailInfo = async (idx: string): Promise<TestResult> => {
  const response = await axiosInstance.get<TestResult>(
    IDEAPI.GET_WEB_TEST_RESULT_DETAIL + idx,
  );
  return response.data;
};
export const useWebTestResultDetailInfoQuery = (idx: string) => {
  return useQuery<TestResult, Error>({
    queryKey: webTestResultKeys.detailInfo(idx),
    queryFn: () => webTestResultDetailInfo(idx),
  });
};

const mobileTestCaseExecutionDetail = async (
  idx: string,
  caseResultIdx: number,
): Promise<TestResultDetailInquiryByCase[]> => {
  const response = await axiosInstance.get<TestResultDetailInquiryByCase[]>(
    IDEAPI.getWebTestCaseExecutionDetail(idx, caseResultIdx),
  );
  return response.data;
};
export const useWebTestCaseExecutionDetailQuery = (
  idx: string,
  caseResultIdx: number,
) =>
  useQuery<TestResultDetailInquiryByCase[], Error>({
    queryKey: webTestResultKeys.executionDetail(idx, caseResultIdx),
    queryFn: () => mobileTestCaseExecutionDetail(idx, caseResultIdx),
    refetchOnMount: true,
    staleTime: 1000 * 20,
  });

//image
const webTestResultImageData = async (path: string[]): Promise<string[]> => {
  const requests = path.map((path) =>
    axiosMediaInstance.get<MediaSource>(path, {
      responseType: 'blob',
    }),
  );

  const responses = await Promise.all(requests);
  const objectUrls = responses.map((response) =>
    URL.createObjectURL(response.data),
  );

  return objectUrls;
};

export const useWebTestResultImageData = (
  path: string[],
  options?: UseQueryOptions<string[], Error>,
) => {
  return useQuery<string[], Error>({
    queryKey: webTestResultKeys.detailImage(path[0]),
    queryFn: () => webTestResultImageData(path),
    ...options,
  });
};
