import React from 'react';

import {
  ConnectionType,
  DeviceInfo,
  DeviceInfoOs,
} from '@customTypes/ide/device/device';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { LiaUsb } from 'react-icons/lia';

interface Props {
  deviceList: DeviceInfo;
  testType: TestTypeCategory;
  mobileOS: DeviceInfoOs;
  aosCount: number;
  iosCount: number;
  selectedDevices: DeviceInfo[];
  checkHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: DeviceInfo,
  ) => void;
}

const Device = ({
  deviceList,
  testType,
  mobileOS,
  aosCount,
  iosCount,
  selectedDevices,
  checkHandler,
}: Props) => {
  return (
    <li
      key={deviceList.udid}
      className={`mr-3 ${
        (testType === 'step' &&
          mobileOS === DeviceInfoOs.Aos &&
          deviceList.os === DeviceInfoOs.Aos &&
          deviceList.enabled) ||
        (testType === 'step' &&
          mobileOS === DeviceInfoOs.Ios &&
          deviceList.os === DeviceInfoOs.Ios &&
          deviceList.enabled) ||
        (testType === 'case' &&
          aosCount !== 0 &&
          deviceList.os === DeviceInfoOs.Aos &&
          deviceList.enabled) ||
        (testType === 'case' &&
          iosCount !== 0 &&
          deviceList.os === DeviceInfoOs.Ios &&
          deviceList.enabled) ||
        (testType === 'scenario' && deviceList.enabled)
          ? 'bg-white'
          : 'bg-gray-100'
      }`}
    >
      <label
        className={`col-span-1 flex rounded-md shadow-sm ${
          (testType === 'step' &&
            mobileOS === DeviceInfoOs.Aos &&
            deviceList.os === DeviceInfoOs.Aos &&
            deviceList.enabled) ||
          (testType === 'step' &&
            mobileOS === DeviceInfoOs.Ios &&
            deviceList.os === DeviceInfoOs.Ios &&
            deviceList.enabled) ||
          (testType === 'case' &&
            aosCount !== 0 &&
            deviceList.os === DeviceInfoOs.Aos &&
            deviceList.enabled) ||
          (testType === 'case' &&
            iosCount !== 0 &&
            deviceList.os === DeviceInfoOs.Ios &&
            deviceList.enabled) ||
          (testType === 'scenario' && deviceList.enabled)
            ? 'cursor-pointer'
            : 'cursor-default'
        }`}
        htmlFor={`comments-${deviceList.udid}`}
      >
        <div className="flex w-16 shrink-0 items-center justify-center gap-2 rounded-l-md border-y border-l border-gray-200 text-sm font-medium">
          <input
            id={`comments-${deviceList.udid}`}
            aria-describedby="comments-description"
            name={`comments-${deviceList.udid}`}
            type="checkbox"
            className={`size-4 rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600 ${
              (testType === 'step' &&
                mobileOS === DeviceInfoOs.Aos &&
                deviceList.os === DeviceInfoOs.Aos &&
                deviceList.enabled) ||
              (testType === 'step' &&
                mobileOS === DeviceInfoOs.Ios &&
                deviceList.os === DeviceInfoOs.Ios &&
                deviceList.enabled) ||
              (testType === 'case' &&
                aosCount !== 0 &&
                deviceList.os === DeviceInfoOs.Aos &&
                deviceList.enabled) ||
              (testType === 'case' &&
                iosCount !== 0 &&
                deviceList.os === DeviceInfoOs.Ios &&
                deviceList.enabled) ||
              (testType === 'scenario' && deviceList.enabled)
                ? 'cursor-pointer bg-white'
                : 'cursor-default bg-gray-100'
            }`}
            checked={
              deviceList.enabled && selectedDevices?.includes(deviceList)
            }
            onChange={(e) => deviceList.enabled && checkHandler(e, deviceList)}
            disabled={
              !deviceList.enabled ||
              (testType === 'step' &&
                mobileOS === DeviceInfoOs.Ios &&
                deviceList.os === DeviceInfoOs.Aos) ||
              (testType === 'step' &&
                mobileOS === DeviceInfoOs.Aos &&
                deviceList.os === DeviceInfoOs.Ios) ||
              (testType === 'case' &&
                aosCount === 0 &&
                deviceList.os === DeviceInfoOs.Aos) ||
              (testType === 'case' &&
                iosCount === 0 &&
                deviceList.os === DeviceInfoOs.Ios)
            }
          />
          {deviceList.connectionType === null ||
          deviceList.connectionType === ConnectionType.USB ? (
            <LiaUsb size="1.5rem" />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.288 15.038a5.25 5.25 0 0 1 7.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 0 1 1.06 0Z"
              />
            </svg>
          )}
        </div>
        <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-y border-r border-gray-200">
          <div className="flex-1 truncate px-0 py-2 text-sm">
            {deviceList.model ?? '-'}
            <p className="truncate text-gray-500">{deviceList.udid}</p>
          </div>
        </div>
      </label>
    </li>
  );
};

export default Device;
