import {
  Button,
  CreatePlusButton,
  DistributedExecutionButton,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  PagingHeadingsNavigate,
  TableSkeleton,
  TooltipTop,
  useCheckbox,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  useDeleteMobileTestScenarioMutation,
  useMobileTestScenarioQuery,
} from '@lib/api/ide/mobile/mobileTestScenario';
import {
  useDeleteWebTestScenarioMutation,
  useWebTestScenarioQuery,
} from '@lib/api/ide/web/webTestScenario';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import ScenarioListTable from './components/ScenarioListTable';
import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';

const TestScenarioList = () => {
  const { projectIdx } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname + location.search;

  const [hoverIdx, setHoverIdx] = useState(null);
  const [isDistributedExecution, setIsDistributedExecution] = useState(false);

  const testScenarioQuery = isWordInLocationPathname('mobile')
    ? useMobileTestScenarioQuery(projectIdx)
    : useWebTestScenarioQuery({ idx: projectIdx });

  const scenarioLists = testScenarioQuery.data;

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(scenarioLists, 'idx');

  const deleteTestScenarioMutation = isWordInLocationPathname('mobile')
    ? useDeleteMobileTestScenarioMutation(selectedArray)
    : useDeleteWebTestScenarioMutation(selectedArray);

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: currentUrl.split('/').slice(0, -2).join('/') + '/overview',
    },
    {
      title: isWordInLocationPathname('mobile') ? 'Mobile' : 'Web',
      href: currentUrl,
    },
    { title: 'Test Scenario', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  // To do: 추후 개수가 들어나면 Tooltip 모듈화한 값으로 수정하기
  return (
    <>
      <div className="flex items-end justify-between">
        <PagingHeadingsNavigate
          breadcrumbs={breadcrumbs}
          pageTitle={'Test Scenario List'}
        />
        <div className="flex">
          <div className="relative top-1">
            {hoverIdx === 1 && (
              <TooltipTop
                positionClass={'icon-description after:left-1/2 -right-9'}
              >
                Create Scenario
              </TooltipTop>
            )}
            <Button
              type="button"
              variant="imageBasic"
              onClick={() => navigate('create')}
              onMouseEnter={() => setHoverIdx(1)}
              onMouseLeave={() => setHoverIdx(null)}
            >
              <CreatePlusButton />
            </Button>
          </div>
          {isWordInLocationPathname('mobile') && (
            <div className="relative top-1">
              {hoverIdx === 2 && (
                <TooltipTop
                  positionClass={'icon-description after:left-[64%] -right-7'}
                >
                  Distributed Execution
                </TooltipTop>
              )}
              <Button
                type="button"
                variant="imageBasic"
                onClick={() => setIsDistributedExecution(true)}
                onMouseEnter={() => setHoverIdx(2)}
                onMouseLeave={() => setHoverIdx(null)}
              >
                <DistributedExecutionButton />
              </Button>
            </div>
          )}
        </div>
      </div>
      {testScenarioQuery.isLoading ? (
        <TableSkeleton />
      ) : testScenarioQuery.isError ? (
        <ErrorMessage>{testScenarioQuery.error.message}</ErrorMessage>
      ) : (
        <ScenarioListTable
          scenarioLists={scenarioLists}
          selectAllArray={selectAllArray}
          selectArray={selectArray}
          selectedArray={selectedArray}
          openDialog={openDialog}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={
          deleteTestScenarioMutation.isError ? 'Error' : 'Delete Test Scenario'
        }
        subTitle={
          deleteTestScenarioMutation.isError
            ? deleteTestScenarioMutation.error.message
            : '해당 테스트 시나리오를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {deleteTestScenarioMutation.isError ? undefined : (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteTestScenarioMutation.mutate(undefined, {
                    onSuccess: () => {
                      closeDialog();
                      resetSelectedArray();
                    },
                    onError: () => {
                      openDialog();
                    },
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      {isDistributedExecution && (
        <TestExecutionContainer
          setState={setIsDistributedExecution}
          testType={'scenario'}
          deviceType={'mobile'}
          isDistributedClicked={true}
        />
      )}
    </>
  );
};

export default TestScenarioList;
