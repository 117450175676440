import {
  Breadcrumbs,
  PageHeadingButtons,
  PagingHeadingsNavigate,
  PlatFormTypeServerName,
} from '@autosquare/common';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CreateSpeedMobileTestForm from './components/CreateSpeedMobileTestForm';
import { CreateSpeedMobileTestCaseData } from '@customTypes/speedTestCase/type';
import { useCreateSpeedMobileTestCaseMutation } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { PriorityNameEnum } from '@customTypes/testCase/type';
import GroupProvider from '@contexts/ide/GroupProvider';
import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/store';

import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

const CreateSpeedMobileTestCase = () => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -3).join('/')}/overview`,
    },
    {
      title: 'Mobile',
      href: `/ide/projects/${projectIdx}/speed-test/mobile/test-case`,
    },
    {
      title: 'Test Case',
      href: `/ide/projects/${projectIdx}/speed-test/mobile/test-case`,
    },
    { title: 'Create', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    { title: 'Save', variant: 'primary', type: 'submit' },
  ];

  const methods = useForm<CreateSpeedMobileTestCaseData>({
    defaultValues: {
      platformType: PlatFormTypeServerName.MobileApp,
      priority: PriorityNameEnum.Medium,
    },
  });

  const createSpeedMobileTestCaseMutation =
    useCreateSpeedMobileTestCaseMutation();

  const onSubmit: SubmitHandler<CreateSpeedMobileTestCaseData> = (data) => {
    data.projectIdx = projectIdx;
    createSpeedMobileTestCaseMutation.mutate(data, {
      onSuccess: () =>
        navigate(`/ide/projects/${projectIdx}/speed-test/mobile/test-case`),
    });
  };

  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );
  const dispatch = useDispatch();

  return (
    <GroupProvider>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PagingHeadingsNavigate
            breadcrumbs={breadcrumbs}
            pageTitle={'Test Case Create'}
            pageHeadingButtons={pageHeadingButtons}
          />
          <CreateSpeedMobileTestForm
            isError={createSpeedMobileTestCaseMutation.isError}
            error={createSpeedMobileTestCaseMutation.error}
          />
        </form>
      </FormProvider>
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
    </GroupProvider>
  );
};

export default CreateSpeedMobileTestCase;
