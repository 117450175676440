import React from 'react';
import { useFormContext } from 'react-hook-form';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { ClickId } from '@utils/static/clickOptions';

import ScrollSwipe from '@components/shared/TestStep/ScrollSwipe';
import Repeat from '@components/shared/TestStep/Repeat';
import WaitCommandHookForm from '@components/shared/TestStep/WaitCommandHookForm';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import AddButton from './MobileTestStepForm/AddButton';
import ConfirmAndInputText from './MobileTestStepForm/ConfirmAndInputText';
import LoopComponent from './MobileTestStepForm/LoopComponent';
import SearchTestStepValue from './MobileTestStepForm/SearchTestStepValue';
import SelectObjectOrCoordinate from './MobileTestStepForm/SelectObjectOrCoordinate';
import TestStepCommand from './MobileTestStepForm/TestStepCommand';
import IFComponent from './MobileTestStepForm/IFComponent';
import TestStepDescriptionHookForm from './MobileTestStepForm/TestStepDescriptionHookForm';
import TestStepRequiredHookForm from './MobileTestStepForm/TestStepRequiredHookForm';
import TestStepWaitTimeHookForm from './MobileTestStepForm/TestStepWaitTimeHookForm';
import SaveText from './MobileTestStepForm/SaveText';
import TextReplace from './MobileTestStepForm/TextReplace';
import UnlockPattern from './MobileTestStepForm/UnlockPattern';

interface Props {
  isError: boolean;
  error: Error;
}

const MobileTestStepForm = ({ isError, error }: Props) => {
  const { watch } = useFormContext<CreateTestStepData>();

  const command = watch('command');
  const clickBy = watch('optionsDto.clickBy');
  const saveTextFrom = watch('optionsDto.from');
  const value = watch('value');

  return (
    <>
      <AddButton isError={isError} error={error} />
      <TestStepCommand />
      {command === MobileCommandOptionServerName.Wait ? (
        <WaitCommandHookForm keyName={'value'} />
      ) : command === MobileCommandOptionServerName.LaunchApp ||
        command === MobileCommandOptionServerName.ResetApp ||
        command === MobileCommandOptionServerName.RemoveApp ? (
        <SearchTestStepValue />
      ) : command === MobileCommandOptionServerName.ScrollSwipe ? (
        <ScrollSwipe />
      ) : (
        command !== MobileCommandOptionServerName.Loop &&
        command !== MobileCommandOptionServerName.IF &&
        command !== MobileCommandOptionServerName.UnlockPattern &&
        saveTextFrom !== SaveTextFrom.Sms && <SelectObjectOrCoordinate />
      )}
      {command === MobileCommandOptionServerName.SaveText ? (
        <SaveText />
      ) : (
        (command === MobileCommandOptionServerName.ConfirmText ||
          command === MobileCommandOptionServerName.InputText) && (
          <ConfirmAndInputText />
        )
      )}
      <TestStepDescriptionHookForm />
      {command === MobileCommandOptionServerName.ScrollSwipe ? (
        <Repeat keyName={'optionsDto.repeatLimit'} />
      ) : (
        command === MobileCommandOptionServerName.Click &&
        clickBy === ClickId.Default && <TextReplace />
      )}
      {command === MobileCommandOptionServerName.Loop ? (
        <LoopComponent />
      ) : command === MobileCommandOptionServerName.IF ? (
        <IFComponent />
      ) : command === MobileCommandOptionServerName.UnlockPattern ? (
        <UnlockPattern />
      ) : (
        command !== MobileCommandOptionServerName.Wait && (
          <TestStepRequiredHookForm />
        )
      )}
      {!(
        command === MobileCommandOptionServerName.Loop &&
        value === MobileCommandOptionServerName.Wait
      ) &&
        command !== MobileCommandOptionServerName.Wait && (
          <TestStepWaitTimeHookForm />
        )}
    </>
  );
};

export default MobileTestStepForm;
