import {
  CreateSpeedSchedulerData,
  SpeedSchedulerTypeName,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWebBrowserListQuery } from '@lib/api/ide/web/webBrowser';
import {
  BrowserInfo,
  SupportedBrowsers,
} from '@customTypes/ide/browser/browser';
import clsx from 'clsx';
import SpeedSchedulerBrowserListbox from '@components/Scheduler/SpeedSchedulerBrowserListbox';
import { ErrorMessage } from '@hookform/error-message';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';

const CreateSpeedTestSchedulerBrowser = () => {
  const {
    watch,
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSpeedSchedulerData>();

  const [browserInfoDto, setBrowserInfoDto] = useState<BrowserInfo>(undefined);

  const testType = watch('type');
  const deviceInfo = watch('deviceInfoDto');

  const webBrowserListQuery = useWebBrowserListQuery({
    enabled: testType === SpeedSchedulerTypeName.Web,
  });

  // testType 변경 시 초기값 설정 및 값 초기화
  useEffect(() => {
    if (testType === SpeedSchedulerTypeName.Mobile) {
      setValue('deviceInfoDto.browser', SupportedBrowsers.Chrome);
      setValue('browserInfoDto', undefined);
      setBrowserInfoDto(undefined);
    }

    return () => {
      setValue('deviceInfoDto', undefined);
      setValue('browserInfoDto', undefined);
    };
  }, [testType]);

  // 디바이스에 설치된 브라우저 리스트를 확인하여 browser 초기값 설정
  useEffect(() => {
    if (deviceInfo?.installedBrowserList?.includes(SupportedBrowsers.Chrome)) {
      setValue('deviceInfoDto.browser', SupportedBrowsers.Chrome);
    }

    if (deviceInfo?.installedBrowserList?.includes(SupportedBrowsers.Safari)) {
      setValue('deviceInfoDto.browser', SupportedBrowsers.Safari);
    }
  }, [deviceInfo]);

  // browser error 초기화
  useEffect(() => {
    if (browserInfoDto) {
      clearErrors('browserInfoDto');
    }
  }, [clearErrors, browserInfoDto]);

  // 브라우저 정보 저장
  const handleChange = (selectedBrowser: BrowserInfo) => {
    setBrowserInfoDto(selectedBrowser);
    setValue('browserInfoDto', selectedBrowser);
  };

  const mobileBrowserList = [
    {
      name: 'Chrome',
      value: SupportedBrowsers.Chrome,
      disabled:
        !deviceInfo?.serialNumber ||
        !deviceInfo?.installedBrowserList?.includes(SupportedBrowsers.Chrome),
    },
    {
      name: 'Safari',
      value: SupportedBrowsers.Safari,
      disabled:
        !deviceInfo?.serialNumber ||
        !deviceInfo?.installedBrowserList?.includes(SupportedBrowsers.Safari),
    },
  ];

  return (
    <SpeedSchedulerGridContainer
      title="Browser"
      isTitleCenter={testType === SpeedSchedulerTypeName.Web}
    >
      {testType === SpeedSchedulerTypeName.Mobile ? (
        <fieldset className="flex items-center gap-10">
          {mobileBrowserList.map((browser) => (
            <label
              key={browser.name}
              className={clsx(
                'flex cursor-pointer items-center gap-3',
                browser.disabled && 'text-gray-200',
              )}
            >
              <input
                type="radio"
                value={browser.value}
                className={clsx(
                  'input-radio-congress-blue',
                  browser.disabled && 'bg-gray-200 text-gray-200',
                )}
                disabled={browser.disabled}
                {...register('deviceInfoDto.browser')}
              />
              {browser.name}
            </label>
          ))}
        </fieldset>
      ) : (
        <>
          <SpeedSchedulerBrowserListbox
            browserInfoDto={browserInfoDto}
            handleChange={handleChange}
            webBrowserListQuery={webBrowserListQuery}
          />
          <ErrorMessage
            errors={errors}
            name={'browserInfoDto'}
            render={({ message }) => (
              <p className="standard-error-message">{message}</p>
            )}
          />
        </>
      )}
    </SpeedSchedulerGridContainer>
  );
};

export default CreateSpeedTestSchedulerBrowser;
