import { DeepKeys } from '@autosquare/common';

import React, {
  HTMLInputTypeAttribute,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { useFormContext } from 'react-hook-form';

import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

interface Props {
  term: string;
  name: DeepKeys<Omit<UpdateTestStepData, 'parsedValue'>>;
  type?: HTMLInputTypeAttribute;
  min?: number;
  max?: number;
  tag?: 'input' | 'textarea';
  isBorderNeeded?: boolean;
  required?: string | boolean;
}

const DefinitionInputOrTextAreaByReactHookForm = ({
  term,
  name,
  type,
  min,
  max,
  tag = 'input',
  isBorderNeeded = true,
  required = false,
}: Props) => {
  const { register } = useFormContext<UpdateTestStepData>();
  const { ref, ...rest } = register(name);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (tag === 'textarea' && textareaRef.current) {
      setHeight(textareaRef.current.scrollHeight);
    }
  }, [name, tag]);

  useImperativeHandle(ref, () => textareaRef.current);

  return (
    <div
      className={`items-center sm:flex sm:px-6 sm:py-4 ${
        isBorderNeeded ? 'border-b border-gray-200' : 'border-none'
      }`}
    >
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        {term}
      </dt>
      <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        <div className="w-full">
          <label htmlFor={name} className="sr-only">
            {term}
          </label>
          {tag === 'input' && type === 'checkbox' ? (
            <input
              type={type}
              name={name}
              id={name}
              className="input-checkbox-blue"
              {...register(name)}
            />
          ) : tag === 'input' && type === 'number' ? (
            <input
              type={'number'}
              name={name}
              id={name}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              {...register(name, {
                required:
                  '대기 시간이 입력되어 있지 않습니다. 대기 시간을 입력해 주세요.',
                min: {
                  value: min,
                  message: `${min} 이상 숫자만 입력 가능합니다.`,
                },
                max: {
                  value: max,
                  message: `${max} 이하 숫자만 입력 가능합니다.`,
                },
              })}
              min={min}
              max={max}
            />
          ) : tag === 'input' ? (
            <input
              type={type}
              name={name}
              id={name}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              {...register(name, {
                required: required,
              })}
            />
          ) : (
            tag === 'textarea' && (
              <textarea
                name={name}
                id={name}
                style={{ height }}
                className="block w-full overflow-y-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 scrollbar-thin placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ref={textareaRef}
                {...rest}
                required
              />
            )
          )}
        </div>
      </dd>
    </div>
  );
};

export default DefinitionInputOrTextAreaByReactHookForm;
