import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '../mobileCommandOption';

export const generateRepeatStepList = (
  list: MobileTestStepData[],
  isStartCombobox: boolean,
) => {
  const disabledList = [
    MobileCommandOptionServerName.SaveText,
    MobileCommandOptionServerName.InputText,
    MobileCommandOptionServerName.Wait,
    MobileCommandOptionServerName.ConfirmText,
    MobileCommandOptionServerName.FindElement,
    MobileCommandOptionServerName.Loop,
    MobileCommandOptionServerName.VerifyNoElement,
    MobileCommandOptionServerName.CompareImage,
    MobileCommandOptionServerName.UnlockPattern,
  ];

  return list?.map((item, index) => {
    const commandName = findMobileCommandNameFromServerName(item.command);
    return {
      idx: item.idx,
      label: `${item.description}, ${commandName}`,
      disabled:
        disabledList.includes(item.command) ||
        (isStartCombobox && index === list.length - 1),
    };
  });
};

export const findTitleFromIdxBySpeedMobileStep = (
  idx: number,
  testStepList: MobileTestStepData[],
): string | undefined => {
  const selectedItem = testStepList?.find((item) => item.idx === idx);

  return selectedItem
    ? `${selectedItem.description}, ${selectedItem.command}`
    : undefined;
};
