import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ScenarioDraggableSection from './CreateSpeedTestSchedulerScenario/ScenarioDraggableSection';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';

const CreateSpeedTestSchedulerScenario = () => {
  return (
    <SpeedSchedulerGridContainer title="Scenario" isTitleCenter={true}>
      <DndProvider backend={HTML5Backend}>
        <ScenarioDraggableSection />
      </DndProvider>
    </SpeedSchedulerGridContainer>
  );
};

export default CreateSpeedTestSchedulerScenario;
