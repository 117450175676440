import { useMutation } from '@tanstack/react-query';

import { axiosLocalInstance } from '..';

import { IDEAPI } from '@api/ideEndpoints';

import {
  DeviceInfo,
  DistributionTestExecutionTestInfo,
} from '@customTypes/ide/device/device';

interface DefaultTestData {
  infos: DeviceInfo[];
  projectIdx: number;
}

interface TestStepData extends DefaultTestData {
  stepIdx: number;
}

interface TestCaseData extends DefaultTestData {
  caseIdx: number;
}

interface TestScenarioData extends DefaultTestData {
  scenarioIdx: number;
}

// post
const executionMobileTestStep = async (
  cookie: string,
  data: TestStepData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.RUN_TEST_STEP,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecutionMobileTestStepMutation = (
  cookie: string,
  data: TestStepData,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executionMobileTestStep(cookie, data),
  });
};

const executeMobileTestCase = async (
  cookie: string,
  data: TestCaseData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post(IDEAPI.RUN_TEST_CASE, data, {
    headers: { 'Token-Value': cookie },
  });
  return response.data;
};
export const useExecuteMobileTestCaseMutation = (
  cookie: string,
  data: TestCaseData,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executeMobileTestCase(cookie, data),
  });
};

const executeMobileTestScenario = async (
  cookie: string,
  data: TestScenarioData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.RUN_TEST_SCENARIO,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteMobileTestScenarioMutation = (
  cookie: string,
  data: TestScenarioData,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executeMobileTestScenario(cookie, data),
  });
};

const stopMobileTestExecution = async (cookie: string): Promise<unknown> => {
  const response = await axiosLocalInstance.post(
    IDEAPI.STOP_MOBILE_TEST_EXECUTION,
    undefined,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useStopMobileTestExecutionMutation = (cookie: string) =>
  useMutation<unknown, Error>({
    mutationFn: () => stopMobileTestExecution(cookie),
  });

const distributionMobileTestExecution = async (
  cookie: string,
  data: DistributionTestExecutionTestInfo,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post(
    IDEAPI.DISTRIBUTION_TEST_EXEUCUTION,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useDistributionMobileTestExecutionMutation = (cookie: string) => {
  return useMutation<unknown, Error, DistributionTestExecutionTestInfo>({
    mutationFn: (data) => distributionMobileTestExecution(cookie, data),
  });
};
