export const webSpeedTestResultKeys = {
  all: ['webSpeedTestResultKeys'] as const,
  lists: () => [...webSpeedTestResultKeys.all, 'list'] as const,
  list: (idx: string, page: number) =>
    [...webSpeedTestResultKeys.lists(), idx, page] as const,
  details: () => [...webSpeedTestResultKeys.all, 'detail'] as const,
  detail: (idx: string) => [...webSpeedTestResultKeys.details(), idx] as const,
  detailInfos: () => [...webSpeedTestResultKeys.all, 'detailInfo'] as const,
  detailInfo: (idx: string) =>
    [...webSpeedTestResultKeys.detailInfos(), idx] as const,
};
