import {
  TableBodyBasic,
  TableContainer,
  TableHeader,
  TableNoList,
  TableRow,
} from '@autosquare/common';

import { WebTestResultPagingData } from '@customTypes/testResult/type';

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  webTestResult: WebTestResultPagingData;
  page: number;
  PAGE_LIMIT: number;
};

const WebTestResultTable = ({ webTestResult, page, PAGE_LIMIT }: Props) => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  return (
    <TableContainer>
      <thead>
        <tr>
          {tableHeader.map((header) => (
            <TableHeader key={header}>{header}</TableHeader>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {webTestResult?.content.length === 0 ? (
          <TableNoList colSpan={tableHeader.length}>
            진행된 테스트 결과가 없습니다.
          </TableNoList>
        ) : (
          webTestResult?.content.map((webTest, index) => (
            <TableRow key={webTest.idx}>
              <TableBodyBasic>
                {(page - 1) * PAGE_LIMIT + index + 1}
              </TableBodyBasic>
              <TableBodyBasic>{webTest.type}</TableBodyBasic>
              <TableBodyBasic>{webTest.scenarioName}</TableBodyBasic>
              <TableBodyBasic>{webTest.startTime}</TableBodyBasic>
              <TableBodyBasic>
                {webTest.browserName[0].toUpperCase() +
                  webTest.browserName.slice(1)}
              </TableBodyBasic>
              <TableBodyBasic>{webTest.result}</TableBodyBasic>
              <TableBodyBasic>
                {webTest.result !== 'Running' && (
                  <button
                    type="button"
                    className="flex justify-center items-center rounded-md hover:text-congress-blue"
                    onClick={() =>
                      navigate({
                        pathname: `/ide/projects/${projectIdx}/ui-test/web/test-result/detail`,
                        search: `idx=${webTest.idx}`,
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                      />
                    </svg>
                  </button>
                )}
              </TableBodyBasic>
            </TableRow>
          ))
        )}
      </tbody>
    </TableContainer>
  );
};

export default WebTestResultTable;

const tableHeader = [
  'No',
  'Type',
  'Title',
  'DateTime',
  'Browser',
  'Result',
  'Detail',
];
