export type KeyboardEventListType = {
  id: number;
  name: string;
  serverName: string;
};

export enum KeyboardEventName {
  Enter = 'Enter',
}

export enum KeyboardEventServerName {
  Enter = 'enter',
}

export const keyboardEventList: KeyboardEventListType[] = [
  {
    id: 1,
    name: KeyboardEventName.Enter,
    serverName: KeyboardEventServerName.Enter,
  },
];

export const keyboardKeyNameList = keyboardEventList.map(
  (keyboardKey) => keyboardKey.name,
);

export const findKeyboardEventNameFromName = (name: KeyboardEventName) =>
  keyboardEventList.find((keyboardKey) => keyboardKey.name === name)?.name;

export const findKeyboardEventNameFromServerName = (
  serverName: KeyboardEventServerName,
) =>
  keyboardEventList.find((keyboardKey) => keyboardKey.serverName === serverName)
    ?.name;
