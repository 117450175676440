import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import type { PayloadAction } from '@reduxjs/toolkit';

import { DeviceInfo } from '@customTypes/ide/device/device';

export interface SelectedDevices {
  selectedDevicesArray: DeviceInfo[];
  deviceInfo: DeviceInfo | null;
}

const initialState: SelectedDevices = {
  selectedDevicesArray: [],
  deviceInfo: null,
};

export const selectedDevicesSlice = createSlice({
  name: 'Selected Device',
  initialState,
  reducers: {
    getSelectedDevices: (state, action: PayloadAction<DeviceInfo[]>) => {
      state.selectedDevicesArray = action.payload;
    },
    resetSelectedDevices: (state) => {
      state.selectedDevicesArray = [];
    },
    getDeviceInfo: (state, action: PayloadAction<DeviceInfo>) => {
      state.deviceInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getSelectedDevices, resetSelectedDevices, getDeviceInfo } =
  selectedDevicesSlice.actions;

export default selectedDevicesSlice.reducer;
