import { HeadingLevelTwoWithUnderline } from '@autosquare/common';

import { TestResultSummary } from '@customTypes/testResult/type';

import React from 'react';

interface Props {
  summary: TestResultSummary;
  result: string;
  reason: string;
}

const TestResultDetailSummary = ({ summary, result, reason }: Props) => {
  const stats = [
    {
      name: 'Result',
      stat: result,
      color: 'text-gray-900',
      sub: result === 'N/A' && reason === null ? 'Unknown Error' : reason,
    },
    {
      name: 'Total',
      stat: summary.total,
      color: 'text-gray-900',
    },
    {
      name: 'Pass',
      stat: summary.pass,
      color: 'text-green-500',
    },
    {
      name: 'Fail',
      stat: summary.fail,
      color: 'text-red-500',
    },
    {
      name: 'Pass Rate',
      stat:
        result === 'N/A'
          ? '-'
          : summary.passRate === 'NaN'
            ? '0%'
            : Number.isInteger(Number(summary.passRate) * 100)
              ? `${Number(summary.passRate) * 100}%`
              : `${(Number(summary.passRate) * 100).toFixed(2)}% `,
      color: Number(summary.passRate) === 1 ? 'text-blue-500' : 'text-red-500',
    },
  ];

  return (
    <section className="">
      <HeadingLevelTwoWithUnderline>Summary</HeadingLevelTwoWithUnderline>
      <div className="-mx-4">
        <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden bg-white md:grid-cols-5 md:divide-x md:divide-y-0">
          {stats?.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                {item.name}
              </dt>
              <dd
                className={`mt-1 text-xl font-semibold tracking-tight ${item.color}`}
              >
                {item.stat}
              </dd>
              {item.sub && (
                <dd className="font-sm truncate whitespace-normal text-xs text-gray-500">
                  {item.sub}
                </dd>
              )}
            </div>
          ))}
        </dl>
      </div>
    </section>
  );
};

export default TestResultDetailSummary;
