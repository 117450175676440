import {
  Breadcrumbs,
  CreatePlusButton,
  PageHeadingButtons,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WebSpeedTestScenarioTable from './components/WebSpeedTestScenarioTable';

const WebSpeedTestScenario = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const overviewPath = `${pathname.split('/').slice(0, -2).join('/')}/overview`;

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Speed Test', href: overviewPath },
    { title: 'Web', href: pathname },
    { title: 'Test Scenario', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      onClick: () => {
        navigate('create');
      },
      description: 'Create Scenario',
      icon: <CreatePlusButton />,
    },
  ];
  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Scenario List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <WebSpeedTestScenarioTable />
    </>
  );
};

export default WebSpeedTestScenario;
