import VariableTableBody from '@components/shared/SpeedWebTestStep/VariableTableBody';
import { UpdateWebSpeedTestStepData } from '@customTypes/ide/speedTest/Web/testStep/type';
import { useSpeedWebVariableTableBody } from '@hooks/speedWebTestStep/useSpeedWebVariableTableBody';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

type Props = {
  idx: number;
  optionsDtoIdx: number;
  tableColsLength: number;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
};

const UpdateWebSpeedVariableTableBody = ({
  idx,
  optionsDtoIdx,
  tableColsLength,
  selectArray,
  selectedArray,
}: Props) => {
  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateWebSpeedTestStepData>();
  const { update } = useFieldArray({ control, name: 'optionsDto' });

  const { handleKeyNameChange, optionsDtoKeyNameList } =
    useSpeedWebVariableTableBody({
      idx,
      watch,
      setValue,
      getValues,
      update,
    });

  return (
    <VariableTableBody
      optionsDtoIdx={optionsDtoIdx}
      selectedArray={selectedArray}
      selectArray={selectArray}
      idx={idx}
      tableColsLength={tableColsLength}
      handleKeyNameChange={handleKeyNameChange}
      optionsDtoKeyNameList={optionsDtoKeyNameList}
      watch={watch}
      control={control}
      register={register}
      errors={errors}
      setError={setError}
      clearErrors={clearErrors}
    />
  );
};

export default UpdateWebSpeedVariableTableBody;
