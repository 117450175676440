import { useMutation } from '@tanstack/react-query';

import { axiosLocalInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';

import {
  UseExecuteWebTestMutation,
  UseExecuteWebTestScenarioMutation,
  WebSpeedTestCaseExecutionData,
  WebSpeedTestExecutionType,
  WebSpeedTestScenarioExecutionData,
  WebTestCaseExecutionData,
  WebTestScenarioExecutionData,
} from '@customTypes/ide/browser/browserExecution';

// POST
// UI Test
const executeWebTestCase = async (
  cookie: string,
  data: WebTestCaseExecutionData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.RUN_WEB_TEST_CASE,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteWebTestCaseMutation = ({
  cookie,
  data,
}: UseExecuteWebTestMutation) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executeWebTestCase(cookie, data),
  });
};

const executeWebTestScenario = async (
  cookie: string,
  data: WebTestScenarioExecutionData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post(
    IDEAPI.RUN_WEB_TEST_SCENARIO,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteWebTestScenarioMutation = ({
  cookie,
  data,
}: UseExecuteWebTestScenarioMutation) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executeWebTestScenario(cookie, data),
  });
};

// Web Speed Test
const executeWebSpeedTestCase = async (
  cookie: string,
  data: WebSpeedTestCaseExecutionData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${IDEAPI.EXECUTE_WEB_SPEED_TEST}${WebSpeedTestExecutionType.SpeedCase}`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteWebSpeedTestCaseMutation = (cookie: string) => {
  return useMutation<unknown, Error, WebSpeedTestCaseExecutionData>({
    mutationFn: (data) => executeWebSpeedTestCase(cookie, data),
  });
};

const executeWebSpeedTestScenario = async (
  cookie: string,
  data: WebSpeedTestScenarioExecutionData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${IDEAPI.EXECUTE_WEB_SPEED_TEST}${WebSpeedTestExecutionType.SpeedScenario}`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteWebSpeedTestScenarioMutation = (cookie: string) => {
  return useMutation<unknown, Error, WebSpeedTestScenarioExecutionData>({
    mutationFn: (data) => executeWebSpeedTestScenario(cookie, data),
  });
};

// Stop Execution
const stopWebTestExecution = async (cookie: string): Promise<unknown> => {
  const response = await axiosLocalInstance.post(
    IDEAPI.STOP_WEB_RUNNING_TEST,
    undefined,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useStopWebTestExecutionMutation = (cookie: string) =>
  useMutation<unknown, Error>({
    mutationFn: () => stopWebTestExecution(cookie),
  });
