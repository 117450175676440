import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import type { PayloadAction } from "@reduxjs/toolkit";

import { UserInfoType } from "../../types/type";

export type UserInfo = {
  userInfo: UserInfoType;
};

const initialState: UserInfo = {
  userInfo: null,
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    getUserInfo: (state, action: PayloadAction<UserInfoType>) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getUserInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;
