import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';

import { UseMutationResult } from '@tanstack/react-query';
import React from 'react';

type Props = {
  isOpenedCautionDialog: boolean;
  setIsOpenedCautionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  deleteWebTestStepMutation: UseMutationResult<unknown, Error, void, unknown>;
  openDialog: () => void;
  resetSelectedArray: () => void;
  closeDialog: () => void;
};

const DeleteTestStepDialog = ({
  isOpenedCautionDialog,
  setIsOpenedCautionDialog,
  deleteWebTestStepMutation,
  openDialog,
  resetSelectedArray,
  closeDialog,
}: Props) => {
  return (
    <DoubleCheckDialogHeadlessUI
      isOpened={isOpenedCautionDialog}
      setIsOpened={setIsOpenedCautionDialog}
      type="caution"
      title={deleteWebTestStepMutation.isError ? 'Error' : 'Delete Test Step'}
      subTitle={
        deleteWebTestStepMutation.isError
          ? deleteWebTestStepMutation.error.message
          : '해당 테스트 스텝을 삭제하시겠습니까?'
      }
      buttonChildren={
        <>
          {!deleteWebTestStepMutation.isError && (
            <Button
              type="button"
              variant="caution"
              onClick={() =>
                deleteWebTestStepMutation.mutate(undefined, {
                  onSuccess: () => {
                    closeDialog();
                    resetSelectedArray();
                  },
                  onError: () => {
                    openDialog();
                  },
                })
              }
            >
              Delete
            </Button>
          )}
          <Button type="button" variant="secondary" onClick={closeDialog}>
            Cancel
          </Button>
        </>
      }
    />
  );
};

export default DeleteTestStepDialog;
