import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTestStepValueList } from '@hooks/testStep/useTestStepValueList';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import TestStepValueListbox from '@components/shared/IDE/TestStepValueListbox';
import DeviceKeyListBox from '@components/shared/TestStep/DeviceKeyListBox';
import ImportTestCaseListBoxHookForm from '@components/shared/TestStep/ImportTestCaseListBoxHookForm';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

const TestStepValue = () => {
  const { isListboxOpened, setIsListboxOpened } = useContext(
    MobileTestStepContext,
  );

  const { setValue, watch, control } = useFormContext<CreateTestStepData>();

  const command = watch('command');
  const saveTextFrom = watch('optionsDto.from');

  const { totalValues, bounds } = useTestStepValueList({
    command: command,
    valueName: 'value',
  });

  useEffect(() => {
    return setValue('value', undefined);
  }, [setValue]);

  useEffect(() => {
    if (
      command === MobileCommandOptionServerName.ImportTestCase ||
      command === MobileCommandOptionServerName.DeviceKey
    ) {
      setValue('optionsDto.elementOptions', undefined);
    } else {
      setValue('optionsDto.elementOptions.bounds', bounds);
    }
    return () => setValue('optionsDto.elementOptions', undefined);
  }, [setValue, bounds, command]);

  const selectElementCondition =
    (command === MobileCommandOptionServerName.SaveText &&
      saveTextFrom === SaveTextFrom.Element) ||
    command === MobileCommandOptionServerName.ConfirmText ||
    command === MobileCommandOptionServerName.InputText ||
    command === MobileCommandOptionServerName.VerifyNoElement;

  const valueTextField = selectElementCondition ? 'Select Element' : 'Value';

  const rulesRequiredMessage = selectElementCondition
    ? 'Element를 선택해주세요.'
    : 'Value를 선택해주세요.';

  return (
    <>
      {command === MobileCommandOptionServerName.ImportTestCase ? (
        <ImportTestCaseListBoxHookForm />
      ) : command === MobileCommandOptionServerName.DeviceKey ? (
        <DeviceKeyListBox />
      ) : (
        <Controller
          control={control}
          name={'value'}
          rules={{ required: rulesRequiredMessage }}
          render={({ field }) => (
            <TestStepValueListbox
              valueText={field.value || valueTextField}
              setValueText={field.onChange}
              totalValues={totalValues}
              isListboxOpened={isListboxOpened}
              setIsListboxOpened={setIsListboxOpened}
              disabled={command === undefined}
              isReset={false}
            />
          )}
        />
      )}
    </>
  );
};

export default TestStepValue;
