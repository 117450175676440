import React from 'react';

import { Outlet } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import NewSidebar from '@components/NewSidebar/NewSidebar';

const ProjectWithSidebar = () => {
  return (
    <div className="mx-auto flex max-w-full sm:px-0 lg:px-0">
      <div className="flex grow">
        <NewSidebar />
        <Sidebar />
      </div>
      <main className="gray-xy-scrollbar ml-24 w-full py-6 lg:pl-56">
        <div className="min-h-[calc(100dvh-3rem)] px-4 sm:px-6 lg:px-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default ProjectWithSidebar;
