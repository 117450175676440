import DateFormat from '@components/shared/MobileWebTestStep/CreateAndUpdate/DateFormat';
import { UpdateMobileWebSpeedTestStepData } from '@customTypes/ide/speedTest/Mobile/webTestStep/type';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const UpdateDateFormat = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateMobileWebSpeedTestStepData>();

  return (
    <DateFormat
      watch={watch}
      setValue={setValue}
      control={control}
      errors={errors}
    />
  );
};

export default UpdateDateFormat;
