import {
  TableBodyBasic,
  TableBodyBasicNoData,
  TableHeader,
  TableRow,
} from '@autosquare/common';

import {
  DeviceInfoOs,
  DeviceInfoOsFullName,
} from '@customTypes/ide/device/device';

import { MobileTestResultProperties } from '@customTypes/testResult/type';

import React from 'react';
import { useParams, Link } from 'react-router-dom';

type Props = {
  mobileTestResults: MobileTestResultProperties[];
  page: number;
};

const MobileTestResultTable = ({ mobileTestResults, page }: Props) => {
  const { projectIdx } = useParams();

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          {tableHeaders?.map((tableHeader) => (
            <TableHeader key={tableHeader}>{tableHeader}</TableHeader>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {mobileTestResults?.length === 0 ? (
          <TableBodyBasicNoData colSpan={tableHeaders?.length}>
            진행된 테스트 결과가 없습니다.
          </TableBodyBasicNoData>
        ) : (
          mobileTestResults?.map((mobileTestResult, idx) => (
            <TableRow key={mobileTestResult.idx}>
              <TableBodyBasic>{(page - 1) * 10 + idx + 1}</TableBodyBasic>
              <TableBodyBasic>{mobileTestResult.type}</TableBodyBasic>
              <TableBodyBasic>
                {mobileTestResult.mobileOs === DeviceInfoOs.Aos
                  ? DeviceInfoOsFullName.Aos
                  : DeviceInfoOsFullName.Ios}
              </TableBodyBasic>
              <TableBodyBasic>{mobileTestResult.scenarioName}</TableBodyBasic>
              <TableBodyBasic>{mobileTestResult.startTime}</TableBodyBasic>
              <TableBodyBasic>{mobileTestResult.device}</TableBodyBasic>
              <TableBodyBasic>{mobileTestResult.result}</TableBodyBasic>
              <TableBodyBasic>
                {mobileTestResult.result !== 'Running' ? (
                  <Link
                    to={`/projects/${projectIdx}/mobile-app/test-result/detail?idx=${mobileTestResult.idx}`}
                    className="rounded-md shadow-sm hover:text-congress-blue"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                      />
                    </svg>
                  </Link>
                ) : (
                  <div className="w-6 h-6" />
                )}
              </TableBodyBasic>
            </TableRow>
          ))
        )}
      </tbody>
    </table>
  );
};

export default MobileTestResultTable;

export const tableHeaders = [
  'No',
  'Type',
  'OS',
  'Title',
  'DateTime',
  'Device',
  'Result',
  'Detail',
];
