import React from "react";

import ErrorComponents from "./components/shared/ErrorComponents";

const NotFound = () => {
  return (
    <ErrorComponents
      errorCode={404}
      errorTitle={"Page not found"}
      errorMessage={["요청하신 페이지를 찾을 수 없습니다."]}
    />
  );
};

export default NotFound;
