import {
  DateFormatDayList,
  DateFormatMonthList,
  DateFormatSeparatorList,
  DateFormatYearList,
  DayName,
  DayServerName,
  MonthName,
  MonthServerName,
  SeparatorName,
  SeparatorServerName,
  YearName,
  YearServerName,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

// Year
export const dateFormatYearList: DateFormatYearList[] = [
  { idx: 1, name: YearName.None, serverName: YearServerName.None },
  { idx: 2, name: YearName.YYYY, serverName: YearServerName.YYYY },
  { idx: 3, name: YearName.YY, serverName: YearServerName.YY },
];

export const findDateFormatYearNameByServerName = (
  serverName: YearServerName,
) => dateFormatYearList.find((year) => year.serverName === serverName)?.name;

// Month
export const dateFormatMonthList: DateFormatMonthList[] = [
  { idx: 1, name: MonthName.None, serverName: MonthServerName.None },
  { idx: 2, name: MonthName.MM, serverName: MonthServerName.MM },
  { idx: 3, name: MonthName.M, serverName: MonthServerName.M },
];

export const findDateFormatMonthNameByServerName = (
  serverName: MonthServerName,
) => dateFormatMonthList.find((month) => month.serverName === serverName)?.name;

// Day
export const dateFormatDayList: DateFormatDayList[] = [
  { idx: 1, name: DayName.None, serverName: DayServerName.None },
  { idx: 2, name: DayName.DD, serverName: DayServerName.DD },
  { idx: 3, name: DayName.D, serverName: DayServerName.D },
];

export const findDateFormatDayNameByServerName = (serverName: DayServerName) =>
  dateFormatDayList.find((day) => day.serverName === serverName)?.name;

// Separator
export const dateFormatSeparatorList: DateFormatSeparatorList[] = [
  { idx: 1, name: SeparatorName.None, serverName: SeparatorServerName.None },
  { idx: 2, name: SeparatorName.Dot, serverName: SeparatorServerName.Dot },
  {
    idx: 3,
    name: SeparatorName.Hyphen,
    serverName: SeparatorServerName.Hyphen,
  },
  { idx: 4, name: SeparatorName.Slash, serverName: SeparatorServerName.Slash },
  { idx: 5, name: SeparatorName.Space, serverName: SeparatorServerName.Space },
];

export const findDateFormatSeparatorNameByServerName = (
  serverName: SeparatorServerName | undefined,
) =>
  dateFormatSeparatorList.find(
    (separator) => separator.serverName === serverName,
  )?.name;
