import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UseMutationResult } from '@tanstack/react-query';

import { Button } from '@autosquare/common';

import { ServerResponseErrorDetails } from '@customTypes/type';
import { AddMemberData } from '@customTypes/dashboard/project/type';

type Props = {
  emailAddress: string;
  checkData: { selectedRole: string; email: string };
  selectedRole: string;
  roleErrorMessage: string;
  addMemberMutation: UseMutationResult<
    unknown,
    ServerResponseErrorDetails,
    AddMemberData,
    unknown
  >;
};

const CancelSaveButton = ({
  emailAddress,
  checkData,
  selectedRole,
  roleErrorMessage,
  addMemberMutation,
}: Props) => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  return (
    <div className="mt-6 flex items-center justify-end gap-x-6">
      <div className="w-full text-center text-error-message">
        {emailAddress === checkData.email &&
          selectedRole === checkData.selectedRole &&
          ((addMemberMutation.isError && addMemberMutation.error.message) ||
            roleErrorMessage)}
      </div>
      <Button
        type="button"
        variant="secondary"
        onClick={() => navigate(`/projects/${projectIdx}/members/list`)}
      >
        Cancel
      </Button>
      <Button type="submit" variant="primary">
        Save
      </Button>
    </div>
  );
};

export default CancelSaveButton;
