import {
  CreateSchedulerData,
  SchedulerTypeName,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const CreateSchedulerTestType = () => {
  const { register } = useFormContext<CreateSchedulerData>();
  const testTypeList = [
    { name: SchedulerTypeName.Mobile, value: SchedulerTypeServerName.Mobile },
    { name: SchedulerTypeName.Web, value: SchedulerTypeServerName.Web },
  ];

  return (
    <>
      <fieldset>
        <legend className="sr-only">Test Type</legend>
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
          <div
            className="text-sm font-medium leading-6 text-gray-900"
            aria-hidden="true"
          >
            Test Type
          </div>
          <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {testTypeList.map((testType) => (
              <div key={testType.name} className="flex items-center">
                <label
                  htmlFor={testType.name}
                  className="block cursor-pointer text-sm font-medium leading-6 text-gray-900"
                >
                  <input
                    id={testType.name}
                    name="test type"
                    type="radio"
                    value={testType.value}
                    {...register('type')}
                    className="input-radio-congress-blue mr-3"
                  />
                  {testType.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default CreateSchedulerTestType;
