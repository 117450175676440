import { hasDownloadedData } from '@store/ide/settings/settingsSlice';

import React from 'react';
import { useDispatch } from 'react-redux';

import Settings from './components/Settings';
import TitleBar from './components/TitleBar';
import Help from './components/Help';

type MenuTab = 'Application' | 'iOS Test' | 'Alarm';

type Props = {
  type: 'settings' | 'help';
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  menu?: MenuTab;
};

const SettingsAndHelpModal = ({ type, setState, menu }: Props) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    if (type === 'settings' && menu === 'iOS Test') {
      dispatch(hasDownloadedData(true));
    }

    setState(false);
  };

  return (
    <div className="relative z-[9999]">
      <div className="fixed inset-0 flex items-center justify-center bg-black/30 backdrop-blur-sm">
        <div className={`size-4/5 rounded-md bg-white`}>
          <TitleBar closeModal={closeModal} />
          <div className="gray-scrollbar flex h-full gap-8 pt-8">
            {type === 'settings' ? (
              <Settings closeModal={closeModal} menu={menu} />
            ) : (
              <Help />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsAndHelpModal;
