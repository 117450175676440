import React from 'react';
import {
  findPlatformTypeNameFromServerName,
  PlatFormTypeServerName,
  SpeedTestResultDetailsSkeleton,
} from '@autosquare/common';
import { ExecutionDetail } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { useIdeSpeedMobileTestResultTableDetailQuery } from '@lib/api/ide/speedMobile/speedMobileTestResult';
import SpeedRepeatResult from './SpeedRepeatResult';
import SpeedInformationTable from './SpeedInformationTable/SpeedInformationTable';
import SpeedExecutionDetails from './SpeedExecutionDetails/SpeedExecutionDetails';
import { useSpeedMobileTestResultTableDetailQuery } from '@lib/api/dashboard/mobile/mobileSpeedResult';

type Props = {
  chartIndex: number;
  isDashboard: boolean;
};

const SpeedTestResultTableDetail = ({ chartIndex, isDashboard }: Props) => {
  const speedMobileTestResultTableQuery = isDashboard
    ? useSpeedMobileTestResultTableDetailQuery(String(chartIndex), {
        enabled: chartIndex !== null && isDashboard,
      })
    : useIdeSpeedMobileTestResultTableDetailQuery(String(chartIndex), {
        enabled: chartIndex !== null && !isDashboard,
      });

  const speedMobileTestResultTableData = speedMobileTestResultTableQuery.data;
  const speedMobileTestResultExecutionDetail: ExecutionDetail =
    speedMobileTestResultTableData?.executionDetail;
  const platformTypeName = findPlatformTypeNameFromServerName(
    speedMobileTestResultTableData?.platform,
  )?.split(' ')[1];
  return (
    <>
      <div className="pb-3 pt-6 font-semibold text-gray-900">
        {speedMobileTestResultTableData?.serviceName} Result Details
      </div>
      {speedMobileTestResultTableQuery.isLoading ||
      speedMobileTestResultTableQuery.isFetching ? (
        <SpeedTestResultDetailsSkeleton />
      ) : speedMobileTestResultTableQuery.isError ? (
        <p className="standard-error-message">
          {speedMobileTestResultTableQuery.error.message}
        </p>
      ) : (
        <>
          <SpeedInformationTable
            platformTypeName={platformTypeName}
            speedMobileTestResultTableData={speedMobileTestResultTableData}
          />
          {speedMobileTestResultTableData?.platform ===
            PlatFormTypeServerName.MobileApp && (
            <SpeedExecutionDetails
              executionDetail={speedMobileTestResultExecutionDetail}
              speedMobileTestResultTableQuery={speedMobileTestResultTableQuery}
            />
          )}
          <SpeedRepeatResult
            speedMobileTestResultTableData={speedMobileTestResultTableData}
            platformTypeName={platformTypeName}
            chartIndex={chartIndex}
          />
        </>
      )}
    </>
  );
};

export default SpeedTestResultTableDetail;
