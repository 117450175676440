import React from 'react';
import { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';

import SchedulerDetailStartDate from './SchedulerDetailSchedule/SchedulerDetailStartDate';
import SchedulerDetailRunningCycle from './SchedulerDetailSchedule/SchedulerDetailRunningCycle';

import 'react-datepicker/dist/react-datepicker.css';
import SchedulerDetailPreview from './SchedulerDetailSchedule/SchedulerDetailPreview';
import SchedulerDetailDays from './SchedulerDetailSchedule/SchedulerDetailDays';

type Props = {
  isRunning: boolean;
};

const SchedulerDetailSchedule = ({ isRunning }: Props) => {
  registerLocale('ko', ko);

  return (
    <div className="sm:grid sm:grid-cols-12 sm:grid-rows-2 sm:items-start sm:gap-x-4 sm:py-4">
      <SchedulerDetailStartDate isRunning={isRunning} />
      <SchedulerDetailRunningCycle isRunning={isRunning} />
      <SchedulerDetailPreview />
      <SchedulerDetailDays isRunning={isRunning} />
    </div>
  );
};

export default SchedulerDetailSchedule;
