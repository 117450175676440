import React from 'react';
import NoSpeedTestCaseList from './NoSpeedTestCaseList';
import SpeedTestCaseListItemCard from './SpeedTestCaseListItemCard';

import {
  SpeedMobileTestCaseData,
  SpeedTestCaseDataInScenario,
} from '@customTypes/speedTestCase/type';
import { WebSpeedTestCaseInfo } from '@customTypes/ide/speedTest/Web/testCase/type';
import DraggableSearchbar from '@components/shared/Searchbar/DraggableSearchbar';
import DraggableCardSkeleton from '@components/shared/Skeleton/DraggableCardSkeleton';

type Props = {
  isLoading: boolean;
  speedTestCaseList: SpeedMobileTestCaseData[] | WebSpeedTestCaseInfo[];
  searchResultTestCaseList: SpeedTestCaseDataInScenario[];
  copiedTestCaseList: SpeedTestCaseDataInScenario[];
  changeCardList: (list: SpeedTestCaseDataInScenario[]) => void;
  searchWord: string;
  onChangeSearchWord: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setSearchWord: React.Dispatch<React.SetStateAction<string>>;
};

const AllTestCaseListInDraggableElement = ({
  isLoading,
  speedTestCaseList,
  searchResultTestCaseList,
  copiedTestCaseList,
  changeCardList,
  searchWord,
  onChangeSearchWord,
  setSearchWord,
}: Props) => {
  return (
    <section>
      <div className="mb-2 flex justify-between">
        <p className="font-medium leading-6">All Test Case List</p>
      </div>
      <div className="gray-scrollbar h-[calc(100vh-21rem)] rounded-md border border-solid border-gray-200">
        <DraggableSearchbar
          searchWord={searchWord}
          onChangeSearchWord={onChangeSearchWord}
          setSearchWord={setSearchWord}
        />
        {isLoading ? (
          <DraggableCardSkeleton />
        ) : speedTestCaseList?.length === 0 ? (
          <NoSpeedTestCaseList />
        ) : (
          searchResultTestCaseList?.map((speedTestCase) => (
            <SpeedTestCaseListItemCard
              key={speedTestCase.idx}
              copiedTestCaseList={copiedTestCaseList}
              speedTestCase={speedTestCase}
              changeCardList={changeCardList}
            />
          ))
        )}
      </div>
    </section>
  );
};

export default AllTestCaseListInDraggableElement;
