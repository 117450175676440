import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

interface WebTestCaseDetailInfoProviderContext {
  isOpenedCreateGroup: boolean;
  setIsOpenedCreateGroup: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WebTestCaseDetailInfoContext =
  createContext<WebTestCaseDetailInfoProviderContext>({
    isOpenedCreateGroup: false,
    setIsOpenedCreateGroup: () => {},
  });

export const useWebTestCaseDetailInfoContext = () => {
  useContext(WebTestCaseDetailInfoContext);
};

interface Props {
  children: ReactNode;
}

const WebTestCaseDetailInfoProvider = ({ children }: Props) => {
  const [isOpenedCreateGroup, setIsOpenedCreateGroup] = useState(false);

  const contextValue = useMemo(
    () => ({ isOpenedCreateGroup, setIsOpenedCreateGroup }),
    [isOpenedCreateGroup],
  );

  return (
    <WebTestCaseDetailInfoContext.Provider value={contextValue}>
      {children}
    </WebTestCaseDetailInfoContext.Provider>
  );
};

export default WebTestCaseDetailInfoProvider;
