import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';
import React from 'react';

type Props = {
  testStepResult: {
    result: 'Pass' | 'Fail' | 'N/A';
    fail_reason?: string;
    load_speed?: string;
  };
  isResultModalOpened: boolean;
  setIsResultModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const RepeatTestStepResultModal = ({
  testStepResult,
  isResultModalOpened,
  setIsResultModalOpened,
}: Props) => {
  const loadSpeed = testStepResult?.load_speed.replace('s', '(sec)');
  return (
    <>
      {testStepResult?.result === 'Pass' ? (
        <DoubleCheckDialogHeadlessUI
          isOpened={isResultModalOpened}
          setIsOpened={setIsResultModalOpened}
          type={'checked'}
          title={'Execute Successful'}
          subTitle={`속도 측정 결과: ${loadSpeed}`}
          buttonChildren={
            <Button
              type="button"
              variant="primary"
              onClick={() => setIsResultModalOpened(false)}
            >
              Close
            </Button>
          }
        />
      ) : testStepResult?.result === 'Fail' ? (
        <DoubleCheckDialogHeadlessUI
          isOpened={isResultModalOpened}
          setIsOpened={setIsResultModalOpened}
          type={'caution'}
          title={'Execute Failure'}
          subTitle={`속도 측정 결과: Fail\n실패 사유: ${testStepResult?.fail_reason}`}
          buttonChildren={
            <Button
              type="button"
              variant="primary"
              onClick={() => setIsResultModalOpened(false)}
            >
              Close
            </Button>
          }
        />
      ) : (
        testStepResult?.result === 'N/A' && (
          <DoubleCheckDialogHeadlessUI
            isOpened={isResultModalOpened}
            setIsOpened={setIsResultModalOpened}
            type={'yellow caution'}
            title={'Execute Error'}
            subTitle={`속도 측정 결과: N/A\n실패 사유: ${testStepResult?.fail_reason}`}
            buttonChildren={
              <Button
                type="button"
                variant="primary"
                onClick={() => setIsResultModalOpened(false)}
              >
                Close
              </Button>
            }
          />
        )
      )}
    </>
  );
};

export default RepeatTestStepResultModal;
