import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';

import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useRemoveMemberMutation } from '@lib/api/dashboard/project/project';

type Props = {
  isRemoved: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoveDialog = ({ isRemoved, setIsRemoved }: Props) => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isRemoveError, setIsRemoveError] = useState(false);

  const removeMemberMutation = useRemoveMemberMutation(projectIdx, idx);

  const closeDialog = () => {
    setIsRemoved(false);
  };

  return (
    <>
      <DoubleCheckDialogHeadlessUI
        isOpened={isRemoved}
        setIsOpened={setIsRemoved}
        type={'caution'}
        title={'Remove Project Member'}
        subTitle={'해당 사용자를 프로젝트에서 삭제하시겠습니까?'}
        buttonChildren={
          <>
            <Button
              type="button"
              variant="caution"
              onClick={() => {
                closeDialog();
                removeMemberMutation.mutate(undefined, {
                  onError: () => {
                    setIsRemoveError(true);
                  },
                });
              }}
            >
              Remove
            </Button>
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isRemoveError}
        setIsOpened={setIsRemoveError}
        type={'caution'}
        title={'Error'}
        subTitle={
          removeMemberMutation.isError && removeMemberMutation.error.message
        }
        buttonChildren={
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsRemoveError(false)}
          >
            Cancel
          </Button>
        }
      />
    </>
  );
};

export default RemoveDialog;
