import { DeviceInfo } from '@customTypes/ide/device/device';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { Fragment } from 'react';

type Props = {
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  buttonValue: string;
  onClickButton: () => void;
  deviceLists: DeviceInfo[];
};

const SerialNumberDeviceListbox = ({
  value,
  onChange,
  buttonValue,
  onClickButton,
  deviceLists,
}: Props) => {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <ListboxButton
              className={clsx(
                'relative w-64 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
              )}
              onClick={onClickButton}
            >
              <span className="block truncate">{buttonValue}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                {deviceLists?.length === 0 ? (
                  <div className="mx-2 my-1 flex items-center justify-start">
                    연결된 디바이스가 없습니다.
                  </div>
                ) : (
                  deviceLists?.map((device) => (
                    <ListboxOption
                      key={device.serialNumber}
                      className={
                        'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white'
                      }
                      value={device.serialNumber}
                    >
                      <span
                        className={
                          'block truncate font-normal group-data-[selected]:font-semibold'
                        }
                      >
                        {device.model} - {device.serialNumber}
                      </span>
                      <span
                        className={
                          'invisible absolute inset-y-0 right-0 flex items-center pr-4 group-data-[selected]:visible'
                        }
                      >
                        <CheckIcon className="size-5" aria-hidden="true" />
                      </span>
                    </ListboxOption>
                  ))
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SerialNumberDeviceListbox;
