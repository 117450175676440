import React from 'react';

import WebSpeedTestCaseDetailInfoBottom from './WebSpeedTestCaseInfoEdit/WebSpeedTestCaseDetailInfoBottom';
import WebSpeedTestCaseInfoDetailTop from './WebSpeedTestCaseInfoEdit/WebSpeedTestCaseInfoDetailTop';

const WebSpeedTestCaseInfoEdit = () => {
  return (
    <div className="mt-1">
      <WebSpeedTestCaseInfoDetailTop />
      <WebSpeedTestCaseDetailInfoBottom />
    </div>
  );
};

export default WebSpeedTestCaseInfoEdit;
