import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { CoordinateInputOptions } from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import { ClickId } from '@utils/static/clickOptions';
import React, { ChangeEvent, Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  imageWidth: number;
  imageHeight: number;
  xCoordinate: string;
  yCoordinate: string;
};

const ClickCoordinateOption = ({
  imageWidth,
  imageHeight,
  xCoordinate,
  yCoordinate,
}: Props) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<UpdateTestStepData>();

  const xValue = watch('clickXValue');
  const yValue = watch('clickYValue');

  useEffect(() => {
    if (watch('optionsDto.clickBy') === ClickId.Coordinate) {
      setValue('clickXValue', xCoordinate);
      setValue('clickYValue', yCoordinate);
    }
  }, [setValue, xCoordinate, yCoordinate]);

  useEffect(() => {
    setValue('value', `${xValue}, ${yValue}`);
  }, [setValue, xValue, yValue]);

  const clickOptions: CoordinateInputOptions[] = [
    {
      labelTitle: 'X',
      max: imageWidth,
      name: 'clickXValue',
    },
    {
      labelTitle: 'Y',
      max: imageHeight,
      name: 'clickYValue',
    },
  ];

  const onChangeHandler = (
    e: ChangeEvent<HTMLInputElement>,
    name: keyof Pick<UpdateTestStepData, 'clickXValue' | 'clickYValue'>,
  ) => {
    const { value } = e.target;
    const newValue = value.replaceAll(/\D/g, '');
    setValue(name, newValue);
  };
  return (
    <>
      <div className="mb-2 grid w-full grid-cols-4 gap-4 text-center text-sm">
        <p>Width</p>
        <p>{imageWidth}</p>
        <p>Height</p>
        <p>{imageHeight}</p>
      </div>
      <div className="grid w-full grid-cols-4 items-center gap-4 text-center text-sm">
        {clickOptions?.map((inputOption, index) => (
          <Fragment key={index}>
            <label>{inputOption?.labelTitle}</label>
            <input
              type="text"
              className="w-full rounded-md border border-solid border-gray-300 px-3 py-1 text-center text-sm"
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              {...register(inputOption.name, {
                onChange: (e) => onChangeHandler(e, inputOption.name),
                required: '좌표 값을 모두 입력해주세요.',
                max: {
                  value: inputOption.max,
                  message:
                    '입력한 좌표 값이 화면 범위를 벗어났습니다. 다시 입력해 주세요',
                },
              })}
            />
          </Fragment>
        ))}
        <ErrorMessage
          name={
            errors.clickXValue
              ? 'clickXValue'
              : errors.clickYValue && 'clickYValue'
          }
          errors={errors}
          render={({ message }) => (
            <p className="standard-error-message col-span-4 pl-10">{message}</p>
          )}
        />
      </div>
    </>
  );
};

export default ClickCoordinateOption;
