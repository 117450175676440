import React, { ReactNode } from 'react';
import { ConnectDropTarget } from 'react-dnd';

type Props = {
  children: ReactNode;
  drop: ConnectDropTarget;
};

const SchedulerCopiedTestScenarioListContainer = ({
  children,
  drop,
}: Props) => {
  return (
    <div ref={drop} className="h-[calc(100%-0.5rem)] w-full">
      {children}
    </div>
  );
};

export default SchedulerCopiedTestScenarioListContainer;
