import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ScreenState {
  width: number;
  height: number;
  imageWidth: number;
  imageHeight: number;
  realScreenRatio: number;
}

const initialState: ScreenState = {
  width: 0,
  height: 0,
  imageWidth: 0,
  imageHeight: 0,
  realScreenRatio: 1,
};

const screenSlice = createSlice({
  name: 'screen',
  initialState,
  reducers: {
    setDimensions: (
      state,
      action: PayloadAction<{ width: number; height: number }>,
    ) => {
      state.width = action.payload.width;
      state.height = action.payload.height;
      state.realScreenRatio = calculateRealScreenRatio(state);
    },
    setImageDimensions: (
      state,
      action: PayloadAction<{ imageWidth: number; imageHeight: number }>,
    ) => {
      state.imageWidth = action.payload.imageWidth;
      state.imageHeight = action.payload.imageHeight;
      state.realScreenRatio = calculateRealScreenRatio(state);
    },
  },
});

const calculateRealScreenRatio = (state: ScreenState): number => {
  const { width, height, imageWidth, imageHeight } = state;
  return imageWidth > imageHeight && imageWidth !== 0
    ? width / imageWidth
    : imageHeight > imageWidth && imageHeight !== 0
      ? height / imageHeight
      : 1;
};

export const { setDimensions, setImageDimensions } = screenSlice.actions;

export default screenSlice.reducer;
