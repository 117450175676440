import { SeparatorServerName } from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

import {
  CreateWebSpeedTestStepData,
  UpdateWebSpeedTestStepData,
} from '@customTypes/ide/speedTest/Web/testStep/type';

import { useEffect } from 'react';
import {
  ControllerRenderProps,
  UseFieldArrayUpdate,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

type Props = {
  idx: number;
  watch: UseFormWatch<CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData>;
  setValue: UseFormSetValue<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
  getValues: UseFormGetValues<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
  update: UseFieldArrayUpdate<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData,
    'optionsDto'
  >;
};

export const useSpeedWebVariableTableBody = ({
  idx,
  watch,
  setValue,
  getValues,
  update,
}: Props) => {
  const dateFormat = watch(`optionsDto.${idx}.dateFormat`);

  const optionsDtoKeyNameList = watch('optionsDto')?.map(
    (option) => option.keyName,
  );

  useEffect(() => {
    if (dateFormat?.includes('.')) {
      setValue(`optionsDto.${idx}.separator`, SeparatorServerName.Dot);
      return;
    }
    if (dateFormat?.includes('-')) {
      setValue(`optionsDto.${idx}.separator`, SeparatorServerName.Hyphen);
      return;
    }
    if (dateFormat?.includes('/')) {
      setValue(`optionsDto.${idx}.separator`, SeparatorServerName.Slash);
      return;
    }
    if (dateFormat?.includes(' ')) {
      setValue(`optionsDto.${idx}.separator`, SeparatorServerName.Space);
      return;
    }

    setValue(`optionsDto.${idx}.separator`, SeparatorServerName.None);
  }, [dateFormat, setValue]);

  const handleKeyNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<
      CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData,
      `optionsDto.${number}.keyName`
    >,
  ) => {
    const newKeyName = e.target.value;
    const currentUrl = getValues('url');
    const currentOptionsDto = getValues('optionsDto');
    const targetOption = currentOptionsDto[idx];

    if (targetOption) {
      const newVariableString = `{{${newKeyName}}}`;
      const updatedUrl =
        currentUrl.slice(0, targetOption.start) +
        newVariableString +
        currentUrl.slice(targetOption.end);

      const newEnd = targetOption.start + newVariableString.length;
      const diff = newEnd - targetOption.end;

      // 현재 변수 업데이트
      update(idx, { ...targetOption, keyName: newKeyName, end: newEnd });

      // 이후 변수들의 위치 업데이트
      for (let i = idx + 1; i < currentOptionsDto.length; i++) {
        const option = currentOptionsDto[i];
        update(i, {
          ...option,
          start: option.start + diff,
          end: option.end + diff,
        });
      }

      setValue('url', updatedUrl);
    }

    field.onChange(e);
  };

  return { handleKeyNameChange, optionsDtoKeyNameList };
};
