import { BrowserInfo } from '@customTypes/ide/browser/browser';
import clsx from 'clsx';
import React from 'react';

type Props = {
  browser: BrowserInfo;
  selectedBrowser: BrowserInfo[];
  checkHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: BrowserInfo,
  ) => void;
};

const BrowserCard = ({ browser, selectedBrowser, checkHandler }: Props) => {
  // To do: 여러 브라우저에서 속도 테스트 가능해지면 삭제
  const isSelected = selectedBrowser?.every(
    (supportedBrowser) => supportedBrowser.browserName === browser.browserName,
  );

  return (
    <li
      className={clsx('flex rounded-md shadow-sm', {
        'bg-gray-100': !isSelected,
      })}
      key={browser.browserName}
    >
      <label
        className="flex w-full rounded-md shadow-sm"
        htmlFor={`comments-${browser.browserName}`}
      >
        <div className="flex w-16 shrink-0 items-center justify-center gap-2 rounded-l-md border-y border-l border-gray-200 text-sm font-medium">
          <input
            id={`comments-${browser.browserName}`}
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className={clsx(
              'size-4 rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600',
              isSelected ? 'bg-white' : 'bg-gray-100',
            )}
            checked={selectedBrowser?.includes(browser)}
            onChange={(e) => checkHandler(e, browser)}
            disabled={!isSelected}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
          >
            <path
              fillRule="evenodd"
              d="M2.25 6a3 3 0 013-3h13.5a3 3 0 013 3v12a3 3 0 01-3 3H5.25a3 3 0 01-3-3V6zm18 3H3.75v9a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V9zm-15-3.75A.75.75 0 004.5 6v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75H5.25zm1.5.75a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V6zm3-.75A.75.75 0 009 6v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75H9.75z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div
          className={clsx(
            'flex flex-1 items-center justify-between rounded-r-md border-y border-r border-gray-200',
            isSelected ? 'bg-white' : 'bg-gray-100',
          )}
        >
          <div className="flex-1 px-0 py-2 text-sm">
            {browser.browserName[0].toUpperCase() +
              browser.browserName.slice(1)}
            <p className="break-all text-gray-500">{browser.version}</p>
          </div>
        </div>
      </label>
    </li>
  );
};

export default BrowserCard;
