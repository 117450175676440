import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { ListBoxHeadlessInController } from '@autosquare/common';

import { RootState } from '@app/store';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

const TestStepCommand = () => {
  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const iOSMobileCommandOptions = mobileCommandOptions.filter(
    (mobileCommandOption) =>
      mobileCommandOption.name !== MobileCommandOptionName.ResetApp,
  );

  const { control, watch, setValue, resetField } =
    useFormContext<CreateTestStepData>();

  const command = watch('command');

  useEffect(() => {
    setValue('optionsDto.optionType', command);
  }, [setValue, command]);

  return (
    <Controller
      control={control}
      name="command"
      rules={{ required: 'Command를 입력해주세요.' }}
      render={({ field }) => (
        <ListBoxHeadlessInController
          value={field.value}
          buttonValue={
            findMobileCommandNameFromServerName(field.value) ||
            MobileCommandOptionName.Command
          }
          onChange={(value) => {
            field.onChange(value);
            resetField('value');
            if (
              command === MobileCommandOptionServerName.Loop ||
              command === MobileCommandOptionServerName.IF
            ) {
              resetField('optionsDto.conditionCommand');
              resetField('optionsDto.conditionValue');
            }
          }}
          lists={
            deviceInfo.os === DeviceInfoOs.Aos
              ? mobileCommandOptions.slice(1)
              : iOSMobileCommandOptions.slice(1)
          }
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      )}
    />
  );
};

export default TestStepCommand;
