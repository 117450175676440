import { DeepKeys } from '@autosquare/common';
import { UpdateWebTestStepData } from '@customTypes/testStep/type';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  name: DeepKeys<UpdateWebTestStepData>;
  radioOptions: {
    title: string;
    id: string;
    defaultChecked: boolean;
    onClick?: () => void;
  }[];
};

const WebTestStepConfirmInputRadio = ({ name, radioOptions }: Props) => {
  const { register } = useFormContext<UpdateWebTestStepData>();

  return (
    <fieldset>
      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        {radioOptions.map((radioOption) => (
          <div key={radioOption.id} className="flex items-center">
            <input
              id={radioOption.id}
              name={radioOption.id}
              type="radio"
              defaultChecked={radioOption.defaultChecked}
              onClick={radioOption.onClick}
              value={radioOption.id}
              className="top-1 size-4 appearance-none rounded-[50%] border border-solid border-gray-300 align-top text-indigo-600 transition-all duration-200 ease-linear checked:border-[6px] checked:border-solid checked:border-indigo-600 focus:ring-indigo-600"
              {...register(name)}
            />
            <label
              htmlFor={radioOption.id}
              className="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900"
            >
              {radioOption.title}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default WebTestStepConfirmInputRadio;
