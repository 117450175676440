export const BASE_URL = '/api';
export const TEST_RESULT = `${BASE_URL}/result`;
export const PROJECT = `${BASE_URL}/project`;
export const ADMIN = `${BASE_URL}/admin`;
export const ADMIN_PROJECT = `${ADMIN}/project`;
export const ADMIN_SETTINGS = `${ADMIN}/settings`;
export const ADMIN_WEBDRIVER = `${ADMIN}/webdriver`;
export const WEB_SPEED_TEST_RESULT = `${BASE_URL}/result/speed/list`;
export const WEB_SPEED_TEST_RESULT_DETAIL = `${WEB_SPEED_TEST_RESULT}/detail`;
export const MOBILE_SPEED_TEST_RESULT = `${BASE_URL}/result/speed/mobile`;
export const WEB_TEST_RESULT = `${BASE_URL}/web/result/functional`;
export const OVERVIEW = `${BASE_URL}/overview/`;
export const SETTINGS = `${BASE_URL}/settings`;
export const DOWNLOAD = `${BASE_URL}/ide/install`;
export const AUTH_CODE = `${BASE_URL}/user/auth-code/`;
export const RESET_PASSWORD = `${BASE_URL}/user/`;

export const API = {
  // User
  LOGIN: `${BASE_URL}/user/login`,
  SIGN_UP: `${BASE_URL}/user/signup`,
  LOGOUT: `${BASE_URL}/logout`,
  USER_INFO: `${BASE_URL}/userinfo`,
  CREATE_INVITE_SIGNUP: `${BASE_URL}/admin/invite`,
  CHECK_USER_INFO_DECODE_KEY: `${BASE_URL}/user/decode-key?key=`,

  // Settings
  EMAIL_ALARM_SETTING: `${SETTINGS}/alarm`,

  // Overview
  MOBILE_OVERVIEW: `${OVERVIEW}mobile?projectIdx=`,
  WEB_OVERVIEW: `${OVERVIEW}web?projectIdx=`,

  // Admin User List
  GET_ADMIN_USER_LIST: `${ADMIN}/userlist`,
  GET_ADMIN_DETAIL_USER_INFO: `${ADMIN}/userlist/`,
  UPDATE_ADMIN_DETAIL_USER_INFO: `${ADMIN}/userlist/`,

  // Admin Project
  GET_ADMIN_ALL_PROJECT_LIST: `${ADMIN_PROJECT}`,
  GET_ADMIN_PROJECT: `${ADMIN_PROJECT}/`,
  CREATE_ADMIN_PROJECT: `${ADMIN_PROJECT}`,
  UPDATE_ADMIN_PROJECT: `${ADMIN_PROJECT}/`,

  // Admin System Settings
  GET_ADMIN_SYSTEM_SETTINGS: `${ADMIN_SETTINGS}`,
  UPDATE_ADMIN_SYSTEM_SETTINGS: `${ADMIN_SETTINGS}`,

  // Admin WebDriver
  GET_ADMIN_WEB_DRIVER_LIST: `${ADMIN_WEBDRIVER}`,
  UPLOAD_ADMIN_WEB_DRIVER: `${ADMIN_WEBDRIVER}`,

  // Project
  PROJECT: `${BASE_URL}/project`,
  getMembers: (projectIdx: string) => {
    return `${PROJECT}/${projectIdx}/list`;
  },
  postProjectMember: (projectIdx: string) => {
    return `${PROJECT}/${projectIdx}/member`;
  },
  getMemberDetail: (projectIdx: string, idx: string) => {
    return `${PROJECT}/${projectIdx}/list/${idx}`;
  },
  putMemberInfo: (projectIdx: string, idx: string) => {
    return `${PROJECT}/${projectIdx}/member/${idx}`;
  },
  removeMember: (projectIdx: string, idx: string) => {
    return `${PROJECT}/${projectIdx}/member/${idx}`;
  },

  // Test Result
  TEST_RESULT: `${BASE_URL}/result`,
  TEST_RESULT_OVERVIEW: `${TEST_RESULT}/overview?projectIdx=`,
  TEST_RESULT_ALL_INQUIRY: `${TEST_RESULT}?projectIdx=`,
  testResultListPagination: (
    projectIdx: string,
    currentPage: number,
    limit: number,
  ) => {
    return `${TEST_RESULT}?projectIdx=${projectIdx}&page=${currentPage}&size=${limit}`;
  },
  GET_TEST_RESULT: `${BASE_URL}/result/`,
  getTestResultList: (projectIdx: number, page: number, size: number) => {
    return `${TEST_RESULT}?projectIdx=${projectIdx}&page=${page}&size=${size}`;
  },
  getMobileTestCaseExecutionDetail: (
    testResultIdx: string,
    testCaseidx: number,
  ) => {
    return `${TEST_RESULT}/${testResultIdx}/detail/${testCaseidx}`;
  },
  getTestCaseResult: (idx: string) => {
    return `${TEST_RESULT}/${idx}/detail?ideRequest=false`;
  },

  // Web Test Result
  GET_WEB_TEST_RESULT: `${WEB_TEST_RESULT}/`,
  getWebTestResultList: (projectIdx: string, page: number, size: number) => {
    return `${WEB_TEST_RESULT}?projectIdx=${projectIdx}&page=${page}&size=${size}`;
  },
  getWebTestCaseExecutionDetail: (
    testResultIdx: string,
    testCaseidx: number,
  ) => {
    return `${WEB_TEST_RESULT}/${testResultIdx}/detail/${testCaseidx}`;
  },
  getWebTestCaseResult: (idx: string) => {
    return `${WEB_TEST_RESULT}/${idx}/detail?ideRequest=false`;
  },

  // Speed Test Result > Mobile
  getMobileSpeedTestResultList: (
    projectIdx: string,
    page: number,
    size: number,
  ) =>
    `${MOBILE_SPEED_TEST_RESULT}/list?projectIdx=${projectIdx}&page=${page}&size=${size}`,

  getMobileSpeedTestResultDetail: (
    resultIdx: string,
    projectIdx: string,
    groupIdx: string,
    start: string,
    end: string,
  ) =>
    `${MOBILE_SPEED_TEST_RESULT}/${resultIdx}?project=${projectIdx}&group=${groupIdx}&start=${start}&end=${end}`,

  DETAIL_SPEED_MOBILE_TEST_RESULT_BY_IDX: `${MOBILE_SPEED_TEST_RESULT}/detail/`,

  // Speed Test Result > Web
  getWebSpeedTestResultList: (projectIdx: string, page: number) =>
    `${WEB_SPEED_TEST_RESULT}?projectIdx=${projectIdx}&page=${page}`,
  getWebSpeedTestResultDetail: (
    projectIdx: string,
    groupIdx: string,
    idx: string,
    start: string,
    end: string,
    orderBy: string,
    channel: string,
  ) =>
    `${WEB_SPEED_TEST_RESULT_DETAIL}?projectIdx=${projectIdx}&groupIdx=${groupIdx}&idx=${idx}&startDate=${start}&endDate=${end}&orderBy=${orderBy}&channel=${channel}`,
  getWebSpeedTestResultByIdx: (chartIdx: string) =>
    `${WEB_SPEED_TEST_RESULT_DETAIL}/repeat?resultIdx=${chartIdx}`,
  // getWebSpeedTestResult: (projectIdx: string, startDateFormat: string) => {
  //   return `${WEB_SPEED_TEST_RESULT}?projectIdx=${projectIdx}&targetDate=${startDateFormat}`;
  // },
  // getWebSpeedTestResultDetail: (chartIndex: number, projectIdx: string) => {
  //   return `${WEB_SPEED_TEST_RESULT}/${chartIndex}/detail?projectIdx=${projectIdx}`;
  // },

  // Access Log Controller
  SAVE_ACCESS_LOG: `${BASE_URL}/log`,

  INVITE: `${BASE_URL}/invite`,
  DECODE_KEY: `${BASE_URL}/user/decode-key`,

  // IDE File Download
  ideFileDownload: (os: 'win' | 'mac', arch: 'x64' | 'arm64') =>
    `${DOWNLOAD}?os=${os}&arch=${arch}`,

  // Send Auth Code
  SEND_AUTH_CODE: `${AUTH_CODE}send`,
  CONFIRM_AUTH_CODE: `${AUTH_CODE}confirm`,

  //Reset Password
  RESET_PASSWORD: `${RESET_PASSWORD}password-reset`,
};
