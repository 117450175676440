import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

export type GroupType = 'Test' | 'Service' | null;
interface GroupProviderContext {
  isEditModalOpened: boolean;
  setIsEditModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  openEditModal: () => void;
  closeEditModal: () => void;
  isTestGroupClicked: boolean;
  setIsTestGroupClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isServiceGroupClicked: boolean;
  setIsServiceGroupClicked: React.Dispatch<React.SetStateAction<boolean>>;
  tabGroupType: GroupType;
  setTabGroupType: React.Dispatch<React.SetStateAction<GroupType>>;
  clickGroupType: GroupType;
  setClickGroupType: React.Dispatch<React.SetStateAction<GroupType>>;
  openCreateGroup: boolean;
  setOpenCreateGroup: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GroupContext = createContext<GroupProviderContext>({
  isEditModalOpened: false,
  setIsEditModalOpened: () => {},
  openEditModal: () => {},
  closeEditModal: () => {},
  isTestGroupClicked: true,
  setIsTestGroupClicked: () => {},
  isServiceGroupClicked: false,
  setIsServiceGroupClicked: () => {},
  tabGroupType: 'Test',
  setTabGroupType: () => {},
  clickGroupType: 'Test',
  setClickGroupType: () => {},
  openCreateGroup: false,
  setOpenCreateGroup: () => {},
});

export const useGroupContext = () => {
  return useContext(GroupContext);
};

interface Props {
  children: ReactNode;
}
const GroupProvider = ({ children }: Props) => {
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [isTestGroupClicked, setIsTestGroupClicked] = useState(true);
  const [isServiceGroupClicked, setIsServiceGroupClicked] = useState(false);
  const [tabGroupType, setTabGroupType] = useState<GroupType>('Test');
  const [clickGroupType, setClickGroupType] = useState<GroupType>(null);
  const [openCreateGroup, setOpenCreateGroup] = useState(false);

  const openEditModal = () => {
    setIsEditModalOpened(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpened(false);
  };

  const contextValue = useMemo(
    () => ({
      isEditModalOpened,
      setIsEditModalOpened,
      openEditModal,
      closeEditModal,
      isTestGroupClicked,
      setIsTestGroupClicked,
      isServiceGroupClicked,
      setIsServiceGroupClicked,
      tabGroupType,
      setTabGroupType,
      clickGroupType,
      setClickGroupType,
      openCreateGroup,
      setOpenCreateGroup,
    }),
    [
      isEditModalOpened,
      isTestGroupClicked,
      isServiceGroupClicked,
      tabGroupType,
      clickGroupType,
      openCreateGroup,
    ],
  );

  return (
    <GroupContext.Provider value={contextValue}>
      {children}
    </GroupContext.Provider>
  );
};

export default GroupProvider;
