import {} from "@autosquare/common";
import {
  PriorityNameEnum,
  TestCaseDetailDataWithoutSteps,
} from "@customTypes/testCase/type";

import React from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";

interface Props {
  copyTestCases: TestCaseDetailDataWithoutSteps[];
  title: string;
  idx: number;
  priority: PriorityNameEnum;
  updatedAt: string;
  changeCardList: (list: TestCaseDetailDataWithoutSteps[]) => void;
}

let id = 1;

const DetailListItemCard = ({
  copyTestCases,
  title,
  idx,
  priority,
  updatedAt,
  changeCardList,
}: Props) => {
  const box = {
    title,
    idx,
  };

  const [, drag] = useDrag({
    type: "card",
    item() {
      const useless = copyTestCases.find(
        (copyTestCase) => copyTestCase.idx === -1
      );
      if (!useless) {
        changeCardList([
          ...copyTestCases,
          {
            title: "Move",
            idx: idx,
            priority: priority,
            updatedAt: updatedAt,
            dndIndex: -1,
          },
        ]);
      }
      return box;
    },
    end(_: unknown, monitor: DragSourceMonitor) {
      const uselessIndex = copyTestCases.findIndex(
        (copyTestCase) => copyTestCase.dndIndex === -1
      );

      if (monitor.didDrop()) {
        copyTestCases.splice(uselessIndex, 1, {
          ...monitor.getItem(),
          dndIndex: id++,
        });
      } else {
        copyTestCases.splice(uselessIndex, 1);
      }
      changeCardList(copyTestCases);
    },
  });

  return (
    <div
      ref={drag}
      className="flex items-center m-2 px-3 py-2 bg-gray-50 border border-solid border-border-line cursor-move"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 9h16.5m-16.5 6.75h16.5"
        />
      </svg>
      <div className="ml-7">{title}</div>
    </div>
  );
};

export default DetailListItemCard;
