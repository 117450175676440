import { useInput } from '@autosquare/common';
import { SpeedSchedulerTypeName } from '@customTypes/ide/speedScheduler/speedScheduler';
import { SpeedTestScenarioDataInScheduler } from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { useSpeedMobileTestScenarioListQuery } from '@lib/api/ide/speedMobile/speedMobileTestScenario';
import { useSpeedSchedulerDetailQuery } from '@lib/api/ide/speedScheduler/speedScheduler';
import { useSpeedWebTestScenarioListQuery } from '@lib/api/ide/speedWeb/webSpeedTestScenario';
import { useEffect, useMemo, useState } from 'react';

export const useSpeedTestSchedulerDraggableSpace = (
  type: 'create' | 'detail',
  testType: SpeedSchedulerTypeName,
) => {
  const [copiedTestScenarioList, setCopiedTestScenarioList] = useState<
    SpeedTestScenarioDataInScheduler[]
  >([]);
  const [processedTestScenarioList, setProcessedTestScenarioList] = useState<
    SpeedTestScenarioDataInScheduler[] | null
  >([]);
  const [searchWord, onChangeSearchWord, setSearchWord] = useInput('');

  const changeCardList = (list: SpeedTestScenarioDataInScheduler[]) => {
    setCopiedTestScenarioList([...list]);
  };

  const speedMobileTestScenarioListQuery = useSpeedMobileTestScenarioListQuery({
    enabled: testType === SpeedSchedulerTypeName.Mobile,
  });
  const speedMobileTestScenarioList = speedMobileTestScenarioListQuery.data;

  const speedWebTestScenarioListQuery = useSpeedWebTestScenarioListQuery({
    enabled: testType === SpeedSchedulerTypeName.Web,
  });
  const speedWebTestScenarioList = speedWebTestScenarioListQuery.data;

  const speedSchedulerDetailQuery = useSpeedSchedulerDetailQuery({
    enabled: type === 'detail',
  });

  useEffect(() => {
    if (testType === SpeedSchedulerTypeName.Mobile) {
      setProcessedTestScenarioList(
        speedMobileTestScenarioList?.map((speedTestScenario, index) => ({
          idx: speedTestScenario.idx,
          title: speedTestScenario.title,
          dndIndex: index + 1,
          uniqueId: crypto.randomUUID(),
        })),
      );
    }
  }, [speedMobileTestScenarioList, testType]);

  useEffect(() => {
    if (testType === SpeedSchedulerTypeName.Web) {
      setProcessedTestScenarioList(
        speedWebTestScenarioList?.map((speedTestScenario, index) => ({
          idx: speedTestScenario.idx,
          title: speedTestScenario.title,
          dndIndex: index + 1,
          uniqueId: crypto.randomUUID(),
        })),
      );
    }
  }, [speedWebTestScenarioList, testType]);

  useEffect(() => {
    if (
      type === 'detail' &&
      testType === SpeedSchedulerTypeName.Mobile &&
      speedSchedulerDetailQuery.isSuccess &&
      speedMobileTestScenarioListQuery.isSuccess
    ) {
      const scenarioIdxList = speedSchedulerDetailQuery.data?.scenario_idx_list;

      const filteredSpeedMobileTestScenarioList = scenarioIdxList?.map(
        (scenarioIdx) =>
          speedMobileTestScenarioList?.find(
            (testScenario) => testScenario.idx === scenarioIdx,
          ),
      );

      setCopiedTestScenarioList(
        filteredSpeedMobileTestScenarioList?.map(
          (speedTestScenario, index) => ({
            idx: speedTestScenario.idx,
            title: speedTestScenario.title,
            dndIndex: index + 1,
            uniqueId: crypto.randomUUID(),
          }),
        ),
      );
    }
  }, [
    speedSchedulerDetailQuery.isSuccess,
    speedMobileTestScenarioListQuery.isSuccess,
    testType,
    type,
  ]);

  useEffect(() => {
    if (
      type === 'detail' &&
      testType === SpeedSchedulerTypeName.Web &&
      speedSchedulerDetailQuery.isSuccess &&
      speedWebTestScenarioListQuery.isSuccess
    ) {
      const scenarioIdxList = speedSchedulerDetailQuery.data?.scenario_idx_list;

      const filteredSpeedWebTestScenarioList = scenarioIdxList?.map(
        (scenarioIdx) =>
          speedWebTestScenarioList?.find(
            (testScenario) => testScenario.idx === scenarioIdx,
          ),
      );

      setCopiedTestScenarioList(
        filteredSpeedWebTestScenarioList?.map((speedTestScenario, index) => ({
          idx: speedTestScenario.idx,
          title: speedTestScenario.title,
          dndIndex: index + 1,
          uniqueId: crypto.randomUUID(),
        })),
      );
    }
  }, [
    speedSchedulerDetailQuery.isSuccess,
    speedWebTestScenarioListQuery.isSuccess,
    testType,
    type,
  ]);

  useEffect(() => {
    if (type === 'create') {
      setCopiedTestScenarioList([]);
    }
  }, [type, testType]);

  const searchResultTestScenarioList = useMemo(
    () =>
      processedTestScenarioList?.filter((testScenario) =>
        testScenario.title.toLowerCase().includes(searchWord.toLowerCase()),
      ),
    [processedTestScenarioList, searchWord],
  );

  return {
    copiedTestScenarioList,
    setCopiedTestScenarioList,
    searchWord,
    onChangeSearchWord,
    setSearchWord,
    changeCardList,
    searchResultTestScenarioList,
    speedMobileTestScenarioList,
    speedWebTestScenarioList,
    isLoading:
      testType === SpeedSchedulerTypeName.Mobile
        ? speedMobileTestScenarioListQuery.isLoading
        : speedWebTestScenarioListQuery.isLoading,
    isError:
      testType === SpeedSchedulerTypeName.Mobile
        ? speedMobileTestScenarioListQuery.isError
        : speedWebTestScenarioListQuery.isError,
    error:
      testType === SpeedSchedulerTypeName.Mobile
        ? speedMobileTestScenarioListQuery.error
        : speedWebTestScenarioListQuery.error,
  };
};
