import React from 'react';
import { LiaUsb } from 'react-icons/lia';

type Props = {
  gridCols: number;
};
const SkeletonDeviceItem = ({ gridCols }: Props) => {
  return (
    <ul className="flex">
      {[...Array(gridCols)].map((_, index) => (
        <li
          key={index}
          className="mr-3 w-full max-w-xs animate-pulse rounded-md shadow-sm"
        >
          <div className="flex flex-col rounded-md border border-gray-200 p-3">
            <div className="flex">
              <div className="flex w-full items-center justify-center gap-2 text-sm font-medium text-gray-500">
                <div className="size-4 rounded border border-solid border-gray-300 "></div>
                <LiaUsb size="1.5rem" />
                <div className="flex-1 items-center justify-between truncate">
                  <div className="flex-1 truncate px-0 py-2 text-sm">
                    <div className="h-5 w-20 rounded bg-gray-200"></div>
                    <p className="mt-1 h-4 w-32 rounded bg-gray-200"></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 flex items-center justify-center gap-2">
              <div className="h-5 w-40 rounded-md bg-gray-200"></div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default SkeletonDeviceItem;
