import { SpeedTestResultTableHeader } from '@autosquare/common';
import { WebSpeedTestResultDetail } from '@customTypes/ide/speedTest/Web/testResult/type';
import dayjs from 'dayjs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  data: WebSpeedTestResultDetail;
};
const InformationTable = ({ data }: Props) => {
  const [searchParams] = useSearchParams();
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  const startDate = dayjs(start).format('YYYY-MM-DD');
  const endDate = dayjs(end).format('YYYY-MM-DD');
  const information = data?.envData;
  return (
    <div className="pb-4">
      <div className="pb-3 pt-6 text-sm font-semibold text-gray-900">
        information
      </div>
      <table className="min-w-full bg-white">
        <thead className="bg-gray-50">
          <tr className="border-b border-gray-200">
            <SpeedTestResultTableHeader width="text-left h-[3.625rem]">
              Tester
            </SpeedTestResultTableHeader>
            <SpeedTestResultTableHeader width="text-left ">
              Date
            </SpeedTestResultTableHeader>
            <SpeedTestResultTableHeader width="text-left">
              Browser
            </SpeedTestResultTableHeader>
            <SpeedTestResultTableHeader width="text-left">
              Title
            </SpeedTestResultTableHeader>
            <SpeedTestResultTableHeader width="text-left">
              Type
            </SpeedTestResultTableHeader>
          </tr>
        </thead>
        <tbody>
          <tr className="h-[3.625rem]">
            <td className="px-3 py-2 text-sm text-gray-900">
              {information?.tester}
            </td>
            <td className="px-3 py-2 text-sm text-gray-900">
              <div>
                {startDate} &#126; &#32;
                {endDate}
              </div>
            </td>
            <td className="px-3 py-2 text-left text-sm text-gray-900">
              {information?.browser}
            </td>
            <td className="px-3 py-2 text-left text-sm text-gray-900">
              {information?.title}
            </td>
            <td className="truncate px-3 py-2 text-left text-sm text-gray-900">
              {information?.type}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InformationTable;
