import { RunningCycle } from '@customTypes/ide/scheduler/scheduler';
import { hourCycle } from '@utils/static/runningCycle';
import { days } from '@utils/static/selectedDays';
import React, { useState } from 'react';
import CreateSpeedSchedulerDays from './CreateSpeedTestSchedulerSchedule/CreateSpeedSchedulerDays';
import CreateSpeedSchedulerPreview from './CreateSpeedTestSchedulerSchedule/CreateSpeedSchedulerPreview';
import CreateSpeedSchedulerRunningCycle from './CreateSpeedTestSchedulerSchedule/CreateSpeedSchedulerRunningCycle';
import CreateSpeedSchedulerStartDate from './CreateSpeedTestSchedulerSchedule/CreateSpeedSchedulerStartDate';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';

const CreateSpeedTestSchedulerSchedule = () => {
  const [selectedCycle, setSelectedCycle] = useState<RunningCycle>(
    hourCycle[0],
  );
  const [selectedDays, setSelectedDays] = useState<string[]>(days);

  return (
    <SpeedSchedulerGridContainer title="Schedule">
      <div className="grid grid-cols-3 items-start gap-x-8 gap-y-2">
        <CreateSpeedSchedulerStartDate />
        <CreateSpeedSchedulerRunningCycle
          selectedCycle={selectedCycle}
          setSelectedCycle={setSelectedCycle}
        />
        <CreateSpeedSchedulerPreview
          selectedCycle={selectedCycle}
          selectedDays={selectedDays}
        />
        <CreateSpeedSchedulerDays
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
        />
      </div>
    </SpeedSchedulerGridContainer>
  );
};

export default CreateSpeedTestSchedulerSchedule;
