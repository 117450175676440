import {
  CreateSpeedSchedulerData,
  CreateSpeedSchedulerSchema,
  SpeedSchedulerTypeName,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { Button, ErrorMessage } from '@autosquare/common';
import { useCreateSpeedSchedulerMutation } from '@lib/api/ide/speedScheduler/speedScheduler';

import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from 'react-router-dom';

import CreateSpeedTestSchedulerSchedule from './CreateSpeedTestScheduleForm/CreateSpeedTestSchedulerSchedule';
import CreateSpeedTestSchedulerBrowser from './CreateSpeedTestScheduleForm/CreateSpeedTestSchedulerBrowser';
import CreateSpeedTestSchedulerDevice from './CreateSpeedTestScheduleForm/CreateSpeedTestSchedulerDevice';
import CreateSpeedTestSchedulerScenario from './CreateSpeedTestScheduleForm/CreateSpeedTestSchedulerScenario';
import CreateSpeedTestSchedulerTestType from './CreateSpeedTestScheduleForm/CreateSpeedTestSchedulerTestType';
import CreateSpeedTestSchedulerTitle from './CreateSpeedTestScheduleForm/CreateSpeedTestSchedulerTitle';
import CreateSpeedTestSchedulerRepeat from './CreateSpeedTestScheduleForm/CreateSpeedTestSchedulerRepeat';
import CreateSpeedTestSchedulerEmailAlarm from './CreateSpeedTestScheduleForm/CreateSpeedTestSchedulerEmailAlarm';

const CreateSpeedTestScheduleForm = () => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  const methods = useForm<CreateSpeedSchedulerData>({
    defaultValues: {
      projectIdx: Number(projectIdx),
      type: SpeedSchedulerTypeName.Mobile,
      repeatNum: 12,
      mailReceivers: [],
      autoRegister: true,
    },
    resolver: zodResolver(CreateSpeedSchedulerSchema),
  });
  const createSpeedSchedulerMutation = useCreateSpeedSchedulerMutation();

  const onSubmit: SubmitHandler<CreateSpeedSchedulerData> = (data) => {
    if (
      data.type === SpeedSchedulerTypeName.Mobile &&
      data.deviceInfoDto.serialNumber === undefined
    ) {
      methods.setError('deviceInfoDto', {
        type: 'required',
        message: 'Device를 선택해 주세요.',
      });
    } else if (
      data.type === SpeedSchedulerTypeName.Web &&
      !data.browserInfoDto
    ) {
      methods.setError('browserInfoDto', {
        type: 'required',
        message: 'Browser를 선택해 주세요.',
      });
    } else if (data.scenarioIdxList.length === 0) {
      methods.setError('scenarioIdxList', {
        type: 'required',
        message: '시나리오를 선택해 주세요.',
      });
    } else {
      createSpeedSchedulerMutation.mutate(data);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="mt-4 flex flex-col gap-8 py-4 text-sm font-medium leading-normal text-gray-900"
      >
        <CreateSpeedTestSchedulerTestType />
        <CreateSpeedTestSchedulerTitle />
        <CreateSpeedTestSchedulerScenario />
        <CreateSpeedTestSchedulerDevice />
        <CreateSpeedTestSchedulerBrowser />
        <CreateSpeedTestSchedulerRepeat />
        <CreateSpeedTestSchedulerSchedule />
        <CreateSpeedTestSchedulerEmailAlarm />
        <div className="flex items-center justify-end gap-6 border-t border-solid border-gray-200 py-4">
          {createSpeedSchedulerMutation.isError && (
            <ErrorMessage>
              {createSpeedSchedulerMutation.error.message}
            </ErrorMessage>
          )}
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={createSpeedSchedulerMutation.isLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateSpeedTestScheduleForm;
