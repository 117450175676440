import { ErrorMessage, ListSkeleton } from '@autosquare/common';

import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { DisclosurePanel } from '@headlessui/react';

import { useMobileTestCaseExecutionDetailQuery } from '@lib/api/ide/mobile/mobileTestResult';
import { useWebTestCaseExecutionDetailQuery } from '@lib/api/ide/web/webTestResult';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import MediaAndLogHandler from './TestStepResult/MediaAndLogHandler';
import TestStepResultDetail from './TestStepResult/TestStepResultDetail';

type Props = {
  caseResultIdx: number;
  videoPath: string;
  logPath: string;
  imagePaths: string[];
  result: 'Pass' | 'Fail' | 'Skip' | 'N/A';
};

const TestStepResult = ({
  caseResultIdx,
  imagePaths,
  videoPath,
  logPath,
  result,
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const testResultDetailInquiryByCaseQuery = isWordInLocationPathname('mobile')
    ? useMobileTestCaseExecutionDetailQuery(idx, caseResultIdx)
    : useWebTestCaseExecutionDetailQuery(idx, caseResultIdx);
  const testResultDetailInquiryByCase = testResultDetailInquiryByCaseQuery.data;

  return (
    <DisclosurePanel>
      <div className="grid grid-cols-2 pb-10">
        {testResultDetailInquiryByCaseQuery.isLoading ||
        testResultDetailInquiryByCaseQuery.isFetching ? (
          <ListSkeleton />
        ) : testResultDetailInquiryByCaseQuery.isError ? (
          <ErrorMessage>
            {testResultDetailInquiryByCaseQuery.error.message}
          </ErrorMessage>
        ) : (
          <>
            <TestStepResultDetail
              testResultDetailInquiryByCase={testResultDetailInquiryByCase}
            />
            {result !== 'N/A' && (
              <MediaAndLogHandler
                imagePaths={imagePaths}
                videoPath={videoPath}
                logPath={logPath}
              />
            )}
          </>
        )}
      </div>
    </DisclosurePanel>
  );
};

export default TestStepResult;
