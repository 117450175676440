import React from 'react';

type Props = {
  tableHeaderLength: number;
  tableBodyColumLength?: number;
};

const SchedulerListTableBodySkeleton = ({
  tableHeaderLength,
  tableBodyColumLength = 10,
}: Props) => {
  return (
    <>
      {Array.from({ length: tableBodyColumLength }, (_, i) => (
        <tr key={i}>
          <td className="px-3 py-4">
            <div className="size-4 animate-pulse rounded bg-gray-200" />
          </td>
          {Array.from({ length: tableHeaderLength - 1 }, (_, j) => (
            <td className="px-3 py-4" key={j}>
              <div className="h-5 w-full animate-pulse rounded bg-gray-200" />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default SchedulerListTableBodySkeleton;
