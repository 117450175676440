import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { axiosInstance } from '../';
import { IDEAPI } from '@api/ideEndpoints';
import { webDriverAgentControllerKeys } from '@queries/ide/webDriverAgentControllerKeys';
import { DerivedData } from '@customTypes/setting/type';

// GET
const getDerivedData = async (): Promise<DerivedData[]> => {
  const response = await axiosInstance.get<DerivedData[]>(IDEAPI.DOWNLOAD_WDA);
  return response.data;
};
export const useDownloadDerivedDataQuery = (
  options?: UseQueryOptions<DerivedData[], Error>,
) => {
  return useQuery<DerivedData[], Error>({
    queryKey: webDriverAgentControllerKeys.downloads(),
    queryFn: () => getDerivedData(),
    ...options,
  });
};

type UploadDerivedDataVariables = {
  idx: number;
  data: FormData;
};

// POST
const uploadDerivedData = async (
  projectIdx: number,
  data: FormData,
): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    IDEAPI.UPLOAD_WDA + projectIdx,
    data,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return response.data;
};
export const useUploadDerivedDataMutation = () => {
  return useMutation<unknown, Error, UploadDerivedDataVariables>({
    mutationFn: (data) => uploadDerivedData(data.idx, data.data),
  });
};
