import React from "react";

import { validateEmail } from "@autosquare/common";

type Props = {
  email: string;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const AddSystemUsersForm = ({ email, handleSubmit, onChangeEmail }: Props) => {
  return (
    <form action="#" className="mt-6 flex" onSubmit={handleSubmit}>
      <label htmlFor="email" className="sr-only">
        Email address
      </label>
      <input
        type="email"
        name="email"
        id="email"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        placeholder="Enter an email"
        onChange={onChangeEmail}
      />
      <button
        type="submit"
        className={`ml-4 flex-shrink-0 rounded-md bg-congress-blue px-3 py-2 text-sm font-semibold text-white shadow-sm ${
          validateEmail(email) && "hover:bg-indigo-500"
        } `}
        disabled={!validateEmail(email)}
      >
        Send invite
      </button>
    </form>
  );
};

export default AddSystemUsersForm;
