import React, { useEffect, useState } from 'react';

import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';

import DeviceListBox from '@components/Scheduler/DeviceListBox';

import { DeviceInfo } from '@customTypes/ide/device/device';
import {
  CreateSchedulerData,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

const CreateSchedulerDevice = () => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSchedulerData>();

  const testType = watch('type');

  const [deviceInfoDto, setDeviceInfoDto] = useState<DeviceInfo>(undefined);
  const deviceListQuery = useDeviceListQuery({
    enabled: testType === SchedulerTypeServerName.Mobile,
  });

  const handleChange = (selectedDevice: DeviceInfo) => {
    setDeviceInfoDto(selectedDevice);
    setValue('device_info_dto', selectedDevice);
  };

  useEffect(() => {
    if (testType === SchedulerTypeServerName.Web) {
      setDeviceInfoDto(undefined);
    }
  }, [testType]);

  useEffect(() => {
    if (deviceInfoDto) {
      clearErrors('device_info_dto');
    }
  }, [deviceInfoDto]);

  return (
    testType === SchedulerTypeServerName.Mobile && (
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
        <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
          {'Device'}
        </label>
        <div className="mt-2 flex flex-col items-start gap-x-4 sm:col-span-2 sm:mt-0">
          <DeviceListBox
            deviceInfoDto={deviceInfoDto}
            handleChange={handleChange}
            deviceListQuery={deviceListQuery}
          />
          <ErrorMessage
            name="device_info_dto"
            errors={errors}
            render={({ message }) => (
              <p className="standard-error-message">{message}</p>
            )}
          />
        </div>
      </div>
    )
  );
};

export default CreateSchedulerDevice;
