import KeyNameAndFromAndValue from '@components/shared/MobileWebTestStep/CreateAndUpdate/KeyNameAndFromAndValue';
import { UpdateMobileWebSpeedTestStepData } from '@customTypes/ide/speedTest/Mobile/webTestStep/type';

import React from 'react';
import { useFormContext } from 'react-hook-form';

const UpdateKeyNameAndFromAndValue = () => {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateMobileWebSpeedTestStepData>();

  return (
    <KeyNameAndFromAndValue
      watch={watch}
      setValue={setValue}
      register={register}
      errors={errors}
      control={control}
    />
  );
};

export default UpdateKeyNameAndFromAndValue;
