import { MobileWebTestStepDataOptionsDto } from '@customTypes/ide/speedTest/Mobile/webTestStep/type';

import { Dispatch, SetStateAction } from 'react';

export const hasDuplicateTitle = (
  arr: MobileWebTestStepDataOptionsDto[],
  setState?: Dispatch<SetStateAction<number>>,
) =>
  arr.some((item, index) => {
    setState && setState(index);
    return arr.findIndex((obj) => obj.keyName === item.keyName) !== index;
  });
