import AddTestStepConditionHeader from '@components/shared/TestStep/AddTestStepConditionHeader';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { ClickId } from '@utils/static/clickOptions';
import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import IfActionFail from './IfCommand/IfActionFail';
import IfActionPass from './IfCommand/IfActionPass';
import IfCondition from './IfCommand/IfCondition';

const IfCommand = () => {
  const { setValue, resetField, watch } =
    useFormContext<CreateWebTestStepData>();

  const failActionValue = watch('optionsDto.failActionValue');

  const [passCommand, setPassCommand] =
    useState<WebCommandOptionServerName>(undefined);
  const [failCommand, setFailCommand] =
    useState<WebCommandOptionServerName>(undefined);

  useEffect(() => {
    if (
      failCommand !== WebCommandOptionServerName.Command &&
      failActionValue !== undefined
    ) {
      setValue('value', `${passCommand},${failCommand}`);
    } else {
      setValue('value', `${passCommand}`);
    }

    if (
      passCommand === WebCommandOptionServerName.Click ||
      (failCommand === WebCommandOptionServerName.Click &&
        failActionValue !== undefined)
    ) {
      setValue('optionsDto.clickBy', ClickId.Default);
    }

    if (
      passCommand !== WebCommandOptionServerName.Click &&
      failCommand !== WebCommandOptionServerName.Click
    ) {
      setValue('optionsDto.clickBy', undefined);
    }

    return () => {
      resetField('value');
      resetField('optionsDto.clickBy');
    };
  }, [passCommand, failCommand, failActionValue, setValue]);

  useEffect(() => {
    setValue('required', true);
  }, [setValue]);

  const valueDefaultText = (command: WebCommandOptionServerName): string => {
    if (command === WebCommandOptionServerName.Click) return 'Select Element';
    if (command === WebCommandOptionServerName.KeyBoardEvent)
      return 'Select KeyBoard Event';
    if (command === WebCommandOptionServerName.ImportTestCase)
      return 'Select Test Case';

    return 'Value';
  };

  return (
    <>
      <IfCondition />
      <IfActionPass
        passCommand={passCommand}
        setPassCommand={setPassCommand}
        valueDefaultText={valueDefaultText}
      />
      <IfActionFail
        passCommand={passCommand}
        failCommand={failCommand}
        setFailCommand={setFailCommand}
        valueDefaultText={valueDefaultText}
      />
      <AddTestStepConditionHeader
        header={'Options'}
        description={'기타 옵션 값을 설정합니다.'}
      />
    </>
  );
};

export default IfCommand;
