import { DownloadButton, ImageSkeletonLoader } from '@autosquare/common';

import TestResultDetailError from '@components/shared/Error/TestResultDetailError';

import { useWebTestResultImageData } from '@lib/api/ide/web/webTestResult';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { downloadBlob } from '@utils/static/downloadBlob';

import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TabPanel } from '@headlessui/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface Props {
  imagePaths: string[];
}

const WebResultDetail = ({ imagePaths }: Props) => {
  const webImageData = useWebTestResultImageData(imagePaths, {
    enabled: isWordInLocationPathname('web'),
  });
  const webImageSrc = webImageData?.data;

  return (
    <TabPanel className="py-2">
      {webImageData.isLoading ? (
        <ImageSkeletonLoader />
      ) : webImageData.isError ? (
        <TestResultDetailError errorMessage={webImageData.error.message} />
      ) : (
        <Swiper
          slidesPerView={1}
          loop={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          className="size-full"
        >
          {webImageSrc?.map((imagePath, index) => (
            <SwiperSlide
              key={imagePath}
              className="flex justify-center bg-white text-center text-sm"
            >
              <div className="flex items-center justify-center pb-8">
                <div className="flex flex-col items-center justify-center">
                  {imagePath !== null && (
                    <DownloadButton
                      onClick={() =>
                        downloadBlob(
                          imagePath,
                          imagePaths[index].split('/').pop(),
                        )
                      }
                    />
                  )}
                  <img
                    src={imagePath}
                    alt="screenshot"
                    className="block aspect-auto w-2/4 object-cover"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </TabPanel>
  );
};

export default WebResultDetail;
