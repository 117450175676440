import {
  Button,
  Checkbox,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  TableHeader,
  TableNoList,
  TableWrapper,
  useCheckbox,
} from '@autosquare/common';

import React from 'react';

import SchedulerListTableBody from './SchedulerListTableBody';
import {
  useDeleteSchedulerMutation,
  useSchedulerListQuery,
} from '@lib/api/ide/scheduler/scheduler';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import SchedulerListTableBodySkeleton from '@components/Scheduler/SchedulerListTableBodySkeleton';

const SchedulerListTable = () => {
  const schedulerListQuery = useSchedulerListQuery();
  const schedulerLists = schedulerListQuery.data;

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(schedulerLists, 'idx');

  const headerTitles = [
    <Checkbox
      checked={selectedArray.length > 0}
      onChange={selectAllArray}
      key="selected-checkbox"
    />,
    'No',
    selectedArray.length > 0 ? '         ' : 'Test Type',
    'Environment',
    'Title',
    'Scenario Count',
    'Schedule',
    'Status',
    'Detail',
  ];
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );
  const deleteSchedulerMutation = useDeleteSchedulerMutation(cookie);

  return (
    <>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {headerTitles?.map((headerTitle, idx) => (
              <TableHeader key={idx} isFirstPaddingZero={false}>
                {headerTitle}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {schedulerListQuery.isLoading ? (
            <SchedulerListTableBodySkeleton
              tableHeaderLength={headerTitles.length}
            />
          ) : schedulerListQuery.isError ? (
            <ErrorMessage>{schedulerListQuery.error.message}</ErrorMessage>
          ) : schedulerLists?.length === 0 ? (
            <TableNoList colSpan={headerTitles?.length}>
              추가된 스케줄러가 없습니다.
            </TableNoList>
          ) : (
            schedulerLists?.map((schedulerList, idx) => (
              <SchedulerListTableBody
                key={schedulerList?.idx}
                idx={idx}
                schedulerList={schedulerList}
                selectArray={selectArray}
                selectedArray={selectedArray}
              />
            ))
          )}
        </tbody>
      </TableWrapper>
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={deleteSchedulerMutation.isError ? 'Error' : 'Delete Scheduler'}
        subTitle={
          deleteSchedulerMutation.isError
            ? deleteSchedulerMutation.error.message
            : '해당 스케쥴러를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteSchedulerMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteSchedulerMutation.mutate(
                    { idxList: selectedArray },
                    {
                      onSuccess: () => {
                        closeDialog();
                        resetSelectedArray();
                      },
                      onError: () => {
                        openDialog();
                      },
                    },
                  )
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

export default SchedulerListTable;
