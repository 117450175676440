import { Button, ErrorMessage } from '@autosquare/common';

import React, { useEffect, useState } from 'react';

const XCodeLocation = () => {
  const [initialXcodeFilePath, setInitialXcodeFilePath] = useState('');
  const [xcodeFilePath, setXcodeFilePath] = useState('');
  const [xcodeSelectReturnMessage, setXcodeSelectReturnMessage] = useState('');

  useEffect(() => {
    const getXcodeLocation = async () => {
      const xcodeSelect: string = await window.electron.invoke('xcodeLocation');
      if (xcodeSelect.includes('.app')) {
        const splitAppString = xcodeSelect.split('.app')[0] + '.app';
        setXcodeFilePath(splitAppString);
        setInitialXcodeFilePath(splitAppString);
      } else {
        setXcodeFilePath(xcodeSelect);
        setInitialXcodeFilePath(xcodeSelect);
      }
    };

    getXcodeLocation();

    window.electron.on('xcodeSelectReturnMessage', (returnMessage: string) => {
      setXcodeSelectReturnMessage(returnMessage);
      if (returnMessage !== 'success') {
        setXcodeFilePath(initialXcodeFilePath);
      }
    });
  }, []);

  const getXcodeFilePath = async () => {
    try {
      const xcodePath: string[] = await window.electron.invoke('xcodeAppPath');

      setXcodeFilePath(
        xcodePath ? xcodePath[0] : 'Xcode installation location not found',
      );

      if (xcodePath && xcodeFilePath !== xcodePath[0]) {
        window.electron.send('xcodeSelect', xcodePath[0]);
        setXcodeSelectReturnMessage('');
      }
    } catch (error) {
      setXcodeSelectReturnMessage(error);
      setXcodeFilePath(initialXcodeFilePath);
    }
  };

  useEffect(() => {
    if (xcodeSelectReturnMessage === 'success') {
      const timer = setTimeout(() => {
        setXcodeSelectReturnMessage('');
      }, 5 * 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [xcodeSelectReturnMessage]);

  return (
    <div className="mt-10 pb-5">
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          Xcode
        </h3>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Location
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div className="col-span-full">
                <div className="flex items-center gap-x-3">
                  <label className="block">
                    {xcodeFilePath.includes('.app')
                      ? xcodeFilePath
                      : 'Xcode installation location not found'}
                  </label>
                  <Button
                    type="button"
                    variant="secondary"
                    buttonSize="sm"
                    onClick={getXcodeFilePath}
                  >
                    Change
                  </Button>
                </div>
              </div>
            </dd>
          </div>
        </dl>
        <ErrorMessage>{xcodeSelectReturnMessage}</ErrorMessage>
      </div>
    </div>
  );
};

export default XCodeLocation;
