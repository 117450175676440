import { Button } from '@autosquare/common';
import React from 'react';

type Props = {
  cancelOnClick: () => void;
  saveDisabled: boolean;
};

const TestStepHeader = ({ cancelOnClick, saveDisabled }: Props) => {
  return (
    <div className="flex items-center justify-between border-b border-solid border-gray-200 pb-3.5">
      <h3 className="text-base font-semibold leading-normal text-gray-900">
        Test Step
      </h3>
      <div className="flex items-center gap-4">
        <Button type="button" variant="secondary" onClick={cancelOnClick}>
          Cancel
        </Button>
        <Button type="submit" disabled={saveDisabled}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default TestStepHeader;
