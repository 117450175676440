import {
  Button,
  Checkbox,
  ErrorMessage,
  useCheckbox,
} from '@autosquare/common';

import React, { useCallback, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import TestStepListTableHeader from './TestStepListTable/TestStepListTableHeader';
import TestStepListTableBody from './TestStepListTable/TestStepListTableBody';
import {
  useGetSpeedMobileTestStep,
  useUpdateOrderSpeedMobileTestStep,
} from '@lib/api/ide/speedMobile/speedMobileTestStep';
import TestStepListRepeatBox from '../../../MobileSpeedTestCase/components/TestStepList/TestStepListTable/TestStepListRepeatBox';
import { useSearchParams } from 'react-router-dom';
import SpeedExecutionAndDialog from './TestStepListTable/SpeedExecutionAndDialog';

interface Props {
  title: DeviceInfoOs;
}

const TestStepListTable = ({ title }: Props) => {
  const [isTestStepExecutionOpened, setIsTestStepExecutionOpened] =
    useState(false);
  const [lists, setLists] = useState<MobileTestStepData[]>([]);
  const [indexArray, setIndexArray] = useState<number[]>(null);

  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const speedMobileTestStepQuery = useGetSpeedMobileTestStep();
  const speedMobileTestStepList = speedMobileTestStepQuery.data;

  const startStepIdx =
    title === DeviceInfoOs.Aos
      ? speedMobileTestStepList?.aosRepeatInfo?.startIdx
      : speedMobileTestStepList?.iosRepeatInfo?.startIdx;
  const endStepIdx =
    title === DeviceInfoOs.Aos
      ? speedMobileTestStepList?.aosRepeatInfo?.endIdx
      : speedMobileTestStepList?.iosRepeatInfo?.endIdx;

  const {
    openDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    closeDialog,
    resetSelectedArray,
  } = useCheckbox(
    title === DeviceInfoOs.Aos
      ? speedMobileTestStepList?.aos
      : speedMobileTestStepList?.ios,
    'idx',
  );

  useEffect(() => {
    setLists(
      title === DeviceInfoOs.Aos
        ? speedMobileTestStepList?.aos
        : speedMobileTestStepList?.ios,
    );
  }, [speedMobileTestStepList, title]);

  const updateTestStepListSequenceMutation = useUpdateOrderSpeedMobileTestStep({
    idx: idx,
    mobileOs: title,
    step: indexArray,
    startStepIdx: startStepIdx ?? 0,
    endStepIdx: endStepIdx ?? 0,
  });

  useEffect(() => {
    if (indexArray) {
      updateTestStepListSequenceMutation.mutate();
    }
  }, [indexArray]);

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const items = Array.from(lists);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLists(items);
      setIndexArray(items.map((items) => items.idx));
    },
    [lists],
  );
  const tableTitles = [
    {
      idx: 1,
      element: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
        />
      ),
    },
    { idx: 2, element: '#' },
    { idx: 3, element: selectedArray.length > 0 ? '       ' : 'Command' },
    { idx: 4, element: 'Description' },
  ];
  return (
    <>
      {speedMobileTestStepQuery.isError ? (
        <ErrorMessage>{speedMobileTestStepQuery.error.message}</ErrorMessage>
      ) : (
        <div className="mt-6 grid-cols-2">
          <h3 className="text-base font-semibold">
            {title === DeviceInfoOs.Aos ? 'Android' : DeviceInfoOs.Ios && 'iOS'}
          </h3>
          <div className="flow-root">
            <div className="-mx-4 -my-2 scrollbar-thin sm:-mx-6 lg:-mx-8">
              <div className="block min-w-full py-4 align-middle sm:px-6 lg:px-8">
                {speedMobileTestStepQuery.isSuccess &&
                title === DeviceInfoOs.Aos ? (
                  <TestStepListRepeatBox title={DeviceInfoOs.Aos} />
                ) : (
                  speedMobileTestStepQuery.isSuccess &&
                  title === DeviceInfoOs.Ios && (
                    <TestStepListRepeatBox title={DeviceInfoOs.Ios} />
                  )
                )}
                {selectedArray ? (
                  <div className="relative">
                    {selectedArray.length > 0 && (
                      <div className="div-delete-all left-16 2xl:left-20">
                        <Button
                          type="button"
                          variant="secondary"
                          buttonSize="xs"
                          onClick={openDialog}
                        >
                          Delete all
                        </Button>
                      </div>
                    )}
                  </div>
                ) : null}
                <table className="h-full min-w-full divide-y divide-gray-300">
                  <TestStepListTableHeader tableTitles={tableTitles} />
                  <TestStepListTableBody
                    title={title}
                    mobileTestStepList={speedMobileTestStepList}
                    tableHeaderLength={tableTitles.length}
                    handleOnDragEnd={handleOnDragEnd}
                    selectArray={selectArray}
                    selectedArray={selectedArray}
                    lists={lists}
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <SpeedExecutionAndDialog
        isTestStepExecutionOpened={isTestStepExecutionOpened}
        setIsTestStepExecutionOpened={setIsTestStepExecutionOpened}
        title={title}
        isOpenedCautionDialog={isOpenedCautionDialog}
        setIsOpenedCautionDialog={setIsOpenedCautionDialog}
        selectedArray={selectedArray}
        openDialog={openDialog}
        closeDialog={closeDialog}
        resetSelectedArray={resetSelectedArray}
      />
    </>
  );
};

export default TestStepListTable;
