export enum ExtractTextFormatTypeName {
  Number = 'Number',
}

export enum ExtractTextFormatTypeServerName {
  Number = 'number',
}

export type ExtractTextFormatType = {
  idx: number;
  name: ExtractTextFormatTypeName;
  serverName: ExtractTextFormatTypeServerName;
};
