import { QuestionsMarkCircle, TooltipTop } from '@autosquare/common';

import { isMacOs } from '@utils/static/isMacOs';

import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import {
  AddServerName,
  FromServerName,
  SeparatorServerName,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TextReplaceFromAndAdd from './TextReplace/TextReplaceFromAndAdd';
import TextReplaceDateFormat from './TextReplace/TextReplaceDateFormat';

const TextReplace = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const { watch, control, setValue, resetField } =
    useFormContext<CreateTestStepData>();

  const mobileOS = watch('mobileOs');
  const value = watch('value');
  const textReplace = watch('optionsDto.textReplace');

  const isIncludesTextOrLabel =
    mobileOS === DeviceInfoOs.Aos
      ? value?.includes('@text')
      : mobileOS === DeviceInfoOs.Ios && value?.includes('@label');

  useEffect(() => {
    if (!isIncludesTextOrLabel) {
      setValue('optionsDto.textReplace', 'false');
    }
  }, [isIncludesTextOrLabel, setValue]);

  useEffect(() => {
    if (textReplace === 'true') {
      setValue('optionsDto.textReplaceOptions.from', FromServerName.Today);
      setValue('optionsDto.textReplaceOptions.addKey', AddServerName.Day);
      setValue('optionsDto.textReplaceOptions.addValue', 0);
      setValue(
        'optionsDto.textReplaceOptions.separator',
        SeparatorServerName.None,
      );
    }

    return () => {
      resetField('optionsDto.textReplaceOptions.from');
      resetField('optionsDto.textReplaceOptions.addKey');
      resetField('optionsDto.textReplaceOptions.addValue');
      resetField('optionsDto.textReplaceOptions.separator');
    };
  }, [textReplace, setValue, resetField]);

  useEffect(() => {
    return () => resetField('optionsDto.textReplace');
  }, [resetField]);

  return (
    <>
      <div
        className={`relative flex items-center justify-between pr-4 ${textReplace === 'true' ? 'gap-4 border-b border-gray-200 pb-2' : ''}`}
      >
        <div className="flex items-center justify-between gap-2">
          <p className="text-sm font-medium leading-normal text-gray-900">
            Text Replace
          </p>
          <div className="flex items-center">
            <button
              type="button"
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
            >
              <QuestionsMarkCircle />
            </button>
          </div>
          {tooltipVisible && (
            <TooltipTop
              positionClass={`left-0 ${isMacOs ? 'after:left-[39%]' : 'after:left-[34.5%]'} ${textReplace === 'true' ? 'bottom-10' : 'bottom-8'}`}
            >
              텍스트를 추출하여 값을 변환하는 기능입니다.
            </TooltipTop>
          )}
        </div>
        <Controller
          control={control}
          name="optionsDto.textReplace"
          render={({ field }) => (
            <SwitchInController
              checked={field.value === 'true'}
              onChange={(checked: boolean) =>
                field.onChange(checked ? 'true' : 'false')
              }
              disabled={!isIncludesTextOrLabel}
            />
          )}
        />
      </div>
      {textReplace === 'true' && (
        <div className="flex flex-col gap-4 text-sm font-medium leading-normal text-gray-900">
          <TextReplaceFromAndAdd />
          <TextReplaceDateFormat />
        </div>
      )}
    </>
  );
};

export default TextReplace;
