import {
  Button,
  Checkbox,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  TableHeader,
  TableNoList,
  TableWrapper,
  useCheckbox,
} from '@autosquare/common';
import SchedulerListTableBodySkeleton from '@components/Scheduler/SchedulerListTableBodySkeleton';

import {
  useDeleteSpeedSchedulerMutation,
  useSpeedSchedulerListQuery,
  useUpdateSpeedSchedulerStatusMutation,
} from '@lib/api/ide/speedScheduler/speedScheduler';
import React, { useState } from 'react';

import SpeedTestSchedulerTableBody from './SpeedTestSchedulerTable/SpeedTestSchedulerTableBody';

const SpeedTestSchedulerTable = () => {
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);

  const speedSchedulerListQuery = useSpeedSchedulerListQuery();
  const speedSchedulerList = speedSchedulerListQuery.data;

  const updateSpeedSchedulerStatusMutation =
    useUpdateSpeedSchedulerStatusMutation();
  const deleteSpeedSchedulerMutation = useDeleteSpeedSchedulerMutation();

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(speedSchedulerList, 'idx');

  const headerTitles = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={selectAllArray}
          key="selected-checkbox"
        />
      ),
    },
    { idx: 2, title: selectedArray.length > 0 ? '' : 'No' },
    { idx: 3, title: selectedArray.length > 0 ? '' : 'Test Type' },
    { idx: 4, title: 'Environment' },
    { idx: 5, title: 'Title' },
    { idx: 6, title: 'Scenario Count' },
    { idx: 7, title: 'Schedule' },
    { idx: 8, title: 'Status' },
    { idx: 9, title: 'Detail' },
  ];
  return (
    <>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {headerTitles?.map((headerTitle) => (
              <TableHeader key={headerTitle.idx}>
                {headerTitle.title}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {speedSchedulerListQuery.isLoading ? (
            <SchedulerListTableBodySkeleton
              tableHeaderLength={headerTitles.length}
            />
          ) : speedSchedulerListQuery.isError ? (
            <ErrorMessage>{speedSchedulerListQuery.error.message}</ErrorMessage>
          ) : speedSchedulerList.length === 0 ? (
            <TableNoList colSpan={headerTitles.length}>
              추가된 스케줄러가 없습니다.
            </TableNoList>
          ) : (
            speedSchedulerList.map((speedScheduler, idx) => (
              <SpeedTestSchedulerTableBody
                key={speedScheduler.idx}
                idx={idx}
                speedScheduler={speedScheduler}
                selectedArray={selectedArray}
                selectArray={selectArray}
                updateSpeedSchedulerStatusMutation={
                  updateSpeedSchedulerStatusMutation
                }
                setIsErrorModalOpened={setIsErrorModalOpened}
              />
            ))
          )}
        </tbody>
      </TableWrapper>
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={
          deleteSpeedSchedulerMutation.isError ? 'Error' : 'Delete Scheduler'
        }
        subTitle={
          deleteSpeedSchedulerMutation.isError
            ? deleteSpeedSchedulerMutation.error.message
            : '해당 스케쥴러를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteSpeedSchedulerMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteSpeedSchedulerMutation.mutate(
                    { idxList: selectedArray },
                    {
                      onSuccess: () => {
                        closeDialog();
                        resetSelectedArray();
                      },
                      onError: () => {
                        openDialog();
                      },
                    },
                  )
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isErrorModalOpened}
        setIsOpened={setIsErrorModalOpened}
        type="caution"
        title={'Error'}
        subTitle={
          updateSpeedSchedulerStatusMutation.isError &&
          updateSpeedSchedulerStatusMutation.error.message
        }
        buttonChildren={
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsErrorModalOpened(false)}
          >
            Cancel
          </Button>
        }
      />
    </>
  );
};

export default SpeedTestSchedulerTable;
