import { RunningCycle } from '@customTypes/ide/scheduler/scheduler';
import dayjs from 'dayjs';

export const createCronExpression = (
  startTime: string,
  selectedDays: string[],
  selectedCycle: RunningCycle,
): string => {
  let cronExpression: string;
  const startHour: number = dayjs(startTime).hour();
  const startMinute: number = dayjs(startTime).minute();

  const daysOfWeek = selectedDays.length === 7 ? '?' : selectedDays.join(',');
  if (selectedCycle.title.includes('hour')) {
    const everyHour = selectedCycle.title.trim().replace(/[^0-9]/g, '');
    if (everyHour === '24') {
      if (daysOfWeek === '?') {
        cronExpression = `0 ${startMinute} ${startHour} * * ?`;
      } else {
        cronExpression = `0 ${startMinute} ${startHour} ? * ${daysOfWeek}`;
      }
    } else {
      if (daysOfWeek === '?') {
        cronExpression = `0 ${startMinute} 0/${everyHour} * * ?`;
      } else {
        cronExpression = `0 ${startMinute} 0/${everyHour} ? * ${daysOfWeek}`;
      }
    }
  } else {
    const everyMinute = selectedCycle.title.trim().replace(/[^0-9]/g, '');
    if (daysOfWeek === '?') {
      cronExpression = `0 0/${everyMinute} * * * ?`;
    } else {
      cronExpression = `0 0/${everyMinute} * ? * ${daysOfWeek}`;
    }
  }

  return cronExpression;
};
