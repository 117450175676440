import {
  UseCheckboxAttribute,
  Detail,
  Execution,
  TableBodyBasic,
  TableHeader,
  TableNoList,
  Checkbox,
  TableWrapper,
  ScenarioListDetailData,
  Time,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';
import { timePattern } from '@utils/static/timePattern';

interface Props extends UseCheckboxAttribute {
  scenarioLists: ScenarioListDetailData[];
}

const ScenarioListTable = ({
  scenarioLists,
  selectAllArray,
  selectArray,
  selectedArray,
  openDialog,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testScenarioIdx, setTestScenarioIdx] = useState<number>(0);

  const tableHeaders = [
    <Checkbox
      checked={selectedArray.length > 0}
      onChange={(e) => selectAllArray(e)}
      key="selected-checkbox"
    />,
    'No',
    selectedArray.length > 0 ? '     ' : 'Title',
    'Test Case Count',
    'Updated at',
    '',
    '',
  ];

  return (
    <>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {tableHeaders?.map((tableHeader, idx) => (
              <TableHeader key={idx}>{tableHeader}</TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {scenarioLists?.length === 0 ? (
            <TableNoList colSpan={tableHeaders?.length}>
              해당 프로젝트에 생성된 테스트 시나리오가 존재하지 않습니다.
            </TableNoList>
          ) : (
            scenarioLists?.map((scenario, idx) => (
              <tr key={scenario.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(scenario.idx)}
                    onChange={(e) => selectArray(scenario.idx, e)}
                  />
                </TableBodyBasic>
                <TableBodyBasic>{idx + 1}</TableBodyBasic>
                <TableBodyBasic>
                  <div className="font-medium text-gray-900">
                    {scenario.title}
                  </div>
                  <div className="mt-1 text-gray-500">
                    {scenario.description}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>{scenario.caseCount}</TableBodyBasic>
                <TableBodyBasic>
                  <div>{scenario?.modifiedAt?.split(' ')[0] ?? '-'}</div>
                  <div
                    className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                  >
                    {scenario.modifiedAt && <Time />}
                    {scenario.modifiedAt?.match(timePattern)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    testType="scenario"
                    tooltipLocation="right"
                    disabled={scenario.caseCount === 0}
                    onClick={() => {
                      if (scenario.caseCount > 0) {
                        setTestScenarioIdx(scenario.idx);
                        setIsTestExecutionOpened(true);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: 'detail',
                        search: `idx=${scenario.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </tbody>
      </TableWrapper>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testScenarioIdx}
          testType={'scenario'}
          deviceType={location.pathname.includes('mobile') ? 'mobile' : 'web'}
        />
      )}
    </>
  );
};

export default ScenarioListTable;
