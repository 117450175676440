import {
  Button,
  CreatePlusButton,
  DoubleCheckDialogHeadlessUI,
  GroupManagement,
  PageHeadingButtons,
  PagingHeadingsInAutosquare,
  useCheckbox,
} from '@autosquare/common';
import {
  useDeleteSpeedMobileTestCaseMutation,
  useSpeedMobileTestCaseListQuery,
} from '@lib/api/ide/speedMobile/speedMobileTestCase';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MobileSpeedTestCaseList from './components/MobileSpeedTestCaseList';
import GroupProvider from '@contexts/ide/GroupProvider';
import GroupContainer from '@components/shared/TestCaseGroup/GroupContainer';

const MobileSpeedTestCase = () => {
  const { pathname } = useLocation();
  const { projectIdx } = useParams();

  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Mobile', href: pathname },
    { title: 'Test Case', href: pathname },
    { title: 'List', href: pathname },
  ];

  const mobileTestCaseListQuery = useSpeedMobileTestCaseListQuery();
  const speedMobileTestCaseList = mobileTestCaseListQuery.data;
  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(speedMobileTestCaseList, 'idx');

  const [isGroupOpened, setIsGroupOpened] = useState(false);

  const speedMobileDeleteTestCaseMutation =
    useDeleteSpeedMobileTestCaseMutation(projectIdx);

  const navigate = useNavigate();

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      icon: <CreatePlusButton />,
      variant: 'imageBasic',
      onClick: () => {
        navigate(
          `/ide/projects/${projectIdx}/speed-test/mobile/test-case/create`,
        );
      },
      description: 'Create Case',
    },
    {
      icon: <GroupManagement />,
      variant: 'imageBasic',
      onClick: () => {
        setIsGroupOpened(true);
      },
      description: 'Group Management',
    },
  ];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <>
        <GroupProvider>
          <MobileSpeedTestCaseList
            speedMobileTestCaseList={speedMobileTestCaseList}
            selectAllArray={selectAllArray}
            selectArray={selectArray}
            selectedArray={selectedArray}
            openDialog={openDialog}
            mobileTestCaseListQuery={mobileTestCaseListQuery}
          />
          {isGroupOpened && <GroupContainer closeModal={setIsGroupOpened} />}
        </GroupProvider>
        <DoubleCheckDialogHeadlessUI
          isOpened={isOpenedCautionDialog}
          setIsOpened={setIsOpenedCautionDialog}
          type="caution"
          title={
            speedMobileDeleteTestCaseMutation.isError
              ? 'Error'
              : 'Delete Test Case'
          }
          subTitle={
            speedMobileDeleteTestCaseMutation.isError
              ? speedMobileDeleteTestCaseMutation.error.message
              : '해당 테스트 케이스를 삭제하시겠습니까?'
          }
          buttonChildren={
            <>
              {!speedMobileDeleteTestCaseMutation.isError && (
                <Button
                  type="button"
                  variant="caution"
                  onClick={() =>
                    speedMobileDeleteTestCaseMutation.mutate(
                      {
                        idxList: selectedArray,
                      },
                      {
                        onSuccess: () => {
                          closeDialog();
                          resetSelectedArray();
                        },
                      },
                    )
                  }
                >
                  Delete
                </Button>
              )}
              <Button type="button" variant="secondary" onClick={closeDialog}>
                Cancel
              </Button>
            </>
          }
        />
      </>
    </>
  );
};

export default MobileSpeedTestCase;
