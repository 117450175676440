import { useUpdateAdminProjectDetailMutation } from '@lib/api/dashboard/admin/admin';

import {
  ProjectListDetail,
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import {
  AdminUserListData,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';

import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import ProjectInformation from './AdminProjectDetailForm/ProjectInformation';
import ProjectDetailsFeatures from './AdminProjectDetailForm/ProjectDetailsFeatures';
import ProjectEditButton from './AdminProjectDetailForm/ProjectEditButton';
import ProjectEditFeatures from './ProjectEditFeatures';
import ProjectEditInformation from './ProjectEditInformation';

interface Props {
  idx: string;
  isEdited: boolean;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  adminProjectData: ProjectListDetail;
  adminUserListData: AdminUserListData[];
}

const AdminProjectDetailForm = ({
  idx,
  isEdited,
  setIsEdited,
  adminProjectData,
  adminUserListData,
}: Props) => {
  const checkFeaturesActivate = (
    projectData: ProjectListDetail,
    category: ProjectListDetailFeaturesCategory,
    featrue: ProjectListDetailFeaturesFeature,
  ) =>
    projectData?.features?.find(
      (value) => value?.category === category && value?.feature === featrue,
    )?.activate;

  const methods = useForm<UpdateAdminProjectDetailData>({
    defaultValues: {
      name: adminProjectData.name,
      serviceName: adminProjectData.serviceName,
      description: adminProjectData.description,
      projectAdminList: adminProjectData.projectAdminList.map(
        (projectAdmin) => projectAdmin.email,
      ),
      features: adminProjectData.features.filter((feature) => feature.activate),
    },
  });

  const updateAdminProjectDetailMutation =
    useUpdateAdminProjectDetailMutation(idx);

  const onSubmit: SubmitHandler<UpdateAdminProjectDetailData> = (data) => {
    if (data.projectAdminList.length === 0) {
      methods.setError('projectAdminList', {
        type: 'required',
        message: 'Project Admin을 1명 이상 선택해주세요.',
      });
    } else if (data.features.length === 0) {
      methods.setError('features', {
        type: 'required',
        message: '프로젝트 기능을 1개 이상 선택해주세요.',
      });
    } else {
      updateAdminProjectDetailMutation.mutate(data);
    }
  };

  return (
    <>
      {isEdited ? (
        <FormProvider {...methods}>
          <form
            className="mt-8 space-y-6"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <ProjectEditInformation adminUserListData={adminUserListData} />
            <ProjectEditFeatures />
            <ProjectEditButton
              setIsEdited={setIsEdited}
              updateAdminProjectDetailMutation={
                updateAdminProjectDetailMutation
              }
            />
          </form>
        </FormProvider>
      ) : (
        <>
          <ProjectInformation
            projectName={adminProjectData?.name}
            serviceName={adminProjectData?.serviceName}
            description={adminProjectData?.description}
            projectAdminEmail={adminProjectData?.projectAdminList[0]?.email}
          />
          <ProjectDetailsFeatures
            checkFeaturesActivate={checkFeaturesActivate}
          />
        </>
      )}
    </>
  );
};

export default AdminProjectDetailForm;
