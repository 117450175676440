import { BrowserInfo } from '@customTypes/ide/browser/browser';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { UseQueryResult } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { Fragment } from 'react';

type Props = {
  browserInfoDto: BrowserInfo;
  handleChange: (selectedBrowser: BrowserInfo) => void;
  webBrowserListQuery: UseQueryResult<BrowserInfo[], Error>;
  disabled?: boolean;
};

const SpeedSchedulerBrowserListbox = ({
  browserInfoDto,
  handleChange,
  webBrowserListQuery,
  disabled = false,
}: Props) => {
  const browserList = webBrowserListQuery.data;

  const isDisabled =
    webBrowserListQuery.isLoading || webBrowserListQuery.isFetching || disabled;

  return (
    <div className="flex items-center gap-4">
      <div className="w-full">
        <Listbox
          value={browserInfoDto || null}
          onChange={handleChange}
          disabled={isDisabled}
        >
          <div className="relative">
            <ListboxButton
              className={clsx(
                'relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
                isDisabled ? 'bg-gray-100' : 'bg-white',
              )}
            >
              <span className="block truncate">
                {browserInfoDto
                  ? `${browserInfoDto?.browserName} - ${browserInfoDto?.version}`
                  : 'Select a test browser'}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                {browserList?.length === 0 ? (
                  <div className="mx-2 my-1 flex items-center justify-start">
                    연결된 디바이스가 없습니다.
                  </div>
                ) : (
                  browserList?.map((browser) => (
                    <ListboxOption
                      key={browser.browserName}
                      className="group relative flex cursor-default select-none items-center gap-2.5 py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                      value={browser}
                    >
                      <span className="block truncate font-normal group-data-[selected]:font-semibold">
                        {browser.browserName} - {browser.version}
                      </span>
                      <span className="invisible absolute inset-y-0 right-0 flex items-center pr-4 group-data-[selected]:visible">
                        <CheckIcon className="size-5" aria-hidden="true" />
                      </span>
                    </ListboxOption>
                  ))
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </Listbox>
      </div>
      <button
        type="button"
        className={clsx(
          'size-9 rounded border border-solid border-gray-300 px-2 py-1',
          isDisabled ? 'bg-gray-100' : 'hover:bg-gray-50',
        )}
        onClick={() => {
          webBrowserListQuery.refetch();
        }}
        disabled={isDisabled}
      >
        <ArrowPathIcon
          className={clsx('size-full', {
            'animate-spin':
              webBrowserListQuery.isLoading || webBrowserListQuery.isFetching,
          })}
        />
      </button>
    </div>
  );
};

export default SpeedSchedulerBrowserListbox;
