import React from 'react';

type Props = {
  title: string;
  data?: string | number | JSX.Element;
};

const DescriptionData = ({ title, data }: Props) => {
  return (
    <div className="grid grid-cols-3 px-3 py-2 odd:bg-gray-50 even:bg-white">
      <dt className="text-xs font-medium leading-6 text-gray-900">{title}</dt>
      <dd className="col-span-2 mt-0 break-words text-xs leading-6 text-gray-700">
        {data}
      </dd>
    </div>
  );
};

export default DescriptionData;
