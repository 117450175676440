import { InputCheckBox } from '@autosquare/common';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const From = () => {
  const { control, watch, resetField, setValue } =
    useFormContext<CreateTestStepData>();

  const mobileOs = watch('mobileOs');
  const saveTextFrom = watch('optionsDto.from');

  useEffect(() => {
    return () => resetField('optionsDto.from');
  }, [resetField]);

  useEffect(() => {
    if (saveTextFrom === SaveTextFrom.Element) {
      setValue('value', '');
      setValue('optionsDto.textExtract', 'false');
    } else if (saveTextFrom === SaveTextFrom.Sms) {
      setValue('optionsDto.textExtract', 'true');
    }

    return () => {
      resetField('optionsDto.textExtract');
    };
  }, [saveTextFrom, setValue, resetField]);

  return (
    <div className="grid w-full grid-cols-3 items-center text-sm font-medium leading-normal text-gray-900">
      <p>From</p>
      <Controller
        control={control}
        name="optionsDto.from"
        render={({ field }) => (
          <>
            <InputCheckBox
              id={SaveTextFrom.Element}
              name={'saveTextFrom'}
              onChange={field.onChange}
              defaultChecked={true}
              title={'Element'}
            />
            <InputCheckBox
              id={SaveTextFrom.Sms}
              name={'saveTextFrom'}
              onChange={field.onChange}
              defaultChecked={false}
              title={'SMS'}
              disabled={mobileOs === DeviceInfoOs.Ios}
            />
          </>
        )}
      />
    </div>
  );
};

export default From;
