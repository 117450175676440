export const webTestStepKeys = {
  all: ["webTestStep"] as const,
  lists: () => [...webTestStepKeys.all, "list"] as const,
  list: (idx: string) => [...webTestStepKeys.lists(), idx] as const,
  details: () => [...webTestStepKeys.all, "detail"] as const,
  detail: (testStepIdx: number) =>
    [...webTestStepKeys.details(), testStepIdx] as const,
  cases: () => [...webTestStepKeys.all, "case"] as const,
  case: (idx: string) => [...webTestStepKeys.cases(), idx] as const,
  commands: () => [...webTestStepKeys.all, "command"] as const,
  command: (idx: number, command: string) =>
    [...webTestStepKeys.commands(), command, idx] as const,
};
