import {
  PriorityNameEnum,
  TestCaseDetailData,
} from '@customTypes/testCase/type';
import React from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';

type DeleteStep = Omit<
  TestCaseDetailData,
  'steps' | 'Description' | 'stepCount' | 'creator'
>;

interface Props {
  copyTestCases: DeleteStep[];
  title: string;
  idx: number;
  priority: PriorityNameEnum;
  updatedAt: string;
  changeCardList: (list: DeleteStep[]) => void;
}

let id = 1;

const ListItemCard = ({
  copyTestCases,
  title,
  idx,
  priority,
  updatedAt,
  changeCardList,
}: Props) => {
  const box = {
    title,
    idx,
  };

  const [, drag] = useDrag({
    type: 'card',
    item() {
      const useless = copyTestCases.find(
        (copyTestCase) => copyTestCase.idx === -1,
      );
      if (!useless) {
        changeCardList([
          ...copyTestCases,
          {
            title: 'Move',
            idx: idx,
            priority: priority,
            updatedAt: updatedAt,
            dndIndex: -1,
          },
        ]);
      }
      return box;
    },
    end(_: unknown, monitor: DragSourceMonitor) {
      const uselessIndex = copyTestCases.findIndex(
        (copyTestCase) => copyTestCase.dndIndex === -1,
      );

      if (monitor.didDrop()) {
        copyTestCases.splice(uselessIndex, 1, {
          ...monitor.getItem(),
          dndIndex: id++,
        });
      } else {
        copyTestCases.splice(uselessIndex, 1);
      }
      changeCardList(copyTestCases);
    },
  });

  return (
    <div
      ref={drag}
      className={
        'm-2 flex cursor-move items-center border border-solid border-border-line bg-gray-50 px-3 py-2'
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 9h16.5m-16.5 6.75h16.5"
        />
      </svg>
      <div className="ml-7">{title}</div>
    </div>
  );
};

export default ListItemCard;
