import WebTestStepProvider from '@contexts/ide/WebTestStepProvider';
import React from 'react';

import WebAddTestStep from './components/WebAddTestStep';
import WebTestStepList from './components/WebTestStepList';

const WebAddTestStepAndList = () => {
  return (
    <div className="flex h-screen gap-2 overflow-hidden">
      <WebTestStepProvider>
        <WebAddTestStep />
        <WebTestStepList />
      </WebTestStepProvider>
    </div>
  );
};

export default WebAddTestStepAndList;
