import { ErrorMessage } from '@autosquare/common';

import { RootState } from '@app/store';

import { hasDownloadedData } from '@store/ide/settings/settingsSlice';

import { DeviceInfo, DeviceInfoOs } from '@customTypes/ide/device/device';

import React, { useRef, useEffect } from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import AddWirelessDeviceButtonInModal from '@components/AddWirelessDeviceButtonInModal/AddWirelessDeviceButtonInModal';

type Props = {
  selectedDevices: DeviceInfo[];
  isSuccess: boolean;
  isSelectedDevices: boolean;
  setIsSelectedDevices: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<DeviceInfo[], Error>>;
  setSelectedDevices: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
  deviceLists: DeviceInfo[];
  testType: TestTypeCategory;
  mobileOS: DeviceInfoOs;
  aosCount: number;
  iosCount: number;
  setNeedIosSetting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
};

const SelectedDevicesHeader = ({
  selectedDevices,
  isSuccess,
  isSelectedDevices,
  setIsSelectedDevices,
  refetch,
  setSelectedDevices,
  deviceLists,
  testType,
  mobileOS,
  aosCount,
  iosCount,
  setNeedIosSetting,
  setIsWirelessDeviceModal,
  setConnectionType,
}: Props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const isIndeterminate =
      selectedDevices?.length > 0 &&
      selectedDevices?.length < deviceLists?.length;
    inputRef.current.indeterminate = isIndeterminate;
    setIsSelectedDevices(false);
  }, [selectedDevices]);

  const resetList = () => {
    setSelectedDevices([]);
    refetch();
    setIsSelectedDevices(false);
    setNeedIosSetting(false);
  };

  const selectAllDevices = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    if (target.checked) {
      setSelectedDevices(
        deviceLists?.filter(
          (device) =>
            (testType === 'step' &&
              mobileOS === DeviceInfoOs.Aos &&
              device.os === DeviceInfoOs.Aos &&
              device.enabled) ||
            (testType === 'step' &&
              mobileOS === DeviceInfoOs.Ios &&
              device.os === DeviceInfoOs.Ios &&
              device.enabled) ||
            (testType === 'case' &&
              aosCount !== 0 &&
              device.os === DeviceInfoOs.Aos &&
              device.enabled) ||
            (testType === 'case' &&
              iosCount !== 0 &&
              device.os === DeviceInfoOs.Ios &&
              device.enabled) ||
            (testType === 'scenario' && device.enabled),
        ),
      );
    } else {
      setSelectedDevices([]);
    }
  };

  const dispatch = useDispatch();
  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  useEffect(() => {
    if (isDownloadedData) {
      resetList();
      dispatch(hasDownloadedData(false));
    }
  }, [isDownloadedData]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center justify-between gap-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Select Devices
          </h3>
          <label
            className="flex items-center justify-between gap-2 text-sm"
            htmlFor="select-all-devices"
          >
            <input
              type="checkbox"
              className="flex size-4 items-center justify-center rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600"
              ref={inputRef}
              checked={selectedDevices?.length > 0}
              onChange={(e) => selectAllDevices(e)}
              id="select-all-devices"
              name="select-all-devices"
              disabled={isSuccess && deviceLists?.length === 0}
            />
            Select All
          </label>
        </div>
        {isSelectedDevices && (
          <ErrorMessage width="max-w-full" margin="m-0">
            디바이스를 선택해 주세요.
          </ErrorMessage>
        )}
      </div>
      <div className="flex gap-x-4">
        <AddWirelessDeviceButtonInModal
          setIsWirelessDeviceModal={setIsWirelessDeviceModal}
          setConnectionType={setConnectionType}
        />
        <button
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-black px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-congress-blue"
          onClick={resetList}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="size-5"
          >
            <path
              fillRule="evenodd"
              d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
              clipRule="evenodd"
            />
          </svg>
          Refresh
        </button>
      </div>
    </div>
  );
};

export default SelectedDevicesHeader;
