import { Breadcrumbs, PagingHeadingButtonOnClick } from '@autosquare/common';

import { mobileDeviceKeys } from '@queries/ide/mobile/mobileDeviceKeys';

import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import WifiConnection from '@components/AddDevice/components/WifiConnection';
import PortConnection from '@components/AddDevice/components/PortConnection';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

interface Props {
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  connectionType: 'wifi' | 'port';
  testType: TestTypeCategory;
  isDistributedClicked?: boolean;
}

const AddDeviceInModal = ({
  setState,
  connectionType,
  testType,
  isDistributedClicked = true,
}: Props) => {
  const queryClient = useQueryClient();

  const closeModal = () => {
    queryClient.invalidateQueries(mobileDeviceKeys.lists());
    setState(false);
  };

  const breadcrumbs: Breadcrumbs[] = [
    { title: `${isWordInLocationPathname('ui-test') ? 'UI' : 'Speed'} Test` },
    { title: 'Mobile' },
    {
      title:
        testType === 'case'
          ? 'Test Case'
          : testType === 'scenario' && 'Test Scenario',
    },
    {
      title: `${isDistributedClicked ? 'Distributed ' : ''}Execution`,
    },
    { title: 'Add Device' },
  ];
  return (
    <>
      <PagingHeadingButtonOnClick
        breadcrumbs={breadcrumbs}
        pageTitle={'Add Device'}
      />

      {connectionType === 'wifi' ? (
        <WifiConnection closeModal={closeModal} />
      ) : (
        <PortConnection closeModal={closeModal} />
      )}
    </>
  );
};

export default AddDeviceInModal;
