import React from 'react';

type Props = {
  projectName: string;
  serviceName: string;
  description: string;
  projectAdminEmail: string;
};

const ProjectInformation = ({
  projectName,
  serviceName,
  description,
  projectAdminEmail,
}: Props) => {
  const projectInformation = [
    { title: 'Project Name', name: projectName },
    { title: 'Service Name', name: serviceName },
    { title: 'Description', name: description },
    { title: 'Project Admin', name: projectAdminEmail },
  ];

  return (
    <>
      <div className="mt-8 border-b border-gray-200 pb-5">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Project Information
        </h2>
      </div>
      <div className="grid grid-cols-4 pb-8 pt-4">
        {projectInformation?.map((information) => (
          <div className="flex flex-col gap-y-4" key={information.title}>
            <p className="text-sm font-medium text-gray-900">
              {information.title}
            </p>
            <p className="text-sm text-gray-900">{information.name}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProjectInformation;
