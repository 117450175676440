import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { DisclosurePanel } from '@headlessui/react';

import TestStepResultDetail from './TestStepResultDetail';
import MediaAndLogHandler from './MediaAndLogHandler';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import { ErrorMessage, ListSkeleton } from '@autosquare/common';
import { useWebTestCaseExecutionDetailQuery } from '@lib/api/dashboard/web/webTestResult';
import { useMobileTestCaseExecutionDetailQuery } from '@lib/api/dashboard/mobile/mobileTestResult';

type Props = {
  caseResultIdx: number;
  videoPath: string;
  logPath: string;
  imagePaths: string[];
  result: 'Pass' | 'Fail' | 'Skip' | 'N/A';
};

const TestStepResult = ({
  caseResultIdx,
  imagePaths,
  videoPath,
  logPath,
  result,
}: Props) => {
  const [searchParams] = useSearchParams();
  const resultIdx = searchParams.get('idx');

  const testStepResultQuery = isWordInLocationPathname('mobile')
    ? useMobileTestCaseExecutionDetailQuery(resultIdx, caseResultIdx)
    : useWebTestCaseExecutionDetailQuery(resultIdx, caseResultIdx);

  return (
    <DisclosurePanel>
      <div className="grid grid-cols-2 pb-10">
        {testStepResultQuery.isLoading || testStepResultQuery.isFetching ? (
          <ListSkeleton />
        ) : testStepResultQuery.isError ? (
          <ErrorMessage>{testStepResultQuery.error.message}</ErrorMessage>
        ) : (
          <>
            <TestStepResultDetail testStepResult={testStepResultQuery.data} />
            {result !== 'N/A' && (
              <MediaAndLogHandler
                imagePaths={imagePaths}
                videoPath={videoPath}
                logPath={logPath}
              />
            )}
          </>
        )}
      </div>
    </DisclosurePanel>
  );
};

export default TestStepResult;
