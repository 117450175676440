import {
  CreatePlusButton,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import { useTestCaseGroupListQuery } from '@lib/api/ide/testGroup/testCaseGroup';

import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

import { findTestGroupNameByIdx } from '@utils/static/findTestGroupNameByIdx';

import { useGroupContext } from '@contexts/ide/GroupProvider';

import { UpdateTestCaseGroup } from '@customTypes/testCaseGroup/type';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';

interface Props {
  isRequired?: boolean;
}

const TestGroupBox = ({ isRequired = true }: Props) => {
  const dispatch = useDispatch();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateTestCaseGroup>();
  const { setClickGroupType } = useGroupContext();

  const testCaseGroupListQuery = useTestCaseGroupListQuery();
  const testCaseGroupList = testCaseGroupListQuery.data;
  const testCaseGroupListLength = testCaseGroupList?.length ?? 0;

  const [testCaseGroupLength, setTestCaseGroupLength] = useState<number>(0);
  const [isLengthChanged, setIsLengthChanged] = useState(false);
  const [isTestCaseGroupLengthChanged, setIsTestCaseGroupLengthChanged] =
    useState(false);

  useEffect(() => {
    if (testCaseGroupListQuery.isRefetching) {
      setIsTestCaseGroupLengthChanged(true);
    }
  }, [testCaseGroupListQuery.isRefetching]);

  useEffect(() => {
    if (testCaseGroupListQuery.isSuccess && testCaseGroupLength === 0) {
      setTestCaseGroupLength(testCaseGroupListLength);
      setIsTestCaseGroupLengthChanged(true);
    }
  }, [testCaseGroupListLength, testCaseGroupListQuery.isSuccess]);

  useEffect(() => {
    if (
      testCaseGroupListQuery.isSuccess &&
      isTestCaseGroupLengthChanged &&
      testCaseGroupLength !== testCaseGroupListLength
    ) {
      setIsLengthChanged(true);
      setTestCaseGroupLength(testCaseGroupListLength);
      setIsTestCaseGroupLengthChanged(false);
    }
  }, [
    testCaseGroupListLength,
    testCaseGroupListQuery.isSuccess,
    testCaseGroupLength,
    setIsTestCaseGroupLengthChanged,
  ]);

  useEffect(() => {
    if (isLengthChanged) {
      setValue('caseGroupIdx', testCaseGroupList?.at(-1)?.idx);
      setIsLengthChanged(false);
    }
  }, [testCaseGroupList, isLengthChanged, setValue]);

  return (
    <div className="flex flex-col">
      <div className="flex gap-2">
        <p className="block text-sm font-medium leading-6 text-gray-900">
          Test Group{isRequired && '*'}
        </p>
        <ErrorMessage
          errors={errors}
          name="caseGroupIdx"
          render={({ message }) => <p className="error-message">{message}</p>}
        />
      </div>
      <div className="mt-2 grid grid-flow-col grid-cols-1 items-center gap-2">
        <Controller
          control={control}
          name={'caseGroupIdx'}
          rules={isRequired && { required: '필수 선택 사항입니다.' }}
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value || ''}
              buttonValue={
                field.value
                  ? findTestGroupNameByIdx(field.value, testCaseGroupList)
                  : 'Select a test group'
              }
              onChange={field.onChange}
              lists={testCaseGroupList}
              valueToSave={'idx'}
              valueToShow={'name'}
              noListMessage={'No test groups available to select'}
            />
          )}
        />
        <button
          type="button"
          className="hover:text-gray-600"
          onClick={() => {
            dispatch(openCreateGroup());
            setClickGroupType('Test');
          }}
        >
          <CreatePlusButton />
        </button>
      </div>
    </div>
  );
};

export default TestGroupBox;
