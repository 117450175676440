import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const DraggableElementContainer = ({ children }: Props) => {
  return (
    <div className="grid w-full grid-cols-2 items-center justify-between gap-4">
      {children}
    </div>
  );
};

export default DraggableElementContainer;
