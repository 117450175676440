import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

export type PreviousLink = {
  link: string | null;
};

const initialState: PreviousLink = {
  link: null,
};

export const previousLinkSlice = createSlice({
  name: "projectName",
  initialState,
  reducers: {
    getPreviousLink: (state, action: PayloadAction<string | null>) => {
      state.link = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getPreviousLink } = previousLinkSlice.actions;

export default previousLinkSlice.reducer;
