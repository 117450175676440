import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type SchedulerSlice = {
  isRunning: boolean;
};

const initialState: SchedulerSlice = {
  isRunning: false,
};

export const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    hasSchedulerRunning: (state, action: PayloadAction<boolean>) => {
      state.isRunning = action.payload;
    },
  },
});

export const { hasSchedulerRunning } = schedulerSlice.actions;

export default schedulerSlice.reducer;
