import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import { findMobileCommandNameFromServerName } from '@utils/static/mobileCommandOption';

import { TestResultCaseDetail } from '@customTypes/testResult/type';
import { findWebCommandNameFromServerName } from '@utils/static/webCommandOptions';

type Props = {
  testStepResult: TestResultCaseDetail[];
};

const TestStepResultDetail = ({ testStepResult }: Props) => {
  return (
    <div className="px-4">
      <div className="mt-6 border-b border-gray-200 pb-3">
        <p className="text-sm font-semibold leading-6 text-gray-900">
          Test Step Result({testStepResult?.length})
        </p>
      </div>
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <>
              <ul role="list" className="space-y-6">
                {testStepResult?.map((testStep, index) => (
                  <li key={testStep.seq} className="relative flex gap-x-4">
                    {testStep.result === 'Fail' ||
                    (testStep.result === 'N/A' &&
                      testStep.failReason !== '-') ? (
                      <>
                        <Disclosure defaultOpen={true}>
                          {({ open }) => (
                            <>
                              <div className="w-full">
                                <div className="bg-white">
                                  <Disclosure.Button className="w-full">
                                    <div className="flex items-center justify-between space-x-3 px-0">
                                      <div className="flex min-w-0 flex-col gap-2">
                                        <div className="relative flex items-start gap-x-3">
                                          <div
                                            className={`absolute left-0 top-0 flex w-6 justify-center ${
                                              index ===
                                              testStepResult?.length - 1
                                                ? 'h-6'
                                                : '-bottom-6'
                                            }`}
                                          >
                                            <div className="w-px bg-gray-200" />
                                          </div>
                                          <div className="relative flex size-6 flex-none items-center justify-center bg-white">
                                            <div
                                              className={`size-1.5 rounded-full ring-1 ring-gray-300 ${
                                                testStep.result === 'Pass'
                                                  ? 'bg-green-500'
                                                  : testStep.result === 'Fail'
                                                    ? 'bg-red-500'
                                                    : 'bg-gray-500'
                                              }`}
                                            />
                                          </div>
                                          <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                            {isWordInLocationPathname(
                                              'mobile-app',
                                            )
                                              ? findMobileCommandNameFromServerName(
                                                  testStep.command,
                                                )
                                              : findWebCommandNameFromServerName(
                                                  testStep.command,
                                                )}
                                            {' - '}
                                            <span className="font-medium text-gray-900">
                                              {testStep.description}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="justify-items-end">
                                        <div className="grid grid-cols-1">
                                          <ChevronRightIcon
                                            className={`size-5 shrink-0 ${
                                              open
                                                ? 'rotate-90 text-gray-500'
                                                : 'text-gray-400'
                                            }`}
                                            aria-hidden="true"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Disclosure.Button>
                                  <Disclosure.Panel>
                                    <div className="mt-4 pl-10">
                                      <p className="text-sm">
                                        {testStep.result === 'Fail'
                                          ? `Fail Reason: ${testStep.failReason}`
                                          : testStep.result === 'N/A' &&
                                            testStep.failReason !== '-' &&
                                            `N/A Reason: ${testStep.failReason}`}
                                      </p>
                                    </div>
                                  </Disclosure.Panel>
                                </div>
                              </div>
                            </>
                          )}
                        </Disclosure>
                      </>
                    ) : (
                      <div className="flex items-center justify-between space-x-3 px-0">
                        <div className="flex min-w-0 flex-col gap-2">
                          <div className="relative flex items-start gap-x-3">
                            <div
                              className={`absolute left-0 top-0 flex w-6 justify-center ${
                                index === testStepResult?.length - 1
                                  ? 'h-6'
                                  : '-bottom-6'
                              }`}
                            >
                              <div className="w-px bg-gray-200" />
                            </div>
                            <div className="relative flex size-6 flex-none items-center justify-center bg-white">
                              <div
                                className={`size-1.5 rounded-full ring-1 ring-gray-300 ${
                                  testStep.result === 'Pass'
                                    ? 'bg-green-500'
                                    : testStep.result === 'Fail'
                                      ? 'bg-red-500'
                                      : 'bg-gray-500'
                                }`}
                              />
                            </div>
                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                              {isWordInLocationPathname('mobile-app')
                                ? findMobileCommandNameFromServerName(
                                    testStep.command,
                                  )
                                : findWebCommandNameFromServerName(
                                    testStep.command,
                                  )}
                              {' - '}
                              <span className="font-medium text-gray-900">
                                {testStep.description}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestStepResultDetail;
