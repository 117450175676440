import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import { WebSpeedTestResultDetail } from '@customTypes/ide/speedTest/Web/testResult/type';

type Props = {
  data: WebSpeedTestResultDetail;
};

const LineChart = ({ data }: Props) => {
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: -1,
        offsetY: -36,
      },
    },
    stroke: {
      width: 3,
      curve: 'straight',
    },
    xaxis: {
      categories: data?.date,
      labels: {
        formatter: (value: string) => dayjs(value).format('M/D'),
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    markers: {
      size: 6,
    },
    colors: [
      '#008FFB',
      '#00E396',
      '#FEB019',
      '#FF4560',
      '#775DD0',
      '#66DA26',
      '#546E7A',
      '#E91E63',
      '#FF9800',
      '#2E93fA',
    ],
    grid: {
      show: false,
      padding: {
        top: 0,
        right: 40,
        bottom: 20,
        left: 40,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 3,
          strokeDashArray: 1,
          opacity: 1,
          borderColor: '#000000',
          fillColor: '#000000',
          label: {
            text: 'Base Line',
          },
        },
      ],
    },
  };

  const series = data?.loadSpeedData.map((item) => ({
    name: item.name,
    data: item.data.map((dataItem) => dataItem.value),
  }));

  return (
    <div>
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default LineChart;
