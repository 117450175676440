import { SubHeaderTitle } from '@autosquare/common';
import {
  CreateMobileSpeedTestScenarioData,
  UpdateMobileSpeedTestScenarioData,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import InformationInput from './InformationInput';

type Props = {
  register: UseFormRegister<
    CreateMobileSpeedTestScenarioData | UpdateMobileSpeedTestScenarioData
  >;
  errors: FieldErrors;
};

const TestScenarioInformation = ({ register, errors }: Props) => {
  return (
    <div className="mt-8 pb-4">
      <SubHeaderTitle
        subTitle={'Test Scenario Information'}
        description={'테스트 시나리오 정보를 입력해주세요.'}
      />
      <div className="mt-4 grid grid-cols-2 gap-4">
        <InformationInput
          title={'Title*'}
          id={'title'}
          register={register('title')}
          errors={errors}
        />
        <InformationInput
          title={'Description'}
          id={'description'}
          register={register('description')}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default TestScenarioInformation;
