import { ErrorMessage, useInput, useRefOutsideClick } from '@autosquare/common';

import React, { MutableRefObject, useRef, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { useProjectMemberListQuery } from '@lib/api/ide/project/project';
import { useEmailAlarmOnKeyDown } from '@hooks/useEmailAlarmOnKeyDown';

import SchedulerDetailEmailList from './SchedulerDetailEmailAlarm/SchedulerDetailEmailList';

import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { ProjectMember } from '@customTypes/dashboard/project/type';
import { MailReceiver } from '@customTypes/ide/speedScheduler/speedScheduler';

interface Props {
  emailAddressLists: MailReceiver[];
  setEmailAddressLists: React.Dispatch<React.SetStateAction<MailReceiver[]>>;
  isRunning: boolean;
}

const SchedulerDetailEmailAlarm = ({
  emailAddressLists,
  setEmailAddressLists,
  isRunning,
}: Props) => {
  const { data } = useProjectMemberListQuery();

  const projectMembers = data;

  const { setValue } = useFormContext<UpdateSchedulerData>();

  const [emailAddress, onChangeEmailAddress, setEmailAddress] = useInput('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [isOpenedMemberList, setIsOpenedMemberList] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [temporarySavedEmailAddress, setTemporarySavedEmailAddress] =
    useState<MailReceiver>({ email: '', name: '' });

  const inputRef = useRef(null);
  const wrapperRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const ulRef: React.MutableRefObject<HTMLUListElement | null> = useRef(null);

  useRefOutsideClick(wrapperRef, () => setIsOpenedMemberList(false));

  useEffect(() => {
    if (
      isOpenedMemberList &&
      ulRef?.current &&
      ulRef?.current?.children[selectedIndex]
    ) {
      ulRef?.current?.children[selectedIndex]?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [selectedIndex, isOpenedMemberList]);

  const filteredPeople =
    emailAddress === ''
      ? projectMembers?.filter(
          (projectMember) =>
            !emailAddressLists?.some(
              (emailAddress) => emailAddress?.email === projectMember?.userId,
            ),
        )
      : projectMembers?.filter(
          (projectMember) =>
            !emailAddressLists?.some(
              (emailAddress) => emailAddress?.email === projectMember?.userId,
            ) &&
            (projectMember?.userId
              .toLowerCase()
              .includes(emailAddress?.toLowerCase()) ||
              projectMember?.name
                .toLowerCase()
                .includes(emailAddress?.toLowerCase())),
        );

  const onSelectMember = (member: ProjectMember) => {
    if (
      emailAddressLists?.find((x) => x?.email === emailAddress) === undefined
    ) {
      setEmailAddressLists([
        ...emailAddressLists,
        { email: member?.userId, name: member?.name },
      ]);
      setValue('mail_receivers', [
        ...emailAddressLists,
        { email: member?.userId, name: member?.name },
      ]);
      setEmailAddress('');
      inputRef.current.value = '';
      setIsOpenedMemberList(false);
    }
  };

  const inputFocus = () => {
    setIsOpenedMemberList(true);
    setTemporarySavedEmailAddress({
      email: filteredPeople[selectedIndex]?.userId,
      name: filteredPeople[selectedIndex]?.name,
    });
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
      <label
        htmlFor="alarm"
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
      >
        Email Alarm
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0">
        <div ref={wrapperRef}>
          <div className="relative">
            <div className="relative">
              <input
                type="text"
                className={`${
                  isRunning ? 'bg-white' : 'bg-gray-100'
                } w-full rounded-md border-0 py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                onChange={onChangeEmailAddress}
                onKeyDown={(e) =>
                  useEmailAlarmOnKeyDown({
                    e: e,
                    setIsOpenedMemberList: setIsOpenedMemberList,
                    inputRef: inputRef,
                    emailAddress: emailAddress,
                    emailAddressLists: emailAddressLists,
                    setEmailAddressLists: setEmailAddressLists,
                    temporarySavedEmailAddress: temporarySavedEmailAddress,
                    setEmailAddress: setEmailAddress,
                    setSelectedIndex: setSelectedIndex,
                    setEmailErrorMessage: setEmailErrorMessage,
                    filteredPeople: filteredPeople,
                    setTemporarySavedEmailAddress:
                      setTemporarySavedEmailAddress,
                    selectedIndex: selectedIndex,
                    setValue: setValue,
                  })
                }
                placeholder="Enter an email address or select project member"
                onFocus={inputFocus}
                ref={inputRef}
                disabled={!isRunning}
              />
              <button
                type="button"
                className="absolute right-2 top-2"
                onClick={() => setIsOpenedMemberList(!isOpenedMemberList)}
                onKeyDown={(e) =>
                  useEmailAlarmOnKeyDown({
                    e: e,
                    setIsOpenedMemberList: setIsOpenedMemberList,
                    inputRef: inputRef,
                    emailAddress: emailAddress,
                    emailAddressLists: emailAddressLists,
                    setEmailAddressLists: setEmailAddressLists,
                    temporarySavedEmailAddress: temporarySavedEmailAddress,
                    setEmailAddress: setEmailAddress,
                    setSelectedIndex: setSelectedIndex,
                    setEmailErrorMessage: setEmailErrorMessage,
                    filteredPeople: filteredPeople,
                    setTemporarySavedEmailAddress:
                      setTemporarySavedEmailAddress,
                    selectedIndex: selectedIndex,
                    setValue: setValue,
                  })
                }
                disabled={!isRunning}
              >
                <ChevronUpDownIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>
            </div>
            {filteredPeople?.length > 0 && isOpenedMemberList && (
              <ul
                className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm"
                ref={ulRef}
              >
                {filteredPeople?.map((person, index) => (
                  <li
                    key={person?.userId}
                    className={`group relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white ${
                      index === selectedIndex
                        ? 'bg-indigo-600 text-white'
                        : 'text-gray-900'
                    }`}
                    onClick={() => onSelectMember(person)}
                  >
                    <div className="flex">
                      <span className={`truncate`}>{person?.name}</span>
                      <span
                        className={`ml-2 truncate text-gray-500 group-hover:text-indigo-200 ${
                          index === selectedIndex
                            ? 'text-indigo-200'
                            : 'text-gray-500'
                        }`}
                      >
                        {person?.userId}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <ErrorMessage>{emailErrorMessage}</ErrorMessage>
        <SchedulerDetailEmailList
          emailAddressLists={emailAddressLists}
          setEmailAddressLists={setEmailAddressLists}
          isRunning={isRunning}
        />
      </div>
    </div>
  );
};

export default SchedulerDetailEmailAlarm;
