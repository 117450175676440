import { isMacOs } from '@utils/static/isMacOs';
import React from 'react';

const NoBrowserList = () => {
  return (
    <div className="flex flex-col items-center gap-4 py-8 text-sm">
      <p>설치된 테스트 지원 대상 브라우저가 존재하지 않습니다.</p>
      <p>지원 브라우저: Google Chrome{isMacOs && ', Apple Safari'}</p>
    </div>
  );
};

export default NoBrowserList;
