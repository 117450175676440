import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { ErrorMessage } from '@hookform/error-message';

import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  isRunning: boolean;
}

const SchedulerDetailTitle = ({ isRunning }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateSchedulerData>();

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
      <label
        htmlFor="title"
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
      >
        Title
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0">
        <input
          id="title"
          className={`input-base ${!isRunning && 'bg-gray-100'}`}
          placeholder="Enter scheduler title"
          disabled={!isRunning}
          {...register('title')}
        />
        <ErrorMessage
          name="title"
          errors={errors}
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </div>
    </div>
  );
};

export default SchedulerDetailTitle;
