import {
  ArrowSpinner,
  Breadcrumbs,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingButtonOnClick,
  TableSkeleton,
  useInput,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@app/store';
import { getTestCaseIndexArrayInDetail } from '@store/ide/numberArraySlice';
import {
  useDeleteMobileTestScenarioMutation,
  useMobileTestScenarioDetailQuery,
  useUpdateMobileTestScenarioMutation,
} from '@lib/api/ide/mobile/mobileTestScenario';
import {
  useWebTestScenarioDetailQuery,
  useUpdateWebTestScenarioMutation,
  useDeleteWebTestScenarioMutation,
} from '@lib/api/ide/web/webTestScenario';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import DetailInput from './components/DetailInput';
import DetailDnd from './components/DetailDnd';

const TestScenarioDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const testScenarioDetailQuery = isWordInLocationPathname('mobile')
    ? useMobileTestScenarioDetailQuery(idx)
    : useWebTestScenarioDetailQuery(idx);

  const scenarioDetails = testScenarioDetailQuery.data;

  const step = scenarioDetails?.step;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestCaseIndexArrayInDetail(step));
  }, [step]);

  const [title, onChangeTitle, setTitle] = useInput('');
  const [description, onChangeDescription, setDescription] = useInput('');
  const [isWrited, setIsWrited] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  useEffect(() => {
    setTitle(scenarioDetails?.title);
    setDescription(scenarioDetails?.description);
  }, [testScenarioDetailQuery.data]);

  const testCaseIdxs = useSelector(
    (state: RootState) => state.numberArray.testCaseIndexArray,
  );

  const updateData = {
    title: title,
    description: description,
    testCaseIdxs: testCaseIdxs,
  };

  const updateTestScenarioMutation = isWordInLocationPathname('mobile')
    ? useUpdateMobileTestScenarioMutation(idx)
    : useUpdateWebTestScenarioMutation(idx);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsWrited(true);
    if (title?.length !== 0) {
      updateTestScenarioMutation.mutate(updateData);
    }
  };

  const deleteTestScenarioMutation = isWordInLocationPathname('mobile')
    ? useDeleteMobileTestScenarioMutation([Number(idx)])
    : useDeleteWebTestScenarioMutation([Number(idx)]);

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'UI Test',
      href: location.pathname.split('/').slice(0, -3).join('/') + '/overview',
    },
    {
      title: isWordInLocationPathname('mobile') ? 'Mobile' : 'Web',
      href: location.pathname.split('/').slice(0, -1).join('/'),
    },
    {
      title: 'Test Scenario',
      href: location.pathname.split('/').slice(0, -1).join('/'),
    },
    {
      title: 'Detail',
      href: location.pathname + location.search,
    },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Delete',
      type: 'button',
      variant: 'caution',
      onClick: () => {
        setIsModalOpened(true);
      },
    },
    { title: 'Save', type: 'submit' },
  ];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PagingHeadingButtonOnClick
          breadcrumbs={breadcrumbs}
          pageTitle={'Test Scenario Detail'}
          pageHeadingButtons={pageHeadingButtons}
        />
        {updateTestScenarioMutation.isLoading ||
        deleteTestScenarioMutation.isLoading ? (
          <ArrowSpinner />
        ) : updateTestScenarioMutation.isError ? (
          <ErrorMessage>
            {updateTestScenarioMutation.error.message}
          </ErrorMessage>
        ) : (
          deleteTestScenarioMutation.isError && (
            <ErrorMessage>
              {deleteTestScenarioMutation.error.message}
            </ErrorMessage>
          )
        )}
        {testScenarioDetailQuery.isLoading ||
        testScenarioDetailQuery.isFetching ? (
          <TableSkeleton />
        ) : testScenarioDetailQuery.isError ? (
          <div>{testScenarioDetailQuery.error.message}</div>
        ) : (
          <>
            <DetailInput
              title={title}
              scenarioDetails={scenarioDetails}
              onChangeTitle={onChangeTitle}
              onChangeDescription={onChangeDescription}
              isWrited={isWrited}
            />
            <DetailDnd />
          </>
        )}
      </form>
      {
        <DoubleCheckDialogHeadlessUI
          isOpened={isModalOpened}
          setIsOpened={setIsModalOpened}
          type={'caution'}
          title={
            deleteTestScenarioMutation.isError
              ? 'Error'
              : 'Delete Test Scenario'
          }
          subTitle={
            deleteTestScenarioMutation.isError
              ? deleteTestScenarioMutation.error.message
              : '해당 테스트 시나리오를 삭제하시겠습니까?'
          }
          buttonChildren={
            <>
              {deleteTestScenarioMutation.isError ? undefined : (
                <Button
                  type="button"
                  variant="caution"
                  onClick={() =>
                    deleteTestScenarioMutation.mutate(undefined, {
                      onSuccess: () => {
                        closeModal();
                        navigate(
                          location.pathname.split('/').slice(0, -1).join('/'),
                        );
                      },
                      onError: () => {
                        openModal();
                      },
                    })
                  }
                >
                  Delete
                </Button>
              )}
              <Button type="button" variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </>
          }
        />
      }
    </>
  );
};

export default TestScenarioDetail;
