import FormField from '@components/shared/Input/FormField';

import { UpdateMobileTestCaseData } from '@customTypes/testCase/type';
import { useSpeedMobileTestCaseDetailQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

const TestCaseInfoBottom = () => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<UpdateMobileTestCaseData>();

  const [searchParam] = useSearchParams();
  const idx = searchParam.get('idx');

  const speedMobileTestCaseDetailQuery = useSpeedMobileTestCaseDetailQuery(idx);
  const speedMobileTestCaseDetail = speedMobileTestCaseDetailQuery.data;

  useEffect(() => {
    setValue('title', speedMobileTestCaseDetail.title);
    setValue('description', speedMobileTestCaseDetail.description);
  }, []);

  return (
    <div className="grid grid-cols-1 gap-4 border-t border-gray-100 sm:grid-cols-2">
      <div className="grid grid-flow-row grid-cols-2 py-6 sm:col-span-1">
        <FormField
          name="title"
          labelTitle="Title*"
          errors={errors}
          register={register('title', {
            required: '필수 입력 사항 입니다.',
            maxLength: {
              value: 50,
              message: '50자를 초과할 수 없습니다.',
            },
          })}
        />
      </div>
      <div className="py-6 sm:col-span-1">
        <FormField
          name="description"
          labelTitle="Description"
          errors={errors}
          register={register('description', {
            minLength: 0,
            maxLength: {
              value: 200,
              message: '200자를 초과할 수 없습니다.',
            },
          })}
        />
      </div>
    </div>
  );
};

export default TestCaseInfoBottom;
