import { http, HttpResponse } from 'msw';

export const handlers = [
  http.get('/api/userinfo', () => {
    return HttpResponse.json({
      idx: 1,
      userId: 'test@t-square.co.kr',
      name: 'Test',
      uuid: '4857404f-19f3-47a3-8d72-8e4e5c4fd3fa',
    });
  }),

  http.post('/api/login', async ({ request }) => {
    const data = await request.json();

    return HttpResponse.json(data);
  }),
];
