import { Button, ErrorMessage } from '@autosquare/common';

import { UpdateAdminProjectDetailData } from '@customTypes/dashboard/admin/type';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { UseMutationResult } from '@tanstack/react-query';

import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  updateAdminProjectDetailMutation: UseMutationResult<
    unknown,
    ServerResponseErrorDetails,
    UpdateAdminProjectDetailData,
    unknown
  >;
}

const ProjectEditButton = ({
  setIsEdited,
  updateAdminProjectDetailMutation,
}: Props) => {
  const { reset } = useFormContext<UpdateAdminProjectDetailData>();

  return (
    <div className="mt-6 flex items-center justify-end gap-x-6">
      <div />
      <ErrorMessage margin={'m-0'}>
        {updateAdminProjectDetailMutation.isError &&
          updateAdminProjectDetailMutation.error.message}
      </ErrorMessage>
      <div className="flex items-center justify-between gap-x-6">
        <Button
          type="button"
          variant="secondary"
          onClick={() => {
            reset();
            updateAdminProjectDetailMutation.reset();
            setIsEdited(false);
          }}
        >
          Cancel
        </Button>
        <Button type="submit" variant="primary">
          Save
        </Button>
      </div>
    </div>
  );
};

export default ProjectEditButton;
