import {
  Breadcrumbs,
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingButtonOnClick,
} from '@autosquare/common';
import {
  CreateMobileSpeedTestScenarioData,
  CreateMobileSpeedTestScenarioDataSchema,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';

import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateSpeedMobileTestScenarioMutation } from '@lib/api/ide/speedMobile/speedMobileTestScenario';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import TestScenarioDndSpace from './components/TestScenarioDndSpace';
import MobileSpeedTestScenarioInformation from './components/MobileSpeedTestScenarioInformation';

const CreateMobileSpeedTestScenario = () => {
  const { pathname } = useLocation();
  const { projectIdx } = useParams();
  const navigate = useNavigate();

  const methods = useForm<CreateMobileSpeedTestScenarioData>({
    defaultValues: { projectIdx: Number(projectIdx) },
    resolver: zodResolver(CreateMobileSpeedTestScenarioDataSchema),
  });

  const createSpeedMobileTestScenarioMutation =
    useCreateSpeedMobileTestScenarioMutation();

  const overviewPath = `${pathname.split('/').slice(0, -2).join('/')}/overview`;
  const listPath = pathname.split('/').slice(0, -1).join('/');

  const onSubmit: SubmitHandler<CreateMobileSpeedTestScenarioData> = (data) => {
    createSpeedMobileTestScenarioMutation.mutate(data, {
      onSuccess: () => {
        navigate(listPath);
      },
    });
  };

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Speed Test', href: overviewPath },
    { title: 'Mobile', href: listPath },
    { title: 'Test Scenario', href: listPath },
    { title: 'Create', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Save',
      type: 'submit',
      variant: 'primary',
    },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PagingHeadingButtonOnClick
          breadcrumbs={breadcrumbs}
          pageTitle={'Test Scenario Create'}
          pageHeadingButtons={pageHeadingButtons}
        />
        {createSpeedMobileTestScenarioMutation.isError && (
          <ErrorMessage>
            {createSpeedMobileTestScenarioMutation.error.message}
          </ErrorMessage>
        )}
        <MobileSpeedTestScenarioInformation />
        <TestScenarioDndSpace />
      </form>
    </FormProvider>
  );
};

export default CreateMobileSpeedTestScenario;
