import { RootState } from '@app/store';
import AddDevice from '@components/AddDevice/AddDevice';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { hasDownloadedData } from '@store/ide/settings/settingsSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  refresh?: () => void;
};

const AddWirelessDeviceButton = ({ refresh }: Props) => {
  const [isWirelessDeviceModal, setIsWirelessDeviceModal] = useState(false);
  const [connectionType, setConnectionType] = useState<'wifi' | 'port'>('wifi');

  const dispatch = useDispatch();
  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  useEffect(() => {
    if (isDownloadedData) {
      refresh && refresh();
      dispatch(hasDownloadedData(false));
    }
  }, [isDownloadedData]);

  const menuItems = [
    {
      idx: 1,
      title: 'Wi-Fi ADB 연결',
      onClick: () => {
        setIsWirelessDeviceModal(true);
        setConnectionType('wifi');
      },
    },
    {
      idx: 2,
      title: 'ADB 포트 입력',
      onClick: () => {
        setIsWirelessDeviceModal(true);
        setConnectionType('port');
      },
    },
  ];

  return (
    <section>
      <Menu>
        <MenuButton>
          <div className="flex items-center justify-between gap-1.5 rounded-md bg-gray-500 px-2.5 py-1.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.25}
              stroke="currentColor"
              className="size-5 font-semibold text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <p className="text-sm font-semibold leading-tight text-white">
              Add Wireless Device
            </p>
          </div>
        </MenuButton>
        <MenuItems
          anchor="bottom"
          className="flex w-[11.5rem] flex-col items-start justify-start gap-2 rounded-md bg-white p-1 shadow"
        >
          {menuItems.map((item) => (
            <MenuItem key={item.idx}>
              <button
                type="button"
                className="size-full rounded-md px-2 py-0.5 text-start text-sm font-normal leading-normal text-gray-900 hover:bg-indigo-500 hover:text-white"
                onClick={item.onClick}
              >
                {item.title}
              </button>
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
      {isWirelessDeviceModal && (
        <AddDevice
          setState={setIsWirelessDeviceModal}
          connectionType={connectionType}
        />
      )}
    </section>
  );
};

export default AddWirelessDeviceButton;
