import {
  ListBoxHeadless,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTestStepValueList } from '@hooks/testStep/useTestStepValueList';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';
import { ClickId } from '@utils/static/clickOptions';

import AddTestStepConditionHeader from '@components/shared/TestStep/AddTestStepConditionHeader';
import ScrollSwipe from '@components/shared/TestStep/ScrollSwipe';
import DeviceKeyListBox from '@components/shared/TestStep/DeviceKeyListBox';
import Repeat from '@components/shared/TestStep/Repeat';
import TestStepValueListbox from '@components/shared/IDE/TestStepValueListbox';
import WaitCommandHookForm from '@components/shared/TestStep/WaitCommandHookForm';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

const LoopAction = () => {
  const { control, watch, resetField, setValue } =
    useFormContext<CreateTestStepData>();

  const [isPassListboxOpened, setIsPassListboxOpened] = useState(false);

  const value = watch('value');
  const conditionCommand = watch('optionsDto.conditionCommand');
  const conditionValue = watch('optionsDto.conditionValue');
  const actionValue = watch('optionsDto.actionValue');

  const { totalValues, bounds } = useTestStepValueList({
    command: value as MobileCommandOptionServerName,
    valueName: 'optionsDto.actionValue',
  });

  useEffect(() => {
    return () => {
      resetField('value');
      resetField('optionsDto.actionValue');
      resetField('optionsDto.actionOptions');
    };
  }, [resetField]);

  useEffect(() => {
    if (value === MobileCommandOptionServerName.Click) {
      setValue('optionsDto.actionOptions.elementOptions.bounds', bounds);
      setValue('optionsDto.actionOptions.clickBy', ClickId.Default);
      return () => setValue('optionsDto.actionOptions', undefined);
    }
  }, [setValue, value, actionValue]);

  useEffect(() => {
    if (value === MobileCommandOptionServerName.Click) {
      setValue('optionsDto.actionOptions.clickBy', ClickId.Default);
    } else {
      setValue('optionsDto.actionOptions.clickBy', undefined);
    }

    return () => {
      setValue('optionsDto.actionOptions', undefined);
    };
  }, [value, setValue]);

  return (
    <>
      <AddTestStepConditionHeader
        header={'Action'}
        description={'반복 실행 될 동작을 설정합니다.'}
      />
      <Controller
        control={control}
        name={'value'}
        rules={{ required: 'Action Command를 선택해주세요.' }}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              findMobileCommandNameFromServerName(
                field.value as MobileCommandOptionServerName,
              ) || 'Command'
            }
            onChange={field.onChange}
            lists={mobileCommandOptions.filter(
              (mobileCommand) =>
                mobileCommand.name === MobileCommandOptionName.DeviceKey ||
                mobileCommand.name === MobileCommandOptionName.ScrollSwipe ||
                mobileCommand.name === MobileCommandOptionName.Click ||
                mobileCommand.name === MobileCommandOptionName.Wait,
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            onClickOption={() => setValue('optionsDto.actionValue', undefined)}
          />
        )}
      />
      {value === MobileCommandOptionServerName.ScrollSwipe ? (
        <>
          <ScrollSwipe value={'optionsDto.actionValue'} />
          <Repeat keyName={'optionsDto.actionOptions.repeatLimit'} />
        </>
      ) : value === MobileCommandOptionServerName.Click ? (
        <Controller
          control={control}
          name={'optionsDto.actionValue'}
          rules={{ required: 'Action Value를 확인해주세요.' }}
          render={({ field }) => (
            <TestStepValueListbox
              valueText={field.value || 'Select Element'}
              setValueText={field.onChange}
              totalValues={totalValues}
              isListboxOpened={isPassListboxOpened}
              setIsListboxOpened={setIsPassListboxOpened}
              resetOnClick={() => {
                setValue('value', undefined);
                setValue('optionsDto.actionValue', undefined);
              }}
              disabled={
                conditionCommand === undefined || conditionValue === undefined
              }
            />
          )}
        />
      ) : value === MobileCommandOptionServerName.DeviceKey ? (
        <DeviceKeyListBox value={'optionsDto.actionValue'} />
      ) : value === MobileCommandOptionServerName.Wait ? (
        <WaitCommandHookForm keyName={'optionsDto.actionValue'} />
      ) : (
        <ListBoxHeadless
          disabled={true}
          state={'Value'}
          setState={() => {}}
          list={[]}
        />
      )}
    </>
  );
};

export default LoopAction;
