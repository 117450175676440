import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const SchedulerTestScenarioListContainer = ({ children }: Props) => {
  return (
    <div>
      <div className="mb-2 flex justify-between">
        <p className="font-medium leading-6">Scheduler Scenario List</p>
      </div>
      <div className="gray-scrollbar h-52 rounded-md border border-solid border-gray-200">
        {children}
      </div>
    </div>
  );
};

export default SchedulerTestScenarioListContainer;
