import { BrowserInfo } from './browser';

export interface WebTestCaseExecutionData {
  infos: BrowserInfo[];
  projectIdx: number;
  caseIdx: number;
}

export interface WebTestScenarioExecutionData {
  scenarioIdx: number;
  projectIdx: number;
  infos: BrowserInfo[];
  repeat?: number;
}

export interface UseExecuteWebTestMutation {
  cookie: string;
  data: WebTestCaseExecutionData;
}

export interface UseExecuteWebTestScenarioMutation {
  cookie: string;
  data: WebTestScenarioExecutionData;
}

export enum WebSpeedTestExecutionType {
  Scheduler = 'monitoring',
  Scenario = 'manual',
  Case = 'unit',
  SpeedCase = 'web_speed',
  SpeedScenario = 'web_speed_scenario',
}

export interface WebSpeedTestCaseExecutionData {
  infos: BrowserInfo;
  projectIdx: number;
  caseIdx: number;
  repeat: number;
}

export interface WebSpeedTestScenarioExecutionData {
  infos: BrowserInfo;
  projectIdx: number;
  scenarioIdx: number;
  repeat: number;
}
