import { RootState } from '@app/store';
import SpeedSchedulerDeviceListbox from '@components/Scheduler/SpeedSchedulerDeviceListbox';
import SpeedSchedulerGridContainer from '@components/Scheduler/SpeedSchedulerGridContainer';
import { SupportedBrowsers } from '@customTypes/ide/browser/browser';
import {
  ConnectionType,
  DeviceInfo,
  DeviceInfoOs,
} from '@customTypes/ide/device/device';
import {
  SpeedSchedulerTypeServerName,
  UpdateSpeedSchedulerData,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorMessage } from '@hookform/error-message';
import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';
import { useSpeedSchedulerDetailQuery } from '@lib/api/ide/speedScheduler/speedScheduler';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const SpeedTestSchedulerDetailDevice = () => {
  const {
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateSpeedSchedulerData>();

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const { data: speedSchedulerDetail } = useSpeedSchedulerDetailQuery();

  const testType = speedSchedulerDetail?.type;

  const [deviceInfoDto, setDeviceInfoDto] = useState<DeviceInfo>(undefined);

  const deviceListQuery = useDeviceListQuery({
    enabled: testType === SpeedSchedulerTypeServerName.Mobile,
  });

  const handleChange = (selectedDevice: DeviceInfo) => {
    setDeviceInfoDto(selectedDevice);
    setValue('infos', selectedDevice);
  };

  useEffect(() => {
    if (testType === SpeedSchedulerTypeServerName.Web) {
      setDeviceInfoDto(undefined);
    }
  }, [testType]);

  useEffect(() => {
    if (deviceInfoDto) {
      clearErrors('infos');
    }
  }, [deviceInfoDto]);

  useEffect(() => {
    const [udid, model, os, connectionType, browser] =
      speedSchedulerDetail.environment.split('/');
    const deviceInfo: DeviceInfo = {
      udid: udid,
      model: model,
      os: os as DeviceInfoOs,
      serialNumber: udid,
      connectionType: connectionType as ConnectionType,
      browser: browser as SupportedBrowsers,
      installedBrowserList: [browser as SupportedBrowsers],
    };
    setDeviceInfoDto(deviceInfo);
    setValue('infos', deviceInfo);
  }, [setValue]);

  return (
    testType === SpeedSchedulerTypeServerName.Mobile && (
      <SpeedSchedulerGridContainer title="Device" isTitleCenter={true}>
        <SpeedSchedulerDeviceListbox
          deviceInfoDto={deviceInfoDto}
          handleChange={handleChange}
          deviceListQuery={deviceListQuery}
          disabled={!isRunning}
        />
        <ErrorMessage
          errors={errors}
          name="infos"
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </SpeedSchedulerGridContainer>
    )
  );
};

export default SpeedTestSchedulerDetailDevice;
