import React from 'react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

interface Props {
  status: 'success' | 'error';
  message: string;
  onClick?: () => void;
}
const ApplicationAlert = ({ status, message, onClick }: Props) => {
  return (
    <div
      className={`absolute -left-8 w-[calc(100%+2rem)] rounded-md p-4 ${
        status === 'success'
          ? 'bg-green-50'
          : status === 'error' && 'bg-yellow-50'
      }`}
    >
      <div className="flex">
        <div className="shrink-0">
          {status === 'success' ? (
            <CheckCircleIcon
              className="size-5 text-green-400"
              aria-hidden="true"
            />
          ) : (
            status === 'error' && (
              <ExclamationTriangleIcon
                className="size-5 text-yellow-400"
                aria-hidden="true"
              />
            )
          )}
        </div>
        <div className="ml-3">
          <p
            className={`text-sm font-medium ${
              status === 'success'
                ? 'text-green-800'
                : status === 'error' && 'text-yellow-800'
            } `}
          >
            {message}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-m-1.5">
            <button
              type="button"
              className={`inline-flex rounded-md p-1.5 ${
                status === 'success'
                  ? 'bg-green-50  text-green-500 hover:bg-green-100'
                  : status === 'error' &&
                    'bg-yellow-50 text-yellow-500 hover:bg-yellow-100'
              }`}
              onClick={onClick}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="size-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationAlert;
