import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  TestCaseDetailSkeleton,
} from '@autosquare/common';
import { UpdateSpeedMobileTestCaseData } from '@customTypes/speedTestCase/type';
import {
  useDeleteSpeedMobileTestCaseMutation,
  useSpeedMobileTestCaseDetailQuery,
  useUpdateSpeedMobileTestCaseMutation,
} from '@lib/api/ide/speedMobile/speedMobileTestCase';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import TestCaseInfoDefault from './TestCaseInfo/TestCaseInfoDefault';
import TestCaseInfoForm from './TestCaseInfo/TestCaseInfoForm';
import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '@app/store';
import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

type Props = {
  idx: string;
};

const TestCaseInfo = ({ idx }: Props) => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();

  const speedMobileTestCaseDetailQuery = useSpeedMobileTestCaseDetailQuery(idx);
  const speedMobileTestCaseDetail = speedMobileTestCaseDetailQuery.data;

  const updateSpeedMobileTestCaseMutation =
    useUpdateSpeedMobileTestCaseMutation(idx);

  const deleteSpeedMobileTestCaseMutation =
    useDeleteSpeedMobileTestCaseMutation(projectIdx);

  const method = useForm<UpdateSpeedMobileTestCaseData>();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const onSubmit: SubmitHandler<UpdateSpeedMobileTestCaseData> = (data) => {
    updateSpeedMobileTestCaseMutation.mutate(data, {
      onSuccess: () => {
        setIsEdited(false);
      },
    });
  };

  const openEditTestCase = () => {
    setIsEdited(true);
    deleteSpeedMobileTestCaseMutation.reset();
  };

  const closeEditTestCase = () => {
    setIsEdited(false);
    method.reset();
  };

  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );
  const dispatch = useDispatch();

  return (
    <FormProvider {...method}>
      <form onSubmit={method.handleSubmit(onSubmit)}>
        <div className="mt-8 pb-5 sm:flex sm:items-center sm:justify-between">
          <h2 className="text-base font-semibold leading-6 text-gray-900">
            Test Case Information
          </h2>
          <div className="mt-3 flex gap-2 sm:ml-4 sm:mt-0">
            {isEdited ? (
              <>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={closeEditTestCase}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={openEditTestCase}
                >
                  Edit
                </Button>
                <Button
                  type="button"
                  variant="caution"
                  onClick={() => setIsModalOpened(true)}
                >
                  Delete
                </Button>
              </>
            )}
          </div>
        </div>
        <ErrorMessage>
          {updateSpeedMobileTestCaseMutation.isError
            ? updateSpeedMobileTestCaseMutation.error.message
            : deleteSpeedMobileTestCaseMutation.isError &&
              deleteSpeedMobileTestCaseMutation.error.message}
        </ErrorMessage>
        {speedMobileTestCaseDetailQuery.isFetching ||
        speedMobileTestCaseDetailQuery.isLoading ? (
          <TestCaseDetailSkeleton testCategory={'speed'} platform={'mobile'} />
        ) : isEdited ? (
          <TestCaseInfoForm />
        ) : (
          <TestCaseInfoDefault
            speedMobileTestCaseDetail={speedMobileTestCaseDetail}
          />
        )}
      </form>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={'Delete Test Case'}
        subTitle={'해당 테스트 케이스를 삭제하시겠습니까?'}
        buttonChildren={
          <>
            <Button
              type="button"
              variant="caution"
              onClick={() =>
                deleteSpeedMobileTestCaseMutation.mutate(
                  { idxList: [Number(idx)] },
                  {
                    onSuccess: () => {
                      setIsModalOpened(false);
                      navigate(
                        `/ide/projects/${projectIdx}/speed-test/mobile/test-case`,
                      );
                    },
                  },
                )
              }
            >
              Delete
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setIsModalOpened(false)}
            >
              Cancel
            </Button>
          </>
        }
      />
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
    </FormProvider>
  );
};

export default TestCaseInfo;
