import { TestResultDetails } from "@customTypes/testResult/type";

import React from "react";

type Props = {
  resultDetails: TestResultDetails[];
  handleClick: (item: string) => void;
  activeSection: string;
};

const TestCaseResultListSidebar = ({
  resultDetails,
  handleClick,
  activeSection,
}: Props) => {
  return (
    <aside className="hidden fixed inset-y-16 right-0 w-96 border-l border-gray-200 px-4 py-1 sm:px-6 lg:px-8 xl:block scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 overflow-x-hidden overflow-y-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
      <div className="border-b border-gray-200 bg-white py-5 mb-4">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Test Case Result List
        </h3>
      </div>
      <div className="flow-root">
        <ul role="list" className="space-y-6">
          {resultDetails?.map((resultDetail, index) => (
            <li key={resultDetail.seq} className="relative flex gap-x-4">
              <div
                className={`absolute left-0 top-0 flex w-6 justify-center ${
                  index === resultDetails?.length - 1 ? "h-6" : "-bottom-6"
                }`}
              >
                <div className="w-px bg-gray-200" />
              </div>
              <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                <div
                  className={`h-1.5 w-1.5 rounded-full ring-1 ring-gray-300 ${
                    resultDetail.result === "Pass"
                      ? "bg-green-500"
                      : resultDetail.result === "Fail"
                      ? "bg-red-500"
                      : "bg-gray-500"
                  }`}
                />
              </div>
              <button
                type="button"
                onClick={() => handleClick(resultDetail.title)}
                className={`flex-auto py-0.5 text-start text-xs leading-5 font-medium text-gray-900 hover:font-semibold ${
                  activeSection === resultDetail.title && "font-bold"
                }`}
              >
                {resultDetail.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default TestCaseResultListSidebar;
