import { ListSkeleton } from "@autosquare/common";

import React from "react";

interface Props {
  isFetching: boolean;
}

const ProjectNotExist = ({ isFetching }: Props) => {
  return (
    <>
      {isFetching ? (
        <ListSkeleton />
      ) : (
        <div className="mx-auto px-4 max-w-full h-[calc(100vh-16rem)] flex">
          <div className="text-center m-auto">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              참여된 프로젝트가 없습니다.
            </h3>
            {/* <p className="mt-1 text-sm text-gray-500">
              새로운 프로젝트를 생성하여 시작할 수 있습니다.
            </p>
            <div className="mt-6">
              <Link to="create-projects">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-congress-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-allports focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create Project
                </button>
              </Link>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectNotExist;
