import { RootState } from '@app/store';
import { SpeedTestScenarioDataInScheduler } from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import clsx from 'clsx';
import React, { useRef } from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
  XYCoord,
} from 'react-dnd';
import { useSelector } from 'react-redux';

type Props = {
  index: number;
  testScenario: SpeedTestScenarioDataInScheduler;
  onRemove: (index: number) => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
};

const SchedulerTestScenarioListCard = ({
  index,
  testScenario,
  onRemove,
  moveCard,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    canDrag: isWordInLocationPathname('detail') ? isRunning : true,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'card',
    hover(item: { index: number }, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current!.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      if (item.index !== undefined) {
        item.index = hoverIndex;
      }
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={clsx(
        'm-2 flex items-center justify-between border border-solid border-border-line bg-input-bg px-3 py-2',
        isDragging ? 'opacity-50' : 'opacity-100',
        isWordInLocationPathname('create') || isRunning
          ? 'cursor-move'
          : 'cursor-default',
      )}
    >
      <div className="flex items-center">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 9h16.5m-16.5 6.75h16.5"
            />
          </svg>
        </div>
        <div className="ml-7">{testScenario.title}</div>
      </div>
      <button
        type="button"
        onClick={() => onRemove(index)}
        disabled={
          isWordInLocationPathname('create')
            ? false
            : isWordInLocationPathname('detail') && !isRunning
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-4 text-gray-700"
        >
          <path
            fillRule="evenodd"
            d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default SchedulerTestScenarioListCard;
