import { ListBoxHeadlessInController } from '@autosquare/common';

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { RootState } from '@app/store';

import { useMobileTestStepBySaveTextQuery } from '@lib/api/ide/mobile/mobileTestStep';

import NoSaveTestStep from '@components/shared/TestStep/NoSaveTestStep';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import {
  CompareRange,
  CreateTestStepData,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import SecretBox from '@components/shared/TestStep/SecretBox';
import clsx from 'clsx';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useSpeedMobileTestStepBySaveTextQuery } from '@lib/api/ide/speedMobile/speedMobileTestStep';

const ConfirmAndInputText = () => {
  const [searchParams] = useSearchParams();
  const testCaseIdx = Number(searchParams.get('idx'));
  const isUiTestLocation = isWordInLocationPathname('ui-test');

  const { register, watch, control, resetField, setValue } =
    useFormContext<CreateTestStepData>();

  const command = watch('command');
  const compareBy = watch('optionsDto.compareBy');

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const getTestStepsByCommandQuery = isUiTestLocation
    ? useMobileTestStepBySaveTextQuery(testCaseIdx)
    : useSpeedMobileTestStepBySaveTextQuery(testCaseIdx);

  const saveTextLists = (
    getTestStepsByCommandQuery.data as MobileTestStepData[]
  )?.filter(
    (saveText: MobileTestStepData) => saveText.mobileOs === deviceInfo.os,
  ) as MobileTestStepData[];

  const findDescriptionFromIdx = (idx: number) =>
    saveTextLists?.find((saveText) => saveText.idx === idx)?.description;

  useEffect(() => {
    return () => {
      resetField('optionsDto.compareBy');
      resetField('optionsDto.compareText');
      resetField('optionsDto.compareIdx');
      resetField('optionsDto.compareRange');
    };
  }, [resetField]);

  useEffect(() => {
    if (command === MobileCommandOptionServerName.ConfirmText) {
      setValue('optionsDto.compareRange', CompareRange.Equal);
    }

    if (command === MobileCommandOptionServerName.InputText) {
      resetField('optionsDto.compareRange');
    }
  }, [resetField, command, setValue]);

  const isSecret = watch('optionsDto.textEncrypt');

  const radioOptions = [
    {
      id: 'id',
      title: '테스트 스텝 선택',
      onClick: () => {
        resetField('optionsDto.compareText');
      },
      defaultChecked: true,
    },
    {
      id: 'text',
      title: '직접 입력',
      onClick: () => {
        resetField('optionsDto.compareIdx');
      },
      defaultChecked: false,
    },
  ];

  const equalAndContainRadioOptions = [
    {
      title: '동일',
      id: CompareRange.Equal,
      defaultChecked: true,
    },
    {
      title: '포함',
      id: CompareRange.Contain,
      defaultChecked: false,
    },
  ];

  return (
    <>
      <div className="space-y-4 sm:flex sm:items-center sm:justify-between sm:space-x-10 sm:space-y-0">
        <fieldset className="space-y-4 sm:flex sm:items-center sm:justify-start sm:space-x-10 sm:space-y-0">
          {radioOptions?.map((radioOption) => (
            <div key={radioOption.id} className="flex items-center">
              <input
                id={radioOption.id}
                name={'compareText'}
                type="radio"
                defaultChecked={radioOption.defaultChecked}
                onClick={radioOption.onClick}
                value={radioOption.id}
                className="top-1 size-4 appearance-none rounded-[50%] border border-solid border-gray-300 align-top text-indigo-600 transition-all duration-200 ease-linear checked:border-[6px] checked:border-solid checked:border-indigo-600 focus:ring-indigo-600"
                {...register('optionsDto.compareBy')}
              />
              <label
                htmlFor={radioOption.id}
                className="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900"
              >
                {radioOption.title}
              </label>
            </div>
          ))}
        </fieldset>
        {compareBy === 'text' &&
          command === MobileCommandOptionServerName.InputText && (
            <SecretBox control={control} />
          )}
      </div>
      {compareBy === 'id' && saveTextLists?.length === 0 ? (
        <NoSaveTestStep />
      ) : compareBy === 'id' && saveTextLists?.length !== 0 ? (
        <Controller
          control={control}
          name="optionsDto.compareIdx"
          rules={{ required: 'Test Step을 선택해주세요.' }}
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={
                findDescriptionFromIdx(Number(field.value)) ||
                'Select Test Step'
              }
              onChange={field.onChange}
              lists={saveTextLists}
              valueToSave={'idx'}
              valueToShow={'description'}
            />
          )}
        />
      ) : (
        compareBy === 'text' && (
          <input
            className={clsx('input-base', {
              'password-input': isSecret,
            })}
            type="text"
            placeholder={
              command === MobileCommandOptionServerName.ConfirmText
                ? '비교할 텍스트를 직접 입력해주세요.'
                : '텍스트를 입력해주세요.'
            }
            {...register('optionsDto.compareText', {
              required: '텍스트를 입력해주세요.',
            })}
          />
        )
      )}
      {command === MobileCommandOptionServerName.ConfirmText && (
        <fieldset>
          <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            <legend className="text-sm">비교 범위 선택 : </legend>
            {equalAndContainRadioOptions.map((radioOption) => (
              <div key={radioOption.id} className="flex items-center">
                <input
                  id={radioOption.id}
                  name={'compareRange'}
                  type="radio"
                  defaultChecked={radioOption.defaultChecked}
                  value={radioOption.id}
                  className="top-1 size-4 appearance-none rounded-[50%] border border-solid border-gray-300 align-top text-indigo-600 transition-all duration-200 ease-linear checked:border-[6px] checked:border-solid checked:border-indigo-600 focus:ring-indigo-600"
                  {...register('optionsDto.compareRange')}
                />
                <label
                  htmlFor={radioOption.id}
                  className="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900"
                >
                  {radioOption.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      )}
    </>
  );
};

export default ConfirmAndInputText;
