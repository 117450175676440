import { RootState } from '@app/store';
import {
  BackToListIcon,
  Breadcrumbs,
  ErrorMessage,
  NoResults,
  PageHeadingButtons,
  PagingHeadingsNavigate,
  SpeedTestResultDetailSkeleton,
} from '@autosquare/common';
import RangePickEndCalendar from '@components/MobileSpeedTestResultDetail/RangePickCalendar/RangePickEndCalendar';
import RangePickStartCalendar from '@components/MobileSpeedTestResultDetail/RangePickCalendar/RangePickStartCalendar';
import InformationTable from '@components/WebSpeedTestResultDetail/InformationTable/InformationTable';
import SpeedTestResultGraph from '@components/WebSpeedTestResultDetail/SpeedTestResultGraph/SpeedTestReulstGraph';
import { useWebSpeedTestResultDetailQuery } from '@lib/api/ide/speedWeb/webSpeedTestResult';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

const IdeWebSpeedTestResultDetail = () => {
  const navigate = useNavigate();
  const entryPoint = useSelector(
    (state: RootState) => state.locationHistory.clickLocation,
  );
  const { pathname, search, state } = useLocation();
  const { projectIdx } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const webSpeedTestResultDetailQuery = useWebSpeedTestResultDetailQuery();
  const webSpeedTestResultDetailData = webSpeedTestResultDetailQuery.data;

  const start = searchParams.get('start');
  const defaultStartDate = dayjs(start).toDate();

  const end = searchParams.get('end');
  const defaultEndDate = dayjs(end).toDate();

  const resultIdx = searchParams.get('result');
  const groupIdx = searchParams.get('group');

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const isResultNA = state.status === 'NA';
  const failReason = webSpeedTestResultDetailData?.failReason;

  const overviewPage = `${pathname.split('/').slice(0, -3).join('/')}/overview`;
  const testResultListPage = `${pathname.split('/').slice(0, -1).join('/')}?page=1`;
  const testUrl = `${localStorage.getItem('applicationUrl')}/projects/${projectIdx}/web/speed/test-result/detail${search}`;
  const noData = webSpeedTestResultDetailData?.date?.length === 0;

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Speed Test', href: overviewPage },
    {
      title: 'Web',
      href: testResultListPage,
    },
    {
      title: 'Test Result',
      href: testResultListPage,
    },
    { title: 'Detail', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: (
        <div className="flex items-center justify-between gap-1.5 text-sm font-semibold leading-5 text-gray-900">
          <BackToListIcon />
          Back to list
        </div>
      ),
      onClick: () => navigate(entryPoint),
      type: 'button',
      variant: 'secondary',
    },
  ];
  useEffect(() => {
    setStartDate(new Date(dayjs(start).format('YYYY-MM-DD')));
    webSpeedTestResultDetailQuery.refetch();
  }, [start]);

  useEffect(() => {
    setEndDate(new Date(dayjs(end).format('YYYY-MM-DD')));
    webSpeedTestResultDetailQuery.refetch();
  }, [end]);
  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result Detail'}
        pageHeadingButtons={pageHeadingButtons}
        url={testUrl}
        copy={true}
      />
      {webSpeedTestResultDetailQuery.isLoading ? (
        <SpeedTestResultDetailSkeleton />
      ) : webSpeedTestResultDetailQuery.isError ? (
        <ErrorMessage>
          {webSpeedTestResultDetailQuery.error.message}
        </ErrorMessage>
      ) : (
        <div className="mt-14 px-8">
          <div className="flex items-center justify-between">
            <h2 className="font-semibold">
              {webSpeedTestResultDetailData?.menu} Loading Time 비교측정 결과
            </h2>
            {!isResultNA && (
              <div className="flex items-center justify-center gap-x-3">
                <RangePickStartCalendar
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  resultIdx={resultIdx}
                  groupIdx={groupIdx}
                  minDate={webSpeedTestResultDetailData.date[0]}
                  isWeb={true}
                />
                <span>&#45;</span>
                <RangePickEndCalendar
                  endDate={endDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  resultIdx={resultIdx}
                  groupIdx={groupIdx}
                  isWeb={true}
                />
              </div>
            )}
          </div>
          {noData ? (
            <NoResults />
          ) : (
            <>
              <InformationTable data={webSpeedTestResultDetailData} />
              {webSpeedTestResultDetailQuery.isRefetching ? (
                <SpeedTestResultDetailSkeleton
                  isRefetching={webSpeedTestResultDetailQuery.isRefetching}
                />
              ) : (
                <SpeedTestResultGraph
                  data={webSpeedTestResultDetailData}
                  isResultNA={isResultNA}
                  failReason={failReason}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default IdeWebSpeedTestResultDetail;
