export const groupKeys = {
  all: ['testCaseGroup'] as const,
  lists: () => [...groupKeys.all, 'list'] as const,
  list: (projectIdx: string) => [...groupKeys.lists(), projectIdx] as const,
};

export const serviceGroupKeys = {
  all: ['testCaseServiceGroup'] as const,
  lists: () => [...serviceGroupKeys.all, 'list'] as const,
  list: (projectIdx: string) =>
    [...serviceGroupKeys.lists(), projectIdx] as const,
};
