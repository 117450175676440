import {
  Checkbox,
  ErrorMessage,
  TableBodyContainer,
  TableHeader,
  TableNoList,
  TableSkeleton,
  useCheckbox,
} from '@autosquare/common';

import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';

import {
  useDeleteWebTestStepMutation,
  useWebTestStepListQuery,
} from '@lib/api/ide/web/webTestStep';

import { useUpdateWebTestCaseStepMutation } from '@lib/api/ide/web/webTestCase';

import { WebTestStepData } from '@customTypes/testStep/type';

import React, { useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import WebTestStepTableBodyRow from './WebTestStepList/WebTestStepTableBodyRow';
import { TableWrapper } from './WebTestStepList/TableWrapper';
import WebTestStepListHeader from './WebTestStepList/WebTestStepListHeader';
import DeleteTestStepDialog from './WebTestStepList/DeleteTestStepDialog';

const WebTestStepList = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [lists, setLists] = useState<WebTestStepData[]>([]);
  const [indexArray, setIndexArray] = useState<number[]>(null);

  const {
    data: testStepLists,
    isLoading,
    isError,
    error,
  } = useWebTestStepListQuery(idx);

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(testStepLists, 'idx');

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;
      if (!destination) return;

      const items = Array.from(lists);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      setLists(items);
      setIndexArray(items.map((item) => item.idx));
    },
    [lists],
  );

  const updateWebTestCaseStepMutation = useUpdateWebTestCaseStepMutation(idx);

  const deleteWebTestStepMutation = useDeleteWebTestStepMutation({
    caseIdx: idx,
    idxList: selectedArray,
  });

  useEffect(() => {
    setLists(testStepLists);
  }, [testStepLists]);

  useEffect(() => {
    updateWebTestCaseStepMutation.mutate({ step: indexArray });
  }, [indexArray]);

  const tableTitles = [
    {
      idx: 1,
      element: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={selectAllArray}
        />
      ),
    },
    { idx: 2, element: selectedArray.length > 0 ? '' : '#' },
    { idx: 3, element: selectedArray.length > 0 ? '' : 'Command' },
    { idx: 4, element: 'Value' },
    { idx: 5, element: 'Description' },
    { idx: 6, element: 'Required' },
    { idx: 7, element: 'Detail' },
  ];

  return (
    <>
      <section className="px-4 pt-6 sm:px-0">
        <WebTestStepListHeader
          setIsTestExecutionOpened={setIsTestExecutionOpened}
          testStepListlength={testStepLists?.length}
        />
        <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
          <thead>
            <tr>
              {tableTitles.map((tableTitle) => (
                <TableHeader key={tableTitle.idx}>
                  {tableTitle.element}
                </TableHeader>
              ))}
            </tr>
          </thead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <TableBodyContainer
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {isLoading ? (
                    <TableNoList colSpan={tableTitles?.length}>
                      <TableSkeleton />
                    </TableNoList>
                  ) : isError ? (
                    <TableNoList colSpan={tableTitles?.length}>
                      <ErrorMessage>{error.message}</ErrorMessage>
                    </TableNoList>
                  ) : testStepLists?.length === 0 ? (
                    <TableNoList colSpan={tableTitles?.length}>
                      추가된 테스트 스텝이 없습니다.
                    </TableNoList>
                  ) : (
                    testStepLists?.map((testStepList, index) => (
                      <WebTestStepTableBodyRow
                        testStepList={testStepList}
                        index={index}
                        key={testStepList.idx}
                        selectArray={selectArray}
                        selectedArray={selectedArray}
                      />
                    ))
                  )}
                  {provided.placeholder}
                </TableBodyContainer>
              )}
            </Droppable>
          </DragDropContext>
        </TableWrapper>
        <DeleteTestStepDialog
          isOpenedCautionDialog={isOpenedCautionDialog}
          setIsOpenedCautionDialog={setIsOpenedCautionDialog}
          deleteWebTestStepMutation={deleteWebTestStepMutation}
          openDialog={openDialog}
          resetSelectedArray={resetSelectedArray}
          closeDialog={closeDialog}
        />
      </section>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={Number(idx)}
          testType={'case'}
          deviceType={'web'}
        />
      )}
    </>
  );
};

export default WebTestStepList;
