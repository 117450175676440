import { DropDownSVG } from '@autosquare/common';
import { GroupType, useGroupContext } from '@contexts/ide/GroupProvider';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';
import React from 'react';
import { useDispatch } from 'react-redux';

type MenuItem = {
  name: string;
  onClick: () => void;
};

const GroupCreateSelectBox = () => {
  const { setClickGroupType } = useGroupContext();

  const dispatch = useDispatch();

  const clickHandler = (clickType: GroupType) => {
    dispatch(openCreateGroup());
    setClickGroupType(clickType);
  };

  const menuItem: MenuItem[] = [
    {
      name: 'Test Group',
      onClick: () => clickHandler('Test'),
    },
    {
      name: 'Service Group',
      onClick: () => clickHandler('Service'),
    },
  ];

  return (
    <div className="flex justify-center items-center gap-1">
      <Menu as="div" className="relative">
        <MenuButton className="inline-flex items-center gap-2 rounded-md bg-blue-200 py-2 px-2.5 text-sm font-semibold text-gray-900 shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-blue-400 data-[hover]:text-white data-[open]:bg-blue-300 data-[open]:text-white data-[focus]:outline-1 data-[focus]:outline-white">
          Create
          <DropDownSVG />
        </MenuButton>
        <Transition
          enter="transition ease-out duration-75"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <MenuItems className="z-10 absolute top-10 right-1 w-36 border border-gray-200 rounded-md bg-white p-1 text-sm/6 text-gray-900 [--anchor-gap:var(--spacing-1)] focus:outline-none">
            {menuItem.map((item) => (
              <MenuItem key={item.name}>
                <button
                  type="button"
                  className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-gray-300/20"
                  onClick={item.onClick}
                >
                  {item.name}
                </button>
              </MenuItem>
            ))}
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
};

export default GroupCreateSelectBox;
