import { ListBoxHeadlessInController } from '@autosquare/common';

import {
  findTextReplaceAddNameByServerName,
  textReplaceAddList,
} from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';

import DefinitionList from '@components/shared/DefinitionList';

import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { AddServerName } from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  addKey: AddServerName;
  addValue: number;
};

const Add = ({ addKey, addValue }: Props) => {
  const {
    control,
    register,
    setValue,
    resetField,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useFormContext<UpdateTestStepData>();

  useEffect(() => {
    if (addKey) {
      setValue('optionsDto.textReplaceOptions.addKey', addKey);
    } else {
      setValue('optionsDto.textReplaceOptions.addKey', AddServerName.Day);
    }

    return () => {
      setValue('optionsDto.textReplaceOptions.addKey', undefined);
    };
  }, [addKey, setValue, resetField]);

  useEffect(() => {
    if (addValue) {
      setValue('optionsDto.textReplaceOptions.addValue', addValue);
    } else {
      setValue('optionsDto.textReplaceOptions.addValue', 0);
    }

    return () => {
      setValue('optionsDto.textReplaceOptions.addValue', undefined);
    };
  }, [addValue, setValue, resetField]);

  const watchAddValue = watch('optionsDto.textReplaceOptions.addValue');
  useEffect(() => {
    if (watchAddValue?.toString() === '') {
      setError('optionsDto.textReplaceOptions.addValue', {
        type: 'manual',
        message: 'Add 항목의 값을 입력해주세요.',
      });
    } else if (watchAddValue > 51) {
      setError('optionsDto.textReplaceOptions.addValue', {
        type: 'manual',
        message: '50이하 숫자를 입력해주세요.',
      });
    }
  }, [watchAddValue, setError]);

  useEffect(() => {
    if (watchAddValue && watchAddValue <= 50) {
      clearErrors('optionsDto.textReplaceOptions.addValue');
    }
  }, [watchAddValue, clearErrors]);

  return (
    <DefinitionList
      term={'Add*'}
      description={
        <div className="grid grid-cols-7 gap-x-6">
          <div className="col-span-2">
            <Controller
              control={control}
              name="optionsDto.textReplaceOptions.addKey"
              render={({ field }) => (
                <ListBoxHeadlessInController
                  value={field.value || 'Select'}
                  buttonValue={
                    findTextReplaceAddNameByServerName(field.value) || 'Select'
                  }
                  onChange={field.onChange}
                  lists={textReplaceAddList}
                  valueToSave={'serverName'}
                  valueToShow={'name'}
                />
              )}
            />
          </div>
          <div className="col-span-5">
            <input
              type="number"
              className="input-base"
              min={0}
              max={50}
              {...register('optionsDto.textReplaceOptions.addValue')}
            />
          </div>
          <ErrorMessage
            name="optionsDto.textReplaceOptions.addValue"
            errors={errors}
            render={({ message }) => (
              <p className="standard-error-message col-span-4">{message}</p>
            )}
          />
        </div>
      }
    />
  );
};

export default Add;
