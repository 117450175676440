import React from 'react';
import SpeedRepeatResultTableHeader from '@components/shared/SpeedTestResultTable/SpeedTestResultTableDetail/SpeedRepeatResult/SpeedRepeatResultTable/SpeedRepeatResultTableHeader';

import { WebSpeedTestResultExecutionDetail } from '@customTypes/ide/speedTest/Web/testResult/type';
import SpeedRepeatResultTableRow from './SpeedRepeatResultTableRow';

type Props = {
  repeatResults: WebSpeedTestResultExecutionDetail[];
};

const SpeedRepeatResultTable = ({ repeatResults }: Props) => {
  const headers = ['Repeat', 'DateTime', 'DomTime', 'LoadTime(s)'];

  return (
    <div className="w-full">
      <SpeedRepeatResultTableHeader headers={headers} isWeb={true} />
      {repeatResults?.map((result, index) => (
        <SpeedRepeatResultTableRow
          key={result.idx}
          result={result}
          index={index}
        />
      ))}
    </div>
  );
};

export default SpeedRepeatResultTable;
