import React from "react";

import NoChildrenSidebarList from "./NoChildrenSidebarList";
import ProjectSidebarSubList from "./ProjectSidebarSubList";

import { SidebarList } from "@customTypes/type";

type Props = {
  sidebarLists: SidebarList[];
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminSidebarMonitorSize = ({
  sidebarLists,
  setIsToggledSidebar,
}: Props) => {
  return (
    <div className="flex grow flex-col overflow-y-auto bg-white px-6 h-full">
      <div className="flex h-16 shrink-0 items-center">
        <span className="text-sm font-semibold">Administration</span>
      </div>
      <div className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {sidebarLists?.map((sidebarList) => (
                <li key={sidebarList.name}>
                  {!sidebarList.children ? (
                    <NoChildrenSidebarList
                      sidebarList={sidebarList}
                      setIsToggledSidebar={setIsToggledSidebar}
                    />
                  ) : (
                    <ProjectSidebarSubList
                      sidebarList={sidebarList}
                      setIsToggledSidebar={setIsToggledSidebar}
                    />
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminSidebarMonitorSize;
