import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { getErrorHookForm } from '@utils/getErrorHookForm';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

type Props = {
  isError: boolean;
  error: Error;
};

const AddButton = ({ isError, error }: Props) => {
  const {
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const errorKey = getErrorHookForm(errors);

  return (
    <div>
      <div className="flex items-center justify-between">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Add Test Step
        </h3>
        <div className="mt-3 sm:ml-4 sm:mt-0">
          <button
            type="submit"
            className="inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 hover:text-congress-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
                clipRule="evenodd"
              />
            </svg>
            <p className="ml-2">Add</p>
          </button>
        </div>
      </div>
      <ErrorMessage
        errors={errors}
        name={errorKey}
        render={({ message }) => <p className="error-message">{message}</p>}
      />
      {isError && <p className="error-message">{error.message}</p>}
    </div>
  );
};

export default AddButton;
