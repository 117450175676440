import { useMobileTestStepBySaveTextQuery } from '@lib/api/ide/mobile/mobileTestStep';

import InputRadio from '@components/shared/TestStepDetail/InputRadio';
import Textarea from '@components/shared/TestStepDetail/Textarea';
import SecretEditBox from '@components/shared/TestStep/SecretEditBox';

import {
  CompareBy,
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import { useSpeedMobileTestStepBySaveTextQuery } from '@lib/api/ide/speedMobile/speedMobileTestStep';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';

import TestStepSelectSaveText from './TestStepConfirmOrInputText/TestStepSelectSaveText';

interface Props {
  mobileTestStep: MobileTestStepData;
}

const TestStepConfirmOrInputText = ({ mobileTestStep }: Props) => {
  const [searchParams] = useSearchParams();
  const testCaseIdx = Number(searchParams.get('idx'));

  const location = isWordInLocationPathname('ui-test');
  const mobileTestStepBySaveTextQuery = location
    ? useMobileTestStepBySaveTextQuery(testCaseIdx)
    : useSpeedMobileTestStepBySaveTextQuery(testCaseIdx);

  const saveTextLists = (
    mobileTestStepBySaveTextQuery.data as MobileTestStepData[]
  )?.filter(
    (saveText: MobileTestStepData) =>
      saveText.mobileOs === mobileTestStep.mobileOs,
  ) as MobileTestStepData[];

  const {
    watch,
    setValue,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();
  const compareBy = watch('optionsDto.compareBy');

  const radioOptions = [
    {
      id: 'id',
      title: '테스트 스텝 선택',
      defaultChecked: mobileTestStep.optionsDto.compareBy === CompareBy.Id,
      onClick: () => {
        setValue(
          'optionsDto.compareIdx',
          mobileTestStep.optionsDto.compareBy === CompareBy.Id
            ? mobileTestStep.optionsDto.compareIdx
            : undefined,
        );
      },
    },
    {
      id: 'text',
      title: '직접 입력',
      defaultChecked: mobileTestStep.optionsDto.compareBy === CompareBy.Text,
      onClick: () => {
        setValue(
          'optionsDto.compareIdx',
          mobileTestStep.optionsDto.compareBy === CompareBy.Id
            ? mobileTestStep.optionsDto.compareIdx
            : undefined,
        );
        setValue(
          'optionsDto.compareText',
          mobileTestStep.optionsDto.compareBy === CompareBy.Text
            ? mobileTestStep.optionsDto.compareText
            : undefined,
        );
      },
    },
  ];
  const equalAndContainRadioOptions = [
    {
      title: '동일',
      id: 'equal',
      defaultChecked: mobileTestStep.optionsDto.compareRange === 'equal',
    },
    {
      title: '포함',
      id: 'contain',
      defaultChecked: mobileTestStep.optionsDto.compareRange === 'contain',
    },
  ];

  const isSecret = watch('optionsDto.textEncrypt') === 'true' ? true : false;
  const [hasValue] = useState(
    mobileTestStep.optionsDto.compareBy === CompareBy.Text,
  );

  useEffect(() => {
    if (
      mobileTestStep.optionsDto.compareBy === CompareBy.Text &&
      mobileTestStep.optionsDto.textEncrypt === 'true'
    ) {
      setValue('optionsDto.compareText', null);
    }
  }, []);

  useEffect(() => {
    if (compareBy === 'text') {
      clearErrors('optionsDto.compareIdx');
    }
  }, [compareBy, clearErrors]);

  return (
    <div className="items-center sm:flex sm:px-6 sm:py-4">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48 ">
        Value*
      </dt>
      <dd className="mt-1 grid w-full grid-rows-1 gap-2 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        <Textarea name={'value'} />
        <div className="space-y-4 sm:flex sm:items-center sm:justify-between sm:space-x-10 sm:space-y-0">
          <InputRadio
            name={'optionsDto.compareBy'}
            radioOptions={radioOptions}
          />
          {mobileTestStep.command === MobileCommandOptionServerName.InputText &&
            compareBy === 'text' && <SecretEditBox control={control} />}
        </div>
        {compareBy === 'id' && saveTextLists?.length === 0 ? (
          <div className="flex flex-col items-center justify-center border border-solid border-border-line p-3 text-sm">
            <p className="mb-2">선택 가능한 테스트 스텝이 없습니다.</p>
            <p>Save Text Step을 먼저 추가해주세요.</p>
          </div>
        ) : compareBy === 'id' && saveTextLists?.length !== 0 ? (
          <TestStepSelectSaveText saveTextLists={saveTextLists} />
        ) : (
          compareBy === 'text' && (
            <>
              <input
                type={'text'}
                className={clsx('input-base', {
                  'password-input': isSecret,
                })}
                placeholder={
                  mobileTestStep.command ==
                  MobileCommandOptionServerName.ConfirmText
                    ? '비교할 텍스트를 직접 입력해주세요.'
                    : mobileTestStep.command ==
                          MobileCommandOptionServerName.InputText &&
                        isSecret &&
                        hasValue
                      ? '암호화된 입력 값은 표시되지 않습니다.'
                      : '텍스트를 입력해주세요.'
                }
                {...register('optionsDto.compareText', {
                  required: {
                    value:
                      mobileTestStep.optionsDto.compareBy === CompareBy.Text &&
                      !isSecret,
                    message: 'Value값을 입력해 주세요.',
                  },
                })}
              />
              <ErrorMessage
                name={'optionsDto.compareText'}
                errors={errors}
                render={({ message }) => (
                  <p className="standard-error-message">{message}</p>
                )}
              />
            </>
          )
        )}
        {mobileTestStep.command ===
          MobileCommandOptionServerName.ConfirmText && (
          <InputRadio
            name={'optionsDto.compareRange'}
            radioOptions={equalAndContainRadioOptions}
          />
        )}
      </dd>
    </div>
  );
};

export default TestStepConfirmOrInputText;
