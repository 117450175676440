import {
  QuestionsMarkCircle,
  TooltipTop,
  useDeviceSize,
} from '@autosquare/common';
import clsx from 'clsx';
import React, { useState } from 'react';
import TestStepRepeatStartBox from './TestStepRepeatBox/TestStepRepeatStartBox';
import TestStepRepeatFinishBox from './TestStepRepeatBox/TestStepRepeatFinishBox';
import { generateRepeatStepList } from '@utils/static/mobileTestStep/findTitleFromIdxBySpeedMobileStep';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useGetSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';

type Props = {
  title: DeviceInfoOs;
};

const TestStepListRepeatBox = ({ title }: Props) => {
  const { width: deviceWidth } = useDeviceSize();
  const speedMobileTestStepQuery = useGetSpeedMobileTestStep();
  const speedMobileTestStepList = speedMobileTestStepQuery?.data;

  const list =
    title === DeviceInfoOs.Aos
      ? speedMobileTestStepList.aos
      : speedMobileTestStepList.ios;

  const startFilteredList = generateRepeatStepList(list, true);
  const endFilteredList = generateRepeatStepList(list, false);

  const startRepeatIdx =
    title === DeviceInfoOs.Ios
      ? speedMobileTestStepList?.iosRepeatInfo?.startIdx
      : speedMobileTestStepList?.aosRepeatInfo?.startIdx;
  const endRepeatIdx =
    title === DeviceInfoOs.Ios
      ? speedMobileTestStepList?.iosRepeatInfo?.endIdx
      : speedMobileTestStepList?.aosRepeatInfo?.endIdx;

  const [error, setError] = useState(startRepeatIdx && !endRepeatIdx);

  return (
    <div className="grid grid-rows-3 gap-y-2.5 rounded-md p-4">
      <div className="flex items-center justify-between border-b border-gray-200 pb-1.5">
        <div className="flex items-center justify-start gap-x-2">
          <h2 className="text-sm font-medium text-gray-900">Repeat Step</h2>
          <div className="group relative">
            <QuestionsMarkCircle />
            <TooltipTop
              positionClass={clsx(
                'icon-description bottom-6 opacity-0 group-hover:opacity-100',
                {
                  ' after:left-[20%] -left-[3.75rem] ': deviceWidth > 1280,
                  'after:left-[50%] -left-[5.5rem]  whitespace-pre-wrap w-48':
                    !(deviceWidth > 1280),
                },
              )}
            >
              속도 측정을 반복할 구간의 시작과 종료 지점을 설정할 수 있습니다.
            </TooltipTop>
          </div>
        </div>
      </div>
      <TestStepRepeatStartBox
        filteredList={startFilteredList}
        startRepeatIdx={startRepeatIdx}
        endRepeatIdx={endRepeatIdx}
        deviceInfo={title}
        setError={setError}
      />
      <TestStepRepeatFinishBox
        filteredList={endFilteredList}
        startRepeatIdx={startRepeatIdx}
        endRepeatIdx={endRepeatIdx}
        deviceInfo={title}
        setError={setError}
      />
      {error ? (
        <p className="error-message">
          반복할 구간의 시작과 종료 스텝을 모두 선택해야 테스트를 실행할 수
          있습니다.
        </p>
      ) : (
        <p className="h-5" />
      )}
    </div>
  );
};

export default TestStepListRepeatBox;
