import { Button } from '@autosquare/common';
import { AuthCode } from '@autosquare/common/src/utils/type/resetPassword/type';
import { ErrorMessage } from '@hookform/error-message';
import { useConfirmAuthCode } from '@lib/api/dashboard/forgotAndResetPassword/forgotAndResetPassword';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  isSentSuccessfully: boolean;
  minutes: string;
  seconds: string;
  timerErrorMessage: string;
};

const ConfirmAuthCodeForm = ({
  isSentSuccessfully,
  minutes,
  seconds,
  timerErrorMessage,
}: Props) => {
  const confirmAuthCode = useConfirmAuthCode();

  const methods = useForm<AuthCode>();

  const confirmCodeInput = methods.watch('authCode');

  const onSubmit: SubmitHandler<AuthCode> = (data) => {
    const userId = sessionStorage.getItem('userId');
    confirmAuthCode.mutate({
      ...data,
      userId,
    });
  };

  useEffect(() => {
    confirmAuthCode.reset();
  }, [confirmCodeInput]);

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col pb-5">
          <div className="flex items-center justify-center space-x-3 px-3">
            <div className="relative grow">
              <label htmlFor="confirm-code" className="my-1 block">
                인증번호
              </label>
              <input
                type="text"
                id="confirm-code"
                placeholder="인증번호를 입력해 주세요."
                className="input-base"
                {...methods.register('authCode', {
                  required: '인증번호를 입력해 주세요.',
                  pattern: {
                    value: /^\d{6}$/,
                    message: '잘못된 인증번호입니다. 다시 입력해 주세요.',
                  },
                })}
              />
              {isSentSuccessfully && (
                <span className="absolute left-48 top-9 text-sm font-semibold text-allports">
                  {minutes}:{seconds}
                </span>
              )}
            </div>
            <div className="mt-7">
              <Button
                type="submit"
                buttonSize="xs"
                className="w-24 py-2"
                disabled={!isSentSuccessfully}
              >
                인증번호 확인
              </Button>
            </div>
          </div>
          {timerErrorMessage ? (
            <p className="standard-error-message mx-auto">
              {timerErrorMessage}
            </p>
          ) : (
            confirmAuthCode.isError && (
              <p className="standard-error-message mx-auto">
                {confirmAuthCode.error.message}
              </p>
            )
          )}

          <ErrorMessage
            name="authCode"
            errors={methods.formState.errors}
            render={({ message }) => (
              <p className="standard-error-message mx-auto">{message}</p>
            )}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default ConfirmAuthCodeForm;
