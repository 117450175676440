import { CreateMobileWebSpeedTestStepData } from '@customTypes/ide/speedTest/Mobile/webTestStep/type';

import { Dialog } from '@headlessui/react';
import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import CreateSetAsVariablePanel from './CreateSetAsVariable/CreateSetAsVariablePanel';

interface Props {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  selectedText: string;
  setSelectedText: React.Dispatch<React.SetStateAction<string>>;
  selectionStart: number;
  selectionEnd: number;
}

const CreateSetAsVariable = ({
  isOpened,
  setIsOpened,
  setSelectedText,
  selectionStart,
  selectionEnd,
}: Props) => {
  const { watch, control } = useFormContext<CreateMobileWebSpeedTestStepData>();
  const { remove } = useFieldArray({
    control,
    name: 'optionsDto',
  });

  const [isSaved, setIsSaved] = useState(false);

  const currentIndex = watch('optionsDto')?.length - 1;

  const closeDialog = () => {
    setIsOpened(false);
    remove(currentIndex);
    setSelectedText('');
    setIsSaved(false);
  };

  return (
    <Dialog
      open={isOpened}
      onClose={closeDialog}
      autoFocus
      className="fixed inset-0 z-50 flex w-screen items-center justify-center bg-black/30"
    >
      <CreateSetAsVariablePanel
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        setSelectedText={setSelectedText}
        isSaved={isSaved}
        setIsSaved={setIsSaved}
        closeDialog={closeDialog}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
    </Dialog>
  );
};

export default CreateSetAsVariable;
