import React from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import {
  useWebTestCaseResultQuery,
  useWebTestResultDetailQuery,
} from '@lib/api/dashboard/web/webTestResult';

import Summary from '@components/shared/TestResultDetail/Summary';
import Information from '@components/shared/TestResultDetail/Information';
import ExecutionDetails from '@components/shared/TestResultDetail/ExecutionDetails';
import TestCaseResultListSidebar from '@components/shared/TestResultDetail/TestCaseResultListSidebar';

import {
  ErrorMessage,
  PagingHeadings,
  TableSkeleton,
  useDetectScrollLocation,
} from '@autosquare/common';

const WebTestResultDetail = () => {
  const { projectIdx } = useParams();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const webTestCaseResultQuery = useWebTestCaseResultQuery(idx);
  const webTestResultDetailQuery = useWebTestResultDetailQuery(idx);

  const testResultSummary = webTestCaseResultQuery.data?.summary;
  const resultDetails = webTestCaseResultQuery.data?.resultDetail;

  const { elementRefs, activeSection, handleClick } = useDetectScrollLocation({
    offsetY: -100,
    dependencyArray: resultDetails,
  });

  const breadcrumbs = [
    {
      title: 'Web',
      href: `/projects/${projectIdx}/web/test-result`,
    },
    {
      title: 'Test Result',
      href: `/projects/${projectIdx}/web/test-result`,
    },
    {
      title: 'Detail',
      href: pathname + search,
    },
  ];

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result Detail'}
      />
      {webTestCaseResultQuery.isLoading ||
      webTestCaseResultQuery.isFetching ||
      webTestResultDetailQuery.isLoading ||
      webTestResultDetailQuery.isFetching ? (
        <TableSkeleton />
      ) : webTestCaseResultQuery.isError || webTestResultDetailQuery.isError ? (
        <ErrorMessage>
          {webTestCaseResultQuery.error.message ||
            webTestResultDetailQuery.error.message}{' '}
        </ErrorMessage>
      ) : (
        <>
          <div className="xl:mr-96">
            <Summary
              testResultSummary={testResultSummary}
              result={webTestResultDetailQuery.data?.result}
              reason={webTestResultDetailQuery.data?.failReason}
            />
            <Information testResult={webTestResultDetailQuery.data} />
            <ExecutionDetails
              resultDetails={resultDetails}
              elementRefs={elementRefs}
            />
          </div>
          <TestCaseResultListSidebar
            resultDetails={resultDetails}
            handleClick={handleClick}
            activeSection={activeSection}
          />
        </>
      )}
    </>
  );
};

export default WebTestResultDetail;
