import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';

import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';
import { DeleteWebSpeedTestCaseInfo } from '@customTypes/ide/speedTest/Web/testCase/type';

import { useDeleteWebSpeedTestCaseMutation } from '@lib/api/ide/speedWeb/webSpeedTestCase';

import React from 'react';

interface Props {
  isTestExecutionOpened: boolean;
  setIsTestExecutionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  testCaseIdx: number;
  isOpenedCautionDialog: boolean;
  setIsOpenedCautionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  selectedArray: number[];
  resetSelectedArray: () => void;
}

const ExecutionContainerAndErrorDialog = ({
  isTestExecutionOpened,
  setIsTestExecutionOpened,
  testCaseIdx,
  isOpenedCautionDialog,
  setIsOpenedCautionDialog,
  closeDialog,
  selectedArray,
  resetSelectedArray,
}: Props) => {
  const deleteWebSpeedTestCaseMutation = useDeleteWebSpeedTestCaseMutation();

  const data: DeleteWebSpeedTestCaseInfo = { idxList: selectedArray };

  return (
    <>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testCaseIdx}
          testType={'case'}
          deviceType={'web'}
          testLocation={'speed-test'}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={
          deleteWebSpeedTestCaseMutation.isError ? 'Error' : 'Delete Test Case'
        }
        subTitle={
          deleteWebSpeedTestCaseMutation.isError
            ? deleteWebSpeedTestCaseMutation.error.message
            : '해당 테스트 케이스를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteWebSpeedTestCaseMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteWebSpeedTestCaseMutation.mutate(data, {
                    onSuccess: () => {
                      closeDialog();
                      resetSelectedArray();
                    },
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

export default ExecutionContainerAndErrorDialog;
