import { RootState } from '@app/store';
import { DeviceInfoOs } from '@customTypes/ide/device/device';

import { hasDownloadedData } from '@store/ide/settings/settingsSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PagingHeaderWithTotalCount from '@components/TestExecution/PagingHeaderWithTotalCount';
import DistributedExecutionSettings from './MobileDistributedExecution/DistributedExecutionSettings';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';

type Props = {
  testType: TestTypeCategory;
  divWidth: number;
  setIsReadyToNavigate: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
};

const MobileDistributedExecution = ({
  testType,
  divWidth,
  setIsReadyToNavigate,
  setIsSettingsOpen,
  setIsWirelessDeviceModal,
  setConnectionType,
}: Props) => {
  const { projectIdx } = useParams();

  const [isSelectedDevices, setIsSelectedDevices] = useState(false);
  const [isXcodeApp, setIsXcodeApp] = useState(false);
  const [isWdaFile, setIsWdaFile] = useState(false);
  const [needIosSetting, setNeedIosSetting] = useState(false);

  const selectedExecutionList = useSelector(
    (state: RootState) => state.distributionExecution.selectedExecutionList,
  );
  const dispatch = useDispatch();
  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  const executionTestOnClick = () => {
    if (!selectedExecutionList?.length) {
      setIsSelectedDevices(true);
      return;
    }

    if (
      selectedExecutionList.some(
        (execution) =>
          execution.device.os === DeviceInfoOs.Ios &&
          (!isXcodeApp || !isWdaFile),
      )
    ) {
      setNeedIosSetting(true);
      return;
    }

    setIsSelectedDevices(false);
    setIsReadyToNavigate(true);
  };

  useEffect(() => {
    const getXcodeLocation = async () => {
      const xcodeLocation: string | undefined =
        await window.electron.invoke('checkXcodeLocation');
      setIsXcodeApp(xcodeLocation?.toLowerCase().includes('xcode.app'));
    };
    const checkWdaFile = async () => {
      const existWdaFile: boolean = await window.electron.invoke(
        'existWdaFile',
        projectIdx,
      );
      setIsWdaFile(existWdaFile);
    };
    getXcodeLocation();
    checkWdaFile();

    if (isDownloadedData) {
      getXcodeLocation();
      checkWdaFile();
      dispatch(hasDownloadedData(false));
    }
  }, [isDownloadedData]);

  const breadcrumbs = [
    { title: 'UI Test' },
    { title: 'Mobile' },
    {
      title:
        testType === 'case'
          ? 'Test Case'
          : testType === 'scenario' && 'Test Scenario',
    },
    { title: 'Distributed Execution' },
  ];

  const pageHeadingButtons = [
    {
      title: 'Execution test',
      onClick: executionTestOnClick,
    },
  ];

  return (
    <>
      <PagingHeaderWithTotalCount
        breadcrumbs={breadcrumbs}
        pageTitle={'Distributed Execution'}
        selectedDeviceLength={selectedExecutionList?.length}
        pageHeadingButtons={pageHeadingButtons}
      />
      <DistributedExecutionSettings
        testType={testType}
        divWidth={divWidth}
        isSelectedDevices={isSelectedDevices}
        needIosSetting={needIosSetting}
        setNeedIosSetting={setNeedIosSetting}
        setIsSettingsOpen={setIsSettingsOpen}
        setIsWirelessDeviceModal={setIsWirelessDeviceModal}
        setConnectionType={setConnectionType}
      />
    </>
  );
};

export default MobileDistributedExecution;
