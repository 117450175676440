import {
  TableBodyBasic,
  Execution,
  Checkbox,
  Detail,
} from '@autosquare/common';

import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import { getTestStepIdx } from '@store/ide/mobileTestStepDetail/mobileTestStepDetailSlice';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { MobileTestStepDetailContext } from '@contexts/ide/MobileTestStepDetailProvider';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import TestStepDetail from '@components/MobileTestCaseDetail/TableStepListTableRow/TestStepDetail';

interface Props {
  mobileTestStep: MobileTestStepData;
  mobileTestStepList: MobileTestStepData[];
  index: number;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
  setIsTestStepExecutionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  title: DeviceInfoOs;
}

const TestStepListTableRow = ({
  mobileTestStep,
  index,
  mobileTestStepList,
  selectArray,
  selectedArray,
  setIsTestStepExecutionOpened,
  title,
}: Props) => {
  const dispatch = useDispatch();

  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const { setMobileOS } = useContext(MobileTestStepDetailContext);

  const [passCommand, failCommand] = mobileTestStep.value.split(',');

  const disabledExecution =
    (mobileTestStep.command === MobileCommandOptionServerName.ConfirmText &&
      mobileTestStep.optionsDto.compareBy === 'id') ||
    (mobileTestStep.command === MobileCommandOptionServerName.InputText &&
      mobileTestStep.optionsDto.compareBy === 'id') ||
    mobileTestStep.command === MobileCommandOptionServerName.ImportTestCase ||
    (mobileTestStep.command === MobileCommandOptionServerName.IF &&
      (passCommand === MobileCommandOptionServerName.ImportTestCase ||
        failCommand === MobileCommandOptionServerName.ImportTestCase));

  return (
    <Draggable draggableId={mobileTestStep.idx.toString()} index={index}>
      {(provided) => (
        <tr
          className={`group h-full hover:bg-gray-50 [&>*:nth-child(3)]:whitespace-pre-wrap`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TableBodyBasic>
            <Checkbox
              checked={selectedArray.includes(mobileTestStep.idx)}
              onChange={(e) => selectArray(mobileTestStep.idx, e)}
            />
          </TableBodyBasic>
          <TableBodyBasic>{index + 1}</TableBodyBasic>
          <TableBodyBasic>
            {findMobileCommandNameFromServerName(mobileTestStep.command)}
          </TableBodyBasic>
          <TableBodyBasic>{mobileTestStep.description}</TableBodyBasic>
          <TableBodyBasic>
            <Execution
              onClick={() => {
                dispatch(getTestStepIdx(mobileTestStep.idx));
                setIsTestStepExecutionOpened(true);
              }}
              disabled={disabledExecution}
              tooltipLocation={'right'}
              testType="step"
            />
          </TableBodyBasic>
          <TableBodyBasic>
            <Detail
              onClick={() => {
                dispatch(getTestStepIdx(mobileTestStep.idx));
                setIsDialogOpened(true);
                setMobileOS(title);
              }}
            />
            <TestStepDetail
              isDialogOpened={isDialogOpened}
              setIsDialogOpened={setIsDialogOpened}
              mobileTestStep={mobileTestStep}
              mobileTestStepList={mobileTestStepList}
            />
          </TableBodyBasic>
        </tr>
      )}
    </Draggable>
  );
};

export default TestStepListTableRow;
