import { Button } from '@autosquare/common';

import React, { ReactElement, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDeleteTestStepMutation } from '@lib/api/ide/mobile/mobileTestStep';

import TestStepListValue from '@components/shared/TestStepDetail/TestStepListValue';
import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';

import { ClickId } from '@utils/static/clickOptions';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import BasicLoop from './TestStepDetailBasic/BasicLoop';
import BasicIfCommand from './TestStepDetailBasic/BasicIfCommand';
import DeleteDialog from './TestStepDetailBasic/DeleteDialog';
import BasicTextReplace from './TestStepDetailBasic/BasicTextReplace';
import SaveTextList from './TestStepDetailBasic/SaveTextList';
import { useDeleteSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';

interface Props {
  mobileTestStepData: MobileTestStepData;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  testStepDetailListValue: string | ReactElement;
}

const TestStepDetailBasic = ({
  mobileTestStepData,
  setIsEdited,
  closeDialog,
  testStepDetailListValue,
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const uiTestDeleteStepMutation = useDeleteTestStepMutation(idx, [
    mobileTestStepData?.idx,
  ]);
  const speedTestDeleteStepMutation = useDeleteSpeedMobileTestStep();
  return (
    <>
      <div className="shrink-0 border-b border-gray-200 px-4 py-5 sm:px-6">
        <div className="flex justify-end space-x-3">
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsEdited(true)}
          >
            Edit
          </Button>
          <Button type="button" variant="caution" onClick={openModal}>
            Delete
          </Button>
        </div>
      </div>
      <div className="divide-y divide-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="space-y-8 sm:space-y-0">
            <TestStepDetailBasicDefinition
              term={
                mobileTestStepData?.mobileOs === DeviceInfoOs.Aos
                  ? 'App Package Name'
                  : 'App Bundle Identifier'
              }
              description={mobileTestStepData?.packageName}
            />
            <TestStepDetailBasicDefinition
              term={'App Version'}
              description={mobileTestStepData?.appVersion}
            />
            <TestStepDetailBasicDefinition
              term={'Command'}
              description={findMobileCommandNameFromServerName(
                mobileTestStepData?.command,
              )}
            />
            {mobileTestStepData?.optionsDto.clickBy === ClickId.Image ||
            mobileTestStepData?.command ===
              MobileCommandOptionServerName.CompareImage ? (
              <TestStepDetailBasicDefinition
                term={'Value'}
                description={
                  <TestStepListValue imageValue={mobileTestStepData?.value} />
                }
              />
            ) : mobileTestStepData?.command ===
              MobileCommandOptionServerName.Wait ? (
              <TestStepDetailBasicDefinition
                term={'Value(sec)'}
                description={testStepDetailListValue}
              />
            ) : (
              mobileTestStepData?.command !==
                MobileCommandOptionServerName.Loop &&
              mobileTestStepData?.command !==
                MobileCommandOptionServerName.IF &&
              mobileTestStepData?.command !==
                MobileCommandOptionServerName.UnlockPattern &&
              !(
                mobileTestStepData?.command ===
                  MobileCommandOptionServerName.SaveText &&
                mobileTestStepData.optionsDto?.from === SaveTextFrom.Sms
              ) && (
                <TestStepDetailBasicDefinition
                  term={'Value'}
                  description={testStepDetailListValue}
                />
              )
            )}
            <TestStepDetailBasicDefinition
              term={'Description'}
              description={mobileTestStepData?.description}
            />
            {mobileTestStepData?.command ===
            MobileCommandOptionServerName.ScrollSwipe ? (
              <TestStepDetailBasicDefinition
                term={'Repeat'}
                description={mobileTestStepData?.optionsDto.repeatLimit}
              />
            ) : mobileTestStepData?.command ===
              MobileCommandOptionServerName.UnlockPattern ? (
              <TestStepDetailBasicDefinition
                term={'Points'}
                description={JSON.parse(mobileTestStepData?.value)
                  .map(
                    (value: { x: number; y: number }) =>
                      `[${value.x}, ${value.y}]`,
                  )
                  .join(', ')}
              />
            ) : mobileTestStepData?.command ===
                MobileCommandOptionServerName.Click &&
              mobileTestStepData.optionsDto.clickBy === ClickId.Default ? (
              <BasicTextReplace mobileTestStepData={mobileTestStepData} />
            ) : (
              mobileTestStepData?.command ===
                MobileCommandOptionServerName.SaveText && (
                <SaveTextList mobileTestStepData={mobileTestStepData} />
              )
            )}
            {mobileTestStepData?.command !==
              MobileCommandOptionServerName.Loop &&
              mobileTestStepData?.command !==
                MobileCommandOptionServerName.IF &&
              mobileTestStepData?.command !==
                MobileCommandOptionServerName.Wait && (
                <TestStepDetailBasicDefinition
                  term={'Required'}
                  description={mobileTestStepData?.required ? 'Y' : 'N'}
                />
              )}
            {mobileTestStepData?.command ===
            MobileCommandOptionServerName.Loop ? (
              <BasicLoop mobileTestStepData={mobileTestStepData} />
            ) : (
              mobileTestStepData?.command ===
                MobileCommandOptionServerName.IF && (
                <BasicIfCommand mobileTestStepData={mobileTestStepData} />
              )
            )}
            {(mobileTestStepData?.command !==
              MobileCommandOptionServerName.Loop ||
              mobileTestStepData?.value !==
                MobileCommandOptionServerName.Wait) &&
              mobileTestStepData?.command !==
                MobileCommandOptionServerName.Wait && (
                <TestStepDetailBasicDefinition
                  term={'WaitTime'}
                  description={mobileTestStepData?.optionsDto.waitingTime}
                />
              )}
          </dl>
        </div>
      </div>
      <DeleteDialog
        isModalOpened={isModalOpened}
        setIsModalOpened={setIsModalOpened}
        closeModal={closeModal}
        closeDialog={closeDialog}
        openModal={openModal}
        uiTestDeleteStepMutation={uiTestDeleteStepMutation}
        speedTestDeleteStepMutation={speedTestDeleteStepMutation}
        mobileStepIdx={mobileTestStepData?.idx}
      />
    </>
  );
};

export default TestStepDetailBasic;
