import { useTitle } from '@autosquare/common';

import React from 'react';
import { Outlet } from 'react-router-dom';

import { useGetRefreshToken } from '@hooks/electron/useGetRefreshToken';

const IdeRoot = () => {
  useTitle('Autosquare');

  const { cookie } = useGetRefreshToken();

  return (
    <>
      <Outlet />
    </>
  );
};

export default IdeRoot;
