import {
  Button,
  Checkbox,
  Detail,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  Execution,
  TableBodyBasic,
  TableBodyContainer,
  TableHeader,
  TableHeaderContainer,
  TableNoList,
  TableSkeleton,
  TableWrapper,
  Time,
  useCheckbox,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  useDeleteWebTestCaseMutation,
  useWebTestCaseListQuery,
} from '@lib/api/ide/web/webTestCase';

import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';
import { timePattern } from '@utils/static/timePattern';

const WebTestCaseList = () => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testCaseIdx, setTestCaseIdx] = useState<number>(0);
  const webTestCaseListQuery = useWebTestCaseListQuery();

  const webTestCases = webTestCaseListQuery.data;
  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(webTestCases, 'idx');

  const deleteWebTestCaseMutation = useDeleteWebTestCaseMutation(selectedArray);

  const tableTitles = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={selectAllArray}
          key="selected-checkbox"
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="w-5" /> : 'No',
    },
    {
      idx: 3,
      title:
        selectedArray.length > 0 ? (
          <div className="w-[4.625rem]" />
        ) : (
          'Test Group'
        ),
    },
    { idx: 4, title: 'Title' },
    { idx: 5, title: 'Step Count' },
    { idx: 6, title: 'Priority' },
    { idx: 7, title: 'Creator' },
    { idx: 8, title: 'Updated at' },
    { idx: 9, title: '' },
    { idx: 10, title: '' },
  ];

  return (
    <>
      {webTestCaseListQuery.isLoading ? (
        <TableSkeleton />
      ) : webTestCaseListQuery.isError ? (
        <ErrorMessage>{webTestCaseListQuery.error.message}</ErrorMessage>
      ) : (
        <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
          <TableHeaderContainer>
            {tableTitles.map((tableTitle) => (
              <TableHeader key={tableTitle.idx}>{tableTitle.title}</TableHeader>
            ))}
          </TableHeaderContainer>
          <TableBodyContainer>
            {webTestCases?.length === 0 ? (
              <TableNoList colSpan={tableTitles.length + 1}>
                해당 프로젝트에 생성된 테스트 케이스가 존재하지 않습니다.
              </TableNoList>
            ) : (
              webTestCases?.map((webTestCase, index) => (
                <tr key={webTestCase.idx}>
                  <TableBodyBasic>
                    <Checkbox
                      checked={selectedArray.includes(webTestCase.idx)}
                      onChange={(e) => selectArray(webTestCase.idx, e)}
                    />
                  </TableBodyBasic>
                  <TableBodyBasic>{index + 1}</TableBodyBasic>
                  <TableBodyBasic>
                    {webTestCase.caseGroupInfo?.name ?? '-'}
                  </TableBodyBasic>
                  <td className="table-data-basic max-w-xs">
                    <div className="font-medium text-gray-900">
                      {webTestCase.title}
                    </div>
                    <div className="mt-1 w-full break-words text-gray-500">
                      {webTestCase.description}
                    </div>
                  </td>
                  <TableBodyBasic>{webTestCase.stepCount}</TableBodyBasic>
                  <TableBodyBasic>{webTestCase.priority}</TableBodyBasic>
                  <TableBodyBasic>{webTestCase.creator}</TableBodyBasic>
                  <TableBodyBasic>
                    <div>{webTestCase?.updatedAt?.split(' ')[0] ?? '-'}</div>
                    <div
                      className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                    >
                      {webTestCase.updatedAt && <Time />}
                      {RegExp(timePattern).exec(webTestCase.updatedAt)[0]}
                    </div>
                  </TableBodyBasic>
                  <TableBodyBasic>
                    <Execution
                      testType="case"
                      disabled={webTestCase.stepCount === 0}
                      tooltipLocation={'right'}
                      onClick={() => {
                        if (webTestCase.stepCount > 0) {
                          setTestCaseIdx(webTestCase.idx);
                          setIsTestExecutionOpened(true);
                        }
                      }}
                    />
                  </TableBodyBasic>
                  <TableBodyBasic>
                    <Detail
                      onClick={() =>
                        navigate({
                          pathname: `/ide/projects/${projectIdx}/ui-test/web/test-case/detail`,
                          search: `idx=${webTestCase.idx}`,
                        })
                      }
                    />
                  </TableBodyBasic>
                </tr>
              ))
            )}
          </TableBodyContainer>
        </TableWrapper>
      )}
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testCaseIdx}
          testType={'case'}
          deviceType={'web'}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={deleteWebTestCaseMutation.isError ? 'Error' : 'Delete Test Case'}
        subTitle={
          deleteWebTestCaseMutation.isError
            ? deleteWebTestCaseMutation.error.message
            : '해당 테스트 케이스를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteWebTestCaseMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteWebTestCaseMutation.mutate(undefined, {
                    onSuccess: () => {
                      closeDialog();
                      resetSelectedArray();
                    },
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

export default WebTestCaseList;
