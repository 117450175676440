import {
  IdeOverviewResults,
  MobileSpeedTestResultsInOverviewType,
  WebSpeedTestResultsInOverviewType,
} from '@customTypes/ide/overview/type';

import { axiosInstance } from '../index';
import { IDEAPI } from '@api/ideEndpoints';
import { useQuery } from '@tanstack/react-query';
import { OverviewFunctionalResultType } from '@autosquare/common';
import { overviewKeys } from '@queries/dashboard/overviewKeys';

interface MobileOverview {
  functionalResults: OverviewFunctionalResultType[];
  speedResults: MobileSpeedTestResultsInOverviewType;
}

interface WebOverview {
  functionalResults: OverviewFunctionalResultType[];
  speedResults: WebSpeedTestResultsInOverviewType;
}
// GET
const getOverview = async (projectIdx: string): Promise<IdeOverviewResults> => {
  const response = await axiosInstance.get(`${IDEAPI.OVERVIEW}${projectIdx}`);
  return response.data;
};
export const useOverviewQuery = (projectIdx: string) =>
  useQuery<IdeOverviewResults, Error, IdeOverviewResults>({
    queryKey: ['overview', projectIdx],
    queryFn: () => getOverview(projectIdx),
  });

const getMobileOverview = async (
  projectIdx: string,
): Promise<MobileOverview> => {
  const response = await axiosInstance.get(
    `${IDEAPI.MOBILE_OVERVIEW}${projectIdx}`,
  );
  return response.data;
};

export const useMobileOverviewQuery = (projectIdx: string) =>
  useQuery<MobileOverview, Error, MobileOverview>({
    queryKey: overviewKeys.mobile(projectIdx),
    queryFn: () => getMobileOverview(projectIdx),
  });

const getWebOverview = async (projecidx: string): Promise<WebOverview> => {
  const response = await axiosInstance.get(
    `${IDEAPI.WEB_OVERVIEW}${projecidx}`,
  );
  return response.data;
};

export const useWebOverviewQuery = (projecidx: string) =>
  useQuery<WebOverview, Error, WebOverview>({
    queryKey: overviewKeys.web(projecidx),
    queryFn: () => getWebOverview(projecidx),
  });
