import NoTestDropList from '@components/DragAndDrop/NoTestDropList';
import DraggableCardSkeleton from '@components/shared/Skeleton/DraggableCardSkeleton';
import ScenarioTestCaseListCard from '@components/SpeedTestScenario/ScenarioTestCaseListCard';
import SpeedTestScenarioTestCaseListContainer from '@components/SpeedTestScenario/SpeedTestScenarioTestCaseListContainer';
import { UpdateMobileSpeedTestScenarioData } from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { SpeedTestCaseDataInScenario } from '@customTypes/speedTestCase/type';
import { useSpeedTestScenarioListDrop } from '@hooks/speedTestScenario/useSpeedTestScenarioListDrop';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  isLoading: boolean;
  copiedTestCaseList: SpeedTestCaseDataInScenario[];
  setCopiedTestCaseList: React.Dispatch<
    React.SetStateAction<SpeedTestCaseDataInScenario[]>
  >;
  changeCardList: (list: SpeedTestCaseDataInScenario[]) => void;
};

const ScenarioTestCaseList = ({
  isLoading,
  copiedTestCaseList,
  setCopiedTestCaseList,
  changeCardList,
}: Props) => {
  const { setValue } = useFormContext<UpdateMobileSpeedTestScenarioData>();

  const { drop, onRemove, moveCard } = useSpeedTestScenarioListDrop(
    setValue,
    copiedTestCaseList,
    setCopiedTestCaseList,
    changeCardList,
  );

  return (
    <SpeedTestScenarioTestCaseListContainer drop={drop}>
      {isLoading ? (
        <DraggableCardSkeleton />
      ) : copiedTestCaseList?.length === 0 ? (
        <NoTestDropList />
      ) : (
        copiedTestCaseList?.map((copiedTestCase, index) => (
          <ScenarioTestCaseListCard
            key={copiedTestCase.uniqueId}
            index={index}
            copiedTestCase={copiedTestCase}
            onRemove={onRemove}
            moveCard={moveCard}
          />
        ))
      )}
    </SpeedTestScenarioTestCaseListContainer>
  );
};

export default ScenarioTestCaseList;
