import React, { ReactNode } from 'react';

const WebTestStepTableBodyDataCell = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <td className="whitespace-pre-wrap px-3 pb-2 text-sm text-gray-900">
      {children}
    </td>
  );
};

export default WebTestStepTableBodyDataCell;
