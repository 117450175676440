import React from "react";

const DeviceSettingsSkeleton = () => {
  return (
    <div className="my-8 px-6 animate-pulse">
      <div className="grid grid-flow-col grid-cols-4 px-6 py-5">
        <div className="w-1/2 h-6 col-span-1 bg-gray-200 rounded-md dark:bg-gray-700" />
        <div className="w-1/2 h-6 col-span-3 bg-gray-200 rounded-md dark:bg-gray-700" />
      </div>
      <div className="grid grid-flow-col grid-cols-4 px-6 py-5">
        <div className="w-1/2 h-6 col-span-1 bg-gray-200 rounded-md dark:bg-gray-700" />
        <div className="w-1/2 h-6 col-span-3 bg-gray-200 rounded-md dark:bg-gray-700" />
      </div>
      <div className="grid grid-flow-col grid-cols-4 px-6 py-5">
        <div className="w-1/2 h-6 col-span-1 bg-gray-200 rounded-md dark:bg-gray-700" />
        <div className="w-1/2 h-6 col-span-3 bg-gray-200 rounded-md dark:bg-gray-700" />
      </div>
    </div>
  );
};

export default DeviceSettingsSkeleton;
