import { useCheckbox } from '@autosquare/common';
import OptionsDtoTableHeader from '@components/shared/MobileWebTestStep/CreateAndUpdate/OptionsDtoTableHeader';

import {
  CreateWebSpeedTestStepData,
  UpdateWebSpeedTestStepData,
  WebSpeedTestStepDataOptionsDto,
} from '@customTypes/ide/speedTest/Web/testStep/type';
import {
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';

type Props = {
  optionsDtoArray: WebSpeedTestStepDataOptionsDto[];
  getValues: UseFormGetValues<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
  setValue: UseFormSetValue<
    CreateWebSpeedTestStepData | UpdateWebSpeedTestStepData
  >;
  remove: UseFieldArrayRemove;
};

export const useSpeedWebVariableList = ({
  optionsDtoArray,
  getValues,
  setValue,
  remove,
}: Props) => {
  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(optionsDtoArray, 'idx');

  const removeOptionsDto = () => {
    const currentUrl = getValues('url');

    const selectedKeyNames = optionsDtoArray?.reduce((acc, optionsDto) => {
      if (selectedArray?.includes(optionsDto.idx)) {
        acc.push(optionsDto.keyName);
      }
      return acc;
    }, []);

    const updatedUrl = selectedKeyNames?.reduce((url, keyName) => {
      const variable = `{{${keyName}}}`;
      return url.replace(new RegExp(variable, 'g'), '');
    }, currentUrl);

    setValue('url', updatedUrl);

    const selectedIndexArray = optionsDtoArray?.reduce((acc, item, index) => {
      if (selectedArray?.includes(item.idx)) {
        acc.push(index);
      }
      return acc;
    }, []);

    remove(selectedIndexArray);

    resetSelectedArray();

    closeDialog();
  };

  const tableHeaders = OptionsDtoTableHeader({
    selectedArray,
    selectAllArray,
    openDialog,
  });

  return {
    removeOptionsDto,
    tableHeaders,
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
  };
};
