import VariableList from '@components/shared/MobileWebTestStep/CreateAndUpdate/VariableList';

import { useVariableList } from '@hooks/mobileWebTestStep/useVariableList';

import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CreateWebSpeedVariableTableBody from './CreateWebSpeedVariableList/CreateWebSpeedVariableTableBody';
import { CreateWebSpeedTestStepData } from '@customTypes/ide/speedTest/Web/testStep/type';

const CreateWebSpeedVariableList = () => {
  const { watch, control, setValue, getValues } =
    useFormContext<CreateWebSpeedTestStepData>();

  const { remove } = useFieldArray({ control, name: 'optionsDto' });

  const optionsDtoArray = watch('optionsDto');

  const {
    tableHeaders,
    removeOptionsDto,
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
  } = useVariableList({ optionsDtoArray, getValues, setValue, remove });

  return (
    <VariableList
      removeOptionsDto={removeOptionsDto}
      isOpenedCautionDialog={isOpenedCautionDialog}
      setIsOpenedCautionDialog={setIsOpenedCautionDialog}
      openDialog={openDialog}
      closeDialog={closeDialog}
      selectedArray={selectedArray}
      selectAllArray={selectAllArray}
    >
      {optionsDtoArray?.map((optionsDto, idx) => (
        <CreateWebSpeedVariableTableBody
          key={optionsDto.idx}
          idx={idx}
          optionsDtoIdx={optionsDto.idx}
          tableColsLength={tableHeaders.length}
          selectArray={selectArray}
          selectedArray={selectedArray}
        />
      ))}
    </VariableList>
  );
};

export default CreateWebSpeedVariableList;
