import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';
import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';
import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import { ClickId } from '@utils/static/clickOptions';
import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import IfCondition from './EditIfCommand/IfCondition';
import IfFailAction from './EditIfCommand/IfFailAction';
import IfPassAction from './EditIfCommand/IfPassAction';

type Props = {
  webTestStepData: WebTestStepData;
};

const EditIfCommand = ({ webTestStepData }: Props) => {
  const { projectIdx } = useParams();

  const { setValue } = useFormContext<UpdateWebTestStepData>();

  const value = webTestStepData.value;

  const splitValue = (
    value: string,
    index: number,
  ): WebCommandOptionServerName =>
    value?.split(',')[index] as WebCommandOptionServerName;

  const [selectedPassCommand, setSelectedPassCommand] = useState(
    splitValue(value, 0) as WebCommandOptionServerName,
  );
  const [selectedFailCommand, setSelectedFailCommand] = useState(
    splitValue(value, 1) as WebCommandOptionServerName,
  );

  useEffect(() => {
    setSelectedPassCommand(splitValue(value, 0) as WebCommandOptionServerName);
    setSelectedFailCommand(splitValue(value, 1) as WebCommandOptionServerName);
  }, []);

  useEffect(() => {
    if (splitValue(value, 1) === undefined) {
      setValue('value', selectedPassCommand);
    } else {
      setValue('value', `${selectedPassCommand},${selectedFailCommand}`);
    }

    if (
      selectedPassCommand === WebCommandOptionServerName.Click ||
      selectedFailCommand === WebCommandOptionServerName.Click
    ) {
      setValue('optionsDto.clickBy', ClickId.Default);
    } else {
      setValue('optionsDto.clickBy', undefined);
    }
  }, [selectedPassCommand, selectedFailCommand]);

  const webTestCaseListQuery = useWebTestCaseListQuery();
  const webTestCaseList = webTestCaseListQuery.data;
  const filteredWebTestCaseList = webTestCaseList?.filter(
    (testCase) => testCase.idx !== Number(projectIdx),
  );

  return (
    <>
      <IfCondition />
      <DetailDivisionLine
        title={'Action'}
        description={'조건식의 결과에 따라 실행될 동작을 지정합니다.'}
      />
      <IfPassAction
        selectedPassCommand={selectedPassCommand}
        setSelectedPassCommand={setSelectedPassCommand}
        splitValue={splitValue}
        webTestStepData={webTestStepData}
        filteredWebTestCaseList={filteredWebTestCaseList}
      />
      {splitValue(value, 1) !== undefined && (
        <IfFailAction
          splitValue={splitValue}
          selectedFailCommand={selectedFailCommand}
          setSelectedFailCommand={setSelectedFailCommand}
          webTestStepData={webTestStepData}
          filteredWebTestCaseList={filteredWebTestCaseList}
        />
      )}
    </>
  );
};

export default EditIfCommand;
