import { DistributionTestExecutionTestData } from '@customTypes/ide/device/device';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DistributedExecutionState {
  selectedExecutionList: DistributionTestExecutionTestData[];
}
const initialState: DistributedExecutionState = {
  selectedExecutionList: [],
};

const distributionExecutionSlice = createSlice({
  name: 'distributedExecution',
  initialState,
  reducers: {
    setSelectedExecutionList: (
      state,
      action: PayloadAction<DistributionTestExecutionTestData[]>,
    ) => {
      state.selectedExecutionList = action.payload;
    },
    addExecutionItem: (
      state,
      action: PayloadAction<DistributionTestExecutionTestData>,
    ) => {
      state.selectedExecutionList.push(action.payload);
    },
    removeExecutionItem: (state, action: PayloadAction<string>) => {
      state.selectedExecutionList = state.selectedExecutionList.filter(
        (item) => item.device.serialNumber !== action.payload,
      );
    },
    resetExecutionItem: (state) => {
      state.selectedExecutionList = [];
    },
  },
});

export const {
  setSelectedExecutionList,
  addExecutionItem,
  removeExecutionItem,
  resetExecutionItem,
} = distributionExecutionSlice.actions;

export default distributionExecutionSlice.reducer;
