import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';

interface Props {
  lists: MobileTestStepData[];
  isLoading: boolean;
}

const SpeedTestStepListHeader = ({ lists, isLoading }: Props) => {
  return (
    <div className="flex items-center justify-between">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        Test Step List
      </h3>
      <div className="ml-4 mt-0">
        <div className="flex items-center justify-center gap-1 text-sm font-semibold">
          Total:{' '}
          {isLoading ? (
            <div className="h-4 w-6 animate-pulse rounded-md bg-gray-200 dark:bg-gray-700" />
          ) : (
            lists?.length
          )}
        </div>
      </div>
    </div>
  );
};

export default SpeedTestStepListHeader;
