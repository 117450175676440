import {
  Breadcrumbs,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import { useCreateWebSpeedTestCaseMutation } from '@lib/api/ide/speedWeb/webSpeedTestCase';

import { RootState } from '@app/store';

import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

import GroupProvider from '@contexts/ide/GroupProvider';

import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';

import { CreateWebSpeedTestCaseInfo } from '@customTypes/ide/speedTest/Web/testCase/type';
import { PriorityNameEnum } from '@customTypes/testCase/type';

import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import WebSpeedTestCaseInfo from './components/WebSpeedTestCaseInfo';

const CreateWebSpeedTestCase = () => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();

  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );
  const dispatch = useDispatch();

  const methods = useForm<CreateWebSpeedTestCaseInfo>({
    defaultValues: {
      projectIdx: projectIdx,
      priority: PriorityNameEnum.Medium,
    },
  });

  const createWebSpeedTestCaseMutation = useCreateWebSpeedTestCaseMutation();

  const onSubmit: SubmitHandler<CreateWebSpeedTestCaseInfo> = (data) => {
    createWebSpeedTestCaseMutation.mutate(data);
  };

  const testCaseLink = pathname.split('/').slice(0, -1).join('/');

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -3).join('/')}/overview`,
    },
    {
      title: 'Web',
      href: testCaseLink,
    },
    {
      title: 'Test Case',
      href: testCaseLink,
    },
    { title: 'Create', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Save',
      variant: 'primary',
      type: 'submit',
      disabled: createWebSpeedTestCaseMutation.isLoading,
    },
  ];

  return (
    <GroupProvider>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PagingHeadingsNavigate
            breadcrumbs={breadcrumbs}
            pageTitle={'Test Case Create'}
            pageHeadingButtons={pageHeadingButtons}
          />
          <WebSpeedTestCaseInfo
            isError={createWebSpeedTestCaseMutation.isError}
            error={createWebSpeedTestCaseMutation.error}
          />
        </form>
      </FormProvider>
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
    </GroupProvider>
  );
};

export default CreateWebSpeedTestCase;
