import React, { useState } from 'react';

import CreateSchedulerStartDate from './CreateSchedulerSchedule/CreateSchedulerStartDate';
import CreateSchedulerRunningCycle from './CreateSchedulerSchedule/CreateSchedulerRunningCycle ';
import CreateSchedulerDays from './CreateSchedulerSchedule/CreateSchedulerDays';
import CreateSchedulerPreview from './CreateSchedulerSchedule/CreateSchedulerPreview';

import { RunningCycle } from '@customTypes/ide/scheduler/scheduler';
import { hourCycle } from '@utils/static/runningCycle';
import { days } from '@utils/static/selectedDays';

const CreateSchedulerSchedule = () => {
  const [selectedCycle, setSelectedCycle] = useState<RunningCycle>(
    hourCycle[0],
  );
  const [selectedDays, setSelectedDays] = useState<string[]>(days);
  return (
    <div className="sm:grid sm:grid-cols-12 sm:grid-rows-2 sm:items-start sm:gap-x-4 sm:py-4">
      <CreateSchedulerStartDate />
      <CreateSchedulerRunningCycle
        selectedCycle={selectedCycle}
        setSelectedCycle={setSelectedCycle}
      />
      <CreateSchedulerPreview
        selectedCycle={selectedCycle}
        selectedDays={selectedDays}
      />
      <CreateSchedulerDays
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
      />
    </div>
  );
};

export default CreateSchedulerSchedule;
