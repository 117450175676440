import {
  CreateWebSpeedTestStepData,
  UpdateWebSpeedTestStepData,
  WebSpeedTestStepData,
} from '@customTypes/ide/speedTest/Web/testStep/type';
import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { webSpeedTestStepKeys } from '@queries/ide/speedWeb/webSpeedTestStepKeys';
import { webSpeedTestCaseKeys } from '@queries/ide/speedWeb/webSpeedTestCaseKeys';

//GET
const getSpeedWebStep = async (
  caseIdx: string,
): Promise<WebSpeedTestStepData> => {
  const response = await axiosInstance.get<WebSpeedTestStepData>(
    `${IDEAPI.WEB_SPEED_TEST_STEP}?case=${caseIdx}`,
  );
  return response.data;
};

export const useSpeedWebStepQuery = () => {
  const [searchParams] = useSearchParams();
  const caseIdx = searchParams.get('idx');

  return useQuery<WebSpeedTestStepData, Error>({
    queryKey: webSpeedTestStepKeys.detail(caseIdx),
    queryFn: () => getSpeedWebStep(caseIdx),
  });
};

//POST
const createSpeedWebStep = async (
  data: CreateWebSpeedTestStepData,
): Promise<WebSpeedTestStepData> => {
  const response = await axiosInstance.post<WebSpeedTestStepData>(
    `${IDEAPI.WEB_SPEED_TEST_STEP}`,
    data,
  );
  return response.data;
};

export const useCreateSpeedWebStepMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<WebSpeedTestStepData, Error, CreateWebSpeedTestStepData>({
    mutationFn: (data) => createSpeedWebStep(data),
    onSuccess: () => {
      queryClient.invalidateQueries(webSpeedTestStepKeys.all);
      queryClient.invalidateQueries(webSpeedTestCaseKeys.lists());
    },
  });
};

//PUT
const updateSpeedWebStep = async (
  idx: number,
  data: UpdateWebSpeedTestStepData,
): Promise<WebSpeedTestStepData> => {
  const response = await axiosInstance.put<WebSpeedTestStepData>(
    `${IDEAPI.WEB_SPEED_TEST_STEP}/${idx}`,
    data,
  );
  return response.data;
};

export const useUpdateSpeedWebStepMutation = (idx: number) => {
  const queryClient = useQueryClient();
  return useMutation<WebSpeedTestStepData, Error, UpdateWebSpeedTestStepData>({
    mutationFn: (data) => updateSpeedWebStep(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webSpeedTestStepKeys.all);
      queryClient.invalidateQueries(webSpeedTestCaseKeys.lists());
    },
  });
};

//DELETE
const deleteSpeedWebStep = async (idx: number): Promise<string> => {
  const response = await axiosInstance.delete<string>(
    `${IDEAPI.WEB_SPEED_TEST_STEP}/${idx}`,
  );
  return response.data;
};

export const useDeleteSpeedWebSepMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<string, Error, number>({
    mutationFn: (idx) => deleteSpeedWebStep(idx),
    onSuccess: () => {
      queryClient.invalidateQueries(webSpeedTestStepKeys.all);
      queryClient.invalidateQueries(webSpeedTestCaseKeys.lists());
    },
  });
};
