import { ClipBoardButton } from '@autosquare/common';

import React, { ReactElement } from 'react';

import { DeviceKeyServerName } from '@utils/static/deviceKeyList';

type Props = {
  term: string;
  description: string | number | ReactElement | DeviceKeyServerName | null;
  isRequiredCopy?: boolean;
  setIsCopied?: React.Dispatch<React.SetStateAction<boolean>>;
};

const SpeedPropertyDetail = ({
  term,
  description,
  isRequiredCopy = false,
  setIsCopied,
}: Props) => {
  const handleCopy = (valueText: string) => {
    window.navigator.clipboard.writeText(valueText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <div className="grid grid-cols-3 px-3 py-2 odd:bg-gray-50 even:bg-white">
      <dt className="text-xs font-medium leading-6 text-gray-900">{term}</dt>
      <dd className="col-span-2 mt-0 break-all text-xs leading-6 text-gray-700">
        {description}{' '}
        {isRequiredCopy && (
          <ClipBoardButton onClick={() => handleCopy(description as string)} />
        )}
      </dd>
    </div>
  );
};

export default SpeedPropertyDetail;
