import { PlatFormTypeServerName } from '@autosquare/common';
import { DeviceInfoOs } from '@customTypes/ide/device/device';

export type SpeedMobileTestTypeCategory = 'Scenario' | 'Case' | 'Monitoring';

export type SpeedTestExecutionResult = 'PASS' | 'FAIL' | 'NA' | 'SKIP';

export enum SpeedExecutionResult {
  Pass = 'PASS',
  Fail = 'FAIL',
  Na = 'NA',
  Skip = 'SKIP',
  Running = 'RUNNING',
}

export type ServiceSpeedData = {
  name: string;
  value: number;
};

export type ContentsData = {
  resultIdx: number;
  testType: string;
  group: string;
  groupIdx: number;
  dateTime: string;
  loadSpeedData: ServiceSpeedData[];
  resultEnum: SpeedExecutionResult;
};

export type MobileSpeedTestResultData = {
  first: boolean;
  last: boolean;
  currentPage: number;
  currentSize: number;
  totalPages: number;
  totalElements: number;
  content: ContentsData[];
};

export type ExecutionDetailStep = {
  stepIdx: number;
  command: string;
  description: string;
  result: SpeedTestExecutionResult;
  failReason: string | null;
};

export type ExecutionDetail = {
  title: string;
  result: string;
  stepList: ExecutionDetailStep[];
};

export type RepeatResultsData = {
  repeat: number;
  dateTime: string;
  loadTime: number;
  domTime: number;
  videoPath: string;
};

export type MobileSpeedTestResultTableDetailData = {
  idx: number;
  serviceName: string;
  version: string;
  averageTime: number;
  executionDetail?: ExecutionDetail;
  repeatResults: RepeatResultsData[];
  logPath: string;
  platform: PlatFormTypeServerName;
  url?: string;
};

export type LoadSpeedData = {
  value: number;
  detailIdx: number;
};
export type LoadSpeedDataContents = {
  name: string;
  data: LoadSpeedData[];
};
export type MobileSpeedTestResultDetailData = {
  idx: number;
  group: string;
  tester: string;
  dateDuration: string;
  os: DeviceInfoOs;
  device: string;
  title: string;
  testType: string;
  dateList: string[];
  loadSpeedData: LoadSpeedDataContents[];
  failReason: string;
};
