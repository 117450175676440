import React from 'react';
import { useFormContext } from 'react-hook-form';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import DefinitionInputByReactHookForm from '@components/shared/TestStepDetail/DefinitionInputByReactHookForm';
import DefinitionTextAreaByReactHookForm from '@components/shared/TestStepDetail/DefinitionTextAreaByReactHookForm';
import TestStepDetailScrollAndSwipe from '@components/shared/TestStepDetail/TestStepDetailScrollAndSwipe';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import TestStepClickOption from './TestStepValue/TestStepClickOption';
import TestStepConfirmOrInputText from './TestStepValue/TestStepConfirmOrInputText';
import TestStepSelectBox from './TestStepValue/TestStepSelectBox';

interface Props {
  mobileTestStep: MobileTestStepData;
}

const TestStepValue = ({ mobileTestStep }: Props) => {
  const { register } = useFormContext<UpdateTestStepData>();

  return (
    <>
      {(mobileTestStep.optionsDto.clickBy !== null &&
        mobileTestStep.optionsDto.clickBy !== undefined &&
        mobileTestStep.command === MobileCommandOptionServerName.Click) ||
      mobileTestStep.command === MobileCommandOptionServerName.CompareImage ? (
        <TestStepClickOption mobileTestStep={mobileTestStep} />
      ) : mobileTestStep.command === MobileCommandOptionServerName.DeviceKey ||
        mobileTestStep.command ===
          MobileCommandOptionServerName.ImportTestCase ? (
        <TestStepSelectBox command={mobileTestStep.command} />
      ) : mobileTestStep.command ===
        MobileCommandOptionServerName.ScrollSwipe ? (
        <TestStepDetailScrollAndSwipe
          mobileTestStep={mobileTestStep}
          keyName={'value'}
        />
      ) : mobileTestStep.command ===
          MobileCommandOptionServerName.ConfirmText ||
        mobileTestStep.command === MobileCommandOptionServerName.InputText ? (
        <TestStepConfirmOrInputText mobileTestStep={mobileTestStep} />
      ) : mobileTestStep.command === MobileCommandOptionServerName.Wait ? (
        <>
          <DefinitionInputByReactHookForm
            term={'Value(sec)*'}
            name={'value'}
            type={'number'}
            min={3}
            max={180}
            register={register('value', {
              required: '대기 시간을 최소 3초 이상 입력해 주세요.',
              min: {
                value: 3,
                message: '대기 시간을 최소 3초 이상 입력해 주세요.',
              },
              max: {
                value: 180,
                message: '대기 시간은 최대 180초까지 입력 가능합니다.',
              },
            })}
          />
        </>
      ) : (
        <DefinitionTextAreaByReactHookForm term={'Value*'} name={'value'} />
      )}
    </>
  );
};

export default TestStepValue;
