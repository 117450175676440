import TestScenarioInformation from '@components/SpeedTestScenario/TestScenarioInformation';
import { CreateMobileSpeedTestScenarioData } from '@customTypes/ide/speedTest/Mobile/testScenario/type';

import React from 'react';
import { useFormContext } from 'react-hook-form';

const MobileSpeedTestScenarioInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateMobileSpeedTestScenarioData>();

  return <TestScenarioInformation register={register} errors={errors} />;
};

export default MobileSpeedTestScenarioInformation;
