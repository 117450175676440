import {
  CreatePlusButton,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import { useGroupContext } from '@contexts/ide/GroupProvider';

import { useGetTestCaseServiceGroupListQuery } from '@lib/api/ide/serviceGroup/serviceGroup';

import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

import { findTestGroupNameByIdx } from '@utils/static/findTestGroupNameByIdx';

import { UpdateTestCaseServiceGroup } from '@customTypes/serviceGroup/type';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';

const ServiceGroupBox = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateTestCaseServiceGroup>();
  const dispatch = useDispatch();
  const { setClickGroupType } = useGroupContext();

  const [testCaseGroupLength, setTestCaseGroupLength] = useState<number>(0);
  const [isLengthChanged, setIsLengthChanged] = useState(false);
  const [isTestCaseGroupLengthChanged, setIsTestCaseGroupLengthChanged] =
    useState(false);

  const serviceGroupListQuery = useGetTestCaseServiceGroupListQuery();
  const serviceGroupList = serviceGroupListQuery.data;
  const serviceGroupListLength = serviceGroupList?.length ?? 0;

  useEffect(() => {
    if (serviceGroupListQuery.isRefetching) {
      setIsTestCaseGroupLengthChanged(true);
    }
  }, [serviceGroupListQuery.isRefetching]);

  useEffect(() => {
    if (serviceGroupListQuery.isSuccess && testCaseGroupLength === 0) {
      setTestCaseGroupLength(serviceGroupListLength);
      setIsTestCaseGroupLengthChanged(true);
    }
  }, [serviceGroupListLength, serviceGroupListQuery.isSuccess]);

  useEffect(() => {
    if (
      serviceGroupListQuery.isSuccess &&
      isTestCaseGroupLengthChanged &&
      testCaseGroupLength !== serviceGroupListLength
    ) {
      setIsLengthChanged(true);
      setTestCaseGroupLength(serviceGroupListLength);
      setIsTestCaseGroupLengthChanged(false);
    }
  }, [
    serviceGroupListLength,
    serviceGroupListQuery.isSuccess,
    testCaseGroupLength,
    setIsTestCaseGroupLengthChanged,
  ]);

  useEffect(() => {
    if (isLengthChanged) {
      setValue('serviceGroupIdx', serviceGroupList?.at(-1)?.idx);
      setIsLengthChanged(false);
    }
  }, [serviceGroupList, isLengthChanged, setValue]);

  return (
    <div className="flex flex-col">
      <div className="flex gap-2">
        <p className="block text-sm font-medium leading-6 text-gray-900">
          Service Group*
        </p>
        <ErrorMessage
          errors={errors}
          name="serviceGroupIdx"
          render={({ message }) => <p className="error-message">{message}</p>}
        />
      </div>
      <div className="mt-2 grid grid-flow-col grid-cols-1 items-center gap-2">
        <Controller
          control={control}
          name={'serviceGroupIdx'}
          rules={{ required: '필수 선택 사항입니다.' }}
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value || ''}
              buttonValue={
                field.value
                  ? findTestGroupNameByIdx(field.value, serviceGroupList)
                  : 'Select a service group'
              }
              onChange={field.onChange}
              lists={serviceGroupList}
              valueToSave={'idx'}
              valueToShow={'name'}
              noListMessage={'No service groups available to select'}
            />
          )}
        />
        <button
          type="button"
          className="hover:text-gray-600"
          onClick={() => {
            dispatch(openCreateGroup());
            setClickGroupType('Service');
          }}
        >
          <CreatePlusButton />
        </button>
      </div>
    </div>
  );
};

export default ServiceGroupBox;
