import { Button } from '@autosquare/common';

import { useEditableDiv } from '@hooks/mobileWebTestStep/useEditableDiv';

import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { CreateWebSpeedTestStepData } from '@customTypes/ide/speedTest/Web/testStep/type';
import WebSpeedCreateSetAsVariable from './WebSpeedEditableDiv/WebSpeedCreateSetAsVariable';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const WebSpeedEditableDiv = ({ value, onChange }: Props) => {
  const { control, watch, getValues, setValue } =
    useFormContext<CreateWebSpeedTestStepData>();
  const { append, remove } = useFieldArray({ control, name: 'optionsDto' });

  const {
    handleInput,
    handleMouseUp,
    divRef,
    buttonRef,
    buttonPosition,
    isVariableSettingDialog,
    setIsVariableSettingDialog,
    selectedText,
    setSelectedText,
    openDialog,
    selectionStart,
    selectionEnd,
  } = useEditableDiv({
    value,
    onChange,
    append,
    remove,
    getValues,
    watch,
    setValue,
  });

  return (
    <div className="relative col-span-7">
      <div
        ref={divRef}
        contentEditable
        suppressContentEditableWarning
        onInput={handleInput}
        onMouseUp={handleMouseUp}
        className="input-base outline-none"
      />
      {selectedText && buttonPosition && (
        <div
          ref={buttonRef}
          className="absolute z-10"
          style={{
            top: `${buttonPosition.top}px`,
            left: `${buttonPosition.left}px`,
          }}
        >
          <Button
            type="button"
            variant="secondary"
            noRing
            onClick={() => openDialog(selectedText)}
          >
            Set As Variable
          </Button>
        </div>
      )}
      <WebSpeedCreateSetAsVariable
        isOpened={isVariableSettingDialog}
        setIsOpened={setIsVariableSettingDialog}
        selectedText={selectedText}
        setSelectedText={setSelectedText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
    </div>
  );
};

export default WebSpeedEditableDiv;
