import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useMobileTestResultListQuery } from '@lib/api/dashboard/mobile/mobileTestResult';

import {
  DefaultSkeleton,
  ErrorMessage,
  PagingHeadings,
  TableSkeleton,
} from '@autosquare/common';

import MobileTestResultTable from './components/MobileTestResultTable';
import MobileTestResultPagination from './components/MobileTestResultPagination';

const MobileTestResult = () => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page'));

  const limit = 10;

  const { data, error, isError, isLoading } = useMobileTestResultListQuery(
    projectIdx,
    currentPage,
    limit,
  );

  const mobileTestResults = data?.content;

  useEffect(() => {
    if (!currentPage) {
      navigate('?page=1');
    }
  }, [currentPage]);

  const breadcrumbs = [
    {
      title: 'Mobile App',
      href: `/projects/${projectIdx}/mobile-app/test-result?page=${currentPage}`,
    },
    {
      title: 'Test Result',
      href: `/projects/${projectIdx}/mobile-app/test-result?page=${currentPage}`,
    },
    {
      title: 'List',
      href: `/projects/${projectIdx}/mobile-app/test-result?page=${currentPage}`,
    },
  ];

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      <div className="px-0">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {isLoading ? (
                <TableSkeleton />
              ) : isError ? (
                <ErrorMessage>{error.message}</ErrorMessage>
              ) : (
                <MobileTestResultTable
                  mobileTestResults={mobileTestResults}
                  page={currentPage}
                />
              )}
            </div>
          </div>
        </div>
        {isLoading ? (
          <DefaultSkeleton number={1} />
        ) : (
          mobileTestResults?.length !== 0 && (
            <MobileTestResultPagination
              page={currentPage}
              total={data?.totalElements}
              limit={limit}
            />
          )
        )}
      </div>
    </>
  );
};

export default MobileTestResult;
