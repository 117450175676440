import React from 'react';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import {
  HeadingLevelTwoWithUnderline,
  TableBodyGray,
} from '@autosquare/common';
import { TestResult } from '@customTypes/testResult/type';
import {
  DeviceInfoOs,
  DeviceInfoOsFullName,
} from '@customTypes/ide/device/device';

type Props = { testResult: TestResult };

const Information = ({ testResult }: Props) => {
  const tableHeaders = isWordInLocationPathname('mobile-app')
    ? ['Tester', 'DateTime', 'OS', 'Devices', 'Scenario', 'App Version', 'Type']
    : ['Tester', 'DateTime', 'Browser', 'Scenario', 'Browser Version', 'Type'];

  const browserName = `${testResult?.browserName?.[0]?.toUpperCase()}${testResult?.browserName?.slice(
    1,
  )}
      `;

  return (
    <section>
      <HeadingLevelTwoWithUnderline>Information</HeadingLevelTwoWithUnderline>
      <div className="-mx-4 w-full">
        <table className="divide-y divide-gray-200 mx-4 py-4 min-w-full">
          <thead className="bg-gray-50">
            <tr>
              {tableHeaders.map((tableHeader) => (
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold"
                  key={tableHeader}
                >
                  {tableHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isWordInLocationPathname('mobile-app') ? (
              <tr>
                <TableBodyGray>{testResult?.tester}</TableBodyGray>
                <TableBodyGray>
                  {testResult?.startTime} ~ {testResult?.endTime.slice(11)}
                </TableBodyGray>
                <TableBodyGray>
                  {testResult.mobileOs === DeviceInfoOs.Aos
                    ? DeviceInfoOsFullName.Aos
                    : DeviceInfoOsFullName.Ios}
                </TableBodyGray>
                <TableBodyGray>{testResult?.device}</TableBodyGray>
                <TableBodyGray>{testResult?.scenarioName}</TableBodyGray>
                <TableBodyGray>
                  {testResult?.appVersion === null
                    ? '-'
                    : testResult?.appVersion}
                </TableBodyGray>
                <TableBodyGray>{testResult?.type}</TableBodyGray>
              </tr>
            ) : (
              <tr>
                <TableBodyGray>{testResult?.tester}</TableBodyGray>
                <TableBodyGray>
                  {testResult?.startTime} ~ {testResult?.endTime.slice(11)}
                </TableBodyGray>

                <TableBodyGray>{browserName}</TableBodyGray>
                <TableBodyGray>{testResult?.scenarioName}</TableBodyGray>
                <TableBodyGray>
                  {testResult?.browserVersion === null
                    ? '-'
                    : testResult?.browserVersion}
                </TableBodyGray>
                <TableBodyGray>{testResult?.type}</TableBodyGray>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Information;
