import { Button, ErrorMessage, useInput } from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';

import {
  useEmailAlarmSettingQuery,
  useUpdateEmailAlarmMutation,
} from '@lib/api/ide/setting/settings';

type Props = {
  closeModal?: () => void;
};

const SettingsEmailForm = ({ closeModal }: Props) => {
  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  const emailAlarmSettingQuery = useEmailAlarmSettingQuery({
    enabled: accessToken !== null,
  });
  const settingValues = emailAlarmSettingQuery.data;

  const [mailServer, onChangeMailServer, setMailServer] = useInput(
    settingValues?.smtp_server,
  );
  const [smtpPort, onChangeSmtpPort, setSmtpPort] = useInput(
    settingValues?.smtp_port,
  );
  const [userName, onChangeUserName, setUserName] = useInput(
    settingValues?.smtp_username,
  );
  const [password, onChangePassword] = useInput('');

  const [comparingValues, setComparingValues] = useState({
    mailServer: '',
    smtpPort: '',
    userName: '',
    password: '',
  });

  useEffect(() => {
    setMailServer(settingValues?.smtp_server);
    setSmtpPort(settingValues?.smtp_port);
    setUserName(settingValues?.smtp_username);
  }, [settingValues]);

  const data =
    password === ''
      ? [
          { key: 'smtp_server', value: mailServer },
          { key: 'smtp_port', value: smtpPort },
          { key: 'smtp_username', value: userName },
        ]
      : [
          { key: 'smtp_server', value: mailServer },
          { key: 'smtp_port', value: smtpPort },
          { key: 'smtp_username', value: userName },
          { key: 'smtp_password', value: password },
        ];

  const updateEmailAlarmMutation = useUpdateEmailAlarmMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (accessToken) {
      updateEmailAlarmMutation.mutate(data, {
        onSuccess: () => {
          closeModal();
        },
        onError: () => {
          setComparingValues({
            mailServer: mailServer,
            smtpPort: smtpPort,
            userName: userName,
            password: password,
          });
        },
      });
    }
  };

  const formProperties = [
    {
      label: 'Mail(SMTP) Server (ex. smtp.gmail.com)',
      id: 'mailServer',
      type: 'text',
      defaultValue: settingValues?.smtp_server,
      onChange: onChangeMailServer,
      required: true,
    },
    {
      label: 'SMTP Port (ex. 465)',
      id: 'smtpPort',
      type: 'number',
      defaultValue: settingValues?.smtp_port,
      onChange: onChangeSmtpPort,
      required: true,
    },
    {
      label: 'Username (ex. user@gmail.com)',
      id: 'userName',
      type: 'email',
      defaultValue: settingValues?.smtp_username,
      onChange: onChangeUserName,
      required: true,
    },
    {
      label: 'Password',
      id: 'password',
      type: 'password',
      onChange: onChangePassword,
      placeholder: settingValues?.smtp_server
        ? '저장된 비밀번호는 표시되지 않습니다.'
        : '',
      required: false,
    },
  ];

  return (
    <>
      <div className="font-semibold">
        <h2 className="mt-5 text-lg">Email</h2>
        <hr className="" />
        <p className="my-5 text-base">SMTP 설정</p>
      </div>
      <form onSubmit={handleSubmit} className="mt-4 flow-root">
        {formProperties?.map((formProperty, index) => (
          <label
            htmlFor={formProperty.id}
            key={index}
            className="text-sm font-medium"
          >
            {formProperty.label}
            <input
              id={formProperty.id}
              name={formProperty.id}
              onChange={formProperty.onChange}
              type={formProperty.type}
              className="input-base my-4 mb-5"
              defaultValue={formProperty.defaultValue}
              disabled={accessToken === null}
              placeholder={formProperty.placeholder}
              required={formProperty.required}
            />
          </label>
        ))}
        <div className="mt-4 flex items-center justify-between px-2">
          <div />
          <ErrorMessage>
            {!accessToken
              ? '로그인 후 등록 가능합니다.'
              : emailAlarmSettingQuery.isError
                ? emailAlarmSettingQuery.error.message
                : comparingValues.mailServer === mailServer &&
                  comparingValues.smtpPort === smtpPort &&
                  comparingValues.userName === userName &&
                  comparingValues.password === password &&
                  updateEmailAlarmMutation.isError &&
                  updateEmailAlarmMutation.error.message}
          </ErrorMessage>
          <Button
            type="submit"
            variant="primary"
            disabled={accessToken === null}
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default SettingsEmailForm;
