import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { RootState } from '@app/store';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import PointOrRectangleInput from '@components/shared/PointOrRectangleInput';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

const ComparingImageInClick = () => {
  const {
    startX,
    startY,
    endX,
    endY,
    onChangeStartX,
    onChangeStartY,
    onChangeEndX,
    onChangeEndY,
    imageWidth,
    imageHeight,
    setCropCoordinate,
  } = useContext(MobileTestStepContext);

  const { setValue, resetField } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setCropCoordinate(`${startX},${startY},${endX},${endY}`);
    setValue(
      'optionsDto.cropCoordinate',
      `${startX},${startY},${endX},${endY}`,
    );
    setValue(
      'value',
      getCroppedImage(
        startX,
        startY,
        Math.abs(endX - startX),
        Math.abs(endY - startY),
      ).split(',')[1],
    );

    return () => {
      setCropCoordinate(null);
      setValue('value', undefined);
      resetField('optionsDto.cropCoordinate');
    };
  }, [startX, startY, endX, endY]);

  const screenshot = useSelector(
    (state: RootState) => state.mobileScreenshotSource.screenshotSource,
  );

  const getCroppedImage = (
    x: number,
    y: number,
    width: number,
    height: number,
  ) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    const imageObj = new window.Image();
    imageObj.src = `data:image/png;base64,${screenshot}`;
    context.drawImage(imageObj, x, y, width, height, 0, 0, width, height);
    return canvas.toDataURL();
  };

  const rectProperties = [
    {
      labelTitle: 'startX',
      maxSize: imageWidth,
      value: startX,
      onChange: onChangeStartX,
    },
    {
      labelTitle: 'startY',
      maxSize: imageHeight,
      value: startY,
      onChange: onChangeStartY,
    },
    {
      labelTitle: 'endX',
      maxSize: imageWidth,
      value: endX,
      onChange: onChangeEndX,
    },
    {
      labelTitle: 'endY',
      maxSize: imageHeight,
      value: endY,
      onChange: onChangeEndY,
    },
  ];

  return (
    <PointOrRectangleInput
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      properties={rectProperties}
      isHiddenInputArrow={true}
    />
  );
};

export default ComparingImageInClick;
