import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  control: Control<CreateTestStepData>;
};

const SecretBox = ({ control }: Props) => {
  return (
    <div className="flex justify-end">
      <label
        htmlFor="secret"
        className="flex items-center justify-between gap-2 text-sm font-medium leading-6 text-gray-900"
      >
        <legend>Secret</legend>
        <Controller
          control={control}
          name="optionsDto.textEncrypt"
          render={({ field }) => (
            <input
              id="secret"
              name="secret"
              type="checkbox"
              className="size-5 rounded border border-solid border-gray-300 text-congress-blue"
              defaultChecked={false}
              {...field}
            />
          )}
        />
      </label>
    </div>
  );
};

export default SecretBox;
