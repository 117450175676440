/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';

import { decodeJwtToken } from '@autosquare/common';

import { UserInfoType } from '@customTypes/type';
import { useDashboardLogoutMutation } from '@lib/api/dashboard/user/users';

type Props = {
  userInfo: UserInfoType;
  accessToken: string;
};

const MobileViewNav = ({ userInfo, accessToken }: Props) => {
  const location = useLocation();

  const dashboardLogoutMutation = useDashboardLogoutMutation();

  const navList = [
    {
      idx: 1,
      name: 'Projects',
      linkTo: '/projects',
    },
    {
      idx: 2,
      name: 'Download',
      linkTo: '/download',
    },
  ];

  return (
    <DisclosurePanel className="sm:hidden">
      <div className="space-y-2 pb-3 pt-2">
        {navList.map((nav) => (
          <Link to={nav.linkTo} key={nav.idx}>
            <DisclosureButton
              as="button"
              className={clsx('block py-2 pl-3 pr-4 text-base font-medium', {
                'border-l-4 border-indigo-500 bg-indigo-50 text-indigo-700':
                  location.pathname.includes(nav.linkTo),
              })}
            >
              {nav.name}
            </DisclosureButton>
          </Link>
        ))}
        <a
          href={`${
            window.location.origin === 'http://localhost:3002'
              ? 'http://localhost:3000/docs/'
              : `${window.location.origin}/docs/`
          }`}
          target="_blank"
        >
          <DisclosureButton
            as="button"
            className="block py-2 pl-3 pr-4 text-base font-medium"
          >
            Documents
          </DisclosureButton>
        </a>
      </div>
      <Menu as={'div'} className="border-t border-gray-200 pb-3 pt-4">
        <MenuButton className="flex items-center px-4">
          <div className="shrink-0">
            <div>
              <span className="sr-only">Open user menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-8"
              >
                <path
                  fillRule="evenodd"
                  d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div className="ml-3">
            <div className="text-base font-medium text-gray-800">
              {userInfo?.name}
            </div>
          </div>
        </MenuButton>
        <div className="mt-3 space-y-1"></div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
            {decodeJwtToken(accessToken)?.auth === 'ROLE_ADMIN' && (
              <MenuItem>
                {({ focus }) => (
                  <Link to={'/admin/users/list'}>
                    <div
                      className={
                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer ' +
                        (focus && 'bg-gray-100')
                      }
                    >
                      Administration
                    </div>
                  </Link>
                )}
              </MenuItem>
            )}
            <MenuItem>
              {({ focus }) => (
                <button
                  type="button"
                  className={clsx(
                    'block cursor-pointer px-4 py-2 text-sm text-gray-700',
                    { 'bg-gray-100': focus },
                  )}
                  onClick={() => dashboardLogoutMutation.mutate()}
                >
                  Sign out
                </button>
              )}
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
    </DisclosurePanel>
  );
};

export default MobileViewNav;
