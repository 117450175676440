import {
  FromName,
  FromServerName,
  TextReplaceFromList,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

export const textReplaceFromList: TextReplaceFromList[] = [
  {
    idx: 1,
    name: FromName.Today,
    serverName: FromServerName.Today,
  },
];

export const findTextReplaceFromNameByServerName = (
  serverName: FromServerName,
) =>
  textReplaceFromList.find(
    (textReplace) => textReplace.serverName === serverName,
  )?.name;

export const findTextReplaceFromServerNameByName = (name: FromName) =>
  textReplaceFromList.find((textReplace) => textReplace.name === name)
    .serverName;
