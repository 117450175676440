import {
  ActionOptions,
  CreateTestStepData,
  CreateTestStepDataOptionsDto,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type NameKeys =
  | `optionsDto.${keyof Pick<CreateTestStepDataOptionsDto, 'repeatLimit'>}`
  | `optionsDto.actionOptions.${keyof Pick<ActionOptions, 'repeatLimit'>}`;

type Props = {
  keyName: NameKeys;
  defaultValue?: number;
  min?: number;
  max?: number;
};

const Repeat = ({ keyName, defaultValue = 1, min = 1, max = 99 }: Props) => {
  const { register, setValue } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setValue(keyName, defaultValue.toString());
  }, [setValue]);

  return (
    <div className="flex items-start gap-4">
      <label
        htmlFor={`repeat-${keyName}`}
        className="block pt-1 text-sm font-medium leading-6 text-gray-900"
      >
        Repeat
      </label>
      <div className="w-full">
        <input
          type="number"
          name={`repeat-${keyName}`}
          id={`repeat-${keyName}`}
          className="input-base"
          defaultValue={defaultValue}
          min={min}
          max={max}
          {...register(keyName, {
            required: 'Repeat을 입력해주세요.',
            min: { value: min, message: `${min} 이상 숫자만 입력 가능합니다.` },
            max: { value: max, message: `${max} 이하 숫자만 입력 가능합니다.` },
          })}
        />
      </div>
    </div>
  );
};

export default Repeat;
