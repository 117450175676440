import {
  CreateSpeedSchedulerData,
  UpdateSpeedSchedulerData,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { SpeedTestScenarioDataInScheduler } from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import update from 'react-addons-update';
import { useDrop } from 'react-dnd';
import { UseFormSetValue } from 'react-hook-form';

export const useSpeedTestSchedulerListDrop = (
  setValue: UseFormSetValue<
    CreateSpeedSchedulerData | UpdateSpeedSchedulerData
  >,
  copiedTestScenarioList: SpeedTestScenarioDataInScheduler[],
  setCopiedTestScenarioList: Dispatch<
    SetStateAction<SpeedTestScenarioDataInScheduler[]>
  >,
  changeCardList: (list: SpeedTestScenarioDataInScheduler[]) => void,
) => {
  useEffect(() => {
    const scenarioIdxList = copiedTestScenarioList?.map(
      (testScenario) => testScenario.idx,
    );
    setValue('scenarioIdxList', scenarioIdxList);
  }, [copiedTestScenarioList, setValue]);

  const [, drop] = useDrop({
    accept: 'card',
  });

  const onRemove = useCallback((index: number) => {
    setCopiedTestScenarioList((prev) =>
      update(prev, {
        $splice: [[index, 1]],
      }),
    );
  }, []);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === undefined) {
        const lessIndex = copiedTestScenarioList.findIndex(
          (item) => item.dndIndex === -1,
        );
        changeCardList(
          update(copiedTestScenarioList, {
            $splice: [
              [lessIndex, 1],
              [
                hoverIndex,
                0,
                { title: 'Here', dndIndex: -1, uniqueId: crypto.randomUUID() },
              ],
            ],
          }),
        );
      } else {
        const dragCard = copiedTestScenarioList[dragIndex];
        changeCardList(
          update(copiedTestScenarioList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        );
      }
    },
    [copiedTestScenarioList],
  );

  return { drop, onRemove, moveCard };
};
