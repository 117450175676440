import { SpeedTestCaseDataInScenario } from '@customTypes/speedTestCase/type';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import React from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';

interface Props {
  copiedTestCaseList: SpeedTestCaseDataInScenario[];
  speedTestCase: SpeedTestCaseDataInScenario;
  changeCardList: (list: SpeedTestCaseDataInScenario[]) => void;
}

const SpeedTestCaseListItemCard = ({
  copiedTestCaseList,
  speedTestCase,
  changeCardList,
}: Props) => {
  let id = 1;

  const box = { ...speedTestCase, uniqueId: crypto.randomUUID() };

  const [, drag] = useDrag({
    type: 'card',
    item() {
      const useless = copiedTestCaseList.find(
        (copyTestCase) => copyTestCase.idx === -1,
      );
      if (!useless) {
        changeCardList([...copiedTestCaseList, speedTestCase]);
      }
      return box;
    },
    end(_: unknown, monitor: DragSourceMonitor) {
      const uselessIndex = copiedTestCaseList.findIndex(
        (copyTestCase) => copyTestCase.dndIndex === -1,
      );

      if (monitor.didDrop()) {
        copiedTestCaseList.splice(uselessIndex, 1, {
          ...monitor.getItem(),
          dndIndex: id++,
        });
      } else {
        copiedTestCaseList.splice(uselessIndex, 1);
      }
      changeCardList(copiedTestCaseList);
    },
  });

  const detailList = [
    {
      title: 'Platform',
      description:
        speedTestCase.platformType === PlatFormTypeServerName.MobileApp
          ? 'App'
          : 'Web',
    },
    {
      title: 'Test Group',
      description: speedTestCase.caseGroupInfo?.name || '-',
    },
    {
      title: 'Service Group',
      description: speedTestCase.serviceGroupInfo?.name || '-',
    },
  ];

  return (
    <div ref={drag} className="m-2 cursor-move">
      <Disclosure>
        <DisclosureButton className="group flex w-full cursor-move items-center justify-between border border-solid border-gray-200 bg-gray-50 px-3 py-2">
          <div className="flex gap-7">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 9h16.5m-16.5 6.75h16.5"
              />
            </svg>
            <p className="text-sm font-normal leading-tight text-black">
              {speedTestCase.title}
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5 cursor-pointer group-data-[open]:rotate-180"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </DisclosureButton>
        <DisclosurePanel className="border-x border-b border-solid border-gray-200 ">
          {detailList.map((detail) => (
            <div
              key={detail.title}
              className="grid grid-cols-3 px-3 py-2 odd:bg-gray-50"
            >
              <p className="flex h-6 items-center text-xs font-medium leading-normal text-gray-900">
                {detail.title}
              </p>
              <p className="col-span-2 flex h-6 items-center break-words text-xs font-normal leading-normal text-gray-700">
                {detail.description}
              </p>
            </div>
          ))}
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
};

export default SpeedTestCaseListItemCard;
