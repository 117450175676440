import TestScenarioInformation from '@components/SpeedTestScenario/TestScenarioInformation';
import { CreateWebSpeedTestScenarioData } from '@customTypes/ide/speedTest/Mobile/testScenario/type';

import React from 'react';
import { useFormContext } from 'react-hook-form';

const WebSpeedTestScenarioInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateWebSpeedTestScenarioData>();

  return <TestScenarioInformation register={register} errors={errors} />;
};

export default WebSpeedTestScenarioInformation;
