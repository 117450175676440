import React from 'react';

type Props = {
  label: string;
};

const SkeletonDeviceLabel = ({ label }: Props) => {
  return (
    <div className="flex flex-col gap-y-3 text-sm">
      <div className="flex gap-x-3">
        <p className="text-gray-900">{label}</p>
        <p className="h-5 w-[1.875rem] rounded-lg bg-gray-100 text-center">0</p>
      </div>
      <hr />
    </div>
  );
};

export default SkeletonDeviceLabel;
