import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  WebSpeedTestCaseExecutionData,
  WebSpeedTestScenarioExecutionData,
} from '@customTypes/ide/browser/browserExecution';
import { BrowserInfo } from '@customTypes/ide/browser/browser';

export type SelectedWebExecutionData = {
  selectedWebExecutionData:
    | WebSpeedTestCaseExecutionData
    | WebSpeedTestScenarioExecutionData;
};

const initialState: SelectedWebExecutionData = {
  selectedWebExecutionData: {
    scenarioIdx: undefined,
    projectIdx: undefined,
    infos: undefined,
    caseIdx: undefined,
    repeat: 12,
  },
};

export const selectedWebExecutionDataSlice = createSlice({
  name: 'SelectedExecutionBrowserData',
  initialState,
  reducers: {
    getWebExecutionData: (
      state,
      action: PayloadAction<
        WebSpeedTestCaseExecutionData | WebSpeedTestScenarioExecutionData
      >,
    ) => {
      state.selectedWebExecutionData = action.payload;
    },
    onChangeWebExecutionRepeatData: (
      state,
      action: PayloadAction<{ repeat: number }>,
    ) => {
      const { repeat } = action.payload;
      state.selectedWebExecutionData.repeat = repeat;
    },
    onChangeWebExecutionInfosData: (
      state,
      action: PayloadAction<{ infos: BrowserInfo }>,
    ) => {
      const { infos } = action.payload;
      if (state.selectedWebExecutionData) {
        state.selectedWebExecutionData.infos = infos;
      }
    },
    resetWebExecutionData: (state) => {
      state.selectedWebExecutionData = initialState.selectedWebExecutionData;
    },
  },
});

export const {
  getWebExecutionData,
  onChangeWebExecutionRepeatData,
  onChangeWebExecutionInfosData,
  resetWebExecutionData,
} = selectedWebExecutionDataSlice.actions;

export default selectedWebExecutionDataSlice.reducer;
