import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  color: "green" | "red";
};

const DivisionWord = ({ children, color }: Props) => {
  const textColor = {
    green: "text-green-400",
    red: "text-red-500",
  };

  return (
    <h4 className={`pt-4 pb-4 px-6 text-sm font-medium ${textColor[color]}`}>
      {children}
    </h4>
  );
};

export default DivisionWord;
