import React from "react";

import { useAdminUserListQuery } from "@lib/api/dashboard/admin/admin";

import AdminUserListTable from "./components/AdminUserListTable";

import {
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadings,
  TableSkeleton,
} from "@autosquare/common";

const AdminUsersList = () => {
  const {
    data: adminUserList,
    isError,
    error,
    isLoading,
    isFetching,
  } = useAdminUserListQuery();

  return (
    <>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={"User List"}
        pageHeadingButtons={pageHeadingButtons}
      />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {isLoading || isFetching ? (
              <TableSkeleton />
            ) : isError ? (
              <ErrorMessage>{error.message}</ErrorMessage>
            ) : (
              <AdminUserListTable adminUserList={adminUserList} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsersList;

const breadcrumbs = [
  { title: "Administration", href: `/admin/users/list` },
  { title: "Users", href: `/admin/users/list` },
  { title: "List", href: `/admin/users/list` },
];

const pageHeadingButtons: PageHeadingButtons[] = [
  {
    title: "Add user",
    href: `/admin/users/add`,
    type: "button",
    variant: "primary",
  },
];
