import React from 'react';
import SpeedTestResultGraphInfoTable from '@components/shared/SpeedTestResultTable/SpeedTestResultGraphInfoTable';

import {
  AccessorFn,
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import LineChart from './LineChart';
import {
  SpeedData,
  WebSpeedTestResultDetail,
} from '@customTypes/ide/speedTest/Web/testResult/type';

type Props = {
  isResultNA: boolean;
  failReason: string;
  data: WebSpeedTestResultDetail;
};

const SpeedTestResultGraph = ({ isResultNA, failReason, data }: Props) => {
  const columnHelper = createColumnHelper();

  const makeData = (
    chartCategories: string[],
    data: SpeedData[],
  ): unknown[] => {
    return [...chartCategories].reverse().map((v, i) => ({
      0: { idx: 0, data: v },
      ...data?.reduce((acc, item, dataIndex) => {
        acc[dataIndex + 1] = [...item.data]
          .map((value) => ({
            idx: value.resultIdx,
            data: value?.value === -1.0 ? '-' : value?.value.toFixed(2),
          }))
          .reverse()[i];
        return acc;
      }, {}),
    }));
  };

  const makeColumns = (
    columnsData: SpeedData[],
  ): ColumnDef<unknown, unknown>[] => [
    columnHelper.accessor('0' as unknown as AccessorFn<unknown, unknown>, {
      header: 'Date',
      cell: (info) => info.getValue(),
    }),
    ...columnsData.map((v, i) =>
      columnHelper.accessor(
        (i + 1).toString() as unknown as AccessorFn<unknown, unknown>,
        {
          header: v?.name,
          cell: (info) => info.getValue(),
        },
      ),
    ),
  ];

  return (
    <>
      <div className="flex items-center justify-between border-b border-gray-200 pb-3 pt-6">
        <h2 className="font-semibold">Test Result Graph</h2>
      </div>
      {isResultNA ? (
        <div className="p-6">
          <div className="text-xl font-semibold text-gray-900">N/A</div>
          <div className="text-sm font-medium text-gray-500">
            {failReason ?? 'unchecked error'}
          </div>
        </div>
      ) : (
        <>
          <LineChart data={data} />
          <SpeedTestResultGraphInfoTable
            data={makeData(data?.date, data?.loadSpeedData)}
            columns={makeColumns(data?.loadSpeedData)}
          />
        </>
      )}
    </>
  );
};

export default SpeedTestResultGraph;
