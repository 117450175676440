export enum DeviceKeyName {
  Default = 'Select Device Key',
  Back = 'Back',
  BackIos = 'BackIos',
  Enter = 'Enter',
  Home = 'Home',
  Hide = 'Hide',
}

export enum DeviceKeyServerName {
  Default = 'Select Device Key',
  Back = 'back',
  BackIos = 'back_ios',
  Enter = 'enter',
  Home = 'home',
  Hide = 'hide_keyboard',
}

export enum DeviceKeyListName {
  Default = 'Select Device Key',
  Back = 'Back Key',
  BackIos = 'Back Key - Android Only',
  Enter = 'Enter Key',
  Home = 'Home Key',
  Hide = 'Hide Keyboard',
}

export type DeviceKeyList = {
  idx: number;
  name: DeviceKeyName;
  serverName: DeviceKeyServerName;
  listName: DeviceKeyListName;
  disabled?: boolean;
};

export const deviceKeyList: DeviceKeyList[] = [
  {
    idx: 0,
    name: DeviceKeyName.Default,
    serverName: DeviceKeyServerName.Default,
    listName: DeviceKeyListName.Default,
  },
  {
    idx: 1,
    name: DeviceKeyName.Back,
    serverName: DeviceKeyServerName.Back,
    listName: DeviceKeyListName.Back,
  },
  {
    idx: 2,
    name: DeviceKeyName.Enter,
    serverName: DeviceKeyServerName.Enter,
    listName: DeviceKeyListName.Enter,
  },
  {
    idx: 3,
    name: DeviceKeyName.Home,
    serverName: DeviceKeyServerName.Home,
    listName: DeviceKeyListName.Home,
  },
  {
    idx: 4,
    name: DeviceKeyName.Hide,
    serverName: DeviceKeyServerName.Hide,
    listName: DeviceKeyListName.Hide,
  },
];

export const deviceKeyListInIos: DeviceKeyList[] = [
  {
    idx: 0,
    name: DeviceKeyName.Default,
    serverName: DeviceKeyServerName.Default,
    listName: DeviceKeyListName.Default,
  },
  {
    idx: 1,
    name: DeviceKeyName.BackIos,
    serverName: DeviceKeyServerName.BackIos,
    listName: DeviceKeyListName.BackIos,
    disabled: true,
  },
  {
    idx: 2,
    name: DeviceKeyName.Enter,
    serverName: DeviceKeyServerName.Enter,
    listName: DeviceKeyListName.Enter,
  },
  {
    idx: 3,
    name: DeviceKeyName.Home,
    serverName: DeviceKeyServerName.Home,
    listName: DeviceKeyListName.Home,
  },
  {
    idx: 4,
    name: DeviceKeyName.Hide,
    serverName: DeviceKeyServerName.Hide,
    listName: DeviceKeyListName.Hide,
  },
];

export const findListNameFromServerName = (serverName: DeviceKeyServerName) =>
  deviceKeyList.find((deviceKey) => deviceKey.serverName === serverName)
    ?.listName;

export const findDeviceKeyServerNameFromListName = (
  listName: DeviceKeyListName,
) =>
  deviceKeyList.find((deviceKey) => deviceKey.listName === listName)
    ?.serverName;

export const findDeviceKeyFromServerName = (serverName: DeviceKeyServerName) =>
  deviceKeyList.find((deviceKey) => deviceKey.serverName === serverName);

export const findListNameFromServerNameInIos = (
  serverName: DeviceKeyServerName,
) =>
  deviceKeyListInIos.find((deviceKey) => deviceKey.serverName === serverName)
    ?.listName;
