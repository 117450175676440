import { Breadcrumbs, PagingHeadingsNavigate } from '@autosquare/common';
import DeviceList from '@components/DeviceList/DeviceList';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const SpeedMobileDeviceList = () => {
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();

  const testCaseDetailPage = `/ide/projects/${projectIdx}/speed-test/mobile/test-case/detail${search}`;
  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Speed Test',
      href: pathname.split('/').slice(0, -3).join('/') + '/overview',
    },
    {
      title: 'Mobile',
      href: `/ide/projects/${projectIdx}/speed-test/mobile/test-case?page=1`,
    },
    {
      title: 'Test Case',
      href: testCaseDetailPage,
    },
    {
      title: 'Test Step',
      href: testCaseDetailPage,
    },
    { title: 'Device List', href: pathname + search },
  ];
  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Device List'}
      />
      <DeviceList />
    </>
  );
};

export default SpeedMobileDeviceList;
